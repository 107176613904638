import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import {Link} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

import { fetchOwnerHorsesHistory } from "../../redux/getReducer/getOwnerHorsesHistory";
import Moment from "react-moment";
import PleaseWait from "../Reuseable/PleaseWait";
import arrow1 from "../../assets/image 13 (Traced).png";
import { fetchownerhorsekindStats } from "../../redux/getReducer/getOwnerHorseKind";
import VideoFrame from "../Modal/VideoFrame";
import JockeyDetail from "../Modal/JockeyModal";
import HorseModal from "../Modal/HorseModal";
import RaceModal from "../Modal/RaceModal";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import ScrollContainer from "react-indiana-drag-scroll";
import { Accordion, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Pagination from "../../pages/Pagination";


const Stats = ({ id }) => {
  const { data: ownerhorsekindstats } = useSelector((state) => state.ownerhorsekindstats);
  const { data: OwnerHorsesHistory } = useSelector((state) => state.OwnerHorsesHistory);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  console.log(OwnerHorsesHistory,"OwnerHorsesHistory =================>")

  let totalcountdata = ownerhorsekindstats ? ownerhorsekindstats.totalcount : "";
  let totalpagesdata = ownerhorsekindstats ? ownerhorsekindstats.totalPages : "";
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(11);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);




  const [ShowJockey, setShowJockey] = useState(false);
  const [modaldatasJockey, setmodaldatasJockey] = useState();
  const handleJockeyCloses = () => setShowJockey(false);
  const handleShowJockey = async (data) => {
    setmodaldatasJockey(data);
    await setShowJockey(true);
  };

  const [showRace, setShowRace] = useState(false);
  const [modaldataRace, setmodaldataRace] = useState();
  const handleCloseRace = () => setShowRace(false);
  const handleShowRace = async (id) => {
    setmodaldataRace(id);
    await setShowRace(true);
  };

  const { t } = useTranslation();
  const cookiedata = Cookies.get("i18next");
  const [ShowHorse, setShowHorse] = useState(false);
  const [modaldatasHorse, setmodaldatasHorse] = useState();
  const handleHorseCloses = () => setShowHorse(false);
  const handleShowHorse = async () => {
    await setShowHorse(true);
  };

  const [shows, setShows] = useState(false);
  const [modaldatas, setmodaldatas] = useState();
  const handleCloses = () => setShows(false);
  const handleShows = async (data) => {
    setmodaldatas(data);
    await setShows(true);
  };

  const FirstLetter = (data) => {
    return data.substring(0, 1);
  }

  const handleTrainerRecord = (horseid) => {
    dispatch(fetchOwnerHorsesHistory({horseid , id }));
  };
  function distanceLabel(distance, rank) {
    const DistanceTobeChecked = parseFloat(distance);
    if (DistanceTobeChecked.toFixed(2) > 25) {
      return "Dist";
    } else if (DistanceTobeChecked.toFixed(2) == 0.05 && rank < 80) {
      return "SHd";
    } else if (DistanceTobeChecked.toFixed(2) == 0.10 && rank < 80) {
      return "Hd";
    } else if (DistanceTobeChecked.toFixed(2) == 0.25 && rank < 80) {
      return "Nk";
    } else if (DistanceTobeChecked.toFixed(2) == 0.01 && rank < 80) {
      return "Ns";
    } else if (DistanceTobeChecked.toFixed(2) == 0.00 && rank < 80) {
      if(rank === 1){
        return 0;
      }
      return "Dh";
    } else {
      return distance;
    }
  }
  return (
    <>
       <div className="mycardclass1">
        <div className="formobilescroll1">
        <table className="statsTable innermodalstats mycss">
            <thead>
              <tr className="mycss1 forownerhorse">
              <td>{t("Horse Kind")}</td>
                <td>{t("Runs")} </td>
                <td>{t("Wins")} </td>
                <td>{t("Second")}</td>
                <td>{t("Third")} </td>
                <td>{t("Fourth")} </td>
                <td>{t("Fifth")} </td>
                <td>{t("Earning")} </td>
                <td>{t("Wins")}% </td>
                <td></td>
              </tr>
            </thead>
          </table>
          <Accordion  defaultActiveKey="0"> 

          {ownerhorsekindstats?.data &&
            ownerhorsekindstats?.data?.map((item, i) => (
              <>
                  <Accordion.Item
                    className="trainerstatsbtn1 data_border"
                  >
                    <div>
                      <Accordion.Header>
                        <table style={{ width: "100%" }}>
                          <thead>
                            <tr className="statsmodalhorse1">
                              <td>
                              { cookiedata === "en" ? item.NameEn : item.NameAr}
                              </td>
                              <td>{item?.Runs}</td>
                              <td>{item?.wins}</td>
                              <td>{item?.second}</td>
                              <td>{item?.third}</td>
                              <td>{item?.fourth}</td>
                              <td>{item?.fifth}</td>
                              <td>{item?.earning == null ? 0 : item?.earning}</td>
                              <td>{item?.winspercentage === null ? 0 : item?.winspercentage}</td>
                              <td>
                                <IoIosArrowDropdownCircle />
                              </td>
                            </tr>
                          </thead>
                        </table>{" "}
                      </Accordion.Header>
                      <Accordion.Body className="trainerstatsbtn1">
                        <div className="raceCardTable">
                          <ScrollContainer>
                            <div className="mycardclass22">
                              <div className="BodyNew mobiledatascroll">
                                <table className="customers">
                                  {/* <thead className="customers5">
                                  <tr>
                                  <td>{t("Horse Kind")}</td>
                <td>{t("Runs")} </td>
                <td>{t("Wins")} </td>
                <td>{t("Second")}</td>
                <td>{t("Third")} </td>
                <td>{t("Fourth")} </td>
                <td>{t("Fifth")} </td>
                <td>{t("Earning")} </td>
                <td>{t("Wins")}% </td>
                <td></td>
                                      </tr>
                                  </thead> */}
                                  <tbody className="customers1 ">
                                    {item?.KindHorseData === undefined ? (
                                      <PleaseWait />
                                    ) : (
                                      <>
                                        {!item?.KindHorseData ? (
                                          <PleaseWait />
                                        ) : (
                                          item?.KindHorseData?.map((data, key1) => {
                                            return (
                                              <Accordion.Item
                                              className="trainerstatsbtn1 data_border"
                                              eventKey={key1}
                                              onClick={() => handleTrainerRecord(data._id)}
                                            >
                                              <div>
                                                <Accordion.Header>
                                                  <table style={{ width: "100%" }}>
                                                    <thead>
                                                      <tr className="statsmodalhorse1" onClick={handleShowHorse}>
                                                        <td >
                                                        { cookiedata === "en" ? data.NameEn : data.NameAr}
                                                        </td>
                                                        <td>{data.Runs}</td>
                                                        <td>{data.wins}</td>
                                                        <td>{data.second}</td>
                                                        <td>{data.third}</td>
                                                        <td>{data.fourth}</td>
                                                        <td>{data.fifth}</td>
                                                        <td>{data.earning == null ? 0 : data.earning}</td>
                                                        <td>{data.winspercentage === null ? 0 : data.winspercentage}</td>
                                                        <td>
                                                          <IoIosArrowDropdownCircle />
                                                        </td>
                                                      </tr>
                                                    </thead>
                                                  </table>{" "}
                                                </Accordion.Header>
                                                
                                              </div>
                                            </Accordion.Item>
                                            );
                                          })
                                        )}
                                      </>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </ScrollContainer>
                        </div>
                      </Accordion.Body>
                    </div>
                  </Accordion.Item>
              </>
            ))}
            </Accordion>

            <Pagination
                postsPerPage={postsPerPage}
                totalPosts={totalcountdata}
                paginate={paginate}
                currentPage={currentPage}
                TotalPages={totalpagesdata}
              />
        </div>
      </div>
      <Modal
        show={shows}
        onHide={handleCloses}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <VideoFrame data={modaldatas} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        show={ShowJockey}
        onHide={handleJockeyCloses}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <JockeyDetail data={modaldatasJockey} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        show={ShowHorse}
        onHide={handleHorseCloses}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <HorseModal data={id} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        show={showRace}
        onHide={handleCloseRace}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <RaceModal id={modaldataRace} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};
export default Stats;
