import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getRaceWithNumberSlice = createSlice({
    name: 'racewithnumber',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchRaceWithNumber.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchRaceWithNumber.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchRaceWithNumber.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setPedigree, setStatus } = getRaceWithNumberSlice.actions;
export default getRaceWithNumberSlice.reducer;

export const fetchRaceWithNumber = createAsyncThunk('/TodaysRacesAlltoAll/fetch', async ({ racecourseid , newDate , token}) => {


    const res = await axios.get(`${window.env.API_URL}TodaysRacesAlltoAll/${racecourseid}/${newDate}`,{
        headers: { "Authorization": token, 'x-xsrf-token': token }
    });
    const RaceNumber = res.data;
    return RaceNumber.data[0].RaceCourseData;
})  