import { useEffect } from "react";
import "../Components/CSS/pagesCSS/about.css";
import "../Components/CSS/pagesCSS/horse.css";
import ListGroup from "react-bootstrap/ListGroup";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { BsFillCaretRightFill } from "react-icons/bs";
import "../Components/CSS/pagesCSS/owner.css";
import Cookies from "js-cookie";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import DefaultImg from "../assets/Frame.png"
import PleaseWait from "../Components/Reuseable/PleaseWait";
import arrow1 from "../assets/image 13 (Traced).png";
import { useNavigate ,useLocation  } from "react-router-dom";
import Stats from "../Components/Tabs/Stats";
import { useDispatch, useSelector } from "react-redux";
import { fetchJockeyHistory } from "../redux/getReducer/getJockeyHistory";


const JockeyDetail = (data) => {
  const { t } = useTranslation()
  let location = useLocation();


  const navigate = useNavigate();
  let jockeyid = data.data._id;
  const { data: JockeyHistory } = useSelector((state) => state.JockeyHistory);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchJockeyHistory({ jockeyid }));
  }, [dispatch, jockeyid]);
  const cookiedata = Cookies.get('i18next')


  return (
    <>
      <div className="aboutpage">
        <div className="RaceDetailCard">
          <div className="horseheader1">
            <div className="horseshirt">
              <img src={data.data.image ? data.data.image : DefaultImg} alt="" className="horseshirtimage" style={{
                height: '90px'
              }} />

            </div>
            {/* <div className="horsecardtop">
              <p>{data.data.Rating}</p>
              <p className="horsefoal"> {data.data.JockeyAllowance}</p>
            </div> */}
            <div className="horsecenterdata">
              <span
                style={{
                  display: "flex",
                }}
              >
                <p
                  style={{
                    fontWeight: "700",
                    fontSize: " 19.6px",
                    lineHeight: "24px",
                    color: "#19469D",
                  }}
                >
                  {
                    location.pathname === '/jockey' ? <>
                                      {cookiedata === "en" ? (data.data.NameEn ? data.data.NameEn : <>- </>) : (data.data.NameEn ? data.data.NameAr : <>- </>)}

                    </> : <>
                    {cookiedata === "en" ? (data.data.JockeyOnRaceData1 ? data.data.JockeyOnRaceData1.NameEn : <>- </>) : (data.data.JockeyOnRaceData1 ? data.data.JockeyOnRaceData1.NameAr : <>- </>)}

                    </>
                  }

                </p>
                {/* <p
                  style={{
                    fontSize: "12px",
                    marginLeft: '20px'
                  }}
                >
                  {data.data.JockeyOnRaceData1.DOB === null ? <>No Data</> : <><Moment fromNow ago>
                    {data.data.JockeyOnRaceData1.DOB}
                  </Moment></>}

                </p> */}
              </span>
              <span
                style={{
                  display: "flex",
                }}
              >
                <p
                  style={{
                    fontSize: "12px",
                    paddingLeft: "10px"
                  }}
                >
                  {t("Minimum Weight")}:<b style={{
                    paddingLeft: "5px"
                  }}>
                    
                    {data.data.MiniumumJockeyWeight === null ? <>- </> : <>{data.data.MiniumumJockeyWeight}</>}</b>
                </p>
                <p
                  style={{
                    fontSize: "10px",
                    paddingLeft: "6px"
                  }}
                >
                  {t("Maximum Weight")}:<b style={{
                    paddingLeft: "5px"
                  }}>{data.data.MaximumJockeyWeight === null ? <>- </> : <>{data.data.MaximumJockeyWeight}</>}</b>
                </p>
                <p
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {t("Allowance")}: <b style={{
                    paddingLeft: "5px"
                  }}>{data.data.JockeyAllowance === null ? <>- </> : <>{data.data.JockeyAllowance}</>}</b>
                </p>
              </span>
              <div className="horsedetailimage">
                {
                  data.data.JockeyNationalityData ? <>
                    <img src={data.data.JockeyNationalityData.image ? data.data.JockeyNationalityData.image : DefaultImg} alt="" />

                  </> : <img src={DefaultImg} alt="" />
                }
                <span>
                  <p
                    style={{
                      fontSize: "12px",
                      lineHeight: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    <b style={{
                      padding: "10px",

                    }}>{t("Nationality")}</b>{cookiedata === "en" ? (data.data.JockeyNationalityData ? data.data.JockeyNationalityData.NameEn : <></>) : (data.data.JockeyNationalityData ? data.data.JockeyNationalityData.NameAr : <></>)}
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      marginLeft: "10px",
                    }}
                  >
                    <b style={{
                      padding: "10px",
                    }}> {t("Short Name")}            </b>{cookiedata === "en" ? (data.data.ShortNameEn ? data.data.ShortNameEn : <>- </>) : (data.data.ShortNameAr ? data.data.ShortNameAr : <>- </>)}
                  </p>
                </span>

              </div>
            </div>
            {/* <div className="horseimage">
          <img src={data.data.HorseImage} alt='' />
        </div> */}
          </div>
          <div className="horsebody">
            <ListGroup>
              <ListGroup.Item className="horsebodyitem">
                <p style={{
                  color: '#fff',
                  textAlign: 'center'
                }}>{t("National Hunt Form")}</p>
              </ListGroup.Item>
              <div className="horsehistorylist">
                <p style={{
                  color: '#fff',
                  padding: '10px'
                }}>
                  00 / P8PP44 / 7P3214112 / 1211
                </p>
              </div>
              <ListGroup.Item className="horsebodyitem">
                <div className='horserecord'>
                  <p>{t("Lifetime Record")}</p>
                  <p>{t("Runs")}</p>
                  <p>{t("Wins")}</p>
                  <p>{t("2nd")}</p>
                  <p>{t("3rd")}</p>
                  <p>{t("Winnings")}</p>
                  <p>{t("Earnings")}</p>
                  <p>{t("OR")} </p>
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="horsebodyitem1">
                <div className='horserecord'>
                  <h6>{t("FLAT TURF")}</h6>
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="horsebodyitem1">
                <div className='horserecord'>
                  <h6>{t("RULES RACES")}</h6>
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="horsebodyitem1">
                <div className='horserecord'>
                  <h6>{t("DURT")}</h6>
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="horsebodyitem1">
                <div className='horserecord'>
                  <h6>{t("TOTAL")}</h6>
                </div>
              </ListGroup.Item>
            </ListGroup>
          </div>
          <div className="RaceNav HorseNav">
            <Tabs id="uncontrolled-tab-example" className="mb-3 RaceNavItem">
              <Tab eventKey="Form" title={t("Form")} tabClassName="profile-tabitem">
              <div className="RaceDetailCard">
                <div className="mycardclass1">
                                <div className="BodyNew">
                                  <table className="customers">
                                    <thead className="customers5">
                                      <tr>
                                        <th>{t("Date")}</th>
                                        <th>{t("Cr")}</th>
                                        <th>{t("Dist")}</th>
                                        <th>{t("TC")}</th>

                                        <th>{t("Dts")}</th>
                                        <th>{t("time")}</th>
                                        <th>{t("Wgt")}</th>
                                        <th>{t("FP")}</th>
                                        <th>{t("Les")}</th>
                                        <th>{t("RS")}</th>
                                        <th>{t("BtBy")}</th>

                                        <th>{t("Draw")}</th>
                                        <th>{t("Link")}</th>
                                      </tr>
                                    </thead>
                                    <tbody className="customers1">
                                    {JockeyHistory === undefined ? (
                                        <PleaseWait />
                                      ) : (
                                        <>
                                          {!JockeyHistory ? (
                                            <PleaseWait />
                                          ) : (
                                            JockeyHistory.map((item) => {
                                              return (
                                                <tr className="BodyNew1 historyhorseth">
                                                  <td className="lb">
                                                    <Moment format="YYYY/MM/DD">
                                                      {item.RaceResultData ===
                                                      null ? (
                                                        <></>
                                                      ) : (
                                                        item.RaceResultData.Day
                                                      )}
                                                    </Moment>
                                                  </td>
                                                  <td>
                                                  {item.RaceResultData
                                                    .RacehorsesData[0]
                                                    ? cookiedata === "en"
                                                      ? item.RaceResultData
                                                          .RacehorsesData[0]
                                                          .RacehorsesData
                                                          .RaceCourseData
                                                          .TrackNameEn
                                                      : item.RaceResultData
                                                          .RacehorsesData[0]
                                                          .RacehorsesData
                                                          .RaceCourseData
                                                          .TrackNameAr
                                                    : <>- </>}
                                                  </td>
                                                  <td>
                                                    {item.Distance &&
                                                      item.Distance}
                                                  </td>
                                                  <td>
                                                    {item.RaceResultData
                                                      .RacehorsesData
                                                      ? cookiedata === "en"
                                                        ? item.RaceResultData
                                                            .RacehorsesData[0]
                                                            .RacehorsesData
                                                            .TrackConditionData
                                                            .NameEn
                                                        : item.RaceResultData
                                                            .RacehorsesData[0]
                                                            .RacehorsesData
                                                            .TrackConditionData
                                                            .NameAr
                                                      : "_"}
                                                  </td>
                                                  <td>
                                                    {item.PrizeWin
                                                      ? item.PrizeWin
                                                      : 0}
                                                  </td>
                                                  <td>{item.RaceTime}</td>
                                                  <td>
                                                    {item.RaceResultData
                                                      .RacehorsesData
                                                      ? item.RaceResultData
                                                          .RacehorsesData[0]
                                                          .JockeyRaceWeight
                                                      : "_"}
                                                  </td>
                                                  <td>
                                                    {item.FinalPositionDataHorse ===
                                                    null ? (
                                                      <></>
                                                    ) : (
                                                      <>
                                                        {cookiedata === "en" ? (
                                                          <>
                                                            {cookiedata === "en"
                                                              ? item.FinalPositionDataHorse &&
                                                                item
                                                                  .FinalPositionDataHorse
                                                                  .NameEn
                                                              : item.FinalPositionDataHorse &&
                                                                item
                                                                  .FinalPositionDataHorse
                                                                  .NameAr}
                                                          </>
                                                        ) : (
                                                          <>
                                                            {
                                                              item
                                                                .FinalPositionDataHorse
                                                                .NameAr
                                                            }
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </td>
                                                  <td>-</td>
                                                  <td>{item.Rating}</td>
                                                  {/* <td>6</td> */}
                                                  {/* <td>16.25</td> */}
                                                  <td>
                                                    {item.BeatenByData ===
                                                    null ? (
                                                      <>-</>
                                                    ) : (
                                                      <>
                                                        {cookiedata === "en" ? (
                                                          <>
                                                            {
                                                              item.BeatenByData
                                                                .NameEn
                                                            }
                                                          </>
                                                        ) : (
                                                          <>
                                                            {
                                                              item.BeatenByData
                                                                .NameAr
                                                            }
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </td>{" "}
                                                  <td>
                                                    {item.RaceResultData
                                                      .RacehorsesData[0]
                                                      ? item.RaceResultData
                                                          .RacehorsesData[0]
                                                          .HorseNo
                                                      : <>- </>}
                                                  </td>
                                                  <td
                                                    className="rb"
                                                    // onClick={() =>
                                                    //   handleShows(
                                                    //     item.VideoLink
                                                    //   )
                                                    // }
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <img src={arrow1} alt="" />
                                                  </td>
                                                </tr>
                                              );
                                            })
                                          )}
                                        </>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                </div>
              </Tab>
              <Tab
                eventKey="Entries"
                title={t("Entries")}
                tabClassName="profile-tabitem"
              >
                <div className="RaceDetailCard">retre</div>
              </Tab>
              <Tab eventKey="Stats" title={t("Stats")} tabClassName="profile-tabitem">
                <div className="RaceDetailCard">
                  <Stats />
                </div>
              </Tab>

            </Tabs>
          </div>
        </div>
      </div >
    </>
  );
};
export default JockeyDetail;
