import React, { useEffect, useState, Fragment } from "react";
import Layout from "../Components/Reuseable/layout";
import Footer from "../Components/Reuseable/Footer.jsx";
import CoptRight from "../Components/Reuseable/Copyrights";
import Cookies from "js-cookie";
import { Accordion } from "react-bootstrap";
import "../../Webiste/Components/CSS/pagesCSS/dashboard.css";
import ScrollContainer from "react-indiana-drag-scroll";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "../Components/CSS/RaceCardCSS/racecard.css";
import Justsponsor from "./justsponser";
import Lottie from 'lottie-react';
import Animate from "../assets/loader.json";
import Select from "react-select";
import ExNews from "../Components/Home/Components/ExNews";
import Language from "../Components/Home/Components/Language";
import Notification from "../Components/Home/Components/Notification";
import Search from "../Components/Home/Components/Search";
// import { Accordion } from 'react-bootstrap';

// import { Fragment } from 'react';

// selector
export default function WinningDetail() {
  const { t } = useTranslation();
  const cookiedata = Cookies.get("i18next");
  const token = Cookies.get("token");
  const [CategorySub, setCategorySub] = useState();
  const [isLoading, setIsLoading] = useState(false);
  // const navigate = useNavigate();

  let getDataCategory = async () => {
    const tokencheck =
      token === undefined
        ? null
        : { Authorization: token, "x-xsrf-token": token };
    setIsLoading(true);
    await fetch(`${window.env.API_URL}/WinningDetailV2`, {
      headers: tokencheck,
    })
      .then((res) => res.json())
      .then((result) => {
        setCategorySub(result);
        setIsLoading(false);

      });
  };
  const numbers = `۰۱۲۳۴۵۶۷۸۹`;
  const convert = (num) => {
    let res = "";
    const str = num ? num.toString() : "";
    for (let c of str) {
      res += numbers.charAt(c);
    }
    return res;
  };

  useEffect(() => {
    getDataCategory();
  }, []);

  return (
    <Fragment>
      <>
        
        <div className="container-fluid mystages over">
          <div className="row">
            <div className="col-sm-8 stages">
              <div className="raceCarParent stages">
                <div className="raceCardHeader1">
                  <div className="row">
                    <div className="col-sm-10">
                     <div style={{
                      display:'flex',
                      justifyContent:'space-between'
                     }}>
                     <h1>{t("Winning Detail")}</h1>
                
                     </div>
                      
                    </div>
                  </div>
                </div>
                {isLoading ? <Lottie
                  animationData={Animate}
                  className="load1"
                /> :
                  CategorySub ? (
                    CategorySub.data.map((singlecompetition, i) =>
                      singlecompetition.CloseCompetitionDataOfRace.length > 0 ? (
                        <Accordion defaultActiveKey="0" className="mobilesitewidth">
                          <Accordion.Item eventKey={i}>
                            <div className="winners">
                              <Accordion.Header>
                                <div className="accordionHeader row">
                                  <div className="headerCard1 carddisplay121 col-sm-6">
                                    <h4>
                                      {cookiedata === "en"
                                        ? singlecompetition.NameEn
                                        : singlecompetition.NameAr}
                                    </h4>
                                  </div>
                                  <div className="headerCard2 imgDiv col-sm-2">
                                    {" "}
                                    <p>
                                      point distibuted :
                                      {singlecompetition
                                        .CloseCompetitionDataOfRace[0]
                                        ? singlecompetition
                                          .CloseCompetitionDataOfRace[0]
                                          .CloseCompetitionTotalPrizeDistiributed
                                        : 0}
                                    </p>
                                  </div>
                                  <div className="headerCard2  imgDiv col-sm-1">
                                    {" "}
                                    <p>
                                      {singlecompetition.CloseCompetitionDataOfRace
                                        ? singlecompetition
                                          .CloseCompetitionDataOfRace.length
                                        : 0}
                                    </p>
                                  </div>
                                  <div className="headerCard2  imgDiv col-sm-2">
                                    <p>
                                      {singlecompetition
                                        ? moment(
                                          singlecompetition.StartDate
                                        ).format("YYYY-MM-DD")
                                        : <>- </>}
                                    </p>
                                  </div>
                                  <div className="headerCard3  col-sm-1">
                                    <i
                                      class="fa fa-chevron-circle-down"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                              </Accordion.Header>

                              <Accordion.Body key="i">
                                <div className="raceCardTable">
                                  <ScrollContainer>
                                    <table className="customers tableHeaderCard">
                                      <thead>
                                        <tr key="i">
                                          <th>{t("Name")}</th>
                                          <th>{t("Rank")}</th>
                                          <th>{t("Point")}</th>
                                          {/* <th>{t("Points")}</th>
                                    <th>{t("Bonus Points")}</th>
                                    <th>{t("Horse Name")}</th>
                                    {/* <th>Horse No</th> */}
                                          {/* <th>{t("Race No")} </th>
                                    <th>{t("Race course")}</th>
                                    <th>{t("Country")}</th>
                                    <th>{t("Race Name")}</th> */}
                                          {/* <th>{t("Race Type")}</th> */}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {singlecompetition.CloseCompetitionDataOfRace ? (
                                          singlecompetition.CloseCompetitionDataOfRace.map(
                                            (Competitiondetail, i) => (
                                              <tr>
                                                {/* <td className='lb'> {Competitiondetail.RaceDataOfCompetition ? Competitiondetail.RaceDataOfCompetition._id : "not aaialable"}</td> */}
                                                <td className="lb">
                                                  {
                                                    Competitiondetail
                                                      .CloseCompetitionSubscriberIdDataOfRace
                                                      .FirstName
                                                  }
                                                </td>
                                                <td className="lb">{i + 1}</td>
                                                <td className="lb">
                                                  {
                                                    Competitiondetail.CloseCompetitionTotalPoint
                                                  }
                                                </td>

                                                {/* {Competitiondetail.RaceDataOfCompetition ? (
                                            <td className="lb">
                                              {" "}
                                              {Competitiondetail
                                                .RaceDataOfCompetition
                                                .CompetitionRaceIDData[0].Rank
                                                ? Competitiondetail
                                                    .RaceDataOfCompetition
                                                    .CompetitionRaceIDData[0]
                                                    .Rank
                                                : 0}
                                            </td>
                                          ) : (
                                            <></>
                                          )}
                                          {Competitiondetail.RaceDataOfCompetition ? (
                                            <td className="lb">
                                              {" "}
                                              {Competitiondetail
                                                .RaceDataOfCompetition
                                                .CompetitionRaceIDData[0]
                                                .Point
                                                ? Competitiondetail
                                                    .RaceDataOfCompetition
                                                    .CompetitionRaceIDData[0]
                                                    .Point
                                                : 0}
                                            </td>
                                          ) : (
                                            <></>
                                          )}
                                          {Competitiondetail.RaceDataOfCompetition ? (
                                            <td className="lb">
                                              {" "}
                                              {Competitiondetail
                                                .RaceDataOfCompetition
                                                .CompetitionRaceIDData[0]
                                                .BonusPoint
                                                ? Competitiondetail
                                                    .RaceDataOfCompetition
                                                    .CompetitionRaceIDData[0]
                                                    .BonusPoint
                                                : 0}
                                            </td>
                                          ) : (
                                            <></>
                                          )}
                                          {Competitiondetail.RaceDataOfCompetition ? (
                                            <td className="lb">
                                              {" "}
                                              {Competitiondetail
                                                .RaceDataOfCompetition
                                                .CompetitionRaceIDData[0] &&
                                              cookiedata === "en" ? (
                                                Competitiondetail
                                                  .RaceDataOfCompetition
                                                  .CompetitionRaceIDData[0] ===
                                                undefined ? (
                                                  <></>
                                                ) : (
                                                  Competitiondetail
                                                    .RaceDataOfCompetition
                                                    .CompetitionRaceIDData[0]
                                                    .CompetitionHorseIDData
                                                    .NameEn
                                                )
                                              ) : Competitiondetail
                                                  .RaceDataOfCompetition
                                                  .CompetitionRaceIDData[0] ===
                                                undefined ? (
                                                <></>
                                              ) : (
                                                Competitiondetail
                                                  .RaceDataOfCompetition
                                                  .CompetitionRaceIDData[0]
                                                  .CompetitionHorseIDData
                                                  .NameAr
                                              )}
                                            </td>
                                          ) : (
                                            <></>
                                          )}

                                          {Competitiondetail.RaceDataOfCompetition ? (
                                            <td className="lb">
                                              {" "}
                                              {Competitiondetail.RaceDataOfCompetition
                                                ? Competitiondetail
                                                    .RaceDataOfCompetition
                                                    .RaceNumber
                                                : "0"}
                                            </td>
                                          ) : (
                                            <></>
                                          )}
                                          <td className="lb">
                                            {cookiedata === "en" ? (
                                              Competitiondetail
                                                .RaceDataOfCompetition
                                                .RaceCourseData ===
                                              undefined ? (
                                                <></>
                                              ) : (
                                                Competitiondetail
                                                  .RaceDataOfCompetition
                                                  .RaceCourseData.TrackNameEn
                                              )
                                            ) : Competitiondetail
                                                .RaceDataOfCompetition
                                                .RaceCourseData ===
                                              undefined ? (
                                              <></>
                                            ) : (
                                              Competitiondetail
                                                .RaceDataOfCompetition
                                                .RaceCourseData.TrackNameAr
                                            )}
                                          </td>
                                          <td className="lb">
                                            {Competitiondetail.RaceDataOfCompetition &&
                                            cookiedata === "en"
                                              ? Competitiondetail
                                                  .RaceDataOfCompetition
                                                  .RaceCourseData
                                                  .NationalityDataRaceCourse
                                                  .NameEn
                                              : Competitiondetail
                                                  .RaceDataOfCompetition
                                                  .RaceCourseData
                                                  .NationalityDataRaceCourse
                                                  .NameAr}
                                          </td>
                                          <td className="lb">
                                            {" "}
                                            {Competitiondetail.RaceDataOfCompetition &&
                                            cookiedata === "en"
                                              ? Competitiondetail
                                                  .RaceDataOfCompetition
                                                  .RaceNameModelData.NameEn
                                              : Competitiondetail
                                                  .RaceDataOfCompetition
                                                  .RaceNameModelData.NameAr}
                                          </td>
                                          <td className="lb">
                                            {" "}
                                            {Competitiondetail.RaceDataOfCompetition &&
                                            cookiedata === "en"
                                              ? Competitiondetail
                                                  .RaceDataOfCompetition
                                                  .RaceTypeModelData.NameEn
                                              : Competitiondetail
                                                  .RaceDataOfCompetition
                                                  .RaceTypeModelData.NameAr}
                                          </td> */}
                                              </tr>
                                            )
                                          )
                                        ) : (
                                          <tr>
                                            <td>No data found</td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </ScrollContainer>
                                </div>
                              </Accordion.Body>
                            </div>
                          </Accordion.Item>
                        </Accordion>
                      ) : (
                        <></>
                      )
                    )
                  ) : (
                    <></>
                  )}
              </div>
            </div>
            <div className="col-sm-4">
              <Justsponsor />
            </div>
          </div>
        </div>

        <Footer />
        <CoptRight />
      </>
    </Fragment>
  );
}
