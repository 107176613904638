import React, { useEffect, useState } from "react";
import moment from "moment";

const RaceCardSiderTimer = ({ timer, starttime }) => {
  const [state, setState] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [Hours, setHours] = useState(0);
  const [styling, setstyling] = useState(false);
  const [showTimer, setShowTimer] = useState(true);

  var d = new Date(starttime);
  let intervalId; // Move the intervalId declaration to the outer scope

  function calculateRemainingTime(inputDate) {
    const parsedDate = moment.utc(inputDate);
    if (!parsedDate.isValid()) {
      setShowTimer(false);
      console.log('Invalid date format. Setting remaining time to 00:00:00');
      return;
    }

    // Convert the parsed date to local time
    const localDate = parsedDate.local();

    // Calculate the initial difference in seconds
    let remainingSeconds = localDate.diff(moment(), 'seconds');

    // Function to update the remaining time every second
    function updateRemainingTime() {
      remainingSeconds -= 1;

      if (remainingSeconds > 0) {
        const hours = Math.floor(remainingSeconds / 3600);
        const minutes = Math.floor((remainingSeconds % 3600) / 60);
        const seconds = remainingSeconds % 60;

        setMinutes(minutes);
        setSeconds(seconds);
        setHours(hours);
      } else {
        clearInterval(intervalId);
      }
    }

    // Call the updateRemainingTime function every second
    intervalId = setInterval(updateRemainingTime, 1000);
  }

  useEffect(() => {
    calculateRemainingTime(starttime);

    // Clear the interval when the component unmounts or when seconds change
    return () => clearInterval(intervalId);
  }, [seconds, starttime]);

  return (
    <div className="racetime">
    {
     Hours<1  ? (
       <p style={{
         display: "flex",
       }}
       className={minutes<5 ? "redclass" : "orangeclass"} >
       {/* {HoursofStarttime.split(":")[0]}:{HoursofStarttime.split(":")[1]} */}
       {Hours < 1 ? `` : `${Hours}:`}{minutes< 10 ? `0${minutes}:` : `${minutes}:`}{seconds < 10 ? `0${seconds}` : `${seconds}`}
     </p>
     ):( 
     <p >
       {/* {HoursofStarttime.split(":")[0]}:{HoursofStarttime.split(":")[1]} */}
       {Hours < 1 ? `` : `${Hours}:`}{minutes< 10 ? `0${minutes}:` : `${minutes}:`}{seconds < 10 ? `0${seconds}` : `${seconds}`}
     </p>)
    }
   </div>
  );
};

export default RaceCardSiderTimer;