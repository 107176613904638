import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getSireHistoryForStatisiticsSlice = createSlice({
    name: 'SireHistoryForStatisitics',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchSireHistoryForStatisitics.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchSireHistoryForStatisitics.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchSireHistoryForStatisitics.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setSireHistoryForStatisitics, setStatus } = getSireHistoryForStatisiticsSlice.actions;
export default getSireHistoryForStatisiticsSlice.reducer;

export const fetchSireHistoryForStatisitics = createAsyncThunk('/SireHistoryForStatisitics/fetch', async ({id , year,
    Nationality,
    RaceCourse,
    GroundType,
    RaceKind,
    HorseKind,
    RaceType,
    TrackLength}) => {
    const res = await axios.get(`${window.env.API_URL}/SireHistoryForStatisiticsV2/${id}?year=${year === undefined ? "" : year}&Nationality=${Nationality === undefined ? "" : Nationality}&RaceCourse=${RaceCourse === undefined ? "" : RaceCourse}&GroundType=${GroundType === undefined ? "" : GroundType}&RaceKind=${RaceKind === undefined ? "" : RaceKind}&HorseKind=${HorseKind === undefined ? "" : HorseKind }&RaceType=${RaceType=== undefined ? "" : RaceType}&TrackLength=${TrackLength === undefined ? "" : TrackLength}`);
    const SireHistoryForStatisiticsData = res.data;
    return SireHistoryForStatisiticsData.data;
})