import "../../CSS/HomeCSS/Competition.css";
import flag from "../../../assets/United Arab Emirates.png";
import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from "react-i18next";
import {
  fetchLeaderBoard,
  STATUSES,
} from "../../../redux/getReducer/getLeader";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import Animate from "../../../assets/loader.json";
import Lottie from "lottie-react";
import noimage from "../../../assets/noimage1.png";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

const LeaderBoard = () => {
  const { t } = useTranslation();
  const { data: LeaderBoard, status } = useSelector(
    (state) => state.LeaderBoard
  );
  const dispatch = useDispatch();
  

  
  useEffect(() => {
    if (LeaderBoard?.length !== 0) {
      console.log(LeaderBoard,'sldr')
    }
    else{
      dispatch(fetchLeaderBoard());
    }
  }, [dispatch]);

  const cookiedata = Cookies.get("i18next");
  if (status === STATUSES.LOADING) {
    return (
      <div>
        <Lottie
          animationData={Animate}
          loop={true}
          className="Lottie compLottie"
        />
      </div>
    );
  }

  if (status === STATUSES.ERROR) {
    return (
      <h2
        style={{
          margin: "100px",
        }}
      >
        Something went wrong!
      </h2>
    );
  }

  // function removeDuplicateIndexes(arr) {
  //   const uniqueMap = new Map();
  //   const result = [];
  
  //   for (const subArray of arr) {
  //     const uniqueSubArray = [];
  //     for (const item of subArray) {
  //       const key = item['SubscriberID'];
  //       if (!uniqueMap.has(key)) {
  //         uniqueMap.set(key, true);
  //         uniqueSubArray.push(item);
  //       }
  //     }
  //     result.push(uniqueSubArray);
  //     uniqueMap.clear();
  //   }
  //   return result;
  // }

  // const uniqueArray = removeDuplicateIndexes(LeaderBoard.map((item) => item.CompetitionIDData));

  return (
    <React.Suspense>
      <div className="Competition">
        <div className="CompetitionHeader">
          <h2>{t("LEADERBOARD")}</h2>
          <img src={flag} alt="" />
        </div>
        <div className="LeaderData">
          {LeaderBoard?.length === 0 ? (
               
           <SkeletonTheme baseColor="#00A6A7" highlightColor="#9DD9DB">
           <p>
             <Skeleton count={3} />
           </p>
         </SkeletonTheme>
          ) : (
            LeaderBoard.map((item, index) => {
              return item.CompetitionIDData &&
                item.CompetitionIDData.length === 0 ? (
                <></>
              ) : (
                <>
                {
                  item.Points && item.Points.length === 0 ? <></> :
                  <Accordion defaultActiveKey="1">
                  <div className="Competitionitem">
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header className="AccordionHeader11">
                        <div className="AccordionHeader">
                          <p className="LeaderCompetition">
                            {cookiedata === "en" ? item.Competition.NameEn : item.Competition.NameAr}
                          </p>
                          <p></p>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        {item.Points ? (
                          item.Points.map((data) => {
                            return (
                              data.PointGained>0?(  <div className="Competition_Matches">
                              <p > 
                                {data ? (
                                  data.FirstName
                                ) : (
                                  <></>
                                )}
                              </p>
                              <p>
                                {data
                                  ? data
                                      .PointGained
                                  : ""}
                              </p>
                            </div>):<></>
                            
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                </Accordion>
                }
                 
                </>
              );
            })
          )}
        </div>
      </div>
    </React.Suspense>
  );
};
export default LeaderBoard;
