import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});


const getHorsemodalstatsSlice = createSlice({
    name: 'horsemodalstats',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchHorsemodalstats.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchHorsemodalstats.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchHorsemodalstats.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setTrainer, setStatus } = getHorsemodalstatsSlice.actions;
export default getHorsemodalstatsSlice.reducer;

export const fetchHorsemodalstats = createAsyncThunk('/HorsemodalForStatisitics/fetch', async ({ id }) => {
    const res = await axios.get(`${window.env.API_URL}/HorseStatsModal/${id}`);
    const trainerstatsData = res.data;
    return trainerstatsData;
})