import React, { useState } from "react";
import flagimg from "../../assets/United Arab Emirates.png";
import banner from "../../assets/RaceCourseBanner.png";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const RaceCourseList = ({ setRacecourse, racecourse }) => {
  const { t } = useTranslation();
  const cookiedata = Cookies.get("i18next");

  // State to track the active element
  const [activeElement, setActiveElement] = useState(null);

  const array = [
    {
      label: "Meydan Racecourse",
      labelAr: "مضمار ميدان",
      value: "option1",
    },
    {
      label: "Abu Dhabi Equestrian Club",
      labelAr: "نادي أبوظبي للفروسية ",
      value: "option2",
    },
    {
      label: "Jebel Ali Racecourse",
      labelAr: "مضمار جبل علي",
      value: "option3",
    },
    {
      label: "Sharjah Equestrian & Racing Club",
      labelAr: "نادي الشارقة للفروسية والسباق",
      value: "option4",
    },
    {
      label: "Al Ain Racecourse",
      labelAr: "مضمار العين",
      value: "option5",
    },
  ];

  // Function to handle click and set the active element
  const handleItemClick = (value) => {
    setRacecourse(value);
    setActiveElement(value);
  };

  return (
    <div className="courseSection">
      <div className="row">
        <div className="col-sm-3">
          <div className="raceCourseCard">
            <div className="cardinner">
              <div className="cardheader">
                <h4> {t("Racecourses")} </h4>
                <img
                  src={flagimg}
                  alt=""
                  style={{
                    width: "50px",
                    marginRight: "10px",
                  }}
                />
              </div>
              {array.map((elem) => {
                return (
                  <div
                    key={elem.value}
                    onClick={() => handleItemClick(elem.value)}
                    className={`cardSec ${activeElement === elem.value ? 'active' : ''}`}
                  >
                    <p>{cookiedata === "en" ? elem.label : elem.labelAr}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="col-sm-9">
          <div>
            <img className="raceCourseBanner" src={banner} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RaceCourseList;
   