import React from "react";
import { useTranslation } from "react-i18next";

const PleaseWait = () => {
  const { t } = useTranslation();
  return (
    <div class="pleaseWait nohistory">
      <p>{t("No History Yet")}</p>
      <span>
        <i></i>
        <i></i>
      </span>
    </div>
  );
};

export default PleaseWait;
