import React from "react";
import Shirt from "../../assets/Frame.png";
const Image = ({ data }) => {
  return (
    <>
      <div className="imgDiv">
        {data.length === 0 ? (
          <>
            <img src={Shirt} alt="" />
          </>
        ) : (
          data &&
          data.map((img) => (
            <img src={img.OwnerSilkColor ? img.OwnerSilkColor : Shirt} alt="" />
          ))
        )}
      </div>
    </>
  );
};

export default Image;
