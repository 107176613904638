import React, { useEffect, useState } from "react";
import "../CSS/competitionModal.css";
import Defaultimg from "../../assets/Frame.png";
import axios from "axios";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const Competition = ({data}) => {
  const [castUpdated, setCastUdated] = useState([]);
  const [AllHorses, setAllHorses] = useState([]);
  const { t } = useTranslation();

  const raceid = data.modaldataCast.racedetail._id;
  const array1 = data.modaldataCast.votedhorse ? data.modaldataCast.votedhorse : [];
  const comid = data.modaldataCast.competitionrace.Competition;
  const castlength = data.modaldataCast.castpoints && data.modaldataCast.castpoints.PointSystemidDataOfCompetition;
  const options = data.modaldataCast.racehorses &&  data.modaldataCast.racehorses.RacehorsesData;



  const filteredHorses = options && options.filter(horse => horse.HorseRunningStatus === true);


  const [loading , setloading] = useState(false)

  const token = Cookies.get("token");

  const cookiedata = Cookies.get("i18next");


  useEffect(() => {
    let data2 = array1.map((item) => item.HorseID)
    setCastUdated(data2)
  },[]);


  let HorseForTheCast =
    filteredHorses === undefined ? (
      <></>
    ) : (
      filteredHorses.map(function (item) {
        return {
          id: item.HorseModelIdData1._id,
          value: item.HorseModelIdData1._id,

          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span
                style={{
                  display: "flex",
                }}
              >
                <img src={item.HorseModelIdData1.image ? item.HorseModelIdData1.image : Defaultimg} height="30px" width="30px" alt="" />
                <p>{item.HorseModelIdData1.NameEn}({item.HorseNo})</p>
              </span>
            </div>
          ),
          isdisabled:
          castUpdated[0] !== item.HorseModelIdData1._id &&
          castUpdated[1] !== item.HorseModelIdData1._id &&
          castUpdated[2] !== item.HorseModelIdData1._id &&
          castUpdated[3] !== item.HorseModelIdData1._id &&
          castUpdated[4] !== item.HorseModelIdData1._id &&
          castUpdated[5] !== item.HorseModelIdData1._id &&
          castUpdated[6] !== item.HorseModelIdData1._id &&
          castUpdated[7] !== item.HorseModelIdData1._id &&
          castUpdated[8] !== item.HorseModelIdData1._id &&
          castUpdated[9] !== item.HorseModelIdData1._id &&
          castUpdated[10] !== item.HorseModelIdData1._id &&
          castUpdated[11] !== item.HorseModelIdData1._id &&
          castUpdated[12] !== item.HorseModelIdData1._id &&
          castUpdated[13] !== item.HorseModelIdData1._id
            ? false
            : true,
        };
      })

    );


  const onoptiohandler = (event, index , id111) => {
   if (AllHorses.length < castlength.length && event !== null) {
      var newHorse = {
        Horse: event.id,
        Rank: index,
      };
      setAllHorses([...AllHorses,newHorse])
      setCastUdated([...castUpdated,event.id]);
    } 
    else if(!event){
      const updatedAllHorse = AllHorses.filter((horse) => horse.Rank !== index);
      const updatedCastUpdated = castUpdated.filter((id, idx) => id !== id111);
      setAllHorses(updatedAllHorse);
      setCastUdated(updatedCastUpdated);
    }
    else {
    }
  };


  const newArray = [];

  array1.forEach(item1 => {
    options.forEach(item2 => {
      if (item1.HorseID === item2.HorseModelIdData1._id) {
        newArray.push({
          "id": item1.HorseID,
          "label": item2.HorseModelIdData1.NameEn,
        });
      }
    });
  });

  const handleDelete = () => {
    const newArray = [];
    setCastUdated([]);


  }  


  const castClick = async () => {
    try {
      setloading(true)
      const response = await axios.post(
        `${window.env.API_URL}/CastVoting/${comid}/${raceid}/1`,
        { AllHorses: AllHorses?.length < 1 ? array1 : AllHorses },
        {
          headers: { Authorization: token, "x-xsrf-token": token },
        }
      );
      setloading(false)
      const msgdata = response.data.message;
      toast(msgdata);
      data.handleCloseCast();

      
    } catch (error) {
      const err = error.response.data.message;
      setloading(false)

      toast(err);
    }
  };



  return (
    <div className="CompetitionModal">
      <div className="CompetitionModalHead">
        <div className="CompetitionName">

          <h2>
          <OverlayTrigger
                          // className="tooltip-btn"
                          overlay={
                            <Tooltip
                              id="tooltip-disabled"
                              // className="tooltip-btn"
                            >
                             {cookiedata === "en"
                              ? data.modaldataCast.racedetail.RaceNameModelData.NameEn
                              : data.modaldataCast.racedetail.RaceNameModelData.NameAr}
                            </Tooltip>
                          }
                        >
                          <span className="d-inline-block">
                            <h2 >
                          {cookiedata === "en"
                          ? data.modaldataCast.racedetail.RaceNameModelData.NameEn
                          : data.modaldataCast.racedetail.RaceNameModelData.NameAr}
                          </h2>
                          </span>
                        </OverlayTrigger>

            
          </h2>
          <h3>{data.modaldataCast.CompetitionCategory}</h3>
        </div>

        {/* <button onClick={handleDelete}>Delete Votes</button> */}

        <div className="CompetitionHorseData1">
          {castlength && castlength.map((item, index) => {
            return (
              <div className="CompetitionHorse">
                <span className="ToggleButton">{item.Rank}</span>
                <span>
                  <Select
                    id="castselect"
                    placeholder={<div>Select Horse</div>}
                    defaultValue={newArray[index]}
                    onChange={event => onoptiohandler(event, item.Rank , HorseForTheCast[index].id  )}
                    options={HorseForTheCast}
                    isClearable={true}
                    isSearchable={true}
                    isOptionDisabled={(option) => option.isdisabled}
                    hideSelectedOptions={true}
                  />
                </span>
              </div>
            );
          })}
    
        </div>
      </div>
      <div className="CompetitionBtn">
        {
          loading ?  <button className="loadcast">{t("SAVE & CLOSE")}</button>:        <button onClick={castClick}>{t("SAVE & CLOSE")}</button>

        }
      </div>
    </div>
  );
};

export default Competition;