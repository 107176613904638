import React, { Fragment, useEffect, useState } from "react";
import Header from "../Components/Reuseable/Header";
import Auth from "../Components/Reuseable/Auth";
import "../../Webiste/Components/CSS/pagesCSS/dashboard.css";
import Search from "../Components/Home/Components/Search";
import Tracker from "../Components/Home/Components/Tracker";
import Notification from "../Components/Home/Components/Notification";
import ExNews from "../Components/Home/Components/ExNews";
import Language from "../Components/Home/Components/Language";
import ScrollContainer from "react-indiana-drag-scroll";
import { useTranslation } from "react-i18next";
import { fetchsingleUser, STATUSES } from "../redux/getReducer/getSingleUser";
import { useDispatch, useSelector } from "react-redux";
import Lottie from "lottie-react";
import Animate from "../assets/loader.json";
import Cookies from "js-cookie";
import Moment from "react-moment";
import { Accordion } from "react-bootstrap";
import { fetchPointStaging } from "../redux/getReducer/getPointStagingSlice";
import axios from "axios";

const MySelection = () => {
  const { t } = useTranslation();
  const cookiedata = Cookies.get("i18next");
  const token = Cookies.get("token");
  const [CategorySub, setCategorySub] = useState();

  const dispatch = useDispatch();
  // const navigate = useNavigate();

  let getDataCategory = async () => {
    const tokencheck =
      token === undefined
        ? null
        : { Authorization: token, "x-xsrf-token": token };

    await fetch(`${window.env.API_URL}/CompetitionStaging`, {
      headers: tokencheck,
    })
      .then((res) => res.json())
      .then((result) => {
        setCategorySub(result.data);
      });
  };

  useEffect(() => {
    getDataCategory();
    alert(CategorySub[0].CompetitionDataOfRace.length)
  }, []);

  return (
    <Fragment>
      {/* <div className="d-flex">
        <Header />
        <Auth />
      </div> */}
      
      <div className="userHeader">
        <h3>{t("Competition Points And Staging")}</h3>
      </div>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "10px",
            }}
          ></div>
          <div className="PointsParent">
            {CategorySub ? (
              CategorySub.map((item, i) => {
                item.CompetitionDataOfRace.length == 0 ? (
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey={i}>
                      <div>
                        <Accordion.Header>
                          <div className="accordionHeader row">
                            <div className="headerCard1 col-sm-12">
                              {" "}
                              <h4>
                                {" "}
                                {cookiedata === "en"
                                  ? item.NameEn
                                  : item.NameAr}
                              </h4>{" "}
                            </div>{" "}
                          </div>
                        </Accordion.Header>
                        {/* <div className="row pointandstagings">
      <div className="col-sm-4">
        <h4>Race Name</h4>
      </div>
      <div className="col-sm-8 d-flex pointShown">
        <h5>Rank</h5>
        <h5>Bonus Point</h5>
        <h5>Points</h5>
   
      </div>
    </div> */}

                        {item.CompetitionDataOfRace &&
                          item.CompetitionDataOfRace.map((item, i) => {
                            return (
                              <Accordion.Body key={i}>
                                <div className="pointTable">
                                  <table>
                                    <thead>
                                      <th>Race Name</th>
                                      <th>Rank </th>
                                      <th>Points</th>
                                      <th>Bonus Points</th>
                                    </thead>

                                    {item.PointTableOfRaceofRace
                                      .PointSystemidDataOfCompetition &&
                                      item.PointTableOfRaceofRace.PointSystemidDataOfCompetition.map(
                                        (item, i) => (
                                          <>
                                            <tbody>
                                              <td>
                                                {item.RaceDataOfCompetition &&
                                                  item.RaceDataOfCompetition
                                                    .NameEn}
                                              </td>
                                              <td>{item.Rank}</td>
                                              <td>{item.BonusPoint}</td>
                                              <td>{item.Point}</td>
                                            </tbody>
                                          </>
                                        )
                                      )}
                                  </table>
                                </div>
                              </Accordion.Body>
                            );
                          })}
                      </div>
                    </Accordion.Item>
                  </Accordion>
                ) : (
                  <></>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MySelection;
