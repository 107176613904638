import axios from 'axios';
const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getCalender = createSlice({
    name: 'CalenderData',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchCalender.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchCalender.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE;
            })
            .addCase(fetchCalender.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            });
    },
});

export const { setRace, setStatus } = getCalender.actions;
export default getCalender.reducer;

export const fetchCalender = createAsyncThunk('RaceCalenderOfToday/fetch', async () => {
    const res = await axios.get(`${window.env.API_URL}/RaceCardCalenderV2`)
    const data = res.data;
    return data.data;
});


