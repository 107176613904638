import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Bar } from "react-chartjs-2";
import Cookies from "js-cookie";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Draw = ({ draw }) => {

  console.log(draw?.data)
  const { t } = useTranslation();
  const { data: ThemeSetting } = useSelector((state) => state.ThemeSetting);

  // const filteredData = data.filter(item => item.Gate !== 100);
  const filteredData = draw?.data?.filter(item => item.Gate < 80) || [];

  const labels = filteredData?.map(item => item.Gate);
  const gateCounts = filteredData?.map(item => item.Gate_Count);
  const winnerGateCounts = filteredData?.map(item => item.winnergatecount);
  
  const cookiedata = Cookies.get("i18next");

  const chartData = {
    labels: labels,
    datasets: [
      // {
      //   label: 'Gate Count',
      //   backgroundColor: ThemeSetting.FourthColor,
      //   borderColor: ThemeSetting.FourthColor,
      //   borderWidth: 1,
      //   hoverBackgroundColor: ThemeSetting.FourthColor,
      //   hoverBorderColor: ThemeSetting.FourthColor,
      //   data: gateCounts,
      // },
      {
        label: 'Winner',
        backgroundColor: ThemeSetting.PrimaryColor,
        borderColor: ThemeSetting.PrimaryColor,
        borderWidth: 1,
        hoverBackgroundColor: ThemeSetting.PrimaryColor,
        hoverBorderColor: ThemeSetting.PrimaryColor,
        data: winnerGateCounts,
      },
    ],
  };

  const chartOptions = {
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
        },
      }],
    },
  };
  return (
    <div>
      <>
           <Bar data={chartData} options={chartOptions} />
          <table className="drawtable">
            <thead className="drawtablehead">
              <tr>
                <th className="pdrawtable">{t("G-N")}</th>
              </tr>
            </thead>
            <tbody className="drawtablebody">
              {cookiedata === "en" ? (
                <>
                  {filteredData &&
                    filteredData?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.Gate}</td>
                        </tr>
                      );
                    })}
                </>
              ) : (
                <>
                  {filteredData &&
                    filteredData?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.Gate}</td>
                          </tr>
                        );
                      })}
                </>
              )}
            </tbody>
          </table>
          <table className="drawtable">
            <thead className="drawtablehead">
              <tr>
                <th className="pdrawtable">{t("W")}</th>
              </tr>
            </thead>
            <tbody className="drawtablebody">
              {filteredData &&
                filteredData?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item?.winnergatecount}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </>
    </div>
  );
};

export default Draw;
