// Firebase Cloud Messaging Configuration File.
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import axios from "axios";
import Cookies from "js-cookie";

var firebaseConfig = {
  apiKey: "AIzaSyA5a_if_wOdeqra8uuD6DsC4QS9x04TALE",
  authDomain: "mksracing-87bea.firebaseapp.com",
  projectId: "mksracing-87bea",
  storageBucket: "mksracing-87bea.appspot.com",
  messagingSenderId: "942994803396",
  appId: "1:942994803396:web:c3376a97a3935c3d589791",
};

initializeApp(firebaseConfig);

const messaging = getMessaging();
const token = Cookies.get("token")

export const requestForToken = () => {


  return getToken(messaging, {
    vapidKey: `BBGzJmcCTPzBE7zg3Ecwqo0KACAqkmCc3OWXU99PIgwKjTql5MoshaZuF2LZf84CllyKSQh96IU7oMjA6NUGDUI`,
  })

    .then((currentToken) => {
      if (currentToken) {
        try {
             axios.put(`${window.env.API_URL}/DeviceToken`,  { DeviceToken: currentToken },
            {
              headers: { Authorization: token, "x-xsrf-token": token },
            });
            } catch (error) {
                console.log(error,'error')
            }
        
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

