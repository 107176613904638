import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});


const getJockeyEntriesSlice = createSlice({
    name: 'entriesjockey',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchJockeyEntries.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchJockeyEntries.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchJockeyEntries.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setTrainer, setStatus } = getJockeyEntriesSlice.actions;
export default getJockeyEntriesSlice.reducer;

export const fetchJockeyEntries = createAsyncThunk('/FutureEntriesWithJockey/fetch', async ({ id }) => {
    const res = await axios.get(`${window.env.API_URL}/FutureEntriesWithJockey/${id}`);
    const JockeyEntriesData = res.data;
    return JockeyEntriesData.data;
})