import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../Components/CSS/RaceCardCSS/racecard.css";
import Layout from "../Components/Reuseable/layout";
import Footer from "../Components/Reuseable/Footer";
import CopyRight from "../Components/Reuseable/Copyrights";
import "../Components/CSS/pagesCSS/stats.css";
import Animate from "../assets/loader.json";
import Lottie from "lottie-react";
import Cookies from "js-cookie";
import Moment from "react-moment";
import moment from "moment";
import Pagination from "./Pagination";
import Select from "react-select";
import {
  fetchResultDates,
} from "../../Webiste/redux//getQueryReducer/getResultDates";
import { fetchResultHorseId } from "../../Webiste/redux//getQueryReducer/getResultHorseId";
import { fetchResultJockey } from "../../Webiste/redux//getQueryReducer/getResultJockey";
import { fetchResultNationality } from "../../Webiste/redux//getQueryReducer/getResultNationality";
import { fetchResultOwner } from "../../Webiste/redux//getQueryReducer/getResultOwner";
import { fetchResultRaceCourse } from "../../Webiste/redux//getQueryReducer/getResultRaceCourse";
import { fetchResultRaceGround } from "../../Webiste/redux//getQueryReducer/getResultRaceGround";
import { fetchResultRaceTracklength } from "../../Webiste/redux//getQueryReducer/getResultRaceTracklength";
import { fetchResultRaceType } from "../../Webiste/redux//getQueryReducer/getResultRaceType";
import { fetchResultTrainer } from "../../Webiste/redux//getQueryReducer/getResultTrainer";
import { fetchResultYearDropdown } from "../../Webiste/redux//getQueryReducer/getResultYearDropdown";
import { fetchResultMeeting } from "../../Webiste/redux//getQueryReducer/getResultMeeting";
import { fetchQueryTool, STATUSES } from "../../Webiste/redux//getQueryReducer/getMainQueryTool";
import { fetchResultSponsor } from "../../Webiste/redux//getQueryReducer/getResultSponsor";
import { fetchHorseKindDropDown } from "../../Webiste/redux//getQueryReducer/getResultHorseKind";
import HorseModal from '../Components/Modal/HorseModal'
import JockeyModal from '../Components/Modal/JockeyModal'
import TrainerModal from '../Components/Modal/TrainerModal';
import RaceModal from "../Components/Modal/RaceModal";
import { fetchSponsor } from "../redux/getReducer/getSponsorSlice"

import Carousel from "react-bootstrap/Carousel";

import DefaultImg from "../assets/default.png";
import { Modal } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import { AiOutlineBars } from "react-icons/ai";
import { FaBars } from "react-icons/fa";

const Stats = () => {
  const dispatch = useDispatch();
  const cookiedata = Cookies.get("i18next");
  const { data: ResultYearDropdown } = useSelector(
    (state) => state.ResultYearDropdown
  );
  const { data: ResultDates } = useSelector((state) => state.ResultDates);
  const { data: ResultRaceCourse } = useSelector(
    (state) => state.ResultRaceCourse
  );
  const { data: ResultRaceType } = useSelector((state) => state.ResultRaceType);

  const { data: ResultNationality } = useSelector(
    (state) => state.ResultNationality
  );
  const { data: ResultRaceGround } = useSelector(
    (state) => state.ResultRaceGround
  );
  const { data: ResultRaceTracklength } = useSelector(
    (state) => state.ResultRaceTracklength
  );



  const { data: sponsor } = useSelector((state) => state.sponsor);

  useEffect(() => {
    dispatch(fetchSponsor());
  }, [dispatch]);



  const [userIsDesktop, setUserIsDesktop] = useState(true);
  useEffect(() => {
    window.innerWidth > 840 ? setUserIsDesktop(true) : setUserIsDesktop(false);
  }, [userIsDesktop]);

  console.log(userIsDesktop,'userIsDesktop')





  const { data: ResultHorseId } = useSelector((state) => state.ResultHorseId);
  const { data: ResultTrainer } = useSelector((state) => state.ResultTrainer);
  const { data: ResultMeeting } = useSelector((state) => state.ResultMeeting);
  const { data: ResultJockey } = useSelector((state) => state.ResultJockey);
  const { data: ResultOwner } = useSelector((state) => state.ResultOwner);
  const { data: QueryTool, status } = useSelector((state) => state.QueryTool);
  const { data: ResultSponsor } = useSelector((state) => state.ResultSponsor);
  const { data: HorseKindDropDown } = useSelector((state) => state.HorseKindDropDown);

  const [ResultYearData, setResultYearData] = useState("");
  const [ResultDatesData, setResultDates] = useState("");
  const [ResultRaceCourseData, setResultRaceCourse] = useState("");
  const [ResultRaceTypeData, setResultRaceType] = useState("");
  const [ResultNationalityData, setResultNationality] = useState("");
  const [ResultRaceGroundData, setResultRaceGround] = useState("");
  const [ResultRaceTracklengthData, setResultRaceTracklength] = useState("");
  const [ResultHorseIdData, setResultHorseId] = useState("");
  const [ResultTrainerData, setResultTrainer] = useState("");
  const [ResultMeetingData, setResultMeeting] = useState("");
  const [ResultJockeyData, setResultJockey] = useState("");
  const [ResultOwnerData, setResultOwner] = useState("");
  const [ResultSponsorData, setResultSponsor] = useState("");
  const [ResultHorseKindData, setResultHorseKind] = useState("");

  const { t } = useTranslation();
  let totalcountdata = QueryTool ? QueryTool.totalcount : '';
  let totalpagesdata = QueryTool.totalcount;
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(15);
  const [TotalCount, setTotalCount] = useState(100);
  const [TotalPages, setTotalPages] = useState(9);


  const [Value1, setValue1] = useState("");
  const [Value2, setValue2] = useState("");
  const [Value3, setValue3] = useState("");
  const [Value4, setValue4] = useState("");
  const [Value5, setValue5] = useState("");
  const [Value6, setValue6] = useState("");
  const [Value7, setValue7] = useState("");
  const [Value8, setValue8] = useState("");
  const [Value9, setValue9] = useState("");
  const [Value10, setValue10] = useState("");
  const [Value11, setValue11] = useState("");
  const [Value12, setValue12] = useState("");
  const [Value13, setValue13] = useState("");

  const [IncreasePage, setIncreasePage] = useState(16);

  const [IncreasePage1, setIncreasePage1] = useState(16);

  const [IncreasePage2, setIncreasePage2] = useState(16);
  const [IncreasePage3, setIncreasePage3] = useState(16);
  const [IncreasePage4, setIncreasePage4] = useState(16);
  const [IncreasePage5, setIncreasePage5] = useState(16);
  const [IncreasePage6, setIncreasePage6] = useState(16);
  const [IncreasePage7, setIncreasePage7] = useState(16);
  const [IncreasePage8, setIncreasePage8] = useState(16);
  const [IncreasePage9, setIncreasePage9] = useState(16);
  const [IncreasePage10, setIncreasePage10] = useState(16);
  const [IncreasePage11, setIncreasePage11] = useState(16);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  
  const [showRace, setShowRace] = useState(false);
  const [modaldataRace, setmodaldataRace] = useState();

  const handleCloseRace = () => setShowRace(false);
  const handleShowRace = async (data) => {
    setmodaldataRace(data);
    await setShowRace(true);
  };




  const [show, setShow] = useState(false);
  const [modaldata, setmodaldata] = useState();

  const handleClose = () => setShow(false);
  const handleShow = async (data) => {
    setmodaldata(data);
    await setShow(true);
  };



  const [showJockey, setShowJockey] = useState(false);
  const [modaldataJockey, setmodaldataJockey] = useState();

  const handleCloseJockey = () => setShowJockey(false);
  const handleShowJockey = async (data) => {
    setmodaldataJockey(data);
    await setShowJockey(true);
  };



  const [showTrainer, setShowTrainer] = useState(false);
  const [modaldataTrainer, setmodaldataTrainer] = useState();

  const handleCloseTrainer = () => setShowTrainer(false);
  const handleShowTrainer = async (data) => {
    setmodaldataTrainer(data);
    await setShowTrainer(true);
  };

  const [showQuery, setShowQuery] = useState(false);



  const handlequery = () => {
    setShowQuery(!showQuery)
  }


  let uniqueTrackLengths = {};

// Create a new array to store items with unique TrackLength values
let filteredData = [];

// Iterate through the original array
for (let item of ResultRaceTracklength) {
  let trackLength = item["TrackLength"];
  
  // Check if the TrackLength value is not already in the uniqueTrackLengths object
  if (!uniqueTrackLengths[trackLength]) {
    // Add the TrackLength value to the uniqueTrackLengths object and add the item to the filteredData array
    uniqueTrackLengths[trackLength] = true;
    filteredData.push(item);
  }
}







  let RaceCourseData = ResultRaceCourse.map(function (item) {
    return {
      id: item._id,
      value: item.TrackNameEn,
      label: item.TrackNameEn,
    };
  });

  let RaceHorseKindDropDown = HorseKindDropDown.map(function (item) {
    return {
      id: item._id,
      value: item.NameEn,
      label: item.NameEn,
    };
  });

  let RaceHorseKindDropDownAr = HorseKindDropDown.map(function (item) {
    return {
      id: item._id,
      value: item.NameAr,
      label: item.NameAr,
    };
  });


  let RaceCourseDataAr = ResultRaceCourse.map(function (item) {
    return {
      id: item._id,
      value: item.TrackNameAr,
      label: item.TrackNameAr,
    };
  });


  let RaceDateYearData = ResultYearDropdown.map(function (item) {
    return {
      id: item._id,
      value: item.ResultYear,
      label: item.ResultYear,
    };
  });

  let RaceDateData = ResultDates.map(function (item) {
    return {
      id: item._id,
      value: item.ResultYear,
      label: item.ResultYear,
    };
  });

  let RaceCountryDropdown = ResultNationality.map(function (item) {
    return {
      id: item._id,
      value: item.NameEn,
      label: item.NameEn,
    };
  });


  let RaceCountryDropdownAr = ResultNationality.map(function (item) {
    return {
      id: item._id,
      value: item.NameAr,
      label: item.NameAr,
    };
  });


  let RaceTypeDropdown = ResultRaceType.map(function (item) {
    return {
      id: item._id,
      value: item.NameEn,
      label: item.NameEn,
    };
  });


  let RaceTypeDropdownAr = ResultRaceType.map(function (item) {
    return {
      id: item._id,
      value: item.NameAr,
      label: item.NameAr,
    };
  });



  let RaceMeetingDropdown = ResultMeeting.map(function (item) {
    return {
      id: item._id,
      value: item.NameEn,
      label: item.NameEn,
    };
  });

  let RaceMeetingDropdownAr = ResultMeeting.map(function (item) {
    return {
      id: item._id,
      value: item.NameAr,
      label: item.NameAr,
    };
  });


  let RaceGroundDropdown = ResultRaceGround.map(function (item) {
    return {
      id: item._id,
      value: item.NameEn,
      label: item.NameEn,
    };
  });


  let RaceGroundDropdownAr = ResultRaceGround.map(function (item) {
    return {
      id: item._id,
      value: item.NameAr,
      label: item.NameAr,
    };
  });

  let RaceTrackDropdown = filteredData && filteredData?.map(function (item) {
    return {
      id: item._id,
      value: item.TrackLength,
      label: item.TrackLength,
    };
  });

  let RaceHorseDropdown = ResultHorseId.map(function (item) {
    return {
      id: item._id,
      value: item.NameEn,
      label: item.NameEn,
    };
  });


  let RaceHorseDropdownAr = ResultHorseId.map(function (item) {
    return {
      id: item._id,
      value: item.NameAr,
      label: item.NameAr,
    };
  });

  let RaceJockeyDropdown = ResultJockey.map(function (item) {
    return {
      id: item._id,
      value: item.NameEn,
      label: item.NameEn,
    };
  });
  let RaceJockeyDropdownAr = ResultJockey.map(function (item) {
    return {
      id: item._id,
      value: item.NameAr,
      label: item.NameAr,
    };
  });

  let RaceTrainerDropdown = ResultTrainer.data && ResultTrainer.data.map(function (item) {
    return {
      id: item._id,
      value: item.NameEn,
      label: item.NameEn,
    };
  });

  let RaceTrainerDropdownAr = ResultTrainer.data && ResultTrainer.data.map(function (item) {
    return {
      id: item._id,
      value: item.NameAr,
      label: item.NameAr,
    };
  });



  let RaceOwnerDropdown = ResultOwner.map(function (item) {
    return {
      id: item._id,
      value: item.NameEn,
      label: item.NameEn,
    };
  });


  let RaceOwnerDropdownAr = ResultOwner.map(function (item) {
    return {
      id: item._id,
      value: item.NameAr,
      label: item.NameAr,
    };
  });


  let RaceSponsorDropdown = ResultSponsor.map(function (item) {
    return {
      id: item._id,
      value: item.TitleEn,
      label: item.TitleEn,
    };
  });


  let RaceSponsorDropdownAr = ResultSponsor.map(function (item) {
    return {
      id: item._id,
      value: item.TitleAr,
      label: item.TitleAr,
    };
  });

  let data2 = ResultHorseIdData ? (ResultHorseIdData.id === undefined ? '' : ResultHorseIdData.id) : "";
  let data3 = ResultTrainerData ? (ResultTrainerData.id === undefined ? '' : ResultTrainerData.id) : "";
  let data5 = ResultRaceCourseData ? (ResultRaceCourseData.id === undefined ? '' : ResultRaceCourseData.id) : "";
  let data4 = ResultJockeyData ? (ResultJockeyData.id === undefined ? '' : ResultJockeyData.id) : "";
  let data1 = ResultDatesData ? (ResultDatesData.value === undefined ? '' : ResultDatesData.value) : "";
  let data6 = ResultNationalityData ? (ResultNationalityData.id === undefined ? '' : ResultNationalityData.id) : "";
  let data7 = ResultRaceTypeData ? (ResultRaceTypeData.id === undefined ? '' : ResultRaceTypeData.id) : "";
  let data8 = ResultRaceGroundData ? (ResultRaceGroundData.id === undefined ? '' : ResultRaceGroundData.id) : "";
  let data9 = ResultRaceTracklengthData ? (ResultRaceTracklengthData.id === undefined ? '' : ResultRaceTracklengthData.id) : "";
  let data10 = ResultOwnerData ? (ResultOwnerData.id === undefined ? '' : ResultOwnerData.id) : "";
  let data11 = ResultMeetingData ? (ResultMeetingData.id === undefined ? '' : ResultMeetingData.id) : "";
  let data12 = ResultSponsorData ? (ResultSponsorData.id === undefined ? '' : ResultSponsorData.id) : "";
  let data13 = ResultHorseKindData ? (ResultHorseKindData.id === undefined ? '' : ResultHorseKindData.id) : "";
  let data14 =ResultYearData ? (ResultYearData.value === undefined ? '' : ResultYearData.value) : "";

  useEffect(() => {
    dispatch(fetchResultDates({ Value1 ,
      data1,
      data2,
      data3,
      data4,
      data5,
      data6,
      data7,
      data8,
      data9,
      data10,
      data11,
      data12,
      data13,
      data14,}));

  }, [dispatch, Value1,   data1,
    data2,
    data3,
    data4,
    data5,
    data6,
    data7,
    data8,
    data9,
    data10,
    data11,
    data12,
    data13,
    data14,]);


  useEffect(() => {
    dispatch(fetchResultHorseId({ Value9,  data1,
      data2,
      data3,
      data4,
      data5,
      data6,
      data7,
      data8,
      data9,
      data10,
      data11,
      data12,
      data13,
      data14, IncreasePage7 }));

  }, [IncreasePage7, Value9, 
     data1,
    data2,
    data3,
    data4,
    data5,
    data6,
    data7,
    data8,
    data9,
    data10,
    data11,
    data12,
    data13,
    data14, dispatch])

  useEffect(() => {
    dispatch(fetchResultTrainer({ Value11, data1,
      data2,
      data3,
      data4,
      data5,
      data6,
      data7,
      data8,
      data9,
      data10,
      data11,
      data12,
      data13,
      data14, IncreasePage9 }));

  }, [dispatch, Value11, data1,
    data2,
    data3,
    data4,
    data5,
    data6,
    data7,
    data8,
    data9,
    data10,
    data11,
    data12,
    data13,
    data14, IncreasePage9]);

  useEffect(() => {
    dispatch(fetchResultJockey({ Value10,  data1,
      data2,
      data3,
      data4,
      data5,
      data6,
      data7,
      data8,
      data9,
      data10,
      data11,
      data12,
      data13,
      data14,IncreasePage8 }));

  }, [dispatch, Value10, data1,
    data2,
    data3,
    data4,
    data5,
    data6,
    data7,
    data8,
    data9,
    data10,
    data11,
    data12,
    data13,
    data14, IncreasePage8]);

  useEffect(() => {
    dispatch(fetchResultNationality({ Value4, data1,
      data2,
      data3,
      data4,
      data5,
      data6,
      data7,
      data8,
      data9,
      data10,
      data11,
      data12,
      data13,
      data14, IncreasePage2 }));

  }, [dispatch, Value4, data1,
    data2,
    data3,
    data4,
    data5,
    data6,
    data7,
    data8,
    data9,
    data10,
    data11,
    data12,
    data13,
    data14, IncreasePage2]);

  useEffect(() => {
    dispatch(fetchResultRaceCourse({ Value3, 
      data1,
      data2,
      data3,
      data4,
      data5,
      data6,
      data7,
      data8,
      // data9,
      data10,
      data11,
      data12,
      data13,
      data14,IncreasePage }));

  }, [dispatch, Value3, data1,
    data2,
    data3,
    data4,
    data5,
    data6,
    data7,
    data8,
    data9,
    data10,
    data11,
    data12,
    data13,
    data14, IncreasePage]);

  useEffect(() => {
    dispatch(fetchResultRaceGround({ Value7, data1,
      data2,
      data3,
      data4,
      data5,
      data6,
      data7,
      data8,
      data9,
      data10,
      data11,
      data12,
      data13,
      data14, IncreasePage5 }));

  }, [dispatch, Value7, data1,
    data2,
    data3,
    data4,
    data5,
    data6,
    data7,
    data8,
    data9,
    data10,
    data11,
    data12,
    data13,
    data14, IncreasePage5]);

  useEffect(() => {
    dispatch(fetchResultRaceTracklength({ Value8, data1,
      data2,
      data3,
      data4,
      data5,
      data6,
      data7,
      data8,
      data9,
      data10,
      data11,
      data12,
      data13,
      data14, IncreasePage6 }));

  }, [dispatch, Value8, data1,
    data2,
    data3,
    data4,
    data5,
    data6,
    data7,
    data8,
    data9,
    data10,
    data11,
    data12,
    data13,
    data14, IncreasePage6]);

  useEffect(() => {
    dispatch(fetchResultRaceType({ Value5, data1,
      data2,
      data3,
      data4,
      data5,
      data6,
      data7,
      data8,
      data9,
      data10,
      data11,
      data12,
      data13,
      data14, IncreasePage3 }));

  }, [dispatch, Value5, data1,
    data2,
    data3,
    data4,
    data5,
    data6,
    data7,
    data8,
    data9,
    data10,
    data11,
    data12,
    data13,
    data14, IncreasePage3]);

  useEffect(() => {
    dispatch(fetchResultYearDropdown({ Value2,
       data1,
      data2,
      data3,
      data4,
      data5,
      data6,
      data7,
      data8,
      data9,
      data10,
      data11,
      data12,
      data13,
      data14, IncreasePage1 }));

  }, [dispatch, Value2, data1,
    data2,
    data3,
    data4,
    data5,
    data6,
    data7,
    data8,
    data9,
    data10,
    data11,
    data12,
    data13,
    data14, IncreasePage1]);

  useEffect(() => {
    dispatch(fetchResultMeeting({ Value6, data1,
      data2,
      data3,
      data4,
      data5,
      data6,
      data7,
      data8,
      data9,
      data10,
      data11,
      data12,
      data13,
      data14, IncreasePage4 }));

  }, [dispatch, Value6, data1,
    data2,
    data3,
    data4,
    data5,
    data6,
    data7,
    data8,
    data9,
    data10,
    data11,
    data12,
    data13,
    data14, IncreasePage4]);

  useEffect(() => {
    dispatch(fetchResultOwner({ Value12, data1,
      data2,
      data3,
      data4,
      data5,
      data6,
      data7,
      data8,
      data9,
      data10,
      data11,
      data12,
      data13,
      data14, IncreasePage10 }));

  }, [dispatch, Value12, data1,
    data2,
    data3,
    data4,
    data5,
    data6,
    data7,
    data8,
    data9,
    data10,
    data11,
    data12,
    data13,
    data14, IncreasePage10]);


  useEffect(() => {
    dispatch(fetchResultSponsor({ Value13, data1,
      data2,
      data3,
      data4,
      data5,
      data6,
      data7,
      data8,
      data9,
      data10,
      data11,
      data12,
      data13,
      data14, IncreasePage11 }));

  }, [dispatch, Value13, data1,
    data2,
    data3,
    data4,
    data5,
    data6,
    data7,
    data8,
    data9,
    data10,
    data11,
    data12,
    data13,
    data14, IncreasePage11]);

  const handleOnScrollBottom = () => {
    setIncreasePage(IncreasePage + 12)
    dispatch(fetchResultRaceCourse({ Value3, IncreasePage }));
  };

  const handleOnScrollBottom1 = () => {
    setIncreasePage1(IncreasePage1 + 12)
    dispatch(fetchResultYearDropdown({ Value2, IncreasePage1 }));
  };

  const handleOnScrollBottom2 = () => {
    setIncreasePage2(IncreasePage2 + 12)
    dispatch(fetchResultNationality({ Value4, IncreasePage2 }));
  };
  const handleOnScrollBottom3 = () => {
    setIncreasePage3(IncreasePage3 + 12)
    dispatch(fetchResultRaceType({ Value5, IncreasePage3 }));
  };
  const handleOnScrollBottom4 = () => {
    setIncreasePage4(IncreasePage4 + 12)
    dispatch(fetchResultMeeting({ Value6, IncreasePage4 }));
  };
  const handleOnScrollBottom5 = () => {
    setIncreasePage5(IncreasePage5 + 12)
    dispatch(fetchResultRaceGround({ Value7, IncreasePage5 }));
  };

  const handleOnScrollBottom6 = () => {
    setIncreasePage6(IncreasePage6 + 12)
    dispatch(fetchResultRaceTracklength({ Value8, IncreasePage6 }));
  };
  const handleOnScrollBottom7 = () => {
    setIncreasePage7(IncreasePage7 + 12)
    dispatch(fetchResultHorseId({ Value9, IncreasePage7 }));
  };

  const handleOnScrollBottom8 = () => {
    setIncreasePage8(IncreasePage8 + 12)
    dispatch(fetchResultJockey({ Value10, IncreasePage8 }));
  };

  const handleOnScrollBottom9 = () => {
    if (ResultTrainer.totalcount > IncreasePage9) {
      setIncreasePage9(IncreasePage9 + 12)
      dispatch(fetchResultTrainer({ Value11, IncreasePage9 }));
    }
    else {

    }

  };
  const handleOnScrollBottom10 = () => {
    setIncreasePage10(IncreasePage10 + 12)
    dispatch(fetchResultOwner({ Value12, IncreasePage10 }));
  };
  const handleOnScrollBottom11 = () => {
    setIncreasePage11(IncreasePage11 + 12)
    dispatch(fetchResultSponsor({ Value13, IncreasePage11 }));
  };





  useEffect(() => {
    dispatch(fetchHorseKindDropDown());
    dispatch(
      fetchQueryTool({
        data1,
        data2,
        data3,
        data4,
        data5,
        data6,
        data7,
        data8,
        data9,
        data10,
        data11,
        data12,
        data13,
        data14,
        currentPage,
      })

    );
   
    setTotalCount(totalcountdata)
  }, [dispatch, currentPage,  data1,
    data2,
    data3,
    data4,
    data5,
    data6,
    data7,
    data8,
    data9,
    data10,
    data11,
    data12,
    data13,
    data14,]);



 


  const GenerateReport = () => {
    dispatch(
      fetchQueryTool({
        data1,
        data2,
        data3,
        data4,
        data5,
        data6,
        data7,
        data8,
        data9,
        data10,
        data11,
        data12,
        data13,
        data14,
        currentPage
      })
    );
  };

  const ClearAll = () => {
    setResultYearData("")
    setResultDates("")
    setResultRaceCourse("")
    setResultRaceType("")
    setResultNationality("")
    setResultRaceGround("")
    setResultRaceTracklength("")
    setResultTrainer("")
    setResultMeeting("")
    setResultJockey("")
    setResultOwner("")
    setResultSponsor("")
    setResultHorseKind("")
    setResultHorseId("")
  }

  const handleChangeInput = (value) => {
    setValue1(value);
  };

  const handleChangeInput1 = (value) => {
    setValue2(value);
  };
  const handleChangeInput2 = (value) => {
    setValue3(value);
  };
  const handleChangeInput3 = (value) => {
    setValue4(value);
  };
  const handleChangeInput4 = (value) => {
    setValue5(value);
  };
  const handleChangeInput5 = (value) => {
    setValue6(value);
  };
  const handleChangeInput6 = (value) => {
    setValue7(value);
  };
  const handleChangeInput7 = (value) => {
    setValue8(value);
  };
  const handleChangeInput8 = (value) => {
    setValue9(value);
  };
  const handleChangeInput9 = (value) => {
    setValue10(value);
  };
  const handleChangeInput10 = (value) => {
    setValue11(value);
  };
  const handleChangeInput11 = (value) => {
    setValue12(value);
  };

  const handleChangeInput13 = (value) => {
    setValue13(value);
  };

  function formatAMPM(date) {
    const data1 = new Date(date);
    var hours = data1.getHours();
    var minutes = data1.getMinutes();
    var ampm = hours >= 12 ? (cookiedata === "en" ?  "pm" :  "مساءً" ) :  (cookiedata === "en" ?  "am" :  "صباحا " );
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }


  return (
    <>
      <div className="querypage">
        <div className="queryheader">

          <AiOutlineBars onClick={handlequery} className="querypageicon"/>
          {
            showQuery || userIsDesktop ? <div className="leftquery">
            <button className="GenerateReport" onClick={GenerateReport}>
              {t("Generate Report")}
            </button>
            <p className="resetrool" onClick={ClearAll}>
              {t("Reset All")}
            </p>
              <div className="qfilter1">
              <Select
              id="selectNow"
              placeholder={t("Year")}
              className="dropdown multidropdown"
              onChange={setResultYearData}
              options={RaceDateYearData}
              isSearchable={true}
              isClearable={true}
              hideSelectedOptions={true}
              onInputChange={handleChangeInput}
              value={ResultYearData}
              name="Year"
              onMenuScrollToBottom={(e) => handleOnScrollBottom1(e)}

            />

            <Select
              id="selectNow"
              placeholder={t("Date")}
              className="dropdown multidropdown"
              onChange={setResultDates}
              options={RaceDateData}
              isSearchable={true}
              isClearable={true}

              hideSelectedOptions={true}
              onInputChange={handleChangeInput1}
              value={ResultDatesData}
              name="Date"
            />

            <Select
              id="selectNow"
              placeholder={t("Race Course")}
              className="dropdown multidropdown"
              onChange={setResultRaceCourse}
              options={cookiedata === "en" ? RaceCourseData : RaceCourseDataAr}
              isSearchable={true}
              isClearable={true}

              hideSelectedOptions={true}
              onInputChange={handleChangeInput2}
              value={ResultRaceCourseData}
              name="Course"
              onMenuScrollToBottom={(e) => handleOnScrollBottom(e)}
            // onMenuScrollToTop={(e) => handleOnScrollTop(e)}

            // components={{ Menu: CustomMenu }}

            />


            <Select
              id="selectNow"
              placeholder={t("Nationality")}
              className="dropdown multidropdown"
              onChange={setResultNationality}
              options={cookiedata === "en" ? RaceCountryDropdown : RaceCountryDropdownAr}
              isSearchable={true}
              isClearable={true}

              hideSelectedOptions={true}
              onInputChange={handleChangeInput3}
              value={ResultNationalityData}
              name="ResultNationality"
              onMenuScrollToBottom={(e) => handleOnScrollBottom2(e)}
            />

            <Select
              id="selectNow"
              placeholder={t("Race Type")}
              className="dropdown multidropdown"
              onChange={setResultRaceType}
              options={cookiedata === "en" ? RaceTypeDropdown : RaceTypeDropdownAr}
              isSearchable={true}
              isClearable={true}

              hideSelectedOptions={true}
              onInputChange={handleChangeInput4}
              value={ResultRaceTypeData}
              name="ResultNationality"
              onMenuScrollToBottom={(e) => handleOnScrollBottom3(e)}
            />

            <Select
              id="selectNow"
              placeholder={t("Meeting Type")}
              className="dropdown multidropdown"
              onChange={setResultMeeting}
              options={cookiedata === "en" ? RaceMeetingDropdown : RaceMeetingDropdownAr}
              isSearchable={true}
              isClearable={true}

              hideSelectedOptions={true}
              onInputChange={handleChangeInput5}
              value={ResultMeetingData}
              name="Meeting"
              onMenuScrollToBottom={(e) => handleOnScrollBottom4(e)}
            />
            <Select
              id="selectNow"
              placeholder={t("Ground Type")}
              className="dropdown multidropdown"
              onChange={setResultRaceGround}
              options={cookiedata === "en" ? RaceGroundDropdown : RaceGroundDropdownAr}
              isSearchable={true}
              isClearable={true}

              hideSelectedOptions={true}
              onInputChange={handleChangeInput6}
              value={ResultRaceGroundData}
              name="Ground"
              onMenuScrollToBottom={(e) => handleOnScrollBottom5(e)}
            />
            <Select
              id="selectNow"
              placeholder={t("KindOfHorse")}
              className="dropdown multidropdown"
              onChange={setResultHorseKind}
              options={cookiedata === "en" ? RaceHorseKindDropDown : RaceHorseKindDropDownAr}

              isSearchable={true}
              isClearable={true}

              hideSelectedOptions={true}
              // onInputChange={handleChangeInput}
              value={ResultHorseKindData}
              name="Year"
            // onMenuScrollToBottom={(e) => handleOnScrollBottom1(e)}

            />


            <Select
              id="selectNow"
              placeholder={t("Track length")}
              className="dropdown multidropdown"
              onChange={setResultRaceTracklength}
              options={RaceTrackDropdown}
              isSearchable={true}
              isClearable={true}

              hideSelectedOptions={true}
              onInputChange={handleChangeInput7}
              value={ResultRaceTracklengthData}
              name="Track"
              onMenuScrollToBottom={(e) => handleOnScrollBottom6(e)}
            />

            <Select
              id="selectNow"
              placeholder={t("Horse")}
              className="dropdown multidropdown"
              onChange={setResultHorseId}
              options={cookiedata === "en" ? RaceHorseDropdown : RaceHorseDropdownAr}
              isSearchable={true}
              isClearable={true}

              hideSelectedOptions={true}
              onInputChange={handleChangeInput8}
              value={ResultHorseIdData}
              name="Horse"
              onMenuScrollToBottom={(e) => handleOnScrollBottom7(e)}
            />

            <Select
              id="selectNow"
              placeholder={t("Jockey")}
              className="dropdown multidropdown"
              onChange={setResultJockey}
              options={cookiedata === "en" ? RaceJockeyDropdown : RaceJockeyDropdownAr}
              isSearchable={true}
              isClearable={true}

              hideSelectedOptions={true}
              onInputChange={handleChangeInput9}
              value={ResultJockeyData}
              name="Jockey"
              onMenuScrollToBottom={(e) => handleOnScrollBottom8(e)}
            />




            <Select
              id="selectNow"
              placeholder={t("Trainer")}
              className="dropdown multidropdown"
              onChange={setResultTrainer}
              options={cookiedata === "en" ? RaceTrainerDropdown : RaceTrainerDropdownAr}
              isSearchable={true}
              isClearable={true}

              hideSelectedOptions={true}
              onInputChange={handleChangeInput10}
              value={ResultTrainerData}
              name="Trainer"
              onMenuScrollToBottom={(e) => handleOnScrollBottom9(e)}
            />

            <Select
              id="selectNow"
              placeholder={t("Owner")}
              className="dropdown multidropdown"
              onChange={setResultOwner}
              options={cookiedata === "en" ? RaceOwnerDropdown : RaceOwnerDropdownAr}
              isSearchable={true}
              isClearable={true}

              hideSelectedOptions={true}
              onInputChange={handleChangeInput11}
              value={ResultOwnerData}
              name="Owner"
              onMenuScrollToBottom={(e) => handleOnScrollBottom10(e)}
            />




            <Select
              id="selectNow"
              placeholder={t("sponsors")}
              className="dropdown multidropdown"
              onChange={setResultSponsor}
              options={cookiedata === "en" ? RaceSponsorDropdown : RaceSponsorDropdownAr}
              isSearchable={true}
              isClearable={true}

              hideSelectedOptions={true}
              onInputChange={handleChangeInput13}
              value={ResultSponsorData}
              name="Sponsor"
              onMenuScrollToBottom={(e) => handleOnScrollBottom11(e)}
            />
                </div>


          </div> : <></>
          }
          


          <div className="rightquery">
          {
                status !== 'idle' ?
                  <Lottie
                  animationData={Animate}
                  className="load1"
                  style={{marginTop:"20%"}}
                />
             :
            <>
            <table className="querytable">
              <thead className="querytablehead">
                <tr>
                  <th>{t("Date")}</th>
                  <th>{t("Time")}</th>
                  <th>{t("Race Course")}</th>
                  <th>{t("Track length")}</th>
                  <th>{t("Ground")}</th>
                  <th>{t("Winning Time")}</th>

                  <th>{t("KindOfHorse")}</th>
                  <th>{t("Horse")}</th>

                  <th>{t("Jockey")}</th>
                  <th>{t("Trainer")}</th>
                  <th>{t("Position")}</th>
                  <th>{t("RaceType")}</th>

                  {/* <th>{t("Runners")}</th> */}
                </tr>
              </thead>
              <tbody className="querytablebody">
                  {QueryTool.data &&
                    QueryTool.data.map((item, index) => {
                      return (
                        <tr key={index} >
                          <Moment format="DD/MM/YYYY" onClick={() => handleShowRace(item.RaceID)} style={{
                            cursor:'pointer'
                          }}>
                            {item.StartTimeOfRace}
                          </Moment>
                          <td>{formatAMPM(item?.StartTimeOfRace).toLocaleUpperCase()}</td>
                        

                          <td>
                            {cookiedata === "en"
                              ? (window.textTransform(item.RaceCourseOfRaceData ? item.RaceCourseOfRaceData.TrackNameEn : <>-</> ))
                              : item.RaceCourseOfRaceData ? item.RaceCourseOfRaceData.TrackNameAr : <>-</>}
                          </td>
                          <td>
                          {
                            item?.RaceTracklengthData?.TrackLength
                          }
                          </td>
                          <td>
                          {cookiedata === "en"
                              ? (window.textTransform(item.RaceGroundData ? item.RaceGroundData.NameEn : <>-</>))
                              : item.RaceGroundData ? item.RaceGroundData.NameAr : <>-</>}
                          </td>

                          <td>
                            {item.RaceTime}
                          </td>
                          <td >
                            {cookiedata === "en"
                              ?window.textTransform(item.HorseIDData?.KindHorseData?.NameEn)
                              : item.HorseIDData?.KindHorseData?.NameAr}
                          </td>
                          <td onClick={() => handleShow(item.HorseID)} style={{
                            cursor: 'pointer'
                          }}>
                          {cookiedata === "en"
                              ? window.textTransform(item.HorseIDData?.NameEn)
                              : window.textTransform(item.HorseIDData?.NameAr)}
                          </td>

                        



                          <td onClick={() => handleShowJockey(item.JockeyOnRace)} style={{
                            cursor: 'pointer'
                          }}>
                            {cookiedata === "en"
                              ? ( window.textTransform(item.JockeyOnRaceDataResult ? item.JockeyOnRaceDataResult.NameEn : <>-</>))
                              : (window.textTransform(item.JockeyOnRaceDataResult ? item.JockeyOnRaceDataResult.NameAr : <>-</>))}
                          </td>
                          <td onClick={() => handleShowTrainer(item.TrainerOnRace)} style={{
                            cursor: 'pointer'
                          }}>
                            {cookiedata === "en"
                              ? (window.textTransform(item.TrainerOnRaceDataResult ? item.TrainerOnRaceDataResult.NameEn : <>-</>))
                              : (window.textTransform(item.TrainerOnRaceDataResult ? item.TrainerOnRaceDataResult.NameAr : <>-</>))}
                          </td>
                          <td>
                            {
                              item.FinalPositionDataHorse &&  item.FinalPositionDataHorse.NameEn
                            }
                          </td>

                          <td className="tooltipclass2">
                            {cookiedata === "en"
                              ? window.textTransform(item.RaceResultData?.RaceTypeModelData?.NameEn)
                              : item.RaceResultData?.RaceTypeModelData?.NameAr}
                          </td>
                        
                          {/* <td>
                            {item.RaceResultData ? (
                              item.RaceResultData.Runners
                            ) : (
                              <>-</>
                            )}
                          </td> */}
                        </tr>
                      );
                    })}
                </tbody>

            </table>
                        <div className="querytoolpagination">
                        <Pagination
                          postsPerPage={postsPerPage}
                          totalPosts={totalpagesdata}
                          paginate={paginate}
                          currentPage={currentPage}
                          TotalPages={TotalPages}
                        />
                      </div>
            </>
}


          </div>
        </div>
        {/* <div className="sponsor querysponsor">
        <Carousel>
          {sponsor?.slice(0,3)?.map((item) => {
            return (
              <Carousel.Item interval={2000}>
                <a>
                  <div className="sponsorimg querysponsorimg">
                    <img src={item.image ? item.image : DefaultImg} alt="" />
                    
                  </div>
                </a>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div> */}
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <HorseModal data={modaldata} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        show={showJockey}
        onHide={handleCloseJockey}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <JockeyModal data={modaldataJockey} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        show={showTrainer}
        onHide={handleCloseTrainer}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <TrainerModal data={modaldataTrainer} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        show={showRace}
        onHide={handleCloseRace}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <RaceModal id={modaldataRace} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Footer />
      <CopyRight />
    </>
  );
};
export default Stats;
