//.....................Import..........................//
import React, { useEffect, useState,useRef } from "react";
import "../Components/CSS/RaceCardCSS/racedetail.css";
import Defaultimg from "../assets/Frame.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchsinglerace, STATUSES } from "../redux/getReducer/getSingleRace";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import Zoom from "react-reveal/Zoom";
import img from "../assets/image 10.png";
import img1 from "../assets/image 10 (1).png";
import Summary from "../Components/RaceCard/Summary";
import Draw from "../Components/RaceCard/Draw";
import Predictor from "../Components/RaceCard/Predictor";
import TrackRecord1 from "../Components/RaceCard/TrackRecord";
import Moment from "react-moment";
import PrintOut from "../Components/RaceCard/Printout";
import { IoPartlySunnyOutline, IoCloudyOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import Animate from "../assets/loader.json";
import RaceNumbers from "../Components/Reuseable/RaceNumbers";
import arrow2 from "../assets/arrow1.png";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import CardData from "../Components/RaceCard/Card";
import flag from "../assets/United Arab Emirates.png";
import { Modal } from "react-bootstrap";
import VideoFrame from "../Components/Modal/VideoFrame";
import { fetchRacePredictor } from "../redux/getReducer/getRacePrediction";
import { fetchDraw } from "../redux/getReducer/getDraw";
import { fetchSummary } from "../redux/getReducer/getSummarySlice";
import { fetchTrackRecord } from "../redux/getReducer/TrackRecord";
import CastModal from '../Components/Modal/CastModal'
import socket from "../../Socket";
const RaceCardDetail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { state } = useLocation()
  const { data: singlerace, status } = useSelector((state) => state.singlerace);
  const { data: RacePredictor } = useSelector((state) => state.RacePredictor);
  const { data: draw } = useSelector((state) => state.draw);
  const { data: summary  } = useSelector((state) => state.summary);
  const { data: TrackRecord } = useSelector((state) => state.TrackRecord);
  const { data: ThemeSetting } = useSelector((state) => state.ThemeSetting);

  const token = Cookies.get("token");
  const myRef = useRef(null);
  useEffect(() => {
    // Scroll to the component when the component mounts
    if (myRef.current) {
    
      myRef.current.scrollIntoView({ behavior: 'smooth' });
    console.log("chal ja")
    }

  }, []);


  function DataOne() {
    if (!state) {
      return (window.location.href = "/");
    }
  }
  DataOne();

  const { id } = state;

  const [show, setShow] = useState(false);
  const [castdependency, setcastdependency] = useState(false);
  const [modaldata, setmodaldata] = useState();
  const handleClose = () => setShow(false);
  const handleShow = async (data) => {
    setmodaldata(data);
    await setShow(true);
  };


  const [showCast, setShowCast] = useState(false);
  const [modaldataCast, setmodaldataCast] = useState();
  const handleCloseCast = () => {
    setShowCast(false);
    setcastdependency(true)
  }
  const handleShowCast = async (data) => {
    setmodaldataCast(data);
    await setShowCast(true);
  };





  const btnNew1 = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
    gap: "10px",
    width: "112px",

    height: "24px",
    background: "#FF0000",
    // borderRadius: "10px",
    border: "none",
    color: "#fff",
  };

  const btnNewdelete = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
    gap: "10px",
    width: "112px",
    marginTop:"10px",
    height: "24px",
    background: "#19469D",
    // borderRadius: "10px",
    border: "none",
    color: "#fff",
  };
  const [activeTab, setActiveTab] = useState("Card");

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
    if (tabIndex == "Predictor") {
      dispatch(fetchRacePredictor({ id }));
      socket.emit("createRoom",id)
    } else if (tabIndex == "Draw") {
      dispatch(fetchDraw({ id }))
      socket.emit("leaveroom",id)

    }
    else if (tabIndex == "Summary") {
      dispatch(fetchSummary({ id }));
    }
    else if (tabIndex == "Track Record") {
      dispatch(fetchTrackRecord({ id }));
    }
    else if (tabIndex == "Printout") {
    }
  
  };


  useEffect(() => {
    dispatch(fetchsinglerace({ id }));
    setActiveTab("Card")
  }, [dispatch, id ,castdependency]);

  if (status === STATUSES.ERROR) {
    return (
      <h2
        style={{
          margin: "100px",
          width: "50%",
        }}
      >
        Something went wrong!
      </h2>
    );
  }

  const cookiedata = Cookies.get("i18next");


  const DataDate = new Date(singlerace.racedetail && singlerace.racedetail.StartTime === null ? "" :singlerace.racedetail && singlerace.racedetail.StartTime);

  function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }
  const formatedData = formatAMPM(DataDate);

  let link = singlerace.RaceResultData
    ? singlerace.RaceResultData[0]
      ? singlerace.RaceResultData[0].VideoLink
      :false
    : false;

  // let link = 'https://www.youtube.com/watch?v=nr3wCgKzSIA'

  
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const options = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };


  const formatDateAr = (dateStr) => {
    const date = new Date(dateStr);
    const options = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('ar', options);
  };
  

  return (
    <>
      <Zoom>
        {
          status !== "loading" ?
          <div className="RaceCardDetail" id="racedetail">
          {singlerace && singlerace.racedetail !== undefined ? (
            <div className="RaceDetailHeader" style={{
              background:`${ThemeSetting && ThemeSetting.PrimaryColor}`
             }}>
              <div>
                <div className="colorheader"  ref={myRef}>
                  <div>
                    <span className="racenameflex">
                      <p>
                        {cookiedata === "en" ? (
                          singlerace.racedetail.RaceCourseData === undefined ? (
                            <>- </>
                          ) : (
                            window.textTransform(singlerace.racedetail.RaceCourseData.TrackNameEn)
                          )
                        ) : singlerace.racedetail.RaceCourseData === undefined ? (
                          <>- </>
                        ) : (
                          singlerace.racedetail.RaceCourseData.TrackNameAr
                        )}
                      </p>

                      {
                        singlerace.racedetail.RaceCourseData === null ? <></>
                          :
                          singlerace.racedetail.RaceCourseData &&
                          <span
                            className="racecardcolor1"
                            style={{
                              background: `${singlerace.racedetail?.RaceCourseData?.ColorCodeData ? singlerace.racedetail?.RaceCourseData?.ColorCodeData?.Color : "#fff"}`,
                            }}
                          ></span>
                      }
                      {singlerace.racedetail.RaceCourseData ? (
                        <img
                          src={
                            singlerace.racedetail.RaceCourseData.image
                              ? singlerace.racedetail.RaceCourseData.image
                              : flag
                          }
                          alt=""
                        />
                      ) : (
                        <img src={flag} alt="" />
                      )}
                    </span>
                    <p className="itemtime">
                      {
                      cookiedata === "en" ?   formatDate(singlerace?.racedetail?.Day) :  formatDateAr(singlerace?.racedetail?.Day)
                      }
                    </p>
                  
                  </div>
                  <RaceNumbers
                    racecoursedata={
                      singlerace.racedetail === undefined
                        ? ""
                        : singlerace.racedetail
                    }
                  />
                </div>
                <div className="racedisc">
                  <div className="itemraces">
                    <div className="inner_itemraces">
                      <span className="itemraces_left">
                        <span
                          className="race"
                          style={{
                            backgroundColor: `${singlerace.racedetail.RaceStatus === "Cancelled"
                              ? "#000000"
                              : singlerace.racedetail.RaceStatus === "Completed"
                                ? "#FF0000"
                                : singlerace.racedetail.RaceStatus === "Live"
                                  ? "#5EC30F"
                                  : "#FF9900"
                              }`,
                            color: `${singlerace.racedetail.RaceStatus === "Cancelled"
                              ? "#ffff"
                              : singlerace.racedetail.RaceStatus === "Completed"
                                ? "#00000"
                                : singlerace.racedetail.RaceStatus === "Live"
                                  ? "#00000"
                                  : "#000000"
                              }`,
                          }}
                        >
                          <p>
                            {t("Race")} {singlerace.racedetail.RaceNumber}
                          </p>
                          <p>
                            {formatedData.toLocaleUpperCase()}
                            {/* {" "}
                        {new Date(singlerace.racedetail.StartTime)
                          .toLocaleTimeString()
                          .slice(0, 4)                      
                          } */}
                            {/* m */}
                          </p>
                        </span>
                        {singlerace.racedetail.SponsorData ? (
                          <>
                            <a
                              href={
                                singlerace.racedetail.SponsorData ? (
                                  singlerace.racedetail.SponsorData.Url
                                ) : (
                                  <></>
                                )
                              }
                              target="_blank"
                            >
                              <img
                                className="sponsor"
                                src={
                                  singlerace.racedetail.SponsorData.image === null
                                    ? Defaultimg
                                    : singlerace.racedetail.SponsorData.image
                                }
                                alt=""
                              />
                            </a>
                          </>
                        ) : (
                          <></>
                        )}
                      </span>
                      <span className="itemraces_center">
                        <OverlayTrigger
                          className="tooltip-btn"
                          overlay={
                            <Tooltip
                              id="tooltip-disabled"
                              className="tooltip-btn"
                            >
                              {cookiedata === "en" ? (
                                singlerace.racedetail.RaceNameModelData === undefined ? (
                                  <>- </>
                                ) : (
                                  window.textTransform(singlerace.racedetail.RaceNameModelData.NameEn)
                                )
                              ) : singlerace.racedetail.RaceNameModelData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.racedetail.RaceNameModelData.NameAr
                              )}
                            </Tooltip>
                          }
                        >
                          <span className="d-inline-block">
                            <h5 className="tooltipclass">
                              {" "}
                              {cookiedata === "en" ? (
                                singlerace.racedetail.RaceNameModelData === undefined ? (
                                  <>- </>
                                ) : (
                                  window.textTransform(singlerace.racedetail.RaceNameModelData.NameEn)
                                )
                              ) : singlerace.racedetail.RaceNameModelData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.racedetail.RaceNameModelData.NameAr
                              )}
                            </h5>
                          </span>
                        </OverlayTrigger>

                        <div
                          className="racecardcss1 classtyperaces1"
                        >
                          <p
                            style={{
                              padding: "1px",
                            }}
                          >
                            <b>{t("RK")}</b> :  <span>  </span>
                            {cookiedata === "en" ? (
                              singlerace.racedetail.RaceKindData === undefined ? (
                                <>- </>
                              ) : (
                                window.textTransform(singlerace.racedetail.RaceKindData.NameEn)
                              )
                            ) : singlerace.racedetail.RaceKindData === undefined ? (
                              <>- </>
                            ) : (
                              singlerace.racedetail.RaceKindData.NameAr
                            )}
                          </p>

                          <p
                           style={{
                            color:"#ff0000"
                          }}
                          >
                           <b style={{color:"#000"}}> {t("RT")} </b>:  <span>  </span>
                            {cookiedata === "en" ? (
                              singlerace.racedetail.RaceTypeModelData === undefined ? (
                                <>- </>
                              ) : (
                                window.textTransform(singlerace.racedetail.RaceTypeModelData.NameEn)
                              )
                            ) : singlerace.racedetail.RaceTypeModelData === undefined ? (
                              <>- </>
                            ) : (
                              singlerace.racedetail.RaceTypeModelData.NameAr
                            )}
                          </p>
                          <p
                          style={{
                            fontWeight:"500",
                            color:"#ff0000"

                          }}
                        >
                         <b style={{color:"#000"}}>  {t("HK")}</b> :  <span>  </span>
                          {cookiedata === "en" ? (
                            singlerace.racedetail.HorseKindinRaceData === undefined ? (
                              <>- </>
                            ) : (
                              window.textTransform(singlerace.racedetail.HorseKindinRaceData.NameEn)
                            )
                          ) : singlerace.racedetail.HorseKindinRaceData === undefined ? (
                            <>- </>
                          ) : (
                            singlerace.racedetail.HorseKindinRaceData.NameAr
                          )}
                        </p>
                        </div>
                        
                      </span>
                      <span className="itemraces_right addtracklength">
                       
                        <span className="distance">
                          <img
                            src={
                              singlerace.racedetail.TrackLengthData &&
                              singlerace.racedetail.TrackLengthData.RaceCourseImage
                            }
                            alt=""
                          />
                        </span>
                        <h6 className="trcklength1">{t("Track length")} : {singlerace?.racedetail?.TrackLengthData?.TrackLength}</h6>                          

                       
                      </span>
                    </div>
                    {singlerace.racedetail.RaceStatus === "Completed" ? (
                      <div className="resultimages">
                        <span className="resultimagesRunningTime">
                          <p>{t("RunningTime")}</p>
                          <h4   style={{
                            color:"#ff0000"
                          }}>
        
                             {singlerace.racedetail.RaceTimeAcheived
                              ? singlerace.racedetail.RaceTimeAcheived
                              : "00:00:00"}
                          </h4>
                        </span>

                        {singlerace.racedetail.RaceimagesData.length !== 0 ? (
                          singlerace.racedetail.RaceimagesData.slice(0, 4).map((data) => {
                            return (
                              <span className="resultimagebox">
                                <img src={data.image} alt="" />
                              </span>
                            );
                          })
                        ) : (
                          <>
                            <span className="resultimagebox"></span>
                            <span className="resultimagebox"></span>
                            <span className="resultimagebox"></span>
                            <span className="resultimagebox"></span>
                          </>
                        )}
                        {
                          link ? <span
                          className="resultimagevideo"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => handleShow(link)}
                        >
                          <img src={arrow2} alt="img" />

                          <p>{t("WatchNow")}</p>
                        </span>  : <></>
                        }

                        
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="hosekindSection"></div>
                    <p>
                      <b>{t("Description")}</b> :
                      {cookiedata === "en" ? (
                        <>{ window.textTransform(singlerace.racedetail.DescriptionEn)}</>
                      ) : (
                        <>{singlerace.racedetail.DescriptionAr}</>
                      )}
                    </p>
                   
                   

                  </div>
                </div>
                <div className="prizecard">
                  <div className="prizecardheaders">
                    <p>
                      {t("Total Prize")}  <span>  </span>: <span>  </span>

                      {cookiedata === "en" ? (
                          <b>
                            {singlerace.racedetail.CurrencyData === undefined ? (
                              <>- </>
                            ) : (
                              singlerace.racedetail.CurrencyData.NameEn
                            )}
                          </b>
                        ) : (
                          <b>
                            {singlerace.racedetail.CurrencyData === undefined ? (
                              <>- </>
                            ) : (
                              singlerace.racedetail.CurrencyData.NameAr
                            )}
                          </b>
                        )}

                      <b className="mx-1">
                        {singlerace.racedetail.FirstPrice +
                          singlerace.racedetail.SecondPrice +
                          singlerace.racedetail.ThirdPrice +
                          singlerace.racedetail.FourthPrice +
                          singlerace.racedetail.FifthPrice +
                          singlerace.racedetail.SixthPrice}

                        
                      </b>
                    </p>
                    {
                    singlerace.racedetail.RaceStatus === "Live" &&  singlerace.CompetitionCategory === "cast" &&  token ? 
                    <>
                     {
                        singlerace.CompetitionCategory === "cast"  && singlerace.votedhorse?.length ==  0 ?   <button
                        style={btnNew1}
                        onClick={() => handleShowCast(singlerace)}
                      >
                       
                         {t("cast")}
                        
                      </button> :  <button
                        style={btnNewdelete}
                        onClick={() => handleShowCast(singlerace)}
                      >
                       
                         {t("casted")}
                        
                      </button>
                      }
                    </>
                    : <></>
                    }
                   
                  </div>
                  <div className="Competitiontrophy">
                    <div className="Trophydata">
                      <span>{t("1st")}</span>
                      <span>
                        <img src={img1} alt="" />
                      </span>
                      <div className="Trophydata_P">
                        <h6>
                          {singlerace.racedetail.FirstPrice}{" "}
                          {cookiedata === "en" ? (
                            <>
                              {singlerace.racedetail.CurrencyData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.racedetail.CurrencyData.NameEn
                              )}
                            </>
                          ) : (
                            <>
                              {singlerace.racedetail.CurrencyData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.racedetail.CurrencyData.NameAr
                              )}
                            </>
                          )}
                        </h6>
                      </div>
                    </div>
                    <div className="Trophydata">
                      <span>{t("2nd")}</span>
                      <span>
                        <img src={img} alt="" />
                      </span>
                      <div className="Trophydata_P">
                        <h6>
                          {singlerace.racedetail.SecondPrice}{" "}
                          {cookiedata === "en" ? (
                            <>
                              {singlerace.racedetail.CurrencyData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.racedetail.CurrencyData.NameEn
                              )}
                            </>
                          ) : (
                            <>
                              {singlerace.racedetail.CurrencyData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.racedetail.CurrencyData.NameAr
                              )}
                            </>
                          )}
                        </h6>
                      </div>
                    </div>
                    <div className="Trophydata">
                      <span>{t("3rd")}</span>
                      <span>
                        <img src={img} alt="" />
                      </span>
                      <div className="Trophydata_P">
                        <h6>
                          {singlerace.racedetail.ThirdPrice}{" "}
                          {cookiedata === "en" ? (
                            <>
                              {singlerace.racedetail.CurrencyData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.racedetail.CurrencyData.NameEn
                              )}
                            </>
                          ) : (
                            <>
                              {singlerace.racedetail.CurrencyData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.racedetail.CurrencyData.NameAr
                              )}
                            </>
                          )}
                        </h6>
                      </div>
                    </div>
                    <div className="Trophydata">
                      <span>{t("4th")}</span>
                      <span>
                        <img src={img} alt="" />
                      </span>
                      <div className="Trophydata_P">
                        <h6>
                          {singlerace.racedetail.FourthPrice}{" "}
                          {cookiedata === "en" ? (
                            <>
                              {singlerace.racedetail.CurrencyData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.racedetail.CurrencyData.NameEn
                              )}
                            </>
                          ) : (
                            <>
                              {singlerace.racedetail.CurrencyData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.racedetail.CurrencyData.NameAr
                              )}
                            </>
                          )}
                        </h6>
                      </div>
                    </div>
                    <div className="Trophydata">
                      <span>{t("5th")}</span>
                      <span>
                        <img src={img} alt="" />
                      </span>
                      <div className="Trophydata_P">
                        <h6>
                          {singlerace.racedetail.FifthPrice}{" "}
                          {cookiedata === "en" ? (
                            <>
                              {singlerace.racedetail.CurrencyData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.racedetail.CurrencyData.NameEn
                              )}
                            </>
                          ) : (
                            <>
                              {singlerace.racedetail.CurrencyData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.racedetail.CurrencyData.NameAr
                              )}
                            </>
                          )}
                        </h6>
                      </div>
                    </div>
                    {singlerace.racedetail.SixthPrice === 0 ? (
                      <></>
                    ) : (
                      <div className="Trophydata">
                        <span>{t("6th")}</span>
                        <span>
                          <img src={img} alt="" />
                        </span>
                        <div className="Trophydata_P">
                          <h6>
                            {singlerace.racedetail.SixthPrice}{" "}
                            {cookiedata === "en" ? (
                              <>
                                {singlerace.racedetail.CurrencyData === undefined ? (
                                  <>- </>
                                ) : (
                                  singlerace.racedetail.CurrencyData.NameEn
                                )}
                              </>
                            ) : (
                              <>
                                {singlerace.racedetail.CurrencyData === undefined ? (
                                  <>- </>
                                ) : (
                                  singlerace.racedetail.CurrencyData.NameAr
                                )}
                              </>
                            )}
                          </h6>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {
                  singlerace?.Verdict?.length !== 0 ? 
                  <div className="prizecard prizecardverdict">
                  <div className="prizecardheaders">
                  <p>
                     <b>{t("verdictname")}</b> 
                    </p>
                    </div>
                  {
                      singlerace?.Verdict.length === 0 ? <></> : 
                      <div className="verdictrace">
                      <table>
                        <thead>
                        <tr className="verdictdata">
                                  <th>{t("verdictname")}</th>
                                  <th>{t("1st")} </th>
                                  <th>{t("2nd")}</th>
                                  <th>{t("3rd")}</th>
                                  <th>{t("Remarks")}</th>
                          </tr>
                          
                          {
                            singlerace.Verdict.map((item,i) => {
                              return(
                                <tr className="verdictdata">
                                 <td>{cookiedata === "en" ?  window.textTransform(item?.VerdictNameData?.NameEn) : item?.VerdictNameData?.NameAr}</td>
                                  <td>{cookiedata === "en" ?   window.textTransform(item?.HorseNo1Data?.NameEn) :  item?.HorseNo1Data?.NameAr}</td>
                                  <td>{cookiedata === "en" ?  window.textTransform(item?.HorseNo2Data?.NameEn) : item?.HorseNo2Data?.NameAr}</td>
                                  <td>{cookiedata === "en" ?  window.textTransform(item?.HorseNo3Data?.NameEn) : item?.HorseNo3Data?.NameAr}</td>
                                  <td>{cookiedata === "en" ? item?.Remarks: item.Remarks}</td>
                                </tr>
                              )
                            })
                          }
                          
                          
                        </thead>
                      </table>
                        </div>
                    }

                   
                  
                </div> : <></>
                }
                
                <div className="RaceNav">
                  <Tabs
                    activeKey={activeTab} onSelect={handleTabChange}
                    defaultActiveKey="Card"
                    id="uncontrolled-tab-example"
                    className="mb-3 RaceNavItem"
                  >
                    <Tab
                      eventKey="Card"
                      title={t("Card")}
                      tabClassName="profile-tabitem"
                    >
                     <CardData /> 
                      
                    </Tab>
                    {
                      singlerace?.racedetail?.RaceStatus == "Live" ?    <Tab
                      eventKey="Predictor"
                      title={t("Predictor")}
                      tabClassName="profile-tabitem"
                    >
                      <div className="RaceDetailCard">
                        <Predictor RacePredictor={RacePredictor} />
                      </div>
                    </Tab> : <></>
                    }
                   

                    <Tab
                      eventKey="Draw"
                      title={t("Draw")}
                      tabClassName="profile-tabitem"
                    >
                      <div className="RaceDetailCard">
                        <Draw draw={draw} />
                      </div>
                    </Tab>
                    <Tab
                      eventKey="Summary"
                      title={t("Summary")}
                      tabClassName="profile-tabitem"
                    >
                      <div className="RaceDetailCard">
                        <Summary summary={summary} />
                      </div>
                    </Tab>
                    <Tab
                      eventKey="Track Record"
                      title={t("TrackRecord")}
                      tabClassName="profile-tabitem"
                    >
                      <div className="RaceDetailCard">
                        <TrackRecord1 TrackRecord={TrackRecord} />
                      </div>
                    </Tab>
                    <Tab
                      eventKey="Printout"
                      title={t("Printout")}
                      tabClassName="profile-tabitem"
                    >
                      <PrintOut />
                    </Tab>
                  </Tabs>
                  <div></div>
                </div>
              </div>
            </div>
          ) : (
            <div className="NAclass RaceCardDetail">
              <Lottie
                animationData={Animate}
                className="Lottie compLottie"
              />
            </div>
          )}
        </div> : <h2 className="RaceCardDetail">
        <Lottie
                            animationData={Animate}
                            loop={true}
                            className="TableLottie"
                          />
        </h2>
        }
        
      </Zoom >
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <VideoFrame data={modaldata} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        show={showCast}
        onHide={handleCloseCast}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <CastModal data={{modaldataCast ,handleCloseCast}}/>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>


      
    </>
  );
};

export default RaceCardDetail;
