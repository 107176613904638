import React, { useEffect, useState, Fragment } from "react";
import Layout from "../Components/Reuseable/layout";
import Footer from "../Components/Reuseable/Footer.jsx";
import CoptRight from "../Components/Reuseable/Copyrights";
import Cookies from "js-cookie";
import { Accordion, Modal } from "react-bootstrap";
import "../../Webiste/Components/CSS/pagesCSS/dashboard.css";
import ScrollContainer from "react-indiana-drag-scroll";
import { useTranslation } from "react-i18next";
import { fetchsingleUser, STATUSES } from "../redux/getReducer/getSingleUser";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Sponser from "../pages/Sponsor";
import "../Components/CSS/RaceCardCSS/racecard.css";
import Justsponsor from "./justsponser";
import img from "../assets/GB.jpg";
import { useNavigate } from "react-router-dom";
import VideoFrame from "../Components/Modal/VideoFrame";
import Lottie from 'lottie-react';
import Animate from "../assets/loader.json";
// import { Accordion } from 'react-bootstrap';
import { IoIosArrowDropdownCircle } from "react-icons/io";
import ExNews from "../Components/Home/Components/ExNews";
import Language from "../Components/Home/Components/Language";
import Notification from "../Components/Home/Components/Notification";
import Search from "../Components/Home/Components/Search";
import { BsCalendarDate } from "react-icons/bs";
import CalenderComponent from "../Components/Home/Components/Calendar";
import { fetchResultScreencreen } from "../redux/getReducer/getraceresultscreen";
// import { Fragment } from 'react';
import noimage from "../assets/noimage1.png";
import noimagearabic from "../assets/nottacearabic.png";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'


// selector
export default function ResultScreen() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showcalender, setshowcalender] = useState(false);
  const { data: ResultScreencreen , status } = useSelector((state) => state.ResultScreencreen);

const dispatch = useDispatch();
  const [userIsDesktop, setUserIsDesktop] = useState(true);
  useEffect(() => {
    window.innerWidth > 840 ? setUserIsDesktop(true) : setUserIsDesktop(false);
  }, [userIsDesktop]);

  function handleRedirect(id) {
    if (userIsDesktop === true) {
      navigate(`/racedetail`, {
        state: {
          id: id,
        },
      });
    } else {
      navigate("/racedetails", {
        state: {
          id: id,
        },
      });
    }
  }
  const { t } = useTranslation();
  const cookiedata = Cookies.get("i18next");
  const token = Cookies.get("token");
  const [CategorySub, setCategorySub] = useState();
  const [DateTobeDone, SetDateTobeDone] = useState([]);
  const [show, setShow] = useState(false);
  const [modaldata, setmodaldata] = useState();
  const [myData, setmyData] = useState( new Date());

  const handleClose = () => setShow(false);
  const handleShow = async (data) => {
    setmodaldata(data);
    await setShow(true);
  };



  let getDataCategory = async () => {
    setIsLoading(true)
    
    await fetch(`${window.env.API_URL}/ResultScreen?Date=${moment().format("YYYY-MM-DD")}`)
      .then((res) => res.json())
      .then((result) => {
        setCategorySub(result);
        SetDateTobeDone(GetDates());
      });
    setIsLoading(false)
  };

  const GetDates = (alldata) => {
    var start = new Date();
    var end = new Date(start);
  
    end.setDate(start.getDate() + 3);
    let Dates = [];

    var loop = new Date(start);

    for (let i = 0; i < 5; i++) {
      if (i == 0) {
        var newDates = loop.setDate(loop.getDate() + i);
        loop = new Date(newDates);
        Dates.push(moment(loop).format("YYYY-MM-DD"));
      } else {
        var newDates = loop.setDate(loop.getDate() - 1);
        loop = new Date(newDates);
        Dates.push(moment(loop).format("YYYY-MM-DD"));
      }
    }

    SetDateTobeDone(Dates);
    return Dates;
  };

  const numbers = `۰۱۲۳۴۵۶۷۸۹`;
  const convert = (num) => {
    let res = "";
    const str = num ? num.toString() : "";
    for (let c of str) {
      res += numbers.charAt(c);
    }
    return res;
  };

  const start =moment().format("YYYY-MM-DD");


  const today = cookiedata === "en" ? "Today" : "اليوم";
  const Yesterday = cookiedata === "en" ? "Yesterday" : "أمس";
  const getDateDataAccordingToTime = async (start) => {
  
    dispatch(fetchResultScreencreen ({  start}));
    setmyData(start)

    setCategorySub();
    setIsLoading(true)
    setIsLoading(false)
  };
  useEffect(() => {
    dispatch(fetchResultScreencreen ({ start }));

    getDataCategory();
  }, []);
  const AllTime = (TimeData) => {
    if (TimeData.length == 1) {
      return `${moment(TimeData[0].StartTime).format("h:mm")}`;
    } else if (TimeData.length == 0) {
      return ``;
    } else {
      let FirstTime = moment(TimeData[0].StartTime).format("h:mm");
      let LastTime = moment(TimeData[TimeData.length - 1].StartTime).format(
        "h:mm"
      );
      return `${FirstTime}-${LastTime}`;
    }
  };


  const date = new Date(myData);

  // Extract year, month, and day
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  
  // Form the new formatted date string
  const formattedDate = `${year}-${month}-${day}`;


  return (
    <Fragment>
      <>
      
        <div className="container-fluid mystages racecards myresults">
          <div className="row">
            <div className="col-sm-8 stages ">
              <div className="raceCarParent stages stages123">
                <div className="raceCardHeader1">
                  <div className="row">
                    <div className="col-sm-10">
                      <h1>{t("Result")}</h1>
                    </div>
                  </div>
                </div>

               

                <div className="posttabs">
                  <div className="row">
                    {DateTobeDone ? (
                      DateTobeDone.map((singledate, i) => (
                        <div className="col-sm-2">
                          <p
                            onClick={() =>
                              getDateDataAccordingToTime(singledate)
                            }
                            className={`${singledate === formattedDate ? "active" : "notactive"}`}
                          >
                            {i === 0 ? today : i === 1 ? Yesterday : singledate}
                          </p>
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                   <BsCalendarDate className="chosecalender" onClick={() => setshowcalender(!showcalender)} />
                  {
                      showcalender ? <CalenderComponent /> : <></>
                    }
                  </div>
                </div>

                <Accordion defaultActiveKey="0" className="mobilesitewidth">
                  {status == "loading" ? <Lottie
                    animationData={Animate}
                    className="load1"
                    style={{
                      marginTop:"30px"
                    }}
                  /> : ResultScreencreen?.length != 0 ? (
                       ResultScreencreen.map((singleracecourse, i) => (
                      <Accordion.Item eventKey={i} className="add_acc_1">
                        <div>
                          <Accordion.Header>
                            <div className="accordionHeader row">
                              <div className="headerCard1 col-sm-2">
                                <img src={img} alt="seo" />
                              </div>
                              <div className="headerCard2 col-sm-5">
                                <h5>
                                  {cookiedata === "en"
                                    ? singleracecourse.TrackNameEn
                                    : singleracecourse.TrackNameAr}
                                </h5>
                              </div>
                              <div className="headerCard2 col-sm-5">
                                <span></span>
                                <span>
                                  {singleracecourse.NationalityDataRaceCourse &&
                                    cookiedata === "en"
                                    ? singleracecourse.NationalityDataRaceCourse
                                      .NameEn
                                    : singleracecourse.NationalityDataRaceCourse
                                      .NameAr}
                                </span>
                                <span>
                                  {t("Race")}
                                  {singleracecourse.RaceCourseData.length &&
                                    cookiedata === "en"
                                    ? singleracecourse.RaceCourseData.length
                                    : convert(
                                      singleracecourse.RaceCourseData.length
                                    )}
                                </span>
                                <span>
                                          <IoIosArrowDropdownCircle />
                                        </span>
                              </div>

                            </div>
                          </Accordion.Header>

                          <Accordion.Body key={i}>
                            <div className="raceCardTable">
                              <div className="mylist resultss">
                                <div className="row">
                                  {/* meeting detail map */}
                                  <div className="col-sm-4 meetingtyp">
                                    <h2>{t("Meeting details")}</h2>
                                    <p>
                                      {t("Going")} :
                                      <span>
                                        {singleracecourse.RaceCourseData[0] &&
                                          cookiedata === "en"
                                          ? singleracecourse.RaceCourseData[0]
                                            .MeetingTypeData.NameEn
                                          : singleracecourse.RaceCourseData[0]
                                            .MeetingTypeData.NameAr}
                                      </span>
                                    </p>
                                    <p>
                                      {t("Weather Condtions")} :
                                      <span>
                                        {
                                          singleracecourse.RaceCourseData[0]
                                            .WeatherType
                                        }
                                      </span>
                                    </p>
                                  </div>

                                  {/* race result map */}
                                  {singleracecourse.RaceCourseData ? (
                                    singleracecourse.RaceCourseData.map(
                                      (singleracedetail, i) => (
                                        <div className="col-sm-4 raceresult">
                                          <div className="row">
                                            <div className="col-sm-3">
                                              <p className="tems">
                                                {singleracedetail.StartTime === null ? "" : moment(
                                                  singleracedetail.StartTime
                                                ).format("h:mm:ss a")}
                                              </p>
                                              {singleracedetail.RaceResultData[0] ? (

                                                <i
                                                  class="fa fa-video-camera"
                                                  aria-hidden="true"

                                                  onClick={() => handleShow(singleracedetail.RaceResultData[0].VideoLink)}
                                                ></i>

                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                            <div className="col-sm-9 meetdetail">
                                              <h2 onClick={() =>
                                                handleRedirect(
                                                  singleracedetail._id
                                                )
                                              }>
                                                {singleracedetail.RaceNameModelData &&
                                                  cookiedata === "en"
                                                  ? singleracedetail
                                                    .RaceNameModelData.NameEn
                                                  : singleracedetail
                                                    .RaceNameModelData.NameAr}
                                              </h2>
                                              <p>
                                                {t("Prize Money")}
                                                <span>
                                                  {" "}
                                                  {cookiedata === "en"
                                                    ? singleracedetail.FirstPrice +
                                                    singleracedetail.SecondPrice +
                                                    singleracedetail.ThirdPrice +
                                                    singleracedetail.FourthPrice +
                                                    singleracedetail.FifthPrice +
                                                    singleracedetail.SixthPrice
                                                    : convert(
                                                      singleracedetail.FirstPrice +
                                                      singleracedetail.SecondPrice +
                                                      singleracedetail.ThirdPrice +
                                                      singleracedetail.FourthPrice +
                                                      singleracedetail.FifthPrice +
                                                      singleracedetail.SixthPrice
                                                    )}
                                                </span>
                                              </p>
                                              <p>
                                                {t("Going")}
                                                <span>
                                                  {" "}
                                                  {singleracedetail.MeetingTypeData &&
                                                    cookiedata === "en"
                                                    ? singleracedetail
                                                      .MeetingTypeData.NameEn
                                                    : singleracedetail
                                                      .MeetingTypeData.NameAr}
                                                </span>
                                              </p>
                                              {singleracedetail
                                                .RaceResultData[0] ? (
                                                <>
                                                  <h4>
                                                    1st:
                                                    <span>
                                                      {singleracedetail
                                                        .RaceResultData[0] &&
                                                        cookiedata === "en"
                                                        ? singleracedetail
                                                          .RaceResultData[0]
                                                          .HorseIDData.NameEn
                                                        : singleracedetail
                                                          .RaceResultData[0]
                                                          .HorseIDData.NameAr}
                                                    </span>
                                                  </h4>
                                                  <h4>
                                                    2nd:
                                                    <span>
                                                      {singleracedetail
                                                        .RaceResultData.length === 0 ? <></> :
                                                        cookiedata === "en"
                                                          ? singleracedetail
                                                            .RaceResultData[1]
                                                            === undefined ? <></> : singleracedetail
                                                              .RaceResultData[1]
                                                              .HorseIDData.NameEn
                                                          :

                                                          singleracedetail
                                                            .RaceResultData[1]
                                                            .HorseIDData.NameAr}

                                                    </span>
                                                  </h4>
                                                  <h4>
                                                    3rd:{" "}
                                                    {singleracedetail
                                                      .RaceResultData.length === 0 ? <></> :
                                                      cookiedata === "en"
                                                        ? singleracedetail
                                                          .RaceResultData[2]
                                                          === undefined ? <></> : singleracedetail
                                                            .RaceResultData[2]
                                                            .HorseIDData.NameEn
                                                        :

                                                        singleracedetail
                                                          .RaceResultData[2]
                                                          .HorseIDData.NameAr}
                                                  </h4>

                                                  <p>
                                                    {t("Time")}:{" "}
                                                    <span>
                                                      {singleracedetail
                                                        .RaceResultData[0]
                                                        ? singleracedetail
                                                          .RaceResultData[0]
                                                          .RaceTime
                                                        : "Not Available"}
                                                    </span>
                                                  </p>

                                                  <p>
                                                    {t("Winning jockey")}:{" "}
                                                    <>
                                                      {singleracedetail
                                                        .RaceResultData[0] &&
                                                        cookiedata === "en"
                                                        ? singleracedetail
                                                          .RaceResultData[0]
                                                          .JockeyOnRaceDataResult
                                                          .NameEn
                                                        : singleracedetail
                                                          .RaceResultData[0]
                                                          .JockeyOnRaceDataResult
                                                          .NameAr}
                                                    </>
                                                  </p>
                                                  <p>
                                                    {t("Winning trainer")}:
                                                    <>
                                                      {singleracedetail
                                                        .RaceResultData[0] &&
                                                        cookiedata === "en"
                                                        ? (singleracedetail
                                                          .RaceResultData[0]
                                                          .TrainerOnRaceDataResult
                                                          ?
                                                          singleracedetail
                                                          .RaceResultData[0]
                                                          .TrainerOnRaceDataResult
                                                          .NameEn : 
                                                          <></>
                                                          )
                                                        : (singleracedetail
                                                          .RaceResultData[0]
                                                          .TrainerOnRaceDataResult
                                                          ? singleracedetail
                                                          .RaceResultData[0]
                                                          .TrainerOnRaceDataResult
                                                          .NameAr : <></>)}
                                                    </>
                                                  </p>
                                                  <button
                                                    onClick={() =>
                                                      handleRedirect(
                                                        singleracedetail._id
                                                      )
                                                    }
                                                  >
                                                    {t("Full Result")}
                                                  </button>
                                                </>
                                              ) : (
                                                <p>
                                                  <span>
                                                    {t("No Result Announced Yet")}
                                                  </span>
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )
                                  ) : (
                                    <p>
                                      <span>{t("No Result Announced Yet")}</span>
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </div>
                      </Accordion.Item>
                    ))
                  ) :  <div className=''>
                 <SkeletonTheme baseColor="#00A6A7" highlightColor="#9DD9DB">
           <p>
             <Skeleton count={3} />
           </p>
         </SkeletonTheme>
                  </div>
                  }
                </Accordion>
                
              </div>
            </div>
            <div className="col-sm-4">
              <Justsponsor />
            </div>
          </div>
        </div>

        <Modal
          show={show}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="popupheader" closeButton>

          </Modal.Header>
          <Modal.Body>
            <VideoFrame data={modaldata} />
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        <Footer />
        <CoptRight />
      </>
    </Fragment>
  );
}
