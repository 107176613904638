import axios from "axios";
import React, { useState, useEffect , useRef } from "react";
import { Modal } from "react-bootstrap";
import HorseDetail from "../../Modal/HorseModal";
import TrainerDetail from '../../Modal/TrainerModal';
import OwnerDetail from '../../Modal/OwnerModal';
import JockeyDetail from '../../Modal/JockeyModal';
import {useTranslation} from 'react-i18next';

import Animate from "../../../assets/loader.json"
import Lottie from 'lottie-react';
import Cookies from "js-cookie";

const Search = () => {
  const [item, SearchData] = useState("");
  const [Data, setData] = useState([]);
  const [Data2, setData2] = useState([]);
  const [Data3, setData3] = useState([]);
  const [Data4, setData4] = useState([]);
  const { t } = useTranslation()

  const [LoaderData, setLoaderData] = useState(false);
  const cookiedata = Cookies.get("i18next");

  const [show, setShow] = useState(false);
  const [modaldata, setmodaldata] = useState();
  const handleClose = () => setShow(false);
  const handleShow = async (data) => {
    setmodaldata(data);
    SearchData('')
    await setShow(true);
  };

  const [showJocley, setShowJockey] = useState(false);
  const [modaldataJockey, setmodaldataJockey] = useState();
  const handleCloseJockey = () => setShowJockey(false);
  const handleShowJocley = async (data) => {
    setmodaldataJockey(data);
    SearchData('')
    await setShowJockey(true);
  };

  const [showtrainer, setShowtrainer] = useState(false);
  const [modaldatatrainer, setmodaldatatrainer] = useState();
  const handleClosetrainer = () => setShowtrainer(false);
  const handleShowtrainer = async (data) => {
    setmodaldatatrainer(data);
    SearchData('')
    await setShowtrainer(true);
  };


  const [showowner, setShowowner] = useState(false);
  const [modaldataowner, setmodaldataowner] = useState();
  const handleCloseowner = () => setShowowner(false);
  const handleShowowner = async (data) => {
    setmodaldataowner(data);
    SearchData('')
    await setShowowner(true);
  };

  const searchChildRef = useRef(null); // Create a ref for the searchchild div

  // Close the searchchild div when clicking outside
  const handleClickOutside = (event) => {
    if (searchChildRef.current && !searchChildRef.current.contains(event.target)) {
      SearchData(""); // Clear the search input
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if(item !== ""){
      (async () => {
        try {
          setLoaderData(true)
          const res = await axios.post(
            `${window.env.API_URL}/searchhorse_trainer_jockey`,
            { Query: item }
          );
          setData(res.data.data1);
          setData2(res.data.data2);
          setData3(res.data.data3);
          setData4(res.data.data4);
          setLoaderData(false);
  
          if (item === "") {
            setData([]);
            setData2([]);
            setData3([]);
            setData4([]);
          }
        } catch (err) {
         
        }
      })();
    }
   
  }, [item]);

 

  return (
    <div className="searchparent">
      <div className="searchbox">
        <input
          type="text"
          onChange={(event) => SearchData(event.target.value)}
          placeholder={t("Search Horse, Jockey, Trainer, Owner")}
          style={{ fontSize: '16px' }}
        />
        <i className="fa fa-search icon11"></i>
      </div>
      <div className={item === "" ? "searchchild1" : "searchchild"} ref={searchChildRef}>
        <div className="searchdatalist ">
          <div className="row searchabc">
            <div className="col-sm abbc">
              <h5 className="searchhorsedata1">{t('Horses')}</h5>
            </div>
            <div className="col-sm abbc">
              <h5 className="searchhorsedata1">{t('Jockey')}</h5>
            </div>
            <div className="col-sm abbc">
              <h5 className="searchhorsedata1">{t('Trainer')}</h5>
            </div>
            <div className="col-sm abbc">
              <h5 className="searchhorsedata1">{t('Owner')}</h5>
            </div>
          </div>
        </div>
        <hr />
        <div className="searchdatalist ">
          {
            !LoaderData? 
            <div className="row searchabc">
            <div className="col-sm abbc">
              {Data.length === 0 ? (
                <p className="searchdatalist1">{t('NoDataFound')}</p>
              ) : (
                <>
                  {Data.map((data2) => {
                    return (
                      <p
                        className="searchname"
                        onClick={() => handleShow(data2)}
                      >
                        {cookiedata === "en" ? window.textTransform(data2?.NameEn) : data2.NameAr}
                      </p>
                    );
                  })}
                </>
              )}
            </div>
            <div className="col-sm abbc">
              {Data4.length === 0 ? (
                <p className="searchdatalist1">{t('NoDataFound')}</p>
              ) : (
                <>
                  {Data4.map((data2) => {
                    return <p className="searchname"
                     onClick={() => handleShowJocley(data2)}
                     >{cookiedata === "en" ? window.textTransform(data2?.NameEn) : data2.NameAr}</p>;
                  })}
                </>
              )}
            </div>
            <div className="col-sm abbc">
              {Data2.length === 0 ? (
                <p className="searchdatalist1">{t('NoDataFound')}</p>
              ) : (
                <>
                  {Data2.map((data2) => {
                    return <p className="searchname" 
                    onClick={() => handleShowtrainer(data2)}
                    
                    >{cookiedata === "en" ? window.textTransform(data2?.NameEn) : data2.NameAr}</p>;
                  })}
                </>
              )}
            </div>
            <div className="col-sm abbc">
              {Data3.length === 0 ? (
                <p className="searchdatalist1">{t('NoDataFound')}</p>
              ) : (
                <>
                  {Data3.map((data2) => {
                    return <p className="searchname"
                     onClick={() => handleShowowner(data2)}
                     >{cookiedata === "en" ?<> {data2.TitleEn} {window.textTransform(data2?.NameEn)} </> : <>{data2.TitleAr} {data2.NameAr} </>}</p>;
                  })}
                </>
              )}
            </div>
          </div> :  <Lottie animationData={Animate} loop={true} className="Lottie searchLottie" />
          }
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <HorseDetail data={modaldata} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        show={showJocley}
        onHide={handleCloseJockey}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <JockeyDetail data={modaldataJockey} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        show={showtrainer}
        onHide={handleClosetrainer}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <TrainerDetail data={modaldatatrainer} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        show={showowner}
        onHide={handleCloseowner}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <OwnerDetail data={modaldataowner} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};
export default Search;
