import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { fetchLatestResultOfHorsesWithOwner } from "../../redux/getReducer/getSingleOwnerStats";
import { fetchownermodalstats } from "../../redux/getReducer/getOwnerModalstats";
import Moment from "react-moment";
import PleaseWait from "../Reuseable/PleaseWait";
import arrow1 from "../../assets/image 13 (Traced).png";
import VideoFrame from "../Modal/VideoFrame";
import HorseModal from "../Modal/HorseModal";
import JockeyDetail from "../Modal/JockeyModal";
import RaceModal from "../Modal/RaceModal";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import ScrollContainer from "react-indiana-drag-scroll";
import { Accordion, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";

const OwnerStats = ({ id }) => {
  const { data: LatestResultOfHorsesWithOwner } = useSelector(
    (state) => state.LatestResultOfHorsesWithOwner
  );

  const { data: ownermodalstats } = useSelector(
    (state) => state.ownermodalstats
  );
  const [activeAccordion, setActiveAccordion] = useState(null);


  const dispatch = useDispatch();
  const [year, setyear] = useState("");

  const [shows, setShows] = useState(false);
  const [modaldatas, setmodaldatas] = useState();
  const handleCloses = () => setShows(false);
  const handleShows = async (data) => {
    setmodaldatas(data);
    await setShows(true);
  };

  const [ShowHorse, setShowHorse] = useState(false);
  const [modaldatasHorse, setmodaldatasHorse] = useState();
  const handleHorseCloses = () => setShowHorse(false);
  const handleShowHorse = async (data) => {
    setmodaldatasHorse(data);
    await setShowHorse(true);
  };
  const [showRace, setShowRace] = useState(false);
  const [modaldataRace, setmodaldataRace] = useState();
  const handleCloseRace = () => setShowRace(false);
  const handleShowRace = async (id) => {
    setmodaldataRace(id);
    await setShowRace(true);
  };

  const [ShowJockey, setShowJockey] = useState(false);
  const [modaldatasJockey, setmodaldatasJockey] = useState();
  const handleJockeyCloses = () => setShowJockey(false);
  const handleShowJockey = async (data) => {
    setmodaldatasJockey(data);
    await setShowJockey(true);
  };

  const { t } = useTranslation();
  const cookiedata = Cookies.get("i18next");
  useEffect(() => {
    dispatch(fetchLatestResultOfHorsesWithOwner({year , id }));
    dispatch(fetchownermodalstats({ id }));
  }, [dispatch, id]);

  const handleTrainerRecord = (year) => {
    dispatch(fetchLatestResultOfHorsesWithOwner({ year, id }));
  };
  function distanceLabel(distance, rank) {
    const DistanceTobeChecked = parseFloat(distance);
    console.log(DistanceTobeChecked);
    if (DistanceTobeChecked.toFixed(2) > 25) {
      return "Dist";
    } else if (DistanceTobeChecked.toFixed(2) == 0.05 && rank < 80) {
      return "SHd";
    } else if (DistanceTobeChecked.toFixed(2) == 0.10 && rank < 80) {
      return "Hd";
    } else if (DistanceTobeChecked.toFixed(2) == 0.25 && rank < 80) {
      return "Nk";
    } else if (DistanceTobeChecked.toFixed(2) == 0.01 && rank < 80) {
      return "Ns";
    } else if (DistanceTobeChecked.toFixed(2) == 0.00 && rank < 80) {
      if(rank === 1){
        return 0;
      }
      return "Dh";
    } else {
      return distance;
    }
  }


  console.log(ownermodalstats,"ownermodalstats>>>>>>>>>>>>>>>>>>>>>>>>>>>>")

  return (
    <>
       <div className="mycardclass1">
        <div className="formobilescroll1">
        <table className="statsTable innermodalstats mycss">
            <thead>
              <tr className="mycss1">
                <td>{t("Year")}</td>
                <td>{t("Runs")} </td>
                <td>{t("Wins")} </td>
                <td>{t("Second")}</td>
                <td>{t("Third")} </td>
                <td>{t("Fourth")} </td>
                <td>{t("Fifth")} </td>
                <td>{t("Earning")} </td>
                <td>{t("Wins")}% </td>
                <td></td>
              </tr>
            </thead>
          </table>
          <Accordion>
          <Accordion.Item
    className={`trainerstatsbtn1 data_border ${activeAccordion === null ? "active" : ""}`}
    eventKey="0"
    onClick={() => {
      if (activeAccordion === null) {
        setActiveAccordion(0);
      } else {
        setActiveAccordion(null);
      }
      handleTrainerRecord("");
    }}
  >
              <div>
                <Accordion.Header>
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr className="statsmodalhorse1">
                        <td>OverAll</td>
                        <td>{ownermodalstats.OverAll?.Runs}</td>
                        <td>{ownermodalstats.OverAll?.wins}</td>
                        <td>{ownermodalstats.OverAll?.second}</td>
                        <td>{ownermodalstats.OverAll?.third}</td>
                        <td>{ownermodalstats.OverAll?.fourth}</td>
                        <td>{ownermodalstats.OverAll?.fifth}</td>
                        <td>{ownermodalstats.OverAll?.earning}</td>
                        <td>{ownermodalstats.OverAll?.winspercentage}</td>
                        <td>
                          <IoIosArrowDropdownCircle />
                        </td>
                      </tr>
                    </thead>
                  </table>{" "}
                </Accordion.Header>
                <Accordion.Body key="i" className="trainerstatsbtn1">
                <div className="raceCardTable">
                          <ScrollContainer>
                            <div className="mycardclass1">
                              <div className="BodyNew mobiledatascroll">
                                <table className="customers">
                                  <thead className="customers5">
                                    <tr>
                                      <th>{t("Date")}</th>
                                      <th>{t("Crs")}</th>
                                      <th>{t("Len")}</th>
                                      <th>{t("TC")}</th>
                                      <th>{t("RT")}</th>

                                      <th>{t("Dts")}</th>
                                      <th>{t("Time")}</th>
                                      <th>{t("Jockey")}</th>
                                      <th>{t("Wgt")}</th>
                                      <th>{t("FP")}</th>
                                      <th>{t("Dist")}</th>
                                      <th>{t("RN")}</th>
                                      <th>{t("BtBy")}</th>
                                      <th>{t("Kgs")}</th>

                                      <th>{t("Draw")}</th>
                                      <th>{t("Link")}</th>
                                    </tr>
                                  </thead>
                                  <tbody className="customers1">
                                    {LatestResultOfHorsesWithOwner === undefined ? (
                                      <PleaseWait />
                                    ) : (
                                      <>
                                        {!LatestResultOfHorsesWithOwner ? (
                                          <PleaseWait />
                                        ) : (
                                          LatestResultOfHorsesWithOwner.map((item, i) => {
                                            return (
                                              <tr
                                          className="BodyNew1 historyhorseth "
                                          style={{
                                            background: `${
                                              item.RaceResultData
                                                ?.TrackLengthData
                                                ?.GroundTypeModelData.NameEn ==
                                              "Dirt"
                                                ? "#9F5109"
                                                : item.RaceResultData
                                                    ?.TrackLengthData
                                                    ?.GroundTypeModelData
                                                    .NameEn == "Turf"
                                                ? "#5ec40f"
                                                : "#949494"
                                            }`,
                                          }}
                                          key={i}
                                        >
                                          <td
                                            className="lb"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              handleShowRace(item.RaceID)
                                            }
                                          >
                                            <Moment format="DD/MM/YYYY">
                                              {item.RaceResultData === null ? (
                                                <></>
                                              ) : (
                                                item.RaceResultData.StartTime
                                              )}
                                            </Moment>
                                          </td>
                                          <td>
                                            {item.RaceResultData &&
                                            cookiedata === "en"
                                              ? item.RaceResultData
                                                  ?.RaceCourseData?.AbbrevEn
                                              : item.RaceResultData &&
                                                item.RaceResultData
                                                  ?.RaceCourseData?.AbbrevAr}

(
                                        {cookiedata === "en" ? (
                                          item.RaceResultData &&
                                          item.RaceResultData ? (
                                            item.RaceResultData?.TrackLengthData?.GroundTypeModelData?.NameEn.charAt(
                                              0
                                            )
                                          ) : (
                                            <>-</>
                                          )
                                        ) : item.RaceResultData ? (
                                          item.RaceResultData?.TrackLengthData?.GroundTypeModelData?.NameAr.charAt(
                                            0
                                          )
                                        ) : (
                                          <>-</>
                                        )}
                                        )

                                          </td>
                                          <td>
                                          {
                                                    distanceLabel(item.Distance &&
                                                      item.Distance , item.FinalPositionDataHorse &&
                                                      item.FinalPositionDataHorse?.Rank)
                                                  }
                                                 
                                          </td>
                                          {/* <td>- </td> */}
                                          <td>
                                            {item.RaceResultData &&
                                              item.RaceResultData
                                                .TrackConditionData.NameEn}
                                          </td>

                                          <td>
                                            <OverlayTrigger
                                              className="tooltip-btn"
                                              overlay={
                                                <Tooltip
                                                  id="tooltip-disabled"
                                                  className="tooltip-btn"
                                                >
                                                  {item.RaceResultData &&
                                                  item.RaceResultData
                                                    ? cookiedata === "en"
                                                      ? item.RaceResultData
                                                          ?.RaceTypeModelData
                                                          ?.NameEn
                                                      : item.RaceResultData &&
                                                        item?.RaceResultData
                                                          ?.RaceTypeModelData
                                                          .NameAr
                                                    : "_"}
                                                </Tooltip>
                                              }
                                            >
                                              <span
                                                className="d-inline-block"
                                                style={{
                                                  marginTop: "12px",
                                                }}
                                              >
                                                <p className="tooltipclass1">
                                                  {" "}
                                                  {item.RaceResultData &&
                                                  item.RaceResultData
                                                    ? cookiedata === "en"
                                                      ? item.RaceResultData
                                                          ?.RaceTypeModelData
                                                          ?.NameEn
                                                      : item.RaceResultData &&
                                                        item?.RaceResultData
                                                          ?.RaceTypeModelData
                                                          .NameAr
                                                    : "_"}
                                                </p>
                                              </span>
                                            </OverlayTrigger>
                                          </td>
                                          <td> {item?.PrizeWin ? item.PrizeWin : 0}</td>
                                          <td>
                                            {
                                              <Moment format="hh:mm:ss">
                                                {item.RaceResultData ===
                                                null ? (
                                                  <></>
                                                ) : (
                                                  item.RaceResultData.StartTime
                                                )}
                                              </Moment>
                                            }
                                          </td>
                                          <td>
                                            {item.JockeyOnRaceDataResult &&
                                            cookiedata === "en"
                                              ? item.JockeyOnRaceDataResult
                                                  ?.ShortNameEn
                                              : item.JockeyOnRaceDataResult &&
                                                item.JockeyOnRaceDataResult
                                                  ?.ShortNameAr}
                                          </td>


                                          <td>
                                        {item.HorseIDData && item?.HorseIDData?.BeatenByJockeyWeight}
                                          
                                      </td>



                                          <td>
                                            {item.FinalPositionDataHorse &&
                                            cookiedata === "en"
                                              ? item.FinalPositionDataHorse
                                                  .NameEn
                                              : item.FinalPositionDataHorse &&
                                                item.FinalPositionDataHorse
                                                  .NameAr}
                                          </td>
                                          <td>
                                            {item.RaceResultData &&
                                              item.RaceResultData
                                                .TrackLengthData.TrackLength}
                                          </td>
                                          <td>
                                            {item.RaceResultData &&
                                              item.RaceResultData &&
                                              item.RaceResultData.Runners}
                                          </td>

                                          <td>
                                            { window.textTransform(item.BeatenByData &&
                                              item?.BeatenByData?.NameEn)}
                                          </td>
                                          <td>
                                            {item.BeatenByData &&
                                              item.BeatenByData
                                                .WinningHorseJockeyWeight?.toFixed(1)}
                                          </td>
                                          <td>
                                            {item.RaceResultData &&
                                              item.RaceResultData.Draw}
                                          </td>
                                          <td
                                            className="rb"
                                            onClick={() =>
                                              handleShows(item.VideoLink)
                                            }
                                            style={{
                                              cursor: "pointer",
                                            }}
                                          >
                                            <img src={arrow1} alt="" />
                                          </td>
                                        </tr>
                                            );
                                          })
                                        )}
                                      </>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </ScrollContainer>
                        </div>
                </Accordion.Body>
              </div>
            </Accordion.Item>
          </Accordion>
          <Accordion defaultActiveKey="0">

          {ownermodalstats?.data &&
            ownermodalstats?.data.map((item, i) => (
              <>
                   <Accordion.Item
        className={`trainerstatsbtn1 ${activeAccordion === i ? "active" : ""}`}
        eventKey={i}
        onClick={() => {
          setActiveAccordion(i);
          handleTrainerRecord(item.Years);
        }}
      >
                    <div>
                      <Accordion.Header>
                        <table style={{ width: "100%" }}>
                          <thead>
                            <tr className="statsmodalhorse1">
                              <td>{item?.Years}</td>
                              <td>{item.Detail?.Runs}</td>
                              <td>{item.Detail?.wins}</td>
                              <td>{item.Detail?.second}</td>
                              <td>{item.Detail?.third}</td>
                              <td>{item.Detail?.fourth}</td>
                              <td>{item.Detail?.fifth}</td>
                              <td>{item.Detail?.earning}</td>
                              <td>{item.Detail?.winspercentage}</td>
                              <td>
                                <IoIosArrowDropdownCircle />
                              </td>
                            </tr>
                          </thead>
                        </table>{" "}
                      </Accordion.Header>
                      <Accordion.Body key="i" className="trainerstatsbtn1">
                        <div className="raceCardTable">
                          <ScrollContainer>
                            <div className="mycardclass1">
                              <div className="BodyNew mobiledatascroll">
                                <table className="customers">
                                  <thead className="customers5">
                                    <tr>
                                      <th>{t("Date")}</th>
                                      <th>{t("Crs")}</th>
                                      <th>{t("Dist")}</th>
                                      <th>{t("TC")}</th>
                                      <th>{t("RT")}</th>

                                      <th>{t("Dts")}</th>
                                      <th>{t("Time")}</th>
                                      <th>{t("Jockey")}</th>
                                      {/* <th>{t("Wgt")}</th> */}
                                      <th>{t("FP")}</th>
                                      <th>{t("Len")}</th>
                                      <th>{t("RN")}</th>
                                      <th>{t("BtBy")}</th>
                                      <th>{t("Kgs")}</th>

                                      <th>{t("Draw")}</th>
                                      <th>{t("Link")}</th>
                                    </tr>
                                  </thead>
                                  <tbody className="customers1">
                                    {LatestResultOfHorsesWithOwner === undefined ? (
                                      <PleaseWait />
                                    ) : (
                                      <>
                                        {!LatestResultOfHorsesWithOwner ? (
                                          <PleaseWait />
                                        ) : (
                                          LatestResultOfHorsesWithOwner.map((item, i) => {
                                            return (
                                              <tr
                                                className="BodyNew1 historyhorseth tempcolor"
                                                style={{
                                                  background: `${
                                                    item.RaceResultData
                                                      ?.TrackLengthData
                                                      ?.GroundTypeModelData
                                                      .NameEn == "Dirt"
                                                      ? "#5ec40f"
                                                      : item.RaceResultData
                                                          ?.TrackLengthData
                                                          ?.GroundTypeModelData
                                                          .NameEn == "Turf"
                                                      ? "#9F5109"
                                                      : "#949494"
                                                  }`,
                                                }}
                                                key={i}
                                              >
                                                <td
                                                  className="lb"
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() =>
                                                    handleShowRace(item.RaceID)
                                                  }
                                                >
                                                  <Moment format="DD/MM/YYYY">
                                                    {item.RaceResultData ===
                                                    null ? (
                                                      <></>
                                                    ) : (
                                                      item.RaceResultData
                                                        .StartTime
                                                    )}
                                                  </Moment>
                                                </td>
                                                <td>
                                                  {item.RaceResultData &&
                                                  cookiedata === "en"
                                                    ? item.RaceResultData
                                                        ?.RaceCourseData
                                                        ?.AbbrevEn
                                                    : item.RaceResultData &&
                                                      item.RaceResultData
                                                        ?.RaceCourseData
                                                        ?.AbbrevAr}
                                                </td>
                                                <td>
                                                {
                                                    distanceLabel(item.Distance &&
                                                      item.Distance , item.FinalPositionDataHorse &&
                                                      item.FinalPositionDataHorse?.Rank)
                                                  }
                                                </td>
                                               

                                                {/* <td>- </td> */}
                                                <td>
                                                  {item.RaceResultData &&
                                                    item.RaceResultData
                                                      .TrackConditionData
                                                      .NameEn}
                                                </td>

                                                <td>
                                                  <OverlayTrigger
                                                    className="tooltip-btn"
                                                    overlay={
                                                      <Tooltip
                                                        id="tooltip-disabled"
                                                        className="tooltip-btn"
                                                      >
                                                        {item.RaceResultData &&
                                                        item.RaceResultData
                                                          ? cookiedata === "en"
                                                            ? item
                                                                .RaceResultData
                                                                ?.RaceTypeModelData
                                                                ?.NameEn
                                                            : item.RaceResultData &&
                                                              item
                                                                ?.RaceResultData
                                                                ?.RaceTypeModelData
                                                                .NameAr
                                                          : "_"}
                                                      </Tooltip>
                                                    }
                                                  >
                                                    <span
                                                      className="d-inline-block"
                                                      style={{
                                                        marginTop: "12px",
                                                      }}
                                                    >
                                                      <p className="tooltipclass1">
                                                        {" "}
                                                        {item.RaceResultData &&
                                                        item.RaceResultData
                                                          ? cookiedata === "en"
                                                            ? item
                                                                .RaceResultData
                                                                ?.RaceTypeModelData
                                                                ?.NameEn
                                                            : item.RaceResultData &&
                                                              item
                                                                ?.RaceResultData
                                                                ?.RaceTypeModelData
                                                                .NameAr
                                                          : "_"}
                                                      </p>
                                                    </span>
                                                  </OverlayTrigger>
                                                </td>
                                                <td>{item?.PrizeWin}</td>
                                                <td>
                                                  {
                                                    <Moment format="hh:mm:ss">
                                                      {item.RaceResultData ===
                                                      null ? (
                                                        <></>
                                                      ) : (
                                                        item.RaceResultData
                                                          .StartTime
                                                      )}
                                                    </Moment>
                                                  }
                                                </td>
                                                <td>
                                                  {item.JockeyOnRaceDataResult &&
                                                  cookiedata === "en"
                                                    ? item
                                                        .JockeyOnRaceDataResult
                                                        .NameEn
                                                    : item.JockeyOnRaceDataResult &&
                                                      item
                                                        .JockeyOnRaceDataResult
                                                        .NameAr}
                                                </td>
                                                <td>
                                                  {item.FinalPositionDataHorse &&
                                                  cookiedata === "en"
                                                    ? item
                                                        .FinalPositionDataHorse
                                                        .NameEn
                                                    : item.FinalPositionDataHorse &&
                                                      item
                                                        .FinalPositionDataHorse
                                                        .NameAr}
                                                </td>
                                                <td>
                                                  {item.RaceResultData &&
                                                    item.RaceResultData
                                                      .TrackLengthData
                                                      .TrackLength}
                                                </td>
                                               
                                                <td>
                                                  {item.RaceResultData &&
                                                    item.RaceResultData &&
                                                    item.RaceResultData.Runners}
                                                </td>

                                                <td>
                                                  {item.BeatenByData &&
                                                    item.BeatenByData.NameEn}
                                                </td>
                                                <td>
                                                  {item.BeatenByData &&
                                                    item.BeatenByData
                                                      .WinningHorseJockeyWeight?.toFixed(1)}
                                                </td>
                                                <td>
                                                  {item.RaceResultData &&
                                                    item.RaceResultData.Draw}
                                                </td>
                                                <td
                                                  className="rb"
                                                  onClick={() =>
                                                    handleShows(item.VideoLink)
                                                  }
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  <img src={arrow1} alt="" />
                                                </td>
                                              </tr>
                                            );
                                          })
                                        )}
                                      </>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </ScrollContainer>
                        </div>
                      </Accordion.Body>
                    </div>
                  </Accordion.Item>
              </>
            ))}
                            </Accordion>

        </div>
      </div>
      <Modal
        show={shows}
        onHide={handleCloses}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <VideoFrame data={modaldatas} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        show={ShowHorse}
        onHide={handleHorseCloses}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <HorseModal data={modaldatasHorse} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        show={ShowJockey}
        onHide={handleJockeyCloses}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <JockeyDetail data={modaldatasJockey} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        show={showRace}
        onHide={handleCloseRace}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <RaceModal id={modaldataRace} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};
export default OwnerStats;
