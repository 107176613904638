//.....................Import..........................//
import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "../Components/CSS/RaceCardCSS/racedetail.css";
import { toast } from "react-toastify";
import flag from "../assets/United Arab Emirates.png";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { fetchsinglerace, STATUSES } from "../redux/getReducer/getSingleRace";
import { useLocation } from "react-router-dom";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import Zoom from "react-reveal/Zoom";
import Card from "react-bootstrap/Card";
import img from "../assets/image 10.png";
import img1 from "../assets/image 10 (1).png";
import Summary from "../Components/RaceCard/Summary";
import Draw from "../Components/RaceCard/Draw";
import Predictor from "../Components/RaceCard/Predictor";
import TrackRecord1 from "../Components/RaceCard/TrackRecord";
import arrow1 from "../assets/image 13 (Traced).png";
import Moment from "react-moment";
import PrintOut from "../Components/RaceCard/Printout";
import Header from "../Components/Reuseable/Header";
import ScrollContainer from "react-indiana-drag-scroll";
import { IoPartlySunnyOutline, IoCloudyOutline } from "react-icons/io5";
import Defaultimg from "../assets/default.jpg";
import RaceNumbers from "../Components/Reuseable/RaceNumbers";
import arrow2 from "../assets/arrow1.png";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import CardData from "../Components/RaceCard/Card";
import Lottie from "lottie-react";
import Animate from "../assets/loader.json";
import { fetchRacePredictor } from "../redux/getReducer/getRacePrediction";
import { fetchDraw } from "../redux/getReducer/getDraw";
import { fetchSummary } from "../redux/getReducer/getSummarySlice";
import { fetchTrackRecord } from "../redux/getReducer/TrackRecord";
import socket from "../../Socket";
import { Modal } from "react-bootstrap";
import CastModal from '../Components/Modal/CastModal'
import VideoFrame from "../Components/Modal/VideoFrame";
import Footer from "../Components/Reuseable/Footer";
import Copyrights from "../Components/Reuseable/Copyrights";


//.....................Function..........................//
const RaceDetails = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { state } = useLocation();
  const { data: singlerace, status } = useSelector((state) => state.singlerace);
  const { data: RacePredictor } = useSelector((state) => state.RacePredictor);
  const { data: draw } = useSelector((state) => state.draw);
  const { data: summary } = useSelector((state) => state.summary);
  const { data: TrackRecord } = useSelector((state) => state.TrackRecord);


  const [Disable, setDisable] = useState(false);
  const [show, setShow] = useState(false);
  const [History, setHistory] = useState([]);
  const [PositionNumber, setPositionNumber] = useState("1");
  const [activeTab, setActiveTab] = useState("Card");



  const [modaldata, setmodaldata] = useState();
  const handleClose = () => setShow(false);
  const handleShow = async (data) => {
    setmodaldata(data);
    await setShow(true);
  };
  const token = Cookies.get("token");


  function DataOne() {
    if (!state) {
      return (window.location.href = "/");
    }
  }
  DataOne();

  const { id } = state;

  useEffect(() => {
    dispatch(fetchsinglerace({ id }));
  }, [id]);
  const [castdependency, setcastdependency] = useState(false);

  const [showCast, setShowCast] = useState(false);
  const [modaldataCast, setmodaldataCast] = useState();
  const handleCloseCast = () => {
    setShowCast(false);
    setcastdependency(true)
  }
  const handleShowCast = async (data) => {
    setmodaldataCast(data);
    await setShowCast(true);
  };

  console.log(showCast,'showCast')




  const btnNew1 = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
    gap: "10px",
    width: "112px",

    height: "24px",
    background: "#FF0000",
    borderRadius: "10px",
    border: "none",
    color: "#fff",
  };
  const btnNewdelete = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
    gap: "10px",
    width: "112px",
    marginTop:"10px",
    height: "24px",
    background: "#19469D",
    borderRadius: "10px",
    border: "none",
    color: "#fff",
  };

  if (status === STATUSES.LOADING) {
    <div className="py-4 text-center">
      <div>
        <Lottie animationData={Animate} loop={true} className="load" />
      </div>
    </div>;
  }

  if (status === STATUSES.ERROR) {
    return (
      <h2
        style={{
          margin: "100px",
          width: "50%",
        }}
      >
        Something went wrong!
      </h2>
    );
  }
  //.....................Function for Toggle to History..........................//
  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      console.log("")
    );

    return (
      <button
        type="button"
        className="ShowPreviousHistory"
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }


  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
    if (tabIndex == "Predictor") {
      dispatch(fetchRacePredictor({ id }));
      socket.emit("createRoom",id)
    } else if (tabIndex == "Draw") {
      dispatch(fetchDraw({ id }))
      socket.emit("leaveroom",id)

    }
    else if (tabIndex == "Summary") {
      dispatch(fetchSummary({ id }));
    }
    else if (tabIndex == "Track Record") {
      dispatch(fetchTrackRecord({ id }));
    }
    else if (tabIndex == "Printout") {
    }
  };



  //.....................Function of Competition..........................//
  const castClick = async (event, horseid, compid) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `/voting/${compid}/${id}/${PositionNumber}`,
        { Horse: horseid },
        {
          withCredentials: true,
        }
      );
      const msgdata = response.data.data.message;
      toast(msgdata);
      setDisable(false);
    } catch (error) {
      const err = error.response.data.message;
      toast(err);
      setDisable(false);
    }
  };
  const pickClick = async (event, compid, horseid) => {
    event.preventDefault();

    try {
      setDisable(true);
      const response = await axios.post(
        `/voting/${compid}/${id}/${PositionNumber}`,
        { Horse: horseid },
        {
          withCredentials: true,
        }
      );

      const msgdata = response.data.message;
      toast(msgdata);
      setDisable(false);
    } catch (error) {
      const err = error.response.data.message;
      toast(err);
      setDisable(false);
    }
  };
  let menuItems = [];
  for (var i = 0; i < 4; i++) {
    menuItems.push(
      <form>
        <input type="radio" name="contact" />
      </form>
    );
  }

  const runCallback = (cb) => {
    return cb();
  };

  

  //.....................CSS.........................//
  const myPara = {
    fontWeight: "700",
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.5)",
    paddingLeft: "3px",
  };

  const btnNew = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "13px 10px",
    gap: "10px",
    width: "139px",
    height: "24px",
    background: "#19469D",
    borderRadius: "10px",
    border: "none",
    color: "#fff",
    marginLeft: "10px",
  };
 
  //.....................Function of Horse History..........................//
  const showHorseHistory = async (horseid) => {
    const res = await axios.get(
      `${window.env.API_URL}/horsehistory/${horseid}`
    );
    setHistory(res.data.data);
  };

  const cookiedata = Cookies.get("i18next");
  

  const DataDate = new Date(singlerace.racedetail && singlerace.racedetail.StartTime === null ? "" :singlerace.racedetail && singlerace.racedetail.StartTime);

  function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }
  const formatedData = formatAMPM(DataDate);

  let link = singlerace.RaceResultData
    ? singlerace.RaceResultData[0]
      ? singlerace.RaceResultData[0].VideoLink
      :false
    : false;

  // let link = 'https://www.youtube.com/watch?v=nr3wCgKzSIA'

  
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const options = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };
  return (
    <>
      <Zoom>
        <div className="RaceCardDetail ">
          {singlerace && singlerace.racedetail !== undefined  ? (
            <>
              <div className="RaceDetailHeader">
                <div>
                  <div className="colorheader">
                    <div>
                      <span className="racenameflex">
                      <p>
                        {cookiedata === "en" ? (
                          singlerace.racedetail.RaceCourseData === undefined ? (
                            <>- </>
                          ) : (
                            singlerace.racedetail.RaceCourseData.TrackNameEn
                          )
                        ) : singlerace.racedetail.RaceCourseData === undefined ? (
                          <>- </>
                        ) : (
                          singlerace.racedetail.RaceCourseData.TrackNameAr
                        )}
                      </p>
                        <img src={flag} alt="" />
                      </span>
                      <p className="itemtime">
                      {
                        formatDate(singlerace.racedetail.Day)
                      }
                      </p>
                      <RaceNumbers
                    racecourseid={
                      singlerace.racedetail.RaceCourseData === undefined
                        ? ""
                        : singlerace.racedetail.RaceCourseData._id
                    }
                  />
                    </div>
                  </div>
                  <div className="racedisc">
                  <div className="itemraces">
                    <div className="inner_itemraces">
                      <span className="itemraces_left">
                        <span
                          className="race"
                          style={{
                            backgroundColor: `${singlerace.racedetail.RaceStatus === "Cancelled"
                              ? "#000000"
                              : singlerace.racedetail.RaceStatus === "Completed"
                                ? "#FF0000"
                                : singlerace.racedetail.RaceStatus === "Live"
                                  ? "#5EC30F"
                                  : "#FF9900"
                              }`,
                            color: `${singlerace.racedetail.RaceStatus === "Cancelled"
                              ? "#ffff"
                              : singlerace.racedetail.RaceStatus === "Completed"
                                ? "#00000"
                                : singlerace.racedetail.RaceStatus === "Live"
                                  ? "#00000"
                                  : "#000000"
                              }`,
                          }}
                        >
                          <p>
                            {t("Race")} {singlerace.racedetail.RaceNumber}
                          </p>
                          <p>
                            {formatedData}
                            {/* {" "}
                        {new Date(singlerace.racedetail.StartTime)
                          .toLocaleTimeString()
                          .slice(0, 4)                      
                          } */}
                            {/* m */}
                          </p>
                        </span>
                        {singlerace.racedetail.SponsorData ? (
                          <>
                            <a
                              href={
                                singlerace.racedetail.SponsorData ? (
                                  singlerace.racedetail.SponsorData.Url
                                ) : (
                                  <></>
                                )
                              }
                              target="_blank"
                            >
                              <img
                                className="sponsor"
                                src={
                                  singlerace.racedetail.SponsorData.image === null
                                    ? Defaultimg
                                    : singlerace.racedetail.SponsorData.image
                                }
                                alt=""
                              />
                            </a>
                          </>
                        ) : (
                          <></>
                        )}
                       
                      </span>
                      <span className="itemraces_right formobile1">
                       
                       <span className="distance">
                         <img
                           src={
                             singlerace.racedetail.TrackLengthData &&
                             singlerace.racedetail.TrackLengthData.RaceCourseImage
                           }
                           alt=""
                         />
                       </span>
                       
                     </span>
                      <span className="itemraces_center">
                        <OverlayTrigger
                          className="tooltip-btn"
                          overlay={
                            <Tooltip
                              id="tooltip-disabled"
                              className="tooltip-btn"
                            >
                              {cookiedata === "en" ? (
                                singlerace.racedetail.RaceNameModelData === undefined ? (
                                  <>- </>
                                ) : (
                                  singlerace.racedetail.RaceNameModelData.NameEn
                                )
                              ) : singlerace.racedetail.RaceNameModelData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.racedetail.RaceNameModelData.NameAr
                              )}
                            </Tooltip>
                          }
                        >
                          <span className="d-inline-block">
                            <h5 className="tooltipclass">
                              {" "}
                              {cookiedata === "en" ? (
                                singlerace.racedetail.RaceNameModelData === undefined ? (
                                  <>- </>
                                ) : (
                                  singlerace.racedetail.RaceNameModelData.NameEn
                                )
                              ) : singlerace.racedetail.RaceNameModelData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.racedetail.RaceNameModelData.NameAr
                              )}
                            </h5>
                          </span>
                        </OverlayTrigger>

                        <div
                          className="racecardcss1"
                        >
                          <p
                            style={{
                              padding: "1px",
                            }}
                          >
                            <b>{t("RK")}</b> :  <span>  </span>
                            {cookiedata === "en" ? (
                              singlerace.racedetail.RaceKindData === undefined ? (
                                <>- </>
                              ) : (
                                window.textTransform(singlerace.racedetail.RaceKindData.NameEn)
                              )
                            ) : singlerace.racedetail.RaceKindData === undefined ? (
                              <>- </>
                            ) : (
                              singlerace.racedetail.RaceKindData.NameAr
                            )}
                          </p>

                          <p
                           style={{
                            color:"#ff0000"
                          }}
                          >
                           <b style={{color:"#000"}}> {t("RT")} </b>:  <span>  </span>
                            {cookiedata === "en" ? (
                              singlerace.racedetail.RaceTypeModelData === undefined ? (
                                <>- </>
                              ) : (
                                window.textTransform(singlerace.racedetail.RaceTypeModelData.NameEn)
                              )
                            ) : singlerace.racedetail.RaceTypeModelData === undefined ? (
                              <>- </>
                            ) : (
                              singlerace.racedetail.RaceTypeModelData.NameAr
                            )}
                          </p>
                          <p
                          style={{
                            fontWeight:"500",
                            color:"#ff0000"

                          }}
                        >
                         <b style={{color:"#000"}}>  {t("HK")}</b> :  <span>  </span>
                          {cookiedata === "en" ? (
                            singlerace.racedetail.HorseKindinRaceData === undefined ? (
                              <>- </>
                            ) : (
                              window.textTransform(singlerace.racedetail.HorseKindinRaceData.NameEn)
                            )
                          ) : singlerace.racedetail.HorseKindinRaceData === undefined ? (
                            <>- </>
                          ) : (
                            singlerace.racedetail.HorseKindinRaceData.NameAr
                          )}
                        </p>
                        </div>
                        <p
                          style={{
                            padding: "1px",
                            marginTop: "-20px",
                          }}
                        >
                          {cookiedata === "en" ? (
                            singlerace.racedetail.HorseKindinRaceData === undefined ? (
                              <>- </>
                            ) : (
                              singlerace.racedetail.HorseKindinRaceData.AbbrevEn
                            )
                          ) : singlerace.racedetail.HorseKindinRaceData === undefined ? (
                            <>- </>
                          ) : (
                            singlerace.racedetail.HorseKindinRaceData.AbbrevAr
                          )}
                        </p>
                      </span>
                      <span className="itemraces_right">
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            margin: "12px",
                          }}
                        >
                         
                          {/* <img src={weather} alt="" /> */}
                        </p>
                        <span className="distance">
                          <img
                            src={
                              singlerace.racedetail.TrackLengthData &&
                              singlerace.racedetail.TrackLengthData.RaceCourseImage
                            }
                            alt=""
                          />
                        </span>
                        <div className="Favourite">
                          <div>
                            <p>{t("Favourite")}</p>
                            <br />
                            {/* <div
                          style={{
                            display: "flex",
                            width:'100px',
                            justifyContent:'space-between'
                          }}
                        >
                          <p>Notre Dame</p>
                          <img src={arrow} alt="" className="videoicon" />
                        </div>
                        <p>4.40</p> */}
                          </div>
                        </div>
                      </span>
                    </div>
                    {singlerace.racedetail.RaceStatus === "Completed" ? (
                      <div className="resultimages">
                        <span className="resultimagesRunningTime">
                          <p>{t("RunningTime")}</p>
                          <h4>
        
                             {singlerace.racedetail.RaceTimeAcheived
                              ? singlerace.racedetail.RaceTimeAcheived
                              : "00:00:00"}
                          </h4>
                        </span>

                        {singlerace.racedetail.RaceimagesData.length !== 0 ? (
                          singlerace.racedetail.RaceimagesData.slice(0, 4).map((data) => {
                            return (
                              <span className="resultimagebox">
                                <img src={data.image} alt="" />
                              </span>
                            );
                          })
                        ) : (
                          <>
                            <span className="resultimagebox"></span>
                            <span className="resultimagebox"></span>
                            <span className="resultimagebox"></span>
                            <span className="resultimagebox"></span>
                          </>
                        )}


{
                          link ? <span
                          className="resultimagevideo"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => handleShow(link)}
                        >
                          <img src={arrow2} alt="img" />

                          <p>{t("WatchNow")}</p>
                        </span>  : <></>
                        }
                       
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="hosekindSection"></div>
                    <p>
                      <b>{t("Description")}</b> :
                      {cookiedata === "en" ? (
                        <>{singlerace.racedetail.DescriptionEn}</>
                      ) : (
                        <>{singlerace.racedetail.DescriptionAr}</>
                      )}
                    </p>
                  </div>
                </div>
                  <div className="prizecard">
                    <div className="prizecardheaders">
                    <p>
                      {t("Total Prize")}:

                      {cookiedata === "en" ? (
                          <b>
                            {singlerace.racedetail.CurrencyData === undefined ? (
                              <>- </>
                            ) : (
                              singlerace.racedetail.CurrencyData.NameEn
                            )}
                          </b>
                        ) : (
                          <b>
                            {singlerace.racedetail.CurrencyData === undefined ? (
                              <>- </>
                            ) : (
                              singlerace.racedetail.CurrencyData.NameAr
                            )}
                          </b>
                        )}

                      <b className="mx-1">
                        {singlerace.racedetail.FirstPrice +
                          singlerace.racedetail.SecondPrice +
                          singlerace.racedetail.ThirdPrice +
                          singlerace.racedetail.FourthPrice +
                          singlerace.racedetail.FifthPrice +
                          singlerace.racedetail.SixthPrice}

                        
                      </b>
                    </p>
                     {
                    singlerace.racedetail.RaceStatus === "Live" &&  singlerace.CompetitionCategory === "cast" &&  token ? 
                    <>
                     {
                        singlerace.CompetitionCategory === "cast"  && singlerace.votedhorse?.length ==  0 ?   <button
                        style={btnNew1}
                        onClick={() => handleShowCast(singlerace)}
                      >
                       
                         {t("cast")}
                        
                      </button> :  <button
                        style={btnNewdelete}
                        onClick={() => handleShowCast(singlerace)}
                      >
                       
                         {t("casted")}
                        
                      </button>
                      }
                    </>
                    : <></>
                    }
                    </div>
                    <ScrollContainer className="scroll-container">
                      <div>
                      <div className="Competitiontrophy">
                    <div className="Trophydata">
                      <span>{t("1st")}</span>
                      <span>
                        <img src={img1} alt="" />
                      </span>
                      <div className="Trophydata_P">
                        <h6>
                          {singlerace.racedetail.FirstPrice}{" "}
                          {cookiedata === "en" ? (
                            <>
                              {singlerace.racedetail.CurrencyData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.racedetail.CurrencyData.NameEn
                              )}
                            </>
                          ) : (
                            <>
                              {singlerace.racedetail.CurrencyData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.racedetail.CurrencyData.NameAr
                              )}
                            </>
                          )}
                        </h6>
                      </div>
                    </div>
                    <div className="Trophydata">
                      <span>{t("2nd")}</span>
                      <span>
                        <img src={img} alt="" />
                      </span>
                      <div className="Trophydata_P">
                        <h6>
                          {singlerace.racedetail.SecondPrice}{" "}
                          {cookiedata === "en" ? (
                            <>
                              {singlerace.racedetail.CurrencyData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.racedetail.CurrencyData.NameEn
                              )}
                            </>
                          ) : (
                            <>
                              {singlerace.racedetail.CurrencyData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.racedetail.CurrencyData.NameAr
                              )}
                            </>
                          )}
                        </h6>
                      </div>
                    </div>
                    <div className="Trophydata">
                      <span>{t("3rd")}</span>
                      <span>
                        <img src={img} alt="" />
                      </span>
                      <div className="Trophydata_P">
                        <h6>
                          {singlerace.racedetail.ThirdPrice}{" "}
                          {cookiedata === "en" ? (
                            <>
                              {singlerace.racedetail.CurrencyData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.racedetail.CurrencyData.NameEn
                              )}
                            </>
                          ) : (
                            <>
                              {singlerace.racedetail.CurrencyData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.racedetail.CurrencyData.NameAr
                              )}
                            </>
                          )}
                        </h6>
                      </div>
                    </div>
                    <div className="Trophydata">
                      <span>{t("4th")}</span>
                      <span>
                        <img src={img} alt="" />
                      </span>
                      <div className="Trophydata_P">
                        <h6>
                          {singlerace.racedetail.FourthPrice}{" "}
                          {cookiedata === "en" ? (
                            <>
                              {singlerace.racedetail.CurrencyData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.racedetail.CurrencyData.NameEn
                              )}
                            </>
                          ) : (
                            <>
                              {singlerace.racedetail.CurrencyData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.racedetail.CurrencyData.NameAr
                              )}
                            </>
                          )}
                        </h6>
                      </div>
                    </div>
                    <div className="Trophydata">
                      <span>{t("5th")}</span>
                      <span>
                        <img src={img} alt="" />
                      </span>
                      <div className="Trophydata_P">
                        <h6>
                          {singlerace.racedetail.FifthPrice}{" "}
                          {cookiedata === "en" ? (
                            <>
                              {singlerace.racedetail.CurrencyData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.racedetail.CurrencyData.NameEn
                              )}
                            </>
                          ) : (
                            <>
                              {singlerace.racedetail.CurrencyData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.racedetail.CurrencyData.NameAr
                              )}
                            </>
                          )}
                        </h6>
                      </div>
                    </div>
                    {singlerace.racedetail.SixthPrice === 0 ? (
                      <></>
                    ) : (
                      <div className="Trophydata">
                        <span>{t("6th")}</span>
                        <span>
                          <img src={img} alt="" />
                        </span>
                        <div className="Trophydata_P">
                          <h6>
                            {singlerace.racedetail.SixthPrice}{" "}
                            {cookiedata === "en" ? (
                              <>
                                {singlerace.racedetail.CurrencyData === undefined ? (
                                  <>- </>
                                ) : (
                                  singlerace.racedetail.CurrencyData.NameEn
                                )}
                              </>
                            ) : (
                              <>
                                {singlerace.racedetail.CurrencyData === undefined ? (
                                  <>- </>
                                ) : (
                                  singlerace.racedetail.CurrencyData.NameAr
                                )}
                              </>
                            )}
                          </h6>
                        </div>
                      </div>
                    )}
                  </div>
                      </div>
                    </ScrollContainer>
                  </div>

                  {
                  singlerace?.Verdict?.length !== 0 ? 
                  <div className="prizecard prizecardverdict">
                  <div className="prizecardheaders">
                  <p>
                     <b>{t("verdictname")}</b> 
                    </p>
                    </div>
                  {
                      singlerace?.Verdict.length === 0 ? <></> : 
                      <div className="verdictrace">
                      <table>
                        <thead>
                        <tr className="verdictdata">
                                  <th>{t("verdictname")}</th>
                                  <th>{t("1st")} </th>
                                  <th>{t("2nd")}</th>
                                  <th>{t("3rd")}</th>
                                  <th>{t("Remarks")}</th>
                          </tr>
                          
                          {
                            singlerace.Verdict.map((item,i) => {
                              return(
                                <tr className="verdictdata">
                                 <td>{cookiedata === "en" ?  window.textTransform(item?.VerdictNameData?.NameEn) : item?.VerdictNameData?.NameAr}</td>
                                  <td>{cookiedata === "en" ?   window.textTransform(item?.HorseNo1Data?.NameEn) :  item?.HorseNo1Data?.NameAr}</td>
                                  <td>{cookiedata === "en" ?  window.textTransform(item?.HorseNo2Data?.NameEn) : item?.HorseNo2Data?.NameAr}</td>
                                  <td>{cookiedata === "en" ?  window.textTransform(item?.HorseNo3Data?.NameEn) : item?.HorseNo3Data?.NameAr}</td>
                                  <td>{cookiedata === "en" ? item?.Remarks: item.Remarks}</td>
                                </tr>
                              )
                            })
                          }
                          
                          
                        </thead>
                      </table>
                        </div>
                    }

                   
                  
                </div> : <></>
                }
                  <div className="RaceNav">
                  <Tabs
                    activeKey={activeTab} onSelect={handleTabChange}
                    defaultActiveKey="Card"
                    id="uncontrolled-tab-example"
                    className="mb-3 RaceNavItem"
                  >
                    <Tab
                      eventKey="Card"
                      title={t("Card")}
                      tabClassName="profile-tabitem"
                    >
                     <CardData /> 
                      
                    </Tab>
                    
                    {
                      singlerace?.racedetail?.RaceStatus == "Live" ?    <Tab
                      eventKey="Predictor"
                      title={t("Predictor")}
                      tabClassName="profile-tabitem"
                    >
                      <div className="RaceDetailCard">
                        <Predictor RacePredictor={RacePredictor} />
                      </div>
                    </Tab> : <></>
                    }

                    <Tab
                      eventKey="Draw"
                      title={t("Draw")}
                      tabClassName="profile-tabitem"
                    > 
                      <div className="RaceDetailCard datamobiletop" >
                        <Draw draw={draw} />
                      </div>
                    </Tab>
                    <Tab
                      eventKey="Summary"
                      title={t("Summary")}
                      tabClassName="profile-tabitem"
                    >
                      <div className="RaceDetailCard datamobiletop">
                        <Summary summary={summary} />
                      </div>
                    </Tab>
                    <Tab
                      eventKey="Track Record"
                      title={t("TrackRecord")}
                      tabClassName="profile-tabitem"
                    >
                      <div className="RaceDetailCard datamobiletop">
                        <TrackRecord1 TrackRecord={TrackRecord} />
                      </div>
                    </Tab>
                    <Tab
                      eventKey="Printout"
                      title={t("Printout")}
                      tabClassName="profile-tabitem"
                    >
                      <PrintOut />
                    </Tab>
                  </Tabs>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="NAclass">
                 <Lottie
                            animationData={Animate}
                            loop={true}
                            className="TableLottie"
                          />
            </div>
          )}

        </div>


        <Footer />
        <Copyrights />
      </Zoom>


      <Modal
        show={showCast}
        onHide={handleCloseCast}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="castfullmobile"
      >
        <Modal.Header className="popupheader11" closeButton></Modal.Header>
        <Modal.Body className="castmodal12">
          <CastModal data={{modaldataCast ,handleCloseCast}}/>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>



      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <VideoFrame data={modaldata} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>



    </>
  );
};

export default RaceDetails;
