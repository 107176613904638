import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAntePostRaces ,STATUSES} from "../../../redux/getReducer/getAntePost";
import { fetchCalender } from "../../../redux/getReducer/getCalender";

import "../../CSS/HomeCSS/blogs.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { BsCalendarDate } from "react-icons/bs";
import { Bounce, Fade } from "react-reveal";
import { useTranslation } from "react-i18next";
import Antepost from './AntePost'
import TodayPost from './TodayPost'
import CalenderComponent from './CalenderData'
import { Lottie } from "lottie-react";
import Animate from "../../../assets/loader.json";

const Match = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: ThemeSetting } = useSelector((state) => state.ThemeSetting);

  const { data: AntePostRaces ,status  } = useSelector((state) => state.AntePostRaces);
  const { data: CalenderData } = useSelector((state) => state.CalenderData);
  const [activeTab, setActiveTab] = useState("home"); // Assuming the initial active tab is the first tab

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
   if (tabIndex == "ante") {
    dispatch(fetchAntePostRaces());
    } else if (tabIndex == "contact") {
    dispatch(fetchCalender());
    }
  };
  if (status === STATUSES.LOADING) {
    <div className="py-4 text-center">
      <div>
        <Lottie animationData={Animate} loop={true} className="load" />
      </div>
    </div>;
  }

  if (status === STATUSES.ERROR) {
    return (
      <h2
        style={{
          margin: "100px",
          width: "50%",
        }}
      >
        Something went wrong!
      </h2>
    );
  }
  const activeTabTitleStyle = {
    backgroundColor: "red",
    // position: "absolute",
    height: "inherit",
    width: "inherit",
    // left: "19px",
    // marginTop: "-29px",
    borderRadius: "5px 5px 0px 0px",
    lineHeight: "45px",
    // backgroundColor: `${ThemeSetting.PrimaryColor}`,
    // Add more CSS properties here as needed
  };

  return (
    <div className="match">
      <Tabs
         activeKey={activeTab} onSelect={handleTabChange}
        defaultActiveKey="home"
        id="uncontrolled-tab-example"
        className="mb-4 tabdata1"
        
      >
        <Tab eventKey="home"
      className={activeTab === "home" ? activeTabTitleStyle : ""}
         title={t("current")} >
          <TodayPost  />
        </Tab>
      
        <Tab eventKey="ante" title={t("ante_post")} className="Ante_Post">
          <Antepost AntePostRaces={AntePostRaces} />
        </Tab>
        <Tab eventKey="contact" title={<BsCalendarDate />}>
          <div className="newpost">
            <Bounce top>
              <Fade>
                <CalenderComponent CalenderData={CalenderData}/>
              </Fade>
            </Bounce>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default Match;
