import React from 'react'
import RaceCardDetail from './RaceCardDetail'
import { Fragment } from 'react'
import Layout from "../Components/Reuseable/layout";
import Footer from '../Components/Reuseable/Footer';
import CoptRight from "../Components/Reuseable/Copyrights";

const SingleRace = () => {
    return (
        <Fragment>
            {/* <Layout /> */}
            <div className='singleRacewidth container'><RaceCardDetail /> </div>
            <Footer />
            <Footer />
            <CoptRight />
        </Fragment>
    )
}

export default SingleRace