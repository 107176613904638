import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getUserTrackedOwnerSlice = createSlice({
    name: 'UserTrackedOwner',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchUserTrackedOwner.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchUserTrackedOwner.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchUserTrackedOwner.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setUserTrackedOwner, setStatus } = getUserTrackedOwnerSlice.actions;
export default getUserTrackedOwnerSlice.reducer;


export const fetchUserTrackedOwner = createAsyncThunk('/UserTrackedOwnerget/fetch', async ({token}) => {
    const res = await axios.get(`${window.env.API_URL}/UserTrackedOwner`,{
        headers: { "Authorization": token, 'x-xsrf-token': token }
    });
    const UserTrackedOwnerData = res.data;
    return UserTrackedOwnerData.data;
})
