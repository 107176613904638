import Layout from "../Components/Reuseable/layout"
import '../Components/CSS/pagesCSS/about.css'
import Footer from '../Components/Reuseable/Footer.jsx';
import CoptRight from '../Components/Reuseable/Copyrights'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchSponsor, STATUSES } from "../redux/getReducer/getSponsorSlice"
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Carousel from "react-bootstrap/Carousel";
import { useTranslation } from "react-i18next";
import ExNews from "../Components/Home/Components/ExNews";
import Search from "../Components/Home/Components/Search";
import Notification from "../Components/Home/Components/Notification";
import Language from "../Components/Home/Components/Language";
import DefaultImg from "../assets/default.png";
const Sponsor = () => {

  const dispatch = useDispatch();
  const { t } = useTranslation(); 




  const cookiedata = Cookies.get("i18next");
return (
    <>
     
      <div className="aboutpage">
        <div className="aboutpageheader">
          <h2>{t("sponsors")}</h2>
        </div>
      
      </div>
      
      <Footer />
      <CoptRight />
    </>
  )
}
export default Sponsor