import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
const Copyrights = () => {
  const {t} = useTranslation();
  const { data: ThemeSetting } = useSelector((state) => state.ThemeSetting);


  return (
    <>
      <div className='Copyrights' >
        <div className='copyrightinner'>© 2023 {t('Copyright Equi innovations PVT LTD')}</div>
        {/* <div className='Policies'>
          <Link to='/policies'>
          <p>{t('Policies')}</p>
          </Link>
          <Link to='/'>
          <p>{t('Help')}</p>
          </Link>
          <Link to='/termscondition'>
          <p>{t('Terms & Conditions')}</p>
          </Link>
        </div> */}
      </div>
      
    </>
  )
}

export default Copyrights
