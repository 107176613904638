import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getOwnerStatsPageSlice = createSlice({
    name: 'OwnerStatsPage',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchOwnerStatsPage.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchOwnerStatsPage.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchOwnerStatsPage.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setOwnerStatsPage, setStatus } = getOwnerStatsPageSlice.actions;
export default getOwnerStatsPageSlice.reducer;

export const fetchOwnerStatsPage = createAsyncThunk('/OwnerStatsPageget/fetch', async ({
    year,
    StartDate,
    EndDate,
    RaceCourseSearch,
    HorseKindSearch,
    TrackLengthSearch,
    RaceTypeSearch,
    RaceKindSearch,
    RaceNationalitySearch,
    RaceGroundSearch,
    RaceDateSearch,
    currentPage,
}) => {
    const res = await axios.get(`${window.env.API_URL}/OwnerStatistics?year=${
        year === undefined ? "" : year
      }&startTimeTo=${StartDate}&startTimeFrom=${EndDate}&RaceCourse=${RaceCourseSearch}&HorseKindinRace=${HorseKindSearch}&TrackLength=${TrackLengthSearch}&RaceType=${RaceTypeSearch}&RaceKind=${RaceKindSearch}&NationalityID=${RaceNationalitySearch}&Ground=${RaceGroundSearch}&Date=${RaceDateSearch}&page=${currentPage}`);
    const OwnerStatsPageData = res.data;
    return OwnerStatsPageData;
})
