import React, { useState } from "react";
import CoptRight from "../Reuseable/Copyrights";
import { useTranslation } from "react-i18next";
import Footer from "../Reuseable/Footer";
import RaceCourseList from "./RaceCourseList";
import RaceCourseContent from "./RaceCourseContent";
import RaceCourseCommittee from "./RaceCourseCommittee";
import Search from "../Home/Components/Search";
import ExNews from "../Home/Components/ExNews";
import Notification from "../Home/Components/Notification";
import Language from "../Home/Components/Language";

const RaceCourse = () => {
  const { t } = useTranslation();
  const [racecourse, setRacecourse] = useState("option1");

  return (
    <>
      <div className="aboutpage">
        <div className="aboutpageheader">
          <h2>{t("RACECOURSES")}</h2>
        </div>
        <RaceCourseList setRacecourse={setRacecourse} />
      </div>

      <RaceCourseContent racecourse={racecourse} />
      {/* <RaceCourseCommittee /> */}
      <Footer />
      <CoptRight />
    </>
  );
};

export default RaceCourse;
   