import { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { getUserDetails, userLogin } from "../redux/postReducer/UserPost";
import {
  fetchNotificationOfUser,
  STATUSES,
} from "../redux/getReducer/getNotificationUser";
import { toast } from "react-toastify";
import ForgetPassword from "../Components/Reuseable/ForgetPassword";
import { Modal } from "react-bootstrap";
import "../Components/CSS/registration.css";
import { useTranslation } from "react-i18next";
import { BsEyeFill } from "react-icons/bs";
import {
  requestForToken,
  onMessageListener,
} from "../../firebaseNotifications/firebase";
import axios from "axios";
import Cookies from "js-cookie";

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [time, setTime] = useState("");

  const token = Cookies.get("token");

  const length = 4;
  const [otp, setOtp] = useState(Array(length).fill(""));
  const inputRefs = useRef([]);
  const dispatch = useDispatch();

  const showToast = (message) => {
    toast.dismiss(); // Clear any existing toasts
    toast.error(message);
  };

  const handleChange = (e, index) => {
    const value = e.target.value;

    if (isNaN(value)) {
      return; // Allow only numeric input
    }

    setOtp((prevOtp) => {
      const newOtp = [...prevOtp];
      newOtp[index] = value;
      return newOtp;
    });

    // Move focus to the next input field
    if (value !== "" && index < length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      // Move focus to the previous input field on backspace
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").slice(0, length);

    setOtp((prevOtp) => {
      const newOtp = [...prevOtp];
      for (let i = 0; i < length; i++) {
        newOtp[i] = pastedData[i] || "";
      }
      return newOtp;
    });
  };

  const handleFocus = (e) => {
    e.target.select();
  };

  const result_string = otp.join("");
  const isEmptyValuePresent = otp.some((value) => value === "");

  // console.log(otp.length, "otp");
  // console.log(isEmptyValuePresent, "otp");
  

  const SubmitOTP = async () => {
    try {
      const res = await axios.put(
        `${window.env.API_URL}/VerifyEmail`,
        { Code: result_string },
        {
          headers: { Authorization: token, "x-xsrf-token": token },
        }
      );
      // dispatch(getUserDetails());
      setOtp(Array(length).fill(""))
      navigate("/login");
    } catch (error) {
      const err = error.response.data.message;
      showToast(err);
      setOtp(Array(length).fill(""))

    }
  };

  const ReSubmitOTP = async () => {
    try {
      const res = await axios.put(
        `${window.env.API_URL}/ResendEmail`,
        { Code: result_string },
        {
          headers: { authorization: token, "x-xsrf-token": token },
        }
      );
      toast.success(res.data.message);
      setOtp(Array(length).fill(""))

    } catch (error) {
      const err = error.response.data.message;
      toast.error(err);
      setOtp(Array(length).fill(""))

    }
  };

  //
  return (
    <div className="mainlogin">
      <div className="registrationform dataverify">
        <div>
          <h5>{t("Verify Email")}</h5>
          <p>{t("Enter Your Email Verification Code")}</p>
          <div className="form-group">
            <div className="containe otpModal">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  inputMode="numeric"
                  maxLength={1}
                  value={digit}
                  onChange={(e) => handleChange(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onPaste={handlePaste}
                  onFocus={handleFocus}
                  ref={(el) => (inputRefs.current[index] = el)}
                />
              ))}
            </div>

            <div className="ForgetPassword1">
              <button className="verifyemailagain" onClick={ReSubmitOTP}>
                {t("Resend OTP")}
              </button>
            </div>
          </div>
          {isEmptyValuePresent && (
            <p className="errormessageotp" style={{ color: "red" }}>
              Error: Please fill all the fields.
            </p>
          )}
          <button
            onClick={SubmitOTP}
            disabled={isEmptyValuePresent}
            className="buttonRegister"
          >
            {t("Submit")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
