import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getSelectedHorseSlice = createSlice({
    name: 'SelectedHorse',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchSelectedHorse.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchSelectedHorse.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchSelectedHorse.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setSelectedHorse, setStatus } = getSelectedHorseSlice.actions;
export default getSelectedHorseSlice.reducer;

export const fetchSelectedHorse = createAsyncThunk('/SelectedHorseget/fetch', async ({ competitionid, raceid }) => {
    const res = await axios.get(`${window.env.API_URL}/GetVotedHorse/${competitionid}/${raceid}`);
    const SelectedHorseData = res.data;
    return SelectedHorseData.data;
})
