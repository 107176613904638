import { useEffect, useState } from "react";
import "../../CSS/HomeCSS/result.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchraceresult,
  STATUSES,
} from "../../../redux/getReducer/getRaceResult";
import Cookies from "js-cookie";
import ResultScreen from "../../../pages/RaceCardResult";
import { Modal } from "react-bootstrap";
import { Carousel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ResultTimer from "../../Reuseable/ResultTimer";
import { Lottie } from "lottie-react";
import Animate from "../../../assets/loader.json";
import socket from '../../../../Socket';
import comingsoonar from '../../../assets/notfount.png'
import NoResultCard from './NoResultCard'


const Result = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data: raceresult, status } = useSelector((state) => state.raceresult);

  const cookiedata = Cookies.get("i18next");
  const [show, setShow] = useState(false);
  const [modaldata, setmodaldata] = useState();
  const handleClose = () => setShow(false);
  const handleShow1 = async (data) => {
    setmodaldata(data);
    await setShow(true);
  };
  const [userIsDesktop, setUserIsDesktop] = useState(true);
  useEffect(() => {
    window.innerWidth > 840 ? setUserIsDesktop(true) : setUserIsDesktop(false);
  }, [userIsDesktop]);

  function HandleJockey(id) {
    if (userIsDesktop === true) {
      navigate(`/racedetail`, {
        state: {
          id: id,
        },
      });
    } else {
      navigate("/racedetails", {
        state: {
          id: id,
        },
      });
    }
  }




  const [PredictorData, setPredictionData] = useState(raceresult);

  useDispatch(() => {
    setPredictionData(raceresult); 
  },[raceresult])

  useEffect(() => {
    dispatch(fetchraceresult());
    socket.on("TodayResult", (Predictor) => {
      setPredictionData(Predictor); 
      console.log(Predictor);
    });
  }, [dispatch]);


  if (status === STATUSES.LOADING) {
    return <></>;
  }

  if (status === STATUSES.ERROR) {
    return (
      <h2
        style={{
          margin: "100px",
        }}
      >
        No Result Found
      </h2>
    );
  }


  return (
    <>
    {
      PredictorData?.length !== 0 ? 
       <div className="liveresult1">
 
      <h2 className="liveResult">
        {t("Live_Results")}
        <span>.</span>{" "}
      </h2>
      <div className="resultCard">
        <Carousel showArrows={false} showIndicators={false} showStatus={false} controls={true}
        touch={true} >
          {PredictorData?.length !== 0 ? (
            PredictorData &&
            PredictorData.map((item, i) => {
              return (
                item.RaceResultData?.RaceResultData == 0 ?
              <></> :<Carousel.Item interval={2000}>
                <div className="resultCardHeader">
                
                    <>
                      <div>
                        <div>
                          <div className="resultslider_head">
     
     
                            <h2>
                              {" "}
                              {cookiedata === "en" ? (
                                <>
                                  {item.RaceCourseData === null ? (
                                    <></>
                                  ) : (
                                    item.RaceCourseData.TrackNameEn
                                  )}
                                </>
                              ) : (
                                <>
                                  {item.RaceCourseData === null ? (
                                    <></>
                                  ) : (
                                    item.RaceCourseData.TrackNameAr
                                  )}
                                </>
                              )}
                            </h2>
                            <h2 >
                              {t("Race")} :{item.RaceNumber}
                            </h2>
     
                          </div>
                          <div className="resultParent">
                            <div >
                              <p className="result_match_name tooltipclass">
                                {cookiedata === "en" ? (
                                  <>
                                    {item.RaceNameModelData === null ? (
                                      <></>
                                    ) : (
                                      item.RaceNameModelData.NameEn
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {item.RaceNameModelData === null ? (
                                      <></>
                                    ) : (
                                      item.RaceNameModelData.NameAr
                                    )}
                                  </>
                                )}
                              </p>
     
                            </div>
                            <div className="resultData">
                              <p>{t("Surface")}: <b>
                                {cookiedata === "en"
                                  ? item.TrackLengthData.GroundTypeModelData
                                    .NameEn
                                  : item.TrackLengthData.GroundTypeModelData
                                    .NameAr}</b></p>
     
                              <p>{t("Going")} :<b> {cookiedata === "en"
                                ? item.MeetingTypeData.NameEn
                                : item.MeetingTypeData.NameAr}</b></p>
                              <p>
     
     
                                {t("Runner")} :<b>
                                {item.RaceResultData.length ===
                                  undefined ? (
                                  <></>
                                ) : (
                                  item.RaceResultData.length
                                )}
     </b>
                              </p>
     
                            </div>
                            <div className="resultracelength">
                              <img
                                src={item.TrackLengthData.RaceCourseImage}
                                alt=""
                              />
                            </div>
                          </div>
                          <div>
                            <table className="customers1">
                              <thead>
                                <th>{t("Positions")}</th>
                                <th>{t("Dis. Beaten")}</th>
                                <th>{t("Horse Name")}</th>
     
                                <th>
                                  {t("OffTime")}:
                                  {item?.RaceResultData[0]?.RaceTime}
                                </th>
     
                                <th>
     
                                {
                        item.RaceResultData?.length !== 0 ? <button
                        onClick={() => HandleJockey(item._id)}
                        className="livebtn"
     
                     >
                       {t("Go To Race")}
                     </button> : <></>
                      }
                                </th>
                              </thead>
                              {item.RaceResultData?.length === 0 ? (
                                <> 
                                <div className="noresultyet">
                                  <h2 className="noresultyeth2">
                                  {t("No Results Found")}
                                  </h2>
                                </div>
                                </>
                              ) : (
                                item.RaceResultData.slice(0, 3).map(
                                  (data, index) => {
                                    return (
                                      <tbody className="livedatacard">
                                        <tr
                                          key={data._id}
                                        // onClick={() =>
                                        //   handleRedirect(data._id)
                                        // }
                                        >
                                          <td>
                                            {" "}
                                            {data.FinalPositionDataHorse ? (
                                              data.FinalPositionDataHorse?.Rank
                                            ) : (
                                              <>-</>
                                            )}
                                          </td>
                                          <td>{data.CumulativeDistance}</td>
                                         
                                          <td>
                                            {cookiedata === "en" ? (
                                              <>
                                                {data.HorseIDData ? (
                                                  cookiedata === "en" ? (
                                                    <>
                                                    <img className="gohoradora" src={data.HorseIDData?.GhoraColor}/>
                                                   {
                                                     data.HorseIDData?.NameEn
                                                   }  
                                                    </>
                                                  ) : (
                                                    data.HorseIDData?.NameAr
                                                  )
                                                ) : (
                                                  <>-</>
                                                )}
                                                (
                                                {data.HorseIDData?.Runners ? (
                                                  data.HorseIDData?.Runners
                                                ) : (
                                                  <>-</>
                                                )}
                                                )
                                              </>
                                            ) : (
                                              <>
                                                {data.HorseIDData ? (
                                                  data.HorseIDData?.NameAr
                                                ) : (
                                                  <>-</>
                                                )}
                                                ({" "}
                                                {data.HorseIDData?.Runners ? (
                                                  data.HorseIDData?.Runners
                                                ) : (
                                                  <>-</>
                                                )}{" "}
                                                )
                                              </>
                                            )}
                                          </td>
                                       
                                          <td><span className="op">asd </span></td>
                                          <td><span className="op">asd </span></td>
                                        </tr>
                                      </tbody>
                                    );
                                  }
                                )
                              )}
                            </table>
                          </div>
                        </div>
                      </div>
                     
                      
                    </>
                  
                </div>
              </Carousel.Item>
                  
              );
            })
          ) : (
            <h4 className="noFoundHeader">  {t("No Results Found")}</h4>
          )}
        </Carousel>
      </div>
     </div> : <NoResultCard />
    }
  
    </>
  );
};
export default Result;
