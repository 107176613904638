import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import 'bootstrap/dist/js/bootstrap.js'
import App from './App'
import 'bootstrap/dist/css/bootstrap.min.css'
import Lottie from 'lottie-react';
import Animate from './Webiste/assets/loader.json'
import "../src/Webiste/Components/CSS/Arabic.css"
import Cookies from "js-cookie";
import Notification from './firebaseNotifications/Notification'
import { toPascalCaseWithSpace } from './Webiste/service/stringUtils';


i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'ar'],
    fallbackLng: 'en',
    debug: false,
    // Options for language detector
    detection: {
      order: ['cookie', 'path', 'htmlTag'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
  })

const cookiedata = Cookies.get("i18next");

window.textTransform = toPascalCaseWithSpace;

const token = Cookies.get("token")
// react: { useSuspense: false },
const loadingMarkup = (
  <div className="py-4 text-center">
    <div>
      <Lottie animationData={Animate} loop={true} className="load" />
    </div>
  </div>
)

ReactDOM.render(
  <Suspense fallback={loadingMarkup}>
    <React.StrictMode>
      {
        token ?    <Notification />  :<></>
      }

      <App />

    </React.StrictMode>
  </Suspense>,
  document.getElementById('root')
)
