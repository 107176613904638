import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";



// Define the async thunk for posting customer data
export const changePasswordData = createAsyncThunk(
  "changePassword/postData",
  async ({data, lastSegment}, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${window.env.API_URL}/password/reset/${lastSegment}`,
        data,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



// Create the Redux Toolkit slice
const changePasswordSlice = createSlice({
  name: "changePassword",
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(changePasswordData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(changePasswordData.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(changePasswordData.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload?.message; // Access the error message from the payload

      });
  },
});

export default changePasswordSlice.reducer;
