import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

const getJockeyTrainerComboSlice = createSlice({
  name: "JockeyTrainerCombo",
  initialState: {
    data: [],
    status: STATUSES.IDLE,
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchJockeyTrainerCombo.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchJockeyTrainerCombo.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(fetchJockeyTrainerCombo.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { setJockeyTrainerCombo, setStatus } =
  getJockeyTrainerComboSlice.actions;
export default getJockeyTrainerComboSlice.reducer;

export const fetchJockeyTrainerCombo = createAsyncThunk(
  "/JockeyTrainerComboget/fetch",
  async ({
    StartDate,
    EndDate,
    year,
    RaceCourseSearch,
    HorseKindSearch,
    TrackLengthSearch,
    RaceTypeSearch,
    RaceKindSearch,
    RaceNationalitySearch,
    RaceGroundSearch,
    RaceDateSearch,
    currentPage,
  }) => {
    const res = await axios.get(
      `${window.env.API_URL}Horse/Trainer_Jockey_HorseComboV3?year=${
        year === undefined ? "" : year
      }&startTimeTo=${StartDate}&startTimeFrom=${EndDate}&RaceCourse=${RaceCourseSearch}&HorseKindinRace=${HorseKindSearch}&TrackLength=${TrackLengthSearch}&RaceType=${RaceTypeSearch}&RaceKind=${RaceKindSearch}&NationalityID=${RaceNationalitySearch}&Ground=${RaceGroundSearch}&Date=${RaceDateSearch}&page=${currentPage}`
    );
    const JockeyTrainerComboData = res.data;
    return JockeyTrainerComboData;
  }
);
