import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

import Moment from "react-moment";
import PleaseWait from "../Reuseable/PleaseWait";
import arrow1 from "../../assets/image 13 (Traced).png";
import VideoFrame from "../Modal/VideoFrame";
import { Modal } from "react-bootstrap";
import { fetchJockeyEntries } from "../../redux/getReducer/getEntriesJockey";
import RaceModal from "../Modal/RaceModal";

const OwnerEntries = ({ id }) => {
    const { data: entriesjockey } = useSelector(
        (state) => state.entriesjockey
    );
    const dispatch = useDispatch();
    const [shows, setShows] = useState(false);
    const [modaldatas, setmodaldatas] = useState();
    const handleCloses = () => setShows(false);
    const handleShows = async (data) => {
        setmodaldatas(data);
        await setShows(true);
    };
    const [showRace, setShowRace] = useState(false);
    const [modaldataRace, setmodaldataRace] = useState();
    const handleCloseRace = () => setShowRace(false);
    const handleShowRace = async (id) => {
        setmodaldataRace(id);
        await setShowRace(true);
    };
    const { t } = useTranslation();
    const cookiedata = Cookies.get("i18next");
    useEffect(() => {
        dispatch(fetchJockeyEntries({ id }));
    }, [dispatch, id]);


    function distanceLabel(distance, rank) {
        const DistanceTobeChecked = parseFloat(distance);
        console.log(DistanceTobeChecked);
        if (DistanceTobeChecked.toFixed(2) > 25) {
          return "Dist";
        } else if (DistanceTobeChecked.toFixed(2) == 0.05 && rank < 80) {
          return "SHd";
        } else if (DistanceTobeChecked.toFixed(2) == 0.10 && rank < 80) {
          return "Hd";
        } else if (DistanceTobeChecked.toFixed(2) == 0.25 && rank < 80) {
          return "Nk";
        } else if (DistanceTobeChecked.toFixed(2) == 0.01 && rank < 80) {
          return "Ns";
        } else if (DistanceTobeChecked.toFixed(2) == 0.00 && rank < 80) {
          if(rank === 1){
            return 0;
          }
          return "Dh";
        } else {
          return distance;
        }
      }
    

    return (
        <>
            <div className="mycardclass1">
                <div className="BodyNew">
                    <table className="customers">
                        <thead className="customers5">
                            <tr>
                                <th>{t("Date")}</th>
                                <th>{t("Crs")}</th>
                                <th>{t("Dist")}</th>
                                <th>{t("TC")}</th>
                                <th>{t("RT")}</th>

                                <th>{t("Dts")}</th>
                                <th>{t("Time")}</th>
                                <th>{t("Jockey")}</th>

                                <th>{t("FP")}</th>
                                <th>{t("Len")}</th>
                                <th>{t("RN")}</th>
                                <th>{t("BtBy")}</th>
                                <th>{t("Kgs")}</th>

                                <th>{t("Draw")}</th>
                                <th>{t("Link")}</th>
                            </tr>
                        </thead>
                        <tbody className="customers1">
                            {entriesjockey === undefined ? (
                                <PleaseWait />
                            ) : (
                                <>
                                    {!entriesjockey ? (
                                        <PleaseWait />
                                    ) : (
                                        entriesjockey.map((item, i) => {
                                            return (
                                                <tr
                                                    className="BodyNew1 historyhorseth tempcolor"
                                                    // style={{
                                                    //   background: `${item.RaceResultData
                                                    //     .RacehorsesData[0]
                                                    //     .RacehorsesData
                                                    //     .TrackLengthData
                                                    //     .GroundTypeModelData
                                                    //     .NameEn == "Dirt"
                                                    //     ? "#5ec40f"
                                                    //     : item.RaceResultData
                                                    //       .RacehorsesData[0]
                                                    //       .RacehorsesData
                                                    //       .TrackLengthData
                                                    //       .GroundTypeModelData
                                                    //       .NameEn == "Turf"
                                                    //       ? "#9F5109"
                                                    //       : "#949494"
                                                    //     }`,
                                                    // }}
                                                    key={i}
                                                >
                                                    <td className="lb" style={{ cursor: "pointer" }}
                                                    //                 onClick={() =>
                                                    //   handleShowRace(item.RaceID)
                                                    // }
                                                    >
                                                        <Moment format="YYYY/MM/DD">
                                                            {item.RaceResultData === null ? (
                                                                <></>
                                                            ) : (
                                                                item.RaceResultData.StartTime
                                                            )}
                                                        </Moment>
                                                    </td>
                                                    <td>-</td>
                                                    <td>
                                                    {
                                                    distanceLabel(item.Distance &&
                                                      item.Distance , item.FinalPositionDataHorse &&
                                                      item.FinalPositionDataHorse?.Rank)
                                                  }
                                                 
                                                    </td>
                                                    {/* <td>- </td> */}
                                                    <td>
                                                        {item.RaceResultData && cookiedata === "en"
                                                            ? item.RaceResultData.TrackConditionData.NameEn
                                                            : item.RaceResultData.TrackConditionData.NameAr}
                                                    </td>

                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>
                                                        {
                                                            <Moment format="hh:mm:ss">
                                                                {item.RaceResultData === null ? (
                                                                    <></>
                                                                ) : (
                                                                    item.RaceResultData.StartTime
                                                                )}
                                                            </Moment>
                                                        }
                                                    </td>
                                                    <td>-</td>
                                                    <td>
                                                        {item.FinalPositionDataHorse && cookiedata === "en"
                                                            ? item.FinalPositionDataHorse.NameEn
                                                            : item.FinalPositionDataHorse &&
                                                            item.FinalPositionDataHorse.NameAr}
                                                    </td>
                                                    <td>
                                                        {item.RaceResultData.TrackLengthData &&
                                                            item.RaceResultData.TrackLengthData.TrackLength}
                                                    </td>
                                                    <td>{item.RaceResultData.Runners}</td>
                                                    <td>-</td>
                                                    <td>
                                                        {item.RaceResultData && item.RaceResultData.Draw}
                                                    </td>
                                                    <td> {item.Rating}</td>

                                                    <td
                                                        className="rb"
                                                        onClick={() => handleShows(item.VideoLink)}
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <img src={arrow1} alt="" />
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    )}
                                </>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            <Modal
                show={shows}
                onHide={handleCloses}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className="popupheader" closeButton></Modal.Header>
                <Modal.Body>
                    <VideoFrame data={modaldatas} />
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
            <Modal
                show={showRace}
                onHide={handleCloseRace}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className="popupheader" closeButton></Modal.Header>
                <Modal.Body>
                    <RaceModal id={modaldataRace} />
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
        </>
    );
};
export default OwnerEntries;
