import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getJockeyHistorySlice = createSlice({
    name: 'JockeyHistory',
    initialState: {
        data: [],
        statusdata: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchJockeyHistory.pending, (state, action) => {
                state.statusdata = STATUSES.LOADING;
            })
            .addCase(fetchJockeyHistory.fulfilled, (state, action) => {
                state.data = action.payload;
                state.statusdata = STATUSES.IDLE
            })
            .addCase(fetchJockeyHistory.rejected, (state, action) => {
                state.statusdata = STATUSES.ERROR;
            })
    }
});

export const { setJockeyHistory, setStatus } = getJockeyHistorySlice.actions;
export default getJockeyHistorySlice.reducer;

export const fetchJockeyHistory = createAsyncThunk('/JockeyHistoryget/fetch', async ({ 
    id ,
    year,
    Nationality,
    RaceCourse,
    GroundType,
    RaceKind,
    HorseKind,
    RaceType,
    TrackLength
 }) => {
    const res = await axios.get(`${window.env.API_URL}/JockeyHistoryV3/${id}?year=${year === undefined ? "" : year}&Nationality=${Nationality === undefined ? "" : Nationality}&RaceCourse=${RaceCourse === undefined ? "" : RaceCourse}&GroundType=${GroundType === undefined ? "" : GroundType}&RaceKind=${RaceKind === undefined ? "" : RaceKind}&HorseKind=${HorseKind === undefined ? "" : HorseKind }&RaceType=${RaceType=== undefined ? "" : RaceType}&TrackLength=${TrackLength === undefined ? "" : TrackLength}`);
    const JockeyHistoryData = res.data;
    return JockeyHistoryData.data;
})
