import axios from "axios";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

const getWinningList = createSlice({
  name: "WinningList",
  initialState: {
    data: [],
    status: STATUSES.IDLE,
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchWinningList.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchWinningList.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(fetchWinningList.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { setWinningList, setStatus } = getWinningList.actions;
export default getWinningList.reducer;

export const fetchWinningList = createAsyncThunk(
  "/WinningBoard/fetch",
  async () => {
    const res = await axios.get(`${window.env.API_URL}/WinningBoard `);
    const data = res.data;
    return data.data;
  }
);
