import React, { useEffect, useState, Fragment } from "react";
import Layout from "../Components/Reuseable/layout";
import Footer from "../Components/Reuseable/Footer.jsx";
import CoptRight from "../Components/Reuseable/Copyrights";
import Cookies from "js-cookie";
import { Accordion } from "react-bootstrap";
import "../../Webiste/Components/CSS/pagesCSS/dashboard.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import "../Components/CSS/RaceCardCSS/racecard.css";
import Justsponsor from "./justsponser";
import img from "../assets/GB.jpg";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import Animate from "../assets/loader.json";
import ExNews from "../Components/Home/Components/ExNews";
import Language from "../Components/Home/Components/Language";
import Notification from "../Components/Home/Components/Notification";
import Search from "../Components/Home/Components/Search";
import { BsCalendarDate } from "react-icons/bs";
import CalenderComponent from "../Components/Home/Components/Calendar";
import noimage from "../assets/noimage1.png";
import noimagearabic from "../assets/nottacearabic.png";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { fetchracecardscreen } from "../redux/getReducer/getracecardscreen";
export default function Racecardscreen() {
  const navigate = useNavigate();
  const [CategorySub, setCategorySub] = useState();
  const [DateTobeDone, SetDateTobeDone] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setshow] = useState(false);
  const [myData, setmyData] = useState(new Date());

  const { data: racecardscreen, status } = useSelector(
    (state) => state.racecardscreen
  );

  const dispatch = useDispatch();
  const [userIsDesktop, setUserIsDesktop] = useState(true);
  useEffect(() => {
    window.innerWidth > 840 ? setUserIsDesktop(true) : setUserIsDesktop(false);
  }, [userIsDesktop]);

  function handleRedirect(id) {
    if (userIsDesktop === true) {
      navigate(`/racedetail`, {
        state: {
          id: id,
        },
      });
    } else {
      navigate("/racedetails", {
        state: {
          id: id,
        },
      });
    }
  }
  const { t } = useTranslation();
  const cookiedata = Cookies.get("i18next");

  let getDataCategory = async () => {
    setIsLoading(true);

    await fetch(`${window.env.API_URL}/RaceScreenForSubscriber`)
      .then((res) => res.json())
      .then((result) => {
        setCategorySub(result);
        SetDateTobeDone(GetDates());
        setIsLoading(false);
      });
  };

  const GetDates = (alldata) => {
    var start = new Date();
    var end = new Date(start);

    end.setDate(start.getDate() + 3);
    let Dates = [];

    var loop = new Date(start);
    let i = 0;
    while (loop <= end) {
      if (i == 0) {
        var newDates = loop.setDate(loop.getDate() + i);
        loop = new Date(newDates);
        Dates.push(moment(loop).format("YYYY-MM-DD"));
      } else {
        var newDates = loop.setDate(loop.getDate() + 1);
        loop = new Date(newDates);
        Dates.push(moment(loop).format("YYYY-MM-DD"));
      }
      i++;
    }
    SetDateTobeDone(Dates);
    return Dates;
  };

  const start = new Date();

  const getDateDataAccordingToTime = async (start) => {
    setCategorySub();
    setmyData(start);

    dispatch(fetchracecardscreen({ start }));

    setIsLoading(true);
  };
  const today = cookiedata === "en" ? "Today" : "اليوم";
  const tommorrow = cookiedata === "en" ? "Tomorrow" : "غداً";

  useEffect(() => {
    dispatch(fetchracecardscreen({ start }));

    getDataCategory();
  }, []);
  const AllTime = (TimeData) => {
    if (TimeData.length == 1) {
      return `${moment(TimeData[0].StartTime).format("h:mm")}`;
    } else if (TimeData.length == 0) {
      return ``;
    } else {
      let FirstTime = moment(TimeData[0].StartTime).format("h:mm");
      let LastTime = moment(TimeData[TimeData.length - 1].StartTime).format(
        "h:mm"
      );
      return `${FirstTime}-${LastTime}`;
    }
  };

  function formatAMPM(date) {
    const DataDate = new Date(date);
    var hours = DataDate.getHours();
    var minutes = DataDate.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };

  const date = new Date(myData);

  // Extract year, month, and day
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  // Form the new formatted date string
  const formattedDate = `${year}-${month}-${day}`;

  return (
    <Fragment>
      <>
  
        <div className="container-fluid mystages racecards">
          <div className="row">
            <div className="col-sm-8 stages">
              <div className="raceCarParent stages racecard1">
                <div className="raceCardHeader1">
                  <div className="row">
                    <div className="col-sm-10">
                      <h1>{t("Racecard Post")}</h1>
                    </div>
                  </div>
                </div>

                <div className="posttabs">
                  <div className="row">
                    {DateTobeDone ? (
                      DateTobeDone.map((singledate, i) => (
                        <div className="col-sm-2">
                          <p
                            onClick={() =>
                              getDateDataAccordingToTime(singledate)
                            }
                            className={`${
                              singledate === formattedDate
                                ? "active"
                                : "notactive"
                            }`}
                          >
                            {i === 0 ? today : i === 1 ? tommorrow : singledate}
                          </p>
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                     <BsCalendarDate className="chosecalender" onClick={() => setshow(!show)} />
                  {
                      show ? <CalenderComponent /> : <></>
                    }
                  </div>
                </div>
               
                {status == "loading" ? (
                  <Lottie animationData={Animate} className="load1"  style={{
                    marginTop:"30px"
                  }}/>
                ) : racecardscreen?.length != 0 ? (
                  racecardscreen.map((singleracecourse, i) => (
                    <Accordion
                      defaultActiveKey={0}
                      flush
                      className="mobilesitewidth racecardpage"
                    >
                      <Accordion.Item eventKey={i}>
                        <div>
                          <Accordion.Header>
                            <div className="accordionHeader displaymobile1 row">
                              <div className="headerCard1 col-sm-2">
                                <img src={img} alt="seo" />
                              </div>
                              <div className="headerCard2 col-sm-5">
                                <h5>
                                  {cookiedata === "en"
                                    ? singleracecourse.TrackNameEn
                                    : singleracecourse.TrackNameAr}
                                </h5>
                              </div>
                              <div className="headerCard2 col-sm-4">
                                <span>
                                  {AllTime(singleracecourse.RaceCourseData)}
                                </span>
                                <span className="nodisplay">
                                  {cookiedata === "en" ? (
                                    singleracecourse.NationalityDataRaceCourse ===
                                    undefined ? (
                                      <></>
                                    ) : (
                                      singleracecourse.NationalityDataRaceCourse
                                        .NameEn
                                    )
                                  ) : singleracecourse.NationalityDataRaceCourse ===
                                    undefined ? (
                                    <></>
                                  ) : (
                                    singleracecourse.NationalityDataRaceCourse
                                      .NameAr
                                  )}
                                </span>
                                <span>
                                  {t("Races")}{" "}
                                  {singleracecourse.RaceCourseData.length}
                                </span>
                              </div>
                              <div className="headerCard3 col-sm-1">
                                <i
                                  class="fa fa-chevron-circle-down"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                          </Accordion.Header>
                          {singleracecourse.RaceCourseData ? (
                            singleracecourse.RaceCourseData.map((data, i) => (
                              <Accordion.Body key="i" className="racecardacc12">
                                <>
                                <div className="racepagesection">
                                    <div
                                      className="row racepageitem"
                                      onClick={() => handleRedirect(data._id)}
                                    >
                                      <div className="col-sm-6 todayracefav">
                                        <div className="RaceDetailsName">
                                          <span
                                            style={{
                                              fontWeight: "300",
                                              fontSize: "20px",
                                              lineHeight: "24px",
                                            }}
                                          >
                                            <h5>
                                              {t("Race")} {data.RaceNumber}
                                            </h5>
                                          </span>
                                          <h6 className="tooltipclass">
                                            {cookiedata === "en"
                                              ? data.RaceNameModelData.NameEn
                                              : data.RaceNameModelData.NameAr}
                                          </h6>
                                          <br />
                                        </div>
                                        <p>
                                          {cookiedata === "en" ? (
                                            <>{data.DescriptionEn}</>
                                          ) : (
                                            <>{data.DescriptionAr}</>
                                          )}
                                          <span className="totalrunners">
                                            {data?.Runners} {t("runners")}
                                          </span>
                                          <span className="totalrunners">
                                            {data?.NonRunners} {t("NonRunner")}
                                          </span>
                                        </p>
                                       
                                      </div>

                                      {/* <br /> */}

                                      <div className="col-sm-3 racestatusright">
                                        <span
                                          className="racestatusclass"
                                          style={{
                                            backgroundColor: `${
                                              data.RaceStatus === "Cancelled"
                                                ? "#000000"
                                                : data.RaceStatus ===
                                                  "Completed"
                                                ? "#FF0000"
                                                : data.RaceStatus === "Live"
                                                ? "#5EC30F"
                                                : "#FF9900"
                                            }`,
                                            color: `${
                                              data.RaceStatus === "Cancelled"
                                                ? "#ffff"
                                                : data.RaceStatus === "End"
                                                ? "#00000"
                                                : data.RaceStatus === "Live"
                                                ? "#00000"
                                                : "#000000"
                                            }`,
                                          }}
                                        >
                                          <p className="StartTimeCards">
                                            {formatAMPM(
                                              data.StartTime
                                            ).toLocaleUpperCase()}
                                          </p>
                                          {/* <p className="racestatusclasstime"><Moment format="hh:mm:ss" className="racestatusclasstime">{item.StartTime}</Moment></p> */}
                                        </span>

                                       
                                      </div>
                                      <div className="col-sm-3">
                                          {
                                            <div className="row">
                                              <div className="top1">
                                                {data.Favourite1English ==
                                                  null &&
                                                data.Favourite2English ==
                                                  null &&
                                                data.Favourite3English ==
                                                  null ? (
                                                  <></>
                                                ) : (
                                                  <div className="top">
                                                    <h5
                                                      style={{
                                                        fontStyle: "normal",
                                                        fontWeight: "300",
                                                        fontSize: "12px",
                                                        lineHeight: "11px",
                                                        color:
                                                          "rgba(0, 0, 0, 0.5)",
                                                      }}
                                                    >
                                                      {t("Favourite")}
                                                    </h5>

                                                    <p
                                                      style={{
                                                        lineHeight: "1px",
                                                        color:
                                                          "rgba(0, 0, 0, 0.5)",
                                                        fontSize: "10px",
                                                        lineHeight: "0px",
                                                      }}
                                                      className="favhorses"
                                                    >
                                                      {data.Favourite1English ===
                                                      null ? (
                                                        <></>
                                                      ) : (
                                                        <p>
                                                          (
                                                          {
                                                            data.FavouriteHorseNumber1
                                                          }
                                                          )
                                                          {cookiedata === "en"
                                                            ? data.Favourite1English
                                                            : data.Favourite1Arabic}{" "}
                                                          -{" "}
                                                          {
                                                            data.FavouriteRating1
                                                          }
                                                        </p>
                                                      )}
                                                      {data.Favourite2English ===
                                                      null ? (
                                                        <></>
                                                      ) : (
                                                        <p>
                                                          (
                                                          {
                                                            data.FavouriteHorseNumber2
                                                          }
                                                          )
                                                          {cookiedata === "en"
                                                            ? data.Favourite2English
                                                            : data.Favourite2Arabic}{" "}
                                                          -{" "}
                                                          {
                                                            data.FavouriteRating1
                                                          }
                                                        </p>
                                                      )}
                                                      {data.Favourite3English ===
                                                      null ? (
                                                        <></>
                                                      ) : (
                                                        <p>
                                                          (
                                                          {
                                                            data.FavouriteHorseNumber3
                                                          }
                                                          )
                                                          {cookiedata === "en"
                                                            ? data.Favourite3English
                                                            : data.Favourite3Arabic}{" "}
                                                          -{" "}
                                                          {
                                                            data.FavouriteRating1
                                                          }
                                                        </p>
                                                      )}
                                                    </p>
                                                  </div>
                                                )}
                                              </div>
                                              <div className="top1">
                                                {data.FirstRankHorseEnglish ==
                                                  null &&
                                                data.SecondRankHorseEnglish ==
                                                  null &&
                                                data.ThirdRankHorseEnglish ==
                                                  null ? (
                                                  <></>
                                                ) : (
                                                  <div className="top">
                                                    <h5
                                                      style={{
                                                        fontStyle: "normal",
                                                        fontWeight: "300",
                                                        fontSize: "12px",
                                                        lineHeight: "11px",
                                                        color:
                                                          "rgba(0, 0, 0, 0.5)",
                                                      }}
                                                    >
                                                      {t("Top")}
                                                    </h5>
                                                    <p>
                                                      ({t("1st")})
                                                      {cookiedata === "en"
                                                        ? data.FirstRankHorseEnglish
                                                        : data.FirstRankHorseArabic}{" "}
                                                      -{" "}
                                                      {
                                                        data.FirstRankHorseNumber
                                                      }{" "}
                                                    </p>
                                                    <p>
                                                      ({t("2nd")})
                                                      {cookiedata === "en"
                                                        ? data.SecondRankHorseEnglish
                                                        : data.SecondRankHorseArabic}
                                                      -{" "}
                                                      {
                                                        data.SecondRankHorseNumber
                                                      }{" "}
                                                    </p>
                                                    <p>
                                                      ({t("3rd")})
                                                      {cookiedata === "en"
                                                        ? data.ThirdRankHorseEnglish
                                                        : data.ThirdRankHorseArabic}{" "}
                                                      -{" "}
                                                      {
                                                        data.ThirdRankHorseNumber
                                                      }
                                                    </p>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          }
                                        </div>
                                        <div className="col-sm-12 racedown">
                                          <p
                                            style={{
                                              color: "#ff0000",
                                            }}
                                          >
                                            <b style={{ color: "#000" }}>
                                              {" "}
                                              {t("Distance")} :
                                            </b>{" "}
                                            {" "}
                                            {data.TrackLengthData
                                              .TrackLength === null ? (
                                              <>- </>
                                            ) : (
                                              data.TrackLengthData.TrackLength
                                            )}
                                          </p>
                                          {/* <p> {cookiedata === "en" ? (data.RaceTypeModelData.NameEn === null ? <></> : data.RaceTypeModelData.NameEn) : (data.RaceTypeModelData.NameAr === null ? <></> : data.RaceTypeModelData.NameAr)} </p> */}
                                          <p
                                            style={{
                                              color: `${
                                                data.TrackLengthData
                                                  ?.GroundTypeModelData
                                                  ?.NameEn === "Turf"
                                                  ? "#5ec40f"
                                                  : "#9d642f"
                                              }`,
                                            }}
                                          >
                                            <b style={{ color: "#000" }}>
                                              {t("Ground")} :
                                            </b>{" "}
                                            {" "}
                                            {cookiedata === "en" ? (
                                              !data.TrackLengthData ? (
                                                <>- </>
                                              ) : (
                                                data.TrackLengthData
                                                  ?.GroundTypeModelData?.NameEn
                                              )
                                            ) : !data.TrackLengthData ? (
                                              <>- </>
                                            ) : (
                                              data.TrackLengthData
                                                ?.GroundTypeModelData?.NameAr
                                            )}
                                          </p>
                                          <p
                                            style={{
                                              color: "#ff0000",
                                            }}
                                          >
                                            <b style={{ color: "#000" }}>
                                              {t("RaceType")} :
                                            </b>{" "}
                                            {" "}
                                            {cookiedata === "en" ? (
                                              !data.RaceTypeModelData ? (
                                                <>- </>
                                              ) : (
                                                data.RaceTypeModelData.NameEn
                                              )
                                            ) : !data.GroundData ? (
                                              <>- </>
                                            ) : (
                                              data.RaceTypeModelData.NameAr
                                            )}
                                          </p>

                                          <p
                                            style={{
                                              color: "#ff0000",
                                            }}
                                          >
                                            <b style={{ color: "#000" }}>
                                            {t("Total Prize")} :
                                            </b>{" "}
                                            {" "}
                                            {data?.FirstPrice +
                                              data?.SecondPrice +
                                              data?.ThirdPrice +
                                              data?.FourthPrice +
                                              data?.FifthPrice +
                                              data?.SixthPrice}
                                          </p>



                                        </div>{" "}
                                    </div>

                                  </div>
                                </>
                              </Accordion.Body>
                            ))
                          ) : (
                            <></>
                          )}
                        </div>
                      </Accordion.Item>
                    </Accordion>
                  ))
                ) : (
                  <div className="">
                    <SkeletonTheme baseColor="#00A6A7" highlightColor="#9DD9DB">
           <p>
             <Skeleton count={3} />
           </p>
         </SkeletonTheme>
                  </div>
                )}
              </div>
             
            </div>
            
            <div className="col-sm-4">
              <Justsponsor />
            </div>
          </div>
        </div>

        <Footer />
        <CoptRight />
      </>
    </Fragment>
  );
}
