import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import Cookies from "js-cookie";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
ChartJS.register(ArcElement, Tooltip, Legend);

const Graph = ({ item }) => {
  const cookiedata = Cookies.get("i18next");
  const { data: ThemeSetting } = useSelector((state) => state.ThemeSetting);

  const data = {
    
    datasets: [
      {
        label: "# of Votes",

        data:
          item === undefined ? [] : item[1].votesdata.map((name) => name.votes),
        backgroundColor: [
          "#00A6A7",
          "#19469d",
          "#73C2FB",
          "#0070BB",
          "rgba(153, 102, 255)",
          "rgba(255, 159, 64)",
        ],
        borderColor: [
          "#19469d",
          "#00A6A7",
          "#73C2FB",
          "#0070BB",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const { t } = useTranslation();
  return (
    <>
      <div className="GraphCard" >
        <p className="chartheader">{t("ChartGraph")}</p>
        <div className="chartclass">
          <Doughnut data={data} />
        </div>
      </div>
    </>
  );
};
export default Graph;
