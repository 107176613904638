import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getUserTrackedJockeySlice = createSlice({
    name: 'UserTrackedJockey',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchUserTrackedJockey.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchUserTrackedJockey.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchUserTrackedJockey.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setUserTrackedJockey, setStatus } = getUserTrackedJockeySlice.actions;
export default getUserTrackedJockeySlice.reducer;

export const fetchUserTrackedJockey = createAsyncThunk('/UserTrackedJockeyget/fetch', async ({token}) => {
    const res = await axios.get(`${window.env.API_URL}/UserTrackedJockey`,{
        headers: { "Authorization": token, 'x-xsrf-token': token }
    });
    const UserTrackedJockeyData = res.data;
    return UserTrackedJockeyData.data;
})
