import React from 'react'

const VideoFrame = (data) => {

  let url = data.data;
  function getId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
  }

  const videoId = getId(url);
  const iframeMarkup = '<iframe width="560" height="315" src="//www.youtube.com/embed/'
    + videoId + '" frameborder="0" allowfullscreen></iframe>';




  return (
    <div>
      <iframe className='videoiframe' frameborder="0" allowfullscreen width="420" height="315" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        src={`//www.youtube.com/embed/` + videoId}>
      </iframe>
    </div>
  )
}

export default VideoFrame
