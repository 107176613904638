import React, { useEffect, useState } from "react";
import "../../CSS/HomeCSS/blogs.css";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchRaceCourseToday,
  STATUSES,
} from "../../../redux/getReducer/getRaceCourseToday";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate ,Outlet } from "react-router-dom";
import { Bounce, Fade } from "react-reveal";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import Animate from "../../../assets/loader.json";
import Lottie from "lottie-react";
import flagimg from "../../../assets/United Arab Emirates.png";
import noimage from "../../../assets/noimage1.png";
import RaceSectionTimer from "../../Reuseable/RaceSectionTimer";
import noimagearabic from "../../../assets/nottacearabic.png";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

const TodayPost = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = useState(false);
  const { t } = useTranslation();
  const { data: RaceCourseRaceToday, status } = useSelector(
    (state) => state.RaceCourseRaceToday
  );



  useEffect(() => {
    if (RaceCourseRaceToday?.length !== 0) {
      console.log('sldr')
    }
    else{
      dispatch(fetchRaceCourseToday());
    }
  }, [dispatch]);



  const [userIsDesktop, setUserIsDesktop] = useState(true);
  useEffect(() => {
    window.innerWidth > 840 ? setUserIsDesktop(true) : setUserIsDesktop(false);
  }, [userIsDesktop]);

  function HandleJockey(id) {
    if (userIsDesktop === true) {
      navigate(`/racedetail`, {
        state: {
          id: id,
        },
      });
    } else {
      navigate("/racedetails", {
        state: {
          id: id,
        },
      });
    }
  }

  function HandleCard(cardid) {
    if (userIsDesktop === true) {
      navigate("/mkscard", {
        state: {
          cardid: cardid,
        },
      });
    } else {
      navigate("/mkscards", {
        state: {
          cardid: cardid,
        },
      });
    }
  }

  if (status === STATUSES.LOADING) {
    return (
      <div>
        <Lottie
          animationData={Animate}
          loop={true}
          className="Lottie compLottie"
        />
      </div>
    );
  }

  if (status === STATUSES.ERROR) {
    return (
      <h2
        style={{
          margin: "100px",
        }}
      >
        Something went wrong!
      </h2>
    );
  }
  const cookiedata = Cookies.get("i18next");
  return (
    <div className=" newpost themeclass">
    <Bounce bottom>
      <div className="Currentpostdiv">
        {RaceCourseRaceToday.length === 0 ||
        RaceCourseRaceToday === undefined ? (
          <SkeletonTheme baseColor="#00A6A7" highlightColor="#9DD9DB">
          <p>
            <Skeleton count={3} />
          </p>
        </SkeletonTheme>
        ) : (
          RaceCourseRaceToday.map((item, ind) => {
            return (
              <>
                <div className="Currentpostheader">
                  <h2>
                    {" "}
                    {cookiedata === "en" ? (
                      item.NationalityDataRaceCourse === null ? (
                        <>- </>
                      ) : (
                        item.NationalityDataRaceCourse.NameEn
                      )
                    ) : item.NationalityDataRaceCourse === null ? (
                      <>- </>
                    ) : (
                      item.NationalityDataRaceCourse.NameAr
                    )}
                  </h2>
                  <img
                    src={flagimg}
                    // src={
                    //   item.NationalityDataRaceCourse
                    //     ? item.NationalityDataRaceCourse.image
                    //     : flagimg
                    // }
                    alt=""
                    style={{
                      width: "50px",
                      heigth: "50px",
                    }}
                  />
                </div>
                <div className="CompetitionData">
                  <Accordion>
                    <div className="Competitionitem">
                      <Accordion.Item eventKey={item._id} className="acciem12">
                        <Accordion.Header onClick={() => HandleCard(item)} className="acciem12">
                          <div className="AccordionHeader">
                            <p >
                              {cookiedata === "en" ? (
                                item.TrackNameEn === null ? (
                                  <>- </>
                                ) : (
                                  item.TrackNameEn
                                )
                              ) : item.TrackNameAr === null ? (
                                <>- </>
                              ) : (
                                item.TrackNameAr
                              )}
                            </p>
                            <p></p>
                          </div>
                        </Accordion.Header>
                        {/* {item.RaceCourseData.map((name, ind) => (
                          <Accordion.Body className="acciem12">
                            <div
                              onClick={() => HandleJockey(name._id)}
                              className="Competition_Matches"
                            >
                              <p className="tooltipclass">
                                {cookiedata === "en" ? (
                                  name.RaceNameModelData === null ? (
                                    <>- </>
                                  ) : (
                                    name.RaceNameModelData.NameEn
                                  )
                                ) : name.RaceNameModelData === null ? (
                                  <>- </>
                                ) : (
                                  name.RaceNameModelData.NameAr
                                )}
                              </p>
                              <RaceSectionTimer
                                timer={
                                  name.TimeRemaining
                                    ? name.TimeRemaining
                                    : "00:00:00"
                                }
                                starttime={name.StartTime}
                                state={state}
                                setState={setState}
                              />
                            </div>
                          </Accordion.Body>
                        ))} */}
                      </Accordion.Item>
                    </div>
                    <span className="raceroursecolor"></span>
                  </Accordion>
                </div>
              </>
            );
          })
        )}
      </div>
    </Bounce>
  </div>
  );
};

export default TodayPost;
