import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import Cookies from "js-cookie";
import arrow1 from "../../assets/image 13 (Traced).png";
import moment from "moment";
import { useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import VideoFrame from "../Modal/VideoFrame";
import RaceModal from "../Modal/RaceModal";
import JockeyDetail from "../Modal/JockeyModal";
import { useSelector } from "react-redux";
import Lottie from "lottie-react";
import Animate from "../../assets/loader.json";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const TrackRecord = () => {
  const { t } = useTranslation();
  const cookiedata = Cookies.get("i18next");
  const [show, setShow] = useState(false);
  const [modaldata, setmodaldata] = useState();
  const handleClose = () => setShow(false);
  const handleShow = async (data) => {
    setmodaldata(data);
    await setShow(true);
  };
  const [showRace, setShowRace] = useState(false);
  const [modaldataRace, setmodaldataRace] = useState();
  const handleCloseRace = () => setShowRace(false);
  const handleShowRace = async (id) => {
    setmodaldataRace(id);
    await setShowRace(true);
  };

  const { data: TrackRecord, status } = useSelector(
    (state) => state.TrackRecord
  );

  const [showJockey, setShowJockey] = useState(false);
  const [modaldataJockey, setmodaldataJockey] = useState();

  const handleCloseJockey = () => setShowJockey(false);
  const handleShowJockey = async (data) => {
    setmodaldataJockey(data);
    await setShowJockey(true);
  };

  const FirstLetter = (data) => {
    return data.substring(0, 1);
  };

  return (
    <>
      <ScrollContainer className="scroll-container">
        <div>
          <div className="customClass">
            <>
              {status == "loading" ? (
                <Lottie animationData={Animate} className="Lottie compLottie" />
              ) : TrackRecord && TrackRecord?.length === 0 ? (
                <>
                  <SkeletonTheme baseColor="#00A6A7" highlightColor="#9DD9DB">
                    <p>
                      <Skeleton count={3} />
                    </p>
                  </SkeletonTheme>
                </>
              ) : (
                <table className="customers">
                  <thead>
                    <tr>
                      <th>{t("Date")}</th>
                      <th>{t("Crs")}</th>
                      <th>{t("Dist")}</th>
                      <th>{t("TC")}</th>
                      <th>{t("RT")}</th>

                      <th>{t("Dts")}</th>
                      <th>{t("Time")}</th>
                      <th>{t("Jockey")}</th>
                      <th>{t("Wgt")}</th>
                      <th>{t("FP")}</th>
                      <th>{t("Len")}</th>
                      <th>{t("RN")}</th>
                      <th>{t("BtBy")}</th>
                      <th>{t("Kgs")}</th>

                      <th>{t("Draw")}</th>
                      <th>{t("Link")}</th>
                    </tr>
                  </thead>
                  {TrackRecord && TrackRecord?.length === 0 ? (
                    <></>
                  ) : (
                    TrackRecord.map((item, i) => (
                      <tr
                        className="trackspan"
                        key={i}
                        style={{
                          background: `${
                            item?.RaceResultData?.TrackLengthData
                              ?.GroundTypeModelData?.NameEn == "Dirt"
                              ? "#9F5109"
                              : item?.RaceResultData?.TrackLengthData
                                  ?.GroundTypeModelData?.NameEn == "Turf"
                              ? "#5ec40f"
                              : "#949494"
                          }`,
                        }}
                      >
                        <td
                          className="lb"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleShowRace(item?.RaceID)}
                        >
                          {item?.RaceResultData?.Day}
                          {moment(item?.RaceResultData?.StartTime).format(
                            "DD:MM:YYYY"
                          )}
                        </td>
                        <td>
                          {item?.RaceResultData?.RaceCourseData &&
                          item?.RaceResultData?.RaceCourseData === undefined ? (
                            <></>
                          ) : cookiedata === "en" ? (
                            item?.RaceResultData?.RaceCourseData?.AbbrevEn
                          ) : (
                            item?.RaceResultData?.RaceCourseData?.AbbrevAr
                          )}
                          (
                          {FirstLetter(
                            cookiedata === "en"
                              ? item?.RaceResultData?.TrackLengthData
                                  ?.GroundTypeModelData?.NameEn
                              : item?.RaceResultData?.TrackLengthData
                                  ?.GroundTypeModelData?.NameAr
                          )}
                          )
                        </td>
                        <td>
                          {item?.RaceResultData?.TrackLengthData?.TrackLength}
                        </td>
                        <td>
                          {item?.RaceResultData &&
                          item?.RaceResultData?.TrackConditionData ===
                            undefined ? (
                            <> </>
                          ) : cookiedata === "en" ? (
                            item?.RaceResultData?.TrackConditionData.NameEn
                          ) : (
                            item?.RaceResultData?.TrackConditionData.NameAr
                          )}
                        </td>
                        <td>
                          <OverlayTrigger
                            className="tooltip-btn"
                            overlay={
                              <Tooltip
                                id="tooltip-disabled"
                                className="tooltip-btn"
                              >
                                {item?.RaceResultData &&
                                item?.RaceResultData?.RaceTypeModelData ===
                                  undefined ? (
                                  <> </>
                                ) : cookiedata === "en" ? (
                                  item?.RaceResultData?.RaceTypeModelData.NameEn
                                ) : (
                                  item?.RaceResultData?.RaceTypeModelData.NameAr
                                )}
                              </Tooltip>
                            }
                          >
                            <span
                              className="d-inline-block"
                              style={{
                                marginTop: "12px",
                              }}
                            >
                              <p className="tooltipclass1">
                                {item?.RaceResultData &&
                                item?.RaceResultData?.RaceTypeModelData ===
                                  undefined ? (
                                  <> </>
                                ) : cookiedata === "en" ? (
                                  item?.RaceResultData?.RaceTypeModelData.NameEn
                                ) : (
                                  item?.RaceResultData?.RaceTypeModelData.NameAr
                                )}
                              </p>
                            </span>
                          </OverlayTrigger>
                        </td>
                        <td>{item?.PrizeWin && item?.PrizeWin}</td>
                        <td>{item?.RaceTime}</td>
                        <td
                          onClick={() =>
                            handleShowJockey(
                              item?.JockeyOnRaceDataResult
                                ? item?.JockeyOnRaceDataResult
                                : ""
                            )
                          }
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          {item?.JockeyOnRaceDataResult === undefined ? (
                            <> </>
                          ) : cookiedata === "en" ? (
                            window.textTransform(
                              item?.JockeyOnRaceDataResult?.ShortNameEn
                            )
                          ) : (
                            item?.JockeyOnRaceDataResult?.ShortNameAr
                          )}
                        </td>
                        <td>
                          {item?.HorseIDData
                            ? item?.HorseIDData?.JockeyWeightOfHorse
                            : "-"}
                        </td>
                        <td>
                          {item?.FinalPositionDataHorse
                            ? cookiedata === "en"
                              ? item?.FinalPositionDataHorse?.NameEn
                              : item?.FinalPositionDataHorse?.NameEn
                            : "-"}
                        </td>
                        <td>{item?.Distance && item?.Distance}</td>
                        <td>{item?.Runners && item?.Runners}</td>
                        <td>
                          {item?.BeatenByData === null ? (
                            <>
                              {cookiedata === "en"
                                ? window.textTransform(
                                    item?.HorseIDData?.HorseBeatenByEnglishName
                                  )
                                : item?.HorseIDData.HorseBeatenByArabicName}
                            </>
                          ) : (
                            <>
                              {cookiedata === "en" ? (
                                <>
                                  {window.textTransform(
                                    item?.BeatenByData?.NameEn
                                  )}
                                </>
                              ) : (
                                <>{item?.BeatenByData.NameAr}</>
                              )}
                            </>
                          )}
                        </td>{" "}
                        <td>
                          {item?.BeatenByData !== null
                            ? item?.BeatenByData.WinningHorseJockeyWeight
                            : item?.HorseIDData.BeatenByJockeyWeight === null
                            ? 0
                            : item?.HorseIDData.BeatenByJockeyWeight}
                        </td>
                        <td>{item?.HorseIDData?.GateNoOfHorse}</td>
                        <td className="rb">
                          {item?.VideoLink && (
                            <span
                              href={item?.VideoLink}
                              onClick={() => handleShow(item?.VideoLink)}
                            >
                              <img src={arrow1} alt="" />
                            </span>
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </table>
              )}
            </>
          </div>
        </div>
      </ScrollContainer>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <VideoFrame data={modaldata} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        show={showJockey}
        onHide={handleCloseJockey}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <JockeyDetail data={modaldataJockey} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        show={showRace}
        onHide={handleCloseRace}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <RaceModal id={modaldataRace} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};
export default TrackRecord;
