import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getRaceCardEverydaySlice = createSlice({
    name: 'RaceCardEveryday',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchRaceCardEveryday.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchRaceCardEveryday.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchRaceCardEveryday.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setPedigree, setStatus } = getRaceCardEverydaySlice.actions;
export default getRaceCardEverydaySlice.reducer;

export const fetchRaceCardEveryday = createAsyncThunk('/RaceCardEveryday/fetch', async ({ cardid }) => {
    const res = await axios.get(`${window.env.API_URL}RaceCardEveryday/${cardid}`);
    const data =  res.data;
    return data.data;
})  