import { RaceCardData } from "../../data/data";
import horse from "../../assets/horse.png";
import ScrollContainer from "react-indiana-drag-scroll";
import { fetchRacePredictor } from "../../redux/getReducer/getRacePrediction";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import socket from "../../../Socket";
import { useState } from "react";
import Lottie from "lottie-react";
import Animate from "../../assets/loader.json";
const Predictor = ({ RacePredictor }) => {

  const [PredictorData, setPredictionData] = useState(RacePredictor);

  function calculateVotes(votes, index) {
    var total = 0;
    for (var i in votes) {
      total += votes[i];
    }
    return total;
  }


  useEffect(() => {
    setPredictionData(RacePredictor)
  },[RacePredictor])


  useEffect(() => {
    socket.on("SingleRacePredictor",(data)=>{
      setPredictionData(data[1].votesdata);
      console.log(Predictor,'s1212')
  });
  },[])
  const { t } = useTranslation()
  const cookiedata = Cookies.get('i18next');

  function calculatePercentage(x, y) {
    return (x / y) * 100;
  }

  console.log(RacePredictor,"RacePredictor")

  return (
    <ScrollContainer className="scroll-container">
      <div>
        {
          !PredictorData?.RacehorsesData ?  <Lottie animationData={Animate} className="load1" /> : 
          PredictorData?.RacehorsesData &&
          PredictorData?.RacehorsesData?.map((item, index) => {
            return (
              <div className="predictor">
                <div className="predict">
                  <>
                    <div className="predictdata">
                      <div
                        className="predictDetail"
                       
                      >
                        <h6>{item ? (cookiedata === "en" ? item?.HorseModelIdData1?.NameEn : item?.HorseModelIdData1?.NameAr) : <></>}({item.HorseNo})</h6>

                        <p>
                          {/* {t("score")} */}
                          {/* {calculatePercentage(
                                item.votes,
                                item.totalvotes
                              )?.toFixed(1)} */}
                              {
                                item?.HorseModelIdData1?.Votes
                              }
                              %
                        </p>
                      </div>
                      <img src={horse} alt=""  style={{
                          marginLeft: `${item?.HorseModelIdData1?.Votes}% `,
                          transition: "margin-left 2s",
                          // left: `${score}%`,
                        }}/>
                    </div>
                  </>
                  <button>{index+1}</button>
                </div>
              </div>
            );
          })
        }
      
      </div>
    </ScrollContainer>
  );
};
export default Predictor;