import axios from "axios";
import Cookies from "js-cookie";

import { createAsyncThunk,createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE : 'idle',
    ERROR:'error',
    LOADING: 'loading',
});

const getsingleracehorse = createSlice({
    name: 'singleracehorse',
    initialState: {
        data:[],
        status : STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
        .addCase(fetchsingleracehorse.pending, (state, action) => {
            state.status = STATUSES.LOADING;
        })
        .addCase(fetchsingleracehorse.fulfilled, (state, action) => {
            state.data = action.payload;
            state.status = STATUSES.IDLE
        })
        .addCase(fetchsingleracehorse.rejected , (state,action) => {
            state.status = STATUSES.ERROR;
        })
    }
});

export const {setRace , setStatus} = getsingleracehorse.actions;
export default getsingleracehorse.reducer;

export const fetchsingleracehorse = createAsyncThunk('/singleracehorseget/fetch', async({id}) => {
    const cookiedata = Cookies.get("i18next");
    const token = Cookies.get("token");
        const res = await axios.get(`${window.env.API_URL}/getsinglerace/${id}`, {
            headers: { "Authorization": token, 'x-xsrf-token': token }
          },
          );
    const raceData = res.data;
    return raceData.data.RacehorsesData;
})