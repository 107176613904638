import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Bounce } from "react-reveal";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import flagimg from "../../../assets/United Arab Emirates.png";
import noimage from "../../../assets/noimage1.png";
import RaceSectionTimer from "../../Reuseable/RaceSectionTimer";
import { useSelector } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const AntePost = ({ AntePostRaces }) => {
  const navigate = useNavigate();
  const [state, setState] = useState(false);
  const { data: ThemeSetting } = useSelector((state) => state.ThemeSetting);

  const { t } = useTranslation();
  const [userIsDesktop, setUserIsDesktop] = useState(true);
  useEffect(() => {
    window.innerWidth > 840 ? setUserIsDesktop(true) : setUserIsDesktop(false);
  }, [userIsDesktop]);

  function HandleJockey(id) {
    if (userIsDesktop === true) {
      navigate(`/racedetail`, {
        state: {
          id: id,
        },
      });
    } else {
      navigate("/racedetails", {
        state: {
          id: id,
        },
      });
    }
  }

  function HandleCard(cardid) {
    // if (userIsDesktop === true) {
    //   navigate("/mkscard", {
    //     state: {
    //       cardid: cardid,
    //     },
    //   });
    // } else {
    //   navigate("/mkscards", {
    //     state: {
    //       cardid: cardid,
    //     },
    //   });
    // }
  }
  console.log(AntePostRaces, "dsads");

  const cookiedata = Cookies.get("i18next");
  return (
    <div
      className=" newpost "
      style={{
        background: `${ThemeSetting && ThemeSetting.PrimaryColor}`,
      }}
    >
      <Bounce bottom>
        <div className="Currentpostdiv">
          {AntePostRaces?.length === 0 || AntePostRaces === undefined ? (
            <SkeletonTheme baseColor="#00A6A7" highlightColor="#9DD9DB">
              <p>
                <Skeleton count={3} />
              </p>
            </SkeletonTheme>
          ) : (
            AntePostRaces?.map((item, ind) => {
              return (
                <>
                  <div className="Currentpostheader">
                    <h2> {item.Year}</h2>
                  </div>
                  <div className="CompetitionData">
                    <Accordion defaultValue="0">
                    <div className="Competitionitem">
                                            {item.SingleDatas.map((singledatarecord, key) => {
                        return (
                          <>
                            <Accordion.Item
                              eventKey={
                                singledatarecord?.RacesOfAllRaceCourseAnnuall
                                  ?._id
                              }
                              className="acciem12"
                            >
                              <Accordion.Header
                                onClick={() => HandleCard(item)}
                                className="AntePostRaceCourseHead"
                              >
                                <div className="AccordionHeader">
                                  <p>
                                    {cookiedata === "en" ? (
                                      singledatarecord
                                        .RacesOfAllRaceCourseAnnuall
                                        .TrackNameEn === null ? (
                                        <>- </>
                                      ) : (
                                        singledatarecord
                                          .RacesOfAllRaceCourseAnnuall
                                          .TrackNameEn
                                      )
                                    ) : singledatarecord
                                        .RacesOfAllRaceCourseAnnuall
                                        .TrackNameAr === null ? (
                                      <>- </>
                                    ) : (
                                      singledatarecord
                                        .RacesOfAllRaceCourseAnnuall.TrackNameAr
                                    )}
                                  </p>
                                  <span
                              className="raceroursecolor"
                              style={{
                                background: `${singledatarecord.RacesOfAllRaceCourseAnnuall.ColorCodeData?.Color}`,
                              }}
                            ></span>
                                  <img
                                    src={flagimg}
                                    alt="flag"
                                    style={{
                                      width: "50px",
                                      heigth: "50px",
                                    }}
                                  />
                                </div>
                              </Accordion.Header>
                              {singledatarecord.RacesData.map(
                                (singleracerecord) => {
                                  return (
                                    <Accordion.Body>
                                      <div
                                        onClick={() =>
                                          HandleJockey(singleracerecord._id)
                                        }
                                        className="Competition_Matches antepostbody"
                                      >
                                        {" "}
                                        {/* <p>
                                          {
                                            singleracerecord.RaceNameModelData
                                              .NameEn
                                          }
                                        </p> */}
{/* dateset */}
                                        <p>
                                        {cookiedata === "en" ? (
                                           singleracerecord.RaceNameModelData === null ? (
                                            <>- </>
                                          ) : (
                                            singleracerecord.RaceNameModelData.NameEn
                                           
                                          )
                                        ) :  singleracerecord.RaceNameModelData === null ? (
                                          <>- </>
                                        ) : (
                                          singleracerecord.RaceNameModelData.NameAr
                                        )}
                                      </p>
                                      <p>
                                      Race No :-  
                                        {
                                           "    "+singleracerecord.RaceNumber
                                        }
                                      </p>
                                      <p>
                                        {
                                          moment(singleracerecord.StartTime).format("DD-MM-YYYY")
                                          // moment(singleracerecord.StartTime).format("YYYY-MM-DD 00:00")
                                        }
                                      </p>

                                      </div>
                                    </Accordion.Body>
                                  );
                                }
                              )}
                            </Accordion.Item>
                          </>
                        );
                      })}
                      </div>

                    </Accordion>
                  </div>
                  {/* <div className="CompetitionData">
                          <Accordion defaultValue="0">
                            <div className="Competitionitem">
                              <Accordion.Item eventKey={item._id} className="acciem12">
                                <Accordion.Header onClick={() => HandleCard(item)>}
                                  <div className="AccordionHeader">
                                    <p >
                                      {cookiedata === "en" ? (
                                        item.TrackNameEn === null ? (
                                          <>- </>
                                        ) : (
                                          item.TrackNameEn
                                        )
                                      ) : item.TrackNameAr === null ? (
                                        <>- </>
                                      ) : (
                                        item.TrackNameAr
                                      )}
                                    </p>
                                    <p></p>
                                  </div>
                                </Accordion.Header>
                                {item.RaceCourseData.map((name, ind) => (
                                  <Accordion.Body >
                                    <div
                                      onClick={() => HandleJockey(name._id)}
                                      className="Competition_Matches"
                                    >
                                      <p>
                                        {cookiedata === "en" ? (
                                          name.RaceNameModelData === null ? (
                                            <>- </>
                                          ) : (
                                            name.RaceNameModelData.NameEn
                                          )
                                        ) : name.RaceNameModelData === null ? (
                                          <>- </>
                                        ) : (
                                          name.RaceNameModelData.NameAr
                                        )}
                                      </p>
                                      <RaceSectionTimer
                                        timer={
                                          name.TimeRemaining
                                            ? name.TimeRemaining
                                            : "00:00:00"
                                        }
                                        starttime={name.StartTime}
                                        state={state}
                                        setState={setState}
                                      />
                                    </div>
                                  </Accordion.Body>
                                ))}
                              </Accordion.Item>
                            </div>
                            <span className="raceroursecolor"></span>
                          </Accordion>
                        </div> */}
                </>
              );
            })
          )}
        </div>
      </Bounce>
    </div>
  );
};

export default AntePost;
