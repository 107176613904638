import React from 'react'
import ExNews from '../ExNews';
import Search from '../Search';
import Tracker from '../Tracker';
import Notification from '../Notification';
import Language from '../Language';
import Auth from '../../../Reuseable/Auth';

const TopNav = () => {
  return (
    <div className='row topbar'>
        <div className='col-sm-8'>
        <ExNews />
            
        </div>
            <div className='col-sm-4'>
            <Language />
            <Tracker />
            <Notification />
           
            <Auth />
             

            </div>
    </div>
  )
}

export default TopNav
