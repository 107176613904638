import Header from './Header';
import Auth from './Auth';
import Search from '../Home/Components/Search';
const layout = () => {
  return (
    <>
    <div className='headerlayout'>
      <div className='row desktopheader'>
        <div className='col-sm-8'> <Header /></div>
        <div className='col-sm-4'><Search /></div>
      </div>
       
        
        
    </div>
    </>
  )
}
export default layout