import React from "react";
import { raceCourseData } from "../../data/data";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const RaceCourseContent = ({ racecourse }) => {

  const cookiedata = Cookies.get("i18next");



  const content = cookiedata === "en" ? raceCourseData[racecourse]?.content || "" : raceCourseData[racecourse]?.contentAr
  const content1 = cookiedata === "en" ? raceCourseData[racecourse]?.content1 || "" : raceCourseData[racecourse]?.content1Ar
  const { t } = useTranslation();

  // Replace "\n" with "<br/>" to interpret line breaks as HTML
  const formattedContent = content?.replace(/\n/g, "<br/>");
  const formattedContent1 = content1?.replace(/\n/g, "<br/>");
  console.log(content);
  return (
    <>
      {" "}
      <div className="container raceCourseContent">
        <div className="row">
          <div className="col-sm-6">
            <div className="imageSection">
              <img src={raceCourseData[racecourse]?.image} alt="" />
            </div>
          </div>

          <div className="col-sm-6">
            <div className="raceCourseTxt">
              <h3> {cookiedata === "en" ? raceCourseData[racecourse]?.heading : raceCourseData[racecourse]?.headingAr}</h3>
              <p dangerouslySetInnerHTML={{ __html: formattedContent }}></p>
            </div>
          </div>

          <div className="col-sm-12 listSection">
            <p dangerouslySetInnerHTML={{ __html: formattedContent1 }}></p>
            <ul>
              {
                cookiedata === "en" ? <> {raceCourseData[racecourse]?.listContent?.map((elem) => {
                  return <li>{elem}</li>;
                })}</> : <> {raceCourseData[racecourse]?.listContentAr?.map((elem) => {
                  return <li>{elem}</li>;
                })}</>
              }
             
            </ul>
            <p>{cookiedata === "en" ?  raceCourseData[racecourse]?.content3 :  raceCourseData[racecourse]?.content3Ar}</p>
            <ul>

            {
                cookiedata === "en" ? <> {raceCourseData[racecourse]?.listContent1?.map((elem) => {
                  return <li>{elem}</li>;
                })}</> : <> {raceCourseData[racecourse]?.listContent1Ar?.map((elem) => {
                  return <li>{elem}</li>;
                })}</>
              }

              
             
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default RaceCourseContent;
