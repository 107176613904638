import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getDrawSlice = createSlice({
    name: 'draw',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchDraw.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchDraw.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchDraw.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setAds, setStatus } = getDrawSlice.actions;
export default getDrawSlice.reducer;

export const fetchDraw = createAsyncThunk('/Draw/fetch', async ({ id }) => {
    const res = await axios.get(`${window.env.API_URL}/Draw/${id}`);
    const data = res.data;
    return data
})