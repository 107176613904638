import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getResultYearFilterForStatsSlice = createSlice({
    name: 'ResultYearFilterForStats',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchResultYearFilterForStats.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchResultYearFilterForStats.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchResultYearFilterForStats.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setResultYearFilterForStats, setStatus } = getResultYearFilterForStatsSlice.actions;
export default getResultYearFilterForStatsSlice.reducer;

export const fetchResultYearFilterForStats = createAsyncThunk('/ResultYearFilterForStats/fetch', async ({
    year,
    Nationality,
    RaceCourse,
    GroundType,
    RaceKind,
    HorseKind,
    RaceType,
    TrackLength
}) => {
    const res = await axios.get(`${window.env.API_URL}/ResultYearFilterForStats?year=${year === undefined ? "" : year}&Nationality=${Nationality === undefined ? "" : Nationality}&RaceCourse=${RaceCourse === undefined ? "" : RaceCourse}&GroundType=${GroundType === undefined ? "" : GroundType}&RaceKind=${RaceKind === undefined ? "" : RaceKind}&HorseKind=${HorseKind === undefined ? "" : HorseKind }&RaceType=${RaceType=== undefined ? "" : RaceType}&TrackLength=${TrackLength === undefined ? "" : TrackLength}`);
    const ResultYearFilterForStatsData = res.data;
    return ResultYearFilterForStatsData.results;
})
