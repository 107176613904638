import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});


const getHorseStatsDetailSlice = createSlice({
    name: 'horsestatsdetail',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchHorseStatsDetail.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchHorseStatsDetail.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchHorseStatsDetail.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setTrainer, setStatus } = getHorseStatsDetailSlice.actions;
export default getHorseStatsDetailSlice.reducer;

export const fetchHorseStatsDetail = createAsyncThunk('/HorseHistoryForStatisiticsV2/fetch', async ({ id }) => {
    const res = await axios.get(`${window.env.API_URL}/HorseHistoryForStatisiticsV2/${id}`);
    const trainerstatsData = res.data;
    return trainerstatsData.data;
})