import Layout from "../Components/Reuseable/layout"
import '../Components/CSS/pagesCSS/about.css'
import Footer from '../Components/Reuseable/Footer.jsx';
import CoptRight from '../Components/Reuseable/Copyrights'
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { fetchSponsor, STATUSES } from "../redux/getReducer/getSponsorSlice"
import { fetchAds, STATUSES } from "../redux/getReducer/getAdsSlice";

import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Carousel from "react-bootstrap/Carousel";

import DefaultImg from "../assets/default.png";
const Justsponsor = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: ads, status } = useSelector((state) => state.ads);

  useEffect(() => {
    dispatch(fetchAds());
  }, [dispatch]);

  
  const cookiedata = Cookies.get("i18next");
  return (
    <>
    <div className="aboutpage justsponser">
      <div className="aboutpagesection">
      <div className="sponsor">
        <Carousel>
          {ads.map((item) => {
            return (
              <Carousel.Item interval={2000}>
                <a>
                  <div className="sponsorimg">
                    <img src={item.image ? item.image : DefaultImg} alt="" />
                    {/* <h2 className="first-txt">
                      {cookiedata === "en" ? (
                        item.TitleEn === null ? (
                          <>- </>
                        ) : (
                          item.TitleEn
                        )
                      ) : item.TitleAr === null ? (
                        <>- </>
                      ) : (
                        item.TitleAr
                      )}
                    </h2> */}
                  </div>
                </a>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
      </div>
    </div>
    </>
  )
}
export default Justsponsor