import React, { useEffect, useState, Fragment } from "react";
import Layout from "../Components/Reuseable/layout";
import Footer from "../Components/Reuseable/Footer.jsx";
import CoptRight from "../Components/Reuseable/Copyrights";
import Cookies from "js-cookie";
import { Accordion } from "react-bootstrap";
import "../../Webiste/Components/CSS/pagesCSS/dashboard.css";
import ScrollContainer from "react-indiana-drag-scroll";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "../Components/CSS/RaceCardCSS/racecard.css";
import Justsponsor from "./justsponser";
import Lottie from 'lottie-react';
import Animate from "../assets/loader.json";
import ExNews from "../Components/Home/Components/ExNews";
import Language from "../Components/Home/Components/Language";
import Notification from "../Components/Home/Components/Notification";
import Search from "../Components/Home/Components/Search";
// import { Accordion } from 'react-bootstrap';

// import { Fragment } from 'react';

// selector
export default function MySelectionPage() {
  const { t } = useTranslation();
  const cookiedata = Cookies.get("i18next");
  const token = Cookies.get("token");
  const [CategorySub, setCategorySub] = useState();
  const [isLoading, setIsLoading] = useState(false);


  // const navigate = useNavigate();

  let getDataCategory = async () => {
    const tokencheck =
      token === undefined
        ? null
        : { Authorization: token, "x-xsrf-token": token };
    setIsLoading(true);

    await fetch(`${window.env.API_URL}/MySelection`, {
      headers: tokencheck,
    })
      .then((res) => res.json())
      .then((result) => {
        setCategorySub(result);
        setIsLoading(false);
      });
  };
  const numbers = `۰۱۲۳۴۵۶۷۸۹`;
  const convert = (num) => {
    let res = "";
    const str = num ? num.toString() : "";
    for (let c of str) {
      res += numbers.charAt(c);
    }
    return res;
  };

  useEffect(() => {
    getDataCategory();
  }, []);

  return (
    <Fragment>
      <>
        {/* <Layout /> */}
          

    
        <div className="container-fluid mystages">
          <div className="row">
            <div className="col-sm-8 stages">
              <div className="raceCarParent stages">
                <div className="raceCardHeader1">
                  <div className="row">
                    <div className="col-sm-10">
                      <h1>{t("MySelection")}</h1>
                    </div>
                    {/* <div className="col-sm-2 voted">
                      <p>{t("Voted In Total Races")}</p>{" "}
                      {CategorySub ? (
                        <h3> {cookiedata === "en" ? CategorySub.votedinraces : convert(CategorySub.votedinraces)}</h3>
                      ) : (
                        <h2> {cookiedata === "en" ? "0" : convert(0)}</h2>
                      )}
                    </div> */}
                  </div>

                  {/* <p>
                    {" "}
                    Today's racecards, tips, form and betting for every
                    racecourse in the World, and for the biggest racing fixtures
                    in the international calendar.
                  </p> */}
                </div>
                {isLoading ? <Lottie
                  animationData={Animate}
                  className="load1"
                /> : CategorySub ? (
                  CategorySub.data.map((singlecompetition, i) =>
                    singlecompetition.CompetitionDataOfRace.length > 0 ? (
                      <Accordion defaultActiveKey="0"  className="mobilesitewidth">
                        <Accordion.Item eventKey={i === 0 ? '0' : i}>
                          <div>
                            <Accordion.Header>
                              <div className="accordionHeader row">
                                <div className="headerCard1 carddisplay121 col-sm-8">
                                  <h4>
                                    {cookiedata === "en"
                                      ? singlecompetition?.NameEn
                                      : singlecompetition?.NameAr}
                                  </h4>
                                </div>
                                <div className="headerCard2 col-sm-3">
                                  {" "}
                                  <p>
                                    {singlecompetition.CompetitionDataOfRace &&
                                      cookiedata === "en"
                                      ? singlecompetition?.CompetitionDataOfRace
                                        ?.length
                                      : convert(
                                        singlecompetition
                                          ?.CompetitionDataOfRace?.length
                                      )}
                                  </p>
                                </div>
                                <div className="headerCard3 col-sm-1">
                                  <i
                                    class="fa fa-chevron-circle-down"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </Accordion.Header>

                            <Accordion.Body key="i">
                              <div className="raceCardTable">
                                <ScrollContainer>
                                  <table className="customers tableHeaderCard">
                                    <thead>
                                      <tr key="i">
                                        <th>{t("Date")}</th>
                                        <th>{t("Type")}</th>
                                        <th>{t("Horse Name")}</th>

                                        <th>{t("Race No")} </th>
                                        <th>{t("Race course")}</th>
                                        <th>{t("Country")}</th>
                                        <th>{t("Race Name")}</th>
                                        <th>{t("Race Type")}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {singlecompetition.CompetitionDataOfRace ? (
                                        singlecompetition.CompetitionDataOfRace.map(
                                          (Competitiondetail, i) => (
                                            <tr>
                                              {/* <td className='lb'> {Competitiondetail.RaceDataOfCompetition ? Competitiondetail.RaceDataOfCompetition._id : "not aaialable"}</td> */}
                                              <td>
                                                {" "}
                                                {moment(
                                                  singlecompetition?.StartDate
                                                ).format(
                                                  "MMMM Do YYYY, h:mm:ss a"
                                                )}{" "}
                                              </td>
                                              <td className="lb">
                                                {" "}
                                                {Competitiondetail?.RaceDataOfCompetition
                                                  ? <>
                                                  {
                                                    Competitiondetail
                                                    ?.PointTableOfRaceofRace
                                                     ? Competitiondetail
                                                    ?.PointTableOfRaceofRace
                                                    ?.Type : <>-</>
                                                  
                                                  }
                                                  </> : <></>}
                                              </td>
                                              {Competitiondetail?.RaceDataOfCompetition ? (
                                                <td className="lb">
                                                  {" "}
                                                  {Competitiondetail
                                                    ?.RaceDataOfCompetition
                                                    ?.CompetitionRaceIDData &&
                                                    cookiedata === "en" ? (
                                                    Competitiondetail
                                                      ?.RaceDataOfCompetition
                                                      ?.CompetitionRaceIDData[0]
                                                      ?.CompetitionHorseIDData ===
                                                      undefined ? (
                                                      <></>
                                                    ) : (
                                                      Competitiondetail
                                                        ?.RaceDataOfCompetition
                                                        ?.CompetitionRaceIDData[0]
                                                        ?.CompetitionHorseIDData
                                                        ?.NameEn
                                                    )
                                                  ) : Competitiondetail
                                                    ?.RaceDataOfCompetition
                                                    ?.CompetitionRaceIDData[0]
                                                    ?.CompetitionHorseIDData ===
                                                    undefined ? (
                                                    <></>
                                                  ) : (
                                                    Competitiondetail
                                                      ?.RaceDataOfCompetition
                                                      ?.CompetitionRaceIDData[0]
                                                      ?.CompetitionHorseIDData
                                                      ?.NameAr
                                                  )}
                                                </td>
                                              ) : (
                                                <></>
                                              )}

                                              {Competitiondetail.RaceDataOfCompetition ? (
                                                <td className="lb">
                                                  {" "}
                                                  {cookiedata === "en"
                                                    ? Competitiondetail
                                                      ?.RaceDataOfCompetition
                                                      ?.RaceNumber
                                                    : convert(
                                                      Competitiondetail
                                                        ?.RaceDataOfCompetition
                                                        ?.RaceNumber
                                                    )}
                                                </td>
                                              ) : (
                                                <></>
                                              )}
                                              <td className="lb">
                                                {" "}
                                                {Competitiondetail.RaceDataOfCompetition &&
                                                  cookiedata === "en" ? (
                                                  Competitiondetail
                                                    ?.RaceDataOfCompetition
                                                    ?.RaceCourseData === null ? (
                                                    <></>
                                                  ) : (
                                                    Competitiondetail
                                                      ?.RaceDataOfCompetition
                                                      ?.RaceCourseData
                                                      ?.TrackNameEn
                                                  )
                                                ) : Competitiondetail
                                                  ?.RaceDataOfCompetition
                                                  ?.RaceCourseData === null ? (
                                                  <></>
                                                ) : (
                                                  Competitiondetail
                                                    ?.RaceDataOfCompetition
                                                    ?.RaceCourseData.TrackNameAr
                                                )}
                                              </td>
                                              <td className="lb">
                                                {" "}
                                                {Competitiondetail?.RaceDataOfCompetition &&
                                                  cookiedata === "en" ? (
                                                  Competitiondetail
                                                    ?.RaceDataOfCompetition
                                                    ?.RaceCourseData
                                                    ?.NationalityDataRaceCourse ===
                                                    undefined ? (
                                                    <></>
                                                  ) : (
                                                    Competitiondetail
                                                      ?.RaceDataOfCompetition
                                                      ?.RaceCourseData
                                                      ?.NationalityDataRaceCourse
                                                      ?.NameEn
                                                  )
                                                ) : Competitiondetail
                                                  .RaceDataOfCompetition
                                                  .RaceCourseData
                                                  .NationalityDataRaceCourse ===
                                                  undefined ? (
                                                  <></>
                                                ) : (
                                                  Competitiondetail
                                                    ?.RaceDataOfCompetition
                                                    ?.RaceCourseData
                                                    ?.NationalityDataRaceCourse
                                                    ?.NameAr
                                                )}
                                              </td>
                                              <td className="lb">
                                                {Competitiondetail.RaceDataOfCompetition &&
                                                  cookiedata === "en" ? (
                                                  Competitiondetail
                                                    ?.RaceDataOfCompetition
                                                    ?.RaceNameModelData ===
                                                    undefined ? (
                                                    <></>
                                                  ) : (
                                                    Competitiondetail
                                                      ?.RaceDataOfCompetition
                                                      ?.RaceNameModelData.NameEn
                                                  )
                                                ) : Competitiondetail
                                                  ?.RaceDataOfCompetition
                                                  ?.RaceNameModelData ===
                                                  undefined ? (
                                                  <></>
                                                ) : (
                                                  Competitiondetail
                                                    .RaceDataOfCompetition
                                                    .RaceNameModelData.NameAr
                                                )}
                                              </td>
                                              <td className="lb">
                                                {Competitiondetail.RaceDataOfCompetition &&
                                                  cookiedata === "en" ? (
                                                  Competitiondetail
                                                    ?.RaceDataOfCompetition
                                                    ?.RaceTypeModelData ===
                                                    undefined ? (
                                                    <></>
                                                  ) : (
                                                    Competitiondetail
                                                      ?.RaceDataOfCompetition
                                                      ?.RaceTypeModelData.NameEn
                                                  )
                                                ) : Competitiondetail
                                                  ?.RaceDataOfCompetition
                                                  ?.RaceTypeModelData ===
                                                  undefined ? (
                                                  <></>
                                                ) : (
                                                  Competitiondetail
                                                    ?.RaceDataOfCompetition
                                                    ?.RaceTypeModelData.NameAr
                                                )}
                                              </td>
                                              {/* {Competitiondetail.RaceDataOfCompetition ? (
                                            <td className="lb">
                                              {" "}
                                              {Competitiondetail
                                                .RaceDataOfCompetition
                                                .CompetitionRaceIDData[0]
                                                .CompetitionHorseIDData.NameEn
                                                ? Competitiondetail
                                                    .RaceDataOfCompetition
                                                    .CompetitionRaceIDData[0]
                                                    .Point
                                                : 0}
                                            </td>
                                          ) : (
                                            <></>
                                          )} */}
                                              {/* {Competitiondetail.RaceDataOfCompetition ? (
                                            <td className="lb">
                                              {" "}
                                              {Competitiondetail
                                                .RaceDataOfCompetition
                                                .CompetitionRaceIDData[0]
                                                .CompetitionHorseIDData.NameEn
                                                ? Competitiondetail
                                                    .RaceDataOfCompetition
                                                    .CompetitionRaceIDData[0]
                                                    .BonusPoint
                                                : 0}
                                            </td>
                                          ) : (
                                            <></>
                                          )} */}
                                            </tr>
                                          )
                                        )
                                      ) : (
                                        <tr>
                                          <td>No data found</td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </ScrollContainer>
                              </div>
                            </Accordion.Body>
                          </div>
                        </Accordion.Item>
                      </Accordion>
                    ) : (
                      <></>
                    )
                  )
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="col-sm-4">
              <Justsponsor />
            </div>
          </div>
        </div>

        <Footer />
        <CoptRight />
      </>
    </Fragment>
  );
}
