import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getResultScreencreenSlice = createSlice({
    name: 'ResultScreencreen',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchResultScreencreen.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchResultScreencreen.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchResultScreencreen.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setResultScreencreen, setStatus } = getResultScreencreenSlice.actions;
export default getResultScreencreenSlice.reducer;

export const fetchResultScreencreen = createAsyncThunk('/ResultScreencreenget/fetch', async ({start}) => {
    const res = await axios.get(`${window.env.API_URL}/ResultScreen?Date=${start}`);
    const ResultScreencreenData = res.data;
    return ResultScreencreenData.data;
})
