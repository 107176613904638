import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getResultRaceTypeSlice = createSlice({
    name: 'ResultRaceType',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchResultRaceType.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchResultRaceType.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchResultRaceType.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setResultRaceType, setStatus } = getResultRaceTypeSlice.actions;
export default getResultRaceTypeSlice.reducer;

export const fetchResultRaceType = createAsyncThunk('/ResultRaceType/fetch', async ({ Value5, data1,data2,data3,data4,data5,data6,data7,data8,data9,data10,data11,data12,data13,data14, IncreasePage3 }) => {
    const res = await axios.get(`${window.env.API_URL}/ResultRaceType?NameEn=${Value5}&size=${IncreasePage3}&StartTimeOfRace=${data1}&HorseID=${data2}&TrainerOnRace=${data3}&JockeyOnRace=${data4}&RaceCourseOfRace=${data5}&RaceCourseNationality=${data6}&RaceTypeOfRace=${data7}&RaceGround=${data8}&RaceTracklength=${data9}&OwnerId=${data10}&MeetingType=${data11}&Sponsor=${data12}&HorseKindinRace=${data13}&date=${data14}`);
    const ResultRaceTypeData = res.data;
    return ResultRaceTypeData.data;
})
