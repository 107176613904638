import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

const getTrainerStatsSlice = createSlice({
  name: "trainerstats",
  initialState: {
    data: [],
    status: STATUSES.IDLE,
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchTrainerStats.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchTrainerStats.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(fetchTrainerStats.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { setTrainer, setStatus } = getTrainerStatsSlice.actions;
export default getTrainerStatsSlice.reducer;

export const fetchTrainerStats = createAsyncThunk(
  "/TrainerStatistics/fetch",
  async ({
    year,
    StartDate,
    EndDate,
    RaceCourseSearch,
    HorseKindSearch,
    TrackLengthSearch,
    RaceTypeSearch,
    RaceKindSearch,
    RaceNationalitySearch,
    RaceGroundSearch,
    RaceDateSearch,
    currentPage,
  }) => {
    const res = await axios.get(
      `${window.env.API_URL}/TrainerStatistics?year=${
        year === undefined ? "" : year
      }&startTimeTo=${StartDate}&startTimeFrom=${EndDate}&RaceCourse=${RaceCourseSearch}&HorseKindinRace=${HorseKindSearch}&TrackLength=${TrackLengthSearch}&RaceType=${RaceTypeSearch}&RaceKind=${RaceKindSearch}&NationalityID=${RaceNationalitySearch}&Ground=${RaceGroundSearch}&Date=${RaceDateSearch}&page=${currentPage}`
    );
    const trainerstatsData = res.data;
    return trainerstatsData;
  }
);
