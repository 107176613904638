import { RaceCardData } from "../../data/data";
import NotFound from "../../assets/notfount.png";
import NotFoundArabic from "../../assets/notresultarabic.png";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useState } from "react";
import Cookies from "js-cookie";
import Image from "../Reuseable/Image";
import Lottie from "lottie-react";
import Animate from "../../assets/loader.json";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Summary = () => {
  const [distance, setDistance] = useState([]);
  const [racecourse, setRacecourse] = useState([]);
  const [classtype, setClasstype] = useState([]);
  const [trainer, setTrainer] = useState([]);
  const [pedigree, setPedigree] = useState([]);
  const [overall, setOverall] = useState([]);
  const [firstFiveHorses, setFirstFiveHorses] = useState([]);
  const { t } = useTranslation();
  const cookiedata = Cookies.get("i18next");

  const { data: summary, status } = useSelector((state) => state.summary);

  useEffect(() => {
    setDistance(summary?.Distance);
    setRacecourse(summary?.RaceCourse);
    setClasstype(summary?.ClassType);
    setTrainer(summary?.Time);
    setPedigree(summary?.Pedigree);
    setOverall(summary?.OverAll);
    setFirstFiveHorses(summary?.FirstFiveHorses);
  }, [
    summary?.ClassType,
    summary?.Distance,
    summary?.OverAll,
    summary?.Pedigree,
    summary?.RaceCourse,
    summary?.Time,
    summary?.FirstFiveHorses,
  ]);

  console.log(firstFiveHorses, "firstFiveHorses");
  console.log(classtype, "classtype");

  function toPascalCaseWithAcronym(inputString) {
    // Split the input string by spaces
    const words = inputString.split(" ");

    // Capitalize the first letter of each word (including the acronym)
    const pascalCasedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    // Join the words back together without spaces
    const pascalCaseString = pascalCasedWords.join("");

    return pascalCaseString;
  }

  const extendedDistance =
    distance?.length == 0
      ? firstFiveHorses
      : distance;
  const extendedracecourse =
    racecourse?.length == 0
      ? firstFiveHorses
      : racecourse;
  const extendedclasstype =
    classtype?.length == 0
      ? firstFiveHorses
      : classtype;
  const extendedtrainer =
    trainer?.length == 0
      ? firstFiveHorses
      : trainer;
  const extendedpedigree =
    pedigree?.length == 0
      ? firstFiveHorses
      : pedigree;
  const extendedoverall =
    overall?.length == 0
      ? firstFiveHorses
      : overall;

  console.log(extendedpedigree,"extendedpedigree");

  return (
    <div className="row">
      {status == "loading" ? (
        <Lottie animationData={Animate} className="Lottie compLottie" />
      ) : (
        <>
          <div className="SummaryDistance col-sm-4">
            <p>{t("Distance")}</p>
            {extendedDistance && extendedDistance?.length == 0 ? (
              <>
                <SkeletonTheme baseColor="#00A6A7" highlightColor="#9DD9DB">
                  <p>
                    <Skeleton count={3} />
                  </p>
                </SkeletonTheme>
              </>
            ) : (
              <>
                {extendedDistance?.map((item, i) => {
                  return (
                    <div key={item.id || item._id}>
                      <div className="row allsum" key={i}>
                        <div className="col-sm-2">
                          {item.HorseModelIdData1 &&
                          item.HorseModelIdData1.length === 0 ? (
                            <p>-</p>
                          ) : item.HorseIDData &&
                            item.HorseIDData.HorseModelIdData1 &&
                            item.HorseIDData.HorseModelIdData1[0] ===
                              undefined ? (
                            <p>-</p>
                          ) : (
                            <p>
                              {item.HorseIDData
                                ? item.HorseIDData.HorseModelIdData1[0].HorseNo
                                : item.HorseNo}
                            </p>
                          )}
                        </div>
                        <div className="col-sm-3">
                          <Image
                            data={
                              item.HorseIDData &&
                              item.HorseIDData.ActiveOwnerData &&
                              item.HorseIDData.ActiveOwnerData.OwnerIDData
                                ? ""
                                : ""
                            }
                          />
                        </div>
                        <div className="col-sm-7">
                          <p>
                            {cookiedata === "en"
                              ? toPascalCaseWithAcronym(
                                  item.HorseIDData
                                    ? item.HorseIDData.NameEn
                                    : item.HorseModelIdData1.NameEn
                                )
                              : item.HorseIDData
                              ? item.HorseIDData.NameAr
                              : item.HorseModelIdData1.NameAr}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
          <div className="SummaryDistance col-sm-4">
            <p>{t("Class")}</p>
            {classtype && classtype.length == 0 ? (
              <>
                <SkeletonTheme baseColor="#00A6A7" highlightColor="#9DD9DB">
                  <p>
                    <Skeleton count={3} />
                  </p>
                </SkeletonTheme>
              </>
            ) : (
              extendedclasstype &&
              extendedclasstype.map((item, i) => {
                return (
                  <div>
                    <div className="row allsum" key={i}>
                      <div className="col-sm-2">
                        {item.HorseModelIdData1 &&
                        item.HorseModelIdData1.length === 0 ? (
                          <p>-</p>
                        ) : item.HorseIDData &&
                          item.HorseIDData.HorseModelIdData1 &&
                          item.HorseIDData.HorseModelIdData1[0] ===
                            undefined ? (
                          <p>-</p>
                        ) : (
                          <p>
                            {item.HorseIDData
                              ? item.HorseIDData.HorseModelIdData1[0].HorseNo
                              : item.HorseNo}
                          </p>
                        )}
                      </div>
                      <div className="col-sm-3">
                        <Image
                          data={
                            item.HorseIDData &&
                            item.HorseIDData.ActiveOwnerData &&
                            item.HorseIDData.ActiveOwnerData.OwnerIDData
                              ? ""
                              : ""
                          }
                        />
                      </div>
                      <div className="col-sm-7">
                        <p>
                          {cookiedata === "en"
                            ? toPascalCaseWithAcronym(
                                item.HorseIDData
                                  ? item.HorseIDData.NameEn
                                  : item.HorseModelIdData1.NameEn
                              )
                            : item.HorseIDData
                            ? item.HorseIDData.NameAr
                            : item.HorseModelIdData1.NameAr}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
          <div className="SummaryDistance col-sm-4">
            <p>{t("Time")}</p>

            {extendedtrainer && extendedtrainer?.length == 0 ? (
              <>
                <SkeletonTheme baseColor="#00A6A7" highlightColor="#9DD9DB">
                  <p>
                    <Skeleton count={3} />
                  </p>
                </SkeletonTheme>
              </>
            ) : (
              extendedtrainer &&
              extendedtrainer?.map((item, i) => {
                return (
                  <div>
                    <div className="row allsum" key={i}>
                      <div className="col-sm-2">
                        {item.HorseModelIdData1 &&
                        item.HorseModelIdData1.length === 0 ? (
                          <p>-</p>
                        ) : item.HorseIDData &&
                          item.HorseIDData.HorseModelIdData1 &&
                          item.HorseIDData.HorseModelIdData1[0] ===
                            undefined ? (
                          <p>-</p>
                        ) : (
                          <p>
                            {item.HorseIDData
                              ? item.HorseIDData.HorseModelIdData1[0].HorseNo
                              : item.HorseNo}
                          </p>
                        )}
                      </div>
                      <div className="col-sm-3">
                        <Image
                          data={
                            item.HorseIDData &&
                            item.HorseIDData.ActiveOwnerData &&
                            item.HorseIDData.ActiveOwnerData.OwnerIDData
                              ? ""
                              : ""
                          }
                        />
                      </div>
                      <div className="col-sm-7">
                        <p>
                          {cookiedata === "en"
                            ? toPascalCaseWithAcronym(
                                item.HorseIDData
                                  ? item.HorseIDData.NameEn
                                  : item.HorseModelIdData1.NameEn
                              )
                            : item.HorseIDData
                            ? item.HorseIDData.NameAr
                            : item.HorseModelIdData1.NameAr}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
          <div className="SummaryDistance col-sm-4">
            <p>{t("Pedigree")}</p>
            {extendedpedigree && extendedpedigree?.length == 0 ? (
              extendedpedigree === 0 ? (
                <>
                  <SkeletonTheme baseColor="#00A6A7" highlightColor="#9DD9DB">
                    <p>
                      <Skeleton count={3} />
                    </p>
                  </SkeletonTheme>
                </>
              ) : (
                <>
                  <SkeletonTheme baseColor="#00A6A7" highlightColor="#9DD9DB">
                    <p>
                      <Skeleton count={3} />
                    </p>
                  </SkeletonTheme>
                </>
              )
            ) : (
              extendedpedigree &&
              extendedpedigree?.map((item, i) => {
                return (
                  <div>
                    <div className="row allsum" key={i}>
                      <div className="col-sm-2">
                        {item.HorseModelIdData1 &&
                        item.HorseModelIdData1.length === 0 ? (
                          <p>-</p>
                        ) : item.HorseIDData &&
                          item.HorseIDData?.HorseModelIdData1 &&
                          item.HorseIDData?.HorseModelIdData1[0] ===
                            undefined ? (
                          <p>-</p>
                        ) : (
                          <p>
                            {item.HorseIDData
                              ? item?.HorseIDData?.HorseModelIdData1 && item?.HorseIDData?.HorseModelIdData1[0]?.HorseNo
                              : item?.HorseNo}
                          </p>
                        )}
                      </div>
                      <div className="col-sm-3">
                        <Image
                          data={
                            item.HorseIDData &&
                            item.HorseIDData?.ActiveOwnerData &&
                            item.HorseIDData?.ActiveOwnerData?.OwnerIDData
                              ? ""
                              : ""
                          }
                        />
                      </div>
                      <div className="col-sm-7">
                        <p>
                          {cookiedata === "en"
                            ? toPascalCaseWithAcronym(
                                item.HorseIDData
                                  ? item.HorseIDData?.NameEn
                                  : item.HorseModelIdData1?.NameEn
                              )
                            : item.HorseIDData
                            ? item.HorseIDData?.NameAr
                            : item.HorseModelIdData1?.NameAr}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
          <div className="SummaryDistance col-sm-4">
            <p>{t("Race Course")}</p>
            {extendedracecourse && extendedracecourse?.length == 0 ? (
              <>
                <SkeletonTheme baseColor="#00A6A7" highlightColor="#9DD9DB">
                  <p>
                    <Skeleton count={3} />
                  </p>
                </SkeletonTheme>
              </>
            ) : (
              extendedracecourse &&
              extendedracecourse?.map((item, i) => {
                return (
                  <div>
                    <div className="row allsum" key={i}>
                      <div className="col-sm-2">
                        {item.HorseModelIdData1 &&
                        item.HorseModelIdData1.length === 0 ? (
                          <p>-</p>
                        ) : item.HorseIDData &&
                          item.HorseIDData.HorseModelIdData1 &&
                          item.HorseIDData.HorseModelIdData1[0] ===
                            undefined ? (
                          <p>-</p>
                        ) : (
                          <p>
                            {item.HorseIDData
                              ? item.HorseIDData.HorseModelIdData1[0].HorseNo
                              : item.HorseNo}
                          </p>
                        )}
                      </div>
                      <div className="col-sm-3">
                        <Image
                          data={
                            item.HorseIDData &&
                            item.HorseIDData.ActiveOwnerData &&
                            item.HorseIDData.ActiveOwnerData.OwnerIDData
                              ? ""
                              : ""
                          }
                        />
                      </div>
                      <div className="col-sm-7">
                        <p>
                          {cookiedata === "en"
                            ? toPascalCaseWithAcronym(
                                item.HorseIDData
                                  ? item.HorseIDData.NameEn
                                  : item.HorseModelIdData1.NameEn
                              )
                            : item.HorseIDData
                            ? item.HorseIDData.NameAr
                            : item.HorseModelIdData1.NameAr}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
          <div className="SummaryDistance col-sm-4">
            <p>{t("Overall")}</p>
            {extendedoverall && extendedoverall?.length == 0 ? (
              <>
                <SkeletonTheme baseColor="#00A6A7" highlightColor="#9DD9DB">
                  <p>
                    <Skeleton count={3} />
                  </p>
                </SkeletonTheme>
              </>
            ) : (
              extendedoverall &&
              extendedoverall?.map((item, i) => {
                return (
                  <div>
                    <div className="row allsum" key={i}>
                      <div className="col-sm-2">
                        {item.HorseModelIdData1 &&
                        item.HorseModelIdData1.length === 0 ? (
                          <p>-</p>
                        ) : item.HorseIDData &&
                          item.HorseIDData.HorseModelIdData1 &&
                          item.HorseIDData.HorseModelIdData1[0] ===
                            undefined ? (
                          <p>-</p>
                        ) : (
                          <p>
                            {item.HorseIDData
                              ? item.HorseIDData.HorseModelIdData1[0].HorseNo
                              : item.HorseNo}
                          </p>
                        )}
                      </div>
                      <div className="col-sm-3">
                        <Image
                          data={
                            item.HorseIDData &&
                            item.HorseIDData.ActiveOwnerData &&
                            item.HorseIDData.ActiveOwnerData.OwnerIDData
                              ? ""
                              : ""
                          }
                        />
                      </div>
                      <div className="col-sm-7">
                        <p>
                          {cookiedata === "en"
                            ? toPascalCaseWithAcronym(
                                item.HorseIDData
                                  ? item.HorseIDData.NameEn
                                  : item.HorseModelIdData1.NameEn
                              )
                            : item.HorseIDData
                            ? item.HorseIDData.NameAr
                            : item.HorseModelIdData1.NameAr}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default Summary;
