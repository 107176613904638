import "../../CSS/HomeCSS/Competition.css";

import flag from "../../../assets/United Arab Emirates.png";
import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from "react-i18next";
import {
  fetchWinningList,
  STATUSES,
} from "../../../redux/getReducer/getWinner";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Animate from "../../../assets/loader.json";
import Lottie from "lottie-react";
import Cookies from "js-cookie";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
const WinningList = () => {
  const { t } = useTranslation();
  const { data: LeaderBoard, status } = useSelector((state) => state.Winner);
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(fetchWinningList());
  // }, [dispatch]);


  useEffect(() => {
    if (LeaderBoard?.length !== 0) {
      console.log(LeaderBoard,'sldr')
    }
    else{
      dispatch(fetchWinningList());
    }
  }, [dispatch]);


  const cookiedata = Cookies.get("i18next");
  if (status === STATUSES.LOADING) {
    return (
      <div>
        <Lottie
          animationData={Animate}
          loop={true}
          className="Lottie compLottie"
        />
      </div>
    );
  }

  if (status === STATUSES.ERROR) {
    return (
      <h2
        style={{
          margin: "100px",
        }}
      >
        Something went wrong!
      </h2>
    );
  }
  return (
    <React.Suspense>
      <div className="Competition">
        <div className="CompetitionHeader">
          <h2>{t("WinnerList")}</h2>
          <img src={flag} alt="" />
        </div>
        <div className="LeaderData">
          {LeaderBoard?.length === 0 ? (
           
           <SkeletonTheme baseColor="#00A6A7" highlightColor="#9DD9DB">
    <p>
      <Skeleton count={3} />
    </p>
  </SkeletonTheme>
           ) : (
            LeaderBoard.map((item, index) => {
              return item.CloseCompetitionDataOfRace &&
                item.CloseCompetitionDataOfRace.length === 0 ? (
                <h4 className="noFoundHeader">No Results Found</h4>
              ) : (
                <>
                  <Accordion defaultActiveKey="1">
                    <div className="Competitionitem">
                      <Accordion.Item eventKey={index}>
                        <Accordion.Header className="AccordionHeader11">
                          <div className="AccordionHeader">
                            <p style={{color:"white"}}>
                              {cookiedata === "en" ? item.NameEn : item.NameAr}
                            </p>
                            <p></p>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          {item.CloseCompetitionDataOfRace ? (
                            item.CloseCompetitionDataOfRace.map((data) => (
                              <div className="Competition_Matches">
                                <p>
                                  {data.CloseCompetitionSubscriberIdDataOfRace ? (
                                    data.CloseCompetitionSubscriberIdDataOfRace
                                      .FirstName
                                  ) : (
                                    <></>
                                  )}
                                </p>
                                <p>{data.CloseCompetitionTotalPoint}</p>
                              </div>
                            ))
                          ) : (
                            <></>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    </div>
                  </Accordion>
                </>
              );
            })
          ) }
        </div>
      </div>
    </React.Suspense>
  );
};
export default WinningList;
