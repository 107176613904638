import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getResultRaceCourseSlice = createSlice({
    name: 'ResultRaceCourse',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchResultRaceCourse.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchResultRaceCourse.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchResultRaceCourse.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setResultRaceCourse, setStatus } = getResultRaceCourseSlice.actions;
export default getResultRaceCourseSlice.reducer;

export const fetchResultRaceCourse = createAsyncThunk('/ResultRaceCourse/fetch', async ({Value3, data1,data2,data3,data4,data5,data6,data7,data8,data10,data11,data12,data13,data14,IncreasePage}) => {
    const res = await axios.get(`${window.env.API_URL}/ResultRaceCourse?TrackNameEn=${Value3}&size=${IncreasePage}&StartTimeOfRace=${data1}&HorseID=${data2}&TrainerOnRace=${data3}&JockeyOnRace=${data4}&RaceCourseOfRace=${data5}&RaceCourseNationality=${data6}&RaceTypeOfRace=${data7}&RaceGround=${data8}&OwnerId=${data10}&MeetingType=${data11}&Sponsor=${data12}&HorseKindinRace=${data13}&date=${data14}`);
    const ResultRaceCourseData = res.data;
    return ResultRaceCourseData.data;
})
