import "../Components/CSS/pagesCSS/about.css";
import React from "react";
import axios from "axios";
import "../Components/CSS/pagesCSS/horse.css";
import ListGroup from "react-bootstrap/ListGroup";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { BsFillCaretRightFill } from "react-icons/bs";
import "../Components/CSS/pagesCSS/owner.css";
import Cookies from "js-cookie";
import Moment from "react-moment";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import Defaultimg from "../assets/default.png"
import Stats from "../Components/Tabs/Stats";
import { useEffect } from "react";
import PleaseWait from "../Components/Reuseable/PleaseWait";
import arrow1 from "../assets/image 13 (Traced).png";

import { useDispatch, useSelector } from "react-redux";
import { fetchTrainerHistory } from "../redux/getReducer/getTrainerHistory";
import { fetchSingletrainer } from "../redux/getReducer/getSingleTrainer";


import { useTranslation } from 'react-i18next';
const TrainerDetail = (data) => {

  const { t } = useTranslation()
  const navigate = useNavigate();
  let trainerid = data.data._id;
  const { data: TrainerHistory } = useSelector((state) => state.TrainerHistory);
  const { data: singletrainer } = useSelector((state) => state.singletrainer);
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(fetchTrainerHistory({ trainerid }));
    dispatch(fetchSingletrainer({ trainerid }));
  }, [dispatch, trainerid]);

  const btnNew1 = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "13px 10px",
    gap: "10px",
    width: "112px",
    height: "24px",
    background: "#FF0000",
    borderRadius: "10px",
    border: "none",
    color: "#fff",
    margin: '6px'
  };
  const cookiedata = Cookies.get('i18next');
  const token = Cookies.get("token");

  const handleTrack = async (Id) => {
    try {
      const res = await axios.post(
        `${window.env.API_URL}/tracktrainer`, { Trainer: Id },  {
          headers: { Authorization: token, "x-xsrf-token": token },
        },
      );
      toast('Tracked Success')
      navigate('/tracker')
    } catch (error) {
      const err = error.response.data.message;
      toast(err)
    }
  };
  function calculatePercentage(x, y) {
    if (x === 0 && y === 0)
      return 0
    else {
      return (x / y) * 100;
    }
  }
  return (
    <>
      <div className="aboutpage">
        <div className="RaceDetailCard">
          <div className="horseheader1">
            <div className="horseshirt">
              <img src={singletrainer.image ? singletrainer.image : Defaultimg} alt="" className="horseshirtimage" style={{
                height: '90px'
              }} />

            </div>

            <div className="horsecenterdata">
              <span
                style={{
                  display: "flex",
                }}
              >
                <p
                  style={{
                    fontWeight: "700",
                    fontSize: " 19.6px",
                    lineHeight: "24px",
                    color: "#19469D",
                    marginTop:'25px'
                  }}
                >
                  {cookiedata === "en" ? (singletrainer.NameEn ? singletrainer.NameEn : "-") : (singletrainer.NameAr ? singletrainer.NameAr : "-")}
                </p>
                {/* <p
                  style={{
                    fontSize: "12px",
                    marginLeft: '20px'
                  }}
                >
                  {data.data.DOB === null ? <>No Data</> : <><Moment fromNow ago>
                    {data.data.DOB}
                  </Moment></>}

                </p> */}
              </span>
              {/* <span
                style={{
                  display: "flex",
                }}
              >
                <p
                  style={{
                    fontSize: "12px",
                    paddingLeft: "10px"
                  }}
                > {t("Title")}
                  <b style={{
                    paddingLeft: "5px"
                  }}> {cookiedata === "en" ? (data.data.TitleEn ? data.data.TitleEn : <>- </>) : (data.data.TitleAr ? data.data.TitleAr : <>- </>)}</b>
                </p>
                <p
                  style={{
                    fontSize: "10px",
                    paddingLeft: "6px"
                  }}
                >{t("Regd")}
                  <b style={{
                    paddingLeft: "5px"
                  }}> {data.data.RegistrationDate === null ? <>No Data</> : <><Moment fromNow ago>
                    {data.data.RegistrationDate}
                  </Moment></>}</b>
                </p>

              </span> */}
              {/* <div className="horsedetailimage">
                {
                  data.data.TrainerNationalityData ?
                    <img src={data.data.TrainerNationalityData.image ? data.data.TrainerNationalityData.image : Defaultimg} alt="" />

                    : <>- </>
                }
                <span>
                  <p
                    style={{
                      fontSize: "12px",
                      lineHeight: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    <b style={{
                      padding: "10px",

                    }}>{t("Nationality")}</b>{cookiedata === "en" ? (data.data.TrainerNationalityData ? data.data.TrainerNationalityData.NameEn : <>- </>) : (data.data.TrainerNationalityData ? data.data.TrainerNationalityData.NameAr : <>- </>)}
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      marginLeft: "10px",
                    }}
                  >
                    <b style={{
                      padding: "10px",
                    }}>{t("Short Name")} </b>{cookiedata === "en" ? (data.data.ShortNameEn ? data.data.ShortNameEn : <>- </>) : (data.data.ShortNameAr ? data.data.ShortNameAr : <>- </>)}
                  </p>
                </span>

              </div> */}
              {/* <span className="detailsForm">
                <p>{cookiedata === "en" ? (data.data.DetailEn ? data.data.DetailEn : <>- </>) : (data.data.DetailAr ? data.data.DetailAr : <>- </>)}</p>
              </span> */}
            </div>
            {/* <div className="horseimage">
      <img src={data.data.HorseImage} alt='' />
    </div> */}
            <div className="ownerTrack">

              <button
                style={btnNew1}
                onClick={() => handleTrack(singletrainer._id)}
              >
                {t("Track")}
              </button>
            </div>
          </div>
          <div className="horsebody">
        {/* <div className="horsebodyitem">
          <p
            style={{
              color: "#fff",
              textAlign: "center",
            }}
          >
            National Hunt Form
          </p>

        </div>
        <div className="horsehistorylist">
          <p
            style={{
              color: "#fff",
              padding: "10px",
            }}
          >
            00-00
          </p>
        </div> */}
        <div className="mycardclass1">
          <div className="BodyNew">
            <table className="customers horseModalTable">
              <thead className="customers5 ">
                <tr style={{
                  background: '#A5A5A5',

                }}>
                  <th></th>
                  <th>Runs</th>
                  <th>Wins</th>
                  <th>%</th>
                  <th>Placed</th>
                  {/* <th>Winnings</th> */}
                  <th>Earnings</th>
                  {/* <th>OR </th> */}
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="customers1">



                <>




                  <tr className="BodyNew1 " >
                    <td className="lb">
                      FLAT TURF
                    </td>
                    <td> {singletrainer.TurfCount == null? <>0</>: singletrainer.TurfCount}</td>
                    <td>
                     {singletrainer.TurfWins  == null ? <>0</>: singletrainer.TurfWins}
                    </td>
                    <td>
                    {calculatePercentage((singletrainer.TurfCount + singletrainer.TurfWins), singletrainer.TurfCount)}%

                    </td>
                    <td>
                    {singletrainer.TurfPlacing  == null ? <>0</>: singletrainer.TurfPlacing}

                    </td>
                    {/* <td>
                      -
                    </td> */}
                    <td>
                    {singletrainer.TurfEarnings  == null ? <>0</>: singletrainer.TurfEarnings}

                    </td>
                    {/* <td
                      className="rb"
                    >

                    </td> */}
                  </tr>
                  <tr className="BodyNew1 " >
                    <td className="lb">
                      DIRT
                    </td>
                    <td> {singletrainer.DirtCount == null? <>0</>: singletrainer.DirtCount}</td>
                    <td>
                    {singletrainer.DirtWins == null? <>0</>: singletrainer.DirtWins}
                    </td>
                    <td>
                    {calculatePercentage((singletrainer.DirtCount + singletrainer.DirtWins), singletrainer.DirtCount)}%

                    </td>
                    <td>
                    {singletrainer.DirtPlacing  == null ? <>0</>: singletrainer.DirtPlacing}

                    </td>
                    <td>
                    {singletrainer.DirtEarnings  == null ? <>0</>: singletrainer.DirtEarnings}

                    </td>
                    {/* <td>
                   -
                    </td>  */}
                         </tr>

                  <tr className="BodyNew1 historyhorseth" >
                    <td className="lb">
                      Total
                    </td>
                    <td>{singletrainer.TotalCount == null? <>0</>: singletrainer.TotalCount}</td>
                    <td>
                    {singletrainer.TotalWins == null? <>0</>: singletrainer.TotalWins}
                    </td>
                    <td>
                    {calculatePercentage((singletrainer.TotalCount + singletrainer.TotalWins), singletrainer.TotalCount)}%


                    </td>
                    <td>
                    {singletrainer.TotalPlacing == null? <>0</>: singletrainer.TotalPlacing}

                    </td>
                    {/* <td>
                      -

                    </td> */}
                    <td>
                    {singletrainer.TotalEarnings  == null ? <>0</>: singletrainer.TotalEarnings}

                    </td>
                    {/* <td
                      className="rb"
                    >

                    </td> */}
                  </tr>





                </>

              </tbody>
            </table>
          </div>


        </div>
      </div>
          <div className="RaceNav HorseNav">
            <Tabs id="uncontrolled-tab-example" className="mb-3 RaceNavItem">
              <Tab eventKey="Form" title={t("Form")} tabClassName="profile-tabitem">
                <div className="RaceDetailCard">
                <div className="mycardclass1">
                                <div className="BodyNew">
                                  <table className="customers">
                                    <thead className="customers5">
                                      <tr>
                                        <th>{t("Date")}</th>
                                        <th>{t("Cr")}</th>
                                        {/* <th>{t("Dist")}</th> */}
                                        {/* <th>{t("TC")}</th> */}

                                        {/* <th>{t("Dts")}</th> */}
                                        <th>{t("Jockey")}</th>
                                        <th>{t("Horse")}</th>
                                        <th>{t("FP")}</th>
                                        {/* <th>{t("Les")}</th> */}
                                        {/* <th>{t("RS")}</th> */}
                                        <th>{t("Runner")}</th>

                                        <th>{t("Draw")}</th>
                                      </tr>
                                    </thead>
                                    <tbody className="customers1">
                                    {TrainerHistory === undefined ? (
                                        <PleaseWait />
                                      ) : (
                                        <>
                                          {!TrainerHistory ? (
                                            <PleaseWait />
                                          ) : (
                                            TrainerHistory && TrainerHistory.map((item) => {
                                              return (
                                                <tr className="BodyNew1 historyhorseth" style={{
                                                  background: '#5ec40f'
                                                }}>
                                                  <td className="lb">
                                                    <Moment format="YYYY/MM/DD">
                                                      {item.RaceResultData ===
                                                        null ? (
                                                        <></>
                                                      ) : (
                                                        item.RaceResultData.StartTime
                                                      )}
                                                    </Moment>
                                                  </td>
                                                  <td>
                                                    {
                                                      cookiedata === "en" ? (item.RaceResultData ? item.RaceResultData.RaceCourseData.TrackNameEn :<>-</>) : (item.RaceResultData ? item.RaceResultData.RaceCourseData.TrackNameAr : <>-</>)
                                                    }
                                                  </td>
                                                 
                                                  {/* <td>
                                                    {
                                                      cookiedata === "en" ? <>{
                                                        item.RaceResultData.RaceCourseData.TrackNameEn
                                                      }</> : <>
                                                        {
                                                          item.RaceResultData.RaceCourseData.TrackNameAr
                                                        }
                                                      </>
                                                    }
                                                  </td> */}
                                                
                                                  <td>
                                                    {
                                                      cookiedata === "en" ? <>{
                                                        item.JockeyOnRaceDataResult.NameEn
                                                      }</> : <>
                                                        {
                                                          item.JockeyOnRaceDataResult.NameAr
                                                        }
                                                      </>
                                                    }
                                                  </td>

                                                  <td>
                                                    {
                                                      cookiedata === "en" ? <>{
                                                        item.HorseIDData.NameEn
                                                      }</> : <>
                                                        {
                                                          item.HorseIDData.NameAr
                                                        }
                                                      </>
                                                    }
                                                  </td>

                                                  <td>
                                                    {item.FinalPositionDataHorse ===
                                                      null ? (
                                                      <></>
                                                    ) : (
                                                      <>
                                                        {cookiedata === "en" ? (
                                                          <>
                                                            {cookiedata === "en"
                                                              ? item.FinalPositionDataHorse &&
                                                              item
                                                                .FinalPositionDataHorse
                                                                .NameEn
                                                              : item.FinalPositionDataHorse &&
                                                              item
                                                                .FinalPositionDataHorse
                                                                .NameAr}
                                                          </>
                                                        ) : (
                                                          <>
                                                            {
                                                              item
                                                                .FinalPositionDataHorse
                                                                .NameAr
                                                            }
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </td>
                                                  {/* <td>
                                                    {
                                                      item.CumulativeDistance
                                                    }
                                                  </td> */}
                                                 
                                                  <td>
                                                  {
                                                      cookiedata === "en" ? (item.RaceResultData ? item.RaceResultData.Runners :<>-</>) : (item.RaceResultData ? item.RaceResultData.Runners : <>-</>)
                                                    }
                                                  </td>
                                                  {/* <td>6</td> */}
                                                  {/* <td>16.25</td> */}
                                                 
                                                  <td
                                                    className="rb"
                                                    // onClick={() =>
                                                    //   handleShows(item.VideoLink)
                                                    // }
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <img src={arrow1} alt="" />
                                                  </td>
                                                </tr>
                                              );
                                            })
                                          )}
                                        </>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                </div>
              </Tab>
              <Tab
                eventKey="Entries"
                title={t("Entries")}
                tabClassName="profile-tabitem"
              >
                  <div className="RaceDetailCard">
                <div className="mycardclass1">
                                <div className="BodyNew">
                                  <table className="customers">
                                    <thead className="customers5">
                                      <tr>
                                        <th>{t("Date")}</th>
                                        <th>{t("Cr")}</th>
                                        {/* <th>{t("Dist")}</th> */}
                                        {/* <th>{t("TC")}</th> */}

                                        {/* <th>{t("Dts")}</th> */}
                                        <th>{t("Jockey")}</th>
                                        <th>{t("Horse")}</th>
                                        <th>{t("FP")}</th>
                                        {/* <th>{t("Les")}</th> */}
                                        {/* <th>{t("RS")}</th> */}
                                        <th>{t("Runner")}</th>

                                        <th>{t("Draw")}</th>
                                      </tr>
                                    </thead>
                                    <tbody className="customers1">
                                    {TrainerHistory === undefined ? (
                                        <PleaseWait />
                                      ) : (
                                        <>
                                          {!TrainerHistory ? (
                                            <PleaseWait />
                                          ) : (
                                            TrainerHistory && TrainerHistory.map((item) => {
                                              return (
                                                <tr className="BodyNew1 historyhorseth" style={{
                                                  background: '#5ec40f'
                                                }}>
                                                  <td className="lb">
                                                    <Moment format="YYYY/MM/DD">
                                                      {item.RaceResultData ===
                                                        null ? (
                                                        <></>
                                                      ) : (
                                                        item.RaceResultData.StartTime
                                                      )}
                                                    </Moment>
                                                  </td>
                                                  <td>
                                                    {
                                                      cookiedata === "en" ? (item.RaceResultData ? item.RaceResultData.RaceCourseData.TrackNameEn :<>-</>) : (item.RaceResultData ? item.RaceResultData.RaceCourseData.TrackNameAr : <>-</>)
                                                    }
                                                  </td>
                                                 
                                                  {/* <td>
                                                    {
                                                      cookiedata === "en" ? <>{
                                                        item.RaceResultData.RaceCourseData.TrackNameEn
                                                      }</> : <>
                                                        {
                                                          item.RaceResultData.RaceCourseData.TrackNameAr
                                                        }
                                                      </>
                                                    }
                                                  </td> */}
                                                
                                                  <td>
                                                    {
                                                      cookiedata === "en" ? <>{
                                                        item.JockeyOnRaceDataResult.NameEn
                                                      }</> : <>
                                                        {
                                                          item.JockeyOnRaceDataResult.NameAr
                                                        }
                                                      </>
                                                    }
                                                  </td>

                                                  <td>
                                                    {
                                                      cookiedata === "en" ? <>{
                                                        item.HorseIDData.NameEn
                                                      }</> : <>
                                                        {
                                                          item.HorseIDData.NameAr
                                                        }
                                                      </>
                                                    }
                                                  </td>

                                                  <td>
                                                    {item.FinalPositionDataHorse ===
                                                      null ? (
                                                      <></>
                                                    ) : (
                                                      <>
                                                        {cookiedata === "en" ? (
                                                          <>
                                                            {cookiedata === "en"
                                                              ? item.FinalPositionDataHorse &&
                                                              item
                                                                .FinalPositionDataHorse
                                                                .NameEn
                                                              : item.FinalPositionDataHorse &&
                                                              item
                                                                .FinalPositionDataHorse
                                                                .NameAr}
                                                          </>
                                                        ) : (
                                                          <>
                                                            {
                                                              item
                                                                .FinalPositionDataHorse
                                                                .NameAr
                                                            }
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </td>
                                                  {/* <td>
                                                    {
                                                      item.CumulativeDistance
                                                    }
                                                  </td> */}
                                                 
                                                  <td>
                                                  {
                                                      cookiedata === "en" ? (item.RaceResultData ? item.RaceResultData.Runners :<>-</>) : (item.RaceResultData ? item.RaceResultData.Runners : <>-</>)
                                                    }
                                                  </td>
                                                  {/* <td>6</td> */}
                                                  {/* <td>16.25</td> */}
                                                 
                                                  <td
                                                    className="rb"
                                                    // onClick={() =>
                                                    //   handleShows(item.VideoLink)
                                                    // }
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <img src={arrow1} alt="" />
                                                  </td>
                                                </tr>
                                              );
                                            })
                                          )}
                                        </>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                </div>
              </Tab>
              <Tab eventKey="Stats" title={t("Stats")} tabClassName="profile-tabitem">
                <div className="RaceDetailCard">
                  <Stats />
                </div>
              </Tab>

            </Tabs>
          </div>
        </div>
      </div >
    </>
  );
};
export default TrainerDetail;
