import React, { useEffect, useState } from "react";
import "../../Components/CSS/RaceCardCSS/racedetail.css";
import Defaultimg from "../../assets/Frame.png";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchsinglerace,
  STATUSES,
} from "../../redux/getReducer/getSingleRace";
import Accordion from "react-bootstrap/Accordion";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import arrow1 from "../../assets/image 13 (Traced).png";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import axios from "axios";
import { Lottie } from "lottie-react";
import Animate from "../../assets/loader.json";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import { BsFillFilterCircleFill } from "react-icons/bs";
import { AiFillCaretDown } from "react-icons/ai";
import { IoCaretUpSharp } from "react-icons/io5";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import HorseDetail from "../Modal/HorseModal";
import JockeyDetail from "../Modal/JockeyModal";
import OwnerDetail from "../Modal/OwnerModal";
import TrainerDetail from "../Modal/TrainerModal";
import RaceModal from "../Modal/RaceModal";
import { Modal } from "react-bootstrap";
import PleaseWait from "../Reuseable/PleaseWait";
import NoHistory from "../Reuseable/NoHistory";
import VideoFrame from "../Modal/VideoFrame";
import { FcDown,FcUp } from 'react-icons/fc';

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
  console.log("")
  );

  return (
    <button
      type="button"
      className="ShowPreviousHistory"
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}
const CardData = ({id}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: singlerace, status } = useSelector((state) => state.singlerace);
  const [Disable, setDisable] = useState(false);
  const [History, setHistory] = useState([]);
  const [historyData, showHistoryData] = useState(false);

  const [show, setShow] = useState(false);
  const [modaldata, setmodaldata] = useState();
  const handleClose = () => setShow(false);
  const handleShow = async (data) => {
    setmodaldata(data);

    await setShow(true);
  };

  const [showJockey, setShowJockey] = useState(false);
  const [modaldataJockey, setmodaldataJockey] = useState();
  const handleCloseJockey = () => setShowJockey(false);
  const handleShowJockey = async (data) => {
    setmodaldataJockey(data);
    await setShowJockey(true);
  };

  const [showOwner, setShowOwner] = useState(false);
  const [modaldataOwner, setmodaldataOwner] = useState();
  const handleCloseOwner = () => setShowOwner(false);
  const handleShowOwner = async (data) => {
    setmodaldataOwner(data);
    await setShowOwner(true);
  };

  const [showTrainer, setShowTrainer] = useState(false);
  const [modaldataTrainer, setmodaldataTrainer] = useState();
  const handleCloseTrainer = () => setShowTrainer(false);
  const handleShowTrainer = async (data) => {
    setmodaldataTrainer(data);
    await setShowTrainer(true);
  };

  const [showRace, setShowRace] = useState(false);
  const [modaldataRace, setmodaldataRace] = useState();
  const handleCloseRace = () => setShowRace(false);
  const handleShowRace = async (id) => {
    setmodaldataRace(id);
    await setShowRace(true);
  };
  const [shows, setShows] = useState(false);
  const [modaldatas, setmodaldatas] = useState();
  const handleCloses = () => setShows(false);
  const handleShows = async (data) => {
    setmodaldatas(data);
    await setShows(true);
  };

  const [PositionNumber, setPositionNumber] = useState("1");
  const [SortedData, setSortData] = useState([]);

  const [showfilter, setshowfilter] = useState(false);



  useEffect(() => {
    // dispatch(fetchsinglerace({ id }));
    setSortData([]);
  }, [dispatch, id]);

  const datafilter = singlerace.racehorses.RacehorsesData
    ? [...singlerace.racehorses.RacehorsesData]
    : [];

  if (status === STATUSES.LOADING) {
    <div className="py-4 text-center">
      <div>Loading ...</div>
    </div>;
  }

  if (status === STATUSES.ERROR) {
    return (
      <h2
        style={{
          margin: "100px",
          width: "50%",
        }}
      >
        Something went wrong!
      </h2>
    );
  }
  // Track




  const myPara = {
    fontWeight: "400",
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.5)",
    paddingLeft: "3px",
  };

  const btnNew1 = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
    gap: "10px",
    width: "112px",

    height: "24px",
    background: "#FF0000",
    borderRadius: "10px",
    border: "none",
    color: "#fff",
  };

  const btnNewdelete = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
    gap: "10px",
    width: "112px",
    marginTop:"10px",
    height: "24px",
    background: "#19469D",
    borderRadius: "10px",
    border: "none",
    color: "#fff",
  };

  const cookiedata = Cookies.get("i18next");
  const token = Cookies.get("token");


  const pickClick = async (event, compid, horseid) => {
    event.preventDefault();
    const api = `${window.env.API_URL}/voting/${compid}/${id}/${PositionNumber}`;
    try {
      const response = await axios.post(
        api,
        { Horse: horseid },
        {
          headers: { Authorization: token, "x-xsrf-token": token },
        }
      );
      const msgdata = response.data.message;
      dispatch(fetchsinglerace({ id }));
      toast.success(msgdata, { autoClose: 2000 });
    } catch (error) {
      const err = error.response.data.message;
      if (err === "Json Web Token is invalid")
        return (
          toast.error("Please Login to Access This Resource", {
            autoClose: 2000,
          }),
          navigate("/login")
        );
      setDisable(false);
      toast.error(err, { autoClose: 2000 });
      setDisable(false);
    }
  };

  const DeletePick = async (event, deleteid, horseid) => {
    event.preventDefault();
    //     awaitaxios( `${window.env.API_URL}/voting/${compid}/${id}/${PositionNumber}`, {
    //   method: "post",
    //   data: someJsonData,
    //   withCredentials: true
    // })
    //
    const api = `${window.env.API_URL}/deletevoting/${deleteid}`;
    try {
      const response = await axios.delete(
        api,
        // { Horse: horseid },
        {
          headers: { Authorization: token, "x-xsrf-token": token },
        }
      );

      const msgdata = response.data.message;
      // toast.error(error, { autoClose: 2000 })
      toast.success(msgdata, { autoClose: 2000 });
      dispatch(fetchsinglerace({ id }));
    } catch (error) {
      const err = error.response.data.message;
      toast.error(err, { autoClose: 2000 });
      setDisable(false);
    }
  };
  let menuItems = [];
  for (var i = 0; i < 4; i++) {
    menuItems.push(
      <form>
        <input type="radio" name="contact" />
      </form>
    );
  }

  const runCallback = (cb) => {
    return cb();
  };
  const showHorseHistory = async (horseid) => {
    setHistory();
    showHistoryData(false);
    const res = await axios.get(
      `${window.env.API_URL}/horsehistory/${horseid}/${id}`
    );

    await setHistory(res.data.data);
    showHistoryData(true);
  };

  const DataDate = new Date(singlerace.StartTime);

  function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }
  const formatedData = formatAMPM(DataDate);

  const filtercard = () => {
    setshowfilter(!showfilter);
  };

  const GateDsc = () => {
    let sortedProducts = datafilter.sort((p1, p2) =>
      p1.GateNo < p2.GateNo ? 1 : p1.GateNo > p2.GateNo ? -1 : 0
    );

    setSortData(sortedProducts);
  };

  const GateAsc = () => {
    let sortedProducts = datafilter.sort((p1, p2) =>
      p1.GateNo > p2.GateNo ? 1 : p1.GateNo < p2.GateNo ? -1 : 0
    );

    setSortData(sortedProducts);
  };

  const OwnerDesc = () => {
    let sortedProducts = datafilter.sort((p1, p2) =>
      p1.HorseNo < p2.HorseNo ? 1 : p1.HorseNo > p2.HorseNo ? -1 : 0
    );

    setSortData(sortedProducts);
  };

  const HorseAsc = () => {
    let sortedProducts = datafilter.sort((p1, p2) =>
      p1.HorseNo > p2.HorseNo ? 1 : p1.HorseNo < p2.HorseNo ? -1 : 0
    );

    setSortData(sortedProducts);
  };

  const HorseDsc = () => {
    let sortedProducts = datafilter.sort((p1, p2) =>
      p1.HorseNo < p2.HorseNo ? 1 : p1.HorseNo > p2.HorseNo ? -1 : 0
    );

    setSortData(sortedProducts);
  };
  const RatingDsc = () => {
    let sortedProducts = datafilter.sort((p1, p2) =>
      p1.Rating < p2.Rating ? 1 : p1.Rating > p2.Rating ? -1 : 0
    );

    setSortData(sortedProducts);
  };
  const RatingAsc = () => {
    let sortedProducts = datafilter.sort((p1, p2) =>
      p1.Rating > p2.Rating ? 1 : p1.Rating < p2.Rating ? -1 : 0
    );

    setSortData(sortedProducts);
  };

  const JockeyWeightDsc = () => {
    let sortedProducts = datafilter.sort((p1, p2) =>
      p1.JockeyRaceWeight < p2.JockeyRaceWeight
        ? 1
        : p1.JockeyRaceWeight > p2.JockeyRaceWeight
        ? -1
        : 0
    );

    setSortData(sortedProducts);
  };
  const RaceRankDsc = () => {
    let sortedProducts = datafilter.sort((p1, p2) =>
      p1.HorseModelIdData1.HorseIDData1[0].FinalPositionDataHorse.Rank <
      p2.HorseModelIdData1.HorseIDData1[0].FinalPositionDataHorse.Rank
        ? 1
        : p1.HorseModelIdData1.HorseIDData1[0].FinalPositionDataHorse.Rank >
          p2.HorseModelIdData1.HorseIDData1[0].FinalPositionDataHorse.Rank
        ? -1
        : 0
    );

    setSortData(sortedProducts);
  };

  const RaceRankAsc = () => {
    let sortedProducts = datafilter.sort((p1, p2) =>
      p1.HorseModelIdData1.HorseIDData1[0].FinalPositionDataHorse.Rank >
      p2.HorseModelIdData1.HorseIDData1[0].FinalPositionDataHorse.Rank
        ? 1
        : p1.HorseModelIdData1.HorseIDData1[0].FinalPositionDataHorse.Rank <
          p2.HorseModelIdData1.HorseIDData1[0].FinalPositionDataHorse.Rank
        ? -1
        : 0
    );

    setSortData(sortedProducts);
  };

  const JockeyWeightAsc = () => {
    let sortedProducts = datafilter.sort((p1, p2) =>
      p1.JockeyRaceWeight > p2.JockeyRaceWeight
        ? 1
        : p1.JockeyRaceWeight < p2.JockeyRaceWeight
        ? -1
        : 0
    );

    setSortData(sortedProducts);
  };
  const sort_by = (field, reverse, primer) => {
    const key = primer
      ? function (x) {
          return primer(x[field]);
        }
      : function (x) {
          return x[field];
        };

    reverse = !reverse ? 1 : -1;

    return function (a, b) {
      return (a = key(a)), (b = key(b)), reverse * ((a > b) - (b > a));
    };
  };
  function compareNameDsc(a, b) {
    // converting to uppercase to have case-insensitive comparison
    const name1 = a.HorseModelIdData1.NameEn.toUpperCase();
    const name2 = b.HorseModelIdData1.NameEn.toUpperCase();

    let comparison = 0;

    if (name1 > name2) {
      comparison = 1;
    } else if (name1 < name2) {
      comparison = -1;
    }
    return comparison;
  }
  function compareNameASC(a, b) {
    // converting to uppercase to have case-insensitive comparison
    const name1 = a.HorseModelIdData1.NameEn.toUpperCase();
    const name2 = b.HorseModelIdData1.NameEn.toUpperCase();

    let comparison = 0;

    if (name1 > name2) {
      comparison = -1;
    } else if (name1 < name2) {
      comparison = 1;
    }
    return comparison;
  }
  const HorseNameDsc = () => {

    let sortedProducts = datafilter.sort(compareNameDsc);

    setSortData(sortedProducts);
  };
  const HorseNameAsc = () => {

    let sortedProducts = datafilter.sort(compareNameASC);

    setSortData(sortedProducts);
  };
  function TrainerNameDsc(a, b) {
    // converting to uppercase to have case-insensitive comparison
    const name1 = a.TrainerOnRaceData1.NameEn.toUpperCase();
    const name2 = b.TrainerOnRaceData1.NameEn.toUpperCase();

    let comparison = 0;

    if (name1 > name2) {
      comparison = 1;
    } else if (name1 < name2) {
      comparison = -1;
    }
    return comparison;
  }
  function TrainerNameASC(a, b) {
    // converting to uppercase to have case-insensitive comparison
    const name1 = a.TrainerOnRaceData1.NameEn.toUpperCase();
    const name2 = b.TrainerOnRaceData1.NameEn.toUpperCase();

    let comparison = 0;

    if (name1 > name2) {
      comparison = -1;
    } else if (name1 < name2) {
      comparison = 1;
    }
    return comparison;
  }

  function OwnerNameDsc(a, b) {
    // converting to uppercase to have case-insensitive comparison
    const name1 = a.OwnerOnRaceData1.NameEn.toUpperCase();
    const name2 = b.OwnerOnRaceData1.NameEn.toUpperCase();

    let comparison = 0;

    if (name1 > name2) {
      comparison = 1;
    } else if (name1 < name2) {
      comparison = -1;
    }
    return comparison;
  }
  function OwnerNameASC(a, b) {
    // converting to uppercase to have case-insensitive comparison
    const name1 = a.OwnerOnRaceData1.NameEn.toUpperCase();
    const name2 = b.OwnerOnRaceData1.NameEn.toUpperCase();

    let comparison = 0;

    if (name1 > name2) {
      comparison = -1;
    } else if (name1 < name2) {
      comparison = 1;
    }
    return comparison;
  }

  const OwnerDsc = () => {

    let sortedProducts = datafilter.sort(OwnerNameDsc);

    setSortData(sortedProducts);
  };

  const OwnerAsc = () => {

    let sortedProducts = datafilter.sort(OwnerNameASC);

    setSortData(sortedProducts);
  };

  const TrainerDsc = () => {

    let sortedProducts = datafilter.sort(TrainerNameDsc);

    setSortData(sortedProducts);
  };
  const TrainerAsc = () => {
   

    let sortedProducts = datafilter.sort(TrainerNameASC);

    setSortData(sortedProducts);
  };

  const JockeyDsc = () => {


    let sortedProducts = datafilter.sort(JockeyNameDsc);

    setSortData(sortedProducts);
  };

  const JockeyAsc = () => {
 

    let sortedProducts = datafilter.sort(JockeyNameASC);

    setSortData(sortedProducts);
  };
  function JockeyNameDsc(a, b) {
    // converting to uppercase to have case-insensitive comparison
    const name1 = a.JockeyOnRaceData1.NameEn.toUpperCase();
    const name2 = b.JockeyOnRaceData1.NameEn.toUpperCase();

    let comparison = 0;

    if (name1 > name2) {
      comparison = 1;
    } else if (name1 < name2) {
      comparison = -1;
    }
    return comparison;
  }
  function JockeyNameASC(a, b) {
    // converting to uppercase to have case-insensitive comparison
    const name1 = a.JockeyOnRaceData1.NameEn.toUpperCase();
    const name2 = b.JockeyOnRaceData1.NameEn.toUpperCase();

    let comparison = 0;

    if (name1 > name2) {
      comparison = -1;
    } else if (name1 < name2) {
      comparison = 1;
    }
    return comparison;
  }


  function getColor(param1, param2, param3) {
    if (param1 > 0) {
      return "#FFB800";
    } else if (param2 > 0) {
      return "#078BE6";
    } else if (param3 > 0) {
      return "#979695";
    } else {
      return "#fff";
    }
  }
  function compareNumbers(a, b) {
    let data1 = a == null ? -1 : a;
    let data2 = b == null ? -1 : b;

    if (data1 > data2) {
      return true;
    } else if (data1 < data2) {
      return false;
    } else {
      return 0;
    }
  }


  const FirstLetter = (data) => {
    return data.substring(0, 1);
  }

  function calculateAge(dateOfBirth) {
    var dob = new Date(dateOfBirth);
    var today = new Date();
  
    var yearsDiff = today.getFullYear() - dob.getFullYear();
    var monthsDiff = today.getMonth() - dob.getMonth();
    var daysDiff = today.getDate() - dob.getDate();
  
    // Check if the current month and day are before the birth month and day
    if (monthsDiff < 0 || (monthsDiff === 0 && daysDiff < 0)) {
      yearsDiff--;
    }
  
    return yearsDiff;
  }
  function distanceLabel(distance, rank) {
    const DistanceTobeChecked = parseFloat(distance);
    if (DistanceTobeChecked.toFixed(2) > 25) {
      return "Dist";
    } else if (DistanceTobeChecked.toFixed(2) == 0.05 && rank < 80) {
      return "SHd";
    } else if (DistanceTobeChecked.toFixed(2) == 0.1 && rank < 80) {
      return "Hd";
    } else if (DistanceTobeChecked.toFixed(2) == 0.25 && rank < 80) {
      return "Nk";
    } else if (DistanceTobeChecked.toFixed(2) == 0.01 && rank < 80) {
      return "Ns";
    } else if (DistanceTobeChecked.toFixed(2) == 0.0 && rank < 80) {
      if (rank === 1) {
        return 0;
      }
      return "Dh";
    } else {
      return distance;
    }
  }

  return (
   <>
    {status !== "loading" ? (
        <div className="RaceDetailCard datamobiletop">
          <button className="filterbtn" onClick={() => filtercard()}>
            <p>
              {t("Filter/Sort")} <BsFillFilterCircleFill />
            </p>
          </button>
          {showfilter ? (
            <>
              <table>
                <thead className="showfilterclass">
                  <tr>
                    <th>{t("Horse No")}:</th>
                    <th>{t("Gate No")}:</th>
                    <th>{t("Horse Name")}: </th>
                    <th>{t("Jockey Name")}:</th>
                    <th>{t("Trainer Name")}:</th>
                    <th>{t("Owner Name")}:</th>
                    <th>
                      {t("Rating")}:<></>
                    </th>
                    <th>
                      {t("Jockey Weight")}: <></>
                    </th>
                    {/* <th>{t("Rank")}: </th> */}
                  </tr>
                </thead>
                <tbody className="showfilterclass icons1">
                  <tr>
                    <td>
                      <AiFillCaretDown onClick={() => HorseDsc()} />{" "}
                      <IoCaretUpSharp onClick={() => HorseAsc()} />
                    </td>

                    <td>
                      <AiFillCaretDown onClick={() => GateDsc()} />{" "}
                      <IoCaretUpSharp onClick={() => GateAsc()} />
                    </td>

                    <td>
                      <AiFillCaretDown onClick={() => HorseNameDsc()} />{" "}
                      <IoCaretUpSharp onClick={() => HorseNameAsc()} />
                    </td>

                    <td>
                      <AiFillCaretDown onClick={() => JockeyDsc()} />{" "}
                      <IoCaretUpSharp onClick={() => JockeyAsc()} />
                    </td>

                    <td>
                      <AiFillCaretDown onClick={() => TrainerDsc()} />{" "}
                      <IoCaretUpSharp onClick={() => TrainerAsc()} />
                    </td>

                    <td>
                      <AiFillCaretDown onClick={() => OwnerDsc()} />{" "}
                      <IoCaretUpSharp onClick={() => OwnerAsc()} />
                    </td>

                    <td>
                      <AiFillCaretDown onClick={() => RatingDsc()} />{" "}
                      <IoCaretUpSharp onClick={() => RatingAsc()} />
                    </td>

                    <td>
                      <AiFillCaretDown onClick={() => JockeyWeightDsc()} />{" "}
                      <IoCaretUpSharp onClick={() => JockeyWeightAsc()} />
                    </td>
                    {/* 
                <td>
                  <AiFillCaretDown onClick={() => RaceRankDsc()} />{" "}
                  <IoCaretUpSharp onClick={() => RaceRankAsc()} />
                </td> */}
                  </tr>
                </tbody>
              </table>
            </>
          ) : null}

          <div className="forfexclass">
            <div>
              <Accordion>
                <div className="RaceAndHorseModelDataCSSFlex">
                  {SortedData.length !== 0 ? (
                    SortedData.map((data, index) => {
                      return (
                        <div className="RaceAndHorseModelDataCSS">
                          <Card>
                            <div className="row">
                              <div className="col-sm-12 myracecards">
                            <Card.Header >
                              <>
                                {data.HorseRunningStatus === false ? (
                                  <div className="nonrunner">
                                    <span>
                                      {cookiedata === "en" ? (
                                        <>Non Runner</>
                                      ) : (
                                        <>غير عداء</>
                                      )}
                                    </span>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </>
                              <div className="col-sm-12 flexcasted">
                                <span className="horseposition">
                                  {!data.HorseModelIdData1 &&
                                  !data.HorseModelIdData1 ? (
                                    <></>
                                  ) : (
                                    <>
                                      {data.HorseModelIdData1.UaeCareer &&
                                        data.HorseModelIdData1.UaeCareer.map(
                                          (item) => {
                                            return (
                                              <>
                                                {item.FinalPositionDataHorse
                                                  .Rank == "1" ? (
                                                  "1st"
                                                ) : (
                                                  <></>
                                                )}
                                                {item.FinalPositionDataHorse
                                                  .Rank == "2" ? (
                                                  "2nd"
                                                ) : (
                                                  <></>
                                                )}
                                                {item.FinalPositionDataHorse
                                                  .Rank == "3" ? (
                                                  "3rd"
                                                ) : (
                                                  <></>
                                                )}

                                                {item.FinalPositionDataHorse
                                                  .Rank > 3 &&
                                                item.FinalPositionDataHorse
                                                  .Rank < 80 ? (
                                                  <>
                                                    {" "}
                                                    {
                                                      item
                                                        .FinalPositionDataHorse
                                                        ?.NameEn
                                                    }
                                                    th
                                                  </>
                                                ) : (
                                                  <></>
                                                )}

                                                {item.FinalPositionDataHorse
                                                  .Rank > 80 ? (
                                                  <>
                                                    {" "}
                                                    {
                                                      item
                                                        .FinalPositionDataHorse
                                                        ?.NameEn
                                                    }
                                                  </>
                                                ) : (
                                                  <></>
                                                )}
                                              </>
                                            );
                                          }
                                        )}
                                    </>
                                  )}
                                </span>
                                {singlerace.CompetitionCategory === "cast" &&
                                data.HorseModelIdData1.Selection !== null ? (
                                  <p style={btnNewdelete} className="mobilec">
                                    {t("vote casted")}
                                  </p>
                                ) : (
                                  <></>
                                )}
                              </div>
                              <p></p>
                              <div className="row mycard1">
                                <div className="col-sm-3 cardraces1">
                                  <img
                                    src={
                                      data.CapColorData1
                                        ? data.CapColorData1.OwnerSilkColor
                                        : Defaultimg
                                    }
                                    alt=""
                                  />
                                  <span className="cardraces1box">
                                    <p>
                                      {data.HorseModelIdData1 ? (
                                        data.HorseModelIdData1.HorseForm
                                      ) : (
                                        <>0</>
                                      )}

                                      {/* {data.HorseModelIdData1 &&
                                    data.HorseModelIdData1.DOB === null ? (
                                      <></>
                                    ) : (
                                      <Moment format="DD-MM-YY">
                                        {data.HorseModelIdData1.DOB}
                                      </Moment>
                                    )} */}
                                    </p>

                                    <h3>{data?.HorseNo}</h3>
                                    <p
                                      style={{
                                        float: "right",
                                      }}
                                    >
                                      (
                                      {data.GateNo === 100 ? (
                                        <></>
                                      ) : (
                                        data.GateNo
                                      )}
                                      )
                                    </p>
                                  </span>
                                </div>

                                <div className="col-sm-6 cardraces2">
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "10px",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "18.6px",
                                        lineHeight: "24px",
                                        color: "#19469D",
                                      }}
                                    >
                                      <span
                                        onClick={() => handleShow(data)}
                                        style={{
                                          cursor: "pointer",
                                          display: "flex",
                                        }}
                                      >
                                        <>
                                          {cookiedata === "en"
                                            ? data.HorseModelIdData1 &&
                                            window.textTransform(data.HorseModelIdData1.NameEn)
                                            : data.HorseModelIdData1 &&
                                              data.HorseModelIdData1.NameAr}
                                        </>
                                        <p
                                          style={{
                                            marginLeft: "12px",
                                            cursor: "pointer",
                                            lineHeight: "15px",
                                            fontSize: "15px",
                                            marginTop: "5px",

                                          }}
                                          className="colorshorse"
                                        >
                                            {cookiedata === "en" ? (
                                              <>
                                                (
                                                {data.HorseModelIdData1 &&
                                                   window.textTransform(data.HorseModelIdData1
                                                    .NationalityData.AbbrevEn)}
                                                )<span style={{width:"4px"}}> </span>
                                                {calculateAge(
                                                  data.HorseModelIdData1 &&
                                                    data.HorseModelIdData1.DOB
                                                )}
                                                <span
                                                  style={{
                                                    textTransform: "lowercase",
                                                  }}
                                                >
                                                 
                                                  {t("yrs")}.
                                                </span>{" "}
                                                <span style={{width:"4px"}}> </span>
                                                {data.HorseModelIdData1 &&
                                                  data.HorseModelIdData1
                                                    .ColorIDData.AbbrevEn}

                                                <span style={{width:"4px"}}> </span>
                                                {data.HorseModelIdData1 &&
                                                  data.HorseModelIdData1
                                                    .SexModelData.AbbrevEn}
                                                <span style={{width:"4px"}}> </span>{" "}
                                                {data.HorseModelIdData1 &&
                                                data.HorseModelIdData1
                                                  .DaysGap !== null ? (
                                                  <>
                                                    (
                                                    {
                                                      data.HorseModelIdData1
                                                        .DaysGap
                                                    }
                                                    )
                                                  </>
                                                ) : (
                                                  <></>
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                (
                                                {data.HorseModelIdData1 &&
                                                  data.HorseModelIdData1
                                                    .NationalityData.AbbrevAr}
                                                )<span> </span>
                                                {calculateAge(
                                                  data.HorseModelIdData1 &&
                                                    data.HorseModelIdData1.DOB
                                                )}
                                                <span
                                                  style={{
                                                    textTransform: "lowercase",
                                                  }}
                                                >
                                                    {t("yrs")}.
                                                </span>
                                                {data.HorseModelIdData1 &&
                                                  data.HorseModelIdData1
                                                    .ColorIDData.AbbrevAr}
                                                     <span> </span>{" "}
                                                {data.HorseModelIdData1 &&
                                                  data.HorseModelIdData1
                                                    .SexModelData.AbbrevAr}
                                                <span> </span>{" "}
                                                {data.HorseModelIdData1 &&
                                                data.HorseModelIdData1
                                                  .DaysGap !== null ? (
                                                  <>
                                                    (
                                                    {
                                                      data.HorseModelIdData1
                                                        .DaysGap
                                                    }
                                                    )
                                                  </>
                                                ) : (
                                                  <></>
                                                )}
                                              </>
                                            )}
                                        </p>
                                      </span>
                                    </p>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      lineHeight: "1px",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    <p style={myPara}>
                                      {t("Dam")}:
                                      <span> </span>{" "}
                                      <b>
                                        
                                        {cookiedata === "en" ? (
                                          data.HorseModelIdData1 &&
                                          data.HorseModelIdData1.DamData ===
                                            null ? (
                                            <> - </>
                                          ) : (
                                            data.HorseModelIdData1 &&
                                            window.textTransform(data.HorseModelIdData1?.DamData?.NameEn)
                                          )
                                        ) : data.HorseModelIdData1.DamData ===
                                          null ? (
                                          <> - </>
                                        ) : (
                                          data.HorseModelIdData1?.DamData?.NameAr
                                        )}
                                      </b>
                                    </p>
                                    <p style={myPara}>
                                      {t("Sire")}:
                                      <b>
                                        
                                        {cookiedata === "en" ? (
                                          data.HorseModelIdData1 &&
                                          data.HorseModelIdData1.SireNameEn ===
                                            undefined ? (
                                            <> - </>
                                          ) : (
                                            data.HorseModelIdData1 &&
                                            window.textTransform(data.HorseModelIdData1.SireNameEn)
                                          )
                                        ) : data.HorseModelIdData1
                                            .SireNameAr === undefined ? (
                                          <> - </>
                                        ) : (
                                          data.HorseModelIdData1 &&
                                          data.HorseModelIdData1.SireNameAr
                                        )}
                                      </b>
                                    </p>
                                    <p style={myPara}>
                                      {t("GSire")}:
                                      <b
                                        style={{
                                          marginLeft: "12px",
                                        }}
                                      >
                                        
                                        {cookiedata === "en" ? (
                                          data.HorseModelIdData1 &&
                                          data.HorseModelIdData1.GSireNameEn ===
                                            undefined ? (
                                            <> - </>
                                          ) : (
                                            data.HorseModelIdData1 &&
                                            window.textTransform(data.HorseModelIdData1.GSireNameEn)
                                          )
                                        ) : data.HorseModelIdData1
                                            .GSireNameEn === undefined ? (
                                          <> - </>
                                        ) : (
                                          data.HorseModelIdData1 &&
                                          data.HorseModelIdData1.GSireNameAr
                                        )}
                                      </b>
                                    </p>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    {/* <p
                                   className="ownernamestyle1"
                                  >
                                  
                                  </p> */}
                                    <p
                                      className="ownernamestyle1"
                                      //  style={{marginLeft:"5px", lineHeight:"11px"}}
                                      onClick={() => handleShowOwner(data)}
                                    >
                                      <b>{t("O")}</b>:
                                      <b>
                                        {" "}
                                        {cookiedata === "en" ? (
                                          data.OwnerOnRaceData1 ===
                                          undefined ? (
                                            <>-</>
                                          ) : (
                                            window.textTransform(data.OwnerOnRaceData1.TitleEn)
                                          )
                                        ) : data.OwnerOnRaceData1 ===
                                          undefined ? (
                                          <>-</>
                                        ) : (
                                          data.OwnerOnRaceData1.TitleAr
                                        )}
                                        {cookiedata === "en" ? (
                                          data.OwnerOnRaceData1 ===
                                          undefined ? (
                                            <>-</>
                                          ) : (
                                            window.textTransform(data.OwnerOnRaceData1.ShortEn)
                                          )
                                        ) : data.OwnerOnRaceData1 ===
                                          undefined ? (
                                          <>-</>
                                        ) : (
                                          data.OwnerOnRaceData1.ShortAr
                                        )}
                                      </b>
                                    </p>
                                    <p className="ownernamestyle1">
                                      {data.OwnerOnRaceData1.OwnerCount}(
                                      {data.OwnerOnRaceData1.OwnerFirstRanking}{" "}
                                      -{" "}
                                      {data.OwnerOnRaceData1.OwnerSecondRanking}{" "}
                                      -{" "}
                                      {data.OwnerOnRaceData1.OwnerThirdRanking})
                                    </p>
                                  </div>

                                  <div className="trainerbreader_section">
                                    <img
                                      src={
                                        data.JockeyOnRaceData1 === null ? (
                                          <></>
                                        ) : data.JockeyOnRaceData1.image ===
                                          null ? (
                                          Defaultimg
                                        ) : (
                                          data.JockeyOnRaceData1.image
                                        )
                                      }
                                      alt=""
                                      className="trainerbreader_image"
                                    />{" "}
                                    <div className="race_trainerbreader">
                                      <span style={{ display: "flex" }}>
                                        <p>
                                          <b> {t("T")}</b>:
                                          <b
                                            style={{
                                              marginLeft: "9px",
                                              cursor: "pointer",
                                              lineHeight: "12px",
                                            }}
                                            onClick={() =>
                                              handleShowTrainer(data)
                                            }
                                          >
                                            {cookiedata === "en" ? (
                                              data.TrainerOnRaceData1 ===
                                              undefined ? (
                                                <>-</>
                                              ) : (
                                                window.textTransform(data.TrainerOnRaceData1.NameEn)
                                              )
                                            ) : data.TrainerOnRaceData1 ===
                                              undefined ? (
                                              <>-</>
                                            ) : (
                                              data.TrainerOnRaceData1.NameAr
                                            )}
                                          </b>
                                        </p>

                                        <p
                                          style={{
                                            fontWeight: "300",
                                            fontSize: "12px",
                                            lineHeight: "12px",
                                            color: "rgba(0, 0, 0, 0.5)",
                                          }}
                                        >
                                          {data.TrainerOnRaceData1?.TrainerCount}
                                          (
                                          {
                                            data.TrainerOnRaceData1
                                              .TrainerFirstRanking
                                          }{" "}
                                          -{" "}
                                          {
                                            data.TrainerOnRaceData1
                                              .TrainerSecondRanking
                                          }{" "}
                                          -{" "}
                                          {
                                            data.TrainerOnRaceData1
                                              .TrainerThirdRanking
                                          }
                                          )
                                        </p>
                                      </span>
                                      <p>
                                        {t("B")}:
                                        <b
                                        
                                          className="breedername2"
                                        >
                                          {cookiedata === "en" ? (
                                            data.HorseModelIdData1 &&
                                            data.HorseModelIdData1
                                              .BreederData === undefined ? (
                                              <>-</>
                                            ) : (
                                              data.HorseModelIdData1 &&
                                              window.textTransform(data.HorseModelIdData1.BreederData
                                                ?.NameEn)
                                            )
                                          ) : data.HorseModelIdData1
                                              .BreederData === undefined ? (
                                            <>-</>
                                          ) : (
                                            data.HorseModelIdData1.BreederData
                                              .NameAr
                                          )}
                                        </b>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                          

                                <div className="col-sm-3 cardraces4 arabic">
                                  <p
                                    style={{
                                      fontWeight: "300",
                                      fontSize: "12px",
                                      lineHeight: "15px",
                                      color: "rgba(0, 0, 0, 0.5)",
                                      textAlign: "end",
                                    }}
                                  >
                                    {cookiedata === "en" ? (
                                      <>
                                        {data.EquipmentData1 === null ? (
                                          <>-</>
                                        ) : (
                                          data.EquipmentData1.NameEn
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {data.EquipmentData1 === null ? (
                                          <>-</>
                                        ) : (
                                          data.EquipmentData1.NameAr
                                        )}
                                      </>
                                    )}{" "}
                                    {t("OR")}:
                                    {data.JockeyOnRaceData1 === null ? (
                                      <>-</>
                                    ) : data.Rating === undefined ? (
                                      <>0</>
                                    ) : (
                                      data.Rating
                                    )}
                                  </p>
                                  <div className="cardracesjockey">
                                    <div className="cardracesjockeyleft">
                                      <p
                                        onClick={() => handleShowJockey(data)}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        className="tooltipclass1"
                                      >
                                        {t("J")} :
                                        <b
                                          style={{
                                            margin: "0px 12px",
                                          }}
                                        >
                                          {cookiedata === "en" ? (
                                            data.JockeyOnRaceData1 === null ? (
                                              <>-</>
                                            ) : data.JockeyOnRaceData1 ===
                                              undefined ? (
                                              <>-</>
                                            ) : (
                                              window.textTransform(data?.JockeyOnRaceData1?.ShortNameEn)
                                            )
                                          ) : data.JockeyOnRaceData1 ===
                                            null ? (
                                            <>-</>
                                          ) : data.JockeyOnRaceData1 ===
                                            undefined ? (
                                            <>-</>
                                          ) : (
                                            data.JockeyOnRaceData1.ShortNameAr
                                          )}
                                        </b>
                                      </p>
                                      <p>
                                        {data.JockeyOnRaceData1 === null ? (
                                          <>-</>
                                        ) : data.JockeyRaceWeight ===
                                          undefined ? (
                                          <>-</>
                                        ) : (
                                          data.JockeyRaceWeight
                                        )}
                                        {t("kg")}
                                      </p>
                                      <p
                                        style={{
                                          fontWeight: "300",
                                          fontSize: "9px",
                                          lineHeight: "15px",
                                          color: "rgba(0, 0, 0, 0.5)",
                                        }}
                                      >
                                        {data.JockeyOnRaceData1 &&
                                          data.JockeyOnRaceData1.JockeyCount}
                                        (
                                        {data.JockeyOnRaceData1 &&
                                          data.JockeyOnRaceData1
                                            .JockeyFirstRanking}{" "}
                                        -{" "}
                                        {data.JockeyOnRaceData1 &&
                                          data.JockeyOnRaceData1
                                            .JockeySecondRanking}{" "}
                                        -{" "}
                                        {data.JockeyOnRaceData1 &&
                                          data.JockeyOnRaceData1
                                            .JockeyThirdRanking}
                                        )
                                      </p>
                                    </div>
                                    <img
                                      src={
                                        data.JockeyOnRaceData1 === null ? (
                                          <>-</>
                                        ) : data.JockeyOnRaceData1.image ===
                                          undefined ? (
                                          <>-</>
                                        ) : data.JockeyOnRaceData1.image ? (
                                          data.JockeyOnRaceData1.image
                                        ) : (
                                          Defaultimg
                                        )
                                      }
                                      alt=""
                                      className="cardracesjockeyimg"
                                    />
                                  </div>
                                  <div className="cardracesjockeycards">
                                    <ul>
                                      <li
                                        style={{
                                          backgroundColor: `${getColor(
                                            data.HorseModelIdData1 &&
                                              data.HorseModelIdData1.CourseGold,
                                            data.HorseModelIdData1 &&
                                              data.HorseModelIdData1.CourseBlue,
                                            data.HorseModelIdData1 &&
                                              data.HorseModelIdData1.CourseGrey
                                          )}`,
                                        }}
                                      >
                                        C
                                      </li>
                                      <li
                                        style={{
                                          backgroundColor: `${getColor(
                                            data.HorseModelIdData1 &&
                                              data.HorseModelIdData1
                                                .DistanceGold,
                                            data.HorseModelIdData1 &&
                                              data.HorseModelIdData1
                                                .DistanceBlue,
                                            data.HorseModelIdData1 &&
                                              data.HorseModelIdData1
                                                .DistanceGrey
                                          )}`,
                                        }}
                                      >
                                        D
                                      </li>
                                      <li
                                        style={{
                                          backgroundColor: `${getColor(
                                            data.HorseModelIdData1 &&
                                              data.HorseModelIdData1
                                                .RaceTypeGold,
                                            data.HorseModelIdData1 &&
                                              data.HorseModelIdData1
                                                .RaceTypeBlue,
                                            data.HorseModelIdData1 &&
                                              data.HorseModelIdData1
                                                .RaceTypeGrey
                                          )}`,
                                        }}
                                      >
                                        CL
                                      </li>
                                      {/* <li>BF</li> */}
                                      {compareNumbers(
                                        data.HorseModelIdData1 &&
                                          data.HorseModelIdData1.RatingNext,
                                        data.HorseModelIdData1 &&
                                          data.HorseModelIdData1.RatingPrevious
                                      ) ? (
                                        <li
                                          style={{
                                            width: "44px",
                                          }}
                                        >
                                          <FcUp />
                                          <p className="updownicon">
                                            {t("rating")}
                                          </p>
                                        </li>
                                      ) : (
                                        <li
                                          style={{
                                            width: "44px",
                                          }}
                                        >
                                          <FcDown />
                                          <p className="updownicon">
                                            {t("rating")}
                                          </p>
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div className="col-sm-12 pmclass">
                                  <p >
                                    {t("PM")} :
                                    <b>
                                      {data.HorseModelIdData1
                                        ? data.HorseModelIdData1.PurchasePrice
                                        : 0}
                                    </b>
                                  </p>
                                  <p>
                                    {t("BTO")} :
                                    <b>
                                      {data.HorseModelIdData1 ? (
                                        data.HorseModelIdData1.TotalBto
                                      ) : (
                                        <> 0</>
                                      )}
                                    </b>
                                  </p>
                                  <p>{t("SP")}  <b>
                                      {data.HorseModelIdData1 ? (
                                        data.HorseModelIdData1.TotalPrizeWin
                                      ) : (
                                        <> 0</>
                                      )}
                                    </b></p>
                                </div>
                                <div className="row uaestats">
                                  <div className="col-sm-2" >  <p >
                                    {t("UAECareer")}:{" "}
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1.UaeCareerCount}
                                    (
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1
                                        .UaeFirstRanking}{" "}
                                    -{" "}
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1
                                        .UaeSecondRanking}{" "}
                                    -{" "}
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1.UaeThirdRanking}
                                    )
                                  </p></div>
                                  <div className="col-sm-2"> <p>
                                    {t("Lifetime")}:{" "}
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1.LifeTimeCount}
                                    (
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1
                                        .LifeTimeFirstRanking}{" "}
                                    -{" "}
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1
                                        .LifeTimeSecondRanking}{" "}
                                    -{" "}
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1
                                        .LifeTimeThirdRanking}
                                    )
                                  </p></div>
                                  <div className="col-sm-2"> <p>
                                    {t("Turf")} :
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1.TurfCount}
                                    (
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1
                                        .TurfFirstRanking}{" "}
                                    -{" "}
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1
                                        .TurfSecondRanking}{" "}
                                    -{" "}
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1.TurfThirdRanking}
                                    )
                                  </p></div>
                                  <div className="col-sm-2"><p>
                                    {t("Dirt")} :{" "}
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1.DirtCount}
                                    (
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1
                                        .DirtFirstRanking}{" "}
                                    -{" "}
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1
                                        .DirtSecondRanking}{" "}
                                    -{" "}
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1.DirtThirdRanking}
                                    )
                                  </p></div>
                                  <div className="col-sm-2"> <p>
                                    {t("Dist")} :
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1.DistanceCount}
                                    (
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1
                                        .DistanceFirstRanking}{" "}
                                    -{" "}
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1
                                        .DistanceSecondRanking}{" "}
                                    -{" "}
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1
                                        .DistanceThirdRanking}
                                    ){" "}
                                  </p></div>
                                  <div className="col-sm-2">  <p>
                                    {t("AW")} :
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1.AllWeatherCount}
                                    (
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1
                                        .AllWeatherFirstRanking}{" "}
                                    -{" "}
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1
                                        .AllWeatherSecondRanking}{" "}
                                    -{" "}
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1
                                        .AllWeatherThirdRanking}
                                    ){" "}
                                  </p></div>
                                  
                                
                                 
                                 
                                  
                                 
                                
                                </div>
                              </div>
                              {singlerace.racedetail.RaceStatus === "Live" &&
                              data.HorseRunningStatus == true &&
                              singlerace.racedetail &&
                              singlerace.CompetitionCategory === "pick" ? (
                                <>
                                  {data.HorseModelIdData1.Selection == null ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: "10px",
                                      }}
                                    >
                                      <button
                                        style={btnNew1}
                                        onClick={(event) =>
                                          pickClick(
                                            event,
                                            singlerace.racedetail
                                              .RaceDataOfCompetition[0]
                                              .Competition,
                                            data.HorseModelIdData1._id,
                                            singlerace?.votedhorse, 
                                            data?.HorseModelIdData1?.NameEn
                                          )
                                        }
                                        className="mobilec"
                                      >
                                        {t("Pick")}
                                      </button>
                                    </div>
                                  ) : (
                                    <button
                                      onClick={(event) =>
                                        DeletePick(
                                          event,
                                          data.HorseModelIdData1.Selection,
                                          data.HorseModelIdData1._id
                                        )
                                      }
                                      style={btnNewdelete}
                                      className="mobilec"
                                    >
                                      {t("Picked")}
                                    </button>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}

                              <CustomToggle eventKey={index}>
                                <>
                                  <button
                                    className="showMore"
                                    onClick={() =>
                                      showHorseHistory(
                                        data.HorseModelIdData1._id
                                      )
                                    }
                                  >
                                    {" "}
                                    {t("ShowHistory")}
                                  </button>
                                </>
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={index}>
                              <Card.Body>
                                <div className="mycardclass1">
                                  <div className="BodyNew mpobile0 mpobile0">
                                    <table className="customers">
                                      <thead className="customers5">
                                        <tr>
                                          <th>{t("Date")}</th>
                                          <th>{t("Crs")}</th>
                                          <th>{t("Dist")}</th>
                                          <th>{t("TC")}</th>
                                          <th>{t("RT")}</th>

                                          <th>{t("Dts")}</th>
                                          <th>{t("Time")}</th>
                                          <th>{t("Jockey")}</th>
                                          <th>{t("Wgt")}</th>
                                          <th>{t("FP")}</th>
                                          <th>{t("Len")}</th>
                                          <th>{t("RN")}</th>
                                          <th>{t("BtBy")}</th>
                                          <th>{t("Kgs")}</th>

                                          <th>{t("Draw")}</th>
                                          <th>{t("Link")}</th>
                                        </tr>
                                      </thead>
                                      <tbody className="customers1">
                                        {History && History?.length === 0 ? (
                                          <NoHistory />
                                        ) : (
                                          <>
                                            {!historyData ? (
                                              <PleaseWait />
                                            ) : (
                                              History.map((item) => {
                                                return (
                                                  <tr
                                                    className="BodyNew mpobile01 historyhorseth"
                                                    style={{
                                                      background: `${
                                                        item.RaceResultData
                                                          .RacehorsesData[0]
                                                          .RacehorsesData
                                                          .TrackLengthData
                                                          .GroundTypeModelData
                                                          .NameEn == "Dirt"
                                                          ? "#9F5109"
                                                          : item.RaceResultData
                                                              .RacehorsesData[0]
                                                              .RacehorsesData
                                                              .TrackLengthData
                                                              .GroundTypeModelData
                                                              .NameEn == "Turf"
                                                          ? "#5ec40f"
                                                          : "#949494"
                                                      }`,
                                                    }}
                                                    key={i}
                                                  >
                                                    <td
                                                      className="lb"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        handleShowRace(
                                                          item.RaceID
                                                        )
                                                      }
                                                    >
                                                      <Moment format="DD/MM/YYYY">
                                                        {item.RaceResultData ===
                                                        null ? (
                                                          <></>
                                                        ) : (
                                                          item.RaceResultData
                                                            .Day
                                                        )}
                                                      </Moment>
                                                    </td>
                                                    <td>
                                                      {item.RaceResultData
                                                        .RacehorsesData[0]
                                                        ? cookiedata === "en"
                                                          ? item.RaceResultData
                                                              .RacehorsesData[0]
                                                              .RacehorsesData
                                                              .RaceCourseData
                                                              .AbbrevEn
                                                          : item.RaceResultData
                                                              .RacehorsesData[0]
                                                              .RacehorsesData
                                                              .RaceCourseData
                                                              .AbbrevAr
                                                        : "-"}
                                                      (
                                                      {FirstLetter(
                                                        cookiedata === "en"
                                                          ? item.RaceResultData
                                                              .RacehorsesData[0]
                                                              .RacehorsesData
                                                              .TrackLengthData
                                                              .GroundTypeModelData
                                                              .NameEn
                                                          : item.RaceResultData
                                                              .RacehorsesData[0]
                                                              .RacehorsesData
                                                              .TrackLengthData
                                                              .GroundTypeModelData
                                                              .NameAr
                                                      )}
                                                      )
                                                    </td>
                                                    <td>
                                                      {distanceLabel(
                                                        item.Distance &&
                                                          item.Distance,
                                                        item.FinalPositionDataHorse &&
                                                          item
                                                            .FinalPositionDataHorse
                                                            ?.Rank
                                                      )}
                                                    </td>
                                                    <td>
                                                      {item.RaceResultData
                                                        .RacehorsesData
                                                        ? cookiedata === "en"
                                                          ? item.RaceResultData
                                                              .RacehorsesData[0]
                                                              .RacehorsesData
                                                              .TrackConditionData
                                                              .NameEn
                                                          : item.RaceResultData
                                                              .RacehorsesData[0]
                                                              .RacehorsesData
                                                              .TrackConditionData
                                                              .NameAr
                                                        : "_"}
                                                    </td>
                                                    <td>
                                                      <OverlayTrigger
                                                        className="tooltip-btn"
                                                        overlay={
                                                          <Tooltip
                                                            id="tooltip-disabled"
                                                            className="tooltip-btn"
                                                          >
                                                            {item.RaceResultData
                                                              .RacehorsesData
                                                              ? cookiedata ===
                                                                "en"
                                                                ? item
                                                                    .RaceResultData
                                                                    .RacehorsesData[0]
                                                                    .RacehorsesData
                                                                    .RaceTypeModelData
                                                                    .NameEn
                                                                : item
                                                                    .RaceResultData
                                                                    .RacehorsesData[0]
                                                                    .RacehorsesData
                                                                    .RaceTypeModelData
                                                                    .NameAr
                                                              : "_"}
                                                          </Tooltip>
                                                        }
                                                      >
                                                        <span
                                                          className="d-inline-block"
                                                          style={{
                                                            marginTop: "12px",
                                                          }}
                                                        >
                                                          <p className="tooltipclass1">
                                                            {" "}
                                                            {item.RaceResultData
                                                              .RacehorsesData
                                                              ? cookiedata ===
                                                                "en"
                                                                ? item
                                                                    .RaceResultData
                                                                    .RacehorsesData[0]
                                                                    .RacehorsesData
                                                                    .RaceTypeModelData
                                                                    .NameEn
                                                                : item
                                                                    .RaceResultData
                                                                    .RacehorsesData[0]
                                                                    .RacehorsesData
                                                                    .RaceTypeModelData
                                                                    .NameAr
                                                              : "_"}
                                                          </p>
                                                        </span>
                                                      </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                      {item.PrizeWin
                                                        ? item.PrizeWin
                                                        : 0}
                                                    </td>
                                                    <td>{item.RaceTime}</td>
                                                    <td
                                                      onClick={() =>
                                                        handleShowJockey(
                                                          item.JockeyOnRaceDataResult
                                                            ? item.JockeyOnRaceDataResult
                                                            : ""
                                                        )
                                                      }
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      {item.JockeyOnRaceDataResult
                                                        ? cookiedata === "en"
                                                          ? item
                                                              .JockeyOnRaceDataResult
                                                              .ShortNameEn
                                                          : item
                                                              .JockeyOnRaceDataResult
                                                              .ShortNameAr
                                                        : "_"}
                                                    </td>
                                                    <td>
                                                      {item.RaceResultData
                                                        .RacehorsesData
                                                        ? item.RaceResultData
                                                            .RacehorsesData[0]
                                                            .JockeyRaceWeight
                                                        : "_"}
                                                    </td>
                                                    {/* <td>
                                                    {item.RaceResultData
                                                      .RacehorsesData
                                                      ? item.RaceResultData
                                                          .RacehorsesData[0]
                                                          .JockeyRaceWeight
                                                      : "_"}
                                                  </td> */}
                                                    <td>
                                                      {item.FinalPositionDataHorse ===
                                                      null ? (
                                                        <></>
                                                      ) : (
                                                        <>
                                                          {cookiedata ===
                                                          "en" ? (
                                                            <>
                                                              {cookiedata ===
                                                              "en"
                                                                ? item.FinalPositionDataHorse &&
                                                                  item
                                                                    .FinalPositionDataHorse
                                                                    .NameEn
                                                                : item.FinalPositionDataHorse &&
                                                                  item
                                                                    .FinalPositionDataHorse
                                                                    .NameEn}
                                                            </>
                                                          ) : (
                                                            <>
                                                              {
                                                                item
                                                                  .FinalPositionDataHorse
                                                                  .NameAr
                                                              }
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                    </td>
                                                    <td>
                                                      {
                                                        item.RaceResultData
                                                          .RacehorsesData[0]
                                                          .RacehorsesData
                                                          .TrackLengthData
                                                          .TrackLength
                                                      }
                                                    </td>
                                                    <td>{item.Runners}</td>
                                                    <td
                                                      onClick={() =>
                                                        handleShow(
                                                          item.BeatenByData
                                                            ? item.BeatenByData
                                                            : ""
                                                        )
                                                      }
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      {item.BeatenByData ===
                                                      null ? (
                                                        <>
                                                          {cookiedata === "en"
                                                            ? item.HorseIDData
                                                                .HorseBeatenByEnglishName
                                                            : item.HorseIDData
                                                                .HorseBeatenByArabicName}
                                                        </>
                                                      ) : (
                                                        <>
                                                          {cookiedata ===
                                                          "en" ? (
                                                            <>
                                                              {
                                                                item
                                                                  .BeatenByData
                                                                  .NameEn
                                                              }
                                                            </>
                                                          ) : (
                                                            <>
                                                              {
                                                                item
                                                                  .BeatenByData
                                                                  .NameAr
                                                              }
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                    </td>{" "}
                                                    <td>
                                                      {item.BeatenByData !==
                                                      null
                                                        ? item.BeatenByData
                                                            .WinningHorseJockeyWeight
                                                        : item.HorseIDData
                                                            .BeatenByJockeyWeight ===
                                                          null
                                                        ? 0
                                                        : item.HorseIDData
                                                            .BeatenByJockeyWeight}
                                                    </td>
                                                    <td>
                                                      {item.RaceResultData
                                                        .RacehorsesData[0]
                                                        ? item.RaceResultData
                                                            .RacehorsesData[0]
                                                            ?.GateNo
                                                        : "-"}
                                                    </td>
                                                    <td
                                                      className="rb"
                                                      onClick={() =>
                                                        handleShows(
                                                          item.VideoLink
                                                        )
                                                      }
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      <img
                                                        src={arrow1}
                                                        alt=""
                                                      />
                                                    </td>
                                                  </tr>
                                                );
                                              })
                                            )}
                                          </>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                            </div>
                            </div>
                          </Card>
                        </div>
                      );
                    })
                  ) : (
                    <>
                      {datafilter.map((data, index) => {
                        return (
                          <div className="RaceAndHorseModelDataCSS">
                            <Card>
                            <div className="row">
                            <div className="col-sm-12 myracecards">
                            <Card.Header>
                              <>
                                {data.HorseRunningStatus === false ? (
                                  <div className="nonrunner">
                                    <span>
                                      {cookiedata === "en" ? (
                                        <>Non Runner</>
                                      ) : (
                                        <>غير عداء</>
                                      )}
                                    </span>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </>
                              <div className="col-sm-12 flexcasted">
                              <span className="horseposition">
                                  {!data.HorseModelIdData1 &&
                                  !data.HorseModelIdData1 ? (
                                    <></>
                                  ) : (
                                    <>
                                      {data.HorseModelIdData1.UaeCareer &&
                                        data.HorseModelIdData1.UaeCareer.map(
                                          (item) => {
                                            return (
                                              <>
                                                {item.FinalPositionDataHorse
                                                  .Rank == "1" ? (
                                                  "1st"
                                                ) : (
                                                  <></>
                                                )}
                                                {item.FinalPositionDataHorse
                                                  .Rank == "2" ? (
                                                  "2nd"
                                                ) : (
                                                  <></>
                                                )}
                                                {item.FinalPositionDataHorse
                                                  .Rank == "3" ? (
                                                  "3rd"
                                                ) : (
                                                  <></>
                                                )}

                                                {item.FinalPositionDataHorse
                                                  .Rank > 3 &&
                                                item.FinalPositionDataHorse
                                                  .Rank < 80 ? (
                                                  <>
                                                    {" "}
                                                    {
                                                      item
                                                        .FinalPositionDataHorse
                                                        ?.NameEn
                                                    }
                                                    th
                                                  </>
                                                ) : (
                                                  <></>
                                                )}

                                                {item.FinalPositionDataHorse
                                                  .Rank > 80 ? (
                                                  <>
                                                    {" "}
                                                    {
                                                      item
                                                        .FinalPositionDataHorse
                                                        ?.NameEn
                                                    }
                                                  </>
                                                ) : (
                                                  <></>
                                                )}
                                              </>
                                            );
                                          }
                                        )}
                                    </>
                                  )}
                                </span>
                                {singlerace.CompetitionCategory === "cast" &&
                                data.HorseModelIdData1.Selection !== null ? (
                                  <p style={btnNewdelete} className="mobilec">
                                    {t("vote casted")}
                                  </p>
                                ) : (
                                  <></>
                                )}
                              </div>
                              <p></p>
                              <div className="row mycard1">
                                <div className="col-sm-3 cardraces1">
                                  <img
                                    src={
                                      data.CapColorData1
                                        ? data.CapColorData1.OwnerSilkColor
                                        : Defaultimg
                                    }
                                    alt=""
                                  />
                                  <span className="cardraces1box">
                                    <p>
                                      {data.HorseModelIdData1 ? (
                                        data.HorseModelIdData1.HorseForm
                                      ) : (
                                        <>0</>
                                      )}

                                      {/* {data.HorseModelIdData1 &&
                                    data.HorseModelIdData1.DOB === null ? (
                                      <></>
                                    ) : (
                                      <Moment format="DD-MM-YY">
                                        {data.HorseModelIdData1.DOB}
                                      </Moment>
                                    )} */}
                                    </p>

                                    <h3>{data?.HorseNo}</h3>
                                    <p
                                      style={{
                                        float: "right",
                                      }}
                                    >
                                      (
                                      {data.GateNo === 100 ? (
                                        <></>
                                      ) : (
                                        data.GateNo
                                      )}
                                      )
                                    </p>
                                  </span>
                                </div>

                                <div className="col-sm-6 cardraces2">
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "10px",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "18.6px",
                                        lineHeight: "24px",
                                        color: "#19469D",
                                      }}
                                    >
                                      <span
                                        onClick={() => handleShow(data)}
                                        style={{
                                          cursor: "pointer",
                                          display: "flex",
                                        }}
                                      >
                                        <>
                                          {cookiedata === "en"
                                            ? data.HorseModelIdData1 &&
                                            window.textTransform(data.HorseModelIdData1.NameEn)
                                            : data.HorseModelIdData1 &&
                                              data.HorseModelIdData1.NameAr}
                                        </>
                                        <p
                                          style={{
                                            marginLeft: "12px",
                                            cursor: "pointer",
                                            lineHeight: "15px",
                                            fontSize: "15px",
                                            marginTop: "5px",

                                          }}
                                          className="colorshorse"
                                        >
                                            {cookiedata === "en" ? (
                                              <>
                                                (
                                                {data.HorseModelIdData1 &&
                                                  data.HorseModelIdData1
                                                    .NationalityData.AbbrevEn}
                                                )<span style={{width:"4px"}}> </span>
                                                {calculateAge(
                                                  data.HorseModelIdData1 &&
                                                    data.HorseModelIdData1.DOB
                                                )}
                                                <span
                                                  style={{
                                                    textTransform: "lowercase",
                                                  }}
                                                >
                                                  <span> </span>{" "}
                                                   {t("yrs")}.
                                                </span>{" "}
                                                <span style={{width:"4px"}}> </span>
                                                {data.HorseModelIdData1 &&
                                                  data.HorseModelIdData1
                                                    .ColorIDData.AbbrevEn}
                                                <span style={{width:"4px"}}> </span>
                                                {data.HorseModelIdData1 &&
                                                  data.HorseModelIdData1
                                                    .SexModelData.AbbrevEn}
                                                <span style={{width:"4px"}}> </span>{" "}
                                                {data.HorseModelIdData1 &&
                                                data.HorseModelIdData1
                                                  .DaysGap !== null ? (
                                                  <>
                                                    (
                                                    {
                                                      data.HorseModelIdData1
                                                        .DaysGap
                                                    }
                                                    )
                                                  </>
                                                ) : (
                                                  <></>
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                (
                                                {data.HorseModelIdData1 &&
                                                  data.HorseModelIdData1
                                                    .NationalityData.AbbrevAr}
                                                )<span> </span>
                                                {calculateAge(
                                                  data.HorseModelIdData1 &&
                                                    data.HorseModelIdData1.DOB
                                                )}
                                                <span
                                                  style={{
                                                    textTransform: "lowercase",
                                                  }}
                                                >
                                                   {t("yrs")}.
                                                </span>
                                                {data.HorseModelIdData1 &&
                                                  data.HorseModelIdData1
                                                    .ColorIDData.AbbrevAr}
                                                     <span>   </span>{" "}
                                                {data.HorseModelIdData1 &&
                                                  data.HorseModelIdData1
                                                    .SexModelData.AbbrevAr}
                                                <span> </span>{" "}
                                                {data.HorseModelIdData1 &&
                                                data.HorseModelIdData1
                                                  .DaysGap !== null ? (
                                                  <>
                                                    (
                                                    {
                                                      data.HorseModelIdData1
                                                        .DaysGap
                                                    }
                                                    )
                                                  </>
                                                ) : (
                                                  <></>
                                                )}
                                              </>
                                            )}
                                        </p>
                                      </span>
                                    </p>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      lineHeight: "1px",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    <p style={myPara}>
                                      {t("Dam")}:
                                      <span> </span>{" "}
                                      <b>
                                        
                                        {cookiedata === "en" ? (
                                          data.HorseModelIdData1 &&
                                          data.HorseModelIdData1.DamData ===
                                            null ? (
                                            <> - </>
                                          ) : (
                                            data.HorseModelIdData1 &&
                                            window.textTransform(data.HorseModelIdData1.NameEn)
                                          )
                                        ) : data.HorseModelIdData1.DamData ===
                                          null ? (
                                          <> - </>
                                        ) : (
                                          data.HorseModelIdData1.NameAr
                                        )}
                                      </b>
                                    </p>
                                    <p style={myPara}>
                                      {t("Sire")}:
                                      <b>
                                        
                                        {cookiedata === "en" ? (
                                          data.HorseModelIdData1 &&
                                          data.HorseModelIdData1.SireNameEn ===
                                            undefined ? (
                                            <> - </>
                                          ) : (
                                            data.HorseModelIdData1 &&
                                            window.textTransform(data.HorseModelIdData1.SireNameEn)
                                          )
                                        ) : data.HorseModelIdData1
                                            .SireNameAr === undefined ? (
                                          <> - </>
                                        ) : (
                                          data.HorseModelIdData1 &&
                                          data.HorseModelIdData1.SireNameAr
                                        )}
                                      </b>
                                    </p>
                                    <p style={myPara}>
                                      {t("GSire")}:
                                      <b
                                        style={{
                                          marginLeft: "12px",
                                        }}
                                      >
                                        
                                        {cookiedata === "en" ? (
                                          data.HorseModelIdData1 &&
                                          data.HorseModelIdData1.GSireNameEn ===
                                            undefined ? (
                                            <> - </>
                                          ) : (
                                            data.HorseModelIdData1 &&
                                            window.textTransform(data.HorseModelIdData1.GSireNameEn)
                                          )
                                        ) : data.HorseModelIdData1
                                            .GSireNameEn === undefined ? (
                                          <> - </>
                                        ) : (
                                          data.HorseModelIdData1 &&
                                          data.HorseModelIdData1.GSireNameAr
                                        )}
                                      </b>
                                    </p>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    {/* <p
                                   className="ownernamestyle1"
                                  >
                                  
                                  </p> */}
                                    <p
                                      className="ownernamestyle1"
                                      //  style={{marginLeft:"5px", lineHeight:"11px"}}
                                      onClick={() => handleShowOwner(data)}
                                    >
                                      <b>{t("O")}</b>:
                                      <b>
                                        {" "}
                                        {cookiedata === "en" ? (
                                          data.OwnerOnRaceData1 ===
                                          undefined ? (
                                            <>-</>
                                          ) : (
                                            window.textTransform(data.OwnerOnRaceData1.TitleEn)
                                          )
                                        ) : data.OwnerOnRaceData1 ===
                                          undefined ? (
                                          <>-</>
                                        ) : (
                                          data.OwnerOnRaceData1.TitleAr
                                        )}
                                        {cookiedata === "en" ? (
                                          data.OwnerOnRaceData1 ===
                                          undefined ? (
                                            <>-</>
                                          ) : (
                                            window.textTransform(data.OwnerOnRaceData1.ShortEn)
                                          )
                                        ) : data.OwnerOnRaceData1 ===
                                          undefined ? (
                                          <>-</>
                                        ) : (
                                          data.OwnerOnRaceData1.ShortAr
                                        )}
                                      </b>
                                    </p>
                                    <p className="ownernamestyle1">
                                      {data.OwnerOnRaceData1.OwnerCount}(
                                      {data.OwnerOnRaceData1.OwnerFirstRanking}{" "}
                                      -{" "}
                                      {data.OwnerOnRaceData1.OwnerSecondRanking}{" "}
                                      -{" "}
                                      {data.OwnerOnRaceData1.OwnerThirdRanking})
                                    </p>
                                  </div>

                                  <div className="trainerbreader_section">
                                    <img
                                      src={
                                        data.JockeyOnRaceData1 === null ? (
                                          <></>
                                        ) : data.JockeyOnRaceData1.image ===
                                          null ? (
                                          Defaultimg
                                        ) : (
                                          data.JockeyOnRaceData1.image
                                        )
                                      }
                                      alt=""
                                      className="trainerbreader_image"
                                    />{" "}
                                    <div className="race_trainerbreader">
                                      <span style={{ display: "flex" }}>
                                        <p>
                                          <b> {t("T")}</b>:
                                          <b
                                            style={{
                                              marginLeft: "9px",
                                              cursor: "pointer",
                                              lineHeight: "12px",
                                            }}
                                            onClick={() =>
                                              handleShowTrainer(data)
                                            }
                                          >
                                            {cookiedata === "en" ? (
                                              data.TrainerOnRaceData1 ===
                                              undefined ? (
                                                <>-</>
                                              ) : (
                                                window.textTransform(data.TrainerOnRaceData1.NameEn)
                                              )
                                            ) : data.TrainerOnRaceData1 ===
                                              undefined ? (
                                              <>-</>
                                            ) : (
                                              data.TrainerOnRaceData1.NameAr
                                            )}
                                          </b>
                                        </p>

                                        <p
                                          style={{
                                            fontWeight: "300",
                                            fontSize: "12px",
                                            lineHeight: "12px",
                                            color: "rgba(0, 0, 0, 0.5)",
                                          }}
                                        >
                                          {data.TrainerOnRaceData1.TrainerCount}
                                          (
                                          {
                                            data.TrainerOnRaceData1
                                              .TrainerFirstRanking
                                          }{" "}
                                          -{" "}
                                          {
                                            data.TrainerOnRaceData1
                                              .TrainerSecondRanking
                                          }{" "}
                                          -{" "}
                                          {
                                            data.TrainerOnRaceData1
                                              .TrainerThirdRanking
                                          }
                                          )
                                        </p>
                                      </span>
                                      <p>
                                        {t("B")}:
                                        <b
                                        
                                          className="breedername2"
                                        >
                                          {cookiedata === "en" ? (
                                            data.HorseModelIdData1 &&
                                            data.HorseModelIdData1
                                              .BreederData === undefined ? (
                                              <>-</>
                                            ) : (
                                              data.HorseModelIdData1 &&
                                              window.textTransform(data.HorseModelIdData1.BreederData
                                                ?.NameEn)
                                            )
                                          ) : data.HorseModelIdData1
                                              .BreederData === undefined ? (
                                            <>-</>
                                          ) : (
                                            data.HorseModelIdData1.BreederData
                                              .NameAr
                                          )}
                                        </b>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                          

                                <div className="col-sm-3 cardraces4 arabic">
                                  <p
                                    style={{
                                      fontWeight: "300",
                                      fontSize: "12px",
                                      lineHeight: "15px",
                                      color: "rgba(0, 0, 0, 0.5)",
                                      textAlign: "end",
                                    }}
                                  >
                                    {cookiedata === "en" ? (
                                      <>
                                        {data.EquipmentData1 === null ? (
                                          <>-</>
                                        ) : (
                                          data.EquipmentData1.NameEn
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {data.EquipmentData1 === null ? (
                                          <>-</>
                                        ) : (
                                          data.EquipmentData1.NameAr
                                        )}
                                      </>
                                    )}{" "}
                                    {t("OR")}:
                                    {data.JockeyOnRaceData1 === null ? (
                                      <>-</>
                                    ) : data.Rating === undefined ? (
                                      <>0</>
                                    ) : (
                                      data.Rating
                                    )}
                                  </p>
                                  <div className="cardracesjockey">
                                    <div className="cardracesjockeyleft">
                                      <p
                                        onClick={() => handleShowJockey(data)}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        className="tooltipclass1"
                                      >
                                        {t("J")} :
                                        <b
                                          style={{
                                            margin: "0px 12px",
                                          }}
                                        >
                                          {cookiedata === "en" ? (
                                            data.JockeyOnRaceData1 === null ? (
                                              <>-</>
                                            ) : data.JockeyOnRaceData1 ===
                                              undefined ? (
                                              <>-</>
                                            ) : (
                                              window.textTransform(data.JockeyOnRaceData1.ShortNameEn)
                                            )
                                          ) : data.JockeyOnRaceData1 ===
                                            null ? (
                                            <>-</>
                                          ) : data.JockeyOnRaceData1 ===
                                            undefined ? (
                                            <>-</>
                                          ) : (
                                            data.JockeyOnRaceData1.ShortNameAr
                                          )}
                                        </b>
                                      </p>
                                      <p>
                                        {data.JockeyRaceWeight === null ? (
                                          <>-</>
                                        ) : data.JockeyRaceWeight ===
                                          undefined ? (
                                          <>-</>
                                        ) : (
                                          data.JockeyRaceWeight
                                        )}
                                        {t("kg")}
                                      </p>
                                      <p
                                        style={{
                                          fontWeight: "300",
                                          fontSize: "9px",
                                          lineHeight: "15px",
                                          color: "rgba(0, 0, 0, 0.5)",
                                        }}
                                      >
                                        {data.JockeyOnRaceData1 &&
                                          data.JockeyOnRaceData1.JockeyCount}
                                        (
                                        {data.JockeyOnRaceData1 &&
                                          data.JockeyOnRaceData1
                                            .JockeyFirstRanking}{" "}
                                        -{" "}
                                        {data.JockeyOnRaceData1 &&
                                          data.JockeyOnRaceData1
                                            .JockeySecondRanking}{" "}
                                        -{" "}
                                        {data.JockeyOnRaceData1 &&
                                          data.JockeyOnRaceData1
                                            .JockeyThirdRanking}
                                        )
                                      </p>
                                    </div>
                                    <img
                                      src={
                                        data.JockeyOnRaceData1 === null ? (
                                          Defaultimg
                                        ) : data.JockeyOnRaceData1.image ===
                                          undefined ? (
                                          <>-</>
                                        ) : data.JockeyOnRaceData1.image ? (
                                          data.JockeyOnRaceData1.image
                                        ) : (
                                          Defaultimg
                                        )
                                      }
                                      alt=""
                                      className="cardracesjockeyimg"
                                    />
                                  </div>
                                  <div className="cardracesjockeycards">
                                    <ul>
                                      <li
                                        style={{
                                          backgroundColor: `${getColor(
                                            data.HorseModelIdData1 &&
                                              data.HorseModelIdData1.CourseGold,
                                            data.HorseModelIdData1 &&
                                              data.HorseModelIdData1.CourseBlue,
                                            data.HorseModelIdData1 &&
                                              data.HorseModelIdData1.CourseGrey
                                          )}`,
                                        }}
                                      >
                                        C
                                      </li>
                                      <li
                                        style={{
                                          backgroundColor: `${getColor(
                                            data.HorseModelIdData1 &&
                                              data.HorseModelIdData1
                                                .DistanceGold,
                                            data.HorseModelIdData1 &&
                                              data.HorseModelIdData1
                                                .DistanceBlue,
                                            data.HorseModelIdData1 &&
                                              data.HorseModelIdData1
                                                .DistanceGrey
                                          )}`,
                                        }}
                                      >
                                        D
                                      </li>
                                      <li
                                        style={{
                                          backgroundColor: `${getColor(
                                            data.HorseModelIdData1 &&
                                              data.HorseModelIdData1
                                                .RaceTypeGold,
                                            data.HorseModelIdData1 &&
                                              data.HorseModelIdData1
                                                .RaceTypeBlue,
                                            data.HorseModelIdData1 &&
                                              data.HorseModelIdData1
                                                .RaceTypeGrey
                                          )}`,
                                        }}
                                      >
                                        CL
                                      </li>
                                      {/* <li>BF</li> */}
                                      {compareNumbers(
                                        data.HorseModelIdData1 &&
                                          data.HorseModelIdData1.RatingNext,
                                        data.HorseModelIdData1 &&
                                          data.HorseModelIdData1.RatingPrevious
                                      ) ? (
                                        <li
                                          style={{
                                            width: "44px",
                                          }}
                                        >
                                          <FcUp />
                                          <p className="updownicon">
                                            {t("rating")}
                                          </p>
                                        </li>
                                      ) : (
                                        <li
                                          style={{
                                            width: "44px",
                                          }}
                                        >
                                          <FcDown />
                                          <p className="updownicon">
                                            {t("rating")}
                                          </p>
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div className="col-sm-12 pmclass">
                                  <p >
                                    {t("PM")} :
                                    <b>
                                      {data.HorseModelIdData1
                                        ? data.HorseModelIdData1.PurchasePrice
                                        : 0}
                                    </b>
                                  </p>
                                  <p>
                                    {t("BTO")} :
                                    <b>
                                      {data.HorseModelIdData1 ? (
                                        data.HorseModelIdData1.TotalBto
                                      ) : (
                                        <> 0</>
                                      )}
                                    </b>
                                  </p>
                                  <p>{t("SP")}  <b>
                                      {data.HorseModelIdData1 ? (
                                        data.HorseModelIdData1.TotalPrizeWin
                                      ) : (
                                        <> 0</>
                                      )}
                                    </b></p>
                                </div>
                                <div className="row uaestats">
                                  <div className="col-sm-2" >  <p >
                                    {t("UAECareer")}:{" "}
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1.UaeCareerCount}
                                    (
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1
                                        .UaeFirstRanking}{" "}
                                    -{" "}
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1
                                        .UaeSecondRanking}{" "}
                                    -{" "}
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1.UaeThirdRanking}
                                    )
                                  </p></div>
                                  <div className="col-sm-2"> <p>
                                    {t("Lifetime")}:{" "}
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1.LifeTimeCount}
                                    (
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1
                                        .LifeTimeFirstRanking}{" "}
                                    -{" "}
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1
                                        .LifeTimeSecondRanking}{" "}
                                    -{" "}
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1
                                        .LifeTimeThirdRanking}
                                    )
                                  </p></div>
                                  <div className="col-sm-2"> <p>
                                    {t("Turf")} :
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1.TurfCount}
                                    (
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1
                                        .TurfFirstRanking}{" "}
                                    -{" "}
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1
                                        .TurfSecondRanking}{" "}
                                    -{" "}
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1.TurfThirdRanking}
                                    )
                                  </p></div>
                                  <div className="col-sm-2"><p>
                                    {t("Dirt")} :{" "}
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1.DirtCount}
                                    (
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1
                                        .DirtFirstRanking}{" "}
                                    -{" "}
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1
                                        .DirtSecondRanking}{" "}
                                    -{" "}
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1.DirtThirdRanking}
                                    )
                                  </p></div>
                                  <div className="col-sm-2"> <p>
                                    {t("Dist")} :
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1.DistanceCount}
                                    (
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1
                                        .DistanceFirstRanking}{" "}
                                    -{" "}
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1
                                        .DistanceSecondRanking}{" "}
                                    -{" "}
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1
                                        .DistanceThirdRanking}
                                    ){" "}
                                  </p></div>
                                  <div className="col-sm-2">  <p>
                                    {t("AW")} :
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1.AllWeatherCount}
                                    (
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1
                                        .AllWeatherFirstRanking}{" "}
                                    -{" "}
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1
                                        .AllWeatherSecondRanking}{" "}
                                    -{" "}
                                    {data.HorseModelIdData1 &&
                                      data.HorseModelIdData1
                                        .AllWeatherThirdRanking}
                                    ){" "}
                                  </p></div>
                                  
                                
                                 
                                 
                                  
                                 
                                
                                </div>
                              </div>
                              {singlerace.racedetail.RaceStatus === "Live" &&
                              data.HorseRunningStatus == true &&
                              singlerace.racedetail &&
                              singlerace.CompetitionCategory === "pick" ? (
                                <>
                                  {data.HorseModelIdData1.Selection == null ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: "10px",
                                      }}
                                    >
                                      <button
                                        style={btnNew1}
                                        onClick={(event) =>
                                          pickClick(
                                            event,
                                            singlerace.racedetail
                                              .RaceDataOfCompetition[0]
                                              .Competition,
                                            data.HorseModelIdData1._id,
                                            singlerace?.votedhorse, 
                                            data?.HorseModelIdData1?.NameEn
                                          )
                                        }
                                        className="mobilec"
                                      >
                                        {t("Pick")}
                                      </button>
                                    </div>
                                  ) : (
                                    <button
                                      onClick={(event) =>
                                        DeletePick(
                                          event,
                                          data.HorseModelIdData1.Selection,
                                          data.HorseModelIdData1._id
                                        )
                                      }
                                      style={btnNewdelete}
                                      className="mobilec"
                                    >
                                      {t("Picked")}
                                    </button>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}

                              <CustomToggle eventKey={index}>
                                <>
                                  <button
                                    className="showMore"
                                    onClick={() =>
                                      showHorseHistory(
                                        data.HorseModelIdData1._id
                                      )
                                    }
                                  >
                                    {" "}
                                    {t("ShowHistory")}
                                  </button>
                                </>
                              </CustomToggle>
                            </Card.Header>
                              <Accordion.Collapse eventKey={index}>
                                <Card.Body>
                                  <div className="mycardclass1">
                                    <div className="BodyNew mpobile0">
                                      <table className="customers">
                                        <thead className="customers5">
                                          <tr>
                                            <th>{t("Date")}</th>
                                            <th>{t("Crs")}</th>
                                            <th>{t("Dist")}</th>
                                            <th>{t("TC")}</th>
                                            <th>{t("RT")}</th>

                                            <th>{t("Dts")}</th>
                                            <th>{t("Time")}</th>
                                            <th>{t("Jockey")}</th>
                                            <th>{t("Wgt")}</th>
                                            <th>{t("FP")}</th>
                                            <th>{t("Len")}</th>
                                            <th>{t("RN")}</th>
                                            <th>{t("BtBy")}</th>
                                            <th>{t("Kgs")}</th>

                                            <th>{t("Draw")}</th>
                                            <th>{t("Link")}</th>
                                          </tr>
                                        </thead>
                                        <tbody className="customers1">
                                          {History && History?.length === 0 ? (
                                            <NoHistory />
                                          ) : (
                                            <>
                                              {!historyData ? (
                                                <PleaseWait />
                                              ) : (
                                                History.map((item) => {
                                                  return (
                                                    <tr
                                                      className="BodyNew mpobile01 historyhorseth"
                                                      style={{
                                                        background: `${
                                                          item.RaceResultData
                                                            .RacehorsesData[0]
                                                            .RacehorsesData
                                                            .TrackLengthData
                                                            .GroundTypeModelData
                                                            .NameEn == "Dirt"
                                                            ? "#9F5109"
                                                            : item
                                                                .RaceResultData
                                                                .RacehorsesData[0]
                                                                .RacehorsesData
                                                                .TrackLengthData
                                                                .GroundTypeModelData
                                                                .NameEn ==
                                                              "Turf"
                                                            ? "#5ec40f"
                                                            : "#949494"
                                                        }`,
                                                      }}
                                                      key={i}
                                                    >
                                                      <td
                                                        className="lb"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                          handleShowRace(
                                                            item.RaceID
                                                          )
                                                        }
                                                      >
                                                        <Moment format="DD/MM/YYYY">
                                                          {item.RaceResultData ===
                                                          null ? (
                                                            <></>
                                                          ) : (
                                                            item.RaceResultData
                                                              .Day
                                                          )}
                                                        </Moment>
                                                      </td>
                                                      <td>
                                                        {item.RaceResultData
                                                          .RacehorsesData[0]
                                                          ? cookiedata === "en"
                                                            ? item
                                                                .RaceResultData
                                                                .RacehorsesData[0]
                                                                .RacehorsesData
                                                                .RaceCourseData
                                                                .AbbrevEn
                                                            : item
                                                                .RaceResultData
                                                                .RacehorsesData[0]
                                                                .RacehorsesData
                                                                .RaceCourseData
                                                                .AbbrevAr
                                                          : "-"}
                                                        (
                                                        {FirstLetter(
                                                          cookiedata === "en"
                                                            ? item
                                                                .RaceResultData
                                                                .RacehorsesData[0]
                                                                .RacehorsesData
                                                                .TrackLengthData
                                                                .GroundTypeModelData
                                                                .NameEn
                                                            : item
                                                                .RaceResultData
                                                                .RacehorsesData[0]
                                                                .RacehorsesData
                                                                .TrackLengthData
                                                                .GroundTypeModelData
                                                                .NameAr
                                                        )}
                                                        )
                                                      </td>
                                                      <td>
                                                      {
                                                          item.RaceResultData
                                                            .RacehorsesData[0]
                                                            .RacehorsesData
                                                            .TrackLengthData
                                                            .TrackLength
                                                        }
                                                      
                                                      </td>
                                                      <td>
                                                        {item.RaceResultData
                                                          .RacehorsesData
                                                          ? cookiedata === "en"
                                                            ? item
                                                                .RaceResultData
                                                                .RacehorsesData[0]
                                                                .RacehorsesData
                                                                .TrackConditionData
                                                                .NameEn
                                                            : item
                                                                .RaceResultData
                                                                .RacehorsesData[0]
                                                                .RacehorsesData
                                                                .TrackConditionData
                                                                .NameAr
                                                          : "_"}
                                                      </td>
                                                      <td>
                                                        <OverlayTrigger
                                                          className="tooltip-btn"
                                                          overlay={
                                                            <Tooltip
                                                              id="tooltip-disabled"
                                                              className="tooltip-btn"
                                                            >
                                                              {item
                                                                .RaceResultData
                                                                .RacehorsesData
                                                                ? cookiedata ===
                                                                  "en"
                                                                  ? item
                                                                      .RaceResultData
                                                                      .RacehorsesData[0]
                                                                      .RacehorsesData
                                                                      .RaceTypeModelData
                                                                      .NameEn
                                                                  : item
                                                                      .RaceResultData
                                                                      .RacehorsesData[0]
                                                                      .RacehorsesData
                                                                      .RaceTypeModelData
                                                                      .NameAr
                                                                : "_"}
                                                            </Tooltip>
                                                          }
                                                        >
                                                          <span
                                                            className="d-inline-block"
                                                            style={{
                                                              marginTop: "12px",
                                                            }}
                                                          >
                                                            <p className="tooltipclass1">
                                                              {" "}
                                                              {item
                                                                .RaceResultData
                                                                .RacehorsesData
                                                                ? cookiedata ===
                                                                  "en"
                                                                  ? item
                                                                      .RaceResultData
                                                                      .RacehorsesData[0]
                                                                      .RacehorsesData
                                                                      .RaceTypeModelData
                                                                      .NameEn
                                                                  : item
                                                                      .RaceResultData
                                                                      .RacehorsesData[0]
                                                                      .RacehorsesData
                                                                      .RaceTypeModelData
                                                                      .NameAr
                                                                : "_"}
                                                            </p>
                                                          </span>
                                                        </OverlayTrigger>
                                                      </td>
                                                      <td>
                                                        {item.PrizeWin
                                                          ? item.PrizeWin
                                                          : 0}
                                                      </td>
                                                      <td>{item.RaceTime}</td>
                                                      <td
                                                        onClick={() =>
                                                          handleShowJockey(
                                                            item.JockeyOnRaceDataResult
                                                              ? item.JockeyOnRaceDataResult
                                                              : ""
                                                          )
                                                        }
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                      >
                                                        {item.JockeyOnRaceDataResult
                                                          ? cookiedata === "en"
                                                            ? item
                                                                .JockeyOnRaceDataResult
                                                                .ShortNameEn
                                                            : item
                                                                .JockeyOnRaceDataResult
                                                                .ShortNameAr
                                                          : "_"}
                                                      </td>
                                                      <td>
                                                        {item.RaceResultData
                                                          .RacehorsesData
                                                          ? item.RaceResultData
                                                              .RacehorsesData[0]
                                                              .JockeyRaceWeight
                                                          : "_"}
                                                      </td>
                                                     
                                                      <td>
                                                        {item.FinalPositionDataHorse ===
                                                        null ? (
                                                          <></>
                                                        ) : (
                                                          <>
                                                            {cookiedata ===
                                                            "en" ? (
                                                              <>
                                                                {cookiedata ===
                                                                "en"
                                                                  ? item.FinalPositionDataHorse &&
                                                                    item
                                                                      ?.FinalPositionDataHorse
                                                                      ?.NameEn
                                                                  : item?.FinalPositionDataHorse &&
                                                                    item
                                                                      ?.FinalPositionDataHorse
                                                                      ?.NameEn}
                                                              </>
                                                            ) : (
                                                              <>
                                                                {
                                                                  item
                                                                    ?.FinalPositionDataHorse
                                                                    ?.NameEn
                                                                }
                                                              </>
                                                            )}
                                                          </>
                                                        )}
                                                      </td>
                                                      <td>
                                                      {distanceLabel(
                                                          item.Distance &&
                                                            item.Distance,
                                                          item.FinalPositionDataHorse &&
                                                            item
                                                              .FinalPositionDataHorse
                                                              ?.Rank
                                                        )}
                                                       
                                                      </td>
                                                      <td>{item.Runners}</td>
                                                     
                                                      <td
                                                        onClick={() =>
                                                          handleShow(
                                                            item.BeatenByData
                                                              ? item.BeatenByData
                                                              : ""
                                                          )
                                                        }
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                      >
                                                        {item.BeatenByData ===
                                                        null ? (
                                                          <>
                                                            {cookiedata === "en"
                                                              ? item.HorseIDData
                                                                  .HorseBeatenByEnglishName
                                                              : item.HorseIDData
                                                                  .HorseBeatenByArabicName}
                                                          </>
                                                        ) : (
                                                          <>
                                                            {cookiedata ===
                                                            "en" ? (
                                                              <>
                                                                {
                                                                  item
                                                                    .BeatenByData
                                                                    .NameEn
                                                                }
                                                              </>
                                                            ) : (
                                                              <>
                                                                {
                                                                  item
                                                                    .BeatenByData
                                                                    .NameAr
                                                                }
                                                              </>
                                                            )}
                                                          </>
                                                        )}
                                                      </td>{" "}
                                                      <td>
                                                        {item.BeatenByData !==
                                                        null
                                                          ? item.BeatenByData
                                                              .WinningHorseJockeyWeight
                                                          : item.HorseIDData
                                                              .BeatenByJockeyWeight ===
                                                            null
                                                          ? 0
                                                          : item.HorseIDData
                                                              .BeatenByJockeyWeight}
                                                      </td>
                                                      <td>
                                                        {item.RaceResultData
                                                          .RacehorsesData[0]
                                                          ? item.RaceResultData
                                                              .RacehorsesData[0]
                                                              ?.GateNo
                                                          : "-"}
                                                      </td>
                                                      <td
                                                        className="rb"
                                                        onClick={() =>
                                                          handleShows(
                                                            item.VideoLink
                                                          )
                                                        }
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                      >
                                                        <img
                                                          src={arrow1}
                                                          alt=""
                                                        />
                                                      </td>
                                                    </tr>
                                                  );
                                                })
                                              )}
                                            </>
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </Card.Body>
                              </Accordion.Collapse>
                              </div>
                              </div>
                            </Card>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </Accordion>
            </div>
          </div>

          <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header className="popupheader" closeButton></Modal.Header>
            <Modal.Body>
              <HorseDetail data={modaldata} />
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>

          <Modal
            show={showJockey}
            onHide={handleCloseJockey}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header className="popupheader" closeButton></Modal.Header>
            <Modal.Body>
              <JockeyDetail data={modaldataJockey} />
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>

          <Modal
            show={showOwner}
            onHide={handleCloseOwner}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header className="popupheader" closeButton></Modal.Header>
            <Modal.Body>
              <OwnerDetail data={modaldataOwner} />
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>

          <Modal
            show={showRace}
            onHide={handleCloseRace}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header className="popupheader" closeButton></Modal.Header>
            <Modal.Body>
              <RaceModal id={modaldataRace} />
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>

          <Modal
            show={showTrainer}
            onHide={handleCloseTrainer}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header className="popupheader" closeButton></Modal.Header>
            <Modal.Body>
              <TrainerDetail data={modaldataTrainer} />
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>

          <Modal
            show={shows}
            onHide={handleCloses}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter videomodal"
            centered
          >
            <Modal.Header className="popupheader" closeButton></Modal.Header>
            <Modal.Body>
              <VideoFrame data={modaldatas} />
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </div>
      ) : (
        <h2 className="RaceCardDetail">
          <Lottie animationData={Animate} loop={true} className="TableLottie" />
        </h2>
      )}
   </>
  );
};
export default CardData;
