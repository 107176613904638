import axios from "axios";
import Cookies from "js-cookie";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getNotificationOfUserSlice = createSlice({
    name: 'NotificationOfUser',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchNotificationOfUser.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchNotificationOfUser.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchNotificationOfUser.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setNotificationOfUser, setStatus } = getNotificationOfUserSlice.actions;
export default getNotificationOfUserSlice.reducer;

// const token = Cookies.get("token");

export const fetchNotificationOfUser = createAsyncThunk('/NotificationOfUserget/fetch', async ({token}) => {
    const res = await axios.get(`${window.env.API_URL}/GetNotificationOfUser`,{
        headers: { "Authorization": token, 'x-xsrf-token': token }
    },);
    const NotificationOfUserData = res.data;
    return NotificationOfUserData;
})
