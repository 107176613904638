import "../CSS/header.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { useState, useEffect } from "react";
import Fade from "react-reveal/Fade";
import Language from "../Home/Components/Language";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { fetchThemeSetting } from "../../redux/getReducer/getthemeSlice";
import { BsCaretDownFill } from "react-icons/bs";
import Dropdown from "react-bootstrap/Dropdown";
import Auth from "./Auth";

const Header = () => {
  const { t } = useTranslation();
  const cookiedata = Cookies.get("i18next");
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchThemeSetting());
  }, [dispatch]);

  const styles = {
    popup: {
      borderRadius: show ? "0px, 0px , 50px ,0px" : "none",
    },
  };
  return (
    <>
      <div className={!show ? "header themeclass" : "header1 themeclass"}>
        <div className="largemenu">
          <div className="row mainmenurow">
            <div className="col-sm-2">
              <Link to="/" className="mylogo">
                <img src={t("logo")} className="logoclassed" alt="img" />
              </Link>
            </div>

            <div className="col-sm-10 menus">
              <div className="menuNav">
                <Link to="/about" className="LinkStyle">
                  <p>{t("about")}</p>
                </Link>

                <div className="dropdown">
                  <p className="dropbtn dropdowntag">
                    {t("statistics")} <BsCaretDownFill />
                  </p>
                  <div className="dropdown-content">
                    <Link to="/atozreports">
                      <p>{t("AZReport")}</p>
                    </Link>
                    <Link to="/hotform">
                      <p>{t("Hot Form")}</p>
                    </Link>
                    <Link to="/querytool">
                      <p>{t("Querytool")}</p>
                    </Link>
                    <Link to="/trainerstatistics">
                      <p>{t("Trainer Statistics")}</p>
                    </Link>
                    <Link to="/ownerstatistics">
                      <p>{t("Owner Statistics")}</p>
                    </Link>
                    <Link to="/jockeystatistics">
                      <p>{t("Jockey Statistics")}</p>
                    </Link>
                    <Link to="/horsestatistics">
                      <p>{t("Horse Statistics")}</p>
                    </Link>
                    <Link to="/trainerjockeycombo">
                      <p>{t("Trainer Jockey Combo")}</p>
                    </Link>
                    <Link to="/SireSnippets">
                      <p>{t("Sire Snippets")}</p>
                    </Link>
                  </div>
                </div>

                <Link to="/racecards" className="LinkStyle">
                  <p>{t("race_card")}</p>
                </Link>

                <Link to="/result" className="LinkStyle">
                  <p>{t("result")}</p>
                </Link>

                <Link to="/racecourses" className="LinkStyle">
                  <p>{t("race_course")}</p>
                </Link>

                <Link to="/competition" className="LinkStyle">
                  <p>{t("competition")}</p>
                </Link>
                <a
                  href="https://silk.saddlebattles.com/"
                  className="LinkStyle"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p>{t("Owner Silk")}</p>
                </a>
                {/* <Link to="/sponsor" className="LinkStyle">
                  <p>{t("sponsors")}</p>
                </Link> */}
              </div>
            </div>
          </div>
        </div>

        {/* mobile */}
        <div className="mobilemenu">
          <div className="mobilemenudata">
            <Link to="/">
              <img src={t("logo")} className="logoclass" alt="img" />
            </Link>
            <FaBars onClick={() => setShow(!show)} className="fafabar" />
          </div>

          {/* mobile */}
          {show ? (
            <Fade top>
              {" "}
              <span className="mobiledropdown">
                <Link to="/about" className="LinkStyle">
                  <p onClick={() => setShow(!show)}>{t("about")}</p>
                </Link>
                <div className="dropdown LinkStyle">
                  <p className="dropbtn dropdowntag">
                    {t("statistics")} <BsCaretDownFill />
                  </p>
                  <div className="dropdown-content">
                    <Link to="/atozreports">
                      <p onClick={() => setShow(!show)}>{t("AZReport")}</p>
                    </Link>
                    <Link to="/hotform">
                      <p onClick={() => setShow(!show)}>{t("Hot Form")}</p>
                    </Link>
                    <Link to="/querytool">
                      <p onClick={() => setShow(!show)}>{t("Querytool")}</p>
                    </Link>
                    <Link to="/trainerstatistics">
                      <p onClick={() => setShow(!show)}>
                        {t("Trainer Statistics")}
                      </p>
                    </Link>
                    <Link to="/jockeystatistics">
                      <p onClick={() => setShow(!show)}>
                        {t("Jockey Statistics")}
                      </p>
                    </Link>
                    <Link to="/horsestatistics">
                      <p onClick={() => setShow(!show)}>
                        {t("Horse Statistics")}
                      </p>
                    </Link>
                    <Link to="/trainerjockeycombo">
                      <p onClick={() => setShow(!show)}>
                        {t("Trainer Jockey Combo")}
                      </p>
                    </Link>
                    <Link to="/SireSnippets">
                      <p onClick={() => setShow(!show)}>{t("Sire Snippets")}</p>
                    </Link>
                  </div>
                </div>
                <Link to="/racecards" className="LinkStyle">
                  <p onClick={() => setShow(!show)}>{t("race_card")}</p>
                </Link>
                <Link to="/result" className="LinkStyle">
                  <p onClick={() => setShow(!show)}>{t("result")}</p>
                </Link>
                <Link to="/racecourses" className="LinkStyle">
                  <p onClick={() => setShow(!show)}>{t("race_course")}</p>
                </Link>
                <Link to="/competition" className="LinkStyle">
                  <p onClick={() => setShow(!show)}>{t("competition")}</p>
                </Link>
                <a
                  href="https://silk.saddlebattles.com/"
                  className="LinkStyle"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p>{t("Owner Silk")}</p>
                </a>
                {/* <Link to="/sponsor" className="LinkStyle">
                  <p onClick={() => setShow(!show)}>{t("sponsors")}</p>
                </Link> */}
                <Link to="/login" className="LinkStyle">
                  <p onClick={() => setShow(!show)}>{t("Login")}</p>
                </Link>
              </span>
            </Fade>
          ) : null}
        </div>

        {/* mobile end */}
      </div>
    </>
  );
};
export default Header;
