import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});


const getjockeymodalstatsSlice = createSlice({
    name: 'jockeymodalstats',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchjockeymodalstats.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchjockeymodalstats.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchjockeymodalstats.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setTrainer, setStatus } = getjockeymodalstatsSlice.actions;
export default getjockeymodalstatsSlice.reducer;

export const fetchjockeymodalstats = createAsyncThunk('/HorsemodalForStatisitics/fetch', async ({ id }) => {
    const res = await axios.get(`${window.env.API_URL}/Jockeystatsmodal/${id}`);
    const trainerstatsData = res.data;
    return trainerstatsData;
})