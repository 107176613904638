import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getValidateUserSlice = createSlice({
    name: 'ValidateUser',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchValidateUser.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchValidateUser.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchValidateUser.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setValidateUser, setStatus } = getValidateUserSlice.actions;
export default getValidateUserSlice.reducer;

export const fetchValidateUser = createAsyncThunk('/ValidateUserget/fetch', async ({tokenverify}) => {
    const res = await axios.get(`${window.env.API_URL}/ValidateUser`,{
        headers: { "Authorization": tokenverify, 'x-xsrf-token': tokenverify }
    });
    const ValidateUserData = res.data;
    return ValidateUserData;
})
