import { useEffect ,useState } from "react";
import notificationIcon from "../../../assets/Notification.png";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useTranslation } from "react-i18next";
import Dropdown from "react-bootstrap/Dropdown";
import ListGroup from "react-bootstrap/ListGroup";
import logo from "../../../assets/Frame.png";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNotificationOfUser,
} from "../../../redux/getReducer/getNotificationUser";
import moment from 'moment';
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import socket from "../../../../Socket";

const Notification = () => {
  const { t } = useTranslation();
  const { userInfo } = useSelector((state) => state.user);
  const { data: NotificationOfUser, status } = useSelector((state) => state.NotificationOfUser);
  const [NotificationData, setNotificationData] = useState(NotificationOfUser);

  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const navigate = useNavigate();

  const cookietoken = Cookies.get("token");
  const cookiedata = Cookies.get("i18next");

  let token = userInfo ?( userInfo.token === undefined ? cookietoken : userInfo.token) : cookietoken

  useEffect(() =>{
    setNotificationData(NotificationOfUser)
  },[NotificationOfUser]);


  useEffect(() => {
    dispatch(fetchNotificationOfUser({token}));
      socket.on("NotificationUpdate", (data) => {
        setNotificationData(data);
        });
  }, [dispatch]);


  const [userIsDesktop, setUserIsDesktop] = useState(true);
  useEffect(() => {
    window.innerWidth > 840 ? setUserIsDesktop(true) : setUserIsDesktop(false);
  }, [userIsDesktop]);

 

  function HandleRedirect(route,id) {
    if (userIsDesktop === true) {
      navigate(`${route === null ? '' : route}`, {
        state: {
          id: id,
        },
      });
    } else {
      navigate(`${route === null ? '' : route}`, {
        state: {
          id: id,
        },
      });
    }
  }




  // if (status === STATUSES.LOADING) {
  //   return <Lottie animationData={Animate} className="load" />;
  // }
  // if (status === STATUSES.ERROR) {
  //   return (
  //     <h2
  //       style={{
  //         margin: "100px",
  //       }}
  //     >
  //       Something went wrong!
  //     </h2>
  //   );
  // }

  // const aaa = new Date().toISOString();

  return (
    <>
      <div className="notification">
        {userInfo === null ? (
          <></>
        ) : (
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic1">
            <span className="notification">
                  <img
                    src={notificationIcon}
                    alt=""
                    style={{
                      cursor: "pointer",
                    }}
                  />
                  <span class="badge">{NotificationData && NotificationData.NotificationCount ?  NotificationData.NotificationCount.NotViewedNotification : 0}</span>
                </span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="notificationmenu">
              <ListGroup>
                {
                NotificationData && NotificationData.data === [] ? <p>No Notification</p> :  NotificationData.data && NotificationData.data.map((item,index) => {
                    return(
                      <ListGroup.Item className="notificationpanel"  onClick={() => HandleRedirect(item.NotificationRoute, item.NotificationRouteid)}>
                      <img src={logo} alt="" className="notificationimg1" />
                      <div className="notifytext">
                      <h4>{cookiedata === "en" ? item.NotificationTitleEn :  item.NotificationTitleAr}</h4>
                        <p>{cookiedata === "en" ? item.NotificationTextEn :  item.NotificationTextAr}</p>
                      </div>
                      <p className="notifytime">
                      {moment(item.createdAt).fromNow()}
                      </p>
                    </ListGroup.Item>
                    )
                  })
                }
                <Link to='/notificationpage'>
                <h4 className="viewnotification">View All</h4>
                </Link>
               
                
              </ListGroup>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
    </>
  );
};
export default Notification;
