import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});


const getTrainerEntriesSlice = createSlice({
    name: 'entriestrainer',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchTrainerEntries.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchTrainerEntries.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchTrainerEntries.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setTrainer, setStatus } = getTrainerEntriesSlice.actions;
export default getTrainerEntriesSlice.reducer;

export const fetchTrainerEntries = createAsyncThunk('/FutureEntriesWithTrainer/fetch', async ({ id }) => {
    const res = await axios.get(`${window.env.API_URL}/FutureEntriesWithTrainer/${id}`);
    const OwnerEntriesData = res.data;
    return OwnerEntriesData.data;
})