import React from 'react'
import { useState , useEffect } from 'react';
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import {useTranslation} from 'react-i18next';

const ForgetPassword = ({data}) => {
    const [email, setEmail] = useState('')
    const history = useNavigate();
    const { t } = useTranslation()

    
  const [currentUrl, setCurrentUrl] = useState('');
  const [statusData, setstatusData] = useState(false);

  useEffect(() => {
    setCurrentUrl(window.location.origin);
  }, []);


    const submit = async (event) => {
        event.preventDefault();
        setstatusData(true)
        try {
          const formData = new FormData();
          formData.append("email", email);
          formData.append("ClientUrl", currentUrl);
         const res = await axios.post(`${window.env.API_URL}/password/forgot`, formData);
         const msg = res.data.message
         toast(msg)
         setstatusData(false)

         data();
          // history("/login");
        } catch (error) {
          const err = error.response.data.message;
          toast(err)
          setstatusData(false)

        }
      };
  return (
    <div>
      <form onSubmit={submit}>
      <div className="col-sm EmailForget">
      <div className='form forgetclass'>
      <label className='forgetmial'>{t("Enter Your Mail Here")}</label>
      <input
       required
       onChange={(e) => setEmail(e.target.value)}
       value={email}
       name="Email"
       type="email"
       placeholder={t('Email')}
      />
    </div>
                 
                  </div>

                  <button type="submit" disabled={statusData} className="buttonRegister">
                  {t('Submit')}
                 </button>
      </form>
    </div>
  )
}

export default ForgetPassword
