import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchRaceWithNumber } from "../../redux/getReducer/getRacewithNumber";
import { useState } from "react";
import { TiTick ,TiTickOutline} from 'react-icons/ti';
import Cookies from "js-cookie";

const RaceNumbers = ({ racecoursedata }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userIsDesktop, setUserIsDesktop] = useState(true);
  const token = Cookies.get("token")

  const { data: RacewithNumber } = useSelector((state) => state.RacewithNumber);

  useEffect(() => {
    window.innerWidth > 840 ? setUserIsDesktop(true) : setUserIsDesktop(false);
  }, [userIsDesktop]);

  const raceHandler = (id) => {
    if (userIsDesktop === true) {
      navigate("/racedetail", {
        state: {
          id: id,
        },
      });
    } else {
      navigate("/racedetails", {
        state: {
          id: id,
        },
      });
    }
  };

 
  console.log(RacewithNumber,"RacewithNumber")
  const racecourseid = racecoursedata? racecoursedata.RaceCourseData?._id : 1221
  const Timing = racecoursedata?.StartTime;


   
  let datecalender =new Date(Timing);

  datecalender.setHours(0, 0, 0);

  // Convert the updated Date object back to a string
  const convertedDate = datecalender.toString();

 let newDate =  new Date (convertedDate).toUTCString()


  useEffect(() => {
    dispatch(fetchRaceWithNumber({ racecourseid , newDate , token }));
  }, [dispatch, racecourseid]);

  return (
    <div className="racestatuscolor">
      {RacewithNumber ? (
        RacewithNumber.map((item, index) => {
          const { RaceStatus } = item;
          // const isTrue = racecoursedata.RaceNumber === item.RaceNumber ? true : false
          return (
            <>
           
              <li
                key={index}
                onClick={() => raceHandler(item._id)}
                style={{
                  backgroundColor: `${
                    RaceStatus === "Cancelled"
                      ? "#000000"
                      : RaceStatus === "Completed"
                      ? "#FF0000"
                      : RaceStatus === "Live"
                      ? "#5EC30F"
                      : "#FF9900"
                  }`,
                  color: `${
                    RaceStatus === "Cancelled"
                      ? "#ffff"
                      : RaceStatus === "Completed"
                      ? "#ffff"
                      : RaceStatus === "Live"
                      ? "#ffff"
                      : "#ffff"
                  }`,
                  height:`${
                    racecoursedata?.RaceNumber == item?.RaceNumber ? "42px" : "32px"
                  }`,
                  boxShadow:`${ racecoursedata?.RaceNumber == item?.RaceNumber ? "rgba(0, 0, 0, 0.35) 0px 5px 15px" : ""}`,
                  position:"relative"
                  
                }}
              >
                {/* && item.Voted == 0 ? <span className="checkvoted"><TiTickOutline /></span>: <span className="checkvoted"><TiTick /></span> */}
                 {
                 item.IsCompetitionRace > 0  ?(
                  item.Voted == 0 ? <span className="checkvoted"><TiTickOutline /></span>: <span className="checkvoted"><TiTick /></span>
                 ) :<></>
            }
                {item?.RaceNumber}
              </li>
            </> 
          );
        })
      ) : (
        <></>
      )}
    </div>
  );
};

export default RaceNumbers;
