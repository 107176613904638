import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getHotFormSlice = createSlice({
    name: 'HotForm',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchHotForm.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchHotForm.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchHotForm.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setHotForm, setStatus } = getHotFormSlice.actions;
export default getHotFormSlice.reducer;

export const fetchHotForm = createAsyncThunk('/HotFormget/fetch', async ({StartDate,
    EndDate,year,RaceCourseSearch,HorseKindSearch,TrackLengthSearch,RaceTypeSearch,RaceKindSearch,RaceGroundSearch,RaceNationalitySearch,currentPage,RaceDateSearch}) => {
    // console.log(currentPage,"currentPage")
    const res = await axios.get(`${window.env.API_URL}/HotForm?year=${
        year === undefined ? "" : year
      }&startTimeTo=${StartDate}&startTimeFrom=${EndDate}&RaceCourse=${RaceCourseSearch}&HorseKindinRace=${HorseKindSearch}&TrackLength=${TrackLengthSearch}&RaceType=${RaceTypeSearch}&RaceKind=${RaceKindSearch}&NationalityID=${RaceNationalitySearch}&Ground=${RaceGroundSearch}&Date=${RaceDateSearch}&page=${currentPage}`);
    const HotFormData = res.data;
    return HotFormData;
})
