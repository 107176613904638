import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});


const getownermodalstatsSlice = createSlice({
    name: 'ownermodalstats',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchownermodalstats.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchownermodalstats.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchownermodalstats.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setTrainer, setStatus } = getownermodalstatsSlice.actions;
export default getownermodalstatsSlice.reducer;

export const fetchownermodalstats = createAsyncThunk('/HorsemodalaccForStatisitics/fetch', async ({year, id }) => {
    const res = await axios.get(`${window.env.API_URL}/OwnerStatsModal/${id}?year=${year}`);
    const trainerstatsData = res.data;
    return trainerstatsData;
})