import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getLatestResultOfHorsesWithOwner = createSlice({
    name: 'LatestResultOfHorsesWithOwner',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchLatestResultOfHorsesWithOwner.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchLatestResultOfHorsesWithOwner.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchLatestResultOfHorsesWithOwner.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setOwner, setStatus } = getLatestResultOfHorsesWithOwner.actions;
export default getLatestResultOfHorsesWithOwner.reducer;



export const fetchLatestResultOfHorsesWithOwner = createAsyncThunk('/LatestResultOfHorsesWithOwner/fetch', async ({year, id }) => {
    const res = await axios.get(`${window.env.API_URL}/LatestResultOfHorsesWithOwner/${id}?year=${year}&size=400`);
    const OwnerData = res.data;
    return OwnerData.data;
})