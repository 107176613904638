import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getAtoZReportSlice = createSlice({
    name: 'AtoZReport',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchAtoZReport.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchAtoZReport.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchAtoZReport.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setAtoZReport, setStatus } = getAtoZReportSlice.actions;
export default getAtoZReportSlice.reducer;

export const fetchAtoZReport = createAsyncThunk('/AtoZReportget/fetch', async () => {
    const res = await axios.get(`${window.env.API_URL}/AtoZReport`);
    const AtoZReportData = res.data;
    return AtoZReportData.AllDetail;
})
