import React from 'react'
import RaceCourse from '../Components/RaceCourse/RaceCourse'
import "../../Webiste/Components/CSS/pagesCSS/RaceCourse.css"

const RacecoursePage = () => {
  return (
    <div><RaceCourse/></div>
  )
}

export default RacecoursePage