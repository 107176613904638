import { useEffect, useState } from "react";
import "../../CSS/HomeCSS/Competition.css";
import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchcompetition,
  STATUSES,
} from "../../../redux/getReducer/getCompetition";
import Animate from "../../../assets/loader.json";
import Lottie from "lottie-react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import noimage from "../../../assets/nocompetitionen.png";
import noimagear from "../../../assets/nocompetition.png";
import flag from "../../../assets/United Arab Emirates.png";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

import RaceSectionTimer from "../../Reuseable/RaceSectionTimer";

const Competition = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState(false);
  const { data: competition, status } = useSelector(
    (state) => state.competition
  );
  const [userIsDesktop, setUserIsDesktop] = useState(true);


  
  useEffect(() => {
    if (competition?.length !== 0) {
      console.log(competition,'sldr')
    }
    else{
      dispatch(fetchcompetition());
    }
  }, [dispatch]);


  useEffect(() => {
    window.innerWidth > 840 ? setUserIsDesktop(true) : setUserIsDesktop(false);
  }, [userIsDesktop]);
  if (status === STATUSES.LOADING) {
    return (
      <div>
        <Lottie
          animationData={Animate}
          loop={true}
          className="Lottie compLottie"
        />
      </div>
    );
  }

  if (status === STATUSES.ERROR) {
    return (
      <h2
        style={{
          margin: "100px",
        }}
      >
        Something went wrong!
      </h2>
    );
  }
  const cookiedata = Cookies.get("i18next");
  function HandleJockey(id, compId) {
    if (userIsDesktop === true) {
      navigate("/racedetail", {
        state: {
          id: id,
        },
      });
    } else {
      navigate("/racedetails", {
        state: {
          id: id,
        },
      });
    }
  }





  return (
    <>
      <div className="Competition">
        <div className="CompetitionHeader">
          <h2>{t("competitionHeading")}</h2>
          <img src={flag} alt="" />
        </div>
        <div className="CompetitionData">
          {competition?.length === 0 ? (
         <SkeletonTheme baseColor="#00A6A7" highlightColor="#9DD9DB">
         <p className="sk-class">
           <Skeleton count={3} />
         </p>
       </SkeletonTheme>
          ) : (
            competition.map((item) => {
              return item.CompetitionDataOfRace.length !== 0 ? (
                <Accordion defaultActiveKey="1">
                  <div className="Competitionitem" key={item._id}>
                    <Accordion.Item eventKey={item._id}>
                      <Accordion.Header className="AccordionHeader11">
                        <div className="AccordionHeader">
                         <div className="compickdisplay">
                         <p>
                            {cookiedata === "en" ? (
                              <>{item.NameEn}</>
                            ) : (
                              <>{item.NameAr}</>
                            )}
                            
                          
                          
                          </p>
                          <span>  </span>
                          <p className="compick"> {cookiedata === "en" ? <>   ({item.CompetitionCategory})</>:<>({item.CompetitionCategory})</>  }</p> 
                         </div>
                          <p>
                            {item.CompetitionDataOfRace.length}{" "}
                           { item.CompetitionDataOfRace.length === 1 ? <> {t('Race')}</> :  <>{t('Races')}</>  }
                          </p>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        {item.CompetitionDataOfRace.map((data, index) => {
                          return (
                            <div
                              className="Competition_Matches com_nnerdata"
                              onClick={() => HandleJockey(data.Race, item._id)}
                            >
                              <p className="tooltipclass">
                                {data.RaceDataOfCompetition == null ? (
                                  <></>
                                ) : (
                                  <>
                                    {cookiedata === "en" ? (
                                      !data.RaceDataOfCompetition
                                        .RaceNameModelData ? (
                                        <>- </>
                                      ) : (
                                        `Race - ${data.RaceDataOfCompetition.RaceNumber}  - ${data.RaceDataOfCompetition
                                          .RaceNameModelData.NameEn}`
                                      )
                                    ) : !data.RaceDataOfCompetition
                                        .RaceNameModelData ? (
                                      <>- </>
                                    ) : (
                                      `Race - ${data.RaceDataOfCompetition.RaceNumber}  - ${data.RaceDataOfCompetition
                                        .RaceNameModelData.NameAr}  `
                                    )}
                                  </>
                                )}
                              </p>

                              <RaceSectionTimer
                                timer={
                                  data.RaceDataOfCompetition == null
                                    ? ""
                                    : data.RaceDataOfCompetition.TimeRemaining
                                    ? data.RaceDataOfCompetition.TimeRemaining
                                    : "00:00:00"
                                }
                                starttime={
                                  data.RaceDataOfCompetition == null
                                    ? ""
                                    : data.RaceDataOfCompetition.StartTime
                                }
                                state={state}
                                setState={setState}
                              />
                            </div>
                          );
                        })}
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                </Accordion>
              ) : (
                 
                <SkeletonTheme baseColor="#00A6A7" highlightColor="#9DD9DB">
                <p className="skeleton-p">
                  <Skeleton count={3} />
                </p>
              </SkeletonTheme>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};
export default Competition;
