import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import {Link} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

import { fetchOwnerHorsesHistory } from "../../redux/getReducer/getOwnerHorsesHistory";
import Moment from "react-moment";
import PleaseWait from "../Reuseable/PleaseWait";
import arrow1 from "../../assets/image 13 (Traced).png";
import { fetchownerhorseStats } from "../../redux/getReducer/getOwnerHorses";
import VideoFrame from "../Modal/VideoFrame";
import JockeyDetail from "../Modal/JockeyModal";
import HorseModal from "../Modal/HorseModal";
import RaceModal from "../Modal/RaceModal";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import ScrollContainer from "react-indiana-drag-scroll";
import { Accordion, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Pagination from "../../pages/Pagination";


const Stats = ({ id }) => {
  const { data: ownerhorsestats } = useSelector((state) => state.ownerhorsestats);
  const { data: OwnerHorsesHistory } = useSelector((state) => state.OwnerHorsesHistory);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let totalcountdata = ownerhorsestats ? ownerhorsestats.totalcount : "";
  let totalpagesdata = ownerhorsestats ? ownerhorsestats.totalPages : "";
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(11);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);




  const [ShowJockey, setShowJockey] = useState(false);
  const [modaldatasJockey, setmodaldatasJockey] = useState();
  const handleJockeyCloses = () => setShowJockey(false);
  const handleShowJockey = async (data) => {
    setmodaldatasJockey(data);
    await setShowJockey(true);
  };

  const [showRace, setShowRace] = useState(false);
  const [modaldataRace, setmodaldataRace] = useState();
  const handleCloseRace = () => setShowRace(false);
  const handleShowRace = async (id) => {
    setmodaldataRace(id);
    await setShowRace(true);
  };

  const { t } = useTranslation();
  const cookiedata = Cookies.get("i18next");
  const [ShowHorse, setShowHorse] = useState(false);
  const [modaldatasHorse, setmodaldatasHorse] = useState();
  const handleHorseCloses = () => setShowHorse(false);
  const handleShowHorse = async (data) => {
    setmodaldatasHorse(data);
    await setShowHorse(true);
  };
  useEffect(() => {
    dispatch(fetchownerhorseStats({ id ,currentPage }));
  }, [currentPage,id]);
  const [shows, setShows] = useState(false);
  const [modaldatas, setmodaldatas] = useState();
  const handleCloses = () => setShows(false);
  const handleShows = async (data) => {
    setmodaldatas(data);
    await setShows(true);
  };

  const FirstLetter = (data) => {
    return data.substring(0, 1);
  }

  const handleTrainerRecord = (horseid) => {
    dispatch(fetchOwnerHorsesHistory({horseid , id }));
  };
  function distanceLabel(distance, rank) {
    const DistanceTobeChecked = parseFloat(distance);
    console.log(DistanceTobeChecked);
    if (DistanceTobeChecked.toFixed(2) > 25) {
      return "Dist";
    } else if (DistanceTobeChecked.toFixed(2) == 0.05 && rank < 80) {
      return "SHd";
    } else if (DistanceTobeChecked.toFixed(2) == 0.10 && rank < 80) {
      return "Hd";
    } else if (DistanceTobeChecked.toFixed(2) == 0.25 && rank < 80) {
      return "Nk";
    } else if (DistanceTobeChecked.toFixed(2) == 0.01 && rank < 80) {
      return "Ns";
    } else if (DistanceTobeChecked.toFixed(2) == 0.00 && rank < 80) {
      if(rank === 1){
        return 0;
      }
      return "Dh";
    } else {
      return distance;
    }
  }
  return (
    <>
       <div className="mycardclass1">
        <div className="formobilescroll1">
        <table className="statsTable innermodalstats mycss">
            <thead>
              <tr className="mycss1">
                <td>{t("Year")}</td>
                <td>{t("Runs")} </td>
                <td>{t("Wins")} </td>
                <td>{t("Second")}</td>
                <td>{t("Third")} </td>
                <td>{t("Fourth")} </td>
                <td>{t("Fifth")} </td>
                <td>{t("Earning")} </td>
                <td>{t("Wins")}% </td>
                <td></td>
              </tr>
            </thead>
          </table>
          <Accordion defaultActiveKey="0">

          {ownerhorsestats?.data &&
            ownerhorsestats?.data?.map((item, i) => (
              <>
                  <Accordion.Item
                    className="trainerstatsbtn1 data_border"
                    eventKey={i}
                    onClick={() => handleTrainerRecord(item._id)}
                  >
                    <div>
                      <Accordion.Header>
                        <table style={{ width: "100%" }}>
                          <thead>
                            <tr className="statsmodalhorse1">
                              <td>
                              { cookiedata === "en" ? item.NameEn : item.NameAr}
                              </td>
                              <td>{item?.Runs}</td>
                              <td>{item?.wins}</td>
                              <td>{item?.second}</td>
                              <td>{item?.third}</td>
                              <td>{item?.fourth}</td>
                              <td>{item?.fifth}</td>
                              <td>{item?.earning == null ? 0 : item?.earning}</td>
                              <td>{item?.winspercentage === null ? 0 : item?.winspercentage}</td>
                              <td>
                                <IoIosArrowDropdownCircle />
                              </td>
                            </tr>
                          </thead>
                        </table>{" "}
                      </Accordion.Header>
                      <Accordion.Body className="trainerstatsbtn1">
                        <div className="raceCardTable">
                          <ScrollContainer>
                            <div className="mycardclass1">
                              <div className="BodyNew mobiledatascroll">
                                <table className="customers">
                                  <thead className="customers5">
                                  <tr>
                                        <th>{t("Date")}</th>
                                        <th>{t("Crs")}</th>
                                        <th>{t("Dist")}</th>
                                        <th>{t("TC")}</th>
                                        <th>{t("RT")}</th>

                                        <th>{t("Dts")}</th>
                                        <th>{t("Time")}</th>
                                        <th>{t("Jockey")}</th>
                                        <th>{t("Wgt")}</th>
                                        <th>{t("FP")}</th>
                                        <th>{t("Len")}</th>
                                        <th>{t("RN")}</th>
                                        <th>{t("BtBy")}</th>
                                        <th>{t("Kgs")}</th>

                                        <th>{t("Draw")}</th>
                                        <th>{t("Link")}</th>
                                      </tr>
                                  </thead>
                                  <tbody className="customers1">
                                    {OwnerHorsesHistory === undefined ? (
                                      <PleaseWait />
                                    ) : (
                                      <>
                                        {!OwnerHorsesHistory ? (
                                          <PleaseWait />
                                        ) : (
                                          OwnerHorsesHistory?.map((item, i) => {
                                            return (
                                              <tr
                                                  className="BodyNew mpobile01 historyhorseth"
                                                  style={{
                                                    background: `${
                                                      item.RaceResultData
                                                        .RacehorsesData[0]
                                                        .RacehorsesData
                                                        .TrackLengthData
                                                        .GroundTypeModelData
                                                        .NameEn == "Dirt"
                                                        ? "#9F5109"
                                                        : item.RaceResultData
                                                            .RacehorsesData[0]
                                                            .RacehorsesData
                                                            .TrackLengthData
                                                            .GroundTypeModelData
                                                            .NameEn == "Turf"
                                                        ? "#5ec40f"
                                                        : "#949494"
                                                    }`,
                                                  }}
                                                  key={i}
                                                >
                                                  <td
                                                    className="lb"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      handleShowRace(
                                                        item.RaceID
                                                      )
                                                    }
                                                  >
                                                    <Moment format="DD/MM/YYYY">
                                                      {item.RaceResultData ===
                                                      null ? (
                                                        <></>
                                                      ) : (
                                                        item.RaceResultData?.Day
                                                      )}
                                                    </Moment>
                                                  </td>
                                                  <td>
                                                    {item.RaceResultData
                                                      .RacehorsesData[0]
                                                      ? cookiedata === "en"
                                                        ? item.RaceResultData
                                                            ?.RacehorsesData[0]
                                                            ?.RacehorsesData
                                                            ?.RaceCourseData
                                                            ?.AbbrevEn
                                                        : item.RaceResultData
                                                            ?.RacehorsesData[0]
                                                            ?.RacehorsesData
                                                            ?.RaceCourseData
                                                            ?.AbbrevAr
                                                      : "-"}
                                                    (
                                                    {FirstLetter(cookiedata === "en"
                                                      ? item.RaceResultData
                                                          ?.RacehorsesData[0]
                                                          ?.RacehorsesData
                                                          ?.TrackLengthData
                                                          ?.GroundTypeModelData
                                                          ?.NameEn
                                                      : item.RaceResultData
                                                          ?.RacehorsesData[0]
                                                          ?.RacehorsesData
                                                          ?.TrackLengthData
                                                          ?.GroundTypeModelData
                                                          ?.NameAr)}
                                                    )
                                                  </td>
                                                  <td>
                                                  {
                                                    distanceLabel(item.Distance &&
                                                      item.Distance , item.FinalPositionDataHorse &&
                                                      item.FinalPositionDataHorse?.Rank)
                                                  }
                                                 
                                                    
                                                  </td>
                                                  <td>
                                                    {item.RaceResultData
                                                      .RacehorsesData
                                                      ? cookiedata === "en"
                                                        ? item.RaceResultData
                                                            ?.RacehorsesData[0]
                                                            ?.RacehorsesData
                                                            ?.TrackConditionData
                                                            ?.NameEn
                                                        : item.RaceResultData
                                                            ?.RacehorsesData[0]
                                                            ?.RacehorsesData
                                                            ?.TrackConditionData
                                                            ?.NameAr
                                                      : "_"}
                                                  </td>
                                                  <td>
                                                    <OverlayTrigger
                                                      className="tooltip-btn"
                                                      overlay={
                                                        <Tooltip
                                                          id="tooltip-disabled"
                                                          className="tooltip-btn"
                                                        >
                                                          {item.RaceResultData
                                                            .RacehorsesData
                                                            ? cookiedata ===
                                                              "en"
                                                              ? item
                                                                  ?.RaceResultData
                                                                  ?.RacehorsesData[0]
                                                                  ?.RacehorsesData
                                                                  ?.RaceTypeModelData
                                                                  ?.NameEn
                                                              : item
                                                                  ?.RaceResultData
                                                                  ?.RacehorsesData[0]
                                                                  ?.RacehorsesData
                                                                  ?.RaceTypeModelData
                                                                  ?.NameAr
                                                            : "_"}
                                                        </Tooltip>
                                                      }
                                                    >
                                                      <span
                                                        className="d-inline-block"
                                                        style={{
                                                          marginTop: "12px",
                                                        }}
                                                      >
                                                        <p className="tooltipclass1">
                                                          {" "}
                                                          {item.RaceResultData
                                                            .RacehorsesData
                                                            ? cookiedata ===
                                                              "en"
                                                              ? item
                                                                  ?.RaceResultData
                                                                  ?.RacehorsesData[0]
                                                                  ?.RacehorsesData
                                                                  ?.RaceTypeModelData
                                                                  ?.NameEn
                                                              : item
                                                                  ?.RaceResultData
                                                                  ?.RacehorsesData[0]
                                                                  ?.RacehorsesData
                                                                  ?.RaceTypeModelData
                                                                  ?.NameAr
                                                            : "_"}
                                                        </p>
                                                      </span>
                                                    </OverlayTrigger>
                                                  </td>
                                                  <td>
                                                    {item.PrizeWin
                                                      ? item.PrizeWin
                                                      : 0}
                                                  </td>
                                                  <td>{item.RaceTime}</td>
                                                  <td
                                                    onClick={() =>
                                                      handleShowJockey(
                                                        item.JockeyOnRaceDataResult
                                                          ? item.JockeyOnRaceDataResult
                                                          : ""
                                                      )
                                                    }
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {item.JockeyOnRaceDataResult
                                                      ? cookiedata === "en"
                                                        ? item
                                                            .JockeyOnRaceDataResult
                                                            ?.ShortNameEn
                                                        : item
                                                            .JockeyOnRaceDataResult
                                                            ?.ShortNameAr
                                                      : "_"}
                                                  </td>
                                                  <td>
                                                    {item.RaceResultData
                                                      .RacehorsesData
                                                      ? item.RaceResultData
                                                          ?.RacehorsesData[0]
                                                          ?.JockeyRaceWeight
                                                      : "_"}
                                                  </td>
                                                  {/* <td>
                                                    {item.RaceResultData
                                                      .RacehorsesData
                                                      ? item.RaceResultData
                                                          .RacehorsesData[0]
                                                          .JockeyRaceWeight
                                                      : "_"}
                                                  </td> */}
                                                  <td>
                                                    {item.FinalPositionDataHorse ===
                                                    null ? (
                                                      <></>
                                                    ) : (
                                                      <>
                                                        {cookiedata === "en" ? (
                                                          <>
                                                            {cookiedata === "en"
                                                              ? item.FinalPositionDataHorse &&
                                                                item
                                                                  .FinalPositionDataHorse
                                                                  ?.NameEn
                                                              : item.FinalPositionDataHorse &&
                                                                item
                                                                  .FinalPositionDataHorse
                                                                  ?.NameAr}
                                                          </>
                                                        ) : (
                                                          <>
                                                            {
                                                              item
                                                                .FinalPositionDataHorse
                                                                .NameAr
                                                            }
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </td>
                                                  <td>
                                                  {
                                                      item.RaceResultData
                                                        ?.RacehorsesData[0]
                                                        ?.RacehorsesData
                                                        ?.TrackLengthData
                                                        ?.TrackLength
                                                    }
                                                  </td>
                                                  
                                                  <td>{item.Runners}</td>
                                                  {/* <td>6</td> */}
                                                  {/* <td>16.25</td> */}
                                                  <td
                                                    // onClick={() =>
                                                    //   handleShow(
                                                    //     item.BeatenByData
                                                    //       ? item.BeatenByData
                                                    //       : ""
                                                    //   )
                                                    // }
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {item.BeatenByData ===
                                                    null ? (
                                                      <>-</>
                                                    ) : (
                                                      <>
                                                        {cookiedata === "en" ? (
                                                          <>
                                                            {
                                                              item.BeatenByData
                                                                .NameEn
                                                            }
                                                          </>
                                                        ) : (
                                                          <>
                                                            {
                                                              item.BeatenByData
                                                                .NameAr
                                                            }
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </td>{" "}
                                                  <td>
                                                    {
                                                      item.BeatenByData ? item.BeatenByData?.WinningHorseJockeyWeight : <>0</>
                                                    }
                                                  </td>
                                                  <td>
                                                    {item.RaceResultData
                                                      .RacehorsesData[0]
                                                      ? item.RaceResultData
                                                          ?.RacehorsesData[0]
                                                          ?.HorseNo
                                                      : "-"}
                                                  </td>
                                                  <td
                                                    className="rb"
                                                    onClick={() =>
                                                      handleShows(
                                                        item.VideoLink
                                                      )
                                                    }
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <img src={arrow1} alt="" />
                                                  </td>
                                                </tr>
                                            );
                                          })
                                        )}
                                      </>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </ScrollContainer>
                        </div>
                      </Accordion.Body>
                    </div>
                  </Accordion.Item>
              </>
            ))}
            </Accordion>

            <Pagination
                postsPerPage={postsPerPage}
                totalPosts={totalcountdata}
                paginate={paginate}
                currentPage={currentPage}
                TotalPages={totalpagesdata}
              />
        </div>
      </div>
      <Modal
        show={shows}
        onHide={handleCloses}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <VideoFrame data={modaldatas} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        show={ShowJockey}
        onHide={handleJockeyCloses}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <JockeyDetail data={modaldatasJockey} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        show={ShowHorse}
        onHide={handleHorseCloses}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <HorseModal data={modaldatasHorse} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        show={showRace}
        onHide={handleCloseRace}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <RaceModal id={modaldataRace} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};
export default Stats;
