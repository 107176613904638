import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getQueryToolSlice = createSlice({
    name: 'QueryTool',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchQueryTool.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchQueryTool.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchQueryTool.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setQueryTool, setStatus } = getQueryToolSlice.actions;
export default getQueryToolSlice.reducer;

export const fetchQueryTool = createAsyncThunk('/QueryTool/fetch', async ({data1,data2,data3,data4,data5,data6,data7,data8,data9,data10,data11,data12,data13,data14,currentPage}) => {
    const res = await axios.get(`${window.env.API_URL}/QueryTool?StartTimeOfRace=${data1}&HorseID=${data2}&TrainerOnRace=${data3}&JockeyOnRace=${data4}&RaceCourseOfRace=${data5}&RaceCourseNationality=${data6}&RaceTypeOfRace=${data7}&RaceGround=${data8}&RaceTracklength=${data9}&OwnerId=${data10}&MeetingType=${data11}&Sponsor=${data12}&HorseKindinRace=${data13}&size=15&page=${currentPage}&date=${data14}`);
    const QueryToolData = res.data;
    return QueryToolData;
})
