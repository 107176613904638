//.....................Import..........................//
import React, { useEffect, useState } from "react";
import "../Components/CSS/RaceCardCSS/racedetail.css";
import Defaultimg from "../assets/Frame.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchsinglerace, STATUSES } from "../redux/getReducer/getSingleRace";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Cookies from "js-cookie";
import Zoom from "react-reveal/Zoom";
import img from "../assets/image 10.png";
import img1 from "../assets/image 10 (1).png";
import Summary from "../Components/RaceCard/Summary";
import Draw from "../Components/RaceCard/Draw";
import Predictor from "../Components/RaceCard/Predictor";
import TrackRecord1 from "../Components/RaceCard/TrackRecord";
import Moment from "react-moment";
import PrintOut from "../Components/RaceCard/Printout";
import { IoPartlySunnyOutline, IoCloudyOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import Animate from "../assets/loader.json";
import RaceNumbers from "../Components/Reuseable/RaceNumbers";
import arrow2 from "../assets/arrow1.png";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import CardData from "../Components/RaceCard/Card";
import flag from "../assets/United Arab Emirates.png";
import { Modal } from "react-bootstrap";
import VideoFrame from "../Components/Modal/VideoFrame";
import { fetchRacePredictor } from "../redux/getReducer/getRacePrediction";
import { fetchDraw } from "../redux/getReducer/getDraw";
import { fetchSummary } from "../redux/getReducer/getSummarySlice";
import { fetchTrackRecord } from "../redux/getReducer/TrackRecord";
import { useLocation, useParams } from "react-router-dom";

const Testform = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data: singlerace, status } = useSelector((state) => state.singlerace);
  const { data: RacePredictor } = useSelector((state) => state.RacePredictor);
  const { data: draw } = useSelector((state) => state.draw);
  const { data: summary } = useSelector((state) => state.summary);
  const { data: TrackRecord } = useSelector((state) => state.TrackRecord);

  const param = useParams()
  const id = param.id
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [modaldata, setmodaldata] = useState();
  const [user, setUser] = useState(location.state);
  const handleClose = () => setShow(false);
  const handleShow = async (data) => {
    setmodaldata(data);
    await setShow(true);
  };

  const [activeTab, setActiveTab] = useState("Card");

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
    if (tabIndex == "Predictor") {
      dispatch(fetchRacePredictor({ id }));
    } else if (tabIndex == "Draw") {
      dispatch(fetchDraw({ id }))
    }
    else if (tabIndex == "Summary") {
      dispatch(fetchSummary({ id }));
    }
    else if (tabIndex == "Track Record") {
      dispatch(fetchTrackRecord({ id }));
    }
    else if (tabIndex == "Printout") {

    }
  };



  useEffect(() => {
    dispatch(fetchsinglerace({ id }));

  }, []);

  if (status === STATUSES.ERROR) {
    return (
      <h2
        style={{
          margin: "100px",
          width: "50%",
        }}
      >
        Something went wrong!
      </h2>
    );
  }

  const cookiedata = Cookies.get("i18next");


  const DataDate = new Date(singlerace.StartTime && singlerace.StartTime === null ? "" : singlerace.StartTime);

  function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }
  const formatedData = formatAMPM(DataDate);

  let link = singlerace.RaceResultData
    ? singlerace.RaceResultData[0]
      ? singlerace.RaceResultData[0].VideoLink
      : "https://www.youtube.com/watch?v=nr3wCgKzSIA"
    : "https://www.youtube.com/watch?v=nr3wCgKzSIA";

  // let link = 'https://www.youtube.com/watch?v=nr3wCgKzSIA'



  return (
    <>
      <Zoom>
        <div className="RaceCardDetail" id="racedetail">
          {singlerace && singlerace.RaceCourseData !== undefined ? (
            <div className="RaceDetailHeader">
              <div>
                <div className="colorheader">
                  <div>
                    <span className="racenameflex">
                      <p>
                        {cookiedata === "en" ? (
                          singlerace.RaceCourseData === undefined ? (
                            <>- </>
                          ) : (
                            singlerace.RaceCourseData.TrackNameEn
                          )
                        ) : singlerace.RaceCourseData === undefined ? (
                          <>- </>
                        ) : (
                          singlerace.RaceCourseData.TrackNameAr
                        )}
                      </p>

                      {
                        singlerace.RaceCourseData === null ? <></>
                          :
                          singlerace.RaceCourseData &&
                          <span
                            className="racecardcolor1"
                            style={{
                              background: `${singlerace.RaceCourseData.Color ? singlerace.RaceCourseData.Color : "#fff"}`,
                            }}
                          ></span>
                      }
                      {singlerace.RaceCourseData ? (
                        <img
                          src={
                            singlerace.RaceCourseData.image
                              ? singlerace.RaceCourseData.image
                              : flag
                          }
                          alt=""
                        />
                      ) : (
                        <img src={flag} alt="" />
                      )}
                    </span>
                    <p className="itemtime">
                      <Moment format="D MMM YYYY" withTitle>
                        {singlerace.Day}
                      </Moment>
                    </p>
                    {/* <p className="itemtime"> 
                <Moment filter={toUpperCaseFilter}> {singlerace.DayNTime}
                      </Moment>  </p> */}
                  </div>
                  <RaceNumbers
                    racecourseid={
                      singlerace.RaceCourseData === undefined
                        ? ""
                        : singlerace.RaceCourseData._id
                    }
                  />
                </div>
                <div className="racedisc">
                  <div className="itemraces">
                    <div className="inner_itemraces">
                      <span className="itemraces_left">
                        <span
                          className="race"
                          style={{
                            backgroundColor: `${singlerace.RaceStatus === "Cancelled"
                              ? "#000000"
                              : singlerace.RaceStatus === "Completed"
                                ? "#FF0000"
                                : singlerace.RaceStatus === "Live"
                                  ? "#5EC30F"
                                  : "#FF9900"
                              }`,
                            color: `${singlerace.RaceStatus === "Cancelled"
                              ? "#ffff"
                              : singlerace.RaceStatus === "Completed"
                                ? "#00000"
                                : singlerace.RaceStatus === "Live"
                                  ? "#00000"
                                  : "#000000"
                              }`,
                          }}
                        >
                          <p>
                            {t("Race")} {singlerace.RaceNumber}
                          </p>
                          <p>
                            {formatedData}
                            {/* {" "}
                        {new Date(singlerace.StartTime)
                          .toLocaleTimeString()
                          .slice(0, 4)                      
                          } */}
                            {/* m */}
                          </p>
                        </span>
                        {singlerace.SponsorData ? (
                          <>
                            <a
                              href={
                                singlerace.SponsorData ? (
                                  singlerace.SponsorData.Url
                                ) : (
                                  <></>
                                )
                              }
                              target="_blank" rel="noreferrer"
                            >
                              <img
                                className="sponsor"
                                src={
                                  singlerace.SponsorData.image === null
                                    ? Defaultimg
                                    : singlerace.SponsorData.image
                                }
                                alt=""
                              />
                            </a>
                          </>
                        ) : (
                          <></>
                        )}
                      </span>
                      <span className="itemraces_center">
                        <OverlayTrigger
                          className="tooltip-btn"
                          overlay={
                            <Tooltip
                              id="tooltip-disabled"
                              className="tooltip-btn"
                            >
                              {cookiedata === "en" ? (
                                singlerace.RaceNameModelData === undefined ? (
                                  <>- </>
                                ) : (
                                  singlerace.RaceNameModelData.NameEn
                                )
                              ) : singlerace.RaceNameModelData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.RaceNameModelData.NameAr
                              )}
                            </Tooltip>
                          }
                        >
                          <span className="d-inline-block">
                            <h5 className="tooltipclass">
                              {" "}
                              {cookiedata === "en" ? (
                                singlerace.RaceNameModelData === undefined ? (
                                  <>- </>
                                ) : (
                                  singlerace.RaceNameModelData.NameEn
                                )
                              ) : singlerace.RaceNameModelData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.RaceNameModelData.NameAr
                              )}
                            </h5>
                          </span>
                        </OverlayTrigger>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            // width: "60%",
                          }}
                        >
                          <p
                            style={{
                              padding: "5px",
                            }}
                          >
                            {cookiedata === "en" ? (
                              singlerace.RaceKindData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.RaceKindData.NameEn
                              )
                            ) : singlerace.RaceKindData === undefined ? (
                              <>- </>
                            ) : (
                              singlerace.RaceKindData.NameAr
                            )}
                          </p>

                          <p
                            style={{
                              padding: "5px 12px",
                            }}
                          >
                            {cookiedata === "en" ? (
                              singlerace.RaceTypeModelData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.RaceTypeModelData.NameEn
                              )
                            ) : singlerace.RaceTypeModelData === undefined ? (
                              <>- </>
                            ) : (
                              singlerace.RaceTypeModelData.NameAr
                            )}
                          </p>
                        </div>
                        <p
                          style={{
                            padding: "5px",
                            marginTop: "-20px",
                          }}
                        >
                          {cookiedata === "en" ? (
                            singlerace.HorseKindinRaceData === undefined ? (
                              <>- </>
                            ) : (
                              singlerace.HorseKindinRaceData.NameEn
                            )
                          ) : singlerace.HorseKindinRaceData === undefined ? (
                            <>- </>
                          ) : (
                            singlerace.HorseKindinRaceData.NameAr
                          )}
                        </p>
                      </span>
                      <span className="itemraces_right">
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            margin: "15px",
                          }}
                        >
                          {singlerace.WeatherType === "Sunny" ? (
                            <IoPartlySunnyOutline className="weatherIcon" />
                          ) : (
                            <IoCloudyOutline className="weatherIcon" />
                          )}
                          {/* <img src={weather} alt="" /> */}
                        </p>
                        <span className="distance">
                          <img
                            src={
                              singlerace.TrackLengthData &&
                              singlerace.TrackLengthData.RaceCourseImage
                            }
                            alt=""
                          />
                        </span>
                        <div className="Favourite">
                          <div>
                            <p>{t("Favourite")}</p>
                            <br />
                            {/* <div
                          style={{
                            display: "flex",
                            width:'100px',
                            justifyContent:'space-between'
                          }}
                        >
                          <p>Notre Dame</p>
                          <img src={arrow} alt="" className="videoicon" />
                        </div>
                        <p>4.40</p> */}
                          </div>
                        </div>
                      </span>
                    </div>
                    {singlerace.RaceStatus === "Completed" ? (
                      <div className="resultimages">
                        <span className="resultimagesRunningTime">
                          <p>{t("RunningTime")}</p>
                          <h4>
                            {singlerace.RaceResultData.length !== 0
                              ? singlerace.RaceResultData[0].RaceTime
                              : "00:00:00"}
                          </h4>
                        </span>

                        {singlerace.RaceimagesData.length !== 0 ? (
                          singlerace.RaceimagesData.slice(0, 4).map((data) => {
                            return (
                              <span className="resultimagebox">
                                <img src={data.image} alt="" />
                              </span>
                            );
                          })
                        ) : (
                          <>
                            <span className="resultimagebox"></span>
                            <span className="resultimagebox"></span>
                            <span className="resultimagebox"></span>
                            <span className="resultimagebox"></span>
                          </>
                        )}

                        <span
                          className="resultimagevideo"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => handleShow(link)}
                        >
                          <img src={arrow2} alt="img" />

                          <p>{t("WatchNow")}</p>
                        </span>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="hosekindSection"></div>
                    <p>
                      <b>{t("Description")}</b> :
                      {cookiedata === "en" ? (
                        <>{singlerace.DescriptionEn}</>
                      ) : (
                        <>{singlerace.DescriptionAr}</>
                      )}
                    </p>
                  </div>
                </div>
                <div className="prizecard">
                  <div className="prizecardheaders">
                    <p>
                      {t("Total Prize")}:
                      <b className="mx-1">
                        {singlerace.FirstPrice +
                          singlerace.SecondPrice +
                          singlerace.ThirdPrice +
                          singlerace.FourthPrice +
                          singlerace.FifthPrice +
                          singlerace.SixthPrice}

                        {cookiedata === "en" ? (
                          <>
                            {singlerace.CurrencyData === undefined ? (
                              <>- </>
                            ) : (
                              singlerace.CurrencyData.NameEn
                            )}
                          </>
                        ) : (
                          <>
                            {singlerace.CurrencyData === undefined ? (
                              <>- </>
                            ) : (
                              singlerace.CurrencyData.NameAr
                            )}
                          </>
                        )}
                      </b>
                    </p>
                  </div>
                  <div className="Competitiontrophy">
                    <div className="Trophydata">
                      <span>{t("1st")}</span>
                      <span>
                        <img src={img1} alt="" />
                      </span>
                      <div className="Trophydata_P">
                        <h6>
                          {singlerace.FirstPrice}{" "}
                          {cookiedata === "en" ? (
                            <>
                              {singlerace.CurrencyData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.CurrencyData.NameEn
                              )}
                            </>
                          ) : (
                            <>
                              {singlerace.CurrencyData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.CurrencyData.NameAr
                              )}
                            </>
                          )}
                        </h6>
                      </div>
                    </div>
                    <div className="Trophydata">
                      <span>{t("2nd")}</span>
                      <span>
                        <img src={img} alt="" />
                      </span>
                      <div className="Trophydata_P">
                        <h6>
                          {singlerace.SecondPrice}{" "}
                          {cookiedata === "en" ? (
                            <>
                              {singlerace.CurrencyData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.CurrencyData.NameEn
                              )}
                            </>
                          ) : (
                            <>
                              {singlerace.CurrencyData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.CurrencyData.NameAr
                              )}
                            </>
                          )}
                        </h6>
                      </div>
                    </div>
                    <div className="Trophydata">
                      <span>{t("3rd")}</span>
                      <span>
                        <img src={img} alt="" />
                      </span>
                      <div className="Trophydata_P">
                        <h6>
                          {singlerace.ThirdPrice}{" "}
                          {cookiedata === "en" ? (
                            <>
                              {singlerace.CurrencyData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.CurrencyData.NameEn
                              )}
                            </>
                          ) : (
                            <>
                              {singlerace.CurrencyData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.CurrencyData.NameAr
                              )}
                            </>
                          )}
                        </h6>
                      </div>
                    </div>
                    <div className="Trophydata">
                      <span>{t("4th")}</span>
                      <span>
                        <img src={img} alt="" />
                      </span>
                      <div className="Trophydata_P">
                        <h6>
                          {singlerace.FourthPrice}{" "}
                          {cookiedata === "en" ? (
                            <>
                              {singlerace.CurrencyData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.CurrencyData.NameEn
                              )}
                            </>
                          ) : (
                            <>
                              {singlerace.CurrencyData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.CurrencyData.NameAr
                              )}
                            </>
                          )}
                        </h6>
                      </div>
                    </div>
                    <div className="Trophydata">
                      <span>{t("5th")}</span>
                      <span>
                        <img src={img} alt="" />
                      </span>
                      <div className="Trophydata_P">
                        <h6>
                          {singlerace.FifthPrice}{" "}
                          {cookiedata === "en" ? (
                            <>
                              {singlerace.CurrencyData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.CurrencyData.NameEn
                              )}
                            </>
                          ) : (
                            <>
                              {singlerace.CurrencyData === undefined ? (
                                <>- </>
                              ) : (
                                singlerace.CurrencyData.NameAr
                              )}
                            </>
                          )}
                        </h6>
                      </div>
                    </div>
                    {singlerace.SixthPrice === 0 ? (
                      <></>
                    ) : (
                      <div className="Trophydata">
                        <span>{t("6th")}</span>
                        <span>
                          <img src={img} alt="" />
                        </span>
                        <div className="Trophydata_P">
                          <h6>
                            {singlerace.SixthPrice}{" "}
                            {cookiedata === "en" ? (
                              <>
                                {singlerace.CurrencyData === undefined ? (
                                  <>- </>
                                ) : (
                                  singlerace.CurrencyData.NameEn
                                )}
                              </>
                            ) : (
                              <>
                                {singlerace.CurrencyData === undefined ? (
                                  <>- </>
                                ) : (
                                  singlerace.CurrencyData.NameAr
                                )}
                              </>
                            )}
                          </h6>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="RaceNav">
                  {/* <Tabs
                    activeKey={activeTab} onSelect={handleTabChange}
                    defaultActiveKey="Card"
                    id="uncontrolled-tab-example"
                    className="mb-3 RaceNavItem"
                  >
                    <Tab
                      eventKey="Card"
                      title={t("Card")}
                      tabClassName="profile-tabitem"
                    >
                      <CardData />
                    </Tab>
                    <Tab
                      eventKey="Predictor"
                      title={t("Predictor")}
                      tabClassName="profile-tabitem"
                    >
                      <div className="RaceDetailCard">
                        <Predictor RacePredictor={RacePredictor} />
                      </div>
                    </Tab>

                    <Tab
                      eventKey="Draw"
                      title={t("Draw")}
                      tabClassName="profile-tabitem"
                    >
                      <div className="RaceDetailCard">
                        <Draw draw={draw} />
                      </div>
                    </Tab>
                    <Tab
                      eventKey="Summary"
                      title={t("Summary")}
                      tabClassName="profile-tabitem"
                    >
                      <div className="RaceDetailCard">
                        <Summary summary={summary} />
                      </div>
                    </Tab>
                    <Tab
                      eventKey="Track Record"
                      title={t("TrackRecord")}
                      tabClassName="profile-tabitem"
                    >
                      <div className="RaceDetailCard">
                        <TrackRecord1 TrackRecord={TrackRecord} />
                      </div>
                    </Tab>
                    <Tab
                      eventKey="Printout"
                      title={t("Printout")}
                      tabClassName="profile-tabitem"
                    >
                      <PrintOut />
                    </Tab>
                  </Tabs> */}
                  <div></div>
                </div>
              </div>
            </div>
          ) : (
            <div className="NAclass RaceCardDetail">
              <Lottie
                animationData={Animate}
                className="Lottie compLottie"
              />
            </div>
          )}
        </div>
      </Zoom >
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <VideoFrame data={modaldata} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default Testform;
