import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCalenderDetail,
  STATUSES,
} from "../../../redux/getReducer/getRaceCalenderDetail";

import { useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import flagimg from "../../../assets/United Arab Emirates.png";
import Accordion from "react-bootstrap/Accordion";
import Cookies from "js-cookie";
import {
  getPreviousMonthStart,
  getPreviousYearStart,
  getPreviousDecadeStart,
  getPreviousCenturyStart,
  getNextMonthStart,
  getNextYearStart,
  getNextDecadeStart,
  getNextCenturyStart,
  getDecadeEnd,
  getCenturyEnd,
} from "@wojtekmaj/date-utils";
import { startOfMonth } from "date-fns";
import { date } from "date-arithmetic";
import RaceSectionTimer from "../../Reuseable/RaceSectionTimer";
import { useTranslation } from "react-i18next";
import moment from "moment";
const CalenderComponent = ({ CalenderData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cookiedata = Cookies.get("i18next");
  const now = new Date();
  const startOfCurrentMonth = startOfMonth(now);
  const { t } = useTranslation();

  const [btnData, setbtnData] = useState(false);

  const formatMonthYear = new Intl.DateTimeFormat(undefined, {
    month: "long",
    year: "numeric",
  }).format;

  const formatYear = new Intl.DateTimeFormat(undefined, {
    year: "numeric",
  }).format;

  const [activeStartDate, setActiveStartDate] = useState(startOfCurrentMonth);
  const [view, setView] = useState("month");

  const prevPeriod = (() => {
    switch (view) {
      case "month":
        return getPreviousMonthStart(activeStartDate);
      case "year":
        return getPreviousYearStart(activeStartDate);
      case "decade":
        return getPreviousDecadeStart(activeStartDate);
      case "century":
        return getPreviousCenturyStart(activeStartDate);
      default:
    }
  })();

  const nextPeriod = (() => {
    switch (view) {
      case "month":
        return getNextMonthStart(activeStartDate);
      case "year":
        return getNextYearStart(activeStartDate);
      case "decade":
        return getNextDecadeStart(activeStartDate);
      case "century":
        return getNextCenturyStart(activeStartDate);
      default:
    }
  })();

  function getLabel(date) {
    switch (view) {
      case "month":
        return formatMonthYear(date);
      case "year":
        return formatYear(date);
      case "decade":
        return formatYear(date) + " – " + formatYear(getDecadeEnd(date));
      case "century":
        return formatYear(date) + " – " + formatYear(getCenturyEnd(date));
      default:
    }
  }

  const handleDateClick = () => {
    // Scroll down to a specific y-coordinate (adjust as needed)
    window.scrollTo({ top: 150, behavior: 'smooth' });
  };


  const prevLabel = getLabel(prevPeriod);
  const nextLabel = getLabel(nextPeriod);

  const aaaaa = new Date(prevLabel).getMonth();

  const monthnumber = aaaaa - 2;

  function onActiveStartDateOrViewChange({
    activeStartDate: nextActiveStartDate,
    view: nextView,
  }) {
    setActiveStartDate(nextActiveStartDate);
    setView(nextView);
  }

  const { data: CalenderDetail } = useSelector((state) => state.CalenderDetail);

  const [userIsDesktop, setUserIsDesktop] = useState(true);

  const [state, setState] = useState(false);
  useEffect(() => {
    window.innerWidth > 840 ? setUserIsDesktop(true) : setUserIsDesktop(false);
  }, [userIsDesktop]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [FormatDate, setFormatDate] = useState("");




  const FormatedDateArray = CalenderData && CalenderData?.map((item,i) => {
    return{
      RaceCourseData:item?.RaceCourseData,
      start:moment(item.start).local().format('YYYY-MM-DD HH:mm:ss')
    }
  })

  const formattedDates = CalenderData.map((dateObj) => {
    const inputDateString = dateObj.start;
    const inputDate = new Date(inputDateString);

    const options = {
      weekday: 'short',
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'long',
      timeZoneOffset: 'numeric',
    };

    return {
      start: new Intl.DateTimeFormat('en-US', options).format(inputDate)
    };
  });



  const eventList = FormatedDateArray.map((item) => {
    return {
      date: new Date(item.start),
      datetimedata: new Date(item.end),
      title: item.RaceCourse,
      racename: item.RaceNameModelData ? item.RaceNameModelData?.title : <></>,
      racecoursecolor: item.RaceCourseData ? item.RaceCourseData?.ColorCodeData?.Color : <></>,
      racecoursename: item.RaceCourseData ? (
        item.RaceCourseData.TrackNameEn
      ) : (
        <></>
      ),
    };
  });


  const getEventsForDate = (date) => {
    
    return eventList.filter(
      (event) => event.date.toDateString() === date.toDateString()
    );
  };


  const tileContent = ({ date }) => {
    const events = getEventsForDate(date);
    if (events.length > 0) {
      return (
        <button
          className="eventdata1"
          style={{
            backgroundColor: events[0].racecoursecolor,
          }}
        ></button>
      );
    }
    return null;
  };



  const tileClassName = ({ date }) => {
    const events = getEventsForDate(date);
    if (events.length > 0) {
      return "bgcolor";
    }
    return "";
  };




  function formatAMPM(date) {
    const DataDate = new Date(date);
    var hours = DataDate.getHours();
    var minutes = DataDate.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  const eventListForSelectedDate = getEventsForDate(selectedDate);
  const onDateChange = (date) => {
    setSelectedDate(date);
    setFormatDate(date);

  };


  const localDate = new Date(selectedDate);
  const utcDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000);
  const start = selectedDate;

  useEffect(() => {
    dispatch(fetchCalenderDetail({ start }));
  }, [dispatch, start]);


  let datacheck = 1;

  function handleAddEvent(allEvents) {
    if (userIsDesktop === true) {
      navigate("/mkscard", {
        state: {
          cardid: allEvents,
        },
      });
    } else {
      navigate("/mkscards", {
        state: {
          cardid: allEvents,
        },
      });
    }
  }

  function HandleJockey(id) {
    if (userIsDesktop === true) {
      navigate("/racedetail", {
        state: {
          id: id,
        },
      });
    } else {
      navigate("/racedetails", {
        state: {
          id: id,
        },
      });
    }
  }

// drop down scroll

  return (
    <div>
      <Calendar
        value={selectedDate}
        onChange={onDateChange}
        tileContent={tileContent}
        onActiveStartDateChange={onActiveStartDateOrViewChange}
        onViewChange={onActiveStartDateOrViewChange}
        tileClassName={tileClassName}
        onClickDay={handleDateClick}
      />
      <div>
        <h2 className="eventrace">On {selectedDate.toDateString('ar', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
})}</h2>
        {CalenderDetail.length > 0 ? (
          <>
            {/* <p className="eventracedata">{CalenderDetail.length} {t("Race")}</p> */}
            <div className="Currentpostdiv">
              <div className="Currentpostheader">
                <h2>{t("UAE")}</h2>
                <img
                  src={flagimg}
                  alt=""
                  style={{
                    width: "50px",
                    heigth: "50px",
                  }}
                />
              </div>
              {CalenderDetail.map((item) => {
                return (
                  <div className="CompetitionData">
                    <Accordion className="acciem12">
                      <div className="Competitionitem">
                        <Accordion.Item eventKey={111} className="acciem12">
                          <Accordion.Header onClick={() => handleAddEvent(item)} className="acciem12">
                            <div className="AccordionHeader">
                              <p
                                className="eventracedata1"

                              >
                                {cookiedata === "en" ? item.TrackNameEn : item.TrackNameAr}
                              </p>
                              <p className="eventracedata1 eventracecalender">
                                {/* {formatAMPM(item.datetimedata)} */}
                              </p>
                            </div>
                            <span
                              className="raceroursecolor"
                              style={{
                                background: `${item.ColorCodeData?.Color}`,
                              }}
                            ></span>
                          </Accordion.Header>

                        </Accordion.Item>
                      </div>
                    </Accordion>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <p className="eventracedata">{t("No Race For This Date")}</p>
        )}
      </div>
    </div>
  );
};

export default CalenderComponent;
