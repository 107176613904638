import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { useTranslation } from 'react-i18next';

import { ComponentToPrint } from './ComponentToPrint';

const Example = () => {
  const componentRef = useRef();
  const { t } = useTranslation();

  return (
    <div>
      <ReactToPrint
        trigger={() => <button className="PrintOutClass">{t("Printout")}</button>}
        content={() => componentRef.current}
      />
      <ComponentToPrint ref={componentRef} />
    </div>
  );
};

export default Example