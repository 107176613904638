import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getHorseKindDropDownSlice = createSlice({
    name: 'HorseKindDropDown',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchHorseKindDropDown.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchHorseKindDropDown.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchHorseKindDropDown.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setHorseKindDropDown, setStatus } = getHorseKindDropDownSlice.actions;
export default getHorseKindDropDownSlice.reducer;

export const fetchHorseKindDropDown = createAsyncThunk('/HorseKindDropDown/fetch', async () => {
    const res = await axios.get(`${window.env.API_URL}/HorseKindDropDown`);
    const HorseKindDropDownData = res.data;
    return HorseKindDropDownData.data;
})
