import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getThemeSettingSlice = createSlice({
    name: 'ThemeSetting',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchThemeSetting.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchThemeSetting.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchThemeSetting.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});
const ThemeData =  {
    PrimaryColor:"#292D76",
    SecondaryColor:"#2086BA",
    ThirdColor:"#9DD9DB",
    FourthColor:"#00A6A7",
    FifthColor:"#121945"
  }


  document.documentElement.style.setProperty(
    "--primary-color",
    ThemeData.PrimaryColor
  );
  document.documentElement.style.setProperty(
    "--secondary-color",
    ThemeData.SecondaryColor
  );
  document.documentElement.style.setProperty(
    "--third-color",
    ThemeData.ThirdColor
  );
  document.documentElement.style.setProperty(
    "--fourth-color",
    ThemeData.FourthColor
  );

  document.documentElement.style.setProperty(
    "--fifth-color",
    ThemeData.FifthColor
  );

export const { setThemeSetting, setStatus } = getThemeSettingSlice.actions;
export default getThemeSettingSlice.reducer;

export const fetchThemeSetting = createAsyncThunk('/ThemeSettingget/fetch', async () => {
    const res = await axios.get(`${window.env.API_URL}/SingleThemeToFollow`);
    const ThemeSettingData = res.data;
    const data = ThemeSettingData.data == null ?  ThemeData :  ThemeSettingData.data
      console.log(data,'121 12')
      // Update the CSS variables with the API color data
      document.documentElement.style.setProperty(
        "--primary-color",
        data.PrimaryColor
      );
      document.documentElement.style.setProperty(
        "--secondary-color",
        data.SecondaryColor
      );
    return data;
})