import "../Components/CSS/pagesCSS/horse.css";
import ListGroup from "react-bootstrap/ListGroup";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { BsFillCaretRightFill } from "react-icons/bs";
import React, { useEffect } from 'react';
import Cookies from "js-cookie";
import Moment from "react-moment";
import axios from "axios";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { fetchPedigree } from "../redux/getReducer/getPedigree";
import { fetchhorsehistory } from "../redux/getReducer/getHorseHistory";
import PleaseWait from "../Components/Reuseable/PleaseWait";
import arrow1 from "../assets/image 13 (Traced).png";
import { useDispatch, useSelector } from "react-redux";
import DefaultImg from "../assets/default.png"
import { useTranslation } from 'react-i18next';
import Stats from "../Components/Tabs/Stats";

const HorseDetail = (data) => {
  const { t } = useTranslation()
  const cookiedata = Cookies.get('i18next');


  const navigate = useNavigate();
  const btnNew1 = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "13px 10px",
    gap: "10px",
    width: "112px",
    height: "24px",
    background: "#FF0000",
    borderRadius: "10px",
    border: "none",
    color: "#fff",
    margin: '6px'
  };

  const token = Cookies.get("token");



  const handleTrack = async (Id) => {
    try {
      await axios.post(
        `${window.env.API_URL}/trackhorse`, { Horse: Id },
        {
          headers: { Authorization: token, "x-xsrf-token": token },
        },
      
      );
      toast('Tracked Success')

      navigate('/tracker')
    } catch (error) {
      const err = error.response.data.message;
      toast(err)

    }
  };


  const id = data.data._id;

  const { data: Pedigree } = useSelector((state) => state.Pedigree);
  const { data: horsehistory } = useSelector((state) => state.horsehistory);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPedigree({ id }));
    dispatch(fetchhorsehistory({ id }));

  }, [dispatch, id]);

  return (
    <div className="RaceDetailCard">
      <div className="horseheader1">
        <div className="horseshirt">
          <img src={DefaultImg} alt="" className="horseshirtimage" />
        </div>
        <div className="cardraces1box">
          <p>
            {data.data.DOB ? (
              <Moment format="DD-MM-YY">
                {data.data.DOB}
              </Moment>
            ) : (
              <>
              </>
            )}
          </p>

          <h3>{data.data.Foal}</h3>
          <p
            style={{
              float: "right",
            }}
          >
            ({data.data.GateNo === 100 ? <></> : data.data.GateNo})
          </p>
        </div>
        <div className="horsecenterdata">
          <span
            style={{
              display: "flex",
            }}
          >
            <p
              style={{
                fontWeight: "700",
                fontSize: " 19.6px",
                lineHeight: "24px",
                color: "#19469D",
                margin: "0px 10px",
              }}
            >


              {cookiedata === 'en' ? (data.data === null ? <></> : data.data.NameEn) : (data.data === null ? <></> : data.data.NameAr)}
            </p>
            <img src={data.data.NationalityData ? data.data.NationalityData.image : DefaultImg} alt="" style={{ height: "18px", margin: "0px 10px" }} />
            <p
              style={{
                fontSize: "12px",
                marginLeft: '20px'
              }}
            >
              {data.data.DOB === null ? <>No Data</> : <><Moment fromNow ago>
                {data.data.DOB}
              </Moment></>} {data.data.CapColorData1 ? <>{data.data.CapColorData1.NameEn}</> : <></>}
              {cookiedata === 'en' ? (data.data === null ? <></> : data.data.NameEn) : (data.data === null ? <></> : data.data.NameAr)}
            </p>
          </span>
          <span
            style={{
              display: "flex",
            }}
          >
            <p
              style={{
                fontSize: "12px",
                paddingLeft: "10px"
              }}
            >
              {t("Dam")}
              <b style={{
                paddingLeft: "5px"
              }}>{cookiedata === 'en' ? (data.data.DamData ? data.data.DamData.NameEn : <>- </>) : (data.data.DamData ? data.data.DamData.NameAr : <>- </>)}
              </b>



            </p>
            <p
              style={{
                fontSize: "12px",
                paddingLeft: "10px"
              }}
            >
              {t("Sire")}<b style={{
                paddingLeft: "5px"
              }}>      {cookiedata === 'en' ? (data.data.SireData ? data.data.SireData.NameEn : <>- </>) : (data.data.SireData ? data.data.SireData.NameAr : <>- </>)}
              </b>


            </p>
            <p
              style={{
                fontSize: "12px",
              }}
            >
              {t("G Sire")} <b style={{
                paddingLeft: "5px"
              }}>  {cookiedata === 'en' ? (data.data.GSireData ? data.data.GSireData.NameEn : <>- </>) : (data.data.GSireData ? data.data.GSireData.NameAr : <>- </>)}</b>
            </p>
          </span>
          <span
            style={{
              display: "flex",
            }}
          >
            <p
              style={{
                fontSize: "12px",
                color: "#FF0000",
              }}
            >
              <b>O </b>{cookiedata === 'en' ? (data.data.ActiveOwnerData === null ? <></> : data.data.ActiveOwnerData.NameEn) : (data.data.ActiveOwnerData === null ? <></> : data.data.ActiveOwnerData.NameAr)}
            </p>
            <p
              style={{
                fontSize: "9px",
                marginLeft: "5px"
              }}
            >
              (8 - 3 - 2 - 8 - 4)
            </p>
          </span>
          <div className="horsedetailimage">
            <img src={data.data.ActiveTrainerData.image ? data.data.ActiveTrainerData.image : DefaultImg} alt="" />
            <span>
              <p
                style={{
                  fontSize: "12px",
                  lineHeight: "10px",
                  marginLeft: "10px",
                }}
              >
                <b style={{
                  padding: "10px",

                }}>T</b>{cookiedata === "en" ? (data.data.ActiveTrainerData === null ? <></> : data.data.ActiveTrainerData.NameEn) : (data.data.ActiveTrainerData === null ? <></> : data.data.ActiveTrainerData.NameAr)}  (8 - 3 - 2 - 8 - 4)
              </p>
              <p
                style={{
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                <b style={{
                  padding: "10px",
                }}>B</b>{cookiedata === "en" ? (data.data.BreederData === null ? <></> : data.data.BreederData.NameEn) : (data.data.BreederData === null ? <></> : data.data.BreederData.NameAr)}


              </p>
            </span>
          </div>
        </div>
        <div className="horseimage">
          <img src={data.data.HorseImage ? data.data.HorseImage : DefaultImg} alt='' />
          <button
            style={btnNew1}
            onClick={() => handleTrack(data.data._id)}
          >
            {t("Track")}
          </button>
        </div>
      </div>
      <div className="horsebody">
        <ListGroup>
          <ListGroup.Item className="horsebodyitem">
            <p style={{
              color: '#fff',
              textAlign: 'center'
            }}>{t("National Hunt Form")}</p>
          </ListGroup.Item>
          <div className="horsehistorylist">
            <p style={{
              color: '#fff',
              padding: '10px'
            }}>
              00 / P8PP44 / 7P3214112 / 1211
            </p>
          </div>
          <ListGroup.Item className="horsebodyitem">
            <div className='horserecord'>
              <p>{t("Lifetime Record")}</p>
              <p>{t("Runs")}</p>
              <p>{t("Wins")}</p>
              <p>{t("2nd")}</p>
              <p>{t("3rd")}</p>
              <p>{t("Winnings")}</p>
              <p>{t("Earnings")}</p>
              <p>{t("OR")} </p>
            </div>
          </ListGroup.Item>
          <ListGroup.Item className="horsebodyitem1">
            <div className='horserecord'>
              <h6>{t("FLAT TURF")}</h6>
            </div>
          </ListGroup.Item>
          <ListGroup.Item className="horsebodyitem1">
            <div className='horserecord'>
              <h6>{t("RULES RACES")}</h6>
            </div>
          </ListGroup.Item>
          <ListGroup.Item className="horsebodyitem1">
            <div className='horserecord'>
              <h6>{t("DURT")}</h6>
            </div>
          </ListGroup.Item>
          <ListGroup.Item className="horsebodyitem1">
            <div className='horserecord'>
              <h6>{t("TOTAL")}</h6>
            </div>
          </ListGroup.Item>
        </ListGroup>
      </div>
      <div className="RaceNav HorseNav">
        <Tabs id="uncontrolled-tab-example" className="mb-3 RaceNavItem">
          <Tab eventKey="Form" title={t("Form")} tabClassName="profile-tabitem">
            <div className="RaceDetailCard">
            <div className="mycardclass1">
                              <div className="BodyNew">
                                <table className="customers">
                                  <thead className="customers5">
                                    <tr>
                                      <th>{t("Date")}</th>
                                      <th>{t("Cr")}</th>
                                      <th>{t("Dist")}</th>
                                      <th>{t("TC")}</th>

                                      <th>{t("Dts")}</th>
                                      <th>{t("time")}</th>
                                      <th>{t("Wgt")}</th>
                                      <th>{t("FP")}</th>
                                      <th>{t("Les")}</th>
                                      <th>{t("RS")}</th>
                                      <th>{t("BtBy")}</th>

                                      <th>{t("Draw")}</th>
                                      <th>{t("Link")}</th>
                                    </tr>
                                  </thead>
                                  <tbody className="customers1">
                                    {horsehistory === undefined ? (
                                      <PleaseWait />
                                    ) : (
                                      <>
                                        {!horsehistory ? (
                                          <PleaseWait />
                                        ) : (
                                          horsehistory.map((item) => {
                                            return (
                                              <tr className="BodyNew1 historyhorseth">
                                                <td className="lb">
                                                  <Moment format="YYYY/MM/DD">
                                                    {item.RaceResultData ===
                                                    null ? (
                                                      <></>
                                                    ) : (
                                                      item.RaceResultData.Day
                                                    )}
                                                  </Moment>
                                                </td>
                                                <td>
                                                  {item.RaceResultData
                                                    .RacehorsesData[0]
                                                    ? cookiedata === "en"
                                                      ? item.RaceResultData
                                                          .RacehorsesData[0]
                                                          .RacehorsesData
                                                          .RaceCourseData
                                                          .TrackNameEn
                                                      : item.RaceResultData
                                                          .RacehorsesData[0]
                                                          .RacehorsesData
                                                          .RaceCourseData
                                                          .TrackNameAr
                                                    : <>- </>}
                                                </td>
                                                <td>
                                                  {item.Distance &&
                                                    item.Distance}
                                                </td>
                                                <td>
                                                  {item.RaceResultData
                                                    .RacehorsesData
                                                    ? cookiedata === "en"
                                                      ? item.RaceResultData
                                                          .RacehorsesData[0]
                                                          .RacehorsesData
                                                          .TrackConditionData
                                                          .NameEn
                                                      : item.RaceResultData
                                                          .RacehorsesData[0]
                                                          .RacehorsesData
                                                          .TrackConditionData
                                                          .NameAr
                                                    : "_"}
                                                </td>
                                                <td>
                                                  {item.PrizeWin
                                                    ? item.PrizeWin
                                                    : 0}
                                                </td>
                                                <td>{item.RaceTime}</td>
                                                <td>
                                                  {item.RaceResultData
                                                    .RacehorsesData
                                                    ? item.RaceResultData
                                                        .RacehorsesData[0]
                                                        .JockeyRaceWeight
                                                    : "_"}
                                                </td>
                                                <td>
                                                  {item.FinalPositionDataHorse ===
                                                  null ? (
                                                    <></>
                                                  ) : (
                                                    <>
                                                      {cookiedata === "en" ? (
                                                        <>
                                                          {cookiedata === "en"
                                                            ? item.FinalPositionDataHorse &&
                                                              item
                                                                .FinalPositionDataHorse
                                                                .NameEn
                                                            : item.FinalPositionDataHorse &&
                                                              item
                                                                .FinalPositionDataHorse
                                                                .NameAr}
                                                        </>
                                                      ) : (
                                                        <>
                                                          {
                                                            item
                                                              .FinalPositionDataHorse
                                                              .NameAr
                                                          }
                                                        </>
                                                      )}
                                                    </>
                                                  )}
                                                </td>
                                                <td>-</td>
                                                <td>{item.Rating}</td>
                                                {/* <td>6</td> */}
                                                {/* <td>16.25</td> */}
                                                <td>
                                                  {item.BeatenByData ===
                                                  null ? (
                                                    <>-</>
                                                  ) : (
                                                    <>
                                                      {cookiedata === "en" ? (
                                                        <>
                                                          {
                                                            item.BeatenByData
                                                              .NameEn
                                                          }
                                                        </>
                                                      ) : (
                                                        <>
                                                          {
                                                            item.BeatenByData
                                                              .NameAr
                                                          }
                                                        </>
                                                      )}
                                                    </>
                                                  )}
                                                </td>{" "}
                                                <td>
                                                  {item.RaceResultData
                                                    .RacehorsesData[0]
                                                    ? item.RaceResultData
                                                        .RacehorsesData[0]
                                                        .HorseNo
                                                    : <>- </>}
                                                </td>
                                                <td
                                                  className="rb"
                                                  // onClick={() =>
                                                  //   handleShows(item.VideoLink)
                                                  // }
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  <img src={arrow1} alt="" />
                                                </td>
                                              </tr>
                                            );
                                          })
                                        )}
                                      </>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
            </div>
          </Tab>
          <Tab
            eventKey="Entries"
            title={t("Entries")}
            tabClassName="profile-tabitem"
          >
            <div className="RaceDetailCard">
              Entries
            </div>
          </Tab>
          <Tab eventKey="Stats" title={t("Stats")} tabClassName="profile-tabitem">
            <div className="RaceDetailCard">
              <Stats />
            </div>
          </Tab>
          <Tab
            eventKey="Pedigree"
            title={t("Pedigree")}
            tabClassName="profile-tabitem"
          >
            <div className="RaceDetailCard">
              <div className="Pedigree">
                {Pedigree.length === 0 ? (
                  <h3>Please Wait Data is Loading ...</h3>
                ) : (
                  <div className="wrapper">
                    <div className="one">
                      <div className="sire pedigreeclass">
                        {Pedigree.generation1 === null ? (
                          <>- </>
                        ) : Pedigree.generation1.SireData === null ? (
                          <>- </>
                        ) : (
                          Pedigree.generation1.SireData.NameEn
                        )}
                      </div>
                      <div className="dam pedigreeclass">
                        {Pedigree.generation1 === null ? (
                          <>- </>
                        ) : Pedigree.generation1.DamData === null ? (
                          <>- </>
                        ) : (
                          Pedigree.generation1.DamData.NameEn
                        )}
                      </div>
                    </div>
                    <div className="two">
                      <div className="gsire pedigreeclass">
                        {Pedigree.generation2b === null ? (
                          <>- </>
                        ) : Pedigree.generation2b.SireData === null ? (
                          <>- </>
                        ) : (
                          Pedigree.generation2b.SireData.NameEn
                        )}
                      </div>
                      <div className="gdam pedigreeclass">
                        {Pedigree.generation2b === null ? (
                          <>- </>
                        ) : Pedigree.generation2b.DamData === null ? (
                          <>- </>
                        ) : (
                          Pedigree.generation2b.DamData.NameEn
                        )}
                      </div>
                      <div className="gsire pedigreeclass">
                        {Pedigree.generation2a === null ? (
                          <>- </>
                        ) : Pedigree.generation2a.SireData === null ? (
                          <>- </>
                        ) : (
                          Pedigree.generation2a.SireData.NameEn
                        )}
                      </div>
                      <div className="gdam pedigreeclass">
                        {Pedigree.generation2a === null ? (
                          <>- </>
                        ) : Pedigree.generation2a.DamData === null ? (
                          <>- </>
                        ) : (
                          Pedigree.generation2a.DamData.NameEn
                        )}
                      </div>
                    </div>
                    <div className="three">
                      <div className="ggsire pedigreeclass">
                        {Pedigree.generation3d === null ? (
                          <>- </>
                        ) : Pedigree.generation3d.SireData === null ? (
                          <>- </>
                        ) : (
                          Pedigree.generation3d.SireData.NameEn
                        )}
                      </div>
                      <div className="ggdam pedigreeclass">
                        {Pedigree.generation3d === null ? (
                          <>- </>
                        ) : Pedigree.generation3d.DamData === null ? (
                          <>- </>
                        ) : (
                          Pedigree.generation3d.DamData.NameEn
                        )}
                      </div>
                      <div className="ggsire pedigreeclass">
                        {Pedigree.generation3c === null ? (
                          <>- </>
                        ) : Pedigree.generation3c.SireData === null ? (
                          <>- </>
                        ) : (
                          Pedigree.generation3c.SireData.NameEn
                        )}
                      </div>
                      <div className="ggdam pedigreeclass">
                        {Pedigree.generation3c === null ? (
                          <>- </>
                        ) : Pedigree.generation3c.DamData === null ? (
                          <>- </>
                        ) : (
                          Pedigree.generation3c.DamData.NameEn
                        )}
                      </div>
                      <div className="ggsire pedigreeclass">
                        {Pedigree.generation3b === null ? (
                          <>- </>
                        ) : Pedigree.generation3b.SireData === null ? (
                          <>- </>
                        ) : Pedigree.generation3b.SireData === null ? (
                          <>- </>
                        ) : (
                          Pedigree.generation3b.SireData.NameEn
                        )}
                      </div>
                      <div className="ggdam pedigreeclass">
                        {Pedigree.generation3b === null ? (
                          <>- </>
                        ) : Pedigree.generation3a.DamData === null ? (
                          <>- </>
                        ) : (
                          Pedigree.generation3b.DamData.NameEn === null ? <>- </> : Pedigree.generation3b.DamData.NameEn
                        )}
                      </div>
                      <div className="ggsire pedigreeclass">
                        {Pedigree.generation3a === null ? (
                          <>- </>
                        ) : Pedigree.generation3a.SireData === null ? (
                          <>- </>
                        ) : (
                          Pedigree.generation3a.SireData.NameEn
                        )}
                      </div>
                      <div className="ggdam pedigreeclass">
                        {Pedigree.generation3a === null ? (
                          <>- </>
                        ) : Pedigree.generation3a.DamData === null ? (
                          <>- </>
                        ) : (
                          Pedigree.generation3a.DamData.NameEn
                        )}
                      </div>

                    </div>
                  </div>
                )}
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};
export default HorseDetail;