import React, { useEffect, useState } from 'react';
import Prediction from '../Components/Prediction';
import Graph from '../Components/Graph';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchPredictionHorse, } from '../../../redux/getReducer/getPrediction';
import { Carousel } from 'react-bootstrap';
import socket from '../../../../Socket';

const HomeCenterSubSection = () => {
    const dispatch = useDispatch();
    const { data: PredictionOfHorse, status } = useSelector(
        (state) => state.PredictionOfHorse
    );
   
    const [PredictorData, setPredictionData] = useState(PredictionOfHorse);

    useEffect(() => {
        setPredictionData(PredictionOfHorse)
      },[PredictionOfHorse])

    useEffect(() => {
        dispatch(fetchPredictionHorse());
        socket.on("Predictor", (Predictor) => {
            setPredictionData(Predictor);
            console.log(Predictor,'s')
        });
       
    }, [dispatch]);

    console.log(PredictorData,"PredictorData")

    const isActive = PredictorData?.length == 0 ? false : true;

    console.log(isActive,"isActive")

    return (
        <Carousel
        controls={isActive}
        touch={true}
        >
            {PredictorData && PredictorData.map((item, i) => {
                return (

                    <Carousel.Item interval={2000000}>
                        <div className='graphParent'>
                            <Graph item={item} />

                            <Prediction data={item} />
                        </div>

                    </Carousel.Item>


                );
            }
            )}
        </Carousel>
    );
};

export default HomeCenterSubSection;