import { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import Moment from "react-moment";
import Defaultimg from "../../assets/Frame.png";
import { useTranslation } from "react-i18next";
import {
  fetchgetsingleowner,
  STATUSES,
} from "../../redux/getReducer/getSingleOwner";
import { fetchOwnerHistory } from "../../redux/getReducer/getSingleOwnerHistory";
import { useDispatch, useSelector } from "react-redux";
import PleaseWait from "../../Components/Reuseable/PleaseWait";
import arrow1 from "../../assets/image 13 (Traced).png";
import Stats from "../Tabs/OwnerStats";
import HorseOwnerStats from "../Tabs/HorseOwnerStats";
import HorseKindOwnerStats from "../Tabs/HorseKindOwnerStats";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import VideoFrame from "./VideoFrame";
import OwnerEntries from "../Tabs/OwnerEntries";
import JockeyDetail from "./JockeyModal";
import HorseModal from "./HorseModal";
import RaceModal from "./RaceModal";
import Lottie from "lottie-react";
import Animate from "../../assets/loader.json";
import Pagination from "../../pages/Pagination";
import { fetchownerhorsekindStats } from "../../redux/getReducer/getOwnerHorseKind";

const OwnerDetail = (data) => {
  const btnNew2 = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "13px 10px",
    gap: "10px",
    width: "112px",
    height: "24px",
    background: "#0388fc",
    borderRadius: "10px",
    border: "none",
    color: "#fff",
    margin: "6px",
  };
  const { t } = useTranslation();
  const cookiedata = Cookies.get("i18next");
  const token = Cookies.get("token");
  const [shows, setShows] = useState(false);
  const [modaldatas, setmodaldatas] = useState();
  const handleCloses = () => setShows(false);
  const handleShows = async (data) => {
    setmodaldatas(data);
    await setShows(true);
  };
  const [ShowHorse, setShowHorse] = useState(false);
  const [modaldatasHorse, setmodaldatasHorse] = useState();
  const handleHorseCloses = () => setShowHorse(false);
  const handleShowHorse = async (data) => {
    setmodaldatasHorse(data);
    await setShowHorse(true);
  };

  const [ShowJockey, setShowJockey] = useState(false);
  const [modaldatasJockey, setmodaldatasJockey] = useState();
  const handleJockeyCloses = () => setShowJockey(false);
  const handleShowJockey = async (data) => {
    setmodaldatasJockey(data);
    await setShowJockey(true);
  };

  let id = data.data.OwnerOnRaceData1
    ? data.data.OwnerOnRaceData1._id
    : data.data._id
    ? data.data._id
    : data.data;

  const { data: getsingleowner, status } = useSelector(
    (state) => state.getsingleowner
  );
  const { data: OwnerHistory } = useSelector((state) => state.OwnerHistory);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  let totalcountdata = OwnerHistory ? OwnerHistory?.length : "";
  const [postsPerPage] = useState(11);
  const totalpagesdata = Math.ceil(totalcountdata / postsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const firstPageIndex = (currentPage - 1) * postsPerPage;
  const lastPageIndex = firstPageIndex + postsPerPage;
  const SliceData =  OwnerHistory.slice(firstPageIndex, lastPageIndex);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [showRace, setShowRace] = useState(false);
  const [modaldataRace, setmodaldataRace] = useState();
  const handleCloseRace = () => setShowRace(false);
  const handleShowRace = async (id) => {
    setmodaldataRace(id);
    await setShowRace(true);
  };
  const handleUnTrack = async (Id) => {
    try {
      await axios.post(
        `${window.env.API_URL}/UnTrackOwner`,
        { Owner: Id },
        {
          headers: { Authorization: token, "x-xsrf-token": token },
        }
      );
      toast("Owner Has Been UnTracked ");

      navigate("/tracker/#Ownercontact");
      // dispatch(fetchgetsingleowner({ Id }));
      // navigate("/tracker");
    } catch (error) {
      const err = error.response.data.message;
      toast(err);
    }
  };
  useEffect(() => {
    dispatch(fetchgetsingleowner({ id }));
    dispatch(fetchOwnerHistory({ id }));
  }, [dispatch, id]);
  const btnNew1 = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "13px 10px",
    gap: "10px",
    width: "112px",
    height: "24px",
    background: "#FF0000",
    borderRadius: "10px",
    border: "none",
    color: "#fff",
    margin: "6px",
  };

  const handleTrack = async (Id) => {
    try {
      await axios.post(
        `${window.env.API_URL}/trackowner`,
        { Owner: Id },
        {
          headers: { Authorization: token, "x-xsrf-token": token },
        }
      );
      // toast('Tracked Success')
      navigate("/tracker/#Ownercontact");
    } catch (error) {
      const err = error.response.data.message;
      if (err === "Json Web Token is invalid") {
        toast.error("Please Login to Access This Resource");
        navigate("/login");
      } else {
        toast.error(err);
      }
    }
  };

  function calculatePercentage(x, y) {
    if (x === 0 && y === 0) return 0;
    else {
      return Math.round((x / y) * 100);
    }
  }


  function distanceLabel(distance, rank) {
    const DistanceTobeChecked = parseFloat(distance);
    console.log(DistanceTobeChecked);
    if (DistanceTobeChecked.toFixed(2) > 25) {
      return "Dist";
    } else if (DistanceTobeChecked.toFixed(2) == 0.05 && rank < 80) {
      return "SHd";
    } else if (DistanceTobeChecked.toFixed(2) == 0.10 && rank < 80) {
      return "Hd";
    } else if (DistanceTobeChecked.toFixed(2) == 0.25 && rank < 80) {
      return "Nk";
    } else if (DistanceTobeChecked.toFixed(2) == 0.01 && rank < 80) {
      return "Ns";
    } else if (DistanceTobeChecked.toFixed(2) == 0.00 && rank < 80) {
      if(rank === 1){
        return 0;
      }
      return "Dh";
    } else {
      return distance;
    }
  }

  const handleHorseKind = (tabIndex) => {
    if (tabIndex == "HorseKind") {
    dispatch(fetchownerhorsekindStats({ id }));
    }
    

  }
  return (
    <>
      {status === "loading" ? (
        <>
          <Lottie animationData={Animate} loop={true} className="TableLottie" />
        </>
      ) : (
        <div className="aboutpage">
          <div className="RaceDetailCard">
            <div className="horseheader1">
              <div className="horseshirt">
                <img
                  src={getsingleowner.image ? getsingleowner.image : Defaultimg}
                  alt=""
                  className="horseshirtimage"
                  style={{
                    height: "60px",
                  }}
                />
              </div>

              <div className="horsecenterdata jockeyname1">
                <span
                  style={{
                    display: "flex",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "700",
                      fontSize: " 19.6px",
                      lineHeight: "24px",
                      color: "#19469D",
                      textTransform:"capitalize"
                    }}
                  >
                     {cookiedata === "en"
                      ? getsingleowner
                        ? getsingleowner.TitleEn
                        : <>- </>
                      : getsingleowner
                      ? getsingleowner.TitleAr
                      : <>- </>}
                    {cookiedata === "en"
                      ? getsingleowner
                        ? getsingleowner.NameEn
                        : <>- </>
                      : getsingleowner
                      ? getsingleowner.NameAr
                      : <>- </>}
                  </p>

                  {/* <p
                                      style={{
                                        fontWeight: "400",
                                        fontSize: "14px",
                                        lineHeight: "15px",
                                        color: "rgba(0, 0, 0, 0.8)",
                                        margin:"5px"
                                      }}
                                    >
                                      {getsingleowner &&
                                        getsingleowner.TotalCount}
                                      (
                                      {getsingleowner &&
                                        getsingleowner
                                          .OwnerFirstRanking}{" "}
                                      -{" "}
                                      {getsingleowner &&
                                        getsingleowner
                                          .OwnerSecondRanking}{" "}
                                      -{" "}
                                      {getsingleowner &&
                                        getsingleowner
                                          .OwnerThirdRanking}
                                      )
                                    </p> */}

                  <p
                  style={{
                    fontSize: "12px",
                    marginLeft: '20px',
                    textTransform:"capitalize"

                  }}
                >
                  {/* {getsingleowner.RegistrationDate === null ? <>No Data</> : <><Moment fromNow ago>
                    {getsingleowner.RegistrationDate}
                  </Moment></>} */}

                </p>
                </span>
                <span
                style={{
                  display: "flex",
                }}
              >
                <p
                  style={{
                    fontSize: "12px",
                    paddingLeft: "0px",
                    textTransform:"capitalize"

                  }}
                > {t("Total Owner Horses")}
                  <b style={{
                    paddingLeft: "5px",
                    textTransform:"capitalize"

                  }}>      
                   {cookiedata === "en" ? (getsingleowner ? getsingleowner.OwnerHorses : <>- </>) : (getsingleowner.OwnerHorses ? getsingleowner.OwnerHorses : <>- </>)}
                  </b>
                </p>
                <p
                  style={{
                    fontSize: "12px",
                    paddingLeft: "16px",
                    textTransform:"capitalize"

                  }}
                >{t("Regd")}
                  <b style={{
                    paddingLeft: "5px",
                    textTransform:"capitalize"

                  }}> {!getsingleowner ? <>No Data</> : <><Moment format="D MMM YYYY" withTitle>
                    {getsingleowner.RegistrationDate}
                  </Moment></>}</b>
                </p>

              </span>
                <p
                  style={{
                    fontWeight: "400",
                    fontSize: " 13.6px",
                    color: "#19469D",
                    textTransform:"capitalize"
                    
                  }} className="mynation"
                >
                  <b>{t("Nationality")}:{" "}</b> 
                  {cookiedata === "en" ?  getsingleowner.OwnerDataNationalityData &&
                    getsingleowner.OwnerDataNationalityData.AbbrevEn : getsingleowner.OwnerDataNationalityData &&
                    getsingleowner.OwnerDataNationalityData.AbbrevAr}
                </p>
              </div>

              <div className="ownerTrack">
                {getsingleowner.track == true ? (
                  <button
                    style={btnNew2}
                    onClick={() => handleUnTrack(getsingleowner._id)}
                  >
                    {t("Untrack")}
                  </button>
                ) : (
                  <button
                    style={btnNew1}
                    onClick={() => handleTrack(getsingleowner._id)}
                  >
                    {t("Track")}
                  </button>
                )}
              </div>
            </div>
            <div className="mycardclass1">
              <div className="BodyNew mpobile0">
                <table className="customers horseModalTable">
                  <thead className="customers5 ">
                    <tr>
                      <th>{t("Lifetime Record")}</th>
                      <th>{t("Runs")}</th>
                      <th>{t("Wins")}</th>
                      <th>{t("2nd")}</th>
                      <th>{t("3rd")}</th>
                      <th>{t("4th")}</th>
                      <th>{t("5th")}</th>
                      <th>{t("Winnings")}</th>
                      <th>{t("Earnings")}</th>
                    </tr>
                  </thead>
                  <tbody className="customers1">
                    <>
                      <tr className="BodyNew1 ">
                        <td className="lb">{t("FLAT TURF")}</td>
                        <td>{getsingleowner.TurfCount}</td>
                        <td>
                          {getsingleowner.TurfWins === null
                            ? 0
                            : getsingleowner.TurfWins}
                        </td>
                        <td>
                          {getsingleowner.Turfsecond === null
                            ? 0
                            : getsingleowner.Turfsecond}
                        </td>
                        <td>
                          {getsingleowner.Turfthird === null
                            ? 0
                            : getsingleowner.Turfthird}
                        </td>
                        <td>
                          {getsingleowner.Turffourth === null
                            ? 0
                            : getsingleowner.Turffourth}
                        </td>
                        <td>
                          {getsingleowner.Turffifth === null
                            ? 0
                            : getsingleowner.Turffifth}
                        </td>
                        <td>
                          {calculatePercentage(
                            getsingleowner.Turfsecond === null
                              ? 0
                              : getsingleowner.Turfsecond +
                                  getsingleowner.TurfWins ===
                                null
                              ? 0
                              : getsingleowner.TurfWins +
                                  getsingleowner.Turfthird ===
                                null
                              ? 0
                              : getsingleowner.Turfthird,
                            getsingleowner.TurfCount
                          )}
                          %
                        </td>
                        <td>
                        {getsingleowner.TurfEarnings === null ? 0 : getsingleowner.TurfEarnings}
                        </td>
                      </tr>
                      <tr className="BodyNew1 ">
                        <td className="lb">{t("DIRT")}</td>
                        <td>{getsingleowner.DirtCount}</td>
                        <td>
                          {getsingleowner.DirtWins === null
                            ? 0
                            : getsingleowner.DirtWins}
                        </td>
                        <td>
                          {getsingleowner.Dirtsecond === null
                            ? 0
                            : getsingleowner.Dirtsecond}
                        </td>
                        <td>
                          {getsingleowner.Dirtthird === null
                            ? 0
                            : getsingleowner.Dirtthird}
                        </td>
                        <td>
                          {getsingleowner.Dirtfourth === null
                            ? 0
                            : getsingleowner.Dirtfourth}
                        </td>
                        <td>
                          {getsingleowner.Dirtfifth === null
                            ? 0
                            : getsingleowner.Dirtfifth}
                        </td>
                        <td>
                          {calculatePercentage(
                            getsingleowner.Dirtsecond === null
                              ? 0
                              : getsingleowner.Dirtsecond +
                                  getsingleowner.DirtWins ===
                                null
                              ? 0
                              : getsingleowner.DirtWins +
                                  getsingleowner.Dirtthird ===
                                null
                              ? 0
                              : getsingleowner.Dirtthird,
                            getsingleowner.DirtCount
                          )}
                          %
                        </td>
                        <td>
                          {getsingleowner.DirtEarnings === null
                            ? 0
                            : getsingleowner.DirtEarnings}
                        </td>
                      </tr>
                      <tr className="BodyNew1 ">
                        <td className="lb">{t("ALL WEATHER")}</td>
                        <td>{getsingleowner.AllWeatherCount}</td>
                        <td>
                          {getsingleowner.AllWeatherWins === null
                            ? 0
                            : getsingleowner.AllWeatherWins}
                        </td>
                        <td>
                          {getsingleowner.AllWeathersecond === null
                            ? 0
                            : getsingleowner.AllWeathersecond}
                        </td>
                        <td>
                          {getsingleowner.AllWeatherthird === null
                            ? 0
                            : getsingleowner.AllWeatherthird}
                        </td>
                        <td>
                          {getsingleowner.AllWeatherfourth === null
                            ? 0
                            : getsingleowner.AllWeatherfourth}
                        </td>
                        <td>
                          {getsingleowner.AllWeatherfifth === null
                            ? 0
                            : getsingleowner.AllWeatherfifth}
                        </td>
                        <td>
                          {calculatePercentage(
                            getsingleowner.AllWeathersecond === null
                              ? 0
                              : getsingleowner.AllWeathersecond +
                                  getsingleowner.AllWeatherWins ===
                                null
                              ? 0
                              : getsingleowner.AllWeatherWins + getsingleowner.AllWeatherthird ===
                                null
                              ? 0
                              : getsingleowner.AllWeatherthird,
                            getsingleowner.AllWeatherCount
                          )}
                          %
                        </td>
                        <td>
                          {getsingleowner.AllWeatherEarnings === null
                            ? 0
                            : getsingleowner.AllWeatherEarnings}
                        </td>
                      </tr>
                      <tr className="BodyNew1 historyhorseth">
                        <td className="lb">{t("TOTAL")}</td>
                        <td>{getsingleowner.TotalCount}</td>
                        <td>
                          {getsingleowner.TotalWins === null
                            ? 0
                            : getsingleowner.TotalWins}
                        </td>
                        <td>
                          {getsingleowner.Totalsecond === null
                            ? 0
                            : getsingleowner.Totalsecond}
                        </td>
                        <td>
                          {getsingleowner.Totalthird === null
                            ? 0
                            : getsingleowner.Totalthird}
                        </td>
                        <td>
                          {getsingleowner.Totalfourth === null
                            ? 0
                            : getsingleowner.Totalfourth}
                        </td>
                        <td>
                          {getsingleowner.Totalfifth === null
                            ? 0
                            : getsingleowner.Totalfifth}
                        </td>
                        <td>
                          {calculatePercentage(
                            getsingleowner.Totalsecond === null
                              ? 0
                              : getsingleowner.Totalsecond +
                                  getsingleowner.TotalWins ===
                                null
                              ? 0
                              : getsingleowner.TotalWins +
                                  getsingleowner.Totalthird ===
                                null
                              ? 0
                              : getsingleowner.Totalthird,
                            getsingleowner.TotalCount
                          )}
                          %
                        </td>
                        <td>
                          {getsingleowner.TotalEarnings === null
                            ? 0
                            : getsingleowner.TotalEarnings}
                        </td>
                      </tr>
                    </>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="RaceNav HorseNav">
              <Tabs id="uncontrolled-tab-example" className="mb-3 RaceNavItem"  onSelect={handleHorseKind}>
                <Tab
                  eventKey="Form"
                  title={t("Form")}
                  tabClassName="profile-tabitem"
                >
                  <div className="RaceDetailCard">
                    <div className="BodyNew mpobile0">
                      <table className="customers">
                        <thead className="customers5">
                          <tr>
                            <th>{t("Date")}</th>
                            <th>{t("Crs")}</th>
                            <th>{t("Dist")}</th>
                            <th>{t("TC")}</th>
                            <th>{t("RT")}</th>

                            <th>{t("Dts")}</th>
                            <th>{t("Time")}</th>
                            <th>{t("Jockey")}</th>
                            <th>{t("Wgt")}</th>

                            <th>{t("FP")}</th>
                            <th>{t("Len")}</th>
                            <th>{t("RN")}</th>
                            <th>{t("BtBy")}</th>
                            <th>{t("Kgs")}</th>

                            <th>{t("Draw")}</th>
                            <th>{t("Link")}</th>
                          </tr>
                        </thead>
                        <tbody className="customers1">
                          {OwnerHistory === undefined ? (
                            <PleaseWait />
                          ) : (
                            <>
                              {!SliceData ? (
                                <PleaseWait />
                              ) : (
                                SliceData &&
                                SliceData.map((item) => {
                                  return (
                                    <tr
                                      className="BodyNew1 historyhorseth"
                                      style={{
                                        background: `${
                                          item.RaceResultData
                                            ? item.RaceResultData
                                                .RacehorsesData[0]
                                                .RacehorsesData.TrackLengthData
                                                .GroundTypeModelData.NameEn ==
                                              "Dirt"
                                              ? "#9F5109"
                                              : item.RaceResultData
                                                  .RacehorsesData[0]
                                                  .RacehorsesData
                                                  .TrackLengthData
                                                  .GroundTypeModelData.NameEn ==
                                                "Turf"
                                              ? "#5ec40f"
                                              : "#949494"
                                            : "#949494"
                                        }`,
                                      }}
                                    >
                                      <td
                                        style={{ cursor: "pointer" }}
                                        className="lb"
                                        onClick={() =>
                                          handleShowRace(item.RaceID)
                                        }
                                      >
                                        <Moment format="DD/MM/YYYY">
                                          {item.RaceResultData === null ? (
                                            <></>
                                          ) : (
                                            item.RaceResultData.Day
                                          )}
                                        </Moment>
                                      </td>
                                      <td>
                                        {item.RaceResultData ? (
                                          cookiedata === "en" ? (
                                            item.RaceResultData &&
                                            item.RaceResultData
                                              .RacehorsesData[0].RacehorsesData
                                              .RaceCourseData ? (
                                              item.RaceResultData
                                                .RacehorsesData[0]
                                                .RacehorsesData.RaceCourseData
                                                .TrackNameEn
                                            ) : (
                                              <>-</>
                                            )
                                          ) : item.RaceResultData &&
                                            item.RaceResultData
                                              .RacehorsesData[0].RacehorsesData
                                              .RaceCourseData ? (
                                            item.RaceResultData
                                              .RacehorsesData[0].RacehorsesData
                                              .RaceCourseData.TrackNameAr
                                          ) : (
                                            <>-</>
                                          )
                                        ) : (
                                          <>- </>
                                        )}
                                        (
                                        {cookiedata === "en" ? (
                                          item.RaceResultData &&
                                          item.RaceResultData ? (
                                            item.RaceResultData.RacehorsesData[0].RacehorsesData.TrackLengthData.GroundTypeModelData.NameEn.charAt(
                                              0
                                            )
                                          ) : (
                                            <>-</>
                                          )
                                        ) : item.RaceResultData ? (
                                          item.RaceResultData.RacehorsesData[0].RacehorsesData.TrackLengthData.GroundTypeModelData.NameAr.charAt(
                                            0
                                          )
                                        ) : (
                                          <>-</>
                                        )}
                                        )
                                      </td>
                                      <td>
                                      {item.RaceResultData ? (
                                          item.RaceResultData.RacehorsesData[0]
                                            .RacehorsesData.TrackLengthData
                                            .TrackLength
                                        ) : (
                                          <>-</>
                                        )}
                                      </td>
                                      <td>
                                        {item.RaceResultData
                                          ? cookiedata === "en"
                                            ? item.RaceResultData &&
                                              item.RaceResultData
                                                .RacehorsesData[0]
                                                .RacehorsesData
                                                .TrackConditionData.NameEn
                                            : item.RaceResultData &&
                                              item.RaceResultData
                                                .RacehorsesData[0]
                                                .RacehorsesData
                                                .TrackConditionData.NameAr
                                          : "_"}
                                      </td>
                                      <td>
                                        <OverlayTrigger
                                          className="tooltip-btn"
                                          overlay={
                                            <Tooltip
                                              id="tooltip-disabled"
                                              className="tooltip-btn"
                                            >
                                              {item.RaceResultData &&
                                              item.RaceResultData.RacehorsesData
                                                ? cookiedata === "en"
                                                  ? item.RaceResultData &&
                                                    item.RaceResultData
                                                      .RacehorsesData &&
                                                    item.RaceResultData
                                                      .RacehorsesData[0]
                                                      .RacehorsesData
                                                      .RaceTypeModelData.NameEn
                                                  : item.RaceResultData &&
                                                    item.RaceResultData
                                                      .RacehorsesData &&
                                                    item.RaceResultData
                                                      .RacehorsesData[0]
                                                      .RacehorsesData
                                                      .RaceTypeModelData.NameAr
                                                : "_"}
                                            </Tooltip>
                                          }
                                        >
                                          <span
                                            className="d-inline-block"
                                            style={{
                                              marginTop: "12px",
                                            }}
                                          >
                                            <p className="tooltipclass1">
                                              {" "}
                                              {item.RaceResultData &&
                                              item.RaceResultData.RacehorsesData
                                                ? cookiedata === "en"
                                                  ? item.RaceResultData &&
                                                    item.RaceResultData
                                                      .RacehorsesData[0]
                                                      .RacehorsesData
                                                      .RaceTypeModelData.NameEn
                                                  : item.RaceResultData &&
                                                    item.RaceResultData
                                                      .RacehorsesData[0]
                                                      .RacehorsesData
                                                      .RaceTypeModelData.NameAr
                                                : "_"}
                                            </p>
                                          </span>
                                        </OverlayTrigger>
                                      </td>
                                      <td>
                                        {item.PrizeWin ? item.PrizeWin : 0}
                                      </td>
                                      <td>{item.RaceTime}</td>
                                      <td
                                        onClick={() =>
                                          handleShowJockey(
                                            item.JockeyOnRaceDataResult
                                              ? item.JockeyOnRaceDataResult
                                              : ""
                                          )
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        {item.JockeyOnRaceDataResult
                                          ? cookiedata === "en"
                                            ?  window.textTransform(item.JockeyOnRaceDataResult?.ShortNameEn)
                                            : item.JockeyOnRaceDataResult?.ShortNameAr
                                          : "_"}
                                      </td>

                                      <td>
                                        {item.RaceResultData
                                          ? item.RaceResultData &&
                                            item.RaceResultData
                                              .RacehorsesData[0].JockeyRaceWeight
                                          : <>- </>}
                                      </td>


                                      <td>
                                        {item.FinalPositionDataHorse ===
                                        null ? (
                                          <></>
                                        ) : (
                                          <>
                                            {cookiedata === "en" ? (
                                              <>
                                                {cookiedata === "en"
                                                  ? item.FinalPositionDataHorse &&
                                                    item.FinalPositionDataHorse
                                                      .NameEn
                                                  : item.FinalPositionDataHorse &&
                                                    item.FinalPositionDataHorse
                                                      .NameAr}
                                              </>
                                            ) : (
                                              <>
                                                {
                                                  item.FinalPositionDataHorse
                                                    .NameAr
                                                }
                                              </>
                                            )}
                                          </>
                                        )}
                                      </td>
                                      <td>

                                      {
                                                    distanceLabel(item.Distance &&
                                                      item.Distance , item.FinalPositionDataHorse &&
                                                      item.FinalPositionDataHorse?.Rank)
                                                  }

                                      
                                      </td>
                                      <td>{item.Runners}</td>
                                      <td
                                        onClick={() =>
                                          handleShowHorse(
                                            item.BeatenByData
                                              ? item.BeatenByData
                                              : ""
                                          )
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                         {item.BeatenByData ===
                                                    null ? (
                                                      <>{cookiedata === "en" ?  window.textTransform(item?.HorseIDData?.HorseBeatenByEnglishName) : item.HorseIDData.HorseBeatenByArabicName}</>
                                                    ) : (
                                                      <>
                                                        {cookiedata === "en" ? (
                                                          <>
                                                            {
                                                               window.textTransform(item?.BeatenByData
                                                                ?.NameEn)
                                                            }
                                                          </>
                                                        ) : (
                                                          <>
                                                            {
                                                              item.BeatenByData
                                                                .NameAr
                                                            }
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                      </td>{" "}
                                      <td>
                                      {
                                                      item.BeatenByData !== null ? item.BeatenByData.WinningHorseJockeyWeight : (item.HorseIDData.BeatenByJockeyWeight === null ? 0 :  item.HorseIDData.BeatenByJockeyWeight)
                                                    }
                                      </td>
                                      <td>
                                        {item.RaceResultData
                                          ? item.RaceResultData &&
                                            item.RaceResultData
                                              .RacehorsesData[0].HorseNo
                                          : <>- </>}
                                      </td>
                                      <td
                                        className="rb"
                                        onClick={() =>
                                          handleShows(item.VideoLink)
                                        }
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        <img src={arrow1} alt="" />
                                      </td>
                                    </tr>
                                  );
                                })
                              )}
                            </>
                          )}
                        </tbody>
                      </table>
                      <div className="paginationstats">
                         <Pagination
                postsPerPage={postsPerPage}
                totalPosts={totalcountdata}
                paginate={paginate}
                currentPage={currentPage}
                TotalPages={totalpagesdata}
              />
                         </div>
                    </div>
                  </div>
                </Tab>
                <Tab
                  eventKey="Entries"
                  title={t("Entries")}
                  tabClassName="profile-tabitem"
                >
                  <OwnerEntries id={id} />
                </Tab>
                <Tab
                  eventKey="Horses"
                  title={t("Horses")}
                  tabClassName="profile-tabitem"
                >
                  <div className="RaceDetailCard">
                    <HorseOwnerStats id={id} />
                  </div>
                </Tab>
                <Tab
                  eventKey="HorseKind"
                  title={t("Horse Kind")}
                  tabClassName="profile-tabitem"

                >
                  <div className="RaceDetailCard">
                    <HorseKindOwnerStats id={id} />
                  </div>
                </Tab>
                <Tab
                  eventKey="Stats"
                  title={t("Stats")}
                  tabClassName="profile-tabitem"
                >
                  <div className="RaceDetailCard">
                    <Stats id={id} />
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      )}

      <Modal
        show={shows}
        onHide={handleCloses}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <VideoFrame data={modaldatas} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        show={ShowJockey}
        onHide={handleJockeyCloses}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <JockeyDetail data={modaldatasJockey} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        show={ShowHorse}
        onHide={handleHorseCloses}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <HorseModal data={modaldatasHorse} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        show={showRace}
        onHide={handleCloseRace}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <RaceModal id={modaldataRace} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};
export default OwnerDetail;
