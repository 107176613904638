import React from "react";
import { useTranslation } from "react-i18next";

const PleaseWait = () => {
  const { t } = useTranslation();
  return (
    <div class="pleaseWait">
      <p>{t("Please Wait")}</p>
      {/* <span>
        <i></i>
        <i></i>
      </span> */}
    </div>
  );
};

export default PleaseWait;
