import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../CSS/HomeCSS/cardslider.css";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import "../../CSS/HomeCSS/blogs.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchRace, STATUSES } from "../../../redux/getReducer/getRaceCard";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import noimage from "../../../assets/noimage1.png";
import noimagearabic from "../../../assets/nottacearabic.png";
import RaceCardSiderTimer from "../../Reuseable/RaceCardSiderTimer";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';

const RaceCardSlider = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: racecard, status } = useSelector((state) => state.racecard);

  const [state, setState] = useState(false);
  const { t } = useTranslation();

  // useEffect(() => {
  //   dispatch(fetchRace());
  // }, [dispatch]);

  const [userIsDesktop, setUserIsDesktop] = useState(true);

  useEffect(() => {
    window.innerWidth > 840 ? setUserIsDesktop(true) : setUserIsDesktop(false);
  }, [userIsDesktop]);

  let racetime = racecard.map((item) => {
    return item.StartTime;
  });

  const datetime = new Date(racetime[0]);

  const getTime = (data) => {
  };

  useEffect(() => {
    const interval = setInterval(() => getTime(datetime), 10000);
    return () => clearInterval(interval);
  }, [datetime]);

  function HandleJockey(id) {
    if (userIsDesktop === true) {
      navigate("/racedetail", {
        state: {
          id: id,
        },
      });
    } else {
      navigate("/racedetails", {
        state: {
          id: id,
        },
      });
    }
  }
  if (status === STATUSES.LOADING) {
    return (
      <div>
        {/* <Lottie animationData={Animate} loop={true}  className='Lottie slideLottie'/> */}
      </div>
    );
  }

  if (status === STATUSES.ERROR) {
    return (
      <h2
        style={{
          margin: "100px",
        }}
      >
        Something went wrong!
      </h2>
    );
  }
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrow:true,
    slidesToShow: 5,
    slidesToScroll: 1,

    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1601,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1539,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1490,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
        },
      },
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
        },
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: false,
        },
      },
    ],
  };

  const cookiedata = Cookies.get("i18next");
  const breakpoints = {
    1920: {
      slidesPerView: 5,
    },
    1600: {
      slidesPerView: 4,
    },
    1366: {
      slidesPerView: 4,
    },
    320: {
      slidesPerView: 2,
    },
    // Add more breakpoints as needed
  };

  return (
    <>
      <div className="RaceCardSlider">
        <div className="slidercards">
          {racecard.length > 0 ? (
           <>
            <Swiper breakpoints={breakpoints} slidesPerView={5} navigation={true} modules={[Navigation]} className="mySwiper">
              {
                racecard.map((item, i) => {
                  return(
                    <SwiperSlide><div
                    className={
                      item.TimeRemaining.charAt(0) === "-"
                        ? "hideClass"
                        : "singleracecard"
                    }
                    key={item.key}
                    onClick={() => HandleJockey(item._id)}
                  >
                    <p className="cardnationality" >
                      {
                        userIsDesktop == false ? <>{t("UAE")}</> : <>
                         {cookiedata === "en" ? (
                        !item.RaceCourseData ? (
                          <>- </>
                        ) : (
                          item.RaceCourseData.NationalityDataRaceCourse.NameEn
                        )
                      ) : !item.RaceCourseData ? (
                        <>- </>
                      ) : (
                        item.RaceCourseData.NationalityDataRaceCourse.NameAr
                      )}
                        </>
                      }
                     
                       <RaceCardSiderTimer
                          timer={
                            item.TimeRemaining ? item.TimeRemaining : "00:00:00"
                          }
                          starttime={item.StartTime}
                          state={state}
                          setState={setState}
                        />
                    </p>
                   

                    <p className="clubname">
                      {cookiedata === "en" ? (
                        item.RaceCourseData === null ? (
                          <>- </>
                        ) : (
                          item.RaceCourseData.TrackNameEn
                        )
                      ) : item.RaceCourseData === null ? (
                        <>- </>
                      ) : (
                        item.RaceCourseData.TrackNameAr
                      )}
                    </p>
                    <p
                      className="owner"
                      style={{
                        maxHeight: "400px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {cookiedata === "en" ? (
                        item.RaceNameModelData === null ? (
                          <>- </>
                        ) : (
                          item.RaceNameModelData.NameEn
                        )
                      ) : item.RaceNameModelData === null ? (
                        <>- </>
                      ) : (
                        item.RaceNameModelData.NameAr
                      )}
                    </p>
                    <span className="racecardrow">
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <p className="raceNo">
                          {" "}
                          {t("Race")} {item.RaceNumber} -{" "}
                        </p>
                        <p className="racedistance">
                          {item.TrackLengthData === null ? (
                            <>No Data</>
                          ) : (
                            item.TrackLengthData.TrackLength
                          )}
                          m
                        </p>
                        {/* <p>{item.TimeRemaining}</p> */}
                      </div>
                      
                    </span>
                    {/* <span className="singleracecardbtn">
                      <button>{t("Form")}</button>
                    </span> */}
                  </div></SwiperSlide>
                  )
                })
              }
      </Swiper>
           </>
          ) : (
            <>
            </>
           
          )}
          <span className="mybor"></span>
        </div>
      </div>
    </>
  );
};
export default RaceCardSlider;
