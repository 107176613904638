//.....................Import..........................//
import React, { useEffect, useState,useRef } from "react";
import Zoom from "react-reveal/Zoom";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchRace, STATUSES } from "../redux/getReducer/getCard";
import Cookies from "js-cookie";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import Header from "../Components/Reuseable/Header";
import Lottie from "lottie-react";
import Animate from "../assets/loader.json";
import flag from "../assets/United Arab Emirates.png";

const RaceCardDetail = () => {
  const { state } = useLocation();
  const { data: Card, status } = useSelector((state) => state.Card);
  const { cardid } = state;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cookiedata = Cookies.get("i18next");
  const navigate = useNavigate();


  const myRef = useRef(null);
  useEffect(() => {
    // Scroll to the component when the component mounts
    if (myRef.current) {
    
      myRef.current.scrollIntoView({ behavior: 'smooth' });
    console.log("chal ja")
    }

  }, []);
  function DataOne() {
    if (!state) {
      return (window.location.href = "/");
    }
  }

  DataOne();

  let calenderid = cardid.title ? cardid.title : cardid._id;
  let calenderdate = cardid.date ? cardid.date : cardid.RaceCourseData[0].Day;

  let datecalender = new Date(calenderdate);

  datecalender.setHours(0, 0, 0);

  // Convert the updated Date object back to a string
  const convertedDate = datecalender.toString();

  useEffect(() => {
    dispatch(fetchRace({ calenderid, convertedDate }));
  }, [calenderid, convertedDate]);

  const [userIsDesktop, setUserIsDesktop] = useState(true);

  useEffect(() => {
    window.innerWidth > 840 ? setUserIsDesktop(true) : setUserIsDesktop(false);
  }, [userIsDesktop]);

  function HandleJockey(id) {
    if (userIsDesktop === true) {
      navigate("/racedetail", {
        state: {
          id: id,
        },
      });
    } else {
      navigate("/racedetails", {
        state: {
          id: id,
        },
      });
    }
  }

  // const DataDate = new Date(singlerace.StartTime);
  function formatAMPM(date) {
    const DataDate = new Date(date);
    var hours = DataDate.getHours();
    var minutes = DataDate.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const options = {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };

  const formatDateAr = (dateStr) => {
    const date = new Date(dateStr);
    const options = {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
    };
    return date.toLocaleDateString("ar", options);
  };

  // const formatedData = formatAMPM(DataDate)

  return (
    <div ref={myRef}>
      {status !== "loading" ? (
        <Zoom>
          <div className="RaceCardDetail"  >
            {Card.length === 0 ? (
              <>
                <div className="NAclass">Loading ...</div>
              </>
            ) : (
              <>
                <div className="RaceCard">
                  {Card.map((item, ind) => {
                    return (
                      <React.Fragment key={item.id}>
                        <div className="racepagehead">
                          <div className="racepageheadflex">
                            <div className="racepagename">
                              <span>
                                {cookiedata === "en"
                                  ? item.TrackNameEn
                                  : item.TrackNameAr}
                              </span>
                              <img
                                src={
                                  flag
                                }
                                alt=""
                                className="imgflag2"
                              />
                              {item.RaceCourseData === null ? (
                                <></>
                              ) : (
                                item.RaceCourseData && (
                                  <span
                                    className="raceroursecolor3"
                                    style={{
                                      background: `${
                                        item.Color && item.Color === undefined
                                          ? ""
                                          : item.Color
                                      }`,
                                    }}
                                  ></span>
                                )
                              )}
                              <p className="racerourseDate">
                                {cookiedata === "en"
                                  ? formatDate(item?.RaceCourseData[0]?.Day)
                                  : formatDateAr(item?.RaceCourseData[0]?.Day)}
                              </p>
                            </div>

                            <div className="raceStatus">
                              <span
                                className="raceStatusitem"
                                style={{ backgroundColor: "#5EC30F" }}
                              >
                                {t("live")}
                              </span>
                              <span
                                className="raceStatusitem"
                                style={{ backgroundColor: "#FF9900" }}
                              >
                                {t("due")}
                              </span>
                              <span
                                className="raceStatusitem"
                                style={{ backgroundColor: "#FF0000" }}
                              >
                                {t("end")}
                              </span>
                              <span
                                className="raceStatusitem"
                                style={{ backgroundColor: "#000" }}
                              >
                                {t("cancel")}
                              </span>
                            </div>
                          </div>
                        </div>
                        {item.RaceCourseData.length === 0 ? (
                          <>
                            <h6 style={{ textAlign: "center" }}>
                              {" "}
                              There is No race in this racecouse{" "}
                            </h6>{" "}
                          </>
                        ) : (
                          <>
                            {item.RaceCourseData.map((data) => {
                              return (
                                <>
                                  <div className="racepagesection">
                                    <div
                                      className="row racepageitem"
                                      onClick={() => HandleJockey(data._id)}
                                    >
                                      <div className="col-sm-6 todayracefav">
                                        <div className="RaceDetailsName">
                                          <span
                                            style={{
                                              fontWeight: "300",
                                              fontSize: "20px",
                                              lineHeight: "24px",
                                            }}
                                          >
                                            <h5>
                                              {t("Race")} {data.RaceNumber}
                                            </h5>
                                          </span>
                                          <h6 className="tooltipclass">
                                            {cookiedata === "en"
                                              ? data.RaceNameModelData.NameEn
                                              : data.RaceNameModelData.NameAr}
                                          </h6>
                                          <br />
                                        </div>
                                        <p>
                                          {cookiedata === "en" ? (
                                            <>{data.DescriptionEn}</>
                                          ) : (
                                            <>{data.DescriptionAr}</>
                                          )}
                                          <span className="totalrunners">
                                            {data?.Runners} {t("runners")}
                                          </span>
                                          <span className="totalrunners">
                                            {data?.NonRunners} {t("NonRunner")}
                                          </span>
                                        </p>
                                       
                                      </div>

                                      {/* <br /> */}

                                      <div className="col-sm-3 racestatusright">
                                        <span
                                          className="racestatusclass"
                                          style={{
                                            backgroundColor: `${
                                              data.RaceStatus === "Cancelled"
                                                ? "#000000"
                                                : data.RaceStatus ===
                                                  "Completed"
                                                ? "#FF0000"
                                                : data.RaceStatus === "Live"
                                                ? "#5EC30F"
                                                : "#FF9900"
                                            }`,
                                            color: `${
                                              data.RaceStatus === "Cancelled"
                                                ? "#ffff"
                                                : data.RaceStatus === "End"
                                                ? "#00000"
                                                : data.RaceStatus === "Live"
                                                ? "#00000"
                                                : "#000000"
                                            }`,
                                          }}
                                        >
                                          <p className="StartTimeCards">
                                            {formatAMPM(
                                              data.StartTime
                                            ).toLocaleUpperCase()}
                                          </p>
                                          {/* <p className="racestatusclasstime"><Moment format="hh:mm:ss" className="racestatusclasstime">{item.StartTime}</Moment></p> */}
                                        </span>

                                       
                                      </div>
                                      <div className="col-sm-3">
                                          {
                                            <div className="row">
                                              <div className="top1">
                                                {data.Favourite1English ==
                                                  null &&
                                                data.Favourite2English ==
                                                  null &&
                                                data.Favourite3English ==
                                                  null ? (
                                                  <></>
                                                ) : (
                                                  <div className="top">
                                                    <h5
                                                      style={{
                                                        fontStyle: "normal",
                                                        fontWeight: "300",
                                                        fontSize: "12px",
                                                        lineHeight: "11px",
                                                        color:
                                                          "rgba(0, 0, 0, 0.5)",
                                                      }}
                                                    >
                                                      {t("Favourite")}
                                                    </h5>

                                                    <p
                                                      style={{
                                                        lineHeight: "1px",
                                                        color:
                                                          "rgba(0, 0, 0, 0.5)",
                                                        fontSize: "10px",
                                                        lineHeight: "0px",
                                                      }}
                                                      className="favhorses"
                                                    >
                                                      {data.Favourite1English ===
                                                      null ? (
                                                        <></>
                                                      ) : (
                                                        <p>
                                                          (
                                                          {
                                                            data.FavouriteHorseNumber1
                                                          }
                                                          )
                                                          {cookiedata === "en"
                                                            ? data.Favourite1English
                                                            : data.Favourite1Arabic}{" "}
                                                          -{" "}
                                                          {
                                                            data.FavouriteRating1
                                                          }
                                                        </p>
                                                      )}
                                                      {data.Favourite2English ===
                                                      null ? (
                                                        <></>
                                                      ) : (
                                                        <p>
                                                          (
                                                          {
                                                            data.FavouriteHorseNumber2
                                                          }
                                                          )
                                                          {cookiedata === "en"
                                                            ? data.Favourite2English
                                                            : data.Favourite2Arabic}{" "}
                                                          -{" "}
                                                          {
                                                            data.FavouriteRating1
                                                          }
                                                        </p>
                                                      )}
                                                      {data.Favourite3English ===
                                                      null ? (
                                                        <></>
                                                      ) : (
                                                        <p>
                                                          (
                                                          {
                                                            data.FavouriteHorseNumber3
                                                          }
                                                          )
                                                          {cookiedata === "en"
                                                            ? data.Favourite3English
                                                            : data.Favourite3Arabic}{" "}
                                                          -{" "}
                                                          {
                                                            data.FavouriteRating1
                                                          }
                                                        </p>
                                                      )}
                                                    </p>
                                                  </div>
                                                )}
                                              </div>
                                              <div className="top1">
                                                {data.FirstRankHorseEnglish ==
                                                  null &&
                                                data.SecondRankHorseEnglish ==
                                                  null &&
                                                data.ThirdRankHorseEnglish ==
                                                  null ? (
                                                  <></>
                                                ) : (
                                                  <div className="top">
                                                    <h5
                                                      style={{
                                                        fontStyle: "normal",
                                                        fontWeight: "300",
                                                        fontSize: "12px",
                                                        lineHeight: "11px",
                                                        color:
                                                          "rgba(0, 0, 0, 0.5)",
                                                      }}
                                                    >
                                                      {t("Top")}
                                                    </h5>
                                                    <p>
                                                      ({t("1st")})
                                                      {cookiedata === "en"
                                                        ? data.FirstRankHorseEnglish
                                                        : data.FirstRankHorseArabic}{" "}
                                                      -{" "}
                                                      {
                                                        data.FirstRankHorseNumber
                                                      }{" "}
                                                    </p>
                                                    <p>
                                                      ({t("2nd")})
                                                      {cookiedata === "en"
                                                        ? data.SecondRankHorseEnglish
                                                        : data.SecondRankHorseArabic}
                                                      -{" "}
                                                      {
                                                        data.SecondRankHorseNumber
                                                      }{" "}
                                                    </p>
                                                    <p>
                                                      ({t("3rd")})
                                                      {cookiedata === "en"
                                                        ? data.ThirdRankHorseEnglish
                                                        : data.ThirdRankHorseArabic}{" "}
                                                      -{" "}
                                                      {
                                                        data.ThirdRankHorseNumber
                                                      }
                                                    </p>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          }
                                        </div>
                                        <div className="col-sm-12 racedown">
                                          <p
                                            style={{
                                              color: "#ff0000",
                                            }}
                                          >
                                            <b style={{ color: "#000" }}>
                                              {" "}
                                              {t("Distance")} :
                                            </b>{" "}
                                            {" "}
                                            {data.TrackLengthData
                                              .TrackLength === null ? (
                                              <>- </>
                                            ) : (
                                              data.TrackLengthData.TrackLength
                                            )}
                                          </p>
                                          {/* <p> {cookiedata === "en" ? (data.RaceTypeModelData.NameEn === null ? <></> : data.RaceTypeModelData.NameEn) : (data.RaceTypeModelData.NameAr === null ? <></> : data.RaceTypeModelData.NameAr)} </p> */}
                                          <p
                                            style={{
                                              color: `${
                                                data.TrackLengthData
                                                  ?.GroundTypeModelData
                                                  ?.NameEn === "Turf"
                                                  ? "#5ec40f"
                                                  : "#9d642f"
                                              }`,
                                            }}
                                          >
                                            <b style={{ color: "#000" }}>
                                              {t("Ground")} :
                                            </b>{" "}
                                            {" "}
                                            {cookiedata === "en" ? (
                                              !data.TrackLengthData ? (
                                                <>- </>
                                              ) : (
                                                data.TrackLengthData
                                                  ?.GroundTypeModelData?.NameEn
                                              )
                                            ) : !data.TrackLengthData ? (
                                              <>- </>
                                            ) : (
                                              data.TrackLengthData
                                                ?.GroundTypeModelData?.NameAr
                                            )}
                                          </p>
                                          <p
                                            style={{
                                              color: "#ff0000",
                                            }}
                                          >
                                            <b style={{ color: "#000" }}>
                                              {t("RaceType")} :
                                            </b>{" "}
                                            {" "}
                                            {cookiedata === "en" ? (
                                              !data.RaceTypeModelData ? (
                                                <>- </>
                                              ) : (
                                                data.RaceTypeModelData.NameEn
                                              )
                                            ) : !data.GroundData ? (
                                              <>- </>
                                            ) : (
                                              data.RaceTypeModelData.NameAr
                                            )}
                                          </p>

                                          <p
                                            style={{
                                              color: "#ff0000",
                                            }}
                                          >
                                            <b style={{ color: "#000" }}>
                                            {t("Total Prize")} :
                                            </b>{" "}
                                            
                                            {" "}
                                            {data?.FirstPrice +
                                              data?.SecondPrice +
                                              data?.ThirdPrice +
                                              data?.FourthPrice +
                                              data?.FifthPrice +
                                              data?.SixthPrice}
                                          </p>



                                        </div>{" "}
                                    </div>

                                  </div>
                                </>
                              );
                            })}
                          </>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </Zoom>
      ) : (
        <h2 className="RaceCardDetail">
          <Lottie animationData={Animate} loop={true} className="TableLottie" />
        </h2>
      )}
    </div>
  );
};

export default RaceCardDetail;
