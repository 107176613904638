import axios from 'axios';
const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getRaceCourseStats = createSlice({
    name: 'racecoursestats',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchRaceCourseStats.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchRaceCourseStats.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE;
            })
            .addCase(fetchRaceCourseStats.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            });
    },
});

export const { setRace, setStatus } = getRaceCourseStats.actions;
export default getRaceCourseStats.reducer;

export const fetchRaceCourseStats = createAsyncThunk('RaceCourseForStatistics/fetch', async ({ start }) => {
    const res = await axios.get(`${window.env.API_URL}/RaceCourseForStatistics?Date=${start}`)
    const data = res.data;
    return data.data;
});


