import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import Moment from "react-moment";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { fetchPedigree } from "../../redux/getReducer/getPedigree";
import {
  fetchSingleHorse,
  STATUSES,
} from "../../redux/getReducer/getSingleHorse";
import { useDispatch, useSelector } from "react-redux";
import DefaultImg from "../../assets/Frame.png";
import { useTranslation } from "react-i18next";
import PleaseWait from "../Reuseable/PleaseWait";
import arrow1 from "../../assets/image 13 (Traced).png";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import VideoFrame from "./VideoFrame";
import { fetchHorseRecord } from "../../redux/getReducer/getHorseRecord";
import { fetchhorsehistory } from "../../redux/getReducer/getHorseHistory";
import { fetchHorseFutureEntries } from "../../redux/getReducer/getFutureEntries";
import HorseStats from "../Tabs/HorseStats";
import OwnerDetail from "./OwnerModal";
import TrainerDetail from "./TrainerModal";
import BeatenByModal from "./HorseModal";
import JockeyDetail from "./JockeyModal";
import RaceModal from "./RaceModal";
import Lottie from "lottie-react";
import Animate from "../../assets/loader.json";
import Pagination from "../../pages/Pagination";

const HorseDetail = (data) => {
  const { t } = useTranslation();
  const cookiedata = Cookies.get("i18next");
  const [shows, setShows] = useState(false);
  const [modaldatas, setmodaldatas] = useState();
  const handleCloses = () => setShows(false);
  const handleShows = async (data) => {
    setmodaldatas(data);
    await setShows(true);
  };

  const { data: Pedigree } = useSelector((state) => state.Pedigree);
  const { data: horsehistory } = useSelector((state) => state.horsehistory);
  const { data: horserecord } = useSelector((state) => state.horserecord);
  const { data: singlehorse, status } = useSelector(
    (state) => state.singlehorse
  );
  const { data: fututreentries } = useSelector((state) => state.fututreentries);

  const [TrackStatus, SetTrackStatus] = useState(false);
  const [ShowOwner, setShowOwner] = useState(false);
  const [modaldatasowner, setmodaldatasowner] = useState();
  const handleOwnerCloses = () => setShowOwner(false);
  const handleShowOwner = async (data) => {
    setmodaldatasowner(data);
    await setShowOwner(true);
  };

  const [ShowTrainer, setShowTrainer] = useState(false);
  const [modaldatasTrainer, setmodaldatasTrainer] = useState();
  const handleTrainerCloses = () => setShowTrainer(false);
  const handleShowTrainer = async (data) => {
    setmodaldatasTrainer(data);
    await setShowTrainer(true);
  };

  const [showRace, setShowRace] = useState(false);
  const [modaldataRace, setmodaldataRace] = useState();
  const handleCloseRace = () => setShowRace(false);
  const handleShowRace = async (id) => {
    setmodaldataRace(id);
    await setShowRace(true);
  };

  const [ShowHorse, setShowHorse] = useState(false);
  const [modaldatasHorse, setmodaldatasHorse] = useState();
  const handleHorseCloses = () => setShowHorse(false);
  const handleShowHorse = async (data) => {
    setmodaldatasHorse(data);
    await setShowHorse(true);
  };

  const [ShowJockey, setShowJockey] = useState(false);
  const [modaldatasJockey, setmodaldatasJockey] = useState();
  const handleJockeyCloses = () => setShowJockey(false);
  const handleShowJockey = async (data) => {
    setmodaldatasJockey(data);
    await setShowJockey(true);
  };

  let totalcountdata = horserecord ? horserecord?.length : "";
  const [postsPerPage] = useState(11);
  const totalpagesdata = Math.ceil(totalcountdata / postsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const firstPageIndex = (currentPage - 1) * postsPerPage;
  const lastPageIndex = firstPageIndex + postsPerPage;
  const SliceData = horserecord?.slice(firstPageIndex, lastPageIndex);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const navigate = useNavigate();
  const btnNew1 = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "13px 10px",
    gap: "10px",
    width: "112px",
    height: "24px",
    background: "#FF0000",
    borderRadius: "10px",
    border: "none",
    color: "#fff",
    margin: "10px 27px",
  };
  const btnNew2 = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "13px 10px",
    gap: "10px",
    width: "112px",
    height: "24px",
    background: "#0388fc",
    borderRadius: "10px",
    border: "none",
    color: "#fff",
    margin: "6px",
  };
  const token = Cookies.get("token");
  const handleUnTrack = async (Id) => {
    try {
      await axios.post(
        `${window.env.API_URL}/UnTrackHorse`,
        { Horse: Id },
        {
          headers: { Authorization: token, "x-xsrf-token": token },
        }
      );
      toast("Horse Has Been UnTracked ");
      navigate("/tracker/#Horseshome");
      // dispatch(fetchSingleHorse({ Id }));
      // navigate("/tracker");
    } catch (error) {
      const err = error.response.data.message;
      toast(err);
    }
  };

  const handleTrack = async (Id) => {
    try {
      await axios.post(
        `${window.env.API_URL}/trackhorse`,
        { Horse: Id },
        {
          headers: { Authorization: token, "x-xsrf-token": token },
        }
      );
      // toast("Tracked Success");

      navigate("/tracker/#Horseshome");
    } catch (error) {
      const err = error.response.data.message;
      if (err === "Json Web Token is invalid") {
        toast.error("Please Login to Access This Resourse");
        navigate("/login");
      } else {
        toast.error(err);
      }
    }
  };

  const id = data.data.HorseModelIdData1
    ? data.data.HorseModelIdData1._id
    : data.data._id
    ? data.data._id
    : data.data;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPedigree({ id }));
    dispatch(fetchHorseRecord({ id }));
    dispatch(fetchSingleHorse({ id }));
    dispatch(fetchHorseFutureEntries({ id }));
    dispatch(fetchhorsehistory({ id }));
  }, [dispatch, id]);
  function calculatePercentage(x, y) {
    if (x === 0 && y === 0) return 0;
    else {
      const answer = Math.round((x / y) * 100);
      return answer;
    }
  }

  function formatAMPM(date) {
    const datenow = new Date(date);
    var hours = datenow.getHours();
    var minutes = datenow.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  function distanceLabel(distance, rank) {
    const DistanceTobeChecked = parseFloat(distance);
    console.log(DistanceTobeChecked);
    if (DistanceTobeChecked.toFixed(2) > 25) {
      return "Dist";
    } else if (DistanceTobeChecked.toFixed(2) == 0.05 && rank < 80) {
      return "SHd";
    } else if (DistanceTobeChecked.toFixed(2) == 0.1 && rank < 80) {
      return "Hd";
    } else if (DistanceTobeChecked.toFixed(2) == 0.25 && rank < 80) {
      return "Nk";
    } else if (DistanceTobeChecked.toFixed(2) == 0.01 && rank < 80) {
      return "Ns";
    } else if (DistanceTobeChecked.toFixed(2) == 0.0 && rank < 80) {
      if (rank === 1) {
        return 0;
      }
      return "Dh";
    } else {
      return distance;
    }
  }
  function calculateAge(dateOfBirth) {
    var dob = new Date(dateOfBirth);
    var today = new Date();

    var yearsDiff = today.getFullYear() - dob.getFullYear();
    var monthsDiff = today.getMonth() - dob.getMonth();
    var daysDiff = today.getDate() - dob.getDate();

    // Check if the current month and day are before the birth month and day
    if (monthsDiff < 0 || (monthsDiff === 0 && daysDiff < 0)) {
      yearsDiff--;
    }

    return yearsDiff;
  }
  return (
    <>
      {status === "loading" ? (
        <>
          <Lottie animationData={Animate} loop={true} className="TableLottie" />
        </>
      ) : (
        <div className="RaceDetailCard">
          {singlehorse && (
            <>
              <div className="horseheader1">
                <div className="horseshirt">
                  <img
                    src={
                      !data.data.CapColorData1
                        ? DefaultImg
                        : data.data.CapColorData1.OwnerSilkColor
                    }
                    alt=""
                    className="horseshirtimage"
                  />
                </div>
                
                <div className="horsecenterdata changecolor1">
                  <span
                    style={{
                      display: "flex",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: "700",
                        fontSize: " 19.6px",
                        lineHeight: "24px",
                        // color: "red",
                        margin: "0px 10px",
                      }}
                    >
                      {cookiedata === "en" ? (
                        singlehorse === null ? (
                          <></>
                        ) : (
                          window.textTransform(singlehorse.NameEn)
                        )
                      ) : singlehorse.HorseModelIdData1 === null ? (
                        <></>
                      ) : (
                        singlehorse.NameAr
                      )}
                    </p>
        
                    <p
                      style={{
                        fontSize: "12px",
                        marginLeft: "20px",
                      }}
                    >
                      {singlehorse.DOB === null ? (
                        <>No Data</>
                      ) : (
                        <>
                          ({" "}
                          {cookiedata === "en" ? (
                            singlehorse.NationalityData ? (
                              <>
                               {cookiedata === "en" ?  singlehorse.NationalityData &&
                    singlehorse.NationalityData.AbbrevEn : singlehorse.NationalityData &&
                    singlehorse.NationalityData.AbbrevAr}
                              </>
                            ) : (
                              <>-</>
                            )
                          ) : (
                            <>
                              {singlehorse.NationalityData ? (
                                singlehorse.NationalityData.NameAr
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                          ) ({" "}
                          {singlehorse.SexModelData ? (
                            <>
                              {cookiedata === "en"
                                ?  window.textTransform(singlehorse.SexModelData.NameEn)
                                : singlehorse.SexModelData.NameAr}
                            </>
                          ) : (
                            <>-</>
                          )}
                          )
                        </>
                      )}{" "}
                      {singlehorse.CapColorData1 ? (
                        <>{ window.textTransform(singlehorse.CapColorData1.NameEn)}</>
                      ) : (
                        <></>
                      )}-
                    {calculateAge(
                                                  singlehorse.DOB
                                                )}yrs. 
                    
                      {singlehorse.DaysGap ? (
                        <>({singlehorse.DaysGap})</>
                      ) : (
                        <></>
                      )}
                    </p>
                    <p
                      style={{
                        fontSize: "12px",
                        marginLeft: "20px",
                      }}
                    >
                      {t("Rating")}: {data.data.Rating}
                    </p>
                  </span>
                  <span
                    style={{
                      display: "flex",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "12px",
                        paddingLeft: "10px",
                      }}
                    >
                      {t("Dam")}
                      <b
                        style={{
                          paddingLeft: "5px",
                        }}
                      >
                        {cookiedata === "en"
                          ? singlehorse.DamData
                            ?  window.textTransform(singlehorse?.DamData?.NameEn)
                            : <>- </>
                          : singlehorse.DamData
                          ? singlehorse.DamData.NameAr
                          : <>- </>}
                      </b>
                    </p>
                    <span> {" "} </span>
                    <p
                      style={{
                        fontSize: "12px",
                        paddingLeft: "10px",
                      }}
                    >
                      {t("Sire")}
                      <b
                        style={{
                          paddingLeft: "5px",
                        }}
                      >
                        {" "}
                        {cookiedata === "en"
                          ? singlehorse.SireData
                            ?  window.textTransform(singlehorse?.SireData?.NameEn)
                            : <>- </>
                          : singlehorse.SireData
                          ? singlehorse.SireData.NameAr
                          : <>- </>}
                      </b>
                    </p>
                    <span> {" "} </span>
                    <p
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      {t("G Sire")}
                      <b
                        style={{
                          paddingLeft: "5px",
                        }}
                      >
                        {" "}
                        {cookiedata === "en"
                          ? singlehorse.GSireData
                            ?  window.textTransform(singlehorse?.GSireData?.NameEn)
                            : <>- </>
                          : singlehorse.GSireData
                          ? singlehorse.GSireData.NameAr
                          : <>- </>}
                      </b>
                    </p>
                  </span>
                  <span
                    style={{
                      display: "flex",
                    }}
                    className="ownerSection"
                  >
                    <p
                      style={{
                        fontSize: "12px",
                        color: "red",
                        display: "flex",
                        marginLeft: "10px",
                      }}
                    >
                      <b>O : </b>
                      {cookiedata === "en" ? (
                        singlehorse.ActiveOwnerData === undefined ? (
                          <></>
                        ) : singlehorse.ActiveOwnerData &&
                          singlehorse.ActiveOwnerData === null ? (
                          <></>
                        ) : (
                          <p
                            onClick={() =>
                              handleShowOwner(singlehorse.ActiveOwnerData)
                            }
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            {singlehorse.ActiveOwnerData &&
                            singlehorse.ActiveOwnerData.NameEn === undefined ? (
                              <></>
                            ) : (
                              singlehorse.ActiveOwnerData &&
                              window.textTransform(singlehorse?.ActiveOwnerData?.NameEn)
                            )}
                          </p>
                        )
                      ) : singlehorse.ActiveOwnerData &&
                        singlehorse.ActiveOwnerData === null ? (
                        <></>
                      ) : (
                        singlehorse.ActiveOwnerData &&
                        singlehorse.ActiveOwnerData.NameAr
                      )}
                    </p>
                    <p
                      style={{
                        fontWeight: "800",
                        fontSize: "11px",
                        lineHeight: "15px",
                        color: "red",
                        margin: "2px 15px",
                      }}
                    >
                      {singlehorse.ActiveOwnerData &&
                        singlehorse.ActiveOwnerData.OwnerCount}
                      (
                      {singlehorse.ActiveOwnerData &&
                        singlehorse.ActiveOwnerData.OwnerFirstRanking}{" "}
                      -{" "}
                      {singlehorse.ActiveOwnerData &&
                        singlehorse.ActiveOwnerData.OwnerSecondRanking}{" "}
                      -{" "}
                      {singlehorse.ActiveOwnerData &&
                        singlehorse.ActiveOwnerData.OwnerThirdRanking}
                      )
                    </p>
                  </span>
                  <div className="horsedetailimage">
            
                    <span>
                      <span style={{ display: "flex" }}>
                        <p
                          style={{
                            fontSize: "12px",
                            lineHeight: "10px",
                            marginLeft: "10px",
                            fontWeight: "600",
                            cursor: "pointer",
                            color:"red"
                          }}
                          onClick={() =>
                            handleShowTrainer(singlehorse.ActiveTrainerData)
                          }
                        >
                          <b
                            style={{
                              padding: "10px",
                            }}
                          >
                            T :
                          </b>
                          {cookiedata === "en" ? (
                            singlehorse.ActiveTrainerData === undefined ? (
                              <></>
                            ) : singlehorse.ActiveTrainerData &&
                              singlehorse.ActiveTrainerData === null ? (
                              <></>
                            ) : singlehorse.ActiveTrainerData.NameEn ===
                              undefined ? (
                              <></>
                            ) : (
                              window.textTransform(singlehorse?.ActiveTrainerData?.NameEn)
                            )
                          ) : singlehorse.ActiveTrainerData === null ? (
                            <></>
                          ) : (
                            singlehorse.ActiveTrainerData &&
                            singlehorse.ActiveTrainerData.NameAr
                          )}
                        </p>
                        <p
                          style={{
                            fontWeight: "800",
                            fontSize: "11px",
                            lineHeight: "15px",
                            color: "red",
                            margin: "-1px 5px",
                          }}
                        >
                          {singlehorse.ActiveTrainerData ? (
                            singlehorse.ActiveTrainerData.TrainerCount
                          ) : (
                            <>-</>
                          )}
                          ( (
                          {singlehorse.ActiveTrainerData &&
                            singlehorse.ActiveTrainerData
                              .TrainerFirstRanking}{" "}
                          -{" "}
                          {singlehorse.ActiveTrainerData &&
                            singlehorse.ActiveTrainerData
                              .TrainerSecondRanking}{" "}
                          -{" "}
                          {singlehorse.ActiveTrainerData &&
                            singlehorse.ActiveTrainerData.TrainerThirdRanking}
                          ) )
                        </p>
                      </span>

                      <p
                        style={{
                          fontSize: "12px",
                          marginLeft: "10px",
                        }}
                        
                      >
                        <b
                          style={{
                            padding: "10px",
                          }}
                          
                        >
                          B :
                        </b>

                        {singlehorse.BreederData === undefined ? (
                          <></>
                        ) : singlehorse.BreederData && cookiedata === "en" ? (
                          singlehorse.BreederData === null ? (
                            <></>
                          ) : (
                            window.textTransform(singlehorse?.BreederData?.NameEn)
                          )
                        ) : singlehorse.BreederData === null ? (
                          <></>
                        ) : singlehorse.BreederData.NameAr &&
                          singlehorse.BreederData.NameAr === undefined ? (
                          <>0</>
                        ) : (
                          singlehorse.BreederData.NameAr
                        )}
                      </p>
                    </span>
                  </div>
                </div>
                <div className="horseimage">
                  <img
                    src={
                      singlehorse.HorseImage
                        ? singlehorse.HorseImage
                        : DefaultImg
                    }
                    alt=""
                  />
                  {singlehorse.track == true ? (
                    <button
                      style={btnNew2}
                      onClick={() => handleUnTrack(singlehorse._id)}
                    >
                      {t("Untrack")}
                    </button>
                  ) : (
                    <button
                      style={btnNew1}
                      onClick={() => handleTrack(singlehorse._id)}
                    >
                      {t("Track")}
                    </button>
                  )}
                </div>
              </div>

              <div className="row" style={{margin:"10px"}}>
                 <div className="col-sm-12 pmclass">
                 <p>
                   {t("PM")} :
                   <b>
                     {data
                       ? data
                           .TotalPrizeWin
                       : 0}
                   </b>
                 </p>
                 <p>
                   {t("BTO")} :
                   <b>
                     {data ? (
                       data.TotalBto
                     ) : (
                       <> 0</>
                     )}
                   </b>
                 </p>
                 <p>
                   {t("SP")}{" "}
                   <b>
                     {data ? (
                       data.PurchasePrice
                     ) : (
                       <> 0</>
                     )}
                   </b>
                 </p>
               </div>
              </div>
             
            </>
          )}

          <div className="horsebody">
            <div className="horsebodyitem">
              <p
                style={{
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                {t("Form")}
              </p>
            </div>
            <div className="horsehistorylist">
              <p
                style={{
                  color: "#fff",
                  padding: "10px",
                }}
              >
                {singlehorse.HorseForm && singlehorse.HorseForm}
              </p>
            </div>
            <div className="mycardclass1">
              <div className="BodyNew mpobile0">
                <table className="customers horseModalTable">
                  <thead className="customers5 ">
                    <tr>
                      <th>{t("Lifetime Record")}</th>
                      <th>{t("Runs")}</th>
                      <th>{t("Wins")}</th>
                      <th>{t("2nd")}</th>
                      <th>{t("3rd")}</th>
                      <th>{t("4th")}</th>
                      <th>{t("5th")}</th>
                      <th>{t("Winnings")}</th>
                      <th>{t("Earnings")}</th>
                      {/* <th>{t("OR")} </th> */}
                    </tr>
                  </thead>
                  <tbody className="customers1">
                    <>
                      <tr className="BodyNew1 ">
                        <td className="lb">{t("TURF")}</td>
                        <td>{singlehorse.TurfCount}</td>
                        <td>
                          {singlehorse.TurfWins === null
                            ? 0
                            : singlehorse.TurfWins}
                        </td>
                        <td>
                          {singlehorse.Turfsecond === null
                            ? 0
                            : singlehorse.Turfsecond}
                        </td>
                        <td>
                          {singlehorse.Turfthird === null
                            ? 0
                            : singlehorse.Turfthird}
                        </td>
                        <td>
                          {singlehorse.Turffourth === null
                            ? 0
                            : singlehorse.Turffourth}
                        </td>
                        <td>
                          {singlehorse.Turffifth === null
                            ? 0
                            : singlehorse.Turffifth}
                        </td>
                        <td>
                          {calculatePercentage(
                            singlehorse.Turfsecond === null
                              ? 0
                              : singlehorse.Turfsecond +
                                  singlehorse.TurfWins ===
                                null
                              ? 0
                              : singlehorse.TurfWins + singlehorse.Turfthird ===
                                null
                              ? 0
                              : singlehorse.Turfthird,
                            singlehorse.TurfCount
                          )}
                          %
                        </td>
                        <td>
                          {singlehorse.TurfEarnings === null
                            ? 0
                            : singlehorse.TurfEarnings}
                        </td>
                      </tr>
                      <tr className="BodyNew1 ">
                        <td className="lb">{t("DIRT")}</td>
                        <td>{singlehorse.DirtCount}</td>
                        <td>
                          {singlehorse.DirtWins === null
                            ? 0
                            : singlehorse.DirtWins}
                        </td>
                        <td>
                          {singlehorse.Dirtsecond === null
                            ? 0
                            : singlehorse.Dirtsecond}
                        </td>
                        <td>
                          {singlehorse.Dirtthird === null
                            ? 0
                            : singlehorse.Dirtthird}
                        </td>
                        <td>
                          {singlehorse.Dirtfourth === null
                            ? 0
                            : singlehorse.Dirtfourth}
                        </td>
                        <td>
                          {singlehorse.Dirtfifth === null
                            ? 0
                            : singlehorse.Dirtfifth}
                        </td>
                        <td>
                          {calculatePercentage(
                            singlehorse.Dirtsecond === null
                              ? 0
                              : singlehorse.Dirtsecond +
                                  singlehorse.DirtWins ===
                                null
                              ? 0
                              : singlehorse.DirtWins + singlehorse.Dirtthird ===
                                null
                              ? 0
                              : singlehorse.Dirtthird,
                            singlehorse.DirtCount
                          )}
                          %
                        </td>
                        <td>
                          {singlehorse.DirtEarnings === null
                            ? 0
                            : singlehorse.DirtEarnings}
                        </td>
                      </tr>

                      <tr className="BodyNew1 ">
                        <td className="lb">{t("ALL WEATHER")}</td>
                        <td>{singlehorse.AllWeatherCount}</td>
                        <td>
                          {singlehorse.AllWeatherWins === null
                            ? 0
                            : singlehorse.AllWeatherWins}
                        </td>
                        <td>
                          {singlehorse.AllWeathersecond === null
                            ? 0
                            : singlehorse.AllWeathersecond}
                        </td>
                        <td>
                          {singlehorse.AllWeatherthird === null
                            ? 0
                            : singlehorse.AllWeatherthird}
                        </td>
                        <td>
                          {singlehorse.AllWeatherfourth === null
                            ? 0
                            : singlehorse.AllWeatherfourth}
                        </td>
                        <td>
                          {singlehorse.AllWeatherfifth === null
                            ? 0
                            : singlehorse.AllWeatherfifth}
                        </td>
                        <td>
                          {calculatePercentage(
                            singlehorse.AllWeathersecond === null
                              ? 0
                              : singlehorse.AllWeathersecond +
                                  singlehorse.AllWeatherWins ===
                                null
                              ? 0
                              : singlehorse.AllWeatherWins + singlehorse.AllWeatherthird ===
                                null
                              ? 0
                              : singlehorse.AllWeatherthird,
                            singlehorse.AllWeatherCount
                          )}
                          %
                        </td>
                        <td>
                          {singlehorse.AllWeatherEarnings === null
                            ? 0
                            : singlehorse.AllWeatherEarnings}
                        </td>
                      </tr>

                      <tr className="BodyNew1 historyhorseth">
                        <td className="lb">{t("TOTAL")}</td>
                        <td>{singlehorse.TotalCount}</td>
                        <td>
                          {singlehorse.TotalWins === null
                            ? 0
                            : singlehorse.TotalWins}
                        </td>
                        <td>
                          {singlehorse.Totalsecond === null
                            ? 0
                            : singlehorse.Totalsecond}
                        </td>
                        <td>
                          {singlehorse.Totalthird === null
                            ? 0
                            : singlehorse.Totalthird}
                        </td>
                        <td>
                          {singlehorse.Totalfourth === null
                            ? 0
                            : singlehorse.Totalfourth}
                        </td>
                        <td>
                          {singlehorse.Totalfifth === null
                            ? 0
                            : singlehorse.Totalfifth}
                        </td>
                        <td>
                          {calculatePercentage(
                            singlehorse.Totalsecond === null
                              ? 0
                              : singlehorse.Totalsecond +
                                  singlehorse.TotalWins ===
                                null
                              ? 0
                              : singlehorse.TotalWins +
                                  singlehorse.Totalthird ===
                                null
                              ? 0
                              : singlehorse.Totalthird,
                            singlehorse.TotalCount
                          )}
                          %
                        </td>
                        <td>
                          {singlehorse.TotalEarnings === null
                            ? 0
                            : singlehorse.TotalEarnings}
                        </td>
                      </tr>
                    </>
                  </tbody>
                </table>
              </div>
              <h2 className="Pedigreeclass2">{t("Pedigree")}</h2>
              <div className="RaceDetailCard">
                <div className="Pedigree">
                  {Pedigree.length === 0 ? (
                    <h3>.</h3>
                  ) : (
                    <div className="wrapper">
                      <div className="one">
                        <div className="sire pedigreeclass">
                          {Pedigree.generation1 === null ? (
                            <>-</>
                          ) : Pedigree?.generation1?.SireData === null ? (
                            <>-</>
                          ) : cookiedata === "en" ? (
                            Pedigree.generation1?.SireData?.NameEn
                          ) : (
                            Pedigree.generation1?.SireData?.NameAr
                          )}
                        </div>
                        <div className="dam pedigreeclass">
                          {Pedigree.generation1 === null ? (
                            <>-</>
                          ) : Pedigree.generation1?.DamData === null ? (
                            <>-</>
                          ) : cookiedata === "en" ? (
                            Pedigree.generation1?.DamData?.NameEn
                          ) : (
                            Pedigree.generation1?.DamData?.NameAr
                          )}
                        </div>
                      </div>
                      <div className="two">
                        <div className="gsire pedigreeclass">
                          {Pedigree.generation2b === null ? (
                            <>-</>
                          ) : Pedigree.generation2b?.SireData === null ? (
                            <>-</>
                          ) : cookiedata === "en" ? (
                            Pedigree.generation2b?.SireData?.NameEn
                          ) : (
                            Pedigree.generation2b?.SireData?.NameAr
                          )}
                        </div>
                        <div className="gdam pedigreeclass">
                          {Pedigree.generation2b === null ? (
                            <>-</>
                          ) : Pedigree.generation2b?.DamData === null ? (
                            <>-</>
                          ) : cookiedata === "en" ? (
                            Pedigree.generation2b?.DamData?.NameEn
                          ) : (
                            Pedigree.generation2b?.DamData?.NameAr
                          )}
                        </div>
                        <div className="gsire pedigreeclass">
                          {Pedigree.generation2a === null ? (
                            <>-</>
                          ) : Pedigree.generation2a?.SireData === null ? (
                            <>-</>
                          ) : cookiedata === "en" ? (
                            Pedigree.generation2a?.SireData?.NameEn
                          ) : (
                            Pedigree.generation2a?.SireData?.NameAr
                          )}
                        </div>
                        <div className="gdam pedigreeclass">
                          {Pedigree.generation2a === null ? (
                            <>-</>
                          ) : Pedigree.generation2a?.DamData === null ? (
                            <>-</>
                          ) : cookiedata === "en" ? (
                            Pedigree.generation2a?.DamData?.NameEn
                          ) : (
                            Pedigree.generation2a?.DamData?.NameAr
                          )}
                        </div>
                      </div>
                      <div className="three">
                        <div className="ggsire pedigreeclass">
                          {Pedigree.generation3d === null ? (
                            <>-</>
                          ) : Pedigree.generation3d.SireData === null ? (
                            <>-</>
                          ) : cookiedata === "en" ? (
                            Pedigree.generation3d?.SireData?.NameEn
                          ) : (
                            Pedigree.generation3d?.SireData?.NameAr
                          )}
                        </div>
                        <div className="ggdam pedigreeclass">
                          {Pedigree.generation3d === null ? (
                            <>-</>
                          ) : Pedigree.generation3d.DamData === null ? (
                            <>-</>
                          ) : cookiedata === "en" ? (
                            Pedigree.generation3d?.DamData?.NameEn
                          ) : (
                            Pedigree.generation3d?.DamData?.NameAr
                          )}
                        </div>
                        <div className="ggsire pedigreeclass">
                          {Pedigree.generation3c === null ? (
                            <>-</>
                          ) : Pedigree.generation3c?.SireData === null ? (
                            <>-</>
                          ) : cookiedata === "en" ? (
                            Pedigree.generation3c.SireData.NameEn
                          ) : (
                            Pedigree.generation3c.SireData.NameAr
                          )}
                        </div>
                        <div className="ggdam pedigreeclass">
                          {Pedigree.generation3c === null ? (
                            <>-</>
                          ) : Pedigree.generation3c?.DamData === null ? (
                            <>-</>
                          ) : cookiedata === "en" ? (
                            Pedigree.generation3c.DamData.NameEn
                          ) : (
                            Pedigree.generation3c.DamData.NameAr
                          )}
                        </div>
                        <div className="ggsire pedigreeclass">
                          {Pedigree.generation3b === null ? (
                            <>-</>
                          ) : Pedigree.generation3b?.SireData === null ? (
                            <>-</>
                          ) : Pedigree.generation3b?.SireData === null ? (
                            <>-</>
                          ) : cookiedata === "en" ? (
                            Pedigree.generation3b?.SireData?.NameEn
                          ) : (
                            Pedigree.generation3b?.SireData?.NameAr
                          )}
                        </div>
                        <div className="ggdam pedigreeclass">
                          {Pedigree.generation3b === null ? (
                            <>-</>
                          ) : Pedigree.generation3a.DamData === null ? (
                            <>-</>
                          ) : Pedigree.generation3b?.DamData?.NameEn ===
                            null ? (
                            <>-</>
                          ) : cookiedata === "en" ? (
                            Pedigree.generation3b?.DamData?.NameEn
                          ) : (
                            Pedigree.generation3b?.DamData?.NameAr
                          )}
                        </div>
                        <div className="ggsire pedigreeclass">
                          {Pedigree.generation3a === null ? (
                            <>-</>
                          ) : Pedigree.generation3a?.SireData === null ? (
                            <>-</>
                          ) : cookiedata === "en" ? (
                            Pedigree.generation3a?.SireData.NameEn
                          ) : (
                            Pedigree.generation3a?.SireData.NameAr
                          )}
                        </div>
                        <div className="ggdam pedigreeclass">
                          {Pedigree.generation3a === null ? (
                            <>-</>
                          ) : Pedigree.generation3a?.DamData === null ? (
                            <>-</>
                          ) : cookiedata === "en" ? (
                            Pedigree.generation3a?.DamData?.NameEn
                          ) : (
                            Pedigree.generation3a?.DamData?.NameAr
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="RaceNav HorseNav">
            <Tabs id="uncontrolled-tab-example" className="mb-3 RaceNavItem">
              <Tab
                eventKey="Form"
                title={t("Form")}
                tabClassName="profile-tabitem"
              >
                <div className="RaceDetailCard">
                  <div className="mycardclass1">
                    <div className="BodyNew mpobile0">
                      <table className="customers">
                        <thead className="customers5">
                          <tr>
                            <th>{t("Date")}</th>
                            <th>{t("Crs")}</th>
                            <th>{t("Dist")}</th>
                            <th>{t("TC")}</th>
                            <th>{t("RT")}</th>

                            <th>{t("Dts")}</th>
                            <th>{t("Time")}</th>
                            <th>{t("Jockey")}</th>
                            <th>{t("Wgt")}</th>

                            <th>{t("FP")}</th>
                            <th>{t("Len")}</th>
                            <th>{t("RN")}</th>
                            <th>{t("BtBy")}</th>
                            <th>{t("Kgs")}</th>

                            <th>{t("Draw")}</th>
                            <th>{t("Link")}</th>
                          </tr>
                        </thead>
                        <tbody className="customers1">
                          {SliceData?.length === undefined ? (
                            <></>
                          ) : (
                            <>
                              {!SliceData ? (
                                <PleaseWait />
                              ) : (
                                SliceData?.map((item) => {
                                  return (
                                    <tr
                                      className="BodyNew1 historyhorseth"
                                      style={{
                                        background: `${
                                          item.RaceResultData
                                            ? item.RaceResultData
                                                .RacehorsesData[0]
                                                .RacehorsesData.TrackLengthData
                                                .GroundTypeModelData.NameEn ==
                                              "Dirt"
                                              ? "#9F5109"
                                              : item.RaceResultData
                                                  .RacehorsesData[0]
                                                  .RacehorsesData
                                                  .TrackLengthData
                                                  .GroundTypeModelData.NameEn ==
                                                "Turf"
                                              ? "#5ec40f"
                                              : "#949494"
                                            : "#949494"
                                        }`,
                                      }}
                                    >
                                      <td
                                        className="lb"
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          handleShowRace(item.RaceID)
                                        }
                                      >
                                        <Moment format="DD/MM/YYYY">
                                          {item.RaceResultData === null ? (
                                            <></>
                                          ) : (
                                            item.RaceResultData.Day
                                          )}
                                        </Moment>
                                      </td>
                                      <td>
                                        {item.RaceResultData
                                          ? cookiedata === "en"
                                            ? item.RaceResultData &&
                                              item.RaceResultData
                                                ?.RacehorsesData[0]
                                                ?.RacehorsesData?.RaceCourseData
                                                ?.AbbrevEn
                                            : item.RaceResultData &&
                                              item.RaceResultData
                                                ?.RacehorsesData[0]
                                                ?.RacehorsesData?.RaceCourseData
                                                ?.AbbrevAr
                                          : <>- </>}
                                        (
                                        {cookiedata === "en"
                                          ? item.RaceResultData &&
                                            item.RaceResultData?.RacehorsesData[0]?.RacehorsesData.TrackLengthData?.GroundTypeModelData.NameEn.charAt(
                                              0
                                            )
                                          : item.RaceResultData
                                              ?.RacehorsesData[0]
                                              ?.RacehorsesData?.TrackLengthData
                                              ?.GroundTypeModelData.NameAr}
                                        )
                                      </td>
                                      <td>
                                        {item.RaceResultData ? (
                                          item.RaceResultData.RacehorsesData[0]
                                            .RacehorsesData.TrackLengthData
                                            .TrackLength
                                        ) : (
                                          <>-</>
                                        )}
                                      </td>
                                      <td>
                                        {item.RaceResultData
                                          ? cookiedata === "en"
                                            ? item.RaceResultData &&
                                              item.RaceResultData
                                                ?.RacehorsesData[0]
                                                ?.RacehorsesData
                                                ?.TrackConditionData.NameEn
                                            : item.RaceResultData &&
                                              item.RaceResultData
                                                ?.RacehorsesData[0]
                                                ?.RacehorsesData
                                                ?.TrackConditionData.NameAr
                                          : "_"}
                                      </td>
                                      <td>
                                        <OverlayTrigger
                                          className="tooltip-btn"
                                          overlay={
                                            <Tooltip
                                              id="tooltip-disabled"
                                              className="tooltip-btn"
                                            >
                                              {item.RaceResultData &&
                                              item.RaceResultData.RacehorsesData
                                                ? cookiedata === "en"
                                                  ? item?.RaceResultData
                                                      ?.RacehorsesData[0]
                                                      ?.RacehorsesData
                                                      ?.RaceTypeModelData
                                                      ?.NameEn
                                                  : item.RaceResultData &&
                                                    item?.RaceResultData
                                                      ?.RacehorsesData[0]
                                                      ?.RacehorsesData
                                                      ?.RaceTypeModelData
                                                      ?.NameAr
                                                : "_"}
                                            </Tooltip>
                                          }
                                        >
                                          <span
                                            className="d-inline-block"
                                            style={{
                                              marginTop: "12px",
                                            }}
                                          >
                                            <p className="tooltipclass1">
                                              {" "}
                                              {item.RaceResultData &&
                                              item.RaceResultData.RacehorsesData
                                                ? cookiedata === "en"
                                                  ? item.RaceResultData
                                                      .RacehorsesData[0]
                                                      .RacehorsesData
                                                      .RaceTypeModelData.NameEn
                                                  : item.RaceResultData &&
                                                    item.RaceResultData
                                                      .RacehorsesData[0]
                                                      .RacehorsesData
                                                      .RaceTypeModelData.NameAr
                                                : "_"}
                                            </p>
                                          </span>
                                        </OverlayTrigger>
                                      </td>
                                      <td>
                                        {item.PrizeWin ? item.PrizeWin : 0}
                                      </td>
                                      <td>{item.RaceTime}</td>
                                      <td
                                        onClick={() =>
                                          handleShowJockey(
                                            item.JockeyOnRaceDataResult
                                              ? item.JockeyOnRaceDataResult._id
                                              : ""
                                          )
                                        }
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        {item.JockeyOnRaceDataResult
                                          ? cookiedata === "en"
                                            ?  window.textTransform(item.JockeyOnRaceDataResult.NameEn)
                                            : item.JockeyOnRaceDataResult.NameAr
                                          : "_"}
                                      </td>
                                      <td>
                                        {item.RaceResultData
                                          ? item.RaceResultData
                                              .RacehorsesData[0]
                                              .JockeyRaceWeight
                                          : "_"}
                                      </td>
                                      <td>
                                        {item.FinalPositionDataHorse ===
                                        null ? (
                                          <></>
                                        ) : (
                                          <>
                                            {cookiedata === "en" ? (
                                              <>
                                                {cookiedata === "en"
                                                  ? item.FinalPositionDataHorse &&
                                                    item.FinalPositionDataHorse
                                                      .NameEn
                                                  : item.FinalPositionDataHorse &&
                                                    item.FinalPositionDataHorse
                                                      .NameAr}
                                              </>
                                            ) : (
                                              <>
                                                {
                                                  item.FinalPositionDataHorse
                                                    .NameAr
                                                }
                                              </>
                                            )}
                                          </>
                                        )}
                                      </td>
                                      <td>
                                        {distanceLabel(
                                          item.Distance && item.Distance,
                                          item.FinalPositionDataHorse &&
                                            item.FinalPositionDataHorse?.Rank
                                        )}
                                      </td>
                                      <td>{item.Runners}</td>
                                      {/* <td>6</td> */}
                                      {/* <td>16.25</td> */}
                                      <td
                                        // onClick={() =>
                                        //   handleShow(
                                        //     item.BeatenByData
                                        //       ? item.BeatenByData
                                        //       : ""
                                        //   )
                                        // }
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        {item.BeatenByData === null ? (
                                          <>
                                            {cookiedata === "en"
                                              ?  window.textTransform(item?.HorseIDData
                                                  ?.HorseBeatenByEnglishName)
                                              : item.HorseIDData
                                                  .HorseBeatenByArabicName}
                                          </>
                                        ) : (
                                          <>
                                            {cookiedata === "en" ? (
                                              <>{ window.textTransform(item?.BeatenByData?.NameEn)}</>
                                            ) : (
                                              <>{item.BeatenByData.NameAr}</>
                                            )}
                                          </>
                                        )}
                                      </td>{" "}
                                      <td>
                                        {item.BeatenByData !== null
                                          ? item.BeatenByData
                                              .WinningHorseJockeyWeight
                                          : item.HorseIDData
                                              .BeatenByJockeyWeight}
                                      </td>
                                      <td>
                                        {item.RaceResultData
                                          ? item.RaceResultData &&
                                            item.RaceResultData
                                              .RacehorsesData[0].HorseNo
                                          : <>- </>}
                                      </td>
                                      <td
                                        className="rb"
                                        onClick={() =>
                                          handleShows(item.VideoLink)
                                        }
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        <img src={arrow1} alt="" />
                                      </td>
                                    </tr>
                                  );
                                })
                              )}
                            </>
                          )}
                        </tbody>
                      </table>
                      <Pagination
                        postsPerPage={postsPerPage}
                        totalPosts={totalcountdata}
                        paginate={paginate}
                        currentPage={currentPage}
                        TotalPages={totalpagesdata}
                      />
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="Entries"
                title={t("Entries")}
                tabClassName="profile-tabitem"
              >
                <div className="RaceDetailCard">
                  <div className="mycardclass1 entryTable">
                    <div className="BodyNew mpobile0">
                      <table className="customers">
                        <thead className="customers5">
                          <tr>
                            <th>{t("Date")}</th>
                            <th>{t("Crs")}</th>
                            <th>{t("TC")}</th>
                            <th>{t("RT")}</th>
                            <th>{t("Time")}</th>
                            <th>{t("Jockey")}</th>
                            <th>{t("Len")}</th>
                            <th>{t("RN")}</th>

                            <th>{t("Kgs")}</th>

                            <th>{t("Draw")}</th>
                          </tr>
                        </thead>
                        <tbody className="customers1">
                          {fututreentries === undefined ? (
                            <PleaseWait />
                          ) : (
                            <>
                              {!fututreentries ? (
                                <PleaseWait />
                              ) : (
                                fututreentries.map((item) => {
                                  return (
                                    <tr
                                      className="BodyNew1 historyhorseth "
                                      // style={{
                                      //   backgroundColor: "#949494",
                                      // }}
                                      style={{
                                        background: `${
                                          item.RacehorsesData.TrackLengthData
                                            .GroundTypeModelData.NameEn ==
                                          "Dirt"
                                            ? "#9F5109"
                                            : item.RacehorsesData
                                                .TrackLengthData
                                                .GroundTypeModelData.NameEn ==
                                              "Turf"
                                            ? "#5ec40f"
                                            : "#949494"
                                        }`,
                                      }}
                                    >
                                      <td
                                        className="lb"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleShowRace(
                                            item.RacehorsesData._id
                                          )
                                        }
                                      >
                                        <Moment format="DD/MM/YYYY">
                                          {!item.RaceResultData ? (
                                            <></>
                                          ) : (
                                            item.RaceResultData.Day
                                          )}
                                        </Moment>
                                      </td>
                                      <td>
                                        {item.RacehorsesData
                                          ? cookiedata === "en"
                                            ? item.RacehorsesData.TrackLengthData.GroundTypeModelData.NameEn.charAt(
                                                0
                                              )
                                            : item.RacehorsesData.TrackLengthData.GroundTypeModelData.NameAr.charAt(
                                                0
                                              )
                                          : "-"}
                                        (
                                        {item.RacehorsesData
                                          ? cookiedata === "en"
                                            ? item.RacehorsesData &&
                                              item.RacehorsesData.RaceCourseData
                                                .TrackNameEn
                                            : ""
                                          : "-"}
                                        )
                                      </td>
                                      <td>
                                        {item.RacehorsesData
                                          ? cookiedata === "en"
                                            ? item.RacehorsesData
                                                .TrackConditionData.NameEn
                                            : item.RacehorsesData
                                                .TrackConditionData.NameAr
                                          : "_"}
                                      </td>
                                      <td>
                                        <OverlayTrigger
                                          className="tooltip-btn"
                                          overlay={
                                            <Tooltip
                                              id="tooltip-disabled"
                                              className="tooltip-btn"
                                            >
                                              {item.RacehorsesData
                                                ? cookiedata === "en"
                                                  ? item.RacehorsesData
                                                      .RaceTypeModelData.NameEn
                                                  : item.RacehorsesData
                                                      .RaceTypeModelData.NameAr
                                                : "_"}
                                            </Tooltip>
                                          }
                                        >
                                          <span
                                            className="d-inline-block"
                                            style={{
                                              marginTop: "12px",
                                            }}
                                          >
                                            <p className="tooltipclass1">
                                              {item.RacehorsesData
                                                ? cookiedata === "en"
                                                  ? item.RacehorsesData
                                                      .RaceTypeModelData.NameEn
                                                  : item.RacehorsesData
                                                      .RaceTypeModelData.NameAr
                                                : "_"}
                                            </p>
                                          </span>
                                        </OverlayTrigger>
                                      </td>

                                      <td>
                                        <Moment format="hh:mm:ss">
                                          {!item.RaceResultData ? (
                                            <></>
                                          ) : (
                                            item.RaceResultData.Day
                                          )}
                                        </Moment>
                                      </td>

                                      <td
                                        onClick={() =>
                                          handleShowJockey(
                                            item.JockeyOnRaceData1
                                              ? item.JockeyOnRaceData1
                                              : ""
                                          )
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        {item.JockeyOnRaceData1
                                          ? cookiedata === "en"
                                            ? item.JockeyOnRaceData1.ShortNameEn
                                            : item.JockeyOnRaceData1.ShortNameAr
                                          : "_"}
                                      </td>

                                      <td>
                                        {item.RacehorsesData &&
                                          item.RacehorsesData.TrackLengthData
                                            .TrackLength}
                                      </td>
                                      <td>{item.Runners}</td>

                                      <td>{item.JockeyRaceWeight} </td>
                                      <td className="rb">
                                        {item.GateNo ? item.GateNo : <>- </>}
                                      </td>
                                    </tr>
                                  );
                                })
                              )}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="Stats"
                title={t("Stats")}
                tabClassName="profile-tabitem"
              >
                <div className="RaceDetailCard">
                  <HorseStats id={id} />
                </div>
              </Tab>
              {/* <Tab
        eventKey="Pedigree"
        title="Pedigree"
        tabClassName="profile-tabitem"
      >
        
      </Tab> */}
            </Tabs>
          </div>
          <Modal
            show={shows}
            onHide={handleCloses}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header className="popupheader" closeButton></Modal.Header>
            <Modal.Body>
              <VideoFrame data={modaldatas} />
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>

          <Modal
            show={ShowOwner}
            onHide={handleOwnerCloses}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header className="popupheader" closeButton></Modal.Header>
            <Modal.Body>
              <OwnerDetail data={modaldatasowner} />
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>

          <Modal
            show={ShowTrainer}
            onHide={handleTrainerCloses}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header className="popupheader" closeButton></Modal.Header>
            <Modal.Body>
              <TrainerDetail data={modaldatasTrainer} />
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>

          <Modal
            show={ShowHorse}
            onHide={handleHorseCloses}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header className="popupheader" closeButton></Modal.Header>
            <Modal.Body>
              <BeatenByModal data={modaldatasHorse} />
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>

          <Modal
            show={ShowJockey}
            onHide={handleJockeyCloses}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header className="popupheader" closeButton></Modal.Header>
            <Modal.Body>
              <JockeyDetail data={modaldatasJockey} />
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
          <Modal
            show={showRace}
            onHide={handleCloseRace}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header className="popupheader" closeButton></Modal.Header>
            <Modal.Body>
              <RaceModal id={modaldataRace} />
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
};
export default HorseDetail;
