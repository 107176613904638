import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getracecardscreenSlice = createSlice({
    name: 'racecardscreen',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchracecardscreen.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchracecardscreen.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchracecardscreen.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setracecardscreen, setStatus } = getracecardscreenSlice.actions;
export default getracecardscreenSlice.reducer;

export const fetchracecardscreen = createAsyncThunk('/racecardscreenget/fetch', async ({start}) => {
    const res = await axios.get(`${window.env.API_URL}/RaceScreenForSubscriber?Date=${start}`);
    const racecardscreenData = res.data;
    return racecardscreenData.data;
})
