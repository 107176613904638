import React, { useState } from "react";
import axios from "axios";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Cookies from "js-cookie";
import Moment from "react-moment";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Defaultimg from "../../assets/Frame.png";
import Stats from "../Tabs/Stats";
import { useEffect } from "react";
import PleaseWait from "../../Components/Reuseable/PleaseWait";
import arrow1 from "../../assets/image 13 (Traced).png";

import { useDispatch, useSelector } from "react-redux";
import { fetchTrainerHistory } from "../../redux/getReducer/getTrainerHistory";
import {
  fetchSingletrainer,
  STATUSES,
} from "../../redux/getReducer/getSingleTrainer";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import HorseModal from "./HorseModal";
import { useTranslation } from "react-i18next";
import TrainerEntries from "../Tabs/TrainerEntries";
import { Modal } from "react-bootstrap";
import VideoFrame from "./VideoFrame";
import JockeyDetail from "./JockeyModal";
import RaceModal from "./RaceModal";
import Lottie from "lottie-react";
import Animate from "../../assets/loader.json";
import Pagination from "../../pages/Pagination";

const TrainerDetail = (data) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // let trainerid = data.data._id;
  const trainerid = data.data.TrainerOnRaceData1
    ? data.data.TrainerOnRaceData1._id
    : data.data._id
    ? data.data._id
    : data.data;

  const { data: TrainerHistory } = useSelector((state) => state.TrainerHistory);
  const { data: singletrainer, status } = useSelector(
    (state) => state.singletrainer
  );
  const btnNew2 = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "13px 10px",
    gap: "10px",
    width: "112px",
    height: "24px",
    background: "#0388fc",
    borderRadius: "10px",
    border: "none",
    color: "#fff",
    margin: "6px",
  };
  const dispatch = useDispatch();
  const [shows, setShows] = useState(false);
  const [modaldatas, setmodaldatas] = useState();
  const handleCloses = () => setShows(false);
  const handleShows = async (data) => {
    setmodaldatas(data);
    await setShows(true);
  };
  const [showRace, setShowRace] = useState(false);
  const [modaldataRace, setmodaldataRace] = useState();
  const handleCloseRace = () => setShowRace(false);
  const handleShowRace = async (id) => {
    setmodaldataRace(id);
    await setShowRace(true);
  };

  const [ShowJockey, setShowJockey] = useState(false);
  const [modaldatasJockey, setmodaldatasJockey] = useState();
  const handleJockeyCloses = () => setShowJockey(false);
  const handleShowJockey = async (data) => {
    setmodaldatasJockey(data);
    await setShowJockey(true);
  };
  const [ShowHorse, setShowHorse] = useState(false);
  const [modaldatasHorse, setmodaldatasHorse] = useState();
  const handleHorseCloses = () => setShowHorse(false);
  const handleShowHorse = async (data) => {
    setmodaldatasHorse(data);
    await setShowHorse(true);
  };

  let totalcountdata = TrainerHistory ? TrainerHistory?.length : "";
  const [postsPerPage] = useState(11);
  const totalpagesdata = Math.ceil(totalcountdata / postsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const firstPageIndex = (currentPage - 1) * postsPerPage;
  const lastPageIndex = firstPageIndex + postsPerPage;
  const SliceData =  TrainerHistory.slice(firstPageIndex, lastPageIndex);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  useEffect(() => {
    dispatch(fetchTrainerHistory({ trainerid }));
    dispatch(fetchSingletrainer({ trainerid }));
  }, [dispatch, trainerid]);

  const btnNew1 = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "13px 10px",
    gap: "10px",
    width: "112px",
    height: "24px",
    background: "#FF0000",
    borderRadius: "10px",
    border: "none",
    color: "#fff",
    margin: "6px",
  };
  const cookiedata = Cookies.get("i18next");
  const token = Cookies.get("token");
  const handleUnTrack = async (Id) => {
    try {
      await axios.post(
        `${window.env.API_URL}/UnTrackTrainer`,
        { Trainer: Id },
        {
          headers: { Authorization: token, "x-xsrf-token": token },
        }
      );
      toast("Trainer Has Been UnTracked ");
      navigate("/tracker/#Trainerprofile");
      // dispatch(fetchSingletrainer({ Id }));
      // navigate("/tracker");
    } catch (error) {
      const err = error.response.data.message;
      toast(err);
    }
  };

  const handleTrack = async (Id) => {
    try {
      const res = await axios.post(
        `${window.env.API_URL}/tracktrainer`,
        { Trainer: Id },
        {
          headers: { Authorization: token, "x-xsrf-token": token },
        }
      );
      // toast('Tracked Success')
      navigate("/tracker/#Trainerprofile");
    } catch (error) {
      const err = error.response.data.message;
      if (err === "Json Web Token is invalid") {
        toast.error("Please Login to Access This Resource");
        navigate("/login");
      } else {
        toast.error(err);
      }
    }
  };
  function calculatePercentage(x, y) {
    if (x === 0 && y === 0) return 0;
    else {
      return Math.round((x / y) * 100);
    }
  }


  function distanceLabel(distance, rank) {
    const DistanceTobeChecked = parseFloat(distance);
    if (DistanceTobeChecked.toFixed(2) > 25) {
      return "Dist";
    } else if (DistanceTobeChecked.toFixed(2) == 0.05 && rank < 80) {
      return "SHd";
    } else if (DistanceTobeChecked.toFixed(2) == 0.10 && rank < 80) {
      return "Hd";
    } else if (DistanceTobeChecked.toFixed(2) == 0.25 && rank < 80) {
      return "Nk";
    } else if (DistanceTobeChecked.toFixed(2) == 0.01 && rank < 80) {
      return "Ns";
    } else if (DistanceTobeChecked.toFixed(2) == 0.00 && rank < 80) {
      if(rank === 1){
        return 0;
      }
      return "Dh";
    } else {
      return distance;
    }
  }

  return (
    <>
      {status === "loading" ? (
        <>
          <Lottie animationData={Animate} loop={true} className="TableLottie" />
        </>
      ) : (
        <div className="aboutpage">
          <div className="RaceDetailCard">
            <div className="horseheader1">
              <div className="horseshirt">
                <img
                  src={singletrainer.image ? singletrainer.image : Defaultimg}
                  alt=""
                  className="horseshirtimage"
                  style={{
                    height: "90px",
                  }}
                />
              </div>

              <div className="horsecenterdata jockeyname1">
                <span
                  style={{
                    display: "flex",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "700",
                      fontSize: " 19.6px",
                      lineHeight: "24px",
                      color: "#19469D",
                      textTransform:"capitalize",
                    }}
                  >
                    {cookiedata === "en"
                      ? singletrainer.NameEn
                        ? singletrainer.NameEn
                        : "-"
                      : singletrainer.NameAr
                      ? singletrainer.NameAr
                      : "-"}
                  </p>


                  <p
                                      style={{
                                        fontWeight: "400",
                                        fontSize: "14px",
                                        lineHeight: "15px",
                                        color: "rgba(0, 0, 0, 0.8)",
                                        margin:"5px"
                                      }}
                                    >
                                      {singletrainer &&
                                        singletrainer.TotalCount}
                                      (
                                      {singletrainer &&
                                        singletrainer
                                          .TrainerFirstRanking}{" "}
                                      -{" "}
                                      {singletrainer &&
                                        singletrainer
                                          .TrainerSecondRanking}{" "}
                                      -{" "}
                                      {singletrainer &&
                                        singletrainer
                                          .TrainerThirdRanking}
                                      )
                                    </p>


                </span>

                <p
                  style={{
                    fontWeight: "400",
                    fontSize: " 13.6px",
                    color: "#19469D",
                  }}
                   className="mynation"
                >
                 <b> {t("Nationality")}:{" "}</b> 
                 

{cookiedata === "en" ?  singletrainer.TrainerNationalityData &&
                    singletrainer.TrainerNationalityData.AbbrevEn : singletrainer.TrainerNationalityData &&
                    singletrainer.TrainerNationalityData.AbbrevAr}
                </p>
              </div>

              <div className="ownerTrack">
                {singletrainer.track == true ? (
                  <button
                    style={btnNew2}
                    onClick={() => handleUnTrack(singletrainer._id)}
                  >
                    {t("Untrack")}
                  </button>
                ) : (
                  <button
                    style={btnNew1}
                    onClick={() => handleTrack(singletrainer._id)}
                  >
                    {t("Track")}
                  </button>
                )}
              </div>
            </div>
            <div className="horsebody">
              <div className="mycardclass1">
                <div className="BodyNew">
                  <table className="customers horseModalTable">
                    <thead className="customers5 ">
                      <tr>
                        <th>{t("Lifetime Record")}</th>
                        <th>{t("Runs")}</th>
                        <th>{t("Wins")}</th>
                        <th>{t("2nd")}</th>
                        <th>{t("3rd")}</th>
                        <th>{t("4th")}</th>
                        <th>{t("5th")}</th>
                        <th>{t("Winnings")}</th>
                        <th>{t("Earnings")}</th>
                        {/* <th>{t("OR")} </th> */}
                      </tr>
                    </thead>
                    <tbody className="customers1">
                      <>
                        <tr className="BodyNew1 ">
                          <td className="lb">{t("TURF")}</td>
                          <td>{singletrainer.TurfCount}</td>
                          <td>
                            {singletrainer.TurfWins === null
                              ? 0
                              : singletrainer.TurfWins}
                          </td>
                          <td>
                            {singletrainer.Turfsecond === null
                              ? 0
                              : singletrainer.Turfsecond}
                          </td>
                          <td>
                            {singletrainer.Turfthird === null
                              ? 0
                              : singletrainer.Turfthird}
                          </td>
                          <td>
                            {singletrainer.Turffourth === null
                              ? 0
                              : singletrainer.Turffourth}
                          </td>
                          <td>
                            {singletrainer.Turffifth === null
                              ? 0
                              : singletrainer.Turffifth}
                          </td>
                          <td>
                            {calculatePercentage(
                              singletrainer.Turfsecond === null
                                ? 0
                                : singletrainer.Turfsecond +
                                    singletrainer.TurfWins ===
                                  null
                                ? 0
                                : singletrainer.TurfWins +
                                    singletrainer.Turfthird ===
                                  null
                                ? 0
                                : singletrainer.Turfthird,
                              singletrainer.TurfCount
                            )}
                            %
                          </td>
                          <td>
                            {singletrainer.TurfEarnings === null ? 0 : <></>}
                          </td>
                        </tr>
                        <tr className="BodyNew1 ">
                          <td className="lb">{t("DIRT")}</td>
                          <td>{singletrainer.DirtCount}</td>
                          <td>
                            {singletrainer.DirtWins === null
                              ? 0
                              : singletrainer.DirtWins}
                          </td>
                          <td>
                            {singletrainer.Dirtsecond === null
                              ? 0
                              : singletrainer.Dirtsecond}
                          </td>
                          <td>
                            {singletrainer.Dirtthird === null
                              ? 0
                              : singletrainer.Dirtthird}
                          </td>
                          <td>
                            {singletrainer.Dirtfourth === null
                              ? 0
                              : singletrainer.Dirtfourth}
                          </td>
                          <td>
                            {singletrainer.Dirtfifth === null
                              ? 0
                              : singletrainer.Dirtfifth}
                          </td>
                          <td>
                            {calculatePercentage(
                              singletrainer.Dirtsecond === null
                                ? 0
                                : singletrainer.Dirtsecond +
                                    singletrainer.DirtWins ===
                                  null
                                ? 0
                                : singletrainer.DirtWins +
                                    singletrainer.Dirtthird ===
                                  null
                                ? 0
                                : singletrainer.Dirtthird,
                              singletrainer.DirtCount
                            )}
                            %
                          </td>
                          <td>
                            {singletrainer.DirtEarnings === null
                              ? 0
                              : singletrainer.DirtEarnings}
                          </td>
                        </tr>

                        <tr className="BodyNew1 ">
                        <td className="lb">{t("ALL WEATHER")}</td>
                        <td>{singletrainer.AllWeatherCount}</td>
                        <td>
                          {singletrainer.AllWeatherWins === null
                            ? 0
                            : singletrainer.AllWeatherWins}
                        </td>
                        <td>
                          {singletrainer.AllWeathersecond === null
                            ? 0
                            : singletrainer.AllWeathersecond}
                        </td>
                        <td>
                          {singletrainer.AllWeatherthird === null
                            ? 0
                            : singletrainer.AllWeatherthird}
                        </td>
                        <td>
                          {singletrainer.AllWeatherfourth === null
                            ? 0
                            : singletrainer.AllWeatherfourth}
                        </td>
                        <td>
                          {singletrainer.AllWeatherfifth === null
                            ? 0
                            : singletrainer.AllWeatherfifth}
                        </td>
                        <td>
                          {calculatePercentage(
                            singletrainer.AllWeathersecond === null
                              ? 0
                              : singletrainer.AllWeathersecond +
                                  singletrainer.AllWeatherWins ===
                                null
                              ? 0
                              : singletrainer.AllWeatherWins + singletrainer.AllWeatherthird ===
                                null
                              ? 0
                              : singletrainer.AllWeatherthird,
                            singletrainer.AllWeatherCount
                          )}
                          %
                        </td>
                        <td>
                          {singletrainer.AllWeatherEarnings === null
                            ? 0
                            : singletrainer.AllWeatherEarnings}
                        </td>
                      </tr>

                        <tr className="BodyNew1 historyhorseth">
                          <td className="lb">{t("TOTAL")}</td>
                          <td>{singletrainer.TotalCount}</td>
                          <td>
                            {singletrainer.TotalWins === null
                              ? 0
                              : singletrainer.TotalWins}
                          </td>
                          <td>
                            {singletrainer.Totalsecond === null
                              ? 0
                              : singletrainer.Totalsecond}
                          </td>
                          <td>
                            {singletrainer.Totalthird === null
                              ? 0
                              : singletrainer.Totalthird}
                          </td>
                          <td>
                            {singletrainer.Totalfourth === null
                              ? 0
                              : singletrainer.Totalfourth}
                          </td>
                          <td>
                            {singletrainer.Totalfifth === null
                              ? 0
                              : singletrainer.Totalfifth}
                          </td>
                          <td>
                            {calculatePercentage(
                              singletrainer.Totalsecond === null
                                ? 0
                                : singletrainer.Totalsecond +
                                    singletrainer.TotalWins ===
                                  null
                                ? 0
                                : singletrainer.TotalWins +
                                    singletrainer.Totalthird ===
                                  null
                                ? 0
                                : singletrainer.Totalthird,
                              singletrainer.TotalCount
                            )}
                            %
                          </td>
                          <td>
                            {singletrainer.TotalEarnings === null
                              ? 0
                              : singletrainer.TotalEarnings}
                          </td>
                        </tr>
                      </>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="RaceNav HorseNav">
              <Tabs id="uncontrolled-tab-example" className="mb-3 RaceNavItem">
                <Tab
                  eventKey="Form"
                  title={t("Form")}
                  tabClassName="profile-tabitem"
                >
                  <div className="RaceDetailCard">
                    <div className="mycardclass1">
                      <div className="BodyNew">
                        <table className="customers">
                          <thead className="customers5">
                            <tr>
                              <th>{t("Date")}</th>
                              <th>{t("Crs")}</th>
                              <th>{t("Dist")}</th>
                              <th>{t("TC")}</th>
                              <th>{t("RT")}</th>

                              <th>{t("Dts")}</th>
                              <th>{t("Time")}</th>
                              <th>{t("Jockey")}</th>
                              <th>{t("Wgt")}</th>
                              <th>{t("FP")}</th>
                              <th>{t("Len")}</th>
                              <th>{t("RN")}</th>
                              <th>{t("BtBy")}</th>
                              <th>{t("Kgs")}</th>

                              <th>{t("Draw")}</th>
                              <th>{t("Link")}</th>
                            </tr>
                          </thead>
                          <tbody className="customers1">
                            {TrainerHistory === undefined ? (
                              <PleaseWait />
                            ) : (
                              <>
                                {!SliceData ? (
                                  <PleaseWait />
                                ) : (
                                  SliceData &&
                                  SliceData.map((item) => {
                                    return (
                                      <tr
                                      className="BodyNew1 historyhorseth"
                                      style={{
                                        background: `${
                                          item.RaceResultData
                                            ? item.RaceResultData
                                                .RacehorsesData[0]
                                                .RacehorsesData.TrackLengthData
                                                .GroundTypeModelData.NameEn ==
                                              "Dirt"
                                              ? "#9F5109"
                                              : item.RaceResultData
                                                  .RacehorsesData[0]
                                                  .RacehorsesData
                                                  .TrackLengthData
                                                  .GroundTypeModelData.NameEn ==
                                                "Turf"
                                              ? "#5ec40f"
                                              : "#949494"
                                            : "#949494"
                                        }`,
                                      }}
                                    >
                                      <td
                                        style={{ cursor: "pointer" }}
                                        className="lb"
                                        onClick={() =>
                                          handleShowRace(item.RaceID)
                                        }
                                      >
                                        <Moment format="DD/MM/YYYY">
                                          {item.RaceResultData === null ? (
                                            <></>
                                          ) : (
                                            item.RaceResultData.Day
                                          )}
                                        </Moment>
                                      </td>
                                      <td>
                                        {item.RaceResultData ? (
                                          cookiedata === "en" ? (
                                            item.RaceResultData &&
                                            item.RaceResultData
                                              .RacehorsesData[0].RacehorsesData
                                              .RaceCourseData ? (
                                              item.RaceResultData
                                                .RacehorsesData[0]
                                                .RacehorsesData.RaceCourseData
                                                .TrackNameEn
                                            ) : (
                                              <>-</>
                                            )
                                          ) : item.RaceResultData &&
                                            item.RaceResultData
                                              .RacehorsesData[0].RacehorsesData
                                              .RaceCourseData ? (
                                            item.RaceResultData
                                              .RacehorsesData[0].RacehorsesData
                                              .RaceCourseData.TrackNameAr
                                          ) : (
                                            <>-</>
                                          )
                                        ) : (
                                          <>- </>
                                        )}
                                        (
                                        {cookiedata === "en" ? (
                                          item.RaceResultData &&
                                          item.RaceResultData ? (
                                            item.RaceResultData.RacehorsesData[0].RacehorsesData.TrackLengthData.GroundTypeModelData.NameEn.charAt(
                                              0
                                            )
                                          ) : (
                                            <>-</>
                                          )
                                        ) : item.RaceResultData ? (
                                          item.RaceResultData.RacehorsesData[0].RacehorsesData.TrackLengthData.GroundTypeModelData.NameAr.charAt(
                                            0
                                          )
                                        ) : (
                                          <>-</>
                                        )}
                                        )
                                      </td>
                                      <td>
                                      {item.RaceResultData ? (
                                          item.RaceResultData.RacehorsesData[0]
                                            .RacehorsesData.TrackLengthData
                                            .TrackLength
                                        ) : (
                                          <>-</>
                                        )}
                                                 
                                        </td>
                                      <td>
                                        {item.RaceResultData
                                          ? cookiedata === "en"
                                            ? item.RaceResultData &&
                                              item.RaceResultData
                                                .RacehorsesData[0]
                                                .RacehorsesData
                                                .TrackConditionData.NameEn
                                            : item.RaceResultData &&
                                              item.RaceResultData
                                                .RacehorsesData[0]
                                                .RacehorsesData
                                                .TrackConditionData.NameAr
                                          : "_"}
                                      </td>
                                      <td>
                                        <OverlayTrigger
                                          className="tooltip-btn"
                                          overlay={
                                            <Tooltip
                                              id="tooltip-disabled"
                                              className="tooltip-btn"
                                            >
                                              {item.RaceResultData &&
                                              item.RaceResultData.RacehorsesData
                                                ? cookiedata === "en"
                                                  ? item.RaceResultData &&
                                                    item.RaceResultData
                                                      ?.RacehorsesData &&
                                                    item.RaceResultData
                                                      ?.RacehorsesData[0]
                                                      ?.RacehorsesData
                                                      ?.RaceTypeModelData.NameEn
                                                  : item.RaceResultData &&
                                                    item.RaceResultData
                                                      ?.RacehorsesData &&
                                                    item.RaceResultData
                                                      ?.RacehorsesData[0]
                                                      ?.RacehorsesData
                                                      ?.RaceTypeModelData.NameAr
                                                : "_"}
                                            </Tooltip>
                                          }
                                        >
                                          <span
                                            className="d-inline-block"
                                            style={{
                                              marginTop: "12px",
                                            }}
                                          >
                                            <p className="tooltipclass1">
                                              {" "}
                                              {item.RaceResultData &&
                                              item.RaceResultData.RacehorsesData
                                                ? cookiedata === "en"
                                                  ? item.RaceResultData &&
                                                    item.RaceResultData
                                                      ?.RacehorsesData[0]
                                                      ?.RacehorsesData
                                                      ?.RaceTypeModelData.NameEn
                                                  : item.RaceResultData &&
                                                    item.RaceResultData
                                                      ?.RacehorsesData[0]
                                                      ?.RacehorsesData
                                                      ?.RaceTypeModelData.NameAr
                                                : "_"}
                                            </p>
                                          </span>
                                        </OverlayTrigger>
                                      </td>
                                      <td>
                                        {item.PrizeWin ? item.PrizeWin : 0}
                                      </td>
                                      <td>{item.RaceTime}</td>
                                      <td
                                        onClick={() =>
                                          handleShowJockey(
                                            item.JockeyOnRaceDataResult
                                              ? item.JockeyOnRaceDataResult
                                              : ""
                                          )
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        {item.JockeyOnRaceDataResult
                                          ? cookiedata === "en"
                                            ?  window.textTransform(item.JockeyOnRaceDataResult?.ShortNameEn)
                                            : item.JockeyOnRaceDataResult?.ShortNameAr
                                          : "_"}
                                      </td>

                                      <td>
                                        {item.RaceResultData
                                          ? item.RaceResultData &&
                                            item.RaceResultData
                                              .RacehorsesData[0].JockeyRaceWeight
                                          : <>- </>}
                                      </td>


                                      <td>
                                        {item.FinalPositionDataHorse ===
                                        null ? (
                                          <></>
                                        ) : (
                                          <>
                                            {cookiedata === "en" ? (
                                              <>
                                                {cookiedata === "en"
                                                  ? item.FinalPositionDataHorse &&
                                                    item.FinalPositionDataHorse
                                                      .NameEn
                                                  : item.FinalPositionDataHorse &&
                                                    item.FinalPositionDataHorse
                                                      .NameAr}
                                              </>
                                            ) : (
                                              <>
                                                {
                                                  item.FinalPositionDataHorse
                                                    .NameAr
                                                }
                                              </>
                                            )}
                                          </>
                                        )}
                                      </td>
                                      <td>

                                      {
                                                    distanceLabel(item.Distance &&
                                                      item.Distance , item.FinalPositionDataHorse &&
                                                      item.FinalPositionDataHorse?.Rank)
                                                  }


                                       
                                      </td>
                                      <td>{item.Runners}</td>
                                      <td
                                        onClick={() =>
                                          handleShowHorse(
                                            item.BeatenByData
                                              ? item.BeatenByData
                                              : ""
                                          )
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        {item.BeatenByData === null ? (
                                          <>-</>
                                        ) : (
                                          <>
                                            {cookiedata === "en" ? (
                                              <>{ window.textTransform(item.BeatenByData.NameEn)}</>
                                            ) : (
                                              <>{item.BeatenByData.NameAr}</>
                                            )}
                                          </>
                                        )}
                                      </td>{" "}
                                      <td>
                                        {item.BeatenByData
                                          ? item.BeatenByData?.WinningHorseJockeyWeight
                                          : "_"}
                                      </td>
                                      <td>
                                        {item.RaceResultData
                                          ? item.RaceResultData &&
                                            item.RaceResultData
                                              .RacehorsesData[0].HorseNo
                                          : <>- </>}
                                      </td>
                                      <td
                                        className="rb"
                                        onClick={() =>
                                          handleShows(item.VideoLink)
                                        }
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        <img src={arrow1} alt="" />
                                      </td>
                                    </tr>
                                    );
                                  })
                                )}
                              </>
                            )}
                          </tbody>
                     
                        </table>
                        <div className="paginationstats">
                         <Pagination
                postsPerPage={postsPerPage}
                totalPosts={totalcountdata}
                paginate={paginate}
                currentPage={currentPage}
                TotalPages={totalpagesdata}
              />
                         </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab
                  eventKey="Entries"
                  title={t("Entries")}
                  tabClassName="profile-tabitem"
                >
                  <TrainerEntries id={trainerid} />
                </Tab>
                <Tab
                  eventKey="Stats"
                  title={t("Stats")}
                  tabClassName="profile-tabitem"
                >
                  <div className="RaceDetailCard">
                    <Stats id={trainerid} />
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      )}

      <Modal
        show={shows}
        onHide={handleCloses}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <VideoFrame data={modaldatas} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        show={ShowJockey}
        onHide={handleJockeyCloses}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <JockeyDetail data={modaldatasJockey} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        show={ShowHorse}
        onHide={handleHorseCloses}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <HorseModal data={modaldatasHorse} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        show={showRace}
        onHide={handleCloseRace}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <RaceModal id={modaldataRace} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};
export default TrainerDetail;
