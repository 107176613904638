import React, { Fragment } from 'react'
import Layout from "../Components/Reuseable/layout";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import Footer from '../Components/Reuseable/Footer';

const TermsAndCondition = () => {

  const cookiedata = Cookies.get("i18next");
  const { t } = useTranslation();

  return (
    <Fragment>
      {/* <Layout /> */}
      <div className="aboutpageheader">
        <h2>Terms And Condition</h2>

      </div>

      <div className='policy'>
        <div class="embled"><p><strong>{t('PloicyLine1')}</strong></p>
          <p>&nbsp;</p>
          <p><span >{t('PloicyLine2')}</span></p>
          <p>&nbsp;</p>
          <p><span >{t('PloicyLine3')}</span></p>
          <p>&nbsp;</p>
          <p><span >{t('PloicyLine4')}</span></p>
          <p><span >{t('PloicyLine5')}</span></p>
          <p><span >{t('PloicyLine6')}</span></p>
          <p>&nbsp;</p>
          <p><strong>{t('PloicyLine7')}</strong></p>
          <p><span >{t('PloicyLine8')}</span></p>
          <p><span >{t('PloicyLine9')}</span></p>
          <p><strong>{t('PloicyLine10')}</strong></p>
          <p><span >{t('PloicyLine11')}</span></p>
          <p><span >{t('PloicyLine12')}</span></p>
          <p><strong>{t('PloicyLine13')}</strong></p>
          <p>&nbsp;</p>
          <p><strong>{t('PloicyLine14')}</strong></p>
          <p><span >{t('PloicyLine15')}</span></p>
          <p><span >{t('PloicyLine16')}</span></p>
          <p><span >{t('PloicyLine17')}</span><a href="mailto:help@racingpost.com"><span ></span></a><span >.</span></p>
          <p>&nbsp;</p>
          <ol>
            <li><strong> {t('PloicyLine18')}</strong></li>
          </ol>
          <p><span >{t('PloicyLine19')}&nbsp;</span></p>
          <ul>
            <li aria-level="1"><span >{t('PloicyLine20')}</span></li>
            <li aria-level="1"><span >{t('PloicyLine21')}</span></li>
            <li aria-level="1"><span >{t('PloicyLine22')}</span></li>
            <li aria-level="1"><span >{t('PloicyLine23')}</span></li>
            <li aria-level="1"><span >{t('PloicyLine24')}</span></li>
            <li aria-level="1"><span >{t('PloicyLine25')}</span></li>
            <li aria-level="1"><span >{t('PloicyLine26')}</span></li>
            <li aria-level="1"><span >{t('PloicyLine27')}</span></li>
          </ul>
          <p>&nbsp;</p>
          <p><span >{t('PloicyLine28')}</span></p>
          <p><span >{t('PloicyLine29')}&nbsp;</span></p>
          <p>&nbsp;</p>
          {/* <p><span >1.2 Such details are collected in a number of ways: they can be obtained via the use of cookies (please see our <a href="https://help.racingpost.com/hc/en-us/articles/208284229-Cookie-policy" target="_self">Cookie Policy</a></span><span ></span><span >), or other tracking systems or when you register to purchase a particular product or service from us. We may also collect information you give us over the telephone or in written or other communications.</span></p>
          <p>&nbsp;</p>
          <p><span >1.3 We use the information we collect from you for the following purposes:</span></p>
          <p>&nbsp;</p>
          <ul>
            <li aria-level="1"><span >to fulfil orders you have made for products or services;</span></li>
            <li aria-level="1"><span >to conduct market research, compile customer reviews and analysis;</span></li>
            <li aria-level="1"><span >to verify your identity;</span></li>
            <li aria-level="1"><span >to personalise your visits to the Site and develop the design and ove the services provided to you;</span></li>
            <li aria-level="1"><span >to inform you about the latest changes to the Site or products, services or promotional offers that you might find interesting;</span></li>
            <li aria-level="1"><span >to communicate with you or to deal with, and respond to you about a comment you have submitted;</span></li>
            <li aria-level="1"><span >to inform you if you have been successful in any Site competitions or promotions and;</span></li>
            <li aria-level="1"><span >to carry out&nbsp; statistical, technical and logistical analysis.</span></li>
          </ul>
          <p><span >1.4 Saddle Battle will process – that means collect, store and use – the information you provide in a manner that is compatible with the UK/EU’s General Data Protection Regulation (GDPR), we also comply with US state regulation as set out in sections 11 - 13. We will endeavour to keep your information accurate and up to date and not keep it for longer than is necessary. In some instances the law sets the length of time information has to be kept, but in most cases Saddle Battle will use its discretion to ensure that we do not keep records outside of our normal business requirements.</span></p>
          <p><span >1.5 Our aim is not to be intrusive, and we undertake not to ask irrelevant or unnecessary questions. Moreover, the information you provide will be subject to rigorous security measures and procedures to minimise the risk of unauthorised access or disclosure.</span></p>
          <p><span >1.6 You will not receive unsolicited marketing mail from us or trusted third parties unless you have agreed to this. However, Saddle Battle may email you occasionally with information about changes to the site, the subscription services available and products you are currently or have been subscribed to in the past, where there is a legitimate interest to do so.</span></p>
          <p><span >1.7&nbsp; If you choose to unsubscribe from email contact by Saddle Battle or an associated third party, it may take a few days to remove your details from our list. This may mean you will receive an email before your details have been updated.</span></p>
          <p><span >1.8 If we contact you via e-mail, in-app or SMS, you have the opportunity to decline to receive future e-mail, in-app and SMS messages from us.</span></p>
          <p>&nbsp;</p>
          <ol start="2">
            <li><strong> Who Do We Share The Information We Collect With?</strong></li>
          </ol>
          <p><span >2.1 We will not sell, trade or rent or make available your personal information to others outside of Spotlight Sports Group. You may have given permission to be contacted by our trusted advertisers, partners, affiliates or other third parties with offers relating to their products, offers, services or promotions. If you subsequently decide you no longer wish to be contacted by our advertisers, partners, affiliates or other third parties, please notify us by email at the following address </span><a href=""><span >mailmks@gmail.com</span></a><span > or unsubscribe on the email.</span></p>
          <p><span >2.2 We compile anonymous statistics of user trends which we may make use of ourselves, or pass to our associated companies and/or selected partners.</span></p>
          <p><span >2.3 We may disclose anonymised details about use of our web sites to other businesses e.g. to demonstrate patterns of use to advertisers and other business partners. The information we pass on will not include any personal information by which you may be identified.</span></p>
          <p><span >2.4 We may forward your personal details to the Police or another regulatory body or government authority if we are asked to do so or wish to do so in order to comply with the law, an investigation of suspected illegal activities, or any suspected misuse of our website by you. Where it is lawful and necessary we may also monitor emails you send and receive.</span></p>
          <p><span >2.5 If you use any of our gaming services, to comply with legislation, we will independently verify your details with a 3rd party agency, who may in turn retain that data for their purposes.</span></p>
          <p><span >2.6 We may transfer or assign any of the information described in this policy to third parties as a result of a sale, merger, consolidation, change of control, transfer of assets or reorganisation of our business.</span></p>
          <p>&nbsp;</p>
          <ol start="3">
            <li><strong> Data Security</strong></li>
          </ol>
          <p><span >3.1 We have adopted measures to ensure that users' personal details are not misused, accidentally destroyed, lost or altered. Where information is transferred to a third party, that third party will be required to keep your personal details secure to the same standards we apply.</span></p>
          <p><span >3.2 We endeavour to prevent unauthorised disclosures of your personal information by any approved suppliers that we work with. Notwithstanding, we are not responsible for any unauthorised disclosures or other breaches of security or for the actions of others if the information was passed to them with your permission or with the authority of anybody other than us or our associated companies.</span></p>
          <p><span >3.3 Please be aware that if you disclose information on chat areas, forums or other public services it may be possible for other people to use this information. We are not responsible for the disclosure of any information you post in this way.</span></p>
          <p>&nbsp;</p>
          <ol start="4">
            <li><strong> Transfer of Details Overseas</strong></li>
          </ol>
          <p><span >Given the global nature of the Internet, the use of the Internet to collect and process personal details may involve the transmission of data internationally. Therefore, by browsing the Site and communicating electronically with us, you acknowledge and agree that we may process your personal details overseas. However, we will always ensure that any third party to whom your personal details are transferred will take appropriate steps to safeguard your personal details. These steps include international data transfer assessments (IDTAs) and Standard Contractual Clauses (SCC) in line with the UK/European Commission’s guidance.</span></p>
          <p>&nbsp;</p>
          <ol start="5">
            <li><strong> Retention and deletion&nbsp;</strong></li>
          </ol>
          <p><span >We will take reasonable steps to ensure the accuracy of the information we hold about you. We will not use your personal information unless it is (to the best of our knowledge) accurate and up to date.</span></p>
          <p><span >If you believe your details are incorrect, you can update them on https://stagemksracing.vercel.app/ "My Account" or e-mailing us at: mksmail .com&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
          <p><span >We will not keep your personal information for longer than is necessary. Once it has been decided that the information is no longer needed, or you have enacted your right for erasure, it will be deleted in line with your </span><a href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/"><span >rights under GDPR</span></a><span >.</span></p>
          <p><span >Information of individuals with active accounts will be retained for as long as the account is kept in use. Accounts that have not been used for over 2 years will be deemed as inactive, and thus, we will endeavour to delete the information after this period.</span></p>
          <p><span >Data of individuals who subscribe to the Saddle Battle Members’ Club Services or have made a financial transaction will be kept for 6 years + 1 in line with HMRC Records Management and Retention and Disposal Policy.</span></p>
          <p>&nbsp;</p>
          <ol start="6">
            <li><strong> Invoking your rights under GDPR</strong></li>
          </ol>
          <p><span >Under the UK/EU’s General Data Protection Regulation you have multiple rights, including the right to find out what information we hold for you, request that it be updated, object to it being processed, ask that it be deleted, or restrict its processing.</span></p>
          <p><span >If you would like to exercise any of these, you can easily do so by contacting </span><a href=""><span >mksmail.com</span></a><span >.</span></p>
          <p>&nbsp;</p>
          <ol start="7">
            <li><strong> Device Identifiers</strong></li>
          </ol>
          <p><span >7.1 Our Apps require access to identifiers stored on your mobile device. We send this information to our servers for the purpose of validating free trials, messaging and analytics. We may use this information&nbsp; to seek your feedback on the website/app and to notify you of new products and features in accordance with this Privacy Policy. We will use this information inline with the preferences you select and may make this data available to selected third parties for use in communication which you have agreed to. <span>The ability to update your preferences at any time is made available&nbsp;via the footer of any email from Saddle Battle. If you do not have access to an email, please contact customer services via&nbsp;</span><a href="" target="_blank" rel="noopener">mksmail.com/</a>.</span></p>
          <p>&nbsp;</p>
          <ol start="8">
            <li><strong> Accessing Your Details</strong></li>
          </ol>
          <p><span >8.1 We will provide you with a summary of personal details that we hold on you upon your request, this could be subject to an administrative charge for requests deemed to be manifestly unfounded or excessive. To make a request, you should send an email to mksracing.com and it will be handled in line with the governing legislation.</span></p>
          <p><span >8.2 You can also request that the personal details that we hold about you are removed from our system. However, this may mean that we can no longer provide you with the products or services that you wish to receive.</span></p>
          <p>&nbsp;</p>
          <ol start="9">
            <li><strong> Cookies</strong></li>
          </ol>
          <p><span >We adhere to global cookie regulations and require your consent to process the types of cookies that are used by Saddle Battle and it’s selected third party vendors. The data you submit and it’s usage is documented in our </span><a href="https://www.racingpost.com/cookie-policy/"><span >Cookie Policy</span></a><span >.&nbsp;</span></p>
          <p>&nbsp;</p>
          <ol start="10">
            <li><strong> Users in California</strong></li>
          </ol>
          <p><span >The California Consumer Privacy Act of 2018 (“CCPA”) provides certain rights to residents of California. This section applies if you are a natural person who is a resident of California (“California Consumer”) and uses our Website. Certain terms used below have the meanings given to them in the CCPA.&nbsp;&nbsp;</span></p>
          <p><span >Without being discriminated against for exercising these rights, California residents have the right to request that we disclose what personal information we collect about them and to delete that information, subject to certain restrictions. You also have the right to designate an agent to exercise these rights on your behalf. To the extent permitted by applicable law, we may charge a reasonable fee to comply with your request.&nbsp;&nbsp;</span></p>
          <p><span >The CCPA specifically sets forth certain obligations for businesses that “sell” personal information. Based on the definition of “sell” under the CCPA and under current regulatory guidance, we do not believe we engage in such activity and have not engaged in such activity in the past.&nbsp;</span></p>
          <p>&nbsp;</p>
          <ol start="11">
            <li><strong> Users in Virginia&nbsp;</strong></li>
          </ol>
          <p><span >Under the Virginia Consumer Data Protection Act (CDPA), Virginia residents have certain rights with regards to the processing of their personal data. If you are a resident of Virginia and use this website, the following applies.</span></p>
          <p><span >Without being discriminated against for exercising these rights, Virginia residents have the right to request that we disclose what personal information we collect about them and to delete that information, subject to certain restrictions. The processing of sensitive information, which is not done by Saddle Battle, requires explicit consent of the individual.&nbsp;</span></p>
          <p>&nbsp;</p>
          <ol start="12">
            <li><strong> Users in Colorado</strong></li>
          </ol>
          <p><span >The Colorado Privacy Act (CPA) provides residents of Colorado with certain rights. If you are a resident of Colorado and use this website, the following applies.</span></p>
          <p><span >Under CPA, consumers have the right to confirm whether or not their personal data is being processed and to access such personal data. Colorado residents may also opt out of marketing communications and must give consent to the processing of sensitive information.&nbsp;</span></p>
          <p>&nbsp;</p>
          <p><strong>Invoking your rights in the United States</strong></p>
          <p><span >If you would like to exercise your rights under U.S. jurisdiction, you can easily do so by contacting </span><a href=""><span >mailmks.com</span></a><span >, </span><span >or by mailing us at:</span></p>
          <p><span >Saddle Battle</span></p>
          <p><span >Floor 7, Vivo Building</span></p>
          <p><span >South Bank Central</span></p>
          <p><span >30 Stamford Street</span></p>
          <p><span >London</span></p>
          <p><span >SE1 9LS</span></p>
          <p>&nbsp;</p>
          <ol start="13">
            <li><strong> Responsibilities</strong></li>
          </ol>
          <p><span >The Data Protection Officer is responsible for ensuring that this notice is placed in front of potential users prior to Saddle Battle collecting/processing their personal data.</span></p>
          <p>&nbsp;</p>
          <ol start="14">
            <li><strong> General</strong></li>
          </ol>
          <p><span >Nothing in this Privacy Policy shall restrict or exclude any liability that we have to any party which cannot be excluded by law. This Privacy Policy shall be governed by English law and the courts of England and Wales and shall have exclusive jurisdiction over any disputes arising in relation to this Privacy Policy and the Site.</span></p>
          <p>&nbsp;</p>
          <ol start="15">
            <li><strong> Data Protection Registration</strong></li>
          </ol>
          <p><span >Spotlight Sports Group, trading as Saddle Battle, is a registered data controller under the UK/EU General Data Protection Regulation (GDPR) and is registered with the Information Commissioner's Office under the Data Protection Registration Number of Z2636371.</span></p>
          <p>&nbsp;</p>
          <p><span ><span>Last Updated June 2023</span></span></p> */}
          <p>&nbsp;</p></div>
      </div>

      <Footer />
    </Fragment>
  )
}

export default TermsAndCondition