import React from 'react';
import Header from "../Components/Reuseable/Header"
import Auth from "../Components/Reuseable/Auth"
import Search from '../Components/Home/Components/Search';
import Tracker from '../Components/Home/Components/Tracker';
import Notification from '../Components/Home/Components/Notification';
import ExNews from '../Components/Home/Components/ExNews';
import Language from '../Components/Home/Components/Language';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Footer from "../Components/Reuseable/Footer";
import Copyrights from '../Components/Reuseable/Copyrights';


const Dashboard = () => {
  const { t } = useTranslation()

  return (
    <>
     
      
      <div className='dashboardpage'>
        <div className='page'>

          <div className='rightsidedata'>

            <div className='DashboardCard'>

              <div className='GenerateReports'>
                <Link to='/racecards'>
                  <p>{t('Race')} </p>
                </Link>
              </div>
              <div className='GenerateReports'>
                <Link to='/myselection'>
                  <p>{t('Selection')}</p>
                  <h3></h3>
                </Link>
              </div>
              <div className='GenerateReports'>
                <Link to="/winnerlist">
                  <p>{t('WinnerList')}</p>
                  <h3></h3>
                </Link>
              </div>
              <div className='GenerateReports'>
                <Link to="/Compitionstage">
                  <p>{t('PointAndStaging')}</p>
                </Link>
              </div>
              <div className='GenerateReports'>
                <Link to="/result">
                  <p>{t('Result')}</p>
                  <h3></h3>
                </Link>
              </div>
              <div className='GenerateReports'>
                <Link to="/myprofile">
                  <p>{t('MyProfile')}</p>
                </Link>
              </div>
              <div className='GenerateReports'>
                <Link to="/tracker">
                  <p>{t('Tracker')}</p>
                </Link>
              </div>
            </div>
          </div>
          <Footer />
          <Copyrights />
          
        </div>
      </div>
    </>
  )
}

export default Dashboard
