import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getSireSnippetsSlice = createSlice({
    name: 'SireSnippets',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchSireSnippets.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchSireSnippets.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchSireSnippets.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setSireSnippets, setStatus } = getSireSnippetsSlice.actions;
export default getSireSnippetsSlice.reducer;

export const fetchSireSnippets = createAsyncThunk('/SireSnippets/fetch', async ({year, StartDate,
    EndDate,RaceCourseSearch,HorseKindSearch,TrackLengthSearch,RaceTypeSearch,RaceKindSearch,RaceGroundSearch,RaceNationalitySearch,currentPage,RaceDateSearch}) => {
    const res = await axios.get(`${window.env.API_URL}/SireSnippets?year=${
        year === undefined ? "" : year
      }&startTimeTo=${StartDate}&startTimeFrom=${EndDate}&RaceCourse=${RaceCourseSearch}&HorseKindinRace=${HorseKindSearch}&TrackLength=${TrackLengthSearch}&RaceType=${RaceTypeSearch}&RaceKind=${RaceKindSearch}&NationalityID=${RaceNationalitySearch}&Ground=${RaceGroundSearch}&Date=${RaceDateSearch}&page=${currentPage}`);
    const SireSnippetsData = res.data;
    return SireSnippetsData;
})