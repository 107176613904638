import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getJockeyDetailStatsSlice = createSlice({
    name: 'JockeyDetailStats',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchJockeyDetailStats.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchJockeyDetailStats.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchJockeyDetailStats.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setJockeyDetailStats, setStatus } = getJockeyDetailStatsSlice.actions;
export default getJockeyDetailStatsSlice.reducer;

export const fetchJockeyDetailStats = createAsyncThunk('/JockeyDetailStatsget/fetch', async ({year , id}) => {
    const res = await axios.get(`${window.env.API_URL}/LatestResultOfHorsesWithJockey/${id}?year=${year}&size=300`);
    const JockeyDetailStatsData = res.data;
    return JockeyDetailStatsData.data;
})
