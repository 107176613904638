import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { lazy } from 'react';
import React from "react";
import Home from "./Webiste/pages/Home";
import RaceCard from "./Webiste/pages/RaceCard";
import "./Webiste/Components/CSS/mediaquery.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import store from "./Webiste/redux/store";
import { Provider } from "react-redux";
import Registration from "./Webiste/pages/Registration";
import Login from "./Webiste/pages/Login";
import Dashboard from "./Webiste/UserDashboard/Dashboard";
import ProtectedRoute from "./Webiste/Components/Reuseable/ProtectedRoute";
import RaceCourse from "./Webiste/pages/RaceCourse";
// import About from "./Webiste/pages/About";

import Result from "./Webiste/pages/Result";
import Competition from "./Webiste/pages/Competition";
import Sponsor from "./Webiste/pages/Sponsor";
import Stats from "./Webiste/pages/Stats";
import Horse from "./Webiste/pages/Horse";
import Trainer from "./Webiste/pages/Trainer";
import Jockey from "./Webiste/pages/Jockey";
import HorseDetail from "./Webiste/pages/HorseDetail";
import OwnerDetail from "./Webiste/pages/OwnerDetail";
import QueryTool from "./Webiste/pages/QueryTool";
import Profile from "./Webiste/pages/AuthPages/Profile";
import ChangePassword from "./Webiste/pages/AuthPages/ChangePassword";
import UserResult from "./Webiste/pages/AuthPages/UserResult";
import MyTracker from "./Webiste/UserDashboard/Tracker";
import JockeyDetail from "./Webiste/pages/JockeyDetail";
import TrainerDetail from "./Webiste/pages/TrainerDetail";
import { useState, useEffect } from "react";
import MySelection from "./Webiste/UserDashboard/MySelection";
import PointStaging from "./Webiste/UserDashboard/PointStaging";
import MyProfile from "./Webiste/UserDashboard/MyProfile";
import WinnerList from "./Webiste/UserDashboard/WinnerList";
import RaceDetails from "./Webiste/pages/RaceDetails";
import BlogDetails from "./Webiste/pages/BlogDetails";
import Policy from "./Webiste/pages/Policy";
import TermsAndCondition from "./Webiste/pages/TermsAndCondition";
import Owner from "./Webiste/pages/Owner";
import Moment from "react-moment";
import NotFound from "./Webiste/pages/NotFound";
import TodayRaceCard from "./Webiste/pages/TodayRaceCard";
import VerifyEmail from "./Webiste/pages/VerifyEmail";

import WinningDetail from "./Webiste/pages/WinningDetail";
// import socketIo from "socket.io-client";
// import { io } from "socket.io-client";
import "./style.css";
import "./arstyle.css";
import "./resposive.css";
// import RaceCards from "./Webiste/pages/RaceCards";
import ResultScreen from "./Webiste/pages/ResultScreen";
import MySelectionPage from "./Webiste/pages/MySelection"
import NotificationPage from "./Webiste/pages/NotificationPage"

import Compitionstage from "./Webiste/pages/Compitionstage";

import RaceCardDetail from "./Webiste/pages/RaceCardDetail";
import Racecardscreen from "./Webiste/pages/Racecardscreen";
import AtoZ from "./Webiste/pages/AtoZ";
import SingleRace from "./Webiste/pages/SingleRace";
import TrainerStats from "./Webiste/pages/TrainerStats";
import OwnerStatistic from "./Webiste/pages/OwnerStats";
import JockeyStatistics from "./Webiste/pages/JockeyStats";
import HorseStatistics from "./Webiste/pages/HorseStatistics";
import HotForm from "./Webiste/pages/HotForm";
import SireSnippets from "./Webiste/pages/SireSnippets";
import Layout from "./Webiste/Components/Reuseable/layout";
import Notification from './firebaseNotifications/Notification'

import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import RaceCourseStats from "./Webiste/pages/RacecourseStats";
import Testform from "./Webiste/pages/Testform";
import ChangePassowrd from "./Webiste/pages/ChangePassowrd";
import RacecoursePage from "./Webiste/pages/RacecoursePage";
import TopNav from "./Webiste/Components/Home/Components/NewLayout/TopNav";
const About = lazy(() => import('./Webiste/pages/About'));



const languages = [
  {
    code: "en",
    name: "En",
    dir: "ltr",
    country_code: "gb",
  },
  {
    code: "ar",
    name: "Ar",
    dir: "rtl",
    country_code: "sa",
  },
];


function App() {
  const [userIsDesktop, setUserIsDesktop] = useState(true);
  const [messages, setMessages] = useState([]);



  useEffect(() => {
    window.innerWidth > 340 ? setUserIsDesktop(true) : setUserIsDesktop(false);
    // console.clear();
  }, [userIsDesktop]);
  const currentLanguageCode = Cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();
  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);


  Moment.globalLocal = true;

  return (
    <>
    
      <ToastContainer />
      {userIsDesktop ? (
        <Provider store={store}>
          <BrowserRouter>
            <TopNav/>
            <Layout />

            <Routes>
              <Route path={"/testform/:id"} element={<Testform />} />
              <Route path="/" exact element={<Home />} />
              <Route path="/blogdetails" element={<BlogDetails />} />
              <Route path="/racecard" element={<Racecardscreen />} />
              <Route path="/racecards" element={<Racecardscreen />} />
              <Route path="/atozreports" element={<AtoZ />} />
              <Route path="/compitionstage" element={<Compitionstage />} />
              <Route path={`/racedetail`} element={<Home />} />
              <Route path={"/racedetails"} element={<RaceDetails />} />
              <Route path={"/singlerace"} element={<RaceCardDetail />} />
              <Route path={"/mkscard"} element={<Home />} />
              {/* <Route path={"/mkscard#races"} element={<Home />} /> */}
              <Route path={"/mkscardall"} element={<Home />} />
              <Route path={"/trainerstatistics"} element={<TrainerStats />} />
              <Route path={"/ownerstatistics"} element={<OwnerStatistic />} />
              <Route path={"/jockeystatistics"} element={<JockeyStatistics />} />
              <Route path={"/horsestatistics"} element={<HorseStatistics />} />
              <Route path={"/hotform"} element={<HotForm />} />
              <Route path={"/SireSnippets"} element={<SireSnippets />} />
              <Route path={"/password/reset/:id"} element={<ChangePassowrd />} />
              <Route path={"/racecourses"} element={<RacecoursePage />} />


              <Route path={"/mkscards"} element={<TodayRaceCard />} />
              <Route path="/login" element={<Login />} />
              <Route path="/verifyemail" element={<VerifyEmail />} />
              <Route path="/register" element={<Registration />} />
              <Route path="/racecourse" element={<RaceCourseStats />} />

              {/* <Route path="/about" element={<About />} /> */}
              <Route path="/about" element={
                <React.Suspense fallback="Loading ...">
                  <About />
                </React.Suspense>
              } />
              <Route path="/policies" element={<Policy />} />
              <Route path="/termscondition" element={<TermsAndCondition />} />
              <Route path="/result" element={<ResultScreen />} />
              <Route path="/competition" element={<Competition />} />
              <Route path="/sponsor" element={<Sponsor />} />
              <Route path="/singleraces" element={<SingleRace />} />
              <Route path="/trainerjockeycombo" element={<Stats />} />
              <Route path="/horse" element={<Horse />} />
              <Route path="/trainer" element={<Trainer />} />
              <Route path="/jockey" element={<Jockey />} />
              <Route path="/owner" element={<Owner />} />
              <Route path="/horsedetail" element={<HorseDetail />} />
              <Route path="/ownerdetail" element={<OwnerDetail />} />
              <Route path="/querytool" element={<QueryTool />} />
              <Route path="/jockeydetail" element={<JockeyDetail />} />
              <Route path="/trainerdetail" element={<TrainerDetail />} />
              <Route path="/racecardscreen" element={<Racecardscreen />} />
              {/* <Route path='/mkscard' element={<TodayRaceCard />} /> */}

              <Route path="*" element={<NotFound />} />

              {/* Protected Routes */}
              <Route element={<ProtectedRoute />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/myprofile" element={<MyProfile />} />
                <Route path="/myselection" element={<MySelectionPage />} />
                <Route path="/pointandstaging" element={<PointStaging />} />
                <Route path="/notificationpage" element={<NotificationPage />} />

                <Route path="/winnerlist" element={<WinningDetail />} />
                <Route path="/userProfile" element={<Profile />} />
                <Route path="/changepassword" element={<ChangePassword />} />
                <Route path="/myresult" element={<UserResult />} />
                <Route path="/tracker" element={<MyTracker />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </Provider>
      ) : (
        <h1
          style={{
            display: "flex",
            justifyContent: "center",
            alignItem: "center",
          }}
        >
          Not Available in this device
        </h1>
      )}
    </>
  );
}

export default App;
