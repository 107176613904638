import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getAllNotificationSlice = createSlice({
    name: 'AllNotification',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchAllNotification.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchAllNotification.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchAllNotification.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setAllNotification, setStatus } = getAllNotificationSlice.actions;
export default getAllNotificationSlice.reducer;

export const fetchAllNotification = createAsyncThunk('/AllNotificationget/fetch', async ({token,currentPage}) => {
    const res = await axios.get(`${window.env.API_URL}/AllNotification?page=${currentPage}` ,{
        headers: { "Authorization": token, 'x-xsrf-token': token }
    },);
    const AllNotificationData = res.data;
    return AllNotificationData;
})
