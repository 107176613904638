import React, { useEffect, useState, Fragment } from "react";
import Layout from "../Components/Reuseable/layout";
import Footer from "../Components/Reuseable/Footer.jsx";
import CoptRight from "../Components/Reuseable/Copyrights";
import Cookies from "js-cookie";
import "../../Webiste/Components/CSS/pagesCSS/dashboard.css";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Select from "react-select";
import "../Components/CSS/RaceCardCSS/racecard.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchResultYearFilterForStats } from "../redux/getStatsFilterReduce/getResultYearFilterForStats";
import { fetchRaceTypeFilterForStats } from "../redux/getStatsFilterReduce/getRaceTypeFilterForStats";
import { fetchTrackLengthFilterForStats } from "../redux/getStatsFilterReduce/getTrackLengthFilterForStats";
import { fetchHorseKindFilterForStats } from "../redux/getStatsFilterReduce/getHorseKindFilterForStats";
import { fetchRaceKindFilterForStats } from "../redux/getStatsFilterReduce/getRaceKindFilterForStats";
import { fetchRaceNationalityFilterForStats } from "../redux/getStatsFilterReduce/getRaceNationalityFilterForStats";
import { fetchRaceCourseFilterForStats } from "../redux/getStatsFilterReduce/getRaceCourseFilterForStats";
import { fetchDatesFilterForStats } from "../redux/getStatsFilterReduce/getDatesFilterForStats";
import { fetchGroundTypeFilterForStats } from "../redux/getStatsFilterReduce/getGroundTypeFilterForStats";

import { fetchHotForm } from "../redux/getReducer/getHotForm";
import Pagination from "./Pagination";
import Moment from "react-moment";
import Lottie from "lottie-react";
import Animate from "../assets/loader.json";
import ExNews from "../Components/Home/Components/ExNews";
import Search from "../Components/Home/Components/Search";
import Notification from "../Components/Home/Components/Notification";
import Language from "../Components/Home/Components/Language";
import HorseModal from "../Components/Modal/HorseModal";
import { Modal } from "react-bootstrap";
import { DatePicker, Form } from "antd";

const { RangePicker } = DatePicker;

export default function Trainerstatistics() {
  const [form] = Form.useForm();
  const { data: HotForm, status } = useSelector((state) => state.HotForm);
  const { data: ResultYearFilterForStats } = useSelector(
    (state) => state.ResultYearFilterForStats
  );
  const { data: RaceTypeFilterForStats } = useSelector(
    (state) => state.RaceTypeFilterForStats
  );
  const { data: TrackLengthFilterForStats } = useSelector(
    (state) => state.TrackLengthFilterForStats
  );
  const { data: HorseKindFilterForStats } = useSelector(
    (state) => state.HorseKindFilterForStats
  );
  const { data: RaceKindFilterForStats } = useSelector(
    (state) => state.RaceKindFilterForStats
  );
  const { data: RaceNationalityFilterForStats } = useSelector(
    (state) => state.RaceNationalityFilterForStats
  );
  const { data: RaceCourseFilterForStats } = useSelector(
    (state) => state.RaceCourseFilterForStats
  );
  const { data: DatesFilterForStats } = useSelector(
    (state) => state.DatesFilterForStats
  );
  const { data: GroundTypeFilterForStats } = useSelector(
    (state) => state.GroundTypeFilterForStats
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const cookiedata = Cookies.get("i18next");

  const [CategorySub, setCategorySub] = useState();
  const [DateTobeDone, SetDateTobeDone] = useState([]);
  const [data, setData] = useState([]);
  const [Dates, setDates] = useState();
  const [optData, setotpData] = useState([]);
  const [optData1, setotpData1] = useState([]);
  const [optData2, setotpData2] = useState([]);
  const [optData3, setotpData3] = useState([]);

  const [ResultYearData, setResultYearData] = useState("");
  const [ResultDatesData, setResultDates] = useState("");
  const [ResultRaceCourseData, setResultRaceCourse] = useState("");
  const [ResultRaceTypeData, setResultRaceType] = useState("");
  const [ResultNationalityData, setResultNationality] = useState("");
  const [ResultRaceGroundData, setResultRaceGround] = useState("");
  const [ResultRaceTracklengthData, setResultRaceTracklength] = useState("");
  const [ResultHorseIdData, setResultHorseId] = useState("");
  const [ResultRaceKindData, setResultRaceKind] = useState("");
  const [ResultMeetingData, setResultMeeting] = useState("");
  const [ResultJockeyData, setResultJockey] = useState("");
  const [ResultOwnerData, setResultOwner] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [ResultHorseKindData, setResultHorseKind] = useState("");
  const [ResultGroundData, setResultGround] = useState("");
  const [postsPerPage] = useState(11);
  let totalcountdata = HotForm ? HotForm.totalcount : "";
  let totalpagesdata = HotForm ? HotForm.totalPages : "";

  const [StartDate, setStartDate] = useState(
    moment().subtract(2, "months").startOf("month").format("YYYY-MM-DD")
  );
  const [EndDate, setEndDate] = useState(
    moment().add(1, "months").endOf("month").format("YYYY-MM-DD")
  );
  useEffect(() => {
    // if (data) {
    form.setFieldsValue({
      // DateRange: data.AllowSettings,
      DateRange: [StartDate, EndDate],
    });
    console.log(form);
    // }
  }, [data, form]);

  const handleDateChange = (dates, i) => {
    let StartDate = i[0];
    let EndDate = i[1];
    dispatch(
      fetchHotForm({
        StartDate,
        EndDate,
        RaceCourseSearch,
        HorseKindSearch,
        TrackLengthSearch,
        RaceTypeSearch,
        RaceKindSearch,
        RaceGroundSearch,
        RaceNationalitySearch,
        currentPage,
        RaceDateSearch,
      })
    );
    setStartDate(i[0]);
    setEndDate(i[1]);
  };

  useEffect(() => {
    dispatch(
      fetchHotForm({
        StartDate,
        EndDate,
        RaceCourseSearch,
        HorseKindSearch,
        TrackLengthSearch,
        RaceTypeSearch,
        RaceKindSearch,
        RaceGroundSearch,
        RaceNationalitySearch,
        currentPage,
        RaceDateSearch,
      })
    );
  }, []);

  const paginate = (pageNumber) => setcurrentPage(pageNumber);

  const GetDates = (alldata) => {
    var start = new Date();
    var end = new Date(start);

    end.setDate(start.getDate() + 3);
    let Dates = [];

    var loop = new Date(start);

    for (let i = 0; i < 5; i++) {
      if (i == 0) {
        var newDates = loop.setDate(loop.getDate() + i);
        loop = new Date(newDates);
        Dates.push(moment(loop).format("YYYY-MM-DD"));
      } else {
        var newDates = loop.setDate(loop.getDate() + 1);
        loop = new Date(newDates);
        Dates.push(moment(loop).format("YYYY-MM-DD"));
      }
    }

    SetDateTobeDone(Dates);
    return Dates;
  };

  const today = cookiedata === "en" ? "Today" : "اليوم";
  const Yesterday = cookiedata === "en" ? "Tomorrow" : "غداً";
  useEffect(() => {
    SetDateTobeDone(GetDates());
  }, []);

  const date12 = new Date();
  const year12 = date12.getFullYear();
  const month12 = date12.getMonth();
  const Date12 = date12.getDate();

  const donemonth = month12 + 1;
  function pad(n) {
    return n < 10 ? "0" + n : n;
  }

  const datefor1 = new Date();
  const mydata = datefor1.getFullYear();

  const [show, setShows] = useState(false);
  const [modaldata, setmodaldatas] = useState();
  const handleClose = () => setShows(false);
  const handleShow = async (data) => {
    setmodaldatas(data);
    await setShows(true);
  };

  const Dates1 = `${year12}-${pad(donemonth)}-${pad(Date12)}`;

  let RaceCourseSearch = ResultRaceCourseData
    ? ResultRaceCourseData.id === undefined
      ? ""
      : ResultRaceCourseData.id
    : "";
  let HorseKindSearch = ResultHorseKindData
    ? ResultHorseKindData.id === undefined
      ? ""
      : ResultHorseKindData.id
    : "";
  let TrackLengthSearch = ResultRaceTracklengthData
    ? ResultRaceTracklengthData.id === undefined
      ? ""
      : ResultRaceTracklengthData.id
    : "";
  let RaceTypeSearch = ResultRaceTypeData
    ? ResultRaceTypeData.id === undefined
      ? ""
      : ResultRaceTypeData.id
    : "";
  let RaceKindSearch = ResultRaceKindData
    ? ResultRaceKindData.id === undefined
      ? ""
      : ResultRaceKindData.id
    : "";
  let RaceNationalitySearch = ResultNationalityData
    ? ResultNationalityData.id === undefined
      ? ""
      : ResultNationalityData.id
    : "";
  let RaceGroundSearch = ResultGroundData
    ? ResultGroundData.id === undefined
      ? ""
      : ResultGroundData.id
    : "";
  let RaceDateSearch = ResultYearData
    ? ResultYearData.id === undefined
      ? ""
      : ResultYearData.id
    : mydata;

  useEffect(() => {
    dispatch(
      fetchHotForm({
        StartDate,
        EndDate,
        RaceCourseSearch,
        HorseKindSearch,
        TrackLengthSearch,
        RaceTypeSearch,
        RaceKindSearch,
        RaceGroundSearch,
        RaceNationalitySearch,
        currentPage,
        RaceDateSearch,
      })
    );
  }, [
    dispatch,
    StartDate,
    EndDate,
    RaceCourseSearch,
    HorseKindSearch,
    TrackLengthSearch,
    RaceTypeSearch,
    RaceKindSearch,
    RaceGroundSearch,
    RaceNationalitySearch,
    RaceDateSearch,
    currentPage,
  ]);

  let year = ResultYearData?.id;
  let Nationality = ResultNationalityData?.id;
  let RaceCourse = ResultRaceCourseData?.id;
  let GroundType = ResultGroundData?.id;
  let RaceKind = ResultRaceKindData?.id;
  let HorseKind = ResultHorseKindData?.id;
  let RaceType = ResultRaceTypeData?.id;
  let TrackLength = ResultRaceTracklengthData?.id;

  useEffect(() => {
    dispatch(
      fetchResultYearFilterForStats({
        year,
        Nationality,
        RaceCourse,
        GroundType,
        RaceKind,
        HorseKind,
        RaceType,
        TrackLength,
      })
    );
    dispatch(
      fetchRaceTypeFilterForStats({
        year,
        Nationality,
        RaceCourse,
        GroundType,
        RaceKind,
        HorseKind,
        RaceType,
        TrackLength,
      })
    );
    dispatch(
      fetchTrackLengthFilterForStats({
        year,
        Nationality,
        RaceCourse,
        GroundType,
        RaceKind,
        HorseKind,
        RaceType,
        TrackLength,
      })
    );
    dispatch(
      fetchHorseKindFilterForStats({
        year,
        Nationality,
        RaceCourse,
        GroundType,
        RaceKind,
        HorseKind,
        RaceType,
        TrackLength,
      })
    );
    dispatch(
      fetchRaceKindFilterForStats({
        year,
        Nationality,
        RaceCourse,
        GroundType,
        RaceKind,
        HorseKind,
        RaceType,
        TrackLength,
      })
    );
    dispatch(
      fetchRaceNationalityFilterForStats({
        year,
        Nationality,
        RaceCourse,
        GroundType,
        RaceKind,
        HorseKind,
        RaceType,
        TrackLength,
      })
    );
    dispatch(
      fetchRaceCourseFilterForStats({
        year,
        Nationality,
        RaceCourse,
        GroundType,
        RaceKind,
        HorseKind,
        RaceType,
        TrackLength,
      })
    );
    dispatch(
      fetchDatesFilterForStats({
        year,
        Nationality,
        RaceCourse,
        GroundType,
        RaceKind,
        HorseKind,
        RaceType,
        TrackLength,
      })
    );
    dispatch(
      fetchGroundTypeFilterForStats({
        year,
        Nationality,
        RaceCourse,
        GroundType,
        RaceKind,
        HorseKind,
        RaceType,
        TrackLength,
      })
    );
  }, [
    dispatch,
    year,
    Nationality,
    RaceCourse,
    GroundType,
    RaceKind,
    HorseKind,
    RaceType,
    TrackLength,
  ]);

  function filterRuns(e) {
    let filterItem = HotForm.data.filter(
      (item) =>
        (item.HorseIDData ? item.HorseIDData.Runs : "") == e.target.value
    );
    setData([...filterItem]);
  }
  function filterWins(e) {
    let filterItem = HotForm.data.filter(
      (item) =>
        (item.HorseIDData ? item.HorseIDData.wins : "") == e.target.value
    );
    setData([...filterItem]);
  }
  function filterWinsPercentage(e) {
    let filterItem = HotForm.data.filter(
      (item) =>
        (item.HorseIDData ? item.HorseIDData.winspercentage : "") ==
        e.target.value
    );
    setData([...filterItem]);
  }

  function filterPlacePercentage(e) {
    let filterItem = HotForm.data.filter(
      (item) =>
        (item.HorseIDData ? item.HorseIDData.placespercentage : "") ==
        e.target.value
    );
    setData([...filterItem]);
  }
  useEffect(() => {
    if (HotForm?.data) {
      const filteredData = HotForm.data.filter((item, index, arr) => {
        return (
          arr.findIndex((t) => t.HorseIDData.Runs === item.HorseIDData.Runs) ===
          index
        );
      });
      setotpData(filteredData);
      const filteredData1 = HotForm.data.filter((item, index, arr) => {
        return (
          arr.findIndex((t) => t.HorseIDData.wins === item.HorseIDData.wins) ===
          index
        );
      });
      setotpData1(filteredData1);

      const filteredData2 = HotForm.data.filter((item, index, arr) => {
        return (
          arr.findIndex(
            (t) =>
              t.HorseIDData.winspercentage === item.HorseIDData.winspercentage
          ) === index
        );
      });
      setotpData2(filteredData2);

      const filteredData3 = HotForm.data.filter((item, index, arr) => {
        return (
          arr.findIndex(
            (t) =>
              t.HorseIDData.placespercentage ===
              item.HorseIDData.placespercentage
          ) === index
        );
      });
      setotpData3(filteredData3);
    }
  }, [HotForm]);

  // let RaceYearDropdown = ResultYearFilterForStats.map(function (item) {
  //   return {
  //     id: item.RacesYear,
  //     value: item.RacesYear,
  //     label: item.RacesYear,
  //   };
  // });

  let RaceYearDropdown = ResultYearFilterForStats.map(function (item) {
    return {
      id: item.RacesYear,
      value: item.RacesYear,
      label: item.RacesYear,
    };
  });

  let RaceTypeFilterForStatsDropdown = RaceTypeFilterForStats.map(function (
    item
  ) {
    return cookiedata === "en"
      ? {
          id: item._id,
          value: item.NameEn,
          label: item.NameEn,
        }
      : {
          id: item._id,
          value: item.NameAr,
          label: item.NameAr,
        };
  });

  let GroundTypeFilterForStatsDropdown =
    GroundTypeFilterForStats &&
    GroundTypeFilterForStats.map(function (item) {
      return cookiedata === "en"
        ? {
            id: item._id,
            value: item.NameEn,
            label: item.NameEn,
          }
        : {
            id: item._id,
            value: item.NameAr,
            label: item.NameAr,
          };
    });

  let TrackLengthFilterForStatsDropdown = TrackLengthFilterForStats.map(
    function (item) {
      return {
        id: item._id,
        value: item.TrackLength,
        label: item.TrackLength,
      };
    }
  );

  let HorseKindFilterForStatsDropdown = HorseKindFilterForStats.map(function (
    item
  ) {
    return cookiedata === "en"
      ? {
          id: item._id,
          value: item.NameEn,
          label: item.NameEn,
        }
      : {
          id: item._id,
          value: item.NameAr,
          label: item.NameAr,
        };
  });

  let RaceKindFilterForStatsDropdown = RaceKindFilterForStats.map(function (
    item
  ) {
    return cookiedata === "en"
      ? {
          id: item._id,
          value: item.NameEn,
          label: item.NameEn,
        }
      : {
          id: item._id,
          value: item.NameAr,
          label: item.NameAr,
        };
  });

  let RaceNationalityFilterForStatsDropdown = RaceNationalityFilterForStats.map(
    function (item) {
      return cookiedata === "en"
        ? {
            id: item._id,
            value: item.NationalityDataRaceCourse ? (
              item.NationalityDataRaceCourse.NameEn
            ) : (
              <></>
            ),
            label: item.NationalityDataRaceCourse ? (
              item.NationalityDataRaceCourse.NameEn
            ) : (
              <></>
            ),
          }
        : {
            id: item._id,
            value: item.NationalityDataRaceCourse ? (
              item.NationalityDataRaceCourse.NameAr
            ) : (
              <></>
            ),
            label: item.NationalityDataRaceCourse ? (
              item.NationalityDataRaceCourse.NameAr
            ) : (
              <></>
            ),
          };
    }
  );

  let RaceCourseFilterForStatsDropdown = RaceCourseFilterForStats.map(function (
    item
  ) {
    return cookiedata === "en"
      ? {
          id: item._id,
          value: item.TrackNameEn,
          label: item.TrackNameEn,
        }
      : {
          id: item._id,
          value: item.TrackNameAr,
          label: item.TrackNameAr,
        };
  });

  function toPascalCase(inputString) {
    // Split the string into words
    const words = inputString.split(/\s+/);

    // Capitalize the first letter of each word
    const pascalCaseWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    // Join the words back together with spaces
    const pascalCaseString = pascalCaseWords.join(" ");

    return pascalCaseString;
  }

  return (
    <Form
      // onFinish={onFinish}
      // onFinishFailed={onFinishFailed}
      layout="vertical"
      form={form}
    >
      <Fragment>
        <>
          <div className="container mystages trainerStages hotform">
            <div className="row">
              <div className="col-sm-12 stages">
                <div className="raceCarParent1 atozParent stages">
                  <div className="raceCardHeader1">
                    <div className="row">
                      <div className="col-sm-12">
                        <h1>{t("Hot Form")}</h1>
                      </div>
                    </div>
                  </div>
                  <div className="posttabs">
                    <div className="row">
                      <div className="Statisticsbtn Statisticsbtn1">
                        <Select
                          id="selectNow2"
                          placeholder={t("Year")}
                          className="dropdown multidropdown"
                          isSearchable={true}
                          hideSelectedOptions={true}
                          name="ResultYearData"
                          onChange={setResultYearData}
                          options={RaceYearDropdown}
                          value={ResultYearData}
                          isClearable={true}
                        />
                        <Select
                          id="selectNow2"
                          placeholder={t("Nationality")}
                          className="dropdown multidropdown"
                          isSearchable={true}
                          hideSelectedOptions={true}
                          name="ResultNationality"
                          onChange={setResultNationality}
                          options={RaceNationalityFilterForStatsDropdown}
                          value={ResultNationalityData}
                          isClearable={true}
                        />
                        <Select
                          id="selectNow2"
                          placeholder={t("Race Course")}
                          className="dropdown multidropdown"
                          isSearchable={true}
                          hideSelectedOptions={true}
                          name="ResultNationality"
                          onChange={setResultRaceCourse}
                          options={RaceCourseFilterForStatsDropdown}
                          value={ResultRaceCourseData}
                          isClearable={true}
                        />
                        <Select
                          id="selectNow2"
                          placeholder={t("Ground Type")}
                          className="dropdown multidropdown"
                          isSearchable={true}
                          hideSelectedOptions={true}
                          name="Meeting"
                          onChange={setResultGround}
                          options={GroundTypeFilterForStatsDropdown}
                          value={ResultGroundData}
                          isClearable={true}
                        />

                        <Select
                          id="selectNow2"
                          placeholder={t("Race Kind")}
                          className="dropdown multidropdown"
                          isSearchable={true}
                          hideSelectedOptions={true}
                          name="ResultNationality"
                          onChange={setResultRaceKind}
                          options={RaceKindFilterForStatsDropdown}
                          value={ResultRaceKindData}
                          isClearable={true}
                        />
                        <Select
                          id="selectNow2"
                          placeholder={t("KindOfHorse")}
                          className="dropdown multidropdown"
                          isSearchable={true}
                          hideSelectedOptions={true}
                          name="ResultNationality"
                          onChange={setResultHorseKind}
                          options={HorseKindFilterForStatsDropdown}
                          value={ResultHorseKindData}
                          isClearable={true}
                        />
                        <Select
                          id="selectNow2"
                          placeholder={t("Track length")}
                          className="dropdown multidropdown"
                          isSearchable={true}
                          hideSelectedOptions={true}
                          name="length"
                          onChange={setResultRaceTracklength}
                          options={TrackLengthFilterForStatsDropdown}
                          value={ResultRaceTracklengthData}
                          isClearable={true}
                        />
                        <Select
                          id="selectNow2"
                          placeholder={t("Race Type")}
                          className="dropdown multidropdown"
                          isSearchable={true}
                          hideSelectedOptions={true}
                          name="ResultNationality"
                          onChange={setResultRaceType}
                          options={RaceTypeFilterForStatsDropdown}
                          value={ResultRaceTypeData}
                          isClearable={true}
                        />
                        <RangePicker
                          onChange={handleDateChange}
                          className="newdatepicker"
                          name="DateRange"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    {status == "idle" ? (
                      <div className="raceCardTable">
                        <table className=" tableHeaderCard">
                          <thead>
                            <tr key="i">
                              <th>{t("Horse Name")}</th>
                              <th>{t("Runs")} </th>
                              <th>{t("Wins")} </th>
                              <th>{t("Place")}</th>
                              <th>{t("Wins")}% </th>
                              <th>{t("Place")}% </th>
                              <th>{t("Earning")}</th>
                              <th>{t("Turf Earning")}</th>
                              <th>{t("Dirt Earning")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {HotForm && HotForm?.data ? (
                              <>
                                {HotForm.data.map((item, i) =>
                                  item.HorseIDData.placespercentage > 60 ? (
                                    <tr>
                                      {/* <td className='lb'> {Competitiondetail.RaceDataOfCompetition ? Competitiondetail.RaceDataOfCompetition._id : "not aaialable"}</td> */}
                                      <td
                                        className="lb"
                                        onClick={() =>
                                          handleShow(item.HorseModelId)
                                        }
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        {item.HorseIDData ? (
                                          <>
                                            {cookiedata === "en"
                                              ? toPascalCase(
                                                  item.HorseIDData.NameEn
                                                )
                                              : item.HorseIDData.NameAr}
                                          </>
                                        ) : (
                                          <>-</>
                                        )}
                                      </td>

                                      {/* <td className="lb">-</td> */}

                                      <td className="lb">
                                        {item.HorseIDData ? (
                                          item.HorseIDData.Runs
                                        ) : (
                                          <>-</>
                                        )}
                                      </td>

                                      <td className="lb">
                                        {item.HorseIDData ? (
                                          item.HorseIDData.wins
                                        ) : (
                                          <>-</>
                                        )}
                                      </td>
                                      <td className="lb">
                                        {item.HorseIDData ? (
                                          item.HorseIDData.places
                                        ) : (
                                          <>-</>
                                        )}
                                      </td>

                                      <td className="lb">
                                        {item.HorseIDData.winspercentage !==
                                        null ? (
                                          item.HorseIDData.winspercentage
                                        ) : (
                                          <>0</>
                                        )}
                                      </td>
                                      <td className="lb">
                                        {item.HorseIDData ? (
                                          item.HorseIDData.placespercentage
                                        ) : (
                                          <>-</>
                                        )}
                                      </td>
                                      <td className="lb">
                                        {item.HorseIDData ? (
                                          item.HorseIDData.Earnings
                                        ) : (
                                          <>-</>
                                        )}
                                      </td>
                                      <td className="lb">
                                        {item.HorseIDData ? (
                                          item.HorseIDData.TurfEarnings
                                        ) : (
                                          <>-</>
                                        )}
                                      </td>
                                      <td className="lb">
                                        {item.HorseIDData ? (
                                          item.HorseIDData.DirtEarnings || 0
                                        ) : (
                                          <>-</>
                                        )}
                                      </td>
                                    </tr>
                                  ) : (
                                    <></>
                                  )
                                )}
                              </>
                            ) : (
                              <tr>
                                <td>No data found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>

                        {/* <Pagination
                        postsPerPage={postsPerPage}
                        totalPosts={totalcountdata}
                        paginate={paginate}
                        currentPage={currentPage}
                        TotalPages={totalpagesdata}
                      /> */}
                      </div>
                    ) : (
                      <Lottie
                        animationData={Animate}
                        className="load1"
                        style={{
                          marginTop: "30px",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="col-sm-4">
              <Justsponsor />
            </div> */}
            </div>
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header className="popupheader" closeButton></Modal.Header>
            <Modal.Body>
              <HorseModal data={modaldata} />
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
          <Footer />
          <CoptRight />
        </>
      </Fragment>
    </Form>
  );
}
