import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getOwnerHistory = createSlice({
    name: 'OwnerHistory',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchOwnerHistory.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchOwnerHistory.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchOwnerHistory.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setOwner, setStatus } = getOwnerHistory.actions;
export default getOwnerHistory.reducer;



export const fetchOwnerHistory = createAsyncThunk('/OwnerHistory/fetch', async ({ id }) => {
    const res = await axios.get(`${window.env.API_URL}/OwnerHistory/${id}`);
    const OwnerData = res.data;
    return OwnerData.data;
})