import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { userLogin } from '../redux/postReducer/UserPost';
import {
  fetchNotificationOfUser,
  STATUSES,
} from "../redux/getReducer/getNotificationUser";
import { toast } from 'react-toastify';
import ForgetPassword from '../Components/Reuseable/ForgetPassword';
import { Modal } from "react-bootstrap";
import '../Components/CSS/registration.css';
import { useTranslation } from 'react-i18next';
import { BsEyeFill } from 'react-icons/bs';
import { requestForToken, onMessageListener } from '../../firebaseNotifications/firebase';
import Cookies from 'js-cookie';
import { clearData, logout } from '../redux/getReducer/UserSlice';
import images from "../assets/registration.png"
import Logo from "../assets/logo-resizr.png"



const Login = () => {
  const { loading, userInfo, error, success } = useSelector((state) => state.user);
  const { data: ValidateUser } = useSelector((state) => state.ValidateUser);

  const dispatch = useDispatch();
  const [passwordShown, setPasswordShown] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = async () => {
    await setShow(true);
  };

  const showToast = (message) => {
    toast.dismiss(); // Clear any existing toasts
    toast.error(message);
  };

  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  // redirect authenticated user to profile screen
  useEffect(() => {
    if (userInfo) {
      navigate('/dashboard');
      requestForToken();
    }
  }, [navigate, userInfo]);

  const submitForm = async (data) => {
    await dispatch(userLogin(data));

  };

  useEffect(() => {
    if (error) {
      const err = error.data.message;
      const token = error.data.token;
      showToast(err);
      if (error.data.message == "Email Not verified") {
        Cookies.set('token', token);
        navigate('/verifyemail');
        dispatch(clearData());
      }
    }
  }, [error]);



  // const AllFilled = (register.Email !== '') && (register.password !== "")
  // 
  return (
    <div className='mainlogin'>
      <div className='container-fluid register'>
        <div className='row'>
          <div className='col-sm-6'>
            <div className='registration'>

              <form onSubmit={handleSubmit(submitForm)}>
              {/* <img src={Logo} alt=""/> */}
                <h5> <b>{t("Login")}</b> & {t("Resume Your Journey")}</h5>

                <div className='row login'>
                  <div className='col-sm-12'>
                    <div className='form-group'>
                      <input
                        type='email'
                        className='form-input'
                        placeholder={t('Email')}
                        {...register('Email')}
                        required
                      />
                    </div>
                  </div>

                  <div className='col-sm-12'>
                    <div className='form-group'>
                      <input
                        type={passwordShown ? "text" : "password"}
                        placeholder={t('password')}
                        className='form-input'
                        {...register('password')}
                        required
                      />
                      <span className='showIcon'> <i onClick={togglePasswordVisiblity}><BsEyeFill /></i></span>

                     
                    </div>
                  </div>

                  <div className='col-sm-6'>
                    <button type='submit' className='buttonRegister'
                      disabled={loading}>
                      {t('Login')}
                    </button>
                  </div>

                  <div className='col-sm-6'>
                  <div className='forget'>
                        <p onClick={() => handleShow()}>{t('Forget Password?')}</p>
                        <Link to="/register"> <p>{t('Don`t Have An Account?')}</p></Link>
                      </div>
                  </div>
                </div>







                {/* <hr/> */}
              </form>

            </div>
          </div>

          <div className="col-sm-6 registrationimg">
            <img src={images} alt="asd" />
          </div>
        </div>
      </div>


      {/* <Footer/>
   <Copyrights /> */}
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className='ForgetEmail1'
        centered
      >
        <Modal.Header closeButton className='ForgetEmailheader'>
          <h2 style={{ fontFamily: "Inter" }}>{t('ForgetEmail')} </h2>
        </Modal.Header>
        <Modal.Body className='ForgetEmailbody'>
          <ForgetPassword data={handleClose}/>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Login;