import horse from "../../../assets/image 4.png";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCompetitionTimer,
  STATUSES,
} from "../../../redux/getReducer/getCompetitionTimer";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import Counter from "./Counter";
import CompetitionTime from "../../Reuseable/CompetitionTimer";
import { useNavigate } from "react-router-dom";

const Addington = () => {
  const { t } = useTranslation();
  const [userIsDesktop, setUserIsDesktop] = useState(true);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { data: CompetitionTimer, status } = useSelector(
    (state) => state.CompetitionTimer
  );
  const cookiedata = Cookies.get("i18next");

  useEffect(() => {
    dispatch(fetchCompetitionTimer());
  }, [dispatch]);

  let Remainingtime =
    CompetitionTimer.length > 0
      ? CompetitionTimer[0].RaceDataOfCompetition
      : "00:00:00";

      useEffect(() => {
        window.innerWidth > 840 ? setUserIsDesktop(true) : setUserIsDesktop(false);
      }, [userIsDesktop]);
      
  function HandleJockey(id) {
    if (userIsDesktop === true) {
      navigate("/racedetail", {
        state: {
          id: id,
        },
      });
    } else {
      navigate("/racedetails", {
        state: {
          id: id,
        },
      });
    }
  }

  const raceid =  CompetitionTimer.length > 0
  ? CompetitionTimer[0].Race
  : null;


  console.log(CompetitionTimer,"Remainingtime >------------")

  return (
    <>
      <div className="Addington">
        <div className="AddingtonStyle">
          <img src={horse} alt="" />
          <p
            className="Addingtonname"
            style={{
              maxHeight: "400px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              cursor:"pointer"
            }}
            onClick={() => HandleJockey(raceid)}
          >
            {Remainingtime.RaceNameModelData
              ? cookiedata === "en"
                ? Remainingtime.RaceNameModelData.NameEn
                : Remainingtime.RaceNameModelData.NameAr
              : t("Competition")}
          </p>
          <p className="Addingtontimer">
            {CompetitionTimer.length !== 0 ? (
              <CompetitionTime
                timer={
                  Remainingtime.TimeRemaining
                    ? Remainingtime.TimeRemaining
                    : "00:00:00"
                }
                starttime={Remainingtime.StartTime}
              />
            ) : (
              "00:00:00"
            )}

            {/* <Counter /> */}
          </p>
        </div>
      </div>
    </>
  );
};
export default Addington;

// import horse from '../../../assets/image 4.png'
// import {useTranslation} from 'react-i18next';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchCompetitionTimer, STATUSES } from "../../../redux/getReducer/getCompetitionTimer";
// import React,{useEffect,useState} from 'react';
// import Cookies from "js-cookie";
// import Counter from './Counter'

// const Addington = () => {
//   const { t } = useTranslation()
//   const dispatch = useDispatch();
//   const { data: CompetitionTimer, status } = useSelector((state) => state.CompetitionTimer);
//   const cookiedata = Cookies.get("i18next");

//   useEffect(() => {
//     dispatch(fetchCompetitionTimer());
//   },[dispatch])

//   let Remainingtime = CompetitionTimer ? (CompetitionTimer[0].RaceDataOfCompetition ? CompetitionTimer[0].RaceDataOfCompetition.TimeRemaining : <></>) : <></>

//   const [state, setState] = useState(false);
//   const hh = Remainingtime.split(":")[0];
//   const min = Remainingtime.split(":")[1];
//   const sec = Remainingtime.split(":")[2];

//   const mm = parseInt(min);
//   const hour = parseInt(hh);

//   const [minutes, setMinutes] = useState(min);
//   const [seconds, setSeconds] = useState(sec);
//   const [styling, setstyling] = useState(false);
//   const [showTimer, setShowTimer] = useState(true);

//   useEffect(() => {
//     let myInterval = setInterval(() => {
//       if (Remainingtime > 0) {
//         setSeconds(seconds - 1);
//       }
//       if (seconds === 0) {
//         if (minutes === 0) {
//           clearInterval(myInterval);
//         } else {
//           setMinutes(minutes - 1);
//           setSeconds(59);
//         }
//       }
//     }, 1000);
//     return () => {
//       clearInterval(myInterval);
//       if (mm < 5) {
//         setstyling(true);
//       }
//       if (hh.charAt(0) === "0" || hh.charAt(1) === "0") {
//         setShowTimer(false);
//       }
//     };
//   }, [hh, mm, seconds, minutes]);
//   if (status === STATUSES.LOADING) {
//     return (
//       <h2
//         style={{
//           margin: "100px",
//         }}
//       >
//       </h2>
//     );
//   }

//   if (status === STATUSES.ERROR) {
//     return (
//       <h2
//         style={{
//           margin: "100px",
//         }}
//       >
//         Something went wrong!
//       </h2>
//     );
//   }

//   return (
//     <>
//       <div className="Addington">
//         <div className='AddingtonStyle'>
//           <img src={horse} alt='' />
//           <p className='Addingtonname'>{t('addington')}</p>
//           <p  className='Addingtontimer'>
//           <div className="timer">

//         </div>
//             {/* <Counter /> */}
//             {
//               CompetitionTimer ? CompetitionTimer[0].RaceDataOfCompetition.TimeRemaining : <></>
//             }
//           </p>
//         </div>
//       </div>
//     </>
//   )
// }
// export default Addington
