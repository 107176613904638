import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Animate from "../assets/loader.json";
import Lottie from "lottie-react";
import { fetchUserTrackedHorses , STATUSES } from "../redux/getReducer/getHorseTrack";
import { fetchUserTrackedTrainer } from "../redux/getReducer/getTrainerTrack";
import { fetchUserTrackedJockey } from "../redux/getReducer/getJockeyTrack";
import { fetchUserTrackedOwner } from "../redux/getReducer/getOwnerTrack";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import axios from "axios";
import { toast } from "react-toastify";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import ExNews from "../Components/Home/Components/ExNews";
import Search from "../Components/Home/Components/Search";
import Notification from "../Components/Home/Components/Notification";
import Language from "../Components/Home/Components/Language";
import Footer from "../Components/Reuseable/Footer";
import Copyrights from "../Components/Reuseable/Copyrights";

const WinnerList = () => {


  const { data: UserTrackedHorses, status } = useSelector((state) => state.UserTrackedHorses);
  const { data: UserTrackedTrainer } = useSelector((state) => state.UserTrackedTrainer);
  const { data: UserTrackedOwner } = useSelector((state) => state.UserTrackedOwner);
  const { data: UserTrackedJockey } = useSelector((state) => state.UserTrackedJockey);


  const { t } = useTranslation();
  const cookiedata = Cookies.get("i18next");
  const token = Cookies.get("token");
  const navigate = useNavigate();
  const {pathname} = useLocation()


  const dispatch = useDispatch();
  // const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchUserTrackedHorses({token}));
    dispatch(fetchUserTrackedOwner({token}));
    dispatch(fetchUserTrackedJockey({token}));
    dispatch(fetchUserTrackedTrainer({token}));
    navigate("/tracker")

  }, [dispatch]);

  if (status === STATUSES.LOADING) {
    return <Lottie animationData={Animate} className="load" />;
  }
  if (status === STATUSES.ERROR) {
    return (
      <h2
        style={{
          margin: "100px",
        }}
      >
        Something went wrong!
      </h2>
    );
  }

 

  const handleUnTrackJockey = async (Id) => {
    try {
      await axios.post(
        `${window.env.API_URL}/UnTrackJockey`, { Jockey: Id },
        {
          headers: { Authorization: token, "x-xsrf-token": token },
        },

      );
      toast('UnTracked Success')
      dispatch(fetchUserTrackedJockey());

    } catch (error) {
      const err = error.response.data.message;
      toast(err)

    }
  };


  const handleUnTrackOwner = async (Id) => {
    try {
      await axios.post(
        `${window.env.API_URL}/UnTrackOwner`, { Owner: Id },
        {
          headers: { Authorization: token, "x-xsrf-token": token },
        },

      );
      toast('UnTracked Success')
      dispatch(fetchUserTrackedOwner());

    } catch (error) {
      const err = error.response.data.message;
      toast(err)

    }
  };

  const handleUnTrackTrainer = async (Id) => {
    try {
      await axios.post(
        `${window.env.API_URL}/UnTrackTrainer`, { Trainer: Id },
        {
          headers: { Authorization: token, "x-xsrf-token": token },
        },

      );
      toast('UnTracked Success')
      dispatch(fetchUserTrackedTrainer());

    } catch (error) {
      const err = error.response.data.message;
      toast(err)

    }
  };

  const handleUnTrackHorse = async (Id) => {
    try {
      await axios.post(
        `${window.env.API_URL}/UnTrackHorse`, { Horse: Id },
        {
          headers: { Authorization: token, "x-xsrf-token": token },
        },

      );
      toast('UnTracked Success')
      dispatch(fetchUserTrackedHorses());

    } catch (error) {
      const err = error.response.data.message;
      toast(err)

    }
  };
  


  const href =  window.location.href.substring(
    window.location.href.lastIndexOf('#') + 1
  );



  return (
    <Fragment>

      <div className="rightlayoutinner dashboardH">
      <div className="userHeader">
        <h3>{t("Tracker")}</h3>
      </div>
        
      </div>

     
      <Tabs
      id="controlled-tab-example"
      className="ButtonTracker"
      defaultActiveKey={pathname === "/tracker" ? "Horseshome" : href}
      transition={false}
      >
      <Tab eventKey="Horseshome" title=  {t("Horses")} >
      <>
          <div className="userHeader2">
            <div className="trackerhead">
              <h6>{t("HorseName")}</h6>
              <h6></h6>
            </div>
            <h6>{t("Action")}</h6>
          </div>

          <div className="tab-tracker">
            {UserTrackedHorses === undefined ? (
              <></>
            ) : (
              <>
                {UserTrackedHorses && UserTrackedHorses.map((item) => {
                  return (
                    <div className="userHeader3">
                      <div className="trackerhead">
                        <h6>{cookiedata === "en" ?  (item.TrackHorses ? item.TrackHorses.NameEn : <>-</>) : (item.TrackHorses ? item.TrackHorses.NameAr : <>-</>)}</h6>
                        <h5  style={{
                          color:'#000'
                        }}></h5>
                      </div>
                      <button className="untrackdata" onClick={() => handleUnTrackHorse(item.TrackHorses._id)}>{t("Remove")}</button>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </>
      </Tab>
      <Tab eventKey="Trainerprofile" title= {t("Trainer")}>
      <>
          <div className="userHeader2">
            <div className="trackerhead">
              <h6>{t("TrainerName")}</h6>
              <h5></h5>
            </div>
            <h6>{t("Action")}</h6>
          </div>

          <div className="tab-tracker">
            {UserTrackedTrainer === undefined ? (
              <></>
            ) : (
              <>
                {UserTrackedTrainer.TrackTrainers && UserTrackedTrainer.TrackTrainers.map((item) => {
                  return (
                    <div className="userHeader3">
                      <div className="trackerhead">
                        <h6>{cookiedata === "en" ? item.NameEn : item.NameAr}</h6>
                        <h5 style={{
                          color:'#000'
                        }}>
                        </h5>
                      </div>
                      <button className="untrackdata" onClick={() => handleUnTrackTrainer(item._id)}>{t("Remove")}</button>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </>
      </Tab>
      <Tab eventKey="Ownercontact" title= {t("Owner")}>
      <>
          <div className="userHeader2">
            <div className="trackerhead">
              <h6>{t("OwnerName")}</h6>
              <h5></h5>
            </div>
            <h6>{t("Action")}</h6>
          </div>

          <div className="tab-tracker">
          {UserTrackedOwner === undefined ? (
              <></>
            ) : (
              <>
                {UserTrackedOwner.TrackOwners && UserTrackedOwner.TrackOwners.map((item) => {
                  return (
                    <div className="userHeader3">
                      <div className="trackerhead">
                        <h6>{cookiedata === "en" ? item.NameEn : item.NameAr}</h6>
                        <h5 style={{
                          color:'#000'
                        }}>
                          {/* {item.TrackOwners.ApprovedStatus === true ? (
                            <>{t("Runner")}</>
                          ) : (
                            <>{t("NonRunner")}</>
                          )} */}
                        </h5>
                      </div>
                      <button className="untrackdata" onClick={() => handleUnTrackOwner(item._id)}>{t("Remove")}</button>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </>
      </Tab>
      <Tab eventKey="Jockeycontact1" title= {t("Jockey")}>
      <>
          <div className="userHeader2">
            <div className="trackerhead">
              <h6>{t("Jockey Name")}</h6>
              <h5></h5>
            </div>
            <h6>{t("Action")}</h6>
          </div>

          <div className="tab-tracker">
          {UserTrackedJockey === undefined ? (
              <></>
            ) : (
              <>
                {UserTrackedJockey.TrackSubscriber1 && UserTrackedJockey.TrackSubscriber1.map((item) => {
                  return (
                    <div className="userHeader3">
                      <div className="trackerhead">
                        <h6>{cookiedata === "en" ? (item.TrackJockeys ? item.TrackJockeys.NameEn : <>-</>) : (item.TrackJockeys ? item.TrackJockeys.NameAr : <>-</>)}</h6>
                        <h5 style={{
                          color:'#000'
                        }}>
                         
                        </h5>
                      </div>
                      <button className="untrackdata" onClick={() => handleUnTrackJockey(item.TrackJockeys._id)}>{t("Remove")}</button>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </>      
      </Tab>
    </Tabs>
    <hr />
              <Footer />
              <Copyrights />
    </Fragment>
  );
};

export default WinnerList;
