import { configureStore } from "@reduxjs/toolkit";
import getAdsSlice from "./getReducer/getAdsSlice";
import getHorseSlice from "./getReducer/getHorseSlice";
import getNewsSlice from "./getReducer/getNewsSlice";
import getSponsorSlice from "./getReducer/getSponsorSlice";
import getTrainerSlice from "./getReducer/getTrainerSlice";
import getRaceCard from "./getReducer/getRaceCard";
import getSlider from "./getReducer/getSlider";
import userReducer from "./getReducer/UserSlice";
import getRaceCourse from "./getReducer/getRaceCourse";
import getJockeySlice from "./getReducer/getJockeySlice";
import getOwnerSlice from "./getReducer/getOwnerSlice";
import { getUserDetails } from "./postReducer/UserPost";
import getUserProfile from "./getReducer/getUserProfile";
import getSingleHorse from "./getReducer/getSingleHorse";
import getSingleJockey from "./getReducer/getSingleJockey";
import getSingleTrainer from "./getReducer/getSingleTrainer";
import getSingleRace from "./getReducer/getSingleRace";
import getSeo from "./getReducer/getSeo";
import getCard from "./getReducer/getCard";
import getSingleCourse from "./getReducer/getSingleCourse";
import getCompetition from "./getReducer/getCompetition";
import getRaceResult from "./getReducer/getRaceResult";
import getNationality from "./getReducer/getNationality";
import getSingleUser from "./getReducer/getSingleUser";
import getPedigree from "./getReducer/getPedigree";
import getPredictor from "./getReducer/Predictor";
import getRaceResultAnnounced from "./getReducer/getRaceResultAnnounced";
import getPredictionSlice from "./getReducer/getPredictionSlice";
import getRaceCourseToday from "./getReducer/getRaceCourseToday";
import getRaceWithNumber from "./getReducer/getRacewithNumber";
import getthemeSlice from "./getReducer/getthemeSlice";
import getCalender from "./getReducer/getCalender";
import getRacePrediction from "./getReducer/getRacePrediction";
import getRaceCardEveryDay from "./getReducer/getRaceCardEveryDay";
import getLeader from "./getReducer/getLeader";
import getSelection from "./getReducer/getSelection";
import getCompetitionTimer from "./getReducer/getCompetitionTimer";
import TrackRecord from "./getReducer/TrackRecord";
import getAntePost from "./getReducer/getAntePost";
import getSingleRaceHorse from "./getReducer/getSingleRaceHorse";
import getRaceScreen from "./getReducer/getScreenRace";
import getPointStagingSlice from "./getReducer/getPointStagingSlice";
import getWinner from "./getReducer/getWinner";
import getSummary from "./getReducer/getSummarySlice";
import getDraw from "./getReducer/getDraw";
import getSelectedHorse from "./getReducer/getSelectedHorse";
import getRaceCalenderDetail from "./getReducer/getRaceCalenderDetail";
import getPrediction from "./getReducer/getPrediction";
import getResultYearDropdown from "./getQueryReducer/getResultYearDropdown";
import getResultDates from "./getQueryReducer/getResultDates";
import getResultRaceCourse from "./getQueryReducer/getResultRaceCourse";
import getResultRaceType from "./getQueryReducer/getResultRaceType";
import getResultNationality from "./getQueryReducer/getResultNationality";
import getResultRaceGround from "./getQueryReducer/getResultRaceGround";
import getResultRaceTracklength from "./getQueryReducer/getResultRaceTracklength";
import getResultHorseId from "./getQueryReducer/getResultHorseId";
import getResultTrainer from "./getQueryReducer/getResultTrainer";
import getResultJockey from "./getQueryReducer/getResultJockey";
import getResultOwner from "./getQueryReducer/getResultOwner";
import getResultMeeting from "./getQueryReducer/getResultMeeting";
import getMainQueryTool from "./getQueryReducer/getMainQueryTool";
import getTrainerStats from "./getReducer/getTrainerStats";
import getTrainerRecord from "./getReducer/getTrainerRecord";
import getTrainerHistory from "./getReducer/getTrainerHistory";
import getHorseHistory from "./getReducer/getHorseHistory";
import getJockeyHistory from "./getReducer/getJockeyHistory";
import getJockeyStats from "./getReducer/getJockeyStats";
import getHorseStats from "./getReducer/getHorseStats";
import getHotForm from "./getReducer/getHotForm";
import getHorseRecord from "./getReducer/getHorseRecord";
import getJockeyTrainerCombo from "./getReducer/getJockeyTrainerCombo";
import getsingleJockeyTrainerCombo from "./getReducer/getsingleJockeyTrainerCombo";
import getResultSponsor from "./getQueryReducer/getResultSponsor";
import getSireSnippets from "./getReducer/getSireSnippets";
import getHorseStatsDetail from "./getReducer/getHorseStatsDetail";
import getFutureEntries from "./getReducer/getFutureEntries";
import getStatsTrainer from "./getReducer/getStatsTrainer";
import getSireHistoryForStatisitics from "./getReducer/getSireHistoryForStatisitics";
import getSingleTrainerStats from "./getReducer/getTrainerStatistics"
import getRacecourseStats from "./getReducer/getRacecourseStats";
import getResultHorseKind from "./getQueryReducer/getResultHorseKind";
import getResultYearFilterForStats from "./getStatsFilterReduce/getResultYearFilterForStats";
import getRaceTypeFilterForStats from "./getStatsFilterReduce/getRaceTypeFilterForStats";
import getTrackLengthFilterForStats from "./getStatsFilterReduce/getTrackLengthFilterForStats";
import getHorseKindFilterForStats from "./getStatsFilterReduce/getHorseKindFilterForStats";
import getRaceNationalityFilterForStats from "./getStatsFilterReduce/getRaceNationalityFilterForStats";
import getRaceCourseFilterForStats from "./getStatsFilterReduce/getRaceCourseFilterForStats";
import getDatesFilterForStats from "./getStatsFilterReduce/getDatesFilterForStats";
import getRaceKindFilterForStats from "./getStatsFilterReduce/getRaceKindFilterForStats";
import getGroundTypeFilterForStats from "./getStatsFilterReduce/getGroundTypeFilterForStats";
import getSingleOwner from "./getReducer/getSingleOwner";
import getLatestResultJockeyStats from "./getReducer/getLatestResultJockeyStats";
import getSingleOwnerHistory from "./getReducer/getSingleOwnerHistory";
import getSingleOwnerStats from "./getReducer/getSingleOwnerStats";
import getEntriesOwner from "./getReducer/getEntriesOwner";
import getTrainerEntries from "./getReducer/getTrainerEntries";
import getJockeyEntriesSlice from "./getReducer/getEntriesJockey"
import getNotificationUser from "./getReducer/getNotificationUser";
import getAllNotification from "./getReducer/getAllNotification";
import getAtoZReport from "./getReducer/getAtoZReport";
import getHorseTrack from "./getReducer/getHorseTrack";
import getTrainerTrack from "./getReducer/getTrainerTrack";
import getOwnerTrack from "./getReducer/getOwnerTrack";
import getJockeyTrack from "./getReducer/getJockeyTrack";
import getValidateUser from "./getReducer/getValidateUser";
import getOwnerHorses from "./getReducer/getOwnerHorses";
import getHorseModalstats from "./getReducer/getHorseModalstats";
import getTrainerModalstats from "./getReducer/getTrainerModalstats";
import getJockeyModalstats from "./getReducer/getJockeyModalstats";
import getOwnerModalstats from "./getReducer/getOwnerModalstats";
import getOwnerHorsesHistory from "./getReducer/getOwnerHorsesHistory";
import getracecardscreen from "./getReducer/getracecardscreen";
import getraceresultscreen from "./getReducer/getraceresultscreen";
import getOwnerStats from "./getReducer/getOwnerStats";
import getOwnerRecord from "./getReducer/getOwnerRecord";
import ChangePassword from "./postReducer/ChangePassword";
import getOwnerHorseKind from "./getReducer/getOwnerHorseKind";

const store = configureStore({
  reducer: {
    news: getNewsSlice,
    ads: getAdsSlice,
    ownerhorsestats:getOwnerHorses,
    sponsor: getSponsorSlice,
    horse: getHorseSlice,
    trainer: getTrainerSlice,
    racecard: getRaceCard,
    slider: getSlider,
    user: userReducer,
    racecourse: getRaceCourse,
    jockey: getJockeySlice,
    owner: getOwnerSlice,
    userProfile: getUserDetails,
    profile: getUserProfile,
    singlehorse: getSingleHorse,
    singlejockey: getSingleJockey,
    singletrainer: getSingleTrainer,
    singlerace: getSingleRace,
    Seo: getSeo,
    Card: getCard,
    singlecourse: getSingleCourse,
    competition: getCompetition,
    raceresult: getRaceResult,
    nationality: getNationality,
    singleUser: getSingleUser,
    Pedigree: getPedigree,
    predictor: getPredictor,
    raceresultDeclared: getRaceResultAnnounced,
    PredictionRace: getPredictionSlice,
    RaceCourseRaceToday: getRaceCourseToday,
    RacewithNumber: getRaceWithNumber,
    ThemeSetting: getthemeSlice,
    CalenderData: getCalender,
    RacePredictor: getRacePrediction,
    RaceCardEveryday: getRaceCardEveryDay,
    LeaderBoard: getLeader,
    LeaderBoardoffollowingcompetition: getSelection,
    CompetitionTimer: getCompetitionTimer,
    TrackRecord: TrackRecord,
    AntePostRaces: getAntePost,
    singleracehorse: getSingleRaceHorse,
    racescreen: getRaceScreen,
    PointStaging: getPointStagingSlice,
    Winner: getWinner,
    summary: getSummary,
    draw: getDraw,
    SelectedHorse: getSelectedHorse,
    CalenderDetail: getRaceCalenderDetail,
    PredictionOfHorse: getPrediction,

    ResultYearDropdown: getResultYearDropdown,
    ResultDates: getResultDates,
    ResultRaceCourse: getResultRaceCourse,
    ResultRaceType: getResultRaceType,
    ResultNationality: getResultNationality,
    ResultRaceGround: getResultRaceGround,
    ResultRaceTracklength: getResultRaceTracklength,
    ResultHorseId: getResultHorseId,
    ResultTrainer: getResultTrainer,
    ResultJockey: getResultJockey,
    ResultOwner: getResultOwner,
    ResultMeeting: getResultMeeting,
    QueryTool: getMainQueryTool,
    trainerstats: getTrainerStats,
    trainerrecord: getTrainerRecord,
    TrainerHistory: getTrainerHistory,
    horsehistory: getHorseHistory,
    JockeyHistory: getJockeyHistory,
    jockeystats: getJockeyStats,
    horsestats: getHorseStats,
    HotForm: getHotForm,
    horserecord: getHorseRecord,
    JockeyTrainerCombo: getJockeyTrainerCombo,
    JockeyTrainerCombosingle: getsingleJockeyTrainerCombo,
    ResultSponsor: getResultSponsor,
    SireSnippets: getSireSnippets,
    horsestatsdetail: getHorseStatsDetail,
    fututreentries: getFutureEntries,
    LatestResultOfHorsesWithTrainer: getStatsTrainer,
    SireHistoryForStatisitics: getSireHistoryForStatisitics,
    singletrainerstats: getSingleTrainerStats,
    racecoursestats: getRacecourseStats,
    HorseKindDropDown: getResultHorseKind,
    ResultYearFilterForStats: getResultYearFilterForStats,
    RaceTypeFilterForStats: getRaceTypeFilterForStats,
    TrackLengthFilterForStats: getTrackLengthFilterForStats,
    HorseKindFilterForStats: getHorseKindFilterForStats,
    RaceNationalityFilterForStats: getRaceNationalityFilterForStats,
    RaceCourseFilterForStats: getRaceCourseFilterForStats,
    DatesFilterForStats: getDatesFilterForStats,
    RaceKindFilterForStats: getRaceKindFilterForStats,
    GroundTypeFilterForStats: getGroundTypeFilterForStats,
    getsingleowner: getSingleOwner,
    JockeyDetailStats: getLatestResultJockeyStats,
    OwnerHistory: getSingleOwnerHistory,
    LatestResultOfHorsesWithOwner: getSingleOwnerStats,
    entriesowner: getEntriesOwner,
    entriestrainer: getTrainerEntries,
    entriesjockey: getJockeyEntriesSlice,
    NotificationOfUser:getNotificationUser,
    AllNotification:getAllNotification,
    AtoZReport:getAtoZReport,
    UserTrackedHorses:getHorseTrack,
    UserTrackedTrainer:getTrainerTrack,
    UserTrackedOwner:getOwnerTrack,
    UserTrackedJockey:getJockeyTrack,
    ValidateUser:getValidateUser,
    horsemodalstats:getHorseModalstats,
    trainermodalstats:getTrainerModalstats,
    jockeymodalstats:getJockeyModalstats,
    ownermodalstats:getOwnerModalstats,
    OwnerHorsesHistory:getOwnerHorsesHistory,
    racecardscreen:getracecardscreen,
    ResultScreencreen:getraceresultscreen,
    OwnerStatsPage:getOwnerStats,
    Ownerrecord:getOwnerRecord,
    changePassword:ChangePassword,
    ownerhorsekindstats:getOwnerHorseKind

  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
