import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const RaceSectionTimer = ({ timer, starttime }) => {
  const [state, setState] = useState(false);
  const hh = timer.split(":")[0];
  const min = timer.split(":")[1];
  const sec = timer.split(":")[2];

  const mm = parseInt(min);

  const [minutes, setMinutes] = useState(min);
  const [seconds, setSeconds] = useState(sec);
  const [styling, setstyling] = useState(false);
  const [showTimer, setShowTimer] = useState(true);

  var d = new Date(starttime);

  const HoursofStarttime = d.toLocaleTimeString(); // Hours

  useEffect(() => {
    if (hh.charAt(0) === "-" || hh === -0 || mm === "-00" || mm === 0) {
      setState(true);
    }

    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
      if (mm < 5) {
        setstyling(true);
      }
      if (hh.charAt(0) === "0" || hh.charAt(1) === "0") {
        setShowTimer(false);
      }
      if (
        hh.charAt(0) === "1" ||
        hh.charAt(1) === "1" ||
        hh.charAt(1) === "2" ||
        hh.charAt(1) === "3" ||
        hh.charAt(1) === "4" ||
        hh.charAt(1) === "5" ||
        hh.charAt(1) === "6" ||
        hh.charAt(1) === "7" ||
        hh.charAt(1) === "8" ||
        hh.charAt(1) === "9"
      ) {
        setShowTimer(true);
      }
    };
  }, [hh, mm, seconds, minutes, showTimer]);

  return (
    <>
      {showTimer ? (
        <p>{HoursofStarttime}</p>
      ) : state ? (
        <> {HoursofStarttime}</>
      ) : minutes === 0 && seconds === 0 ? null : (
        <p className={styling ? "redclass" : ""}>
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </p>
      )}
    </>
  );
};

export default RaceSectionTimer;
