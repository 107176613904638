export const Country_Name = [
  { "country_id": "229", "sortname": "AE", "country_name": "United Arab Emirates" },

    { "country_id": "1", "sortname": "AF", "country_name": "Afghanistan" },
    { "country_id": "2", "sortname": "AL", "country_name": "Albania" },
    { "country_id": "3", "sortname": "DZ", "country_name": "Algeria" },
    { "country_id": "4", "sortname": "AS", "country_name": "American Samoa" },
    { "country_id": "5", "sortname": "AD", "country_name": "Andorra" },
    { "country_id": "6", "sortname": "AO", "country_name": "Angola" },
    { "country_id": "7", "sortname": "AI", "country_name": "Anguilla" },
    { "country_id": "8", "sortname": "AQ", "country_name": "Antarctica" },
    { "country_id": "9", "sortname": "AG", "country_name": "Antigua And Barbuda" },
    { "country_id": "10", "sortname": "AR", "country_name": "Argentina" },
    { "country_id": "11", "sortname": "AM", "country_name": "Armenia" },
    { "country_id": "12", "sortname": "AW", "country_name": "Aruba" },
    { "country_id": "13", "sortname": "AU", "country_name": "Australia" },
    { "country_id": "14", "sortname": "AT", "country_name": "Austria" },
    { "country_id": "15", "sortname": "AZ", "country_name": "Azerbaijan" },
    { "country_id": "16", "sortname": "BS", "country_name": "Bahamas The" },
    { "country_id": "17", "sortname": "BH", "country_name": "Bahrain" },
    { "country_id": "18", "sortname": "BD", "country_name": "Bangladesh" },
    { "country_id": "19", "sortname": "BB", "country_name": "Barbados" },
    { "country_id": "20", "sortname": "BY", "country_name": "Belarus" },
    { "country_id": "21", "sortname": "BE", "country_name": "Belgium" },
    { "country_id": "22", "sortname": "BZ", "country_name": "Belize" },
    { "country_id": "23", "sortname": "BJ", "country_name": "Benin" },
    { "country_id": "24", "sortname": "BM", "country_name": "Bermuda" },
    { "country_id": "25", "sortname": "BT", "country_name": "Bhutan" },
    { "country_id": "26", "sortname": "BO", "country_name": "Bolivia" },
    { "country_id": "27", "sortname": "BA", "country_name": "Bosnia and Herzegovina" },
    { "country_id": "28", "sortname": "BW", "country_name": "Botswana" },
    { "country_id": "29", "sortname": "BV", "country_name": "Bouvet Island" },
    { "country_id": "30", "sortname": "BR", "country_name": "Brazil" },
    { "country_id": "31", "sortname": "IO", "country_name": "British Indian Ocean Territory" },
    { "country_id": "32", "sortname": "BN", "country_name": "Brunei" },
    { "country_id": "33", "sortname": "BG", "country_name": "Bulgaria" },
    { "country_id": "34", "sortname": "BF", "country_name": "Burkina Faso" },
    { "country_id": "35", "sortname": "BI", "country_name": "Burundi" },
    { "country_id": "36", "sortname": "KH", "country_name": "Cambodia" },
    { "country_id": "37", "sortname": "CM", "country_name": "Cameroon" },
    { "country_id": "38", "sortname": "CA", "country_name": "Canada" },
    { "country_id": "39", "sortname": "CV", "country_name": "Cape Verde" },
    { "country_id": "40", "sortname": "KY", "country_name": "Cayman Islands" },
    { "country_id": "41", "sortname": "CF", "country_name": "Central African Republic" },
    { "country_id": "42", "sortname": "TD", "country_name": "Chad" },
    { "country_id": "43", "sortname": "CL", "country_name": "Chile" },
    { "country_id": "44", "sortname": "CN", "country_name": "China" },
    { "country_id": "45", "sortname": "CX", "country_name": "Christmas Island" },
    { "country_id": "46", "sortname": "CC", "country_name": "Cocos (Keeling) Islands" },
    { "country_id": "47", "sortname": "CO", "country_name": "Colombia" },
    { "country_id": "48", "sortname": "KM", "country_name": "Comoros" },
    { "country_id": "49", "sortname": "CG", "country_name": "Congo" },
    { "country_id": "50", "sortname": "CD", "country_name": "Congo The Democratic Republic Of The" },
    { "country_id": "51", "sortname": "CK", "country_name": "Cook Islands" },
    { "country_id": "52", "sortname": "CR", "country_name": "Costa Rica" },
    { "country_id": "53", "sortname": "CI", "country_name": "Cote D'Ivoire (Ivory Coast)" },
    { "country_id": "54", "sortname": "HR", "country_name": "Croatia (Hrvatska)" },
    { "country_id": "55", "sortname": "CU", "country_name": "Cuba" },
    { "country_id": "56", "sortname": "CY", "country_name": "Cyprus" },
    { "country_id": "57", "sortname": "CZ", "country_name": "Czech Republic" },
    { "country_id": "58", "sortname": "DK", "country_name": "Denmark" },
    { "country_id": "59", "sortname": "DJ", "country_name": "Djibouti" },
    { "country_id": "60", "sortname": "DM", "country_name": "Dominica" },
    { "country_id": "61", "sortname": "DO", "country_name": "Dominican Republic" },
    { "country_id": "62", "sortname": "TP", "country_name": "East Timor" },
    { "country_id": "63", "sortname": "EC", "country_name": "Ecuador" },
    { "country_id": "64", "sortname": "EG", "country_name": "Egypt" },
    { "country_id": "65", "sortname": "SV", "country_name": "El Salvador" },
    { "country_id": "66", "sortname": "GQ", "country_name": "Equatorial Guinea" },
    { "country_id": "67", "sortname": "ER", "country_name": "Eritrea" },
    { "country_id": "68", "sortname": "EE", "country_name": "Estonia" },
    { "country_id": "69", "sortname": "ET", "country_name": "Ethiopia" },
    { "country_id": "70", "sortname": "XA", "country_name": "External Territories of Australia" },
    { "country_id": "71", "sortname": "FK", "country_name": "Falkland Islands" },
    { "country_id": "72", "sortname": "FO", "country_name": "Faroe Islands" },
    { "country_id": "73", "sortname": "FJ", "country_name": "Fiji Islands" },
    { "country_id": "74", "sortname": "FI", "country_name": "Finland" },
    { "country_id": "75", "sortname": "FR", "country_name": "France" },
    { "country_id": "76", "sortname": "GF", "country_name": "French Guiana" },
    { "country_id": "77", "sortname": "PF", "country_name": "French Polynesia" },
    { "country_id": "78", "sortname": "TF", "country_name": "French Southern Territories" },
    { "country_id": "79", "sortname": "GA", "country_name": "Gabon" },
    { "country_id": "80", "sortname": "GM", "country_name": "Gambia The" },
    { "country_id": "81", "sortname": "GE", "country_name": "Georgia" },
    { "country_id": "82", "sortname": "DE", "country_name": "Germany" },
    { "country_id": "83", "sortname": "GH", "country_name": "Ghana" },
    { "country_id": "84", "sortname": "GI", "country_name": "Gibraltar" },
    { "country_id": "85", "sortname": "GR", "country_name": "Greece" },
    { "country_id": "86", "sortname": "GL", "country_name": "Greenland" },
    { "country_id": "87", "sortname": "GD", "country_name": "Grenada" },
    { "country_id": "88", "sortname": "GP", "country_name": "Guadeloupe" },
    { "country_id": "89", "sortname": "GU", "country_name": "Guam" },
    { "country_id": "90", "sortname": "GT", "country_name": "Guatemala" },
    { "country_id": "91", "sortname": "XU", "country_name": "Guernsey and Alderney" },
    { "country_id": "92", "sortname": "GN", "country_name": "Guinea" },
    { "country_id": "93", "sortname": "GW", "country_name": "Guinea-Bissau" },
    { "country_id": "94", "sortname": "GY", "country_name": "Guyana" },
    { "country_id": "95", "sortname": "HT", "country_name": "Haiti" },
    { "country_id": "96", "sortname": "HM", "country_name": "Heard and McDonald Islands" },
    { "country_id": "97", "sortname": "HN", "country_name": "Honduras" },
    { "country_id": "98", "sortname": "HK", "country_name": "Hong Kong S.A.R." },
    { "country_id": "99", "sortname": "HU", "country_name": "Hungary" },
    { "country_id": "100", "sortname": "IS", "country_name": "Iceland" },
    { "country_id": "101", "sortname": "IN", "country_name": "India" },
    { "country_id": "102", "sortname": "ID", "country_name": "Indonesia" },
    { "country_id": "103", "sortname": "IR", "country_name": "Iran" },
    { "country_id": "104", "sortname": "IQ", "country_name": "Iraq" },
    { "country_id": "105", "sortname": "IE", "country_name": "Ireland" },
    { "country_id": "106", "sortname": "IL", "country_name": "Israel" },
    { "country_id": "107", "sortname": "IT", "country_name": "Italy" },
    { "country_id": "108", "sortname": "JM", "country_name": "Jamaica" },
    { "country_id": "109", "sortname": "JP", "country_name": "Japan" },
    { "country_id": "110", "sortname": "XJ", "country_name": "Jersey" },
    { "country_id": "111", "sortname": "JO", "country_name": "Jordan" },
    { "country_id": "112", "sortname": "KZ", "country_name": "Kazakhstan" },
    { "country_id": "113", "sortname": "KE", "country_name": "Kenya" },
    { "country_id": "114", "sortname": "KI", "country_name": "Kiribati" },
    { "country_id": "115", "sortname": "KP", "country_name": "Korea North" },
    { "country_id": "116", "sortname": "KR", "country_name": "Korea South" },
    { "country_id": "117", "sortname": "KW", "country_name": "Kuwait" },
    { "country_id": "118", "sortname": "KG", "country_name": "Kyrgyzstan" },
    { "country_id": "119", "sortname": "LA", "country_name": "Laos" },
    { "country_id": "120", "sortname": "LV", "country_name": "Latvia" },
    { "country_id": "121", "sortname": "LB", "country_name": "Lebanon" },
    { "country_id": "122", "sortname": "LS", "country_name": "Lesotho" },
    { "country_id": "123", "sortname": "LR", "country_name": "Liberia" },
    { "country_id": "124", "sortname": "LY", "country_name": "Libya" },
    { "country_id": "125", "sortname": "LI", "country_name": "Liechtenstein" },
    { "country_id": "126", "sortname": "LT", "country_name": "Lithuania" },
    { "country_id": "127", "sortname": "LU", "country_name": "Luxembourg" },
    { "country_id": "128", "sortname": "MO", "country_name": "Macau S.A.R." },
    { "country_id": "129", "sortname": "MK", "country_name": "Macedonia" },
    { "country_id": "130", "sortname": "MG", "country_name": "Madagascar" },
    { "country_id": "131", "sortname": "MW", "country_name": "Malawi" },
    { "country_id": "132", "sortname": "MY", "country_name": "Malaysia" },
    { "country_id": "133", "sortname": "MV", "country_name": "Maldives" },
    { "country_id": "134", "sortname": "ML", "country_name": "Mali" },
    { "country_id": "135", "sortname": "MT", "country_name": "Malta" },
    { "country_id": "136", "sortname": "XM", "country_name": "Man (Isle of)" },
    { "country_id": "137", "sortname": "MH", "country_name": "Marshall Islands" },
    { "country_id": "138", "sortname": "MQ", "country_name": "Martinique" },
    { "country_id": "139", "sortname": "MR", "country_name": "Mauritania" },
    { "country_id": "140", "sortname": "MU", "country_name": "Mauritius" },
    { "country_id": "141", "sortname": "YT", "country_name": "Mayotte" },
    { "country_id": "142", "sortname": "MX", "country_name": "Mexico" },
    { "country_id": "143", "sortname": "FM", "country_name": "Micronesia" },
    { "country_id": "144", "sortname": "MD", "country_name": "Moldova" },
    { "country_id": "145", "sortname": "MC", "country_name": "Monaco" },
    { "country_id": "146", "sortname": "MN", "country_name": "Mongolia" },
    { "country_id": "147", "sortname": "MS", "country_name": "Montserrat" },
    { "country_id": "148", "sortname": "MA", "country_name": "Morocco" },
    { "country_id": "149", "sortname": "MZ", "country_name": "Mozambique" },
    { "country_id": "150", "sortname": "MM", "country_name": "Myanmar" },
    { "country_id": "151", "sortname": "NA", "country_name": "Namibia" },
    { "country_id": "152", "sortname": "NR", "country_name": "Nauru" },
    { "country_id": "153", "sortname": "NP", "country_name": "Nepal" },
    { "country_id": "154", "sortname": "AN", "country_name": "Netherlands Antilles" },
    { "country_id": "155", "sortname": "NL", "country_name": "Netherlands The" },
    { "country_id": "156", "sortname": "NC", "country_name": "New Caledonia" },
    { "country_id": "157", "sortname": "NZ", "country_name": "New Zealand" },
    { "country_id": "158", "sortname": "NI", "country_name": "Nicaragua" },
    { "country_id": "159", "sortname": "NE", "country_name": "Niger" },
    { "country_id": "160", "sortname": "NG", "country_name": "Nigeria" },
    { "country_id": "161", "sortname": "NU", "country_name": "Niue" },
    { "country_id": "162", "sortname": "NF", "country_name": "Norfolk Island" },
    { "country_id": "163", "sortname": "MP", "country_name": "Northern Mariana Islands" },
    { "country_id": "164", "sortname": "NO", "country_name": "Norway" },
    { "country_id": "165", "sortname": "OM", "country_name": "Oman" },
    { "country_id": "166", "sortname": "PK", "country_name": "Pakistan" },
    { "country_id": "167", "sortname": "PW", "country_name": "Palau" },
    { "country_id": "168", "sortname": "PS", "country_name": "Palestinian Territory Occupied" },
    { "country_id": "169", "sortname": "PA", "country_name": "Panama" },
    { "country_id": "170", "sortname": "PG", "country_name": "Papua new Guinea" },
    { "country_id": "171", "sortname": "PY", "country_name": "Paraguay" },
    { "country_id": "172", "sortname": "PE", "country_name": "Peru" },
    { "country_id": "173", "sortname": "PH", "country_name": "Philippines" },
    { "country_id": "174", "sortname": "PN", "country_name": "Pitcairn Island" },
    { "country_id": "175", "sortname": "PL", "country_name": "Poland" },
    { "country_id": "176", "sortname": "PT", "country_name": "Portugal" },
    { "country_id": "177", "sortname": "PR", "country_name": "Puerto Rico" },
    { "country_id": "178", "sortname": "QA", "country_name": "Qatar" },
    { "country_id": "179", "sortname": "RE", "country_name": "Reunion" },
    { "country_id": "180", "sortname": "RO", "country_name": "Romania" },
    { "country_id": "181", "sortname": "RU", "country_name": "Russia" },
    { "country_id": "182", "sortname": "RW", "country_name": "Rwanda" },
    { "country_id": "183", "sortname": "SH", "country_name": "Saint Helena" },
    { "country_id": "184", "sortname": "KN", "country_name": "Saint Kitts And Nevis" },
    { "country_id": "185", "sortname": "LC", "country_name": "Saint Lucia" },
    { "country_id": "186", "sortname": "PM", "country_name": "Saint Pierre and Miquelon" },
    { "country_id": "187", "sortname": "VC", "country_name": "Saint Vincent And The Grenadines" },
    { "country_id": "188", "sortname": "WS", "country_name": "Samoa" },
    { "country_id": "189", "sortname": "SM", "country_name": "San Marino" },
    { "country_id": "190", "sortname": "ST", "country_name": "Sao Tome and Principe" },
    { "country_id": "192", "sortname": "SN", "country_name": "Senegal" },
    { "country_id": "193", "sortname": "RS", "country_name": "Serbia" },
    { "country_id": "194", "sortname": "SC", "country_name": "Seychelles" },
    { "country_id": "195", "sortname": "SL", "country_name": "Sierra Leone" },
    { "country_id": "196", "sortname": "SG", "country_name": "Singapore" },
    { "country_id": "197", "sortname": "SK", "country_name": "Slovakia" },
    { "country_id": "198", "sortname": "SI", "country_name": "Slovenia" },
    { "country_id": "199", "sortname": "XG", "country_name": "Smaller Territories of the UK" },
    { "country_id": "200", "sortname": "SB", "country_name": "Solomon Islands" },
    { "country_id": "201", "sortname": "SO", "country_name": "Somalia" },
    { "country_id": "202", "sortname": "ZA", "country_name": "South Africa" },
    { "country_id": "203", "sortname": "GS", "country_name": "South Georgia" },
    { "country_id": "204", "sortname": "SS", "country_name": "South Sudan" },
    { "country_id": "205", "sortname": "ES", "country_name": "Spain" },
    { "country_id": "206", "sortname": "LK", "country_name": "Sri Lanka" },
    { "country_id": "207", "sortname": "SD", "country_name": "Sudan" },
    { "country_id": "208", "sortname": "SR", "country_name": "Suriname" },
    { "country_id": "209", "sortname": "SJ", "country_name": "Svalbard And Jan Mayen Islands" },
    { "country_id": "210", "sortname": "SZ", "country_name": "Swaziland" },
    { "country_id": "211", "sortname": "SE", "country_name": "Sweden" },
    { "country_id": "212", "sortname": "CH", "country_name": "Switzerland" },
    { "country_id": "213", "sortname": "SY", "country_name": "Syria" },
    { "country_id": "214", "sortname": "TW", "country_name": "Taiwan" },
    { "country_id": "215", "sortname": "TJ", "country_name": "Tajikistan" },
    { "country_id": "216", "sortname": "TZ", "country_name": "Tanzania" },
    { "country_id": "217", "sortname": "TH", "country_name": "Thailand" },
    { "country_id": "218", "sortname": "TG", "country_name": "Togo" },
    { "country_id": "219", "sortname": "TK", "country_name": "Tokelau" },
    { "country_id": "220", "sortname": "TO", "country_name": "Tonga" },
    { "country_id": "221", "sortname": "TT", "country_name": "Trinidad And Tobago" },
    { "country_id": "222", "sortname": "TN", "country_name": "Tunisia" },
    { "country_id": "223", "sortname": "TR", "country_name": "Turkey" },
    { "country_id": "224", "sortname": "TM", "country_name": "Turkmenistan" },
    { "country_id": "225", "sortname": "TC", "country_name": "Turks And Caicos Islands" },
    { "country_id": "226", "sortname": "TV", "country_name": "Tuvalu" },
    { "country_id": "227", "sortname": "UG", "country_name": "Uganda" },
    { "country_id": "228", "sortname": "UA", "country_name": "Ukraine" },
    { "country_id": "230", "sortname": "GB", "country_name": "United Kingdom" },
    { "country_id": "231", "sortname": "US", "country_name": "United States" },
    { "country_id": "232", "sortname": "UM", "country_name": "United States Minor Outlying Islands" },
    { "country_id": "233", "sortname": "UY", "country_name": "Uruguay" },
    { "country_id": "234", "sortname": "UZ", "country_name": "Uzbekistan" },
    { "country_id": "235", "sortname": "VU", "country_name": "Vanuatu" },
    { "country_id": "236", "sortname": "VA", "country_name": "Vatican City State (Holy See)" },
    { "country_id": "237", "sortname": "VE", "country_name": "Venezuela" },
    { "country_id": "238", "sortname": "VN", "country_name": "Vietnam" },
    { "country_id": "239", "sortname": "VG", "country_name": "Virgin Islands (British)" },
    { "country_id": "240", "sortname": "VI", "country_name": "Virgin Islands (US)" },
    { "country_id": "241", "sortname": "WF", "country_name": "Wallis And Futuna Islands" },
    { "country_id": "242", "sortname": "EH", "country_name": "Western Sahara" },
    { "country_id": "243", "sortname": "YE", "country_name": "Yemen" },
    { "country_id": "244", "sortname": "YU", "country_name": "Yugoslavia" },
    { "country_id": "245", "sortname": "ZM", "country_name": "Zambia" },
    { "country_id": "246", "sortname": "ZW", "country_name": "Zimbabwe" },
    { "country_id": "246", "sortname": "SA", "country_name": "Saudi Arabia" },
]

export const Country_NameAr = [
    {
        "code": "SA",
        "name": "المملكة العربية السعودية",
        "dialCode": "+966"
    },
    {
        "code": "AD",
        "name": "أندورا",
        "dialCode": "+376"
    },
    {
        "code": "AE",
        "name": "الامارات العربية المتحدة",
        "dialCode": "+971"
    },
    {
        "code": "AF",
        "name": "أفغانستان",
        "dialCode": "+93"
    },
    {
        "code": "AG",
        "name": "أنتيجوا وبربودا",
        "dialCode": "+1"
    },
    {
        "code": "AI",
        "name": "أنجويلا",
        "dialCode": "+1"
    },
    {
        "code": "AL",
        "name": "ألبانيا",
        "dialCode": "+355"
    },
    {
        "code": "AM",
        "name": "أرمينيا",
        "dialCode": "+374"
    },
    {
        "code": "AO",
        "name": "أنجولا",
        "dialCode": "+244"
    },
    {
        "code": "AQ",
        "name": "القطب الجنوبي",
        "dialCode": "+672"
    },
    {
        "code": "AR",
        "name": "الأرجنتين",
        "dialCode": "+54"
    },
    {
        "code": "AS",
        "name": "ساموا الأمريكية",
        "dialCode": "+1"
    },
    {
        "code": "AT",
        "name": "النمسا",
        "dialCode": "+43"
    },
    {
        "code": "AU",
        "name": "أستراليا",
        "dialCode": "+61"
    },
    {
        "code": "AW",
        "name": "آروبا",
        "dialCode": "+297"
    },
    {
        "code": "AX",
        "name": "جزر أولان",
        "dialCode": "+358"
    },
    {
        "code": "AZ",
        "name": "أذربيجان",
        "dialCode": "+994"
    },
    {
        "code": "BA",
        "name": "البوسنة والهرسك",
        "dialCode": "+387"
    },
    {
        "code": "BB",
        "name": "بربادوس",
        "dialCode": "+1"
    },
    {
        "code": "BD",
        "name": "بنجلاديش",
        "dialCode": "+880"
    },
    {
        "code": "BE",
        "name": "بلجيكا",
        "dialCode": "+32"
    },
    {
        "code": "BF",
        "name": "بوركينا فاسو",
        "dialCode": "+226"
    },
    {
        "code": "BG",
        "name": "بلغاريا",
        "dialCode": "+359"
    },
    {
        "code": "BH",
        "name": "البحرين",
        "dialCode": "+973"
    },
    {
        "code": "BI",
        "name": "بوروندي",
        "dialCode": "+257"
    },
    {
        "code": "BJ",
        "name": "بنين",
        "dialCode": "+229"
    },
    {
        "code": "BL",
        "name": "سان بارتيلمي",
        "dialCode": "+590"
    },
    {
        "code": "BM",
        "name": "برمودا",
        "dialCode": "+1"
    },
    {
        "code": "BN",
        "name": "بروناي",
        "dialCode": "+673"
    },
    {
        "code": "BO",
        "name": "بوليفيا",
        "dialCode": "+591"
    },
    {
        "code": "BQ",
        "name": "بونير",
        "dialCode": "+599"
    },
    {
        "code": "BR",
        "name": "البرازيل",
        "dialCode": "+55"
    },
    {
        "code": "BS",
        "name": "الباهاما",
        "dialCode": "+1"
    },
    {
        "code": "BT",
        "name": "بوتان",
        "dialCode": "+975"
    },
    {
        "code": "BV",
        "name": "جزيرة بوفيه",
        "dialCode": "+47"
    },
    {
        "code": "BW",
        "name": "بتسوانا",
        "dialCode": "+267"
    },
    {
        "code": "BY",
        "name": "روسيا البيضاء",
        "dialCode": "+375"
    },
    {
        "code": "BZ",
        "name": "بليز",
        "dialCode": "+501"
    },
    {
        "code": "CA",
        "name": "كندا",
        "dialCode": "+1"
    },
    {
        "code": "CC",
        "name": "جزر كوكوس",
        "dialCode": "+61"
    },
    {
        "code": "CD",
        "name": "جمهورية الكونغو الديمقراطية",
        "dialCode": "+243"
    },
    {
        "code": "CF",
        "name": "جمهورية افريقيا الوسطى",
        "dialCode": "+236"
    },
    {
        "code": "CG",
        "name": "الكونغو - برازافيل",
        "dialCode": "+242"
    },
    {
        "code": "CH",
        "name": "سويسرا",
        "dialCode": "+41"
    },
    {
        "code": "CI",
        "name": "ساحل العاج",
        "dialCode": "+225"
    },
    {
        "code": "CK",
        "name": "جزر كوك",
        "dialCode": "+682"
    },
    {
        "code": "CL",
        "name": "شيلي",
        "dialCode": "+56"
    },
    {
        "code": "CM",
        "name": "الكاميرون",
        "dialCode": "+237"
    },
    {
        "code": "CN",
        "name": "الصين",
        "dialCode": "+86"
    },
    {
        "code": "CO",
        "name": "كولومبيا",
        "dialCode": "+57"
    },
    {
        "code": "CR",
        "name": "كوستاريكا",
        "dialCode": "+506"
    },
    {
        "code": "CU",
        "name": "كوبا",
        "dialCode": "+53"
    },
    {
        "code": "CV",
        "name": "الرأس الأخضر",
        "dialCode": "+238"
    },
    {
        "code": "CW",
        "name": "كوراساو",
        "dialCode": "+599"
    },
    {
        "code": "CX",
        "name": "جزيرة الكريسماس",
        "dialCode": "+61"
    },
    {
        "code": "CY",
        "name": "قبرص",
        "dialCode": "+357"
    },
    {
        "code": "CZ",
        "name": "جمهورية التشيك",
        "dialCode": "+420"
    },
    {
        "code": "DE",
        "name": "ألمانيا",
        "dialCode": "+49"
    },
    {
        "code": "DJ",
        "name": "جيبوتي",
        "dialCode": "+253"
    },
    {
        "code": "DK",
        "name": "الدانمرك",
        "dialCode": "+45"
    },
    {
        "code": "DM",
        "name": "دومينيكا",
        "dialCode": "+1"
    },
    {
        "code": "DO",
        "name": "جمهورية الدومينيك",
        "dialCode": "+1"
    },
    {
        "code": "DZ",
        "name": "الجزائر",
        "dialCode": "+213"
    },
    {
        "code": "EC",
        "name": "الاكوادور",
        "dialCode": "+593"
    },
    {
        "code": "EE",
        "name": "استونيا",
        "dialCode": "+372"
    },
    {
        "code": "EG",
        "name": "مصر",
        "dialCode": "+20"
    },
    {
        "code": "EH",
        "name": "الصحراء الغربية",
        "dialCode": "+212"
    },
    {
        "code": "ER",
        "name": "اريتريا",
        "dialCode": "+291"
    },
    {
        "code": "ES",
        "name": "أسبانيا",
        "dialCode": "+34"
    },
    {
        "code": "ET",
        "name": "اثيوبيا",
        "dialCode": "+251"
    },
    {
        "code": "FI",
        "name": "فنلندا",
        "dialCode": "+358"
    },
    {
        "code": "FJ",
        "name": "فيجي",
        "dialCode": "+679"
    },
    {
        "code": "FK",
        "name": "جزر فوكلاند",
        "dialCode": "+500"
    },
    {
        "code": "FM",
        "name": "ميكرونيزيا",
        "dialCode": "+691"
    },
    {
        "code": "FO",
        "name": "جزر فارو",
        "dialCode": "+298"
    },
    {
        "code": "FR",
        "name": "فرنسا",
        "dialCode": "+33"
    },
    {
        "code": "GA",
        "name": "الجابون",
        "dialCode": "+241"
    },
    {
        "code": "GB",
        "name": "المملكة المتحدة",
        "dialCode": "+44"
    },
    {
        "code": "GD",
        "name": "جرينادا",
        "dialCode": "+1"
    },
    {
        "code": "GE",
        "name": "جورجيا",
        "dialCode": "+995"
    },
    {
        "code": "GF",
        "name": "غويانا",
        "dialCode": "+594"
    },
    {
        "code": "GG",
        "name": "غيرنزي",
        "dialCode": "+44"
    },
    {
        "code": "GH",
        "name": "غانا",
        "dialCode": "+233"
    },
    {
        "code": "GI",
        "name": "جبل طارق",
        "dialCode": "+350"
    },
    {
        "code": "GL",
        "name": "جرينلاند",
        "dialCode": "+299"
    },
    {
        "code": "GM",
        "name": "غامبيا",
        "dialCode": "+220"
    },
    {
        "code": "GN",
        "name": "غينيا",
        "dialCode": "+224"
    },
    {
        "code": "GP",
        "name": "جوادلوب",
        "dialCode": "+590"
    },
    {
        "code": "GQ",
        "name": "غينيا الاستوائية",
        "dialCode": "+240"
    },
    {
        "code": "GR",
        "name": "اليونان",
        "dialCode": "+30"
    },
    {
        "code": "GS",
        "name": "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
        "dialCode": "+500"
    },
    {
        "code": "GT",
        "name": "جواتيمالا",
        "dialCode": "+502"
    },
    {
        "code": "GU",
        "name": "جوام",
        "dialCode": "+1"
    },
    {
        "code": "GW",
        "name": "غينيا بيساو",
        "dialCode": "+245"
    },
    {
        "code": "GY",
        "name": "غيانا",
        "dialCode": "+595"
    },
    {
        "code": "HK",
        "name": "هونج كونج الصينية",
        "dialCode": "+852"
    },
    {
        "code": "HM",
        "name": "جزيرة هيرد وماكدونالد",
        "dialCode": ""
    },
    {
        "code": "HN",
        "name": "هندوراس",
        "dialCode": "+504"
    },
    {
        "code": "HR",
        "name": "كرواتيا",
        "dialCode": "+385"
    },
    {
        "code": "HT",
        "name": "هايتي",
        "dialCode": "+509"
    },
    {
        "code": "HU",
        "name": "المجر",
        "dialCode": "+36"
    },
    {
        "code": "ID",
        "name": "اندونيسيا",
        "dialCode": "+62"
    },
    {
        "code": "IE",
        "name": "أيرلندا",
        "dialCode": "+353"
    },
    {
        "code": "IL",
        "name": "اسرائيل",
        "dialCode": "+972"
    },
    {
        "code": "IM",
        "name": "جزيرة مان",
        "dialCode": "+44"
    },
    {
        "code": "IN",
        "name": "الهند",
        "dialCode": "+91"
    },
    {
        "code": "IO",
        "name": "المحيط الهندي البريطاني",
        "dialCode": "+246"
    },
    {
        "code": "IQ",
        "name": "العراق",
        "dialCode": "+964"
    },
    {
        "code": "IR",
        "name": "ايران",
        "dialCode": "+98"
    },
    {
        "code": "IS",
        "name": "أيسلندا",
        "dialCode": "+354"
    },
    {
        "code": "IT",
        "name": "ايطاليا",
        "dialCode": "+39"
    },
    {
        "code": "JE",
        "name": "جيرسي",
        "dialCode": "+44"
    },
    {
        "code": "JM",
        "name": "جامايكا",
        "dialCode": "+1"
    },
    {
        "code": "JO",
        "name": "الأردن",
        "dialCode": "+962"
    },
    {
        "code": "JP",
        "name": "اليابان",
        "dialCode": "+81"
    },
    {
        "code": "KE",
        "name": "كينيا",
        "dialCode": "+254"
    },
    {
        "code": "KG",
        "name": "قرغيزستان",
        "dialCode": "+996"
    },
    {
        "code": "KH",
        "name": "كمبوديا",
        "dialCode": "+855"
    },
    {
        "code": "KI",
        "name": "كيريباتي",
        "dialCode": "+686"
    },
    {
        "code": "KM",
        "name": "جزر القمر",
        "dialCode": "+269"
    },
    {
        "code": "KN",
        "name": "سانت كيتس ونيفيس",
        "dialCode": "+1"
    },
    {
        "code": "KP",
        "name": "كوريا الشمالية",
        "dialCode": "+850"
    },
    {
        "code": "KR",
        "name": "كوريا الجنوبية",
        "dialCode": "+82"
    },
    {
        "code": "KW",
        "name": "الكويت",
        "dialCode": "+965"
    },
    {
        "code": "KY",
        "name": "جزر الكايمن",
        "dialCode": "+345"
    },
    {
        "code": "KZ",
        "name": "كازاخستان",
        "dialCode": "+7"
    },
    {
        "code": "LA",
        "name": "لاوس",
        "dialCode": "+856"
    },
    {
        "code": "LB",
        "name": "لبنان",
        "dialCode": "+961"
    },
    {
        "code": "LC",
        "name": "سانت لوسيا",
        "dialCode": "+1"
    },
    {
        "code": "LI",
        "name": "ليختنشتاين",
        "dialCode": "+423"
    },
    {
        "code": "LK",
        "name": "سريلانكا",
        "dialCode": "+94"
    },
    {
        "code": "LR",
        "name": "ليبيريا",
        "dialCode": "+231"
    },
    {
        "code": "LS",
        "name": "ليسوتو",
        "dialCode": "+266"
    },
    {
        "code": "LT",
        "name": "ليتوانيا",
        "dialCode": "+370"
    },
    {
        "code": "LU",
        "name": "لوكسمبورج",
        "dialCode": "+352"
    },
    {
        "code": "LV",
        "name": "لاتفيا",
        "dialCode": "+371"
    },
    {
        "code": "LY",
        "name": "ليبيا",
        "dialCode": "+218"
    },
    {
        "code": "MA",
        "name": "المغرب",
        "dialCode": "+212"
    },
    {
        "code": "MC",
        "name": "موناكو",
        "dialCode": "+377"
    },
    {
        "code": "MD",
        "name": "مولدافيا",
        "dialCode": "+373"
    },
    {
        "code": "ME",
        "name": "الجبل الأسود",
        "dialCode": "+382"
    },
    {
        "code": "MF",
        "name": "سانت مارتين",
        "dialCode": "+590"
    },
    {
        "code": "MG",
        "name": "مدغشقر",
        "dialCode": "+261"
    },
    {
        "code": "MH",
        "name": "جزر المارشال",
        "dialCode": "+692"
    },
    {
        "code": "MK",
        "name": "مقدونيا",
        "dialCode": "+389"
    },
    {
        "code": "ML",
        "name": "مالي",
        "dialCode": "+223"
    },
    {
        "code": "MM",
        "name": "ميانمار",
        "dialCode": "+95"
    },
    {
        "code": "MN",
        "name": "منغوليا",
        "dialCode": "+976"
    },
    {
        "code": "MO",
        "name": "ماكاو الصينية",
        "dialCode": "+853"
    },
    {
        "code": "MP",
        "name": "جزر ماريانا الشمالية",
        "dialCode": "+1"
    },
    {
        "code": "MQ",
        "name": "مارتينيك",
        "dialCode": "+596"
    },
    {
        "code": "MR",
        "name": "موريتانيا",
        "dialCode": "+222"
    },
    {
        "code": "MS",
        "name": "مونتسرات",
        "dialCode": "+1"
    },
    {
        "code": "MT",
        "name": "مالطا",
        "dialCode": "+356"
    },
    {
        "code": "MU",
        "name": "موريشيوس",
        "dialCode": "+230"
    },
    {
        "code": "MV",
        "name": "جزر الملديف",
        "dialCode": "+960"
    },
    {
        "code": "MW",
        "name": "ملاوي",
        "dialCode": "+265"
    },
    {
        "code": "MX",
        "name": "المكسيك",
        "dialCode": "+52"
    },
    {
        "code": "MY",
        "name": "ماليزيا",
        "dialCode": "+60"
    },
    {
        "code": "MZ",
        "name": "موزمبيق",
        "dialCode": "+258"
    },
    {
        "code": "NA",
        "name": "ناميبيا",
        "dialCode": "+264"
    },
    {
        "code": "NC",
        "name": "كاليدونيا الجديدة",
        "dialCode": "+687"
    },
    {
        "code": "NE",
        "name": "النيجر",
        "dialCode": "+227"
    },
    {
        "code": "NF",
        "name": "جزيرة نورفوك",
        "dialCode": "+672"
    },
    {
        "code": "NG",
        "name": "نيجيريا",
        "dialCode": "+234"
    },
    {
        "code": "NI",
        "name": "نيكاراجوا",
        "dialCode": "+505"
    },
    {
        "code": "NL",
        "name": "هولندا",
        "dialCode": "+31"
    },
    {
        "code": "NO",
        "name": "النرويج",
        "dialCode": "+47"
    },
    {
        "code": "NP",
        "name": "نيبال",
        "dialCode": "+977"
    },
    {
        "code": "NR",
        "name": "نورو",
        "dialCode": "+674"
    },
    {
        "code": "NU",
        "name": "نيوي",
        "dialCode": "+683"
    },
    {
        "code": "NZ",
        "name": "نيوزيلاندا",
        "dialCode": "+64"
    },
    {
        "code": "OM",
        "name": "عمان",
        "dialCode": "+968"
    },
    {
        "code": "PA",
        "name": "بنما",
        "dialCode": "+507"
    },
    {
        "code": "PE",
        "name": "بيرو",
        "dialCode": "+51"
    },
    {
        "code": "PF",
        "name": "بولينيزيا الفرنسية",
        "dialCode": "+689"
    },
    {
        "code": "PG",
        "name": "بابوا غينيا الجديدة",
        "dialCode": "+675"
    },
    {
        "code": "PH",
        "name": "الفيلبين",
        "dialCode": "+63"
    },
    {
        "code": "PK",
        "name": "باكستان",
        "dialCode": "+92"
    },
    {
        "code": "PL",
        "name": "بولندا",
        "dialCode": "+48"
    },
    {
        "code": "PM",
        "name": "سانت بيير وميكولون",
        "dialCode": "+508"
    },
    {
        "code": "PN",
        "name": "بتكايرن",
        "dialCode": "+872"
    },
    {
        "code": "PR",
        "name": "بورتوريكو",
        "dialCode": "+1"
    },
    {
        "code": "PS",
        "name": "فلسطين",
        "dialCode": "+970"
    },
    {
        "code": "PT",
        "name": "البرتغال",
        "dialCode": "+351"
    },
    {
        "code": "PW",
        "name": "بالاو",
        "dialCode": "+680"
    },
    {
        "code": "PY",
        "name": "باراجواي",
        "dialCode": "+595"
    },
    {
        "code": "QA",
        "name": "قطر",
        "dialCode": "+974"
    },
    {
        "code": "RE",
        "name": "روينيون",
        "dialCode": "+262"
    },
    {
        "code": "RO",
        "name": "رومانيا",
        "dialCode": "+40"
    },
    {
        "code": "RS",
        "name": "صربيا",
        "dialCode": "+381"
    },
    {
        "code": "RU",
        "name": "روسيا",
        "dialCode": "+7"
    },
    {
        "code": "RW",
        "name": "رواندا",
        "dialCode": "+250"
    },

    {
        "code": "SB",
        "name": "جزر سليمان",
        "dialCode": "+677"
    },
    {
        "code": "SC",
        "name": "سيشل",
        "dialCode": "+248"
    },
    {
        "code": "SD",
        "name": "السودان",
        "dialCode": "+249"
    },
    {
        "code": "SE",
        "name": "السويد",
        "dialCode": "+46"
    },
    {
        "code": "SG",
        "name": "سنغافورة",
        "dialCode": "+65"
    },
    {
        "code": "SH",
        "name": "سانت هيلنا",
        "dialCode": "+290"
    },
    {
        "code": "SI",
        "name": "سلوفينيا",
        "dialCode": "+386"
    },
    {
        "code": "SJ",
        "name": "سفالبارد وجان مايان",
        "dialCode": "+47"
    },
    {
        "code": "SK",
        "name": "سلوفاكيا",
        "dialCode": "+421"
    },
    {
        "code": "SL",
        "name": "سيراليون",
        "dialCode": "+232"
    },
    {
        "code": "SM",
        "name": "سان مارينو",
        "dialCode": "+378"
    },
    {
        "code": "SN",
        "name": "السنغال",
        "dialCode": "+221"
    },
    {
        "code": "SO",
        "name": "الصومال",
        "dialCode": "+252"
    },
    {
        "code": "SR",
        "name": "سورينام",
        "dialCode": "+597"
    },
    {
        "code": "SS",
        "name": "جنوب السودان",
        "dialCode": "+211"
    },
    {
        "code": "ST",
        "name": "ساو تومي وبرينسيبي",
        "dialCode": "+239"
    },
    {
        "code": "SV",
        "name": "السلفادور",
        "dialCode": "+503"
    },
    {
        "code": "SX",
        "name": "سينت مارتن",
        "dialCode": "+1"
    },
    {
        "code": "SY",
        "name": "سوريا",
        "dialCode": "+963"
    },
    {
        "code": "SZ",
        "name": "سوازيلاند",
        "dialCode": "+268"
    },
    {
        "code": "TC",
        "name": "جزر الترك وجايكوس",
        "dialCode": "+1"
    },
    {
        "code": "TD",
        "name": "تشاد",
        "dialCode": "+235"
    },
    {
        "code": "TF",
        "name": "المقاطعات الجنوبية الفرنسية",
        "dialCode": "+262"
    },
    {
        "code": "TG",
        "name": "توجو",
        "dialCode": "+228"
    },
    {
        "code": "TH",
        "name": "تايلند",
        "dialCode": "+66"
    },
    {
        "code": "TJ",
        "name": "طاجكستان",
        "dialCode": "+992"
    },
    {
        "code": "TK",
        "name": "توكيلو",
        "dialCode": "+690"
    },
    {
        "code": "TL",
        "name": "تيمور الشرقية",
        "dialCode": "+670"
    },
    {
        "code": "TM",
        "name": "تركمانستان",
        "dialCode": "+993"
    },
    {
        "code": "TN",
        "name": "تونس",
        "dialCode": "+216"
    },
    {
        "code": "TO",
        "name": "تونجا",
        "dialCode": "+676"
    },
    {
        "code": "TR",
        "name": "تركيا",
        "dialCode": "+90"
    },
    {
        "code": "TT",
        "name": "ترينيداد وتوباغو",
        "dialCode": "+1"
    },
    {
        "code": "TV",
        "name": "توفالو",
        "dialCode": "+688"
    },
    {
        "code": "TW",
        "name": "تايوان",
        "dialCode": "+886"
    },
    {
        "code": "TZ",
        "name": "تانزانيا",
        "dialCode": "+255"
    },
    {
        "code": "UA",
        "name": "أوكرانيا",
        "dialCode": "+380"
    },
    {
        "code": "UG",
        "name": "أوغندا",
        "dialCode": "+256"
    },
    {
        "code": "UM",
        "name": "جزر الولايات المتحدة البعيدة الصغيرة",
        "dialCode": ""
    },
    {
        "code": "US",
        "name": "الولايات المتحدة الأمريكية",
        "dialCode": "+1"
    },
    {
        "code": "UY",
        "name": "أورجواي",
        "dialCode": "+598"
    },
    {
        "code": "UZ",
        "name": "أوزبكستان",
        "dialCode": "+998"
    },
    {
        "code": "VA",
        "name": "الفاتيكان",
        "dialCode": "+379"
    },
    {
        "code": "VC",
        "name": "سانت فنسنت وغرنادين",
        "dialCode": "+1"
    },
    {
        "code": "VE",
        "name": "فنزويلا",
        "dialCode": "+58"
    },
    {
        "code": "VG",
        "name": "جزر فرجين البريطانية",
        "dialCode": "+1"
    },
    {
        "code": "VI",
        "name": "جزر فرجين الأمريكية",
        "dialCode": "+1"
    },
    {
        "code": "VN",
        "name": "فيتنام",
        "dialCode": "+84"
    },
    {
        "code": "VU",
        "name": "فانواتو",
        "dialCode": "+678"
    },
    {
        "code": "WF",
        "name": "جزر والس وفوتونا",
        "dialCode": "+681"
    },
    {
        "code": "WS",
        "name": "ساموا",
        "dialCode": "+685"
    },
    {
        "code": "XK",
        "name": "كوسوفو",
        "dialCode": "+383"
    },
    {
        "code": "YE",
        "name": "اليمن",
        "dialCode": "+967"
    },
    {
        "code": "YT",
        "name": "مايوت",
        "dialCode": "+262"
    },
    {
        "code": "ZA",
        "name": "جمهورية جنوب افريقيا",
        "dialCode": "+27"
    },
    {
        "code": "ZM",
        "name": "زامبيا",
        "dialCode": "+260"
    },
    {
        "code": "ZW",
        "name": "زيمبابوي",
        "dialCode": "+263"
    },
    {
        "code": "SA",
        "name": "المملكة العربية السعودية",
        "dialCode": "+966"
    },
]


export const New_Country = [
    {
      name: "Ascension Island",
      code: "AC",
      emoji: "🇦🇨",
      unicode: "U+1F1E6 U+1F1E8",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AC.svg",
    },
    {
      name: "Andorra",
      code: "AD",
      emoji: "🇦🇩",
      unicode: "U+1F1E6 U+1F1E9",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AD.svg",
    },
    {
      name: "United Arab Emirates",
      code: "AE",
      emoji: "🇦🇪",
      unicode: "U+1F1E6 U+1F1EA",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AE.svg",
    },
    {
      name: "Afghanistan",
      code: "AF",
      emoji: "🇦🇫",
      unicode: "U+1F1E6 U+1F1EB",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AF.svg",
    },
    {
      name: "Antigua & Barbuda",
      code: "AG",
      emoji: "🇦🇬",
      unicode: "U+1F1E6 U+1F1EC",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AG.svg",
    },
    {
      name: "Anguilla",
      code: "AI",
      emoji: "🇦🇮",
      unicode: "U+1F1E6 U+1F1EE",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AI.svg",
    },
    {
      name: "Albania",
      code: "AL",
      emoji: "🇦🇱",
      unicode: "U+1F1E6 U+1F1F1",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AL.svg",
    },
    {
      name: "Armenia",
      code: "AM",
      emoji: "🇦🇲",
      unicode: "U+1F1E6 U+1F1F2",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AM.svg",
    },
    {
      name: "Angola",
      code: "AO",
      emoji: "🇦🇴",
      unicode: "U+1F1E6 U+1F1F4",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AO.svg",
    },
    {
      name: "Antarctica",
      code: "AQ",
      emoji: "🇦🇶",
      unicode: "U+1F1E6 U+1F1F6",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AQ.svg",
    },
    {
      name: "Argentina",
      code: "AR",
      emoji: "🇦🇷",
      unicode: "U+1F1E6 U+1F1F7",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AR.svg",
    },
    {
      name: "American Samoa",
      code: "AS",
      emoji: "🇦🇸",
      unicode: "U+1F1E6 U+1F1F8",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AS.svg",
    },
    {
      name: "Austria",
      code: "AT",
      emoji: "🇦🇹",
      unicode: "U+1F1E6 U+1F1F9",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AT.svg",
    },
    {
      name: "Australia",
      code: "AU",
      emoji: "🇦🇺",
      unicode: "U+1F1E6 U+1F1FA",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AU.svg",
    },
    {
      name: "Aruba",
      code: "AW",
      emoji: "🇦🇼",
      unicode: "U+1F1E6 U+1F1FC",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AW.svg",
    },
    {
      name: "Åland Islands",
      code: "AX",
      emoji: "🇦🇽",
      unicode: "U+1F1E6 U+1F1FD",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AX.svg",
    },
    {
      name: "Azerbaijan",
      code: "AZ",
      emoji: "🇦🇿",
      unicode: "U+1F1E6 U+1F1FF",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AZ.svg",
    },
    {
      name: "Bosnia & Herzegovina",
      code: "BA",
      emoji: "🇧🇦",
      unicode: "U+1F1E7 U+1F1E6",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BA.svg",
    },
    {
      name: "Barbados",
      code: "BB",
      emoji: "🇧🇧",
      unicode: "U+1F1E7 U+1F1E7",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BB.svg",
    },
    {
      name: "Bangladesh",
      code: "BD",
      emoji: "🇧🇩",
      unicode: "U+1F1E7 U+1F1E9",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BD.svg",
    },
    {
      name: "Belgium",
      code: "BE",
      emoji: "🇧🇪",
      unicode: "U+1F1E7 U+1F1EA",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BE.svg",
    },
    {
      name: "Burkina Faso",
      code: "BF",
      emoji: "🇧🇫",
      unicode: "U+1F1E7 U+1F1EB",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BF.svg",
    },
    {
      name: "Bulgaria",
      code: "BG",
      emoji: "🇧🇬",
      unicode: "U+1F1E7 U+1F1EC",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BG.svg",
    },
    {
      name: "Bahrain",
      code: "BH",
      emoji: "🇧🇭",
      unicode: "U+1F1E7 U+1F1ED",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg",
    },
    {
      name: "Burundi",
      code: "BI",
      emoji: "🇧🇮",
      unicode: "U+1F1E7 U+1F1EE",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BI.svg",
    },
    {
      name: "Benin",
      code: "BJ",
      emoji: "🇧🇯",
      unicode: "U+1F1E7 U+1F1EF",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BJ.svg",
    },
    {
      name: "St. Barthélemy",
      code: "BL",
      emoji: "🇧🇱",
      unicode: "U+1F1E7 U+1F1F1",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BL.svg",
    },
    {
      name: "Bermuda",
      code: "BM",
      emoji: "🇧🇲",
      unicode: "U+1F1E7 U+1F1F2",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BM.svg",
    },
    {
      name: "Brunei",
      code: "BN",
      emoji: "🇧🇳",
      unicode: "U+1F1E7 U+1F1F3",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BN.svg",
    },
    {
      name: "Bolivia",
      code: "BO",
      emoji: "🇧🇴",
      unicode: "U+1F1E7 U+1F1F4",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BO.svg",
    },
    {
      name: "Caribbean Netherlands",
      code: "BQ",
      emoji: "🇧🇶",
      unicode: "U+1F1E7 U+1F1F6",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BQ.svg",
    },
    {
      name: "Brazil",
      code: "BR",
      emoji: "🇧🇷",
      unicode: "U+1F1E7 U+1F1F7",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BR.svg",
    },
    {
      name: "Bahamas",
      code: "BS",
      emoji: "🇧🇸",
      unicode: "U+1F1E7 U+1F1F8",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BS.svg",
    },
    {
      name: "Bhutan",
      code: "BT",
      emoji: "🇧🇹",
      unicode: "U+1F1E7 U+1F1F9",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BT.svg",
    },
    {
      name: "Bouvet Island",
      code: "BV",
      emoji: "🇧🇻",
      unicode: "U+1F1E7 U+1F1FB",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BV.svg",
    },
    {
      name: "Botswana",
      code: "BW",
      emoji: "🇧🇼",
      unicode: "U+1F1E7 U+1F1FC",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BW.svg",
    },
    {
      name: "Belarus",
      code: "BY",
      emoji: "🇧🇾",
      unicode: "U+1F1E7 U+1F1FE",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BY.svg",
    },
    {
      name: "Belize",
      code: "BZ",
      emoji: "🇧🇿",
      unicode: "U+1F1E7 U+1F1FF",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BZ.svg",
    },
    {
      name: "Canada",
      code: "CA",
      emoji: "🇨🇦",
      unicode: "U+1F1E8 U+1F1E6",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CA.svg",
    },
    {
      name: "Cocos (Keeling) Islands",
      code: "CC",
      emoji: "🇨🇨",
      unicode: "U+1F1E8 U+1F1E8",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CC.svg",
    },
    {
      name: "Congo - Kinshasa",
      code: "CD",
      emoji: "🇨🇩",
      unicode: "U+1F1E8 U+1F1E9",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CD.svg",
    },
    {
      name: "Central African Republic",
      code: "CF",
      emoji: "🇨🇫",
      unicode: "U+1F1E8 U+1F1EB",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CF.svg",
    },
    {
      name: "Congo - Brazzaville",
      code: "CG",
      emoji: "🇨🇬",
      unicode: "U+1F1E8 U+1F1EC",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CG.svg",
    },
    {
      name: "Switzerland",
      code: "CH",
      emoji: "🇨🇭",
      unicode: "U+1F1E8 U+1F1ED",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CH.svg",
    },
    {
      name: "Côte d’Ivoire",
      code: "CI",
      emoji: "🇨🇮",
      unicode: "U+1F1E8 U+1F1EE",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CI.svg",
    },
    {
      name: "Cook Islands",
      code: "CK",
      emoji: "🇨🇰",
      unicode: "U+1F1E8 U+1F1F0",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CK.svg",
    },
    {
      name: "Chile",
      code: "CL",
      emoji: "🇨🇱",
      unicode: "U+1F1E8 U+1F1F1",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CL.svg",
    },
    {
      name: "Cameroon",
      code: "CM",
      emoji: "🇨🇲",
      unicode: "U+1F1E8 U+1F1F2",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CM.svg",
    },
    {
      name: "China",
      code: "CN",
      emoji: "🇨🇳",
      unicode: "U+1F1E8 U+1F1F3",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CN.svg",
    },
    {
      name: "Colombia",
      code: "CO",
      emoji: "🇨🇴",
      unicode: "U+1F1E8 U+1F1F4",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CO.svg",
    },
    {
      name: "Clipperton Island",
      code: "CP",
      emoji: "🇨🇵",
      unicode: "U+1F1E8 U+1F1F5",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CP.svg",
    },
    {
      name: "Costa Rica",
      code: "CR",
      emoji: "🇨🇷",
      unicode: "U+1F1E8 U+1F1F7",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CR.svg",
    },
    {
      name: "Cuba",
      code: "CU",
      emoji: "🇨🇺",
      unicode: "U+1F1E8 U+1F1FA",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CU.svg",
    },
    {
      name: "Cape Verde",
      code: "CV",
      emoji: "🇨🇻",
      unicode: "U+1F1E8 U+1F1FB",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CV.svg",
    },
    {
      name: "Curaçao",
      code: "CW",
      emoji: "🇨🇼",
      unicode: "U+1F1E8 U+1F1FC",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CW.svg",
    },
    {
      name: "Christmas Island",
      code: "CX",
      emoji: "🇨🇽",
      unicode: "U+1F1E8 U+1F1FD",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CX.svg",
    },
    {
      name: "Cyprus",
      code: "CY",
      emoji: "🇨🇾",
      unicode: "U+1F1E8 U+1F1FE",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CY.svg",
    },
    {
      name: "Czechia",
      code: "CZ",
      emoji: "🇨🇿",
      unicode: "U+1F1E8 U+1F1FF",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CZ.svg",
    },
    {
      name: "Germany",
      code: "DE",
      emoji: "🇩🇪",
      unicode: "U+1F1E9 U+1F1EA",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DE.svg",
    },
    {
      name: "Diego Garcia",
      code: "DG",
      emoji: "🇩🇬",
      unicode: "U+1F1E9 U+1F1EC",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DG.svg",
    },
    {
      name: "Djibouti",
      code: "DJ",
      emoji: "🇩🇯",
      unicode: "U+1F1E9 U+1F1EF",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DJ.svg",
    },
    {
      name: "Denmark",
      code: "DK",
      emoji: "🇩🇰",
      unicode: "U+1F1E9 U+1F1F0",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DK.svg",
    },
    {
      name: "Dominica",
      code: "DM",
      emoji: "🇩🇲",
      unicode: "U+1F1E9 U+1F1F2",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DM.svg",
    },
    {
      name: "Dominican Republic",
      code: "DO",
      emoji: "🇩🇴",
      unicode: "U+1F1E9 U+1F1F4",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DO.svg",
    },
    {
      name: "Algeria",
      code: "DZ",
      emoji: "🇩🇿",
      unicode: "U+1F1E9 U+1F1FF",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DZ.svg",
    },
    {
      name: "Ceuta & Melilla",
      code: "EA",
      emoji: "🇪🇦",
      unicode: "U+1F1EA U+1F1E6",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EA.svg",
    },
    {
      name: "Ecuador",
      code: "EC",
      emoji: "🇪🇨",
      unicode: "U+1F1EA U+1F1E8",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EC.svg",
    },
    {
      name: "Estonia",
      code: "EE",
      emoji: "🇪🇪",
      unicode: "U+1F1EA U+1F1EA",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EE.svg",
    },
    {
      name: "Egypt",
      code: "EG",
      emoji: "🇪🇬",
      unicode: "U+1F1EA U+1F1EC",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EG.svg",
    },
    {
      name: "Western Sahara",
      code: "EH",
      emoji: "🇪🇭",
      unicode: "U+1F1EA U+1F1ED",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EH.svg",
    },
    {
      name: "Eritrea",
      code: "ER",
      emoji: "🇪🇷",
      unicode: "U+1F1EA U+1F1F7",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ER.svg",
    },
    {
      name: "Spain",
      code: "ES",
      emoji: "🇪🇸",
      unicode: "U+1F1EA U+1F1F8",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ES.svg",
    },
    {
      name: "Ethiopia",
      code: "ET",
      emoji: "🇪🇹",
      unicode: "U+1F1EA U+1F1F9",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ET.svg",
    },
    {
      name: "European Union",
      code: "EU",
      emoji: "🇪🇺",
      unicode: "U+1F1EA U+1F1FA",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EU.svg",
    },
    {
      name: "Finland",
      code: "FI",
      emoji: "🇫🇮",
      unicode: "U+1F1EB U+1F1EE",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FI.svg",
    },
    {
      name: "Fiji",
      code: "FJ",
      emoji: "🇫🇯",
      unicode: "U+1F1EB U+1F1EF",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FJ.svg",
    },
    {
      name: "Falkland Islands",
      code: "FK",
      emoji: "🇫🇰",
      unicode: "U+1F1EB U+1F1F0",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FK.svg",
    },
    {
      name: "Micronesia",
      code: "FM",
      emoji: "🇫🇲",
      unicode: "U+1F1EB U+1F1F2",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FM.svg",
    },
    {
      name: "Faroe Islands",
      code: "FO",
      emoji: "🇫🇴",
      unicode: "U+1F1EB U+1F1F4",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FO.svg",
    },
    {
      name: "France",
      code: "FR",
      emoji: "🇫🇷",
      unicode: "U+1F1EB U+1F1F7",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FR.svg",
    },
    {
      name: "Gabon",
      code: "GA",
      emoji: "🇬🇦",
      unicode: "U+1F1EC U+1F1E6",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GA.svg",
    },
    {
      name: "United Kingdom",
      code: "GB",
      emoji: "🇬🇧",
      unicode: "U+1F1EC U+1F1E7",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg",
    },
    {
      name: "Grenada",
      code: "GD",
      emoji: "🇬🇩",
      unicode: "U+1F1EC U+1F1E9",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GD.svg",
    },
    {
      name: "Georgia",
      code: "GE",
      emoji: "🇬🇪",
      unicode: "U+1F1EC U+1F1EA",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GE.svg",
    },
    {
      name: "French Guiana",
      code: "GF",
      emoji: "🇬🇫",
      unicode: "U+1F1EC U+1F1EB",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GF.svg",
    },
    {
      name: "Guernsey",
      code: "GG",
      emoji: "🇬🇬",
      unicode: "U+1F1EC U+1F1EC",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GG.svg",
    },
    {
      name: "Ghana",
      code: "GH",
      emoji: "🇬🇭",
      unicode: "U+1F1EC U+1F1ED",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GH.svg",
    },
    {
      name: "Gibraltar",
      code: "GI",
      emoji: "🇬🇮",
      unicode: "U+1F1EC U+1F1EE",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GI.svg",
    },
    {
      name: "Greenland",
      code: "GL",
      emoji: "🇬🇱",
      unicode: "U+1F1EC U+1F1F1",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GL.svg",
    },
    {
      name: "Gambia",
      code: "GM",
      emoji: "🇬🇲",
      unicode: "U+1F1EC U+1F1F2",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GM.svg",
    },
    {
      name: "Guinea",
      code: "GN",
      emoji: "🇬🇳",
      unicode: "U+1F1EC U+1F1F3",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GN.svg",
    },
    {
      name: "Guadeloupe",
      code: "GP",
      emoji: "🇬🇵",
      unicode: "U+1F1EC U+1F1F5",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GP.svg",
    },
    {
      name: "Equatorial Guinea",
      code: "GQ",
      emoji: "🇬🇶",
      unicode: "U+1F1EC U+1F1F6",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GQ.svg",
    },
    {
      name: "Greece",
      code: "GR",
      emoji: "🇬🇷",
      unicode: "U+1F1EC U+1F1F7",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GR.svg",
    },
    {
      name: "South Georgia & South Sandwich Islands",
      code: "GS",
      emoji: "🇬🇸",
      unicode: "U+1F1EC U+1F1F8",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GS.svg",
    },
    {
      name: "Guatemala",
      code: "GT",
      emoji: "🇬🇹",
      unicode: "U+1F1EC U+1F1F9",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GT.svg",
    },
    {
      name: "Guam",
      code: "GU",
      emoji: "🇬🇺",
      unicode: "U+1F1EC U+1F1FA",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GU.svg",
    },
    {
      name: "Guinea-Bissau",
      code: "GW",
      emoji: "🇬🇼",
      unicode: "U+1F1EC U+1F1FC",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GW.svg",
    },
    {
      name: "Guyana",
      code: "GY",
      emoji: "🇬🇾",
      unicode: "U+1F1EC U+1F1FE",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GY.svg",
    },
    {
      name: "Hong Kong SAR China",
      code: "HK",
      emoji: "🇭🇰",
      unicode: "U+1F1ED U+1F1F0",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HK.svg",
    },
    {
      name: "Heard & McDonald Islands",
      code: "HM",
      emoji: "🇭🇲",
      unicode: "U+1F1ED U+1F1F2",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HM.svg",
    },
    {
      name: "Honduras",
      code: "HN",
      emoji: "🇭🇳",
      unicode: "U+1F1ED U+1F1F3",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HN.svg",
    },
    {
      name: "Croatia",
      code: "HR",
      emoji: "🇭🇷",
      unicode: "U+1F1ED U+1F1F7",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HR.svg",
    },
    {
      name: "Haiti",
      code: "HT",
      emoji: "🇭🇹",
      unicode: "U+1F1ED U+1F1F9",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HT.svg",
    },
    {
      name: "Hungary",
      code: "HU",
      emoji: "🇭🇺",
      unicode: "U+1F1ED U+1F1FA",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HU.svg",
    },
    {
      name: "Canary Islands",
      code: "IC",
      emoji: "🇮🇨",
      unicode: "U+1F1EE U+1F1E8",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IC.svg",
    },
    {
      name: "Indonesia",
      code: "ID",
      emoji: "🇮🇩",
      unicode: "U+1F1EE U+1F1E9",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ID.svg",
    },
    {
      name: "Ireland",
      code: "IE",
      emoji: "🇮🇪",
      unicode: "U+1F1EE U+1F1EA",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IE.svg",
    },
    {
      name: "Israel",
      code: "IL",
      emoji: "🇮🇱",
      unicode: "U+1F1EE U+1F1F1",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IL.svg",
    },
    {
      name: "Isle of Man",
      code: "IM",
      emoji: "🇮🇲",
      unicode: "U+1F1EE U+1F1F2",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IM.svg",
    },
    {
      name: "India",
      code: "IN",
      emoji: "🇮🇳",
      unicode: "U+1F1EE U+1F1F3",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IN.svg",
    },
    {
      name: "British Indian Ocean Territory",
      code: "IO",
      emoji: "🇮🇴",
      unicode: "U+1F1EE U+1F1F4",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IO.svg",
    },
    {
      name: "Iraq",
      code: "IQ",
      emoji: "🇮🇶",
      unicode: "U+1F1EE U+1F1F6",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IQ.svg",
    },
    {
      name: "Iran",
      code: "IR",
      emoji: "🇮🇷",
      unicode: "U+1F1EE U+1F1F7",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IR.svg",
    },
    {
      name: "Iceland",
      code: "IS",
      emoji: "🇮🇸",
      unicode: "U+1F1EE U+1F1F8",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IS.svg",
    },
    {
      name: "Italy",
      code: "IT",
      emoji: "🇮🇹",
      unicode: "U+1F1EE U+1F1F9",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IT.svg",
    },
    {
      name: "Jersey",
      code: "JE",
      emoji: "🇯🇪",
      unicode: "U+1F1EF U+1F1EA",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JE.svg",
    },
    {
      name: "Jamaica",
      code: "JM",
      emoji: "🇯🇲",
      unicode: "U+1F1EF U+1F1F2",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JM.svg",
    },
    {
      name: "Jordan",
      code: "JO",
      emoji: "🇯🇴",
      unicode: "U+1F1EF U+1F1F4",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JO.svg",
    },
    {
      name: "Japan",
      code: "JP",
      emoji: "🇯🇵",
      unicode: "U+1F1EF U+1F1F5",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JP.svg",
    },
    {
      name: "Kenya",
      code: "KE",
      emoji: "🇰🇪",
      unicode: "U+1F1F0 U+1F1EA",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KE.svg",
    },
    {
      name: "Kyrgyzstan",
      code: "KG",
      emoji: "🇰🇬",
      unicode: "U+1F1F0 U+1F1EC",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KG.svg",
    },
    {
      name: "Cambodia",
      code: "KH",
      emoji: "🇰🇭",
      unicode: "U+1F1F0 U+1F1ED",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KH.svg",
    },
    {
      name: "Kiribati",
      code: "KI",
      emoji: "🇰🇮",
      unicode: "U+1F1F0 U+1F1EE",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KI.svg",
    },
    {
      name: "Comoros",
      code: "KM",
      emoji: "🇰🇲",
      unicode: "U+1F1F0 U+1F1F2",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KM.svg",
    },
    {
      name: "St. Kitts & Nevis",
      code: "KN",
      emoji: "🇰🇳",
      unicode: "U+1F1F0 U+1F1F3",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KN.svg",
    },
    {
      name: "North Korea",
      code: "KP",
      emoji: "🇰🇵",
      unicode: "U+1F1F0 U+1F1F5",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KP.svg",
    },
    {
      name: "South Korea",
      code: "KR",
      emoji: "🇰🇷",
      unicode: "U+1F1F0 U+1F1F7",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KR.svg",
    },
    {
      name: "Kuwait",
      code: "KW",
      emoji: "🇰🇼",
      unicode: "U+1F1F0 U+1F1FC",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KW.svg",
    },
    {
      name: "Cayman Islands",
      code: "KY",
      emoji: "🇰🇾",
      unicode: "U+1F1F0 U+1F1FE",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KY.svg",
    },
    {
      name: "Kazakhstan",
      code: "KZ",
      emoji: "🇰🇿",
      unicode: "U+1F1F0 U+1F1FF",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KZ.svg",
    },
    {
      name: "Laos",
      code: "LA",
      emoji: "🇱🇦",
      unicode: "U+1F1F1 U+1F1E6",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LA.svg",
    },
    {
      name: "Lebanon",
      code: "LB",
      emoji: "🇱🇧",
      unicode: "U+1F1F1 U+1F1E7",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LB.svg",
    },
    {
      name: "St. Lucia",
      code: "LC",
      emoji: "🇱🇨",
      unicode: "U+1F1F1 U+1F1E8",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LC.svg",
    },
    {
      name: "Liechtenstein",
      code: "LI",
      emoji: "🇱🇮",
      unicode: "U+1F1F1 U+1F1EE",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LI.svg",
    },
    {
      name: "Sri Lanka",
      code: "LK",
      emoji: "🇱🇰",
      unicode: "U+1F1F1 U+1F1F0",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LK.svg",
    },
    {
      name: "Liberia",
      code: "LR",
      emoji: "🇱🇷",
      unicode: "U+1F1F1 U+1F1F7",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LR.svg",
    },
    {
      name: "Lesotho",
      code: "LS",
      emoji: "🇱🇸",
      unicode: "U+1F1F1 U+1F1F8",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LS.svg",
    },
    {
      name: "Lithuania",
      code: "LT",
      emoji: "🇱🇹",
      unicode: "U+1F1F1 U+1F1F9",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LT.svg",
    },
    {
      name: "Luxembourg",
      code: "LU",
      emoji: "🇱🇺",
      unicode: "U+1F1F1 U+1F1FA",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LU.svg",
    },
    {
      name: "Latvia",
      code: "LV",
      emoji: "🇱🇻",
      unicode: "U+1F1F1 U+1F1FB",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LV.svg",
    },
    {
      name: "Libya",
      code: "LY",
      emoji: "🇱🇾",
      unicode: "U+1F1F1 U+1F1FE",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LY.svg",
    },
    {
      name: "Morocco",
      code: "MA",
      emoji: "🇲🇦",
      unicode: "U+1F1F2 U+1F1E6",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MA.svg",
    },
    {
      name: "Monaco",
      code: "MC",
      emoji: "🇲🇨",
      unicode: "U+1F1F2 U+1F1E8",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MC.svg",
    },
    {
      name: "Moldova",
      code: "MD",
      emoji: "🇲🇩",
      unicode: "U+1F1F2 U+1F1E9",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MD.svg",
    },
    {
      name: "Montenegro",
      code: "ME",
      emoji: "🇲🇪",
      unicode: "U+1F1F2 U+1F1EA",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ME.svg",
    },
    {
      name: "St. Martin",
      code: "MF",
      emoji: "🇲🇫",
      unicode: "U+1F1F2 U+1F1EB",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MF.svg",
    },
    {
      name: "Madagascar",
      code: "MG",
      emoji: "🇲🇬",
      unicode: "U+1F1F2 U+1F1EC",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MG.svg",
    },
    {
      name: "Marshall Islands",
      code: "MH",
      emoji: "🇲🇭",
      unicode: "U+1F1F2 U+1F1ED",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MH.svg",
    },
    {
      name: "North Macedonia",
      code: "MK",
      emoji: "🇲🇰",
      unicode: "U+1F1F2 U+1F1F0",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MK.svg",
    },
    {
      name: "Mali",
      code: "ML",
      emoji: "🇲🇱",
      unicode: "U+1F1F2 U+1F1F1",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ML.svg",
    },
    {
      name: "Myanmar (Burma)",
      code: "MM",
      emoji: "🇲🇲",
      unicode: "U+1F1F2 U+1F1F2",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MM.svg",
    },
    {
      name: "Mongolia",
      code: "MN",
      emoji: "🇲🇳",
      unicode: "U+1F1F2 U+1F1F3",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MN.svg",
    },
    {
      name: "Macao SAR China",
      code: "MO",
      emoji: "🇲🇴",
      unicode: "U+1F1F2 U+1F1F4",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MO.svg",
    },
    {
      name: "Northern Mariana Islands",
      code: "MP",
      emoji: "🇲🇵",
      unicode: "U+1F1F2 U+1F1F5",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MP.svg",
    },
    {
      name: "Martinique",
      code: "MQ",
      emoji: "🇲🇶",
      unicode: "U+1F1F2 U+1F1F6",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MQ.svg",
    },
    {
      name: "Mauritania",
      code: "MR",
      emoji: "🇲🇷",
      unicode: "U+1F1F2 U+1F1F7",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MR.svg",
    },
    {
      name: "Montserrat",
      code: "MS",
      emoji: "🇲🇸",
      unicode: "U+1F1F2 U+1F1F8",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MS.svg",
    },
    {
      name: "Malta",
      code: "MT",
      emoji: "🇲🇹",
      unicode: "U+1F1F2 U+1F1F9",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MT.svg",
    },
    {
      name: "Mauritius",
      code: "MU",
      emoji: "🇲🇺",
      unicode: "U+1F1F2 U+1F1FA",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MU.svg",
    },
    {
      name: "Maldives",
      code: "MV",
      emoji: "🇲🇻",
      unicode: "U+1F1F2 U+1F1FB",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MV.svg",
    },
    {
      name: "Malawi",
      code: "MW",
      emoji: "🇲🇼",
      unicode: "U+1F1F2 U+1F1FC",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MW.svg",
    },
    {
      name: "Mexico",
      code: "MX",
      emoji: "🇲🇽",
      unicode: "U+1F1F2 U+1F1FD",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MX.svg",
    },
    {
      name: "Malaysia",
      code: "MY",
      emoji: "🇲🇾",
      unicode: "U+1F1F2 U+1F1FE",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MY.svg",
    },
    {
      name: "Mozambique",
      code: "MZ",
      emoji: "🇲🇿",
      unicode: "U+1F1F2 U+1F1FF",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MZ.svg",
    },
    {
      name: "Namibia",
      code: "NA",
      emoji: "🇳🇦",
      unicode: "U+1F1F3 U+1F1E6",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NA.svg",
    },
    {
      name: "New Caledonia",
      code: "NC",
      emoji: "🇳🇨",
      unicode: "U+1F1F3 U+1F1E8",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NC.svg",
    },
    {
      name: "Niger",
      code: "NE",
      emoji: "🇳🇪",
      unicode: "U+1F1F3 U+1F1EA",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NE.svg",
    },
    {
      name: "Norfolk Island",
      code: "NF",
      emoji: "🇳🇫",
      unicode: "U+1F1F3 U+1F1EB",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NF.svg",
    },
    {
      name: "Nigeria",
      code: "NG",
      emoji: "🇳🇬",
      unicode: "U+1F1F3 U+1F1EC",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NG.svg",
    },
    {
      name: "Nicaragua",
      code: "NI",
      emoji: "🇳🇮",
      unicode: "U+1F1F3 U+1F1EE",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NI.svg",
    },
    {
      name: "Netherlands",
      code: "NL",
      emoji: "🇳🇱",
      unicode: "U+1F1F3 U+1F1F1",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NL.svg",
    },
    {
      name: "Norway",
      code: "NO",
      emoji: "🇳🇴",
      unicode: "U+1F1F3 U+1F1F4",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NO.svg",
    },
    {
      name: "Nepal",
      code: "NP",
      emoji: "🇳🇵",
      unicode: "U+1F1F3 U+1F1F5",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NP.svg",
    },
    {
      name: "Nauru",
      code: "NR",
      emoji: "🇳🇷",
      unicode: "U+1F1F3 U+1F1F7",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NR.svg",
    },
    {
      name: "Niue",
      code: "NU",
      emoji: "🇳🇺",
      unicode: "U+1F1F3 U+1F1FA",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NU.svg",
    },
    {
      name: "New Zealand",
      code: "NZ",
      emoji: "🇳🇿",
      unicode: "U+1F1F3 U+1F1FF",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NZ.svg",
    },
    {
      name: "Oman",
      code: "OM",
      emoji: "🇴🇲",
      unicode: "U+1F1F4 U+1F1F2",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/OM.svg",
    },
    {
      name: "Panama",
      code: "PA",
      emoji: "🇵🇦",
      unicode: "U+1F1F5 U+1F1E6",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PA.svg",
    },
    {
      name: "Peru",
      code: "PE",
      emoji: "🇵🇪",
      unicode: "U+1F1F5 U+1F1EA",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PE.svg",
    },
    {
      name: "French Polynesia",
      code: "PF",
      emoji: "🇵🇫",
      unicode: "U+1F1F5 U+1F1EB",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PF.svg",
    },
    {
      name: "Papua New Guinea",
      code: "PG",
      emoji: "🇵🇬",
      unicode: "U+1F1F5 U+1F1EC",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PG.svg",
    },
    {
      name: "Philippines",
      code: "PH",
      emoji: "🇵🇭",
      unicode: "U+1F1F5 U+1F1ED",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PH.svg",
    },
    {
      name: "Pakistan",
      code: "PK",
      emoji: "🇵🇰",
      unicode: "U+1F1F5 U+1F1F0",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PK.svg",
    },
    {
      name: "Poland",
      code: "PL",
      emoji: "🇵🇱",
      unicode: "U+1F1F5 U+1F1F1",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PL.svg",
    },
    {
      name: "St. Pierre & Miquelon",
      code: "PM",
      emoji: "🇵🇲",
      unicode: "U+1F1F5 U+1F1F2",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PM.svg",
    },
    {
      name: "Pitcairn Islands",
      code: "PN",
      emoji: "🇵🇳",
      unicode: "U+1F1F5 U+1F1F3",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PN.svg",
    },
    {
      name: "Puerto Rico",
      code: "PR",
      emoji: "🇵🇷",
      unicode: "U+1F1F5 U+1F1F7",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PR.svg",
    },
    {
      name: "Palestinian Territories",
      code: "PS",
      emoji: "🇵🇸",
      unicode: "U+1F1F5 U+1F1F8",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PS.svg",
    },
    {
      name: "Portugal",
      code: "PT",
      emoji: "🇵🇹",
      unicode: "U+1F1F5 U+1F1F9",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PT.svg",
    },
    {
      name: "Palau",
      code: "PW",
      emoji: "🇵🇼",
      unicode: "U+1F1F5 U+1F1FC",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PW.svg",
    },
    {
      name: "Paraguay",
      code: "PY",
      emoji: "🇵🇾",
      unicode: "U+1F1F5 U+1F1FE",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PY.svg",
    },
    {
      name: "Qatar",
      code: "QA",
      emoji: "🇶🇦",
      unicode: "U+1F1F6 U+1F1E6",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/QA.svg",
    },
    {
      name: "Réunion",
      code: "RE",
      emoji: "🇷🇪",
      unicode: "U+1F1F7 U+1F1EA",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RE.svg",
    },
    {
      name: "Romania",
      code: "RO",
      emoji: "🇷🇴",
      unicode: "U+1F1F7 U+1F1F4",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RO.svg",
    },
    {
      name: "Serbia",
      code: "RS",
      emoji: "🇷🇸",
      unicode: "U+1F1F7 U+1F1F8",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RS.svg",
    },
    {
      name: "Russia",
      code: "RU",
      emoji: "🇷🇺",
      unicode: "U+1F1F7 U+1F1FA",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RU.svg",
    },
    {
      name: "Rwanda",
      code: "RW",
      emoji: "🇷🇼",
      unicode: "U+1F1F7 U+1F1FC",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RW.svg",
    },
    {
      name: "Saudi Arabia",
      code: "SA",
      emoji: "🇸🇦",
      unicode: "U+1F1F8 U+1F1E6",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SA.svg",
    },
    {
      name: "Solomon Islands",
      code: "SB",
      emoji: "🇸🇧",
      unicode: "U+1F1F8 U+1F1E7",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SB.svg",
    },
    {
      name: "Seychelles",
      code: "SC",
      emoji: "🇸🇨",
      unicode: "U+1F1F8 U+1F1E8",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SC.svg",
    },
    {
      name: "Sudan",
      code: "SD",
      emoji: "🇸🇩",
      unicode: "U+1F1F8 U+1F1E9",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SD.svg",
    },
    {
      name: "Sweden",
      code: "SE",
      emoji: "🇸🇪",
      unicode: "U+1F1F8 U+1F1EA",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SE.svg",
    },
    {
      name: "Singapore",
      code: "SG",
      emoji: "🇸🇬",
      unicode: "U+1F1F8 U+1F1EC",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SG.svg",
    },
    {
      name: "St. Helena",
      code: "SH",
      emoji: "🇸🇭",
      unicode: "U+1F1F8 U+1F1ED",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SH.svg",
    },
    {
      name: "Slovenia",
      code: "SI",
      emoji: "🇸🇮",
      unicode: "U+1F1F8 U+1F1EE",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SI.svg",
    },
    {
      name: "Svalbard & Jan Mayen",
      code: "SJ",
      emoji: "🇸🇯",
      unicode: "U+1F1F8 U+1F1EF",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SJ.svg",
    },
    {
      name: "Slovakia",
      code: "SK",
      emoji: "🇸🇰",
      unicode: "U+1F1F8 U+1F1F0",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SK.svg",
    },
    {
      name: "Sierra Leone",
      code: "SL",
      emoji: "🇸🇱",
      unicode: "U+1F1F8 U+1F1F1",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SL.svg",
    },
    {
      name: "San Marino",
      code: "SM",
      emoji: "🇸🇲",
      unicode: "U+1F1F8 U+1F1F2",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SM.svg",
    },
    {
      name: "Senegal",
      code: "SN",
      emoji: "🇸🇳",
      unicode: "U+1F1F8 U+1F1F3",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SN.svg",
    },
    {
      name: "Somalia",
      code: "SO",
      emoji: "🇸🇴",
      unicode: "U+1F1F8 U+1F1F4",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SO.svg",
    },
    {
      name: "Suriname",
      code: "SR",
      emoji: "🇸🇷",
      unicode: "U+1F1F8 U+1F1F7",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SR.svg",
    },
    {
      name: "South Sudan",
      code: "SS",
      emoji: "🇸🇸",
      unicode: "U+1F1F8 U+1F1F8",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SS.svg",
    },
    {
      name: "São Tomé & Príncipe",
      code: "ST",
      emoji: "🇸🇹",
      unicode: "U+1F1F8 U+1F1F9",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ST.svg",
    },
    {
      name: "El Salvador",
      code: "SV",
      emoji: "🇸🇻",
      unicode: "U+1F1F8 U+1F1FB",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SV.svg",
    },
    {
      name: "Sint Maarten",
      code: "SX",
      emoji: "🇸🇽",
      unicode: "U+1F1F8 U+1F1FD",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SX.svg",
    },
    {
      name: "Syria",
      code: "SY",
      emoji: "🇸🇾",
      unicode: "U+1F1F8 U+1F1FE",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SY.svg",
    },
    {
      name: "Eswatini",
      code: "SZ",
      emoji: "🇸🇿",
      unicode: "U+1F1F8 U+1F1FF",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SZ.svg",
    },
    {
      name: "Tristan da Cunha",
      code: "TA",
      emoji: "🇹🇦",
      unicode: "U+1F1F9 U+1F1E6",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TA.svg",
    },
    {
      name: "Turks & Caicos Islands",
      code: "TC",
      emoji: "🇹🇨",
      unicode: "U+1F1F9 U+1F1E8",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TC.svg",
    },
    {
      name: "Chad",
      code: "TD",
      emoji: "🇹🇩",
      unicode: "U+1F1F9 U+1F1E9",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TD.svg",
    },
    {
      name: "French Southern Territories",
      code: "TF",
      emoji: "🇹🇫",
      unicode: "U+1F1F9 U+1F1EB",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TF.svg",
    },
    {
      name: "Togo",
      code: "TG",
      emoji: "🇹🇬",
      unicode: "U+1F1F9 U+1F1EC",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TG.svg",
    },
    {
      name: "Thailand",
      code: "TH",
      emoji: "🇹🇭",
      unicode: "U+1F1F9 U+1F1ED",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TH.svg",
    },
    {
      name: "Tajikistan",
      code: "TJ",
      emoji: "🇹🇯",
      unicode: "U+1F1F9 U+1F1EF",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TJ.svg",
    },
    {
      name: "Tokelau",
      code: "TK",
      emoji: "🇹🇰",
      unicode: "U+1F1F9 U+1F1F0",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TK.svg",
    },
    {
      name: "Timor-Leste",
      code: "TL",
      emoji: "🇹🇱",
      unicode: "U+1F1F9 U+1F1F1",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TL.svg",
    },
    {
      name: "Turkmenistan",
      code: "TM",
      emoji: "🇹🇲",
      unicode: "U+1F1F9 U+1F1F2",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TM.svg",
    },
    {
      name: "Tunisia",
      code: "TN",
      emoji: "🇹🇳",
      unicode: "U+1F1F9 U+1F1F3",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TN.svg",
    },
    {
      name: "Tonga",
      code: "TO",
      emoji: "🇹🇴",
      unicode: "U+1F1F9 U+1F1F4",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TO.svg",
    },
    {
      name: "Turkey",
      code: "TR",
      emoji: "🇹🇷",
      unicode: "U+1F1F9 U+1F1F7",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TR.svg",
    },
    {
      name: "Trinidad & Tobago",
      code: "TT",
      emoji: "🇹🇹",
      unicode: "U+1F1F9 U+1F1F9",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TT.svg",
    },
    {
      name: "Tuvalu",
      code: "TV",
      emoji: "🇹🇻",
      unicode: "U+1F1F9 U+1F1FB",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TV.svg",
    },
    {
      name: "Taiwan",
      code: "TW",
      emoji: "🇹🇼",
      unicode: "U+1F1F9 U+1F1FC",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TW.svg",
    },
    {
      name: "Tanzania",
      code: "TZ",
      emoji: "🇹🇿",
      unicode: "U+1F1F9 U+1F1FF",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TZ.svg",
    },
    {
      name: "Ukraine",
      code: "UA",
      emoji: "🇺🇦",
      unicode: "U+1F1FA U+1F1E6",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UA.svg",
    },
    {
      name: "Uganda",
      code: "UG",
      emoji: "🇺🇬",
      unicode: "U+1F1FA U+1F1EC",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UG.svg",
    },
    {
      name: "U.S. Outlying Islands",
      code: "UM",
      emoji: "🇺🇲",
      unicode: "U+1F1FA U+1F1F2",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UM.svg",
    },
    {
      name: "United Nations",
      code: "UN",
      emoji: "🇺🇳",
      unicode: "U+1F1FA U+1F1F3",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UN.svg",
    },
    {
      name: "United States",
      code: "US",
      emoji: "🇺🇸",
      unicode: "U+1F1FA U+1F1F8",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg",
    },
    {
      name: "Uruguay",
      code: "UY",
      emoji: "🇺🇾",
      unicode: "U+1F1FA U+1F1FE",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UY.svg",
    },
    {
      name: "Uzbekistan",
      code: "UZ",
      emoji: "🇺🇿",
      unicode: "U+1F1FA U+1F1FF",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UZ.svg",
    },
    {
      name: "Vatican City",
      code: "VA",
      emoji: "🇻🇦",
      unicode: "U+1F1FB U+1F1E6",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VA.svg",
    },
    {
      name: "St. Vincent & Grenadines",
      code: "VC",
      emoji: "🇻🇨",
      unicode: "U+1F1FB U+1F1E8",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VC.svg",
    },
    {
      name: "Venezuela",
      code: "VE",
      emoji: "🇻🇪",
      unicode: "U+1F1FB U+1F1EA",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VE.svg",
    },
    {
      name: "British Virgin Islands",
      code: "VG",
      emoji: "🇻🇬",
      unicode: "U+1F1FB U+1F1EC",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VG.svg",
    },
    {
      name: "U.S. Virgin Islands",
      code: "VI",
      emoji: "🇻🇮",
      unicode: "U+1F1FB U+1F1EE",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VI.svg",
    },
    {
      name: "Vietnam",
      code: "VN",
      emoji: "🇻🇳",
      unicode: "U+1F1FB U+1F1F3",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VN.svg",
    },
    {
      name: "Vanuatu",
      code: "VU",
      emoji: "🇻🇺",
      unicode: "U+1F1FB U+1F1FA",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VU.svg",
    },
    {
      name: "Wallis & Futuna",
      code: "WF",
      emoji: "🇼🇫",
      unicode: "U+1F1FC U+1F1EB",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WF.svg",
    },
    {
      name: "Samoa",
      code: "WS",
      emoji: "🇼🇸",
      unicode: "U+1F1FC U+1F1F8",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WS.svg",
    },
    {
      name: "Kosovo",
      code: "XK",
      emoji: "🇽🇰",
      unicode: "U+1F1FD U+1F1F0",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/XK.svg",
    },
    {
      name: "Yemen",
      code: "YE",
      emoji: "🇾🇪",
      unicode: "U+1F1FE U+1F1EA",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YE.svg",
    },
    {
      name: "Mayotte",
      code: "YT",
      emoji: "🇾🇹",
      unicode: "U+1F1FE U+1F1F9",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YT.svg",
    },
    {
      name: "South Africa",
      code: "ZA",
      emoji: "🇿🇦",
      unicode: "U+1F1FF U+1F1E6",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZA.svg",
    },
    {
      name: "Zambia",
      code: "ZM",
      emoji: "🇿🇲",
      unicode: "U+1F1FF U+1F1F2",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZM.svg",
    },
    {
      name: "Zimbabwe",
      code: "ZW",
      emoji: "🇿🇼",
      unicode: "U+1F1FF U+1F1FC",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZW.svg",
    },
    {
      name: "England",
      code: "ENGLAND",
      emoji: "🏴󠁧󠁢󠁥󠁮󠁧󠁿",
      unicode: "U+1F3F4 U+E0067 U+E0062 U+E0065 U+E006E U+E0067 U+E007F",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ENGLAND.svg",
    },
    {
      name: "Scotland",
      code: "SCOTLAND",
      emoji: "🏴󠁧󠁢󠁳󠁣󠁴󠁿",
      unicode: "U+1F3F4 U+E0067 U+E0062 U+E0073 U+E0063 U+E0074 U+E007F",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SCOTLAND.svg",
    },
    {
      name: "Wales",
      code: "WALES",
      emoji: "🏴󠁧󠁢󠁷󠁬󠁳󠁿",
      unicode: "U+1F3F4 U+E0067 U+E0062 U+E0077 U+E006C U+E0073 U+E007F",
      image:
        "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WALES.svg",
    },
  ];
  