import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getRaceNationalityFilterForStatsSlice = createSlice({
    name: 'RaceNationalityFilterForStats',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchRaceNationalityFilterForStats.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchRaceNationalityFilterForStats.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchRaceNationalityFilterForStats.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setRaceNationalityFilterForStats, setStatus } = getRaceNationalityFilterForStatsSlice.actions;
export default getRaceNationalityFilterForStatsSlice.reducer;

export const fetchRaceNationalityFilterForStats = createAsyncThunk('/RaceNationalityFilterForStats/fetch', async ({ year,
    Nationality,
    RaceCourse,
    GroundType,
    RaceKind,
    HorseKind,
    RaceType,
    TrackLength}) => {
    const res = await axios.get(`${window.env.API_URL}/RaceNationalityFilterForStats?year=${year === undefined ? "" : year}&Nationality=${Nationality === undefined ? "" : Nationality}&RaceCourse=${RaceCourse === undefined ? "" : RaceCourse}&GroundType=${GroundType === undefined ? "" : GroundType}&RaceKind=${RaceKind === undefined ? "" : RaceKind}&HorseKind=${HorseKind === undefined ? "" : HorseKind }&RaceType=${RaceType=== undefined ? "" : RaceType}&TrackLength=${TrackLength === undefined ? "" : TrackLength}&size=40`);
    const RaceNationalityFilterForStatsData = res.data;
    return RaceNationalityFilterForStatsData.data;
})
