import { BsCaretDownFill } from "react-icons/bs";
import "../CSS/HomeCSS/auth.css";
import icondata from "../../assets/Forward (1).png";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { getUserDetails } from "../../redux/postReducer/UserPost";
import { logout } from "../../redux/getReducer/UserSlice";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Cookies from "js-cookie";

const Auth = () => {
  const { t } = useTranslation();
  const { userInfo, token } = useSelector((state) => state.user);
  const { data: ValidateUser } = useSelector((state) => state.ValidateUser);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  // useEffect(() => {
  // if(ValidateUser?.length === 0){
  //   navigate('/login')
  // }
  // },[ValidateUser])

  useEffect(() => {
    if (token !== null && userInfo === null) {
      dispatch(getUserDetails());
    }
  }, [userInfo, dispatch]);




  const clearAllCookies = () => {
    const cookies = Cookies.get();
    for (const cookie in cookies) {
      Cookies.remove(cookie);
      console.log(cookie)
    }
  };
 
  const handleLogout = async () => {
    await axios.get(`${window.env.API_URL}/logout`);
    dispatch(logout());

    navigate("/login");
  };

  return (
    <>
      <div className="cta">
        {userInfo ? (
          <div className="auth">
            <div className="userprofile">
              <span className="profilepic">
                {userInfo !== null ? (
                  // <></>
                  <img
                    src={
                      userInfo === null ? (
                        <></>
                      ) : userInfo.user ? (
                        userInfo?.user?.ProfilePicture
                      ) : (
                        userInfo?.data?.ProfilePicture
                      )
                    }
                    alt=""
                  />
                ) : (
                  <></>
                )}
              </span>
              <span className="profilename">
                <p className="username">
                  {userInfo === null ? (
                    <> </>
                  ) : userInfo?.user ? (
                    userInfo?.user?.FirstName
                  ) : (
                    userInfo?.data?.FirstName
                  )}{" "}
                  {userInfo === null ? (
                    <> </>
                  ) : userInfo?.user ? (
                    userInfo?.user?.LastName
                  ) : (
                    userInfo?.data?.LastName
                  )}
                </p>
                {/* <p className="pointuser">{t('1000 pts')}</p> */}
              </span>
              <span>
                <BsCaretDownFill
                  style={{
                    color: "rgba(0, 0, 0, 0.5)",
                  }}
                />
              </span>
            </div>
            <div className="authhover">
              <ul>
                <li>
                  <Link to="/dashboard" className="LinkStyle">
                    {t("Dashboard")}
                  </Link>
                </li>
                <li>
                  <Link to="/tracker/#Horseshome" className="LinkStyle">
                    {t("My Tracker")}
                  </Link>
                </li>
                <li>
                  <Link to="/myprofile" className="LinkStyle">
                    {t("MyProfile")}
                  </Link>
                </li>
                {/* <li>{t("Setting")}</li> */}
                
                <li style={{fontSize:"20px"}} onClick={handleLogout}>{t("LOGOUT")}</li>
              </ul>
            </div>
          </div>
        ) : (
         
          <div className="auth1 ">
            <NavLink className="buttonLogin" to="/login">
              {t("Login")}
            </NavLink>
            <NavLink className="buttonSubscribe" to="/register">
              {t("Register")}
            </NavLink>
          </div>
        )}
      </div>
    </>
  );
};
export default Auth;