import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getUserTrackedHorsesSlice = createSlice({
    name: 'UserTrackedHorses',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchUserTrackedHorses.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchUserTrackedHorses.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchUserTrackedHorses.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setUserTrackedHorses, setStatus } = getUserTrackedHorsesSlice.actions;
export default getUserTrackedHorsesSlice.reducer;
// const token = Cookies.get("token");

export const fetchUserTrackedHorses = createAsyncThunk('/UserTrackedHorsesget/fetch', async ({token}) => {
    const res = await axios.get(`${window.env.API_URL}/UserTrackedHorses`,{
        headers: { "Authorization": token, 'x-xsrf-token': token }
    });
    const UserTrackedHorsesData = res.data;
    return UserTrackedHorsesData.data;
})
