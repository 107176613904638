import { useTranslation } from "react-i18next";
import { fetchPredictionRace } from "../../../redux/getReducer/getPredictionSlice";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import Shirt from "../../../assets/Frame.png";
import noimage from "../../../assets/novote.png";

const Prediction = ({ data }) => {
  const { t } = useTranslation();
  // const { data: PredictionRace } = useSelector((state) => state.PredictionRace);
  const { data: ThemeSetting } = useSelector((state) => state.ThemeSetting);

  const dispatch = useDispatch();
  const cookiedata = Cookies.get("i18next");
  // useEffect(() => {
  //   dispatch(fetchPredictionRace());
  // }, [dispatch]);
  function calculatePercentage(x, y) {
    return (x / y) * 100;
  }
  return (
    <>
      <div>
        <h6 className="liveResult1">
          {t("live_prediction")}
          <span>.</span>{" "}
        </h6>
      </div>
      <div className="PredictionCard"   >
        <div className="presictData">
          {!data ? (
            <></>
          ) : (
            <>
              <div className="MatchDataPredict">
               
                <span className="PredictionFlex">
                <h3 className="tooltipclass">
                  {cookiedata === "en" ? (
                    <>
                      {data[0].racedata.RaceNameModelData.NameEn ? (
                        `${data[0].racedata.RaceNameModelData.NameEn} - ${data[0].racedata.RaceNumber}`
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <> {`${data[0].racedata.RaceNameModelData.NameAr} - ${data[0].racedata.RaceNumber}`}</>
                  )}
                </h3>
                  <p>
                    {data[0].racedata.RacehorsesData ? (
                      <>
                        {data[0].racedata.RacehorsesData.length === 0 ? (
                          <>-</>
                        ) : (
                          <>{data[0].racedata.RacehorsesData.length}</>
                        )}
                      </>
                    ) : (
                      <></>
                    )}{" "}
                    {t("Runner")}
                  </p>
                  {/* <p> {t("min")}</p> */}
                </span>
                <div className="playerPredictTable">
                  {data[1].votesdata.length == 0 ? (
                    <>
                      {" "}
                      <img src={noimage} alt="" className="noimage1" />
                    </>
                  ) : (
                    data[1].votesdata.map((datas, index) => {
                      return (
                        <>
                          <span className="Predictiontable">
                            <img
                              src={
                                datas.OwnerSilkColor
                                  ? datas.OwnerSilkColor
                                  : Shirt
                              }
                              alt=""
                            />
                            <p>
                              {cookiedata === "en" ? (
                                <>{datas.NameEn}</>
                              ) : (
                                <>{datas.NameAr}</>
                            )}
                              ({datas.HorseNo ? datas.HorseNo : "-"})
                            </p>
                            <p>
                              {calculatePercentage(
                                datas.votes,
                                data[2].totalvotes
                              ).toFixed(2)}
                              %
                            </p>
                          </span>
                        </>
                      );
                    })
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default Prediction;
