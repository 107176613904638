import React, { useEffect, useState, Fragment } from "react";
import Layout from "../Components/Reuseable/layout";
import Footer from "../Components/Reuseable/Footer.jsx";
import CoptRight from "../Components/Reuseable/Copyrights";
import Cookies from "js-cookie";
import { Accordion } from "react-bootstrap";
import "../../Webiste/Components/CSS/pagesCSS/dashboard.css";
import ScrollContainer from "react-indiana-drag-scroll";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import Select from "react-select";
import Pagination from "./Pagination";
import "../Components/CSS/RaceCardCSS/racecard.css";
import { useSelector, useDispatch } from "react-redux";
import { fetchTrainerStats } from "../redux/getReducer/getTrainerStats";
import { fetchTrainerRecord } from "../redux/getReducer/getTrainerRecord";
import Moment from "react-moment";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import PleaseWait from "../Components/Reuseable/PleaseWait";
import arrow1 from "../assets/image 13 (Traced).png";

import { fetchResultYearFilterForStats } from "../redux/getStatsFilterReduce/getResultYearFilterForStats";
import { fetchRaceTypeFilterForStats } from "../redux/getStatsFilterReduce/getRaceTypeFilterForStats";
import { fetchTrackLengthFilterForStats } from "../redux/getStatsFilterReduce/getTrackLengthFilterForStats";
import { fetchHorseKindFilterForStats } from "../redux/getStatsFilterReduce/getHorseKindFilterForStats";
import { fetchRaceKindFilterForStats } from "../redux/getStatsFilterReduce/getRaceKindFilterForStats";
import { fetchRaceNationalityFilterForStats } from "../redux/getStatsFilterReduce/getRaceNationalityFilterForStats";
import { fetchRaceCourseFilterForStats } from "../redux/getStatsFilterReduce/getRaceCourseFilterForStats";
import { fetchDatesFilterForStats } from "../redux/getStatsFilterReduce/getDatesFilterForStats";
import { fetchGroundTypeFilterForStats } from "../redux/getStatsFilterReduce/getGroundTypeFilterForStats";

import Lottie from "lottie-react";
import Animate from "../assets/loader.json";
import TrainerModal from "../Components/Modal/TrainerModal";
import JockeyModal from "../Components/Modal/JockeyModal";
import HorseModal from "../Components/Modal/HorseModal";
import { Modal } from "react-bootstrap";
import VideoFrame from "../Components/Modal/VideoFrame";
import RaceModal from "../Components/Modal/RaceModal";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

export default function Trainerstatistics() {
  const { data: trainerstats, status } = useSelector(
    (state) => state.trainerstats
  );

  const { data: trainerrecord, statusdata } = useSelector(
    (state) => state.trainerrecord
  );
  const { data: ResultYearFilterForStats } = useSelector(
    (state) => state.ResultYearFilterForStats
  );
  const { data: RaceTypeFilterForStats } = useSelector(
    (state) => state.RaceTypeFilterForStats
  );
  const { data: TrackLengthFilterForStats } = useSelector(
    (state) => state.TrackLengthFilterForStats
  );
  const { data: HorseKindFilterForStats } = useSelector(
    (state) => state.HorseKindFilterForStats
  );
  const { data: RaceKindFilterForStats } = useSelector(
    (state) => state.RaceKindFilterForStats
  );
  const { data: RaceNationalityFilterForStats } = useSelector(
    (state) => state.RaceNationalityFilterForStats
  );
  const { data: RaceCourseFilterForStats } = useSelector(
    (state) => state.RaceCourseFilterForStats
  );
  const { data: DatesFilterForStats } = useSelector(
    (state) => state.DatesFilterForStats
  );
  const { data: GroundTypeFilterForStats } = useSelector(
    (state) => state.GroundTypeFilterForStats
  );

  const dispatch = useDispatch();
  const [value, setValue] = useState(null);

  const [StartDate, setStartDate] = useState(
 moment().subtract(2,"months").startOf("month").format("YYYY-MM-DD")
  );
  const [EndDate, setEndDate] = useState(
    moment().add(1, "months").endOf("month").format("YYYY-MM-DD")
  );

  const { t } = useTranslation();
  const cookiedata = Cookies.get("i18next");

  const [DateTobeDone, SetDateTobeDone] = useState([]);
  const [showBody, setShowBody] = useState(null);
  const [show, setShow] = useState(false);
  const [modaldata, setmodaldata] = useState();

  const handleClose = () => setShow(false);
  const handleShow = async (data) => {
    setmodaldata(data);
    await setShow(true);
  };

  const [showHorse, setShowHorse] = useState(false);
  const [modaldataHorse, setmodaldataHorse] = useState();

  const handleCloseHorse = () => setShowHorse(false);
  const handleShowHorse = async (data) => {
    setmodaldataHorse(data);
    await setShowHorse(true);
  };
  const [shows, setShows] = useState(false);
  const [modaldatas, setmodaldatas] = useState();
  const handleCloses = () => setShows(false);
  const handleShows = async (data) => {
    setmodaldatas(data);
    await setShows(true);
  };

  const [showRace, setShowRace] = useState(false);
  const [modaldataRace, setmodaldataRace] = useState();
  const handleCloseRace = () => setShowRace(false);
  const handleShowRace = async (id) => {
    setmodaldataRace(id);
    await setShowRace(true);
  };

  const [showJockey, setShowJockey] = useState(false);
  const [modaldataJockey, setmodaldataJockey] = useState();

  const handleCloseJockey = () => setShowJockey(false);
  const handleShowJockey = async (data) => {
    setmodaldataJockey(data);
    await setShowJockey(true);
  };

  let totalcountdata = trainerstats ? trainerstats.totalcount : "";
  let totalpagesdata = trainerstats ? trainerstats.totalPages : "";
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(11);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [ResultYearData, setResultYearData] = useState("");
  const [ResultDatesData, setResultDates] = useState("");
  const [ResultRaceCourseData, setResultRaceCourse] = useState("");
  const [ResultRaceTypeData, setResultRaceType] = useState("");
  const [ResultNationalityData, setResultNationality] = useState("");
  const [ResultRaceTracklengthData, setResultRaceTracklength] = useState("");
  const [ResultGroundData, setResultGround] = useState("");
  const [ResultRaceKindData, setResultRaceKind] = useState("");

  const [ResultHorseKindData, setResultHorseKind] = useState("");

  const [data, setData] = useState([]);
  const [Dates, setDates] = useState([]);
  const GetDates = (alldata) => {
    var start = new Date();
    var end = new Date(start);

    end.setDate(start.getDate() + 3);
    let Dates = [];

    var loop = new Date(start);

    for (let i = 0; i < 5; i++) {
      if (i == 0) {
        var newDates = loop.setDate(loop.getDate() + i);
        loop = new Date(newDates);
        Dates.push(moment(loop).format("YYYY-MM-DD"));
      } else {
        var newDates = loop.setDate(loop.getDate() - 1);
        loop = new Date(newDates);
        Dates.push(moment(loop).format("YYYY-MM-DD"));
      }
    }

    SetDateTobeDone(Dates);
    return Dates;
  };

  let RaceYearDropdown = ResultYearFilterForStats.map(function (item) {
    return {
      id: item.RacesYear,
      value: item.RacesYear,
      label: item.RacesYear,
    };
  });

  let RaceTypeFilterForStatsDropdown = RaceTypeFilterForStats.map(function (
    item
  ) {
    return cookiedata === "en"
      ? {
          id: item._id,
          value: item.NameEn,
          label: item.NameEn,
        }
      : {
          id: item._id,
          value: item.NameAr,
          label: item.NameAr,
        };
  });

  let GroundTypeFilterForStatsDropdown =
    GroundTypeFilterForStats &&
    GroundTypeFilterForStats.map(function (item) {
      return cookiedata === "en"
        ? {
            id: item._id,
            value: item.NameEn,
            label: item.NameEn,
          }
        : {
            id: item._id,
            value: item.NameAr,
            label: item.NameAr,
          };
    });

  let TrackLengthFilterForStatsDropdown = TrackLengthFilterForStats.map(
    function (item) {
      return {
        id: item._id,
        value: item.TrackLength,
        label: item.TrackLength,
      };
    }
  );

  let HorseKindFilterForStatsDropdown = HorseKindFilterForStats.map(function (
    item
  ) {
    return cookiedata === "en"
      ? {
          id: item._id,
          value: item.NameEn,
          label: item.NameEn,
        }
      : {
          id: item._id,
          value: item.NameAr,
          label: item.NameAr,
        };
  });

  let RaceKindFilterForStatsDropdown = RaceKindFilterForStats.map(function (
    item
  ) {
    return cookiedata === "en"
      ? {
          id: item._id,
          value: item.NameEn,
          label: item.NameEn,
        }
      : {
          id: item._id,
          value: item.NameAr,
          label: item.NameAr,
        };
  });

  let RaceNationalityFilterForStatsDropdown = RaceNationalityFilterForStats.map(
    function (item) {
      return cookiedata === "en"
        ? {
            id: item._id,
            value: item.NationalityDataRaceCourse ? (
              item.NationalityDataRaceCourse.NameEn
            ) : (
              <></>
            ),
            label: item.NationalityDataRaceCourse ? (
              item.NationalityDataRaceCourse.NameEn
            ) : (
              <></>
            ),
          }
        : {
            id: item._id,
            value: item.NationalityDataRaceCourse ? (
              item.NationalityDataRaceCourse.NameAr
            ) : (
              <></>
            ),
            label: item.NationalityDataRaceCourse ? (
              item.NationalityDataRaceCourse.NameAr
            ) : (
              <></>
            ),
          };
    }
  );

  let RaceCourseFilterForStatsDropdown = RaceCourseFilterForStats.map(function (
    item
  ) {
    return cookiedata === "en"
      ? {
          id: item._id,
          value: item.TrackNameEn,
          label: item.TrackNameEn,
        }
      : {
          id: item._id,
          value: item.TrackNameAr,
          label: item.TrackNameAr,
        };
  });

  let year = ResultYearData?.id;

  let RaceCourseSearch = ResultRaceCourseData
    ? ResultRaceCourseData.id === undefined
      ? ""
      : ResultRaceCourseData.id
    : "";
  let HorseKindSearch = ResultHorseKindData
    ? ResultHorseKindData.id === undefined
      ? ""
      : ResultHorseKindData.id
    : "";
  let TrackLengthSearch = ResultRaceTracklengthData
    ? ResultRaceTracklengthData.id === undefined
      ? ""
      : ResultRaceTracklengthData.id
    : "";
  let RaceTypeSearch = ResultRaceTypeData
    ? ResultRaceTypeData.id === undefined
      ? ""
      : ResultRaceTypeData.id
    : "";
  let RaceKindSearch = ResultRaceKindData
    ? ResultRaceKindData.id === undefined
      ? ""
      : ResultRaceKindData.id
    : "";
  let RaceNationalitySearch = ResultNationalityData
    ? ResultNationalityData.id === undefined
      ? ""
      : ResultNationalityData.id
    : "";
  let RaceGroundSearch = ResultGroundData
    ? ResultGroundData.id === undefined
      ? ""
      : ResultGroundData.id
    : "";
  let RaceDateSearch = ResultYearData
    ? ResultYearData.id === undefined
      ? ""
      : ResultYearData.id
    : "";
  const handleDateChange = (dates, i) => {
    let StartDate = i[0];
    let EndDate = i[1];
    dispatch(
      fetchTrainerStats({
        StartDate,
        EndDate,
        RaceCourseSearch,
        HorseKindSearch,
        TrackLengthSearch,
        RaceTypeSearch,
        RaceKindSearch,
        RaceNationalitySearch,
        RaceGroundSearch,
        RaceDateSearch,
        currentPage,
      })
    );
    setStartDate(i[0]);
    setEndDate(i[1]);
  };

  useEffect(() => {
    dispatch(
      fetchTrainerStats({
        StartDate,
        EndDate,
        RaceCourseSearch,
        HorseKindSearch,
        TrackLengthSearch,
        RaceTypeSearch,
        RaceKindSearch,
        RaceNationalitySearch,
        RaceGroundSearch,
        RaceDateSearch,
        currentPage,
      })
    );
  }, []);
  useEffect(() => {
    dispatch(
      fetchTrainerStats({
        StartDate,
        EndDate,
        RaceCourseSearch,
        HorseKindSearch,
        TrackLengthSearch,
        RaceTypeSearch,
        RaceKindSearch,
        RaceNationalitySearch,
        RaceGroundSearch,
        RaceDateSearch,
        currentPage,
      })
    );
  }, [
    RaceCourseSearch,
    HorseKindSearch,
    TrackLengthSearch,
    RaceTypeSearch,
    RaceKindSearch,
    RaceNationalitySearch,
    RaceGroundSearch,
    RaceDateSearch,
    StartDate,
    EndDate,
    currentPage,
    dispatch,
  ]);

  let Nationality = ResultNationalityData?.id;
  let RaceCourse = ResultRaceCourseData?.id;
  let GroundType = ResultGroundData?.id;
  let RaceKind = ResultRaceKindData?.id;
  let HorseKind = ResultHorseKindData?.id;
  let RaceType = ResultRaceTypeData?.id;
  let TrackLength = ResultRaceTracklengthData?.id;

  useEffect(() => {
    dispatch(
      fetchTrainerRecord(
        year,
        Nationality,
        RaceCourse,
        GroundType,
        RaceKind,
        HorseKind,
        RaceType,
        TrackLength
      )
    );
    dispatch(
      fetchResultYearFilterForStats({
        year,
        Nationality,
        RaceCourse,
        GroundType,
        RaceKind,
        HorseKind,
        RaceType,
        TrackLength,
      })
    );
    dispatch(
      fetchRaceTypeFilterForStats({
        year,
        Nationality,
        RaceCourse,
        GroundType,
        RaceKind,
        HorseKind,
        RaceType,
        TrackLength,
      })
    );
    dispatch(
      fetchTrackLengthFilterForStats({
        year,
        Nationality,
        RaceCourse,
        GroundType,
        RaceKind,
        HorseKind,
        RaceType,
        TrackLength,
      })
    );
    dispatch(
      fetchHorseKindFilterForStats({
        year,
        Nationality,
        RaceCourse,
        GroundType,
        RaceKind,
        HorseKind,
        RaceType,
        TrackLength,
      })
    );
    dispatch(
      fetchRaceKindFilterForStats({
        year,
        Nationality,
        RaceCourse,
        GroundType,
        RaceKind,
        HorseKind,
        RaceType,
        TrackLength,
      })
    );
    dispatch(
      fetchRaceNationalityFilterForStats({
        year,
        Nationality,
        RaceCourse,
        GroundType,
        RaceKind,
        HorseKind,
        RaceType,
        TrackLength,
      })
    );
    dispatch(
      fetchRaceCourseFilterForStats({
        year,
        Nationality,
        RaceCourse,
        GroundType,
        RaceKind,
        HorseKind,
        RaceType,
        TrackLength,
      })
    );
    dispatch(
      fetchDatesFilterForStats({
        year,
        Nationality,
        RaceCourse,
        GroundType,
        RaceKind,
        HorseKind,
        RaceType,
        TrackLength,
      })
    );
    dispatch(
      fetchGroundTypeFilterForStats({
        year,
        Nationality,
        RaceCourse,
        GroundType,
        RaceKind,
        HorseKind,
        RaceType,
        TrackLength,
      })
    );
  }, [
    dispatch,
    year,
    Nationality,
    RaceCourse,
    GroundType,
    RaceKind,
    HorseKind,
    RaceType,
    TrackLength,
  ]);

  useEffect(() => {
    SetDateTobeDone(GetDates());
    setData(trainerstats);
  }, [trainerstats]);

  const handleTrainerRecord = async (id, index) => {
    setShowBody(index);
    await dispatch(
      fetchTrainerRecord({
        id,
        year,
        Nationality,
        RaceCourse,
        GroundType,
        RaceKind,
        HorseKind,
        RaceType,
        TrackLength,
      })
    );
  };

  const checkindex = (index1, index2, length, data) => {
    if (index2 < length) {
      return true;
    } else {
      return false;
    }
  };

  function compareDate(a, b) {
    var t1 = new Date(a);
    var t2 = new Date(b);
    var differenceInMilliseconds = Math.abs(t2 - t1);
    var differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    return differenceInDays;
  }

  function distanceLabel(distance, rank) {
    const DistanceTobeChecked = parseFloat(distance);
    if (DistanceTobeChecked.toFixed(2) > 25) {
      return "Dist";
    } else if (DistanceTobeChecked.toFixed(2) == 0.05 && rank < 80) {
      return "SHd";
    } else if (DistanceTobeChecked.toFixed(2) == 0.1 && rank < 80) {
      return "Hd";
    } else if (DistanceTobeChecked.toFixed(2) == 0.25 && rank < 80) {
      return "Nk";
    } else if (DistanceTobeChecked.toFixed(2) == 0.01 && rank < 80) {
      return "Ns";
    } else if (DistanceTobeChecked.toFixed(2) == 0.0 && rank < 80) {
      if (rank === 1) {
        return 0;
      }
      return "Dh";
    } else {
      return distance;
    }
  }

  function distanceLabel(distance, rank) {
    const DistanceTobeChecked = parseFloat(distance);
    if (DistanceTobeChecked.toFixed(2) > 25) {
      return "Dist";
    } else if (DistanceTobeChecked.toFixed(2) == 0.05 && rank < 80) {
      return "SHd";
    } else if (DistanceTobeChecked.toFixed(2) == 0.1 && rank < 80) {
      return "Hd";
    } else if (DistanceTobeChecked.toFixed(2) == 0.25 && rank < 80) {
      return "Nk";
    } else if (DistanceTobeChecked.toFixed(2) == 0.01 && rank < 80) {
      return "Ns";
    } else if (DistanceTobeChecked.toFixed(2) == 0.0 && rank < 80) {
      if (rank === 1) {
        return 0;
      }
      return "Dh";
    } else {
      return distance;
    }
  }

  return (
    <Fragment>
      <>
        {/* <Layout /> */}
        <div className="container mystages trainerStages">
          <div className="row">
            <div className="col-sm-12 stages">
              <div className="raceCarParent stages stages123">
                <div className="raceCardHeader1">
                  <div className="row">
                    <div className="col-sm-10">
                      <h1>{t("Trainer Statistics")}</h1>
                    </div>
                  </div>
                </div>
                <div className="posttabs">
                  <div className="row">
                    <div className="Statisticsbtn Statisticsbtn1">
                      <Select
                        id="selectNow2"
                        placeholder={t("Year")}
                        className="dropdown multidropdown"
                        isSearchable={true}
                        hideSelectedOptions={true}
                        name="ResultYearData"
                        onChange={setResultYearData}
                        options={RaceYearDropdown}
                        isClearable={true}
                        value={ResultYearData}
                      />
                      <Select
                        id="selectNow2"
                        placeholder={t("Nationality")}
                        className="dropdown multidropdown"
                        isSearchable={true}
                        hideSelectedOptions={true}
                        isClearable={true}
                        name="ResultNationality"
                        onChange={setResultNationality}
                        options={RaceNationalityFilterForStatsDropdown}
                        value={ResultNationalityData}
                      />
                      <Select
                        id="selectNow2"
                        placeholder={t("Race Course")}
                        className="dropdown multidropdown"
                        isSearchable={true}
                        isClearable={true}
                        hideSelectedOptions={true}
                        name="ResultNationality"
                        onChange={setResultRaceCourse}
                        options={RaceCourseFilterForStatsDropdown}
                        value={ResultRaceCourseData}
                      />
                      <Select
                        id="selectNow2"
                        placeholder={t("Ground Type")}
                        className="dropdown multidropdown"
                        isSearchable={true}
                        hideSelectedOptions={true}
                        name="Meeting"
                        isClearable={true}
                        onChange={setResultGround}
                        options={GroundTypeFilterForStatsDropdown}
                        value={ResultGroundData}
                      />

                      <Select
                        id="selectNow2"
                        placeholder={t("Race Kind")}
                        className="dropdown multidropdown"
                        isSearchable={true}
                        hideSelectedOptions={true}
                        name="ResultNationality"
                        isClearable={true}
                        onChange={setResultRaceKind}
                        options={RaceKindFilterForStatsDropdown}
                        value={ResultRaceKindData}
                      />
                      <Select
                        id="selectNow2"
                        placeholder={t("KindOfHorse")}
                        className="dropdown multidropdown"
                        isSearchable={true}
                        hideSelectedOptions={true}
                        name="ResultNationality"
                        onChange={setResultHorseKind}
                        isClearable={true}
                        options={HorseKindFilterForStatsDropdown}
                        value={ResultHorseKindData}
                      />
                      <Select
                        id="selectNow2"
                        placeholder={t("Track length")}
                        className="dropdown multidropdown"
                        isSearchable={true}
                        hideSelectedOptions={true}
                        name="length"
                        isClearable={true}
                        onChange={setResultRaceTracklength}
                        options={TrackLengthFilterForStatsDropdown}
                        value={ResultRaceTracklengthData}
                      />
                      <Select
                        id="selectNow2"
                        placeholder={t("Race Type")}
                        className="dropdown multidropdown"
                        isSearchable={true}
                        hideSelectedOptions={true}
                        name="ResultNationality"
                        isClearable={true}
                        onChange={setResultRaceType}
                        options={RaceTypeFilterForStatsDropdown}
                        value={ResultRaceTypeData}
                      />
                      <RangePicker
                        onChange={handleDateChange}
                        className="newdatepicker"
                      />
                    </div>
                  </div>
                </div>
                {status == "idle" ? (
                  <div className="formobilescroll1">
                    {" "}
                    <table className="statsTable innermodalstats mycss">
                      <thead>
                        <tr className="mycss1">
                          <td>{t("Trainer Name")}</td>
                          <td>{t("Runs")} </td>
                          <td>{t("Wins")} </td>
                          <td>{t("Second")}</td>
                          <td>{t("Third")} </td>
                          <td>{t("Fourth")} </td>
                          <td>{t("Fifth")} </td>
                          <td>{t("Earning")} </td>
                          <td>{t("Wins")}% </td>
                          <td></td>
                        </tr>
                      </thead>
                    </table>
                    {trainerstats.data &&
                      trainerstats.data.map((item, index) => (
                        <>
                          <Accordion eventKey={index} key={index}>
                            <Accordion.Item className="trainerstatsbtn1">
                              <div>
                                <Accordion.Header>
                                  <table>
                                    <thead>
                                      <tr
                                        onClick={() =>
                                          handleTrainerRecord(
                                            item.TrainerOnRace,
                                            index
                                          )
                                        }
                                      >
                                        <td
                                          onClick={() =>
                                            handleShow(item.TrainerOnRace)
                                          }
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          {cookiedata === "en"
                                            ? item.TrainerOnRaceDataResult
                                                ?.NameEn
                                            : item.TrainerOnRaceDataResult
                                                ?.NameAr}
                                        </td>
                                        <td>
                                          {item.TrainerOnRaceDataResult?.Runs}
                                        </td>
                                        <td>
                                          {item.TrainerOnRaceDataResult?.wins}
                                        </td>
                                        <td>
                                          {item.TrainerOnRaceDataResult?.second}
                                        </td>
                                        <td>
                                          {item.TrainerOnRaceDataResult?.third}
                                        </td>
                                        <td>
                                          {item.TrainerOnRaceDataResult?.fourth}
                                        </td>
                                        <td>
                                          {item.TrainerOnRaceDataResult?.fifth}
                                        </td>
                                        <td>
                                          {
                                            item.TrainerOnRaceDataResult
                                              ?.earning
                                          }
                                        </td>
                                        <td>
                                          {
                                            item.TrainerOnRaceDataResult
                                              ?.winspercentage
                                          }
                                        </td>
                                        <td>
                                          <IoIosArrowDropdownCircle />
                                        </td>
                                      </tr>
                                    </thead>
                                  </table>{" "}
                                </Accordion.Header>
                                {showBody === index && (
                                  <Accordion.Body className="trainerstatsbtn1">
                                    <div className="raceCardTable">
                                      <ScrollContainer>
                                        <div className="mycardclass1">
                                          <div className="BodyNew">
                                            <table className="customers">
                                              <thead className="customers5">
                                                <tr>
                                                  <th>{t("Date")}</th>
                                                  <th>{t("Crs")}</th>
                                                  <th>{t("Dist")}</th>
                                                  <th>{t("TC")}</th>
                                                  <th>{t("RT")}</th>

                                                  <th>{t("Dts")}</th>
                                                  <th>{t("Time")}</th>
                                                  <th>{t("Jockey")}</th>
                                                  <th>{t("Wgt")}</th>
                                                  <th>{t("FP")}</th>
                                                  <th>{t("Len")}</th>
                                                  <th>{t("RN")}</th>
                                                  <th>{t("BtBy")}</th>
                                                  <th>{t("Kgs")}</th>

                                                  <th>{t("Draw")}</th>
                                                  <th>{t("Link")}</th>
                                                </tr>
                                              </thead>
                                              <tbody className="customers1">
                                                {statusdata === "loading" ? (
                                                  <div class="pleaseWaitnodata nohistory">
                                                    <p>{t("Please Wait")}</p>
                                                    <span>
                                                      <i></i>
                                                      <i></i>
                                                    </span>
                                                  </div>
                                                ) : (
                                                  <>
                                                    {!trainerrecord ? (
                                                      <p>
                                                        {t("No History Yet")}
                                                      </p>
                                                    ) : (
                                                      trainerrecord.map(
                                                        (item, index) => {
                                                          return (
                                                            <>
                                                              <tr
                                                                className="BodyNew1 historyhorseth"
                                                                style={{
                                                                  background: `${
                                                                    item.RaceResultData
                                                                      ? item.RaceResultData &&
                                                                        item
                                                                          ?.RaceResultData
                                                                          ?.RacehorsesData[0]
                                                                          ?.RacehorsesData
                                                                          ?.TrackLengthData
                                                                          ?.GroundTypeModelData
                                                                          ?.NameEn ==
                                                                          "Dirt"
                                                                        ? "#9F5109"
                                                                        : item.RaceResultData &&
                                                                          item
                                                                            ?.RaceResultData
                                                                            ?.RacehorsesData[0]
                                                                            ?.RacehorsesData
                                                                            ?.TrackLengthData
                                                                            ?.GroundTypeModelData
                                                                            ?.NameEn ==
                                                                            "Turf"
                                                                        ? "#5ec40f"
                                                                        : "#949494"
                                                                      : "#949494"
                                                                  }`,
                                                                }}
                                                              >
                                                                <td
                                                                  className="lb"
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  onClick={() =>
                                                                    handleShowRace(
                                                                      item.RaceID
                                                                    )
                                                                  }
                                                                >
                                                                  <Moment format="DD/MM/YYYY">
                                                                    {item.RaceResultData ===
                                                                    null ? (
                                                                      <></>
                                                                    ) : (
                                                                      item
                                                                        ?.RaceResultData
                                                                        ?.Day
                                                                    )}
                                                                  </Moment>
                                                                </td>
                                                                <td>
                                                                  {item.RaceResultData &&
                                                                  item.RaceResultData ? (
                                                                    cookiedata ===
                                                                    "en" ? (
                                                                      item
                                                                        ?.RaceResultData
                                                                        ?.RacehorsesData[0]
                                                                        ?.RacehorsesData
                                                                        ?.RaceCourseData &&
                                                                      item
                                                                        ?.RaceResultData
                                                                        ?.RacehorsesData[0]
                                                                        ?.RacehorsesData
                                                                        ?.RaceCourseData
                                                                        ?.TrackNameEn
                                                                    ) : (
                                                                      item?.RaceResultData &&
                                                                      item
                                                                        ?.RaceResultData
                                                                        ?.RacehorsesData[0]
                                                                        ?.RacehorsesData
                                                                        ?.RaceCourseData
                                                                        ?.TrackNameAr
                                                                    )
                                                                  ) : (
                                                                    <>- </>
                                                                  )}
                                                                  (
                                                                  {cookiedata ===
                                                                  "en"
                                                                    ? item.RaceResultData &&
                                                                      item.RaceResultData?.RacehorsesData[0]?.RacehorsesData?.TrackLengthData?.GroundTypeModelData.NameEn.charAt(
                                                                        0
                                                                      )
                                                                    : item
                                                                        ?.RaceResultData
                                                                        ?.RacehorsesData[0]
                                                                        ?.RacehorsesData
                                                                        ?.TrackLengthData
                                                                        ?.GroundTypeModelData
                                                                        ?.NameAr}
                                                                  )
                                                                </td>
                                                                <td>
                                                                  {item.RaceResultData ? (
                                                                    item
                                                                      ?.RaceResultData
                                                                      ?.RacehorsesData[0]
                                                                      ?.RacehorsesData
                                                                      ?.TrackLengthData
                                                                      ?.TrackLength
                                                                  ) : (
                                                                    <>-</>
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {item.RaceResultData
                                                                    ? cookiedata ===
                                                                      "en"
                                                                      ? item.RaceResultData &&
                                                                        item
                                                                          ?.RaceResultData
                                                                          ?.RacehorsesData[0]
                                                                          ?.RacehorsesData
                                                                          ?.TrackConditionData
                                                                          ?.NameEn
                                                                      : item.RaceResultData &&
                                                                        item
                                                                          ?.RaceResultData
                                                                          ?.RacehorsesData[0]
                                                                          ?.RacehorsesData
                                                                          ?.TrackConditionData
                                                                          ?.NameAr
                                                                    : "_"}
                                                                </td>
                                                                <td>
                                                                  {item.RaceResultData
                                                                    ? cookiedata ===
                                                                      "en"
                                                                      ? item.RaceResultData &&
                                                                        item
                                                                          ?.RaceResultData
                                                                          ?.RacehorsesData[0]
                                                                          ?.RacehorsesData
                                                                          ?.RaceTypeModelData
                                                                          ?.NameEn
                                                                      : item.RaceResultData &&
                                                                        item
                                                                          ?.RaceResultData
                                                                          ?.RacehorsesData[0]
                                                                          ?.RacehorsesData
                                                                          ?.RaceTypeModelData
                                                                          .NameAr
                                                                    : "_"}
                                                                </td>
                                                                <td>
                                                                  {item.PrizeWin
                                                                    ? item.PrizeWin
                                                                    : 0}
                                                                </td>
                                                                <td>
                                                                  {
                                                                    item.RaceTime
                                                                  }
                                                                </td>
                                                                <td
                                                                  onClick={() =>
                                                                    handleShowJockey(
                                                                      item.JockeyOnRaceDataResult
                                                                        ? item
                                                                            ?.JockeyOnRaceDataResult
                                                                            ._id
                                                                        : ""
                                                                    )
                                                                  }
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                >
                                                                  {item.JockeyOnRaceDataResult
                                                                    ? cookiedata ===
                                                                      "en"
                                                                      ? window.textTransform(
                                                                          item
                                                                            ?.JockeyOnRaceDataResult
                                                                            .NameEn
                                                                        )
                                                                      : item
                                                                          ?.JockeyOnRaceDataResult
                                                                          .NameAr
                                                                    : "_"}
                                                                </td>
                                                                <td>
                                                                  {item
                                                                    .RaceResultData
                                                                    ?.RacehorsesData
                                                                    ? item
                                                                        ?.RaceResultData
                                                                        ?.RacehorsesData[0]
                                                                        ?.JockeyRaceWeight
                                                                    : "_"}
                                                                </td>
                                                                <td>
                                                                  {item.FinalPositionDataHorse ===
                                                                  null ? (
                                                                    <></>
                                                                  ) : (
                                                                    <>
                                                                      {cookiedata ===
                                                                      "en" ? (
                                                                        <>
                                                                          {cookiedata ===
                                                                          "en"
                                                                            ? item.FinalPositionDataHorse &&
                                                                              item
                                                                                ?.FinalPositionDataHorse
                                                                                ?.NameEn
                                                                            : item.FinalPositionDataHorse &&
                                                                              item
                                                                                ?.FinalPositionDataHorse
                                                                                ?.NameAr}
                                                                        </>
                                                                      ) : (
                                                                        <>
                                                                          {
                                                                            item
                                                                              ?.FinalPositionDataHorse
                                                                              ?.NameAr
                                                                          }
                                                                        </>
                                                                      )}
                                                                    </>
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {distanceLabel(
                                                                    item.Distance &&
                                                                      item.Distance,
                                                                    item.FinalPositionDataHorse &&
                                                                      item
                                                                        .FinalPositionDataHorse
                                                                        ?.Rank
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {item.Runners}
                                                                </td>
                                                                <td
                                                                  onClick={() =>
                                                                    handleShowHorse(
                                                                      item?.BeatenByData
                                                                        ? item?.BeatenByData
                                                                        : ""
                                                                    )
                                                                  }
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                >
                                                                  {item.BeatenByData ===
                                                                  null ? (
                                                                    <>
                                                                      {cookiedata ===
                                                                      "en"
                                                                        ? item
                                                                            .HorseIDData
                                                                            ?.HorseBeatenByEnglishName
                                                                        : item
                                                                            .HorseIDData
                                                                            ?.HorseBeatenByArabicName}
                                                                    </>
                                                                  ) : (
                                                                    <>
                                                                      {cookiedata ===
                                                                      "en" ? (
                                                                        <>
                                                                          {window.textTransform(
                                                                            item
                                                                              .BeatenByData
                                                                              ?.NameEn
                                                                          )}
                                                                        </>
                                                                      ) : (
                                                                        <>
                                                                          {
                                                                            item
                                                                              .BeatenByData
                                                                              ?.NameAr
                                                                          }
                                                                        </>
                                                                      )}
                                                                    </>
                                                                  )}
                                                                </td>{" "}
                                                                <td>
                                                                  {item.BeatenByData !==
                                                                  null
                                                                    ? item
                                                                        .BeatenByData
                                                                        ?.WinningHorseJockeyWeight
                                                                    : item
                                                                        .HorseIDData
                                                                        ?.BeatenByJockeyWeight ===
                                                                      null
                                                                    ? 0
                                                                    : item
                                                                        .HorseIDData
                                                                        ?.BeatenByJockeyWeight}
                                                                </td>
                                                                <td>
                                                                  {item.RaceResultData ? (
                                                                    item.RaceResultData &&
                                                                    item
                                                                      .RaceResultData
                                                                      .RacehorsesData[0]
                                                                      .HorseNo
                                                                  ) : (
                                                                    <>- </>
                                                                  )}
                                                                </td>
                                                                <td
                                                                  className="rb"
                                                                  onClick={() =>
                                                                    handleShows(
                                                                      item.VideoLink
                                                                    )
                                                                  }
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                >
                                                                  <img
                                                                    src={arrow1}
                                                                    alt=""
                                                                  />
                                                                </td>
                                                              </tr>
                                                            </>
                                                          );
                                                        }
                                                      )
                                                    )}
                                                  </>
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </ScrollContainer>
                                    </div>
                                  </Accordion.Body>
                                )}
                              </div>
                            </Accordion.Item>
                          </Accordion>
                        </>
                      ))}
                  </div>
                ) : (
                  <Lottie
                    animationData={Animate}
                    className="load1"
                    style={{
                      marginTop: "30px",
                    }}
                  />
                )}
              </div>
              <Pagination
                postsPerPage={postsPerPage}
                totalPosts={totalcountdata}
                paginate={paginate}
                currentPage={currentPage}
                TotalPages={totalpagesdata}
              />
            </div>
            {/* <div className="col-sm-4">
              <Justsponsor />
            </div> */}
          </div>
        </div>

        <Modal
          show={show}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="popupheader" closeButton></Modal.Header>
          <Modal.Body>
            <TrainerModal data={modaldata} />
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        <Modal
          show={showJockey}
          onHide={handleCloseJockey}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="popupheader" closeButton></Modal.Header>
          <Modal.Body>
            <JockeyModal data={modaldataJockey} />
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        <Modal
          show={showHorse}
          onHide={handleCloseHorse}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="popupheader" closeButton></Modal.Header>
          <Modal.Body>
            <HorseModal data={modaldataHorse} />
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        <Modal
          show={shows}
          onHide={handleCloses}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="popupheader" closeButton></Modal.Header>
          <Modal.Body>
            <VideoFrame data={modaldatas} />
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        <Modal
          show={showRace}
          onHide={handleCloseRace}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="popupheader" closeButton></Modal.Header>
          <Modal.Body>
            <RaceModal id={modaldataRace} />
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        <Footer />
        <CoptRight />
      </>
    </Fragment>
  );
}
