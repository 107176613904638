import React, { useEffect, useState, Fragment } from "react";
import Footer from "../Components/Reuseable/Footer.jsx";
import CoptRight from "../Components/Reuseable/Copyrights";
import Cookies from "js-cookie";
import "../../Webiste/Components/CSS/pagesCSS/dashboard.css";
import { useTranslation } from "react-i18next";
import "../Components/CSS/RaceCardCSS/racecard.css";
import { useNavigate , Link } from "react-router-dom";
import Lottie from 'lottie-react';
import Animate from "../assets/loader.json";
// import { Accordion } from 'react-bootstrap';
import ExNews from "../Components/Home/Components/ExNews";
import Language from "../Components/Home/Components/Language";
import Notification from "../Components/Home/Components/Notification";
import Search from "../Components/Home/Components/Search";
import {fetchAllNotification} from '../redux/getReducer/getAllNotification.js'
import { useDispatch , useSelector } from "react-redux";
import {MdNotificationsActive} from 'react-icons/md'
import ListGroup from "react-bootstrap/ListGroup";
import logo from "../assets/Frame.png";
import moment from 'moment';
import Pagination from './Pagination.js'

// import { Fragment } from 'react';

// selector
export default function ResultScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userIsDesktop, setUserIsDesktop] = useState(true);
  const { data: AllNotification, status } = useSelector(
    (state) => state.AllNotification
);
const { userInfo } = useSelector((state) => state.user);
const cookietoken = Cookies.get("token");

let totalcountdata = AllNotification ? AllNotification.totalcount : "";
let totalpagesdata = AllNotification ? AllNotification.totalPages : "";
const [currentPage, setCurrentPage] = useState(1);
const [postsPerPage] = useState(11);

const paginate = (pageNumber) => setCurrentPage(pageNumber);


console.log(userInfo,'userInfo')
let token = userInfo ?( userInfo.token === undefined ? cookietoken : userInfo.token) : cookietoken

  useEffect(() => {
    dispatch(fetchAllNotification({token,currentPage}))
  },[dispatch,currentPage])

  function handleRedirect(id) {
    if (userIsDesktop === true) {
      navigate("/racedetail", {
        state: {
          id: id,
        },
      });
    } else {
      navigate("/racedetails", {
        state: {
          id: id,
        },
      });
    }
  }

  function HandleRedirect(route,id) {
    if (userIsDesktop === true) {
      navigate(`${route === null ? '' : route}`, {
        state: {
          id: id,
        },
      });
    } else {
      navigate(`${route === null ? '' : route}`, {
        state: {
          id: id,
        },
      });
    }
  }
  const { t } = useTranslation();
  const cookiedata = Cookies.get("i18next");

  console.log(AllNotification,"AllNotification")

  return (
    <Fragment>
      <>
 
        <div className="container-fluid mystages racecards">
          <div className="row">
            <div className="col-sm-8 stagescenter">
            <div className="raceCarParent stages stages123">
                <div className="raceCardHeader1">
                  <div className="row">
                    <div className="col-sm-10 flex">
                      <h1>{t("Notification")}</h1>
                      <span className="notificationicon1">
                         <MdNotificationsActive />
                      </span>
                    </div>
                  </div>
                </div>
                <ListGroup className="allnotifilcationdata">
                {
                AllNotification.data && AllNotification.data?.length === 0 ? <p>No Notification</p> :  AllNotification.data && AllNotification.data.map((item,index) => {
                    return(
                      <ListGroup.Item className="notificationpanel"  onClick={() => HandleRedirect(item.NotificationRoute, item.NotificationRouteid)}>
                      <img src={logo} alt="" className="notificationimg1" />
                      <div className="notifytext">
                        <h4>{cookiedata === "en" ? item.NotificationTitleEn :  item.NotificationTitleAr}</h4>
                        <p>{cookiedata === "en" ? item.NotificationTextEn :  item.NotificationTextAr}</p>
                      </div>
                      <p className="notifytime">
                      {moment(item.createdAt).fromNow()}
                      </p>
                    </ListGroup.Item>
                    )
                  })
                }
               
               
                
              </ListGroup>
                </div>
            </div>
            <Pagination
                postsPerPage={postsPerPage}
                totalPosts={totalcountdata}
                paginate={paginate}
                currentPage={currentPage}
                TotalPages={totalpagesdata}
              />
          </div>
        </div>

      
        <Footer />
        <CoptRight />
      </>
    </Fragment>
  );
}
