import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getResultSponsorSlice = createSlice({
    name: 'ResultSponsor',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchResultSponsor.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchResultSponsor.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchResultSponsor.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setResultSponsor, setStatus } = getResultSponsorSlice.actions;
export default getResultSponsorSlice.reducer;

export const fetchResultSponsor = createAsyncThunk('/ResultSponsor/fetch', async ({ Value13,  data1,data2,data3,data4,data5,data6,data7,data8,data9,data10,data11,data12,data13,data14,IncreasePage11 }) => {
    const res = await axios.get(`${window.env.API_URL}/ResultSponsor?TitleEn=${Value13}&size=${IncreasePage11}&StartTimeOfRace=${data1}&HorseID=${data2}&TrainerOnRace=${data3}&JockeyOnRace=${data4}&RaceCourseOfRace=${data5}&RaceCourseNationality=${data6}&RaceTypeOfRace=${data7}&RaceGround=${data8}&RaceTracklength=${data9}&OwnerId=${data10}&MeetingType=${data11}&Sponsor=${data12}&HorseKindinRace=${data13}&date=${data14}`);
    const ResultSponsorData = res.data;
    return ResultSponsorData.data;
})
