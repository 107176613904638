import React, { useEffect, useState, Fragment } from "react";
import Layout from "../Components/Reuseable/layout";
import Footer from "../Components/Reuseable/Footer.jsx";
import CoptRight from "../Components/Reuseable/Copyrights";
import Cookies from "js-cookie";
import "../../Webiste/Components/CSS/pagesCSS/dashboard.css";
import { useTranslation } from "react-i18next";
import moment from "moment";

import Defaultimg from "../../Webiste/assets/Shirt.jpg";
import "../Components/CSS/RaceCardCSS/racecard.css";
import { useNavigate } from "react-router-dom";
import { fetchRaceCourseStats } from "../redux/getReducer/getRacecourseStats";
import { useDispatch, useSelector } from "react-redux";
import ExNews from "../Components/Home/Components/ExNews";
import Search from "../Components/Home/Components/Search";
import Notification from "../Components/Home/Components/Notification";
import Language from "../Components/Home/Components/Language";
// import { Accordion } from 'react-bootstrap';
import { BsCalendarDate } from "react-icons/bs";
import CalenderComponent from "../Components/Home/Components/Calendar";
import Justsponsor from "./justsponser";
// import { Fragment } from 'react';

// selector
export default function RaceCourseStats() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [show, setshow] = useState(false);

    const { data: racecoursestats } = useSelector((state) => state.racecoursestats);
    const [userIsDesktop, setUserIsDesktop] = useState(true);
    function handleRedirect(id) {
        if (userIsDesktop === true) {
            navigate("/racedetail", {
                state: {
                    id: id,
                },
            });
        } else {
            navigate("/racedetails", {
                state: {
                    id: id,
                },
            });
        }
    }
    const { t } = useTranslation();
    const cookiedata = Cookies.get("i18next");
 
   
    
    return (
        <Fragment>
            <>

            <div className="rightlayoutinner abouthead dashboardH">
        <ExNews />
        <Search />
        {/* <Tracker /> */}
        <Notification />
        <Language />
        </div>
                {/* <Layout /> */}
                <div className="container-fluid mystages racecards">
                    <div className="row">
                        <div className="col-sm-8 stages"> 
                            <div className="raceCarParent1 atozParent raced">
                                <div className="raceCardHeader1">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h1>{t("Race Course")}</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="posttabs">
                                        <div className="row">
                                        
                                        <p onClick={() => setshow(!show)}>{t("Choose Your Date")}</p> <BsCalendarDate className="chosecalender" onClick={() => setshow(!show)} />
                                        {
                                            show ? <CalenderComponent /> : <></>
                                            }
                                        </div>
                                        </div>
                                <div>
                                    <div className="raceCardTable">
                                        <table className=" tableHeaderCard">
                                            <thead>
                                                <tr key="i">


                                                    <th>{t("Race course")}</th>
                                                    <th>{t("Color")}</th>
                                                    {/* <th>-</th>
                                                    <th>-</th>
                                                    <th>-</th>
                                                    <th>-</th> */}
                                                </tr>
                                            </thead>
                                            {racecoursestats ? (
                                                racecoursestats.map((item, i) => (
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                {item.TrackNameEn}
                                                            </td>
                                                            <td>
                                                                {item.Color}
                                                            </td>
                                                            {/* <td>-</td>
                                                            <td>-</td>

                                                            <td>-</td>

                                                            <td>-</td> */}
                                                        </tr>
                                                    </tbody>
                                                ))
                                            ) : (
                                                <></>
                                            )}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
              <Justsponsor />
            </div>
                    </div>
                </div>

                <Footer />
                <CoptRight />
            </>
        </Fragment>
    );
}
