import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Form, Input } from "antd";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { BsEyeFill } from "react-icons/bs";

function UpdatePassword() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [state, setState1] = useState({ Password: "", ConfirmPassword: "" });
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");


  const [statusData, setstatusData] = useState(false);


  const url = "https://sumairroudani.com/api/v1/"

  const [confimPassword, setConfirmPassword] = useState();
  const [data, setdata] = useState({
    password: null,
  });
  const [currentUrl, setCurrentUrl] = useState("");

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const togglePasswordVisiblity1 = () => {
    setPasswordShown1(passwordShown1 ? false : true);
  };

  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, []);
  const segments = currentUrl.split("/");
  const lastSegment = segments[segments.length - 1];

  const token = params.token;
  const handlechange = (event) => {
    setdata({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setState1({ ...state, Password: newPassword });
    if (newPassword.length < 6) {
      setPasswordError("Password must be at least 6 characters");
    } else {
      setPasswordError("");
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setState1({ ...state, ConfirmPassword: newConfirmPassword });

    // Add your confirm password validation logic here
    if (newConfirmPassword !== state.Password) {
      setConfirmPasswordError("Passwords do not match");
    } else {
      setConfirmPasswordError("");
    }
  };
  

  const SubmitDATA = async (e) => {
    e.preventDefault();
    setstatusData(true)
    try {
      const formData = new FormData();
      formData.append("firstpassword", state.Password);
      formData.append("confirmPassword", state.Password);
      const res = await axios.put(`${url}/password/reset/${lastSegment}`,
        formData
      );
      toast.success("Password Changed Succeessfully");
      setstatusData(false)

      navigate("/login");
    } catch (error) {
      console.log(error,"error")
      const err = error?.response?.data?.message;
      setstatusData(false)

      toast.error(err);
    }
  };

  return (
    <Fragment>
      <div className="mainlogin">
        <div className="registrationform">
          <form>
            <h5>{t("Reset")}</h5>
            <p>{t("Reset Your Password")}</p>
            <div className="form-group aaaaaaa col-md-12">
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Password",
                  },
                ]}
              >
                <Input
                  type={passwordShown ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  autoComplete="off"
                  onChange={handlePasswordChange}
                />
                <span className="showIcon">
                  {" "}
                  <i onClick={togglePasswordVisiblity}>
                    <BsEyeFill />
                  </i>
                </span>
                {passwordError && (
                  <div className="error-message1" style={{ color: "#fff" }}>
                    {passwordError}
                  </div>
                )}
              </Form.Item>
            </div>
            <div className="form-group aaaaaaa">
              <Form.Item
                name="confirmPassword"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your Password",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("The two passwords do not match");
                    },
                  }),
                ]}
              >
                <Input
                  type={passwordShown1 ? "text" : "password"}
                  autoComplete="new-password"
                  placeholder="Confirm Password"
                  value={confimPassword}
                  onChange={handleConfirmPasswordChange}
                />
                <span className="showIcon">
                  {" "}
                  <i onClick={togglePasswordVisiblity1}>
                    <BsEyeFill />
                  </i>
                </span>
                {confirmPasswordError && (
                  <div className="error-message1" style={{ color: "#fff" }}>
                    {confirmPasswordError}
                  </div>
                )}
              </Form.Item>
            </div>
            <button onClick={SubmitDATA} disabled={confirmPasswordError === "" ? false : true } className="buttonRegister">
              {t("submit")}
            </button>

            {/* <hr/> */}
          </form>
        </div>
      </div>
    </Fragment>
  );
}

export default UpdatePassword;
