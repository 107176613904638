import { useEffect, useState } from "react";
import Layout from "../Components/Reuseable/layout";
import "../Components/CSS/registration.css";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";
import images from "../assets/registration.png";
import Logo from "../assets/logo-resizr.png";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { DatePicker, Space } from 'antd';

import { BsEye, BsEyeFill } from 'react-icons/bs';


import { Country_Name, New_Country } from "../Components/Reuseable/Country";

const RegisterScreen = () => {
  const dispatch = useDispatch();
  const cookiedata = Cookies.get("i18next");
  const { t } = useTranslation();
  const [passwordShown, setPasswordShown] = useState(false);
  const [password, setpassword] = useState("");
  const [passwordMatch, setpasswordMatch] = useState(false);
  const [profileNumberData, setprofileNumberData] = useState("");


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setprofileNumberData(data)
    setShow(true)
  };


  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const [formValues, setFormValues] = useState({
    FirstName: "",
    LastName: "",
    PassportNo: "",
    password: "",
    PhoneNumber: "",
    Email: "",
    Address: "",
    NationalityID: "",
    DOB: "",
    errors: {},
  });
  const {
    FirstName,
    LastName,
    PassportNo,
    Email,
    Address,
    NationalityID,
    PhoneNumber,
    errors,
    DOB,
  } = formValues;

  const [passportNumber, setPassportNumber] = useState("");
  const [isValidPassport, setIsValidPassport] = useState(true);


  const handlePassportNumberChange = (e) => {
    const newValue = e.target.value;
    setPassportNumber(newValue);
  
    // Define your passport number regular expression pattern
    const passportNumberPattern = /^[A-Z0-9]{6,15}[A-Z]*$/;
  
    // Test if the input matches the pattern
    const isValid = passportNumberPattern.test(newValue);
    setIsValidPassport(isValid);
  };

  const [state, setState1] = useState({ Password: "", ConfirmPassword: "" });
  const [passwordError, setPasswordError] = useState("");
  const [DOBData, setDOBData] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setState1({ ...state, Password: newPassword });
    if (newPassword.length < 6) {
      setPasswordError("Password must be at least 6 characters");
    } else {
      setPasswordError("");
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setState1({ ...state, ConfirmPassword: newConfirmPassword });

    // Add your confirm password validation logic here
    if (newConfirmPassword !== state.Password) {
      setpasswordMatch(true)
      setConfirmPasswordError("Passwords do not match");
    } else {
      setConfirmPasswordError("");
      setpasswordMatch(false)

    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const [PassportPicture, setPassportPicture] = useState();
  const [ProfilePicture, setProfilePicture] = useState();

  const [preview, setPreview] = useState();
  const [preview1, setPreview1] = useState();

  const onSelectFileProfile = (e) => {
    setProfilePicture(e.target.files[0]);
  };

  const onSelectFilePassport = (e) => {
    setPassportPicture(e.target.files[0]);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (!ProfilePicture) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(ProfilePicture);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [ProfilePicture]);

  useEffect(() => {
    if (!PassportPicture) {
      setPreview1(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(PassportPicture);
    setPreview1(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [PassportPicture]);

  const [validated, setValidated] = useState(false);

  const showToast = (message) => {
    toast.dismiss(); // Clear any existing toasts
    toast.error(message);
  };

  
  const onChange = (date, dateString) => {
    console.log( dateString);
    setDOBData(dateString)
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false || passwordMatch === true) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else if (isValidPassport) {
      try {
        const formData = new FormData();
        formData.append("FirstName", FirstName);
        formData.append("LastName", LastName);
        formData.append("PassportNo", passportNumber);
        formData.append("PhoneNumber", PhoneNumber);
        formData.append("password", state.Password);
        formData.append("Email", Email);
        formData.append(
          "Nationality",
          NationalityID === "" ? "United Arab Emirates" : NationalityID
        );
        formData.append("Address", Address);
        formData.append("DOB", DOBData);
        formData.append("PassportPicture", PassportPicture);
        formData.append("ProfilePicture", ProfilePicture);

        const res = await axios.post(
          `${window.env.API_URL}/register`,
          formData
        );
        Cookies.set("token", res.data.token);
        //  Cookies.set('id', res.data.user._id)
        // toast.success("Successfuly Registered");
        navigate("/verifyemail");
      } catch (error) {
        console.log(error, "error");
        showToast(error?.response?.data?.message);

      }
    }
  };

  const removeImagePreview = () => {
    setProfilePicture(null);
    setPreview();
    // document.getElementById("file").value = "";
  };



  const removeImagePassPreview = () => {
    setPassportPicture(null);
    setPreview1();
    // document.getElementById("file").value = "";
  };

  return (
    <>
      <div className="container-fluid register registerpage">
        <div className="row">
          <div className="col-sm-6">
            <div className="registration">
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                {/* <img src={Logo} alt=""/> */}
                <h5>
                  <b> {t("Sign Up")}</b>& 
                  {t("Be A Part of Saddle Battles")}
                </h5>

                <div className="row">
                  <div className="col-sm-6">
                    <Form.Group controlId="validationCustom03">
                      <Form.Label>{t("FirstName")}</Form.Label>
                      <Form.Control
                        name="FirstName"
                        type="text"
                        placeholder={t("FirstName")}
                        value={FirstName}
                        onChange={handleChange}
                        required
                        className="autofill-override" // Apply the CSS class here

                      />
                      <Form.Control.Feedback type="invalid">
                        {t("Please provide First Name.")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="validationCustom03">
                      <Form.Label>{t("LastName")}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("LastName")}
                        value={LastName}
                        name="LastName"
                        onChange={handleChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {t("Please provide Last Name.")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="validationCustom04">
                      <Form.Label>{t("Email")}</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder={t("Email")}
                        value={Email}
                        name="Email"
                        onChange={handleChange}
                        required
                        autocomplete="off"
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(" Please provide a valid Email.")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="validationCustom05">
                      <Form.Label>{t("PassportNo")}</Form.Label>
                      <Form.Control
                        // onKeyPress={(event) => {
                        //   if (!/[0-9.]/.test(event.key)) {
                        //     event.preventDefault();
                        //   }
                        // }}
                        type="text"
                        value={passportNumber}
                        name="PassportNo"
                        placeholder={t("PassportNo")}
                        onChange={handlePassportNumberChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {t("Please provide valid PassportNo.")}
                      </Form.Control.Feedback>

                      {isValidPassport ? (
                        ""
                      ) : (
                        <div className="error-message1">
                          {t("PassportNo should be 6-15 length with Capital Letter")} 
                        </div>
                      )}
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="validationCustom05">
                      <Form.Label>{t("PhoneNumber")}</Form.Label>
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        type="text"
                        name="PhoneNumber"
                        placeholder={t("PhoneNumber")}
                        onChange={handleChange}
                        value={PhoneNumber}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {t("Please provide valid PhoneNumber.")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="validationCustom05">
                      <Form.Label>{t("Date of Birth")}</Form.Label><br />
                      <DatePicker onChange={onChange} />

                      <Form.Control.Feedback type="invalid">
                        {t("Please provide Date of Birth.")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="validationCustom05">
                      <Form.Label>{t("Address")}</Form.Label>
                      <Form.Control
                        name="Address"
                        type="text"
                        placeholder={t("Address")}
                        value={Address}
                        onChange={handleChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {t("Please provide Address.")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="validationCustom05">
                      <Form.Label>{t("Nationality")}</Form.Label>
                      <Form.Select
                        name="NationalityID"
                        value={NationalityID}
                        onChange={handleChange}
                        required
                      >
                        {Country_Name.map((item, i) => {
                          return (
                            <option value={item.country_name}>
                              {item.country_name}
                            </option>
                          );
                        })}
                      </Form.Select>

                      <Form.Control.Feedback type="invalid">
                        {t("Please provide Nationality.")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="validationCustom05">
                      <Form.Label>{t("Password")}</Form.Label>
                      <Form.Control
                        value={state.Password}
                        onChange={handlePasswordChange}
                        name="password"
                        type={passwordShown ? "text" : "password"}
                        placeholder={t("Password")}
                        required
                        autocomplete="off"
                      />
                      {/* <span className='showIcon'> <i onClick={togglePasswordVisiblity}><BsEyeFill /></i></span> */}

                      <Form.Control.Feedback type="invalid">
                        {t("Please provide Password.")}
                      </Form.Control.Feedback>
                      {passwordError && (
                        <div
                          className="error-message1"
                          style={{ color: "#fff" }}
                        >
                          {passwordError}
                        </div>
                      )}
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="validationCustom05">
                      <Form.Label>{t("Confirm Password")}</Form.Label>
                      <Form.Control
                        value={state.ConfirmPassword}
                        onChange={handleConfirmPasswordChange}
                        name="password"
                        type={passwordShown ? "text" : "password"}
                        placeholder={t("Confirm Password")}
                        required
                      />
                       {/* <span className='showIcon'> <i onClick={togglePasswordVisiblity}><BsEyeFill /></i></span> */}

                      <Form.Control.Feedback type="invalid">
                        {t("Please provide Password.")}
                      </Form.Control.Feedback>
                      {confirmPasswordError && (
                        <span
                          className="error-message1"
                          style={{ color: "#fff" }}
                        >
                          {confirmPasswordError}
                        </span>
                      )}
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="validationCustom05">
                      <Form.Label>{t("Profile Picture")}</Form.Label>
                      <Form.Control
                        onChange={onSelectFileProfile}
                        type="file"
                      />
                      <Form.Control.Feedback type="invalid">
                        {t("Please provide Profile Picture.")}
                      </Form.Control.Feedback>
                    </Form.Group>
                    {ProfilePicture && (
                      <>
                        <img src={preview} className="PreviewImage" alt=""  />
                        <i
                          className="fa fa-times"
                          onClick={removeImagePreview}
                        ></i>
                      </>
                    )}
                  </div>

                  <div className="col-sm-6 passports">
                    <Form.Group controlId="validationCustom05">
                      <Form.Label>{t("Passport Picture")}</Form.Label>
                      <Form.Control
                        onChange={onSelectFilePassport}
                        type="file"
                      />
                      <Form.Control.Feedback type="invalid">
                        {t("Please provide Passport Picture.")}
                      </Form.Control.Feedback>
                    </Form.Group>
                    {PassportPicture && (
                      <div>
                        <img src={preview1} className="PreviewImage" alt="" />
                        <i
                          className="fa fa-times"
                          onClick={removeImagePassPreview}
                        ></i>
                      </div>
                    )}
                  </div>

                  <div className="submitbtn1 col-sm-12">
                    <Form.Group controlId="validationCustom05">
                      <Button type="submit">{t("RegisterNow")}</Button>
                    </Form.Group>
                  </div>
                </div>
              </Form>
            </div>
          </div>

          <div className="col-sm-6 registrationimg">
            <img src={images} alt="asd" />
          </div>
        </div>
      </div>

      <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      onClick={handleClose} // Close the modal when clicking outside
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <img src={profileNumberData} alt="" className="modalimg"/>
      </Modal.Body>
    </Modal>
    </>
  );
};

export default RegisterScreen;
  