import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});


const getOwnerEntriesSlice = createSlice({
    name: 'entriesowner',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchOwnerEntries.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchOwnerEntries.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchOwnerEntries.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setTrainer, setStatus } = getOwnerEntriesSlice.actions;
export default getOwnerEntriesSlice.reducer;

export const fetchOwnerEntries = createAsyncThunk('/FutureEntriesWithOwner/fetch', async ({ id }) => {
    const res = await axios.get(`${window.env.API_URL}/FutureEntriesWithOwner/${id}`);
    const OwnerEntriesData = res.data;
    return OwnerEntriesData.data;
})