import Ain from '../assets/Gif1000/Al Ain 1000 Gif.gif';
import Jeb from '../assets/Gif1000/Jeb1200Dirt.gif';
import Mey from '../assets/Gif1000/Mey1200Dirt.gif';
import Shj from '../assets/Gif1000/Shj1200Dirt.gif';
import AbuDhani from '../assets/Gif1000/Abu Dhabi 1300 Gif.gif';


export const News = [
  {
    id: "1",
    title: "Exclusive",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
  },
];
export const RaceCard = [
  {
    id: "1",
    clubname: "Abu Dhabi Club",
    owner: "Al Bithnah",
    raceNo: "4",
    distance: "340 m",
    time: "5m",
  },
  {
    id: "2",
    clubname: "Abu Dhabi Club",
    owner: "Al Bithnah",
    raceNo: "4",
    distance: "340 m",
    time: "5m",
  },
  {
    id: "3",
    clubname: "Abu Dhabi Club",
    owner: "Al Bithnah",
    raceNo: "4",
    distance: "340 m",
    time: "5m",
  },
  {
    id: "4",
    clubname: "Abu Dhabi Club",
    owner: "Al Bithnah",
    raceNo: "4",
    distance: "340 m",
    time: "5m",
  },
  {
    id: "5",
    clubname: "Abu Dhabi Club",
    owner: "Al Bithnah",
    raceNo: "4",
    distance: "340 m",
    time: "5m",
  },
  {
    id: "6",
    clubname: "Abu Dhabi Club",
    owner: "Al Bithnah",
    raceNo: "4",
    distance: "340 m",
    time: "5m",
  },
  {
    id: "7",
    clubname: "Abu Dhabi Club",
    owner: "Al Bithnah",
    raceNo: "4",
    distance: "340 m",
    time: "5m",
  },
];
export const RaceCourse = [
  {
    id: "1",
    name: "Jebel Ali Racecourse",
    raceNo: "08",
    matches: [
      {
        id: "4.25",
        name: "Cobram",
      },
      {
        id: "4.25 ",
        name: "Riverina Paceway",
      },
      {
        id: "3.54",
        name: "Mildura",
      },
      {
        id: "4.34",
        name: "Albion Park",
      },
      {
        id: "5.11",
        name: "Dubbo",
      },
      {
        id: "6.24",
        name: "Riverina Paceway",
      },
      {
        id: "4.32",
        name: "Mildura",
      },
      {
        id: "4.32",
        name: "Newcastle",
      },
    ],
  },
  {
    id: "2",
    name: "Sharjah & Racing Club",
    raceNo: "05",
    matches: [
      {
        id: "1",
        name: "Cobram",
      },
      {
        id: "2",
        name: "Cobram",
      },
      {
        id: "3",
        name: "Cobram",
      },
      {
        id: "4",
        name: "Cobram",
      },
      {
        id: "5",
        name: "Cobram",
      },
    ],
  },
  {
    id: "3",
    name: "Al Ain Racecourse",
    raceNo: "03",
    matches: [
      {
        id: "1",
        name: "Cobram",
      },
      {
        id: "2",
        name: "Cobram",
      },
      {
        id: "3",
        name: "Cobram",
      },
    ],
  },
  {
    id: "4",
    name: "Jinma Lake Racecourse",
    raceNo: "08",
    matches: [
      {
        id: "1",
        name: "Cobram",
      },
      {
        id: "2",
        name: "Riverina Paceway",
      },
      {
        id: "3",
        name: "Mildura",
      },
      {
        id: "4",
        name: "Albion Park",
      },
      {
        id: "5",
        name: "Dubbo",
      },
      {
        id: "6",
        name: "Riverina Paceway",
      },
      {
        id: "7",
        name: "Mildura",
      },
      {
        id: "8",
        name: "Newcastle",
      },
    ],
  },
];

export const LeaderBoard1 = [
  {
    id: "1",
    name: "Competition One",
    raceNo: "01",
    matches: [
      {
        id: "4.25",
        name: "Winner Name",
        namear: "اسم المسابقة",
      },
      {
        id: "4.25 ",
        name: "Competition Name",
        namear: "اسم المسابقة",
      },
      {
        id: "3.54",
        name: "Competition Name",
        namear: "اسم المسابقة",
      },
      {
        id: "4.34",
        name: "Competition Name",
        namear: "اسم المسابقة",
      },
      {
        id: "5.11",
        name: "Competition Name",
        namear: "اسم المسابقة",
      },
      {
        id: "6.24",
        name: "Competition Name",
        namear: "اسم المسابقة",
      },
      {
        id: "4.32",
        name: "Competition Name",
        namear: "اسم المسابقة",
      },
      {
        id: "4.32",
        name: "Competition Name",
        namear: "اسم المسابقة",
      },
    ],
  },
];
export const BlogData = [
  {
    id: "1",
    image:
      "https://images.pexels.com/photos/1996333/pexels-photo-1996333.jpeg?auto=compress&cs=tinysrgb&w=1600",
    TitleEn: "Consectetur adipiscing elit, sed do eiusmod tempor.",
    DescriptionEn:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
  },
  {
    id: "2",
    image:
      "https://images.pexels.com/photos/459124/pexels-photo-459124.jpeg?auto=compress&cs=tinysrgb&w=1600",
    TitleEn: "Consectetur adipiscing elit, sed do eiusmod tempor.",
    DescriptionEn:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
  },
  {
    id: "3",
    image:
      "https://images.pexels.com/photos/1996330/pexels-photo-1996330.jpeg?auto=compress&cs=tinysrgb&w=1600",
    TitleEn: "Consectetur adipiscing elit, sed do eiusmod tempor.",
    DescriptionEn:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
  },
  {
    id: "4",
    image:
      "https://images.pexels.com/photos/635499/pexels-photo-635499.jpeg?auto=compress&cs=tinysrgb&w=1600",
    TitleEn: "Consectetur adipiscing elit, sed do eiusmod tempor.",
    DescriptionEn:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
  },
  {
    id: "5",
    image:
      "https://images.pexels.com/photos/1996333/pexels-photo-1996333.jpeg?auto=compress&cs=tinysrgb&w=1600",
    TitleEn: "Consectetur adipiscing elit, sed do eiusmod tempor.",
    DescriptionEn:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
  },
];
export const MatchData = [
  {
    id: "1",
    name: "Riverina Paceway",
    raceNo: "4",
    totalRunner: "09",
    distance: "340 m",
    offtime: "20:23:08 h",
    time: "25",
    surface: "Truf",
    flat: "Flat",
    going: "Good",
    player: [
      {
        id: "1",
        position: "1",
        name: "   Jacovec Cavern (7)",
        win: "14:00",
        place: "2.6",
        win: "14.4",
      },
      {
        id: "2",
        position: "2",
        name: "Lexington Knight (5)",

        place: "4.2",
      },
      {
        id: "3",
        position: "3",
        name: "Amalfi Bay (1)",

        place: "2",
      },
    ],
  },
];
export const CurrentData = [
  {
    id: "1",
    countryName: "United Arab Emirates",
    match1: "UAE result match # 1",
    match2: "UAE result match # 2",
    match3: "UAE result match # 3",
  },
  {
    id: "2",
    countryName: "United State of America",
    match1: "USA result match # 1",
    match2: "USA result match # 2",
    match3: "USA result match # 3",
  },
  {
    id: "3",
    countryName: "Kingdom of Saudia",
    match1: "KSA result match # 1",
    match2: "KSA result match # 2",
    match3: "KSA result match # 3",
  },
];
export const RaceCardData = [
  {
    id: "1",
    racename: "Riverina Paceway",
    raceNo: "4",
    time: "Friday 26 AUG 2022",
    raceStatus: [
      {
        id: "1",
        status: "due",
      },
      {
        id: "2",
        status: "live",
      },
      {
        id: "3",
        status: "end",
      },
      {
        id: "4",
        status: "can",
      },
    ],
    races: [
      {
        id: "1",
        clubname: "Abu Dhabi Club",
        owner: "Al Bithnah",
        detail:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.",
        raceNo: "2",
        distance: "3400 m",
        time: "19:35",
        surface: "Truf",
        flat: "Flat",
        going: "Good",
        runner: "11",
        Favourite: "Notre Dame",
        status: "due",
      },
      {
        id: "2",
        clubname: "Abu Dhabi Club",
        owner: "Worcester",
        detail: "For 4 year olds and upwards",
        raceNo: "2",
        distance: "3400 m",
        time: "16:35",
        surface: "Truf",
        flat: "Race",
        going: "Good",
        runner: "11",
        Favourite: "Notre Dame",
        status: "live",
      },
      {
        id: "3",
        clubname: "Abu Dhabi Club",
        detail: "For 4 year olds and upwards",
        raceNo: "2",
        owner: "Al Bithnah",
        distance: "3400 m",
        time: "1:35",
        surface: "Truf",
        flat: "Flat",
        going: "Good",
        runner: "11",
        Favourite: "Notre Dame",
        status: "cancel",
      },
      {
        id: "4",
        clubname: "Abu Dhabi Club",
        detail: "For 4 year olds and upwards",
        raceNo: "2",
        distance: "3400 m",
        owner: "Al Faetha",
        time: "16:30",
        surface: "Truf",
        flat: "Flat",
        going: "Good",
        runner: "11",
        Favourite: "Notre Dame",
        status: "end",
      },
      {
        id: "5",
        clubname: "Abu Dhabi Club",
        detail: "For 4 year olds and upwards",
        raceNo: "2",
        distance: "3400 m",
        owner: "Al Bithnah",
        time: "16:05",
        surface: "Truf",
        flat: "Flat",
        going: "Good",
        runner: "11",
        Favourite: "Notre Dame",
        status: "live",
      },
    ],
    Prizes: [
      {
        id: "1",
        position: "1st",
        image: "../assets/image 10 (1)",
        prize: "50,000AED",
      },

      {
        id: "2",
        position: "2nd",
        image: ".secondprize.png",
        prize: "45,000AED",
      },
      {
        id: "3",
        position: "3rd",
        image: "secondprize.png",
        prize: "40,000AED",
      },
      {
        id: "4",
        position: "4th",
        image: "secondprize.png",
        prize: "35,000AED",
      },
      {
        id: "5",
        position: "5th",
        image: "secondprize.png",
        prize: "30,000AED",
      },
      {
        id: "6",
        position: "6th",
        image: "secondprize.png",
        prize: "25,000AED",
      },
    ],
    predictor: [
      {
        id: "1",
        name: "Anti Nokika",
        position: "1",
        score: "444",
      },
      {
        id: "2",
        name: "Miss Alice",
        position: "2",
        score: "344",
      },
      {
        id: "3",
        name: "Filiq Alice",
        position: "7",
        score: "10",
      },
      {
        id: "4",
        name: "Miss Alice",
        position: "5",
        score: "44",
      },
      {
        id: "5",
        name: "Danging lice",
        position: "6",
        score: "40",
      },
      {
        id: "6",
        name: " Alice John",
        position: "4",
        score: "144",
      },
      {
        id: "7",
        name: "Mishla Alice",
        position: "3",
        score: "244",
      },
    ],
  },
];
export const StatsData = [
  {
    id: "1",
    Trainer: "Miss Alice ",
    Runs: "22",
    Wins: "14",
    Places: "7",
    Win: "16.25%",
    Place: "64.26%",
    Win: "9.54",
    EW: "3.9",
    AE: "1.22",
    IV: "7.99",
  },
  {
    id: "2",
    Trainer: "Miss Alice ",
    Runs: "22",
    Wins: "8",
    Places: "7",
    Win: "16.25%",
    Place: "64.26%",
    Win: "9.54",
    EW: "3.9",
    AE: "1.22",
    IV: "7.99",
  },
  {
    id: "3",
    Trainer: "Miss Alice ",
    Runs: "22",
    Wins: "6",
    Places: "7",
    Win: "16.25%",
    Place: "64.26%",
    Win: "9.54",
    EW: "3.9",
    AE: "1.22",
    IV: "7.99",
  },
  {
    id: "4",
    Trainer: "Miss Alice ",
    Runs: "22",
    Wins: "24",
    Places: "7",
    Win: "16.25%",
    Place: "64.26%",
    Win: "9.54",
    EW: "3.9",
    AE: "1.22",
    IV: "7.99",
  },
  {
    id: "5",
    Trainer: "Miss Alice ",
    Runs: "22",
    Wins: "5",
    Places: "7",
    Win: "16.25%",
    Place: "64.26%",
    Win: "9.54",
    EW: "3.9",
    AE: "1.22",
    IV: "7.99",
  },
];
export const HorseCardData = [
  {
    id: "1",
    racename: "Riverina Paceway",
    raceNo: "4",
    time: "Friday 26 AUG 2022",
    raceStatus: [
      {
        id: "1",
        status: "due",
      },
      {
        id: "2",
        status: "live",
      },
      {
        id: "3",
        status: "end",
      },
      {
        id: "4",
        status: "can",
      },
    ],
    races: [
      {
        id: "1",
        clubname: "Abu Dhabi Club",
        owner: "Al Bithnah",
        detail:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.",
        raceNo: "2",
        distance: "3400 m",
        time: "19:35",
        surface: "Truf",
        flat: "Flat",
        going: "Good",
        runner: "11",
        Favourite: "Notre Dame",
        status: "due",
      },
    ],
    Prizes: [
      {
        id: "1",
        position: "1st",
        image: "../assets/image 10 (1)",
        prize: "50,000AED",
      },

      {
        id: "2",
        position: "2nd",
        image: ".secondprize.png",
        prize: "45,000AED",
      },
      {
        id: "3",
        position: "3rd",
        image: "secondprize.png",
        prize: "40,000AED",
      },
      {
        id: "4",
        position: "4th",
        image: "secondprize.png",
        prize: "35,000AED",
      },
      {
        id: "5",
        position: "5th",
        image: "secondprize.png",
        prize: "30,000AED",
      },
      {
        id: "6",
        position: "6th",
        image: "secondprize.png",
        prize: "25,000AED",
      },
    ],
    predictor: [
      {
        id: "1",
        name: "Anti Nokika",
        position: "1",
        score: "444",
      },
      {
        id: "2",
        name: "Miss Alice",
        position: "2",
        score: "344",
      },
      {
        id: "3",
        name: "Filiq Alice",
        position: "7",
        score: "10",
      },
      {
        id: "4",
        name: "Miss Alice",
        position: "5",
        score: "44",
      },
      {
        id: "5",
        name: "Danging lice",
        position: "6",
        score: "40",
      },
      {
        id: "6",
        name: " Alice John",
        position: "4",
        score: "144",
      },
      {
        id: "7",
        name: "Mishla Alice",
        position: "3",
        score: "244",
      },
    ],
  },
];

export const QueryTool1 = [
  {
    id: "1",
    name: "",
  },
  {
    id: "2",
    name: "",
  },
  {
    id: "3",
    name: "",
  },
  {
    id: "4",
    name: "",
  },
  {
    id: "5",
    name: "",
  },
  {
    id: "6",
    name: "",
  },
  {
    id: "7",
    name: "",
  },
  {
    id: "8",
    name: "",
  },
  {
    id: "9",
    name: "",
  },
  {
    id: "10",
    name: "",
  },
  {
    id: "111",
    name: "",
  },
  {
    id: "12",
    name: "",
  },
];

export const HorseDetail1 = [
  {
    id: "1",
    Date: "12 Oct 22",
    Cr: "Wol (T)",
    Dist: "2400",
    TC: "D",
    Type: "Novice",
    Dts: "02:05:55",
    time: "Miss",
    Jockey: "58",
    Wgt: "6",
    FP: "16.25",
    Les: "5",
    FP: "67",
    RS: "5",
  },
  {
    id: "1",
    Date: "12 Oct 22",
    Cr: "Wol (T)",
    Dist: "2400",
    TC: "D",
    Type: "Novice",
    Dts: "02:05:55",
    time: "Miss",
    Jockey: "58",
    Wgt: "6",
    FP: "16.25",
    Les: "5",
    FP: "67",
    RS: "5",
  },
  {
    id: "2",
    Date: "12 Oct 22",
    Cr: "Wol (T)",
    Dist: "2400",
    TC: "D",
    Type: "Novice",
    Dts: "02:05:55",
    time: "Miss",
    Jockey: "58",
    Wgt: "6",
    FP: "16.25",
    Les: "5",
    FP: "67",
    RS: "5",
  },
  {
    id: "3",
    Date: "12 Oct 22",
    Cr: "Wol (T)",
    Dist: "2400",
    TC: "D",
    Type: "Novice",
    Dts: "02:05:55",
    time: "Miss",
    Jockey: "58",
    Wgt: "6",
    FP: "16.25",
    Les: "5",
    FP: "67",
    RS: "5",
  },
  {
    id: "4",
    Date: "12 Oct 22",
    Cr: "Wol (T)",
    Dist: "2400",
    TC: "D",
    Type: "Novice",
    Dts: "02:05:55",
    time: "Miss",
    Jockey: "58",
    Wgt: "6",
    FP: "16.25",
    Les: "5",
    FP: "67",
    RS: "5",
  },
];

export const raceCourseData = {
  option1: {
    heading: "About the Meydan Racecourse",
    headingAr: "نبذة عن مضمار ميدان",
    image:Mey,
    content:
      "The Meydan Grandstand and Racecourse are poised to become a prominent business and conference hub, seamlessly connecting lifestyle, entertainment, and sports. It sprawls across a linear landscraper spanning 1.6 kilometers and boasts a maximum capacity to host up to 60,000 individuals. \n At its pinnacle, the Meydan Grandstand boasts a striking cantilevered Crescent Roof adorned with curved solar and titanium panels, enhancing the building's energy efficiency. This iconic structure represents a distinctive and intelligent development, housing world-class hospitality amenities tailored to cater to horsemen, patrons, travelers, and visitors alike",
      contentAr:
      "من المتوقع أن يصبح ميدان الميدان ومضمار السباق مركزًا بارزًا للأعمال والمؤتمرات، حيث يربط بين نمط الحياة والترفيه والرياضة بسلاسة. يمتد على مساحة 1.6 كيلومتر ويتسع لاستضافة ما يصل إلى 60 ألف فرد. \n في قمته، يتميز مدرج الميدان بسقف هلالي مذهل مزين بألواح شمسية وألواح تيتانيوم منحنية، مما يعزز كفاءة استخدام الطاقة في المبنى. يمثل هذا الهيكل المميز تطورًا مميزًا وذكيًا، ويضم مرافق ضيافة عالمية المستوى مصممة خصيصًا لتلبية احتياجات الفرسان والرعاة والمسافرين والزوار على حدٍ سواء.      ",
    content1:
      "Among its notable features is the world's inaugural five-star trackside hotel, The Meydan, offering unobstructed vistas of the racetracks. The facility also houses a diverse range of dining and beverage options, a covered parking area accommodating up to 8,600 vehicles, the Meydan Museum and Gallery, an IMAX Theatre, and the Meydan Marina.\n Having opened its doors for the racing season on January 28, 2010, the Meydan Grandstand and Racecourse are renowned as the new home of the Dubai World Cup, scheduled for March 26, 2022. The staggering prize money of US$ 35 million attracts the finest racehorses from around the globe.\n Enterprises and high-net-worth individuals can immerse themselves in the excitement from one of the 78 exclusive Meydan Grandstand Corporate Suites, promising guests unparalleled views, luxurious comfort, and integrated technology for the ultimate horseracing experience.\n Conceived from the vision of His Highness Sheikh Mohammed bin Rashid Al Maktoum, UAE Vice President and Prime Minister and Ruler of Dubai, the Meydan Grandstand and Racecourse represent the epitome of a global sporting destination.\n Key Facts",
    
      content1Ar:
      "ومن بين معالمه البارزة هو فندق الخمس نجوم الافتتاحي في العالم، الميدان، الذي يوفر مناظر خالية من العوائق على حلبات السباق. يضم المرفق أيضًا مجموعة متنوعة من خيارات تناول الطعام والمشروبات، ومنطقة مغطاة لوقوف السيارات تتسع لما يصل إلى 8600 مركبة، ومتحف ومعرض الميدان، ومسرح آيماكس، ومرسى الميدان.\n بعد أن فتح أبوابه لموسم السباق في يناير اعتبارًا من 28 أكتوبر 2010، أصبح مدرج الميدان ومضمار السباق المقر الجديد لكأس دبي العالمي، المقرر عقده في 26 مارس 2022. وتجذب الجوائز المالية المذهلة البالغة 35 مليون دولار أمريكي أرقى خيول السباق من جميع أنحاء العالم.\n الشركات و يمكن للأفراد من ذوي الثروات العالية أن ينغمسوا في الإثارة في واحد من 78 جناحًا حصريًا للشركات في ميدان جراند ستاند، مما يعد الضيوف بمناظر لا مثيل لها، وراحة فاخرة، وتكنولوجيا متكاملة لتجربة سباق الخيل المثالية.\n مستوحاة من رؤية صاحب السمو الشيخ صاحب السمو الشيخ محمد بن راشد آل مكتوم، نائب رئيس الدولة رئيس مجلس الوزراء حاكم دبي، يمثل مدرج الميدان ومضمار السباق مثالاً للوجهة الرياضية العالمية.\n حقائق أساسية",
    
    
      listContent: [
      "Size of the Racecourse District: 67 million square feet",
      "The Meydan Hotel: Adjacent to the Grandstand, with over 95% of its 290 rooms and suites offering direct, unobstructed racetrack views.",
      "Grandstand Capacity: Tiered seating for 20,000 spectators and an overall capacity exceeding 60,000.",
      "Total Grandstand Building Length: 1.6 kilometers, including the Boathouse and receiving barns.",
      "Total Car Park Wingspan (Resembling a Falcon): 1.3 kilometers",
      "Car Park Capacity: Up to 8,600 cars",
      "Tracks: A 1750-meter (8.75 furlongs) Dirt surface track and a 2400-meter (12 furlongs) Turf track",
    ],

    listContentAr: [
      "مساحة منطقة مضمار السباق: 67 مليون قدم مربع",
      "فندق الميدان: يقع بجوار المدرج، حيث يوفر أكثر من 95% من غرفه وأجنحته البالغ عددها 290 غرفة إطلالات مباشرة ودون عائق على مضمار السباق.",
      "سعة المدرج: مقاعد متدرجة تتسع لـ 20.000 متفرج وسعة إجمالية تزيد عن 60.000.      ",
      "إجمالي طول مبنى المدرج: 1.6 كيلومتر، بما في ذلك المرفأ وحظائر الاستقبال.",
      "إجمالي جناحي موقف السيارات (الذي يشبه الصقر): 1.3 كيلومتر",
      "سعة موقف السيارات: حتى 8,600 سيارة",
      "المسارات: مسار ترابي بطول 1750 مترًا (8.75 فيرلنغ) ومسار عشبي بطول 2400 متر (12 فيرلنغ)      ",
    ],

    content3: "Other Notable Features Surrounding the Grandstand Include",
    content3Ar: "تشمل الميزات البارزة الأخرى المحيطة بالمدرج",

    listContent1: [
      "World-class horseracing facilities such as receiving barns and stables for horses.",
      "All-weather surface practice track.",
      "Jockey and horsemen's lounges, among other amenities.",
    ],
    listContent1Ar: [
      "مرافق عالمية المستوى لسباقات الخيل مثل استقبال الحظائر والإسطبلات الخاصة بالخيول.",
      "مسار تدريب سطحي مناسب لجميع الأحوال الجوية.",
      "صالات للخيالة والفرسان، من بين وسائل الراحة الأخرى.",
    ],
  },



  option2: {
    heading: "About the Equestrian Club",
    headingAr: "نبذة عن نادي الفروسية",
    image:AbuDhani,
    content:
      "The Abu Dhabi Equestrian Club boasts a storied history that dates back to its origins in 1976 when it was established as a riding club under the esteemed patronage of The President, His Highness Sheikh Zayed bin Sultan Al Nahyan. This auspicious beginning laid the foundation for an enduring legacy in the world of equestrian sports.\n The pivotal year of 1980 witnessed the laying of a grass-surfaced racetrack, setting the stage for a new era of horse racing. However, it wasn't until 1991 that the club opened its doors to open racing events, marking a significant milestone in its evolution.",
    
      contentAr:
      "يتمتع نادي أبوظبي للفروسية بتاريخ حافل يعود إلى بداياته في عام 1976 عندما تم تأسيسه كنادٍ للفروسية تحت رعاية كريمة من رئيس الدولة صاحب السمو الشيخ زايد بن سلطان آل نهيان. وضعت هذه البداية الميمونة الأساس لإرث دائم في عالم رياضات الفروسية.\n شهد عام 1980 المحوري وضع مضمار سباق على العشب، مما مهد الطريق لعصر جديد من سباقات الخيل. ومع ذلك، لم يفتح النادي أبوابه أمام أحداث السباق إلا في عام 1991، مما يمثل علامة فارقة في تطوره.",
   
      content1:
      "Before embracing open racing, the club had been known for hosting exclusive gatherings for Arabian horses, often coinciding with important and memorable occasions.\nIn 1994, in alignment with the visionary directives of His Highness The President, a sweeping modernization endeavor was initiated to propel the club into the future. This comprehensive transformation included the construction of a cutting-edge fibre turf grass racetrack and the installation of floodlights, revolutionizing the racing experience by extending it into the evening hours.\nMoreover, the club expanded its amenities to cater to the needs of both horses and enthusiasts alike. New air-conditioned stable blocks were introduced, ensuring the comfort and well-being of the equine residents. An equine swimming pool further underscored the commitment to top-tier facilities.\nAs the club continued to evolve, it welcomed a state-of-the-art grandstand in preparation for the 2011/2012 season. This grandstand, with a remarkable seating capacity of 5,000, not only offers prime viewing spots for racegoers but also boasts corporate hospitality suites, VIP areas, and administrative offices, embodying a commitment to excellence and providing a world-class experience.\nIn summary, the Abu Dhabi Equestrian Club stands as a testament to its rich heritage, dedication to progress, and unwavering commitment to elevating the world of equestrian sports.",
 
      content1Ar:
      "قبل تبني السباق المفتوح، كان النادي معروفًا باستضافة تجمعات حصرية للخيول العربية، والتي غالبًا ما تتزامن مع مناسبات مهمة لا تنسى.\nفي عام 1994، تماشيًا مع التوجيهات الحكيمة لصاحب السمو رئيس الدولة، بدأ مسعى تحديث شامل لدفع النادي في المستقبل. شمل هذا التحول الشامل بناء مضمار سباق متطور مصنوع من الألياف العشبية وتركيب الأضواء الكاشفة، مما أحدث ثورة في تجربة السباق من خلال تمديدها حتى ساعات المساء.\nعلاوة على ذلك، قام النادي بتوسيع وسائل الراحة الخاصة به لتلبية احتياجات كل من الخيول و المتحمسين على حد سواء. تم إدخال إسطبلات جديدة مكيفة، مما يضمن راحة ورفاهية سكان الخيول. كما أكد حوض سباحة الخيول على الالتزام بتوفير مرافق من الدرجة الأولى.\nمع استمرار النادي في التطور، رحب بمدرج على أحدث طراز استعدادًا لموسم 2011/2012. هذا المدرج، الذي يتسع لـ 5000 مقعد، لا يوفر أماكن مشاهدة رئيسية لمتسابقي السباق فحسب، بل يضم أيضًا أجنحة ضيافة للشركات، ومناطق لكبار الشخصيات، ومكاتب إدارية، مما يجسد الالتزام بالتميز وتوفير تجربة عالمية المستوى.\nباختصار، يقف نادي أبوظبي للفروسية بمثابة شهادة على تراثه الغني، وتفانيه في التقدم، والتزامه الثابت بالارتقاء بعالم رياضات الفروسية.",
  },




  option3: {
    heading: "About Jebel Ali Racecourse",
    headingAr: "نبذة عن مضمار جبل علي ",
    image:Jeb,
    content:
      "The Jebel Ali Racecourse stands as a testament to meticulous planning and vision, shaped by the directives of His Highness Sheikh Ahmed bin Rashid Al Maktoum and the expertise of Dhruba Selvaratnam. Its inception dates back to 1990 when the racecourse and grandstand were carefully constructed, marking the beginning of a new era in equestrian excellence.\nOver the years, a commitment to enhancing the racecourse's facilities and amenities remained unwavering. In 1995, a significant expansion took place with the addition of another level to the grandstand. This expansion was driven by a desire to provide the utmost in comfort and luxury for corporate hospitality guests, ensuring they have an unforgettable experience while witnessing thrilling races.",
   
      contentAr:
      "يعد مضمار جبل علي بمثابة شهادة على التخطيط الدقيق والرؤية، التي شكلتها توجيهات صاحب السمو الشيخ أحمد بن راشد آل مكتوم وخبرة دروبا سيلفاراتنام. يعود تاريخ إنشائه إلى عام 1990 عندما تم إنشاء مضمار السباق والمدرج بعناية، مما يمثل بداية حقبة جديدة في التميز في الفروسية.\nعلى مر السنين، ظل الالتزام بتعزيز مرافق مضمار السباق ووسائل الراحة ثابتًا. في عام 1995، تم إجراء توسعة كبيرة بإضافة مستوى آخر إلى المدرج. وكان هذا التوسع مدفوعًا بالرغبة في توفير أقصى درجات الراحة والرفاهية لضيوف الضيافة من الشركات، مما يضمن حصولهم على تجربة لا تُنسى أثناء مشاهدة السباقات المثيرة.",
   
      content1:
      "Today, the Jebel Ali Racecourse offers seating for well over 2,000 spectators, creating a vibrant and lively atmosphere for horse racing enthusiasts. It has garnered a distinct reputation for its unique and welcoming ambiance, often likened to a 'Garden Party.' This atmosphere sets it apart, making it a favored destination for those seeking not only thrilling races but also an environment that evokes a sense of celebration and camaraderie.\n In summary, the Jebel Ali Racecourse has evolved into a premier equestrian venue, thoughtfully developed under the guidance of His Highness Sheikh Ahmed bin Rashid Al Maktoum and the dedicated team led by Dhruba Selvaratnam. Its continued commitment to excellence and its inviting atmosphere make it a cherished location for racing enthusiasts and corporate guests alike.",

      
      content1Ar:"واليوم، يتسع مضمار جبل علي لسباق الخيل لأكثر من 2000 متفرج، مما يخلق أجواء مفعمة بالحيوية والنشاط لعشاق سباقات الخيل. لقد اكتسب سمعة متميزة بسبب أجواءه الفريدة والترحيبية، والتي غالبًا ما يتم تشبيهها بـ هذا الجو يميزه، مما يجعله وجهة مفضلة لأولئك الذين يبحثون ليس فقط عن السباقات المثيرة ولكن أيضًا عن بيئة تثير الشعور بالاحتفال والصداقة الحميمة.\n باختصار، تطور مضمار جبل علي ليصبح مكانًا متميزًا للفروسية، تم تطويره بعناية بتوجيهات من سمو الشيخ أحمد بن راشد آل مكتوم والفريق المتفاني بقيادة دروبا سيلفاراتنام. إن التزامها المستمر بالتميز وأجواءها الجذابة يجعلها موقعًا عزيزًا لعشاق السباقات وضيوف الشركات على حدٍ سواء.",
  },





  option4: {
    heading: "About Sharjah Longines Racecourse",
    headingAr: "نبذة عن مضمار لونجين لسباق الخيل بالشارقة",
    image:Shj,
    content:
      "The Sharjah Longines Racecourse holds a rich history in the world of horse racing. Originally, in 1982, it was meticulously constructed under the watchful supervision of His Excellency Sheikh Abdulla bin Majid Al Qasimi. It wasn't until 1983 that this remarkable venue hosted its inaugural race, marking the beginning of an exciting journey.\n However, recognizing the need for modernization and continuous improvement, the ruler of Sharjah, His Highness Dr. Sheikh Sultan bin Mohammed Al Qasimi, issued vital directives in 1995. These directives paved the way for an ambitious program aimed at revitalizing the racecourse. This comprehensive initiative has recently reached fruition and stands as a testament to the commitment to excellence in equestrian sports.",
      contentAr:
      "يحمل مضمار لونجين الشارقة لسباق الخيل تاريخاً غنياً في عالم سباقات الخيل. في الأصل، في عام 1982، تم تشييده بدقة تحت إشراف ساهر من معالي الشيخ عبد الله بن ماجد القاسمي. لم يستضيف هذا المكان الرائع السباق الافتتاحي إلا في عام 1983، مما يمثل بداية رحلة مثيرة.\n ومع ذلك، إدراكًا للحاجة إلى التحديث والتحسين المستمر، حاكم الشارقة، صاحب السمو الشيخ الدكتور سلطان بن محمد آل أصدر القاسمي توجيهات حيوية عام 1995. مهدت هذه التوجيهات الطريق لبرنامج طموح يهدف إلى تنشيط مضمار السباق. وقد أتت هذه المبادرة الشاملة بثمارها مؤخرًا وهي بمثابة شهادة على الالتزام بالتميز في رياضة الفروسية.",
   
      content1:
      "The renovation efforts encompassed several significant elements, with the construction of a brand-new grandstand being one of the highlights. This grandstand, a symbol of modernity and luxury, is designed to provide racegoers and enthusiasts with an enhanced viewing experience. Furthermore, the racetrack itself has undergone a meticulous redesign, ensuring that it meets the highest standards of quality and safety.\n In summary, the Sharjah Longines Racecourse has evolved from its origins in the early 1980s, thanks to the visionary leadership of His Excellency Sheikh Abdulla bin Majid Al Qasimi and the commitment to progress demonstrated by His Highness Dr. Sheikh Sultan bin Mohammed Al Qasimi. Today, it stands as a world-class equestrian venue that continues to captivate both participants and spectators alike.",
 
   
      content1Ar:
      "شملت جهود التجديد العديد من العناصر المهمة، وكان بناء مدرج جديد تمامًا أحد المعالم البارزة. تم تصميم هذا المدرج، الذي يعد رمزًا للحداثة والرفاهية، لتزويد رواد السباق والمتحمسين بتجربة مشاهدة محسنة. علاوة على ذلك، فقد خضع مضمار السباق نفسه لعملية إعادة تصميم دقيقة، مما يضمن استيفائه لأعلى معايير الجودة والسلامة.\n باختصار، لقد تطور مضمار سباق لونجين الشارقة منذ بداياته في أوائل الثمانينيات، وذلك بفضل القيادة الحكيمة لمعاليه. الشيخ عبدالله بن ماجد القاسمي والالتزام بالتقدم الذي أبداه صاحب السمو الشيخ الدكتور سلطان بن محمد القاسمي. واليوم، أصبح الملعب مكانًا عالميًا للفروسية يواصل جذب المشاركين والمشاهدين على حدٍ سواء.",
  },



  option5: {
    heading: "About Al Ain Raceway Racecourse",
    headingAr: "نبذة عن مضمار سباق العين",
    image:Ain,
    content:
      "Al Ain Raceway is a state-of-the-art international karting facility specialising in Event Management and delivering high performance karting at its very best. Our world-leading, fully flood-lit circuit features several distinctive configurations, providing an exhilarating challenge for drivers of all abilities.",
    
    
    contentAr:
      "مضمار العين هو منشأة عالمية حديثة للكارتينج متخصصة في إدارة الأحداث وتقديم الكارتينج عالي الأداء في أفضل حالاته. تتميز حلبتنا الرائدة عالميًا والمضاءة بالكامل بالعديد من التكوينات المميزة، مما يوفر تحديًا مبهجًا للسائقين من جميع القدرات.",
   
      content1:
      "At Al Ain Raceway we offer unrivalled opportunities for those who demand the very best in Corporate Entertainment and Conferencing, Arrive and Drive, Junior Driving and Tuition as well as Professional Racing, Kart sales and Technical Support.",
  

      content1Ar:
      "في حلبة سباق العين، نقدم فرصًا لا مثيل لها لأولئك الذين يطلبون الأفضل في مجال الترفيه المؤسسي والمؤتمرات، والوصول والقيادة، والقيادة للمبتدئين، والرسوم الدراسية، بالإضافة إلى السباقات الاحترافية، ومبيعات الكارت والدعم الفني.",
  }
};
