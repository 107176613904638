import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getGroundTypeFilterForStatsSlice = createSlice({
    name: 'GroundTypeFilterForStats',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchGroundTypeFilterForStats.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchGroundTypeFilterForStats.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchGroundTypeFilterForStats.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setGroundTypeFilterForStats, setStatus } = getGroundTypeFilterForStatsSlice.actions;
export default getGroundTypeFilterForStatsSlice.reducer;

export const fetchGroundTypeFilterForStats = createAsyncThunk('/GroundTypeFilterForStats/fetch', async ({ year,
    Nationality,
    RaceCourse,
    GroundType,
    RaceKind,
    HorseKind,
    RaceType,
    TrackLength}) => {
    const res = await axios.get(`${window.env.API_URL}/GroundTypeFilterForStats?year=${year === undefined ? "" : year}&Nationality=${Nationality === undefined ? "" : Nationality}&RaceCourse=${RaceCourse === undefined ? "" : RaceCourse}&GroundType=${GroundType === undefined ? "" : GroundType}&RaceKind=${RaceKind === undefined ? "" : RaceKind}&HorseKind=${HorseKind === undefined ? "" : HorseKind }&RaceType=${RaceType=== undefined ? "" : RaceType}&TrackLength=${TrackLength === undefined ? "" : TrackLength}&size=540`);
    const GroundTypeFilterForStatsData = res.data;
    return GroundTypeFilterForStatsData.data;
})
