import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getJockeyTrainerCombosingleSlice = createSlice({
    name: 'JockeyTrainerCombosingle',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchJockeyTrainerCombosingle.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchJockeyTrainerCombosingle.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchJockeyTrainerCombosingle.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setJockeyTrainerCombosingle, setStatus } = getJockeyTrainerCombosingleSlice.actions;
export default getJockeyTrainerCombosingleSlice.reducer;

export const fetchJockeyTrainerCombosingle = createAsyncThunk('/JockeyTrainerCombosingleget/fetch', async ({Trainer,Jockey,
    year,
      Nationality,
      RaceCourse,
      GroundType,
      RaceKind,
      HorseKind,
      RaceType,
      TrackLength
}) => {
    const res = await axios.get(`${window.env.API_URL}/Trainer_JockeyCombo_HistroyV2/${Trainer}/${Jockey}?year=${year === undefined ? "" : year}&Nationality=${Nationality === undefined ? "" : Nationality}&RaceCourse=${RaceCourse === undefined ? "" : RaceCourse}&GroundType=${GroundType === undefined ? "" : GroundType}&RaceKind=${RaceKind === undefined ? "" : RaceKind}&HorseKind=${HorseKind === undefined ? "" : HorseKind }&RaceType=${RaceType=== undefined ? "" : RaceType}&TrackLength=${TrackLength === undefined ? "" : TrackLength}`);
    const JockeyTrainerCombosingleData = res.data;
    return JockeyTrainerCombosingleData.data;
})
