import React, { useEffect, useState, Fragment } from "react";
import Footer from "../Components/Reuseable/Footer.jsx";
import CoptRight from "../Components/Reuseable/Copyrights.jsx";
import Cookies from "js-cookie";
import "../../Webiste/Components/CSS/pagesCSS/dashboard.css";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Defaultimg from "../../Webiste/assets/Frame.png";
import "../Components/CSS/RaceCardCSS/racecard.css";
import { useNavigate } from "react-router-dom";
import Lottie from 'lottie-react';
import Animate from "../assets/loader.json";
import HorseModal from '../Components/Modal/HorseModal.js'
import JockeyModal from '../Components/Modal/JockeyModal.js'
import TrainerModal from '../Components/Modal/TrainerModal.js'
import OwnerModal from '../Components/Modal/OwnerModal.js'
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchAtoZReport } from "../redux/getReducer/getAtoZReport.js";
import ExNews from "../Components/Home/Components/ExNews.jsx";
import Search from "../Components/Home/Components/Search.jsx";
import Notification from "../Components/Home/Components/Notification.jsx";
import Language from "../Components/Home/Components/Language.jsx";

export default function AtoZReport() {
  const navigate = useNavigate();
  const [userIsDesktop, setUserIsDesktop] = useState(true);
  function handleRedirect(id) {
    if (userIsDesktop === true) {
      navigate("/racedetail", {
        state: {
          id: id,
        },
      });
    } else {
      navigate("/racedetails", {
        state: {
          id: id,
        },
      });
    }
  }


  const { data: AtoZReport } = useSelector(
    (state) => state.AtoZReport
  );
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const cookiedata = Cookies.get("i18next");

  
  const [show, setShow] = useState(false);
  const [modaldata, setmodaldata] = useState();

  const handleClose = () => setShow(false);
  const handleShow = async (data) => {
    setmodaldata(data);
    await setShow(true);
  };



  const [showJockey, setShowJockey] = useState(false);
  const [modaldataJockey, setmodaldataJockey] = useState();

  const handleCloseJockey = () => setShowJockey(false);
  const handleShowJockey = async (data) => {
    setmodaldataJockey(data);
    await setShowJockey(true);
  };



  const [showTrainer, setShowTrainer] = useState(false);
  const [modaldataTrainer, setmodaldataTrainer] = useState();

  const handleCloseTrainer = () => setShowTrainer(false);
  const handleShowTrainer = async (data) => {
    setmodaldataTrainer(data);
    await setShowTrainer(true);
  };


  
  const [showOwner, setShowOwner] = useState(false);
  const [modaldataOwner, setmodaldataOwner] = useState();

  const handleCloseOwner = () => setShowOwner(false);
  const handleShowOwner = async (data) => {
    setmodaldataOwner(data);
    await setShowOwner(true);
  };

  useEffect(() => {
    if (AtoZReport && AtoZReport?.length == undefined) {}
    dispatch(fetchAtoZReport())
  },[])



  const GetDates = (alldata) => {
    var start = new Date();
    var end = new Date(start);
 

    end.setDate(start.getDate() + 3);
    let Dates = [];

    var loop = new Date(start);

    for (let i = 0; i < 5; i++) {
      if (i == 0) {
        var newDates = loop.setDate(loop.getDate() + i);
        loop = new Date(newDates);
        Dates.push(moment(loop).format("YYYY-MM-DD"));
      } else {
        var newDates = loop.setDate(loop.getDate() - 1);
        loop = new Date(newDates);
        Dates.push(moment(loop).format("YYYY-MM-DD"));
      }
    }

    // SetDateTobeDone(Dates);
    return Dates;
  };

  // const today = cookiedata === "en" ? "Today" : "اليوم";
  // const Yesterday = cookiedata === "en" ? "Yesterday" : "أمس";
  // const getDateDataAccordingToTime = async (data) => {
  //   setCategorySub();
  //   await fetch(`${window.env.API_URL}/AtoZReport?Date=${data}`)
  //     .then((res) => res.json())
  //     .then((result) => {
  //       setCategorySub(result);
  //     });
  // };



  const AllTime = (TimeData) => {
    if (TimeData.length == 1) {
      return `${moment(TimeData[0].StartTime).format("h:mm")}`;
    } else if (TimeData.length == 0) {
      return ``;
    } else {
      let FirstTime = moment(TimeData[0].StartTime).format("h:mm");
      let LastTime = moment(TimeData[TimeData.length - 1].StartTime).format(
        "h:mm"
      );
      return `${FirstTime}-${LastTime}`;
    }
  };



  function toPascalCase(inputString) {
    // Split the string into words
    const words = inputString.split(/\s+/);
  
    // Capitalize the first letter of each word
    const pascalCaseWords = words.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
  
    // Join the words back together with spaces
    const pascalCaseString = pascalCaseWords.join(' ');
  
    return pascalCaseString;
  }
  
  return (
    <Fragment>
      <>
    
        <div className="container mystages">
          <div className="row">
            <div className="col-sm-12 ">
              <div className="raceCarParent1 atozParent">
                <div className="raceCardHeader1">
                  <div className="row">
                    <div className="col-sm-12">
                      <h1>{t("AZReport")}</h1>
                    </div>
                  </div>
                </div>
                <div className="posttabs">
                  <div className="row">
                    {/* {DateTobeDone ? (
                      DateTobeDone.map((singledate, i) => (
                        <div className="col-sm-1">
                          <p
                            onClick={() =>
                              getDateDataAccordingToTime(singledate)
                            }
                          >
                            {i == 0 ? today : i == 1 ? Yesterday : singledate}
                          </p>
                        </div>
                      ))
                    ) : (
                      <></>
                    )} */}
                  </div>
                </div>
                <div>
                  <div className="raceCardTable">
                    <table className=" tableHeaderCard">
                      <thead>
                        <tr key="i">
                          <th>{t("Date")}</th>

                          <th>{t("Time")}</th>

                          <th>{t("Race course")}</th>

                          <th>{t("Gate No")}</th>
                          <th>{t("Horse No")}</th>
                          <th>{t("Silk Color")}</th>
                          <th>{t("Horse Name")}</th>
                          <th>{t("Trainer")}</th>
                          <th>{t("Jockey")}</th>
                          <th>{t("Owner")} </th>
                        </tr>
                      </thead>
                      {AtoZReport ? (
                        AtoZReport.map((singlecompetition, i) => (
                          <tbody>
                            {singlecompetition.RacehorsesData ? (
                              singlecompetition.RacehorsesData.map(
                                (Competitiondetail, i) => (
                                  <tr>
                                    <td>
                                      {" "}
                                      {moment(
                                        singlecompetition.StartTime
                                      ).format("Do/MM/yy")}
                                    </td>
                                    <td>
                                      {" "}
                                      {moment(
                                        singlecompetition.StartTime
                                      ).format("h:mm:ss")}
                                    </td>

                                    <td className="lb">
                                      {" "}
                                      {cookiedata === "en"
                                        ? toPascalCase(singlecompetition.RaceCourseData
                                            .TrackNameEn)
                                        : singlecompetition.RaceCourseData
                                            .TrackNameEn}
                                    </td>

                                    <td className="lb">
                                    {Competitiondetail.GateNo
                                        ? Competitiondetail.GateNo
                                        : "not available"}
                                    </td>

                                    <td className="lb">
                                      {" "}
                                      {Competitiondetail.HorseNo
                                        ? Competitiondetail.HorseNo
                                        : "not available"}
                                    </td>

                                    <td className="lb">
                                      <img
                                        src={
                                          Competitiondetail.CapColorData1
                                            ? Competitiondetail.CapColorData1
                                                .OwnerSilkColor
                                            : Defaultimg
                                        }
                                        alt=""
                                      />
                                    </td>

                                    <td className="lb " onClick={() => handleShow(Competitiondetail.HorseModelIdData1 ? Competitiondetail.HorseModelIdData1._id : '' )} style={{
                                          cursor:'pointer'
                                        }}>
                                      {Competitiondetail.HorseModelIdData1 &&
                                      cookiedata === "en"
                                        ? toPascalCase(Competitiondetail.HorseModelIdData1
                                            .NameEn)
                                        : Competitiondetail.HorseModelIdData1
                                            .NameAr}
                                    </td>

                                    <td className="lb" onClick={() => handleShowTrainer(Competitiondetail.TrainerOnRaceData1 ? Competitiondetail.TrainerOnRaceData1._id : '' )} style={{
                                          cursor:'pointer'
                                        }}>
                                      {Competitiondetail.TrainerOnRaceData1 &&
                                      cookiedata === "en"
                                        ? toPascalCase(Competitiondetail.TrainerOnRaceData1
                                            .NameEn)
                                        : Competitiondetail.TrainerOnRaceData1
                                            .NameAr}
                                    </td>
                                    <td className="lb" onClick={() => handleShowJockey(Competitiondetail.JockeyOnRaceData1 ? Competitiondetail.JockeyOnRaceData1._id : '' )} style={{
                                          cursor:'pointer'
                                        }}>
                                      {Competitiondetail.JockeyOnRaceData1 ===
                                      null ? (
                                        <></>
                                      ) : cookiedata === "en" ? (
                                        toPascalCase(Competitiondetail.JockeyOnRaceData1
                                          .NameEn)
                                      ) : (
                                        Competitiondetail.JockeyOnRaceData1
                                          .NameAr
                                      )}
                                    </td>

                                    <td className="lb" onClick={() => handleShowOwner(Competitiondetail.OwnerOnRaceData1 ? Competitiondetail.OwnerOnRaceData1._id : '' )} style={{
                                          cursor:'pointer'
                                        }}>
                                      {Competitiondetail.OwnerOnRaceData1 ===
                                      null ? (
                                        <></>
                                      ) : cookiedata === "en" ? (
                                        toPascalCase(Competitiondetail.OwnerOnRaceData1
                                          .NameEn)
                                      ) : (
                                        Competitiondetail.OwnerOnRaceData1
                                          .NameAr
                                      )}
                                    </td>
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td>No data found</td>
                              </tr>
                            )}
                          </tbody>
                        ))
                      ) : (
                        <></>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
<Modal
          show={show}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="popupheader" closeButton></Modal.Header>
          <Modal.Body>
            <HorseModal data={modaldata} />
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        <Modal
          show={showJockey}
          onHide={handleCloseJockey}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="popupheader" closeButton></Modal.Header>
          <Modal.Body>
            <JockeyModal data={modaldataJockey} />
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        <Modal
          show={showTrainer}
          onHide={handleCloseTrainer}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="popupheader" closeButton></Modal.Header>
          <Modal.Body>
            <TrainerModal data={modaldataTrainer} />
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        <Modal
          show={showOwner}
          onHide={handleCloseOwner}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="popupheader" closeButton></Modal.Header>
          <Modal.Body>
            <OwnerModal data={modaldataOwner} />
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>


        <Footer />
        <CoptRight />
      </>
    </Fragment>
  );
}
