import React, { Fragment } from "react";
import Header from "../Components/Reuseable/Header";
import Auth from "../Components/Reuseable/Auth";
import Search from "../Components/Home/Components/Search";
import ExNews from "../Components/Home/Components/ExNews";
import Notification from "../Components/Home/Components/Notification";
import Tracker from "../Components/Home/Components/Tracker";
import { Form } from "react-bootstrap";
import { FloatingLabel } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserDetails } from "../redux/postReducer/UserPost";
import axios from "axios";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Cookies from "js-cookie";
import { Country_Name, Country_NameAr } from "../Components/Reuseable/Country";
import { useTranslation } from "react-i18next";
import { fetchsingleUser, STATUSES } from "../redux/getReducer/getSingleUser";
import { useDispatch, useSelector } from "react-redux";
import Lottie from "lottie-react";
import Animate from "../assets/loader.json";
import { ImCross } from "react-icons/im";
import Language from "../Components/Home/Components/Language";
import Select from "react-select";
import Button from 'react-bootstrap/Button';
import Footer from "../Components/Reuseable/Footer";
import Copyrights from "../Components/Reuseable/Copyrights";

const MyProfile = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.user);
  const { data: singleUser, status } = useSelector((state) => state.singleUser);
  const cookiedata = Cookies.get("i18next");
  const [PassportPicture, setPassportPicture] = useState();
  const [IsPassportPicture, setIsPassportPicture] = useState(false);
  const [ProfilePicture, setProfilePicture] = useState();
  const [IsProfilePicture, setIsProfilePicture] = useState(false);
  const [NationalityID, setNationalityID] = useState("");
  const [preview, setPreview] = useState();
  const [preview1, setPreview1] = useState();
  const ProfileId = Cookies.get("id");

  const [state1, setState] = useState({
    FirstName: "",
    LastName: "",
    PassportNo: "",
    PhoneNumber: "",
    PassportPicture: PassportPicture,
    ProfilePicture:ProfilePicture,
    Email: "",
    NationalityID: "",
    Address: "",
    DOB: "",
  });

  let AllCountry = Country_Name.map(function (item) {
    return {
      id: item.country_id,
      value: item.country_name,
      label: item.country_name,
    };
  });
  const [validated, setValidated] = useState(false);


  const [state, setState1] = useState({ Password: "", ConfirmPassword: "" });
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setState1({ ...state, Password: newPassword });
    if (newPassword.length < 6) {
      setPasswordError("Password must be at least 6 characters");
    } else {
      setPasswordError("");
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setState1({ ...state, ConfirmPassword: newConfirmPassword });
    if (newConfirmPassword !== state.Password) {
      setConfirmPasswordError("Passwords do not match");
    } else {
      setConfirmPasswordError("");
    }
  };

  

  useEffect(() => {
    if (singleUser) {
      setState({
        FirstName: singleUser.FirstName,
        LastName: singleUser.LastName,
        PassportNo: singleUser.PassportNo,
        PhoneNumber: singleUser.PhoneNumber,
        Email: singleUser.Email,
        NationalityID: singleUser.Nationality,
        Address: singleUser.Address,
        DOB: singleUser.DOB,
      });
    } else {
    }
  }, [singleUser]);

  useEffect(() => {
    dispatch(fetchsingleUser());
  }, [dispatch]);

  useEffect(() => {
    if (ProfilePicture === undefined) {
      setPreview(singleUser.ProfilePicture);
      return;
    }
    const objectUrl = URL.createObjectURL(ProfilePicture);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [singleUser.ProfilePicture, ProfilePicture]);


  useEffect(() => {
    if (PassportPicture === undefined) {
      setPreview1(singleUser.PassportPicture);
      return;
    }
    const objectUrl = URL.createObjectURL(PassportPicture);
    setPreview1(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);

  }, [singleUser.PassportPicture, PassportPicture]);

  console.log(preview1,"passpost pic")



  if (status === STATUSES.LOADING) {
    return <Lottie animationData={Animate} className="load" />;
  }
  if (status === STATUSES.ERROR) {
    return (
      <h2
        style={{
          margin: "100px",
        }}
      >
        Something went wrong!
      </h2>
    );
  }

  const onSelectFile = (event) => {
    const image = event.target.files[0];
    setProfilePicture(image);


  };


  const onSelectFile1 = (event) => {
    const image = event.target.files[0];
    setPassportPicture(image);

  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("FirstName", state1.FirstName);
      formData.append("LastName", state1.LastName);
      formData.append("PassportNo", state1.PassportNo);
      formData.append("PhoneNumber", state1.PhoneNumber);
      formData.append(
        "Nationality",
        NationalityID ? NationalityID : state1.NationalityID
      );
      // formData.append("Email", state1.Email);
      if (state.Password !== "") {
        formData.append("password", state.Password);
      }
      formData.append("DOB", state1.DOB);
      formData.append("Address", state1.Address);
      formData.append("PassportPicture", PassportPicture);
      formData.append("ProfilePicture", ProfilePicture);

      if (IsPassportPicture === true && PassportPicture === undefined) {
        formData.append("deletePassportPicture", IsPassportPicture)
      }

      if (IsProfilePicture === true && ProfilePicture === undefined) {
        formData.append("deleteProfilePicture", IsProfilePicture)
      }

      await axios.put(
        `${window.env.API_URL}/updatesubscriber/${ProfileId}`,
        formData
      );
      toast("Successfuly Updated");
      dispatch(fetchsingleUser());
      dispatch(getUserDetails());
    } catch (error) {
      toast(error.response.data.message);
    }

  };


  const onSelectFileProfile = (e) => {
    setProfilePicture(e.target.files[0]);
  };


   const onSelectFilePassport = (e) => {
    setPassportPicture(e.target.files[0]);
  };



  const UpdateProfile = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("FirstName", state1.FirstName);
      formData.append("LastName", state1.LastName);
      formData.append("PassportNo", state1.PassportNo);
      formData.append("PhoneNumber", state1.PhoneNumber);
      formData.append(
        "Nationality",
        NationalityID ? NationalityID.value : state1.NationalityID
      );
      // formData.append("Email", state1.Email);
      if (state.Password !== "") {
        formData.append("password", state.Password);
      }
      formData.append("DOB", state1.DOB);
      formData.append("Address", state1.Address);
      formData.append("PassportPicture", PassportPicture);
      formData.append("ProfilePicture", ProfilePicture);

      if (IsPassportPicture === true && PassportPicture === undefined) {
        formData.append("deletePassportPicture", IsPassportPicture)
      }

      if (IsProfilePicture === true && ProfilePicture === undefined) {
        formData.append("deleteProfilePicture", IsProfilePicture)
      }

      await axios.put(
        `${window.env.API_URL}/updatesubscriber/${ProfileId}`,
        formData
      );
      toast("Successfuly Updated");
      dispatch(fetchsingleUser());
      dispatch(getUserDetails());
    } catch (error) {
      toast(error.response.data.message);
    }
  };
  const date = new Date(state1.DOB);

  const handlePreview = () => {
    setProfilePicture();
    setPreview();
    setIsProfilePicture(true)
    // document.getElementById("file").value = "";
  };


  const handlePreview1 = () => {
    setPassportPicture();
    setPreview1();
    setIsPassportPicture(true)

    // document.getElementById("file").value = "";
  };

  const handleChange = () => {
  }

  return (
    <Fragment>
     
      <div className="userHeader">
        <h3>{t("MyProfile")}</h3>
      </div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
               
                
                <div className="row datarow">
                  <div className="col-sm-6">

                    <Form.Group controlId="validationCustom03">
                      <Form.Label className="labelnew1">{t("Name")}</Form.Label>
                      <Form.Control name="Name" type="text" placeholder="First Name"
                       value={state1.FirstName}
                        // value={FirstName}
                        onChange={(e) =>
                          setState({ ...state1, FirstName: e.target.value })
                        }
                        required />
                      <Form.Control.Feedback type="invalid">
                        {t("Please provide First Name.")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">

                    <Form.Group controlId="validationCustom03">
                      <Form.Label className="labelnew1">{t("LastName")}</Form.Label>
                      <Form.Control type="text" placeholder="Last Name"
                        // value={LastName}
                        value={state1.LastName}
                        name="LastName"
                        onChange={(e) =>
                          setState({ ...state1, LastName: e.target.value })
                        }
                        required />
                      <Form.Control.Feedback type="invalid">
                        {t("Please provide a Last Name.")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="validationCustom04">
                      <Form.Label className="labelnew1">{t("Email")}</Form.Label>
                      <Form.Control type="email" placeholder="Email"
                        // value={Email}
                        name="Email"
                        value={state1.Email}
                        onChange={(e) => setState({ ...state1, Email: e.target.value })} readOnly />
                      <Form.Control.Feedback type="invalid">
                       {t(" Please provide a valid Email.")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="validationCustom05">
                      <Form.Label className="labelnew1">{t("Date of Birth")}</Form.Label>
                      <Form.Control   onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }} type="text"
                  //  value={passportNumber} 
                  onChange={(e) => setState({ ...state1, DOB: e.target.value })}
                  value={date.toDateString()}
                   name="PassportNo" placeholder="Date of Birth" 
                   readOnly
                        />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Date of Birth.
                      </Form.Control.Feedback>
                      
                     
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="validationCustom05">
                      <Form.Label className="labelnew1">{t("PhoneNumber")}</Form.Label>
                      <Form.Control   onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                   type="text" name="PhoneNumber" placeholder="PhoneNumber"  onChange={(e) =>
                    setState({ ...state1, PhoneNumber: e.target.value })
                  }
                  value={state1.PhoneNumber}
                       required />
                      <Form.Control.Feedback type="invalid">
                        {t("Please provide a valid PhoneNumber.")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="validationCustom05">
                      <Form.Label className="labelnew1">{t("PassportNo")}</Form.Label>
                      <Form.Control name="" type="text" placeholder="Date of Birth" 
                       onChange={(e) =>
                        setState({ ...state1, PassportNo: e.target.value })
                        
                      } 
                      onKeyPress={(event) => {
                        if (!/[0-9.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      value={state1.PassportNo} required />
                      <Form.Control.Feedback type="invalid">
                        {t("Please provide a valid Passport No .")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="validationCustom05">
                      <Form.Label className="labelnew1">Address</Form.Label>
                      <Form.Control name="Address" type="text" placeholder="Address" 
                        onChange={(e) =>
                          setState({ ...state1, Address: e.target.value })
                        } value={state1.Address} required />
                      <Form.Control.Feedback type="invalid">
                        {t("Please provide a valid Address.")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="validationCustom05">
                      <Form.Label className="labelnew1">{t("Nationality")}</Form.Label>
                      <Form.Select name="NationalityID" 
                      placeholder={NationalityID == "" ? state1?.NationalityID : NationalityID}
                      value={NationalityID == "" ? state1?.NationalityID : NationalityID}
                        onChange={(e) => setNationalityID(e.target.value)} required>
                        {
                          Country_Name.map((item, i) => {
                            return (
                              <option value={item.country_name}>{item.country_name}</option>
                            )
                          })
                        }
                      </Form.Select>

                      <Form.Control.Feedback type="invalid">
                        {t("Please provide a valid Nationality.")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="validationCustom05">
                      <Form.Label className="labelnew1">{t("Password")}</Form.Label>
                      <Form.Control  value={state.Password}
                       onChange={handlePasswordChange}
                       name="password" type="text" placeholder="Password"  />
                        <Form.Control.Feedback type="invalid">
                        {t("Please provide a valid Password.")}
                      </Form.Control.Feedback>
                      {passwordError && (
                <div className="error-message">{passwordError}</div>
              )}                    </Form.Group>
                  </div>

                  <div className="col-sm-6">
                    <Form.Group controlId="validationCustom05">
                      <Form.Label className="labelnew1">Confirm Password</Form.Label>
                      <Form.Control 
                       value={state.ConfirmPassword}
                       onChange={handleConfirmPasswordChange}
                        name="password" type="text" placeholder="Confirm Password" />
                          <Form.Control.Feedback type="invalid">
                          {t("Please provide a valid Password.")}
                      </Form.Control.Feedback>
                      {confirmPasswordError && (
                <div className="error-message">{confirmPasswordError}</div>
              )}
                    </Form.Group>
                  </div>

                  <div className="col-sm-6">

                    <Form.Group controlId="validationCustom05">
                      <Form.Label className="labelnew1">Profile Picture</Form.Label>
                      <Form.Control type="file" onChange={onSelectFileProfile}/>
                      <Form.Control.Feedback type="invalid">
                       {t("Please provide a valid Profile Picture.")}
                      </Form.Control.Feedback>
                    </Form.Group>
                    {preview && (
              <>
                
                <img src={preview} className="PreviewImage" alt="" />
              </>
            )}
                  </div>

                  <div className="col-sm-6">

                    <Form.Group controlId="validationCustom05">
                      <Form.Label className="labelnew1">Passport Picture</Form.Label>
                      <Form.Control  type="file"  onChange={onSelectFilePassport}/>
                      <Form.Control.Feedback type="invalid">
                        {t("Please provide a valid Passport Picture.")}
                      </Form.Control.Feedback>
                    </Form.Group>
                    {preview1 && (
              <>
                <ImCross onClick={handlePreview1} className="crossIcon" />
                <img src={preview1} className="PreviewImage" alt="" />
              </>
            )}
                  </div>

                  <div className="submitbtn1 col-sm-12">
                  <div className="ButtonSection">
                    <Form.Group controlId="validationCustom05 ">
                      <Button className="btn123" disabled={confirmPasswordError === "" ? false : true} type="submit">Update</Button>
                    </Form.Group>
                    </div>
                  </div>

                </div>
              </Form>

              <hr />
              <Footer />
              <Copyrights />
    </Fragment>
  );
};

export default MyProfile;
