import { useEffect, useState } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { BsFillCaretRightFill } from "react-icons/bs";
import Cookies from "js-cookie";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import DefaultImg from "../../assets/Frame.png";
import PleaseWait from "../../Components/Reuseable/PleaseWait";
import arrow1 from "../../assets/image 13 (Traced).png";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { fetchJockeyHistory } from "../../redux/getReducer/getJockeyHistory";
import {
  fetchSinglejockey,
  STATUSES,
} from "../../redux/getReducer/getSingleJockey";
import Stats from "../Tabs/JockeyStats.jsx";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import JockeyEntries from "../Tabs/JockeyEntries";
import VideoFrame from "./VideoFrame";
import HorseModal from "./HorseModal";
import RaceModal from "./RaceModal";
import Lottie from "lottie-react";
import Animate from "../../assets/loader.json";
import Pagination from "../../pages/Pagination";

const JockeyDetail = (data) => {
  const { t } = useTranslation();
  const [shows, setShows] = useState(false);
  const [modaldatas, setmodaldatas] = useState();
  const handleCloses = () => setShows(false);
  const handleShows = async (data) => {
    setmodaldatas(data);
    await setShows(true);
  };

  const [showRace, setShowRace] = useState(false);
  const [modaldataRace, setmodaldataRace] = useState();
  const handleCloseRace = () => setShowRace(false);
  const handleShowRace = async (id) => {
    setmodaldataRace(id);
    await setShowRace(true);
  };

  const [ShowHorse, setShowHorse] = useState(false);
  const [modaldatasHorse, setmodaldatasHorse] = useState();
  const handleHorseCloses = () => setShowHorse(false);
  const handleShowHorse = async (data) => {
    setmodaldatasHorse(data);
    await setShowHorse(true);
  };
  const [ShowJockey, setShowJockey] = useState(false);
  const [modaldatasJockey, setmodaldatasJockey] = useState();
  const handleJockeyCloses = () => setShowJockey(false);
  const handleShowJockey = async (data) => {
    setmodaldatasJockey(data);
    await setShowJockey(true);
  };

  const navigate = useNavigate();

  let id = data.data.JockeyOnRaceData1
    ? data.data.JockeyOnRaceData1._id
    : data.data._id
    ? data.data._id
    : data.data;
  const { data: JockeyHistory } = useSelector((state) => state.JockeyHistory);
  const { data: singlejockey, status } = useSelector(
    (state) => state.singlejockey
  );


  let totalcountdata = JockeyHistory ? JockeyHistory?.length : "";
  const [postsPerPage] = useState(11);
  const totalpagesdata = Math.ceil(totalcountdata / postsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const firstPageIndex = (currentPage - 1) * postsPerPage;
  const lastPageIndex = firstPageIndex + postsPerPage;
  const SliceData =  JockeyHistory.slice(firstPageIndex, lastPageIndex);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  const dispatch = useDispatch();

  const handleUnTrack = async (Id) => {
    try {
      await axios.post(
        `${window.env.API_URL}/UnTrackJockey`,
        { Jockey: Id },
        {
          headers: { Authorization: token, "x-xsrf-token": token },
        }
      );
      toast("Jockey Has Been UnTracked ");
      navigate("/tracker/#Jockeycontact1");
      // dispatch(fetchSinglejockey({ id }));
      // navigate("/tracker");
    } catch (error) {
      const err = error.response.data.message;
      toast(err);
    }
  };
  useEffect(() => {
    dispatch(fetchJockeyHistory({ id }));
    dispatch(fetchSinglejockey({ id }));
  }, [dispatch, id]);
  const cookiedata = Cookies.get("i18next");
  const token = Cookies.get("token");
  const btnNew2 = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "13px 10px",
    gap: "10px",
    width: "112px",
    height: "24px",
    background: "#0388fc",
    borderRadius: "10px",
    border: "none",
    color: "#fff",
    margin: "6px",
  };
  const btnNew1 = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "13px 10px",
    gap: "10px",
    width: "112px",
    height: "24px",
    background: "#FF0000",
    borderRadius: "10px",
    border: "none",
    color: "#fff",
    margin: "6px",
  };

  const handleTrack = async (Id) => {
    try {
      const res = await axios.post(
        `${window.env.API_URL}/TrackJockey`,
        { Jockey: Id },
        {
          headers: { Authorization: token, "x-xsrf-token": token },
        }
      );
      // toast('Tracked Success')
      navigate("/tracker/#Jockeycontact1");
    } catch (error) {
      const err = error.response.data.message;
      if (err === "Json Web Token is invalid") {
        toast.error("Please Login to Access This Resource");
        navigate("/login");
      } else {
        toast.error(err);
      }
    }
  };
  function calculatePercentage(x, y) {
    if (x === 0 && y === 0) return 0;
    else {
      const answer = Math.round((x / y) * 100);
      return answer;
    }
  }


  function distanceLabel(distance, rank) {
    const DistanceTobeChecked = parseFloat(distance);
    console.log(DistanceTobeChecked);
    if (DistanceTobeChecked.toFixed(2) > 25) {
      return "Dist";
    } else if (DistanceTobeChecked.toFixed(2) == 0.05 && rank < 80) {
      return "SHd";
    } else if (DistanceTobeChecked.toFixed(2) == 0.10 && rank < 80) {
      return "Hd";
    } else if (DistanceTobeChecked.toFixed(2) == 0.25 && rank < 80) {
      return "Nk";
    } else if (DistanceTobeChecked.toFixed(2) == 0.01 && rank < 80) {
      return "Ns";
    } else if (DistanceTobeChecked.toFixed(2) == 0.00 && rank < 80) {
      if(rank === 1){
        return 0;
      }
      return "Dh";
    } else {
      return distance;
    }
  }


  return (
    <>
      {status === "loading" ? (
        <>
          <Lottie animationData={Animate} loop={true} className="TableLottie" />
        </>
      ) : (
        <div className="aboutpage">
          <div className="RaceDetailCard">
            <div className="horseheader1">
              <div className="horseshirt">
                <img
                  src={singlejockey.image ? singlejockey.image : DefaultImg}
                  alt=""
                  className="horseshirtimage"
                  style={{
                    height: "60px",
                  }}
                />
              </div>
            
              <div className="horsecenterdata jockeyname1">
                <span
                  style={{
                    display: "flex",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "700",
                      fontSize: " 19.6px",
                      lineHeight: "24px",
                      color: "#19469D",
                    }}
                  >
                    {cookiedata === "en"
                      ? singlejockey.NameEn
                        ? singlejockey.NameEn
                        : <>- </>
                      : singlejockey.NameEn
                      ? singlejockey.NameAr
                      : <>- </>}
                  </p>

                  <p
                                      style={{
                                        fontWeight: "400",
                                        fontSize: "14px",
                                        lineHeight: "15px",
                                        color: "rgba(0, 0, 0, 0.8)",
                                        margin:"5px"
                                      }}
                                    >
                                      {singlejockey &&
                                        singlejockey.JockeyCount}
                                      (
                                      {singlejockey &&
                                        singlejockey
                                          .JockeyFirstRanking}{" "}
                                      -{" "}
                                      {singlejockey &&
                                        singlejockey
                                          .JockeySecondRanking}{" "}
                                      -{" "}
                                      {singlejockey &&
                                        singlejockey
                                          .JockeyThirdRanking}
                                      )
                                    </p>

                </span>

                <p
                  style={{
                    fontWeight: "400",
                    fontSize: " 13.6px",
                    color: "#19469D",
                    textTransform:"capitalize"

                  }}
                >
                  {t("Rating")}:{" "}
                  {singlejockey.Rating &&
                    singlejockey.Rating}
                </p>
                <p
                  style={{
                    fontWeight: "400",
                    fontSize: " 13.6px",
                    color: "#19469D",
                  }} className="mynation"
                >
                  <b>{t("Nationality")}:</b>{" "}
                  {cookiedata === "en" ?  singlejockey?.JockeyNationalityData &&
                    singlejockey?.JockeyNationalityData?.AbbrevEn : singlejockey?.JockeyNationalityData &&
                    singlejockey?.JockeyNationalityData?.AbbrevAr}
                </p>
              </div>
              <div className="ownerTrack">
                {singlejockey.track == true ? (
                  <button
                    style={btnNew2}
                    onClick={() => handleUnTrack(singlejockey._id)}
                  >
                    {t("Untrack")}
                  </button>
                ) : (
                  <button
                    style={btnNew1}
                    onClick={() => handleTrack(singlejockey._id)}
                  >
                    {t("Track")}
                  </button>
                )}
              </div>
            </div>

            <div className="mycardclass1">
              <div className="BodyNew mpobile0">
                <table className="customers horseModalTable">
                  <thead className="customers5 ">
                    <tr>
                      <th>{t("Lifetime Record")}</th>
                      <th>{t("Runs")}</th>
                      <th>{t("Wins")}</th>
                      <th>{t("2nd")}</th>
                      <th>{t("3rd")}</th>
                      <th>{t("4th")}</th>
                      <th>{t("5th")}</th>
                      <th>{t("Winnings")}</th>
                      <th>{t("Earnings")}</th>
                      {/* <th>{t("OR")} </th> */}
                    </tr>
                  </thead>
                  <tbody className="customers1">
                    <>
                      <tr className="BodyNew1 ">
                        <td className="lb">{t("TURF")}</td>
                        <td>{singlejockey.TurfCount}</td>
                        <td>
                          {singlejockey.TurfWins === null
                            ? 0
                            : singlejockey.TurfWins}
                        </td>
                        <td>
                          {singlejockey.Turfsecond === null
                            ? 0
                            : singlejockey.Turfsecond}
                        </td>
                        <td>
                          {singlejockey.Turfthird === null
                            ? 0
                            : singlejockey.Turfthird}
                        </td>
                        <td>
                          {singlejockey.Turffourth === null
                            ? 0
                            : singlejockey.Turffourth}
                        </td>
                        <td>
                          {singlejockey.Turffifth === null
                            ? 0
                            : singlejockey.Turffifth}
                        </td>
                        <td>
                          {calculatePercentage(
                            singlejockey.Turfsecond === null
                              ? 0
                              : singlejockey.Turfsecond +
                                  singlejockey.TurfWins ===
                                null
                              ? 0
                              : singlejockey.TurfWins +
                                  singlejockey.Turfthird ===
                                null
                              ? 0
                              : singlejockey.Turfthird,
                            singlejockey.TurfCount
                          )}
                          %
                        </td>
                        <td>
                          {singlejockey.TurfEarnings === null ? 0 : singlejockey.TurfEarnings}
                        </td>
                      </tr>
                      <tr className="BodyNew1 ">
                        <td className="lb">{t("DIRT")}</td>
                        <td>{singlejockey.DirtCount}</td>
                        <td>
                          {singlejockey.DirtWins === null
                            ? 0
                            : singlejockey.DirtWins}
                        </td>
                        <td>
                          {singlejockey.Dirtsecond === null
                            ? 0
                            : singlejockey.Dirtsecond}
                        </td>
                        <td>
                          {singlejockey.Dirtthird === null
                            ? 0
                            : singlejockey.Dirtthird}
                        </td>
                        <td>
                          {singlejockey.Dirtfourth === null
                            ? 0
                            : singlejockey.Dirtfourth}
                        </td>
                        <td>
                          {singlejockey.Dirtfifth === null
                            ? 0
                            : singlejockey.Dirtfifth}
                        </td>
                        <td>
                          {calculatePercentage(
                            singlejockey.Dirtsecond === null
                              ? 0
                              : singlejockey.Dirtsecond +
                                  singlejockey.DirtWins ===
                                null
                              ? 0
                              : singlejockey.DirtWins +
                                  singlejockey.Dirtthird ===
                                null
                              ? 0
                              : singlejockey.Dirtthird,
                            singlejockey.DirtCount
                          )}
                          %
                        </td>
                        <td>
                          {singlejockey.DirtEarnings === null
                            ? 0
                            : singlejockey.DirtEarnings}
                        </td>
                      </tr>
                      <tr className="BodyNew1 ">
                        <td className="lb">{t("ALL WEATHER")}</td>
                        <td>{singlejockey?.AllWeatherCount}</td>
                        <td>
                          {singlejockey?.AllWeatherWins === null
                            ? 0
                            : singlejockey?.AllWeatherWins}
                        </td>
                        <td>
                          {singlejockey?.AllWeathersecond === null
                            ? 0
                            : singlejockey?.AllWeathersecond}
                        </td>
                        <td>
                          {singlejockey?.AllWeatherthird === null
                            ? 0
                            : singlejockey?.AllWeatherthird}
                        </td>
                        <td>
                          {singlejockey?.AllWeatherfourth === null
                            ? 0
                            : singlejockey?.AllWeatherfourth}
                        </td>
                        <td>
                          {singlejockey?.AllWeatherfifth === null
                            ? 0
                            : singlejockey?.AllWeatherfifth}
                        </td>
                        <td>
                          {calculatePercentage(
                            singlejockey?.AllWeathersecond === null
                              ? 0
                              : singlejockey?.AllWeathersecond +
                                  singlejockey?.AllWeatherWins ===
                                null
                              ? 0
                              : singlejockey?.AllWeatherWins + singlejockey?.AllWeatherthird ===
                                null
                              ? 0
                              : singlejockey?.AllWeatherthird,
                            singlejockey?.AllWeatherCount
                          )}
                          %
                        </td>
                        <td>
                          {singlejockey?.AllWeatherEarnings === null
                            ? 0
                            : singlejockey?.AllWeatherEarnings}
                        </td>
                      </tr>
                      <tr className="BodyNew1 historyhorseth">
                        <td className="lb">{t("TOTAL")}</td>
                        <td>{singlejockey.TotalCount}</td>
                        <td>
                          {singlejockey.TotalWins === null
                            ? 0
                            : singlejockey.TotalWins}
                        </td>
                        <td>
                          {singlejockey.Totalsecond === null
                            ? 0
                            : singlejockey.Totalsecond}
                        </td>
                        <td>
                          {singlejockey.Totalthird === null
                            ? 0
                            : singlejockey.Totalthird}
                        </td>
                        <td>
                          {singlejockey.Totalfourth === null
                            ? 0
                            : singlejockey.Totalfourth}
                        </td>
                        <td>
                          {singlejockey.Totalfifth === null
                            ? 0
                            : singlejockey.Totalfifth}
                        </td>
                        <td>
                          {calculatePercentage(
                            singlejockey.Totalsecond === null
                              ? 0
                              : singlejockey.Totalsecond +
                                  singlejockey.TotalWins ===
                                null
                              ? 0
                              : singlejockey.TotalWins +
                                  singlejockey.Totalthird ===
                                null
                              ? 0
                              : singlejockey.Totalthird,
                            singlejockey.TotalCount
                          )}
                          %
                        </td>
                        <td>
                          {singlejockey.TotalEarnings === null
                            ? 0
                            : singlejockey.TotalEarnings}
                        </td>
                      </tr>
                    </>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="RaceNav HorseNav">
              <Tabs id="uncontrolled-tab-example" className="mb-3 RaceNavItem">
                <Tab
                  eventKey="Form"
                  title={t("Form")}
                  tabClassName="profile-tabitem"
                >
                  <div className="RaceDetailCard">
                    <div className="mycardclass1">
                      <div className="BodyNew mpobile0">
                        <table className="customers">
                          <thead className="customers5">
                            <tr>
                              <th>{t("Date")}</th>
                              <th>{t("Crs")}</th>
                              <th>{t("Dist")}</th>
                              <th>{t("TC")}</th>
                              <th>{t("RT")}</th>

                              <th>{t("Dts")}</th>
                              <th>{t("Time")}</th>
                              <th>{t("Jockey")}</th>
                              <th>{t("Wgt")}</th>

                              <th>{t("FP")}</th>
                              <th>{t("Len")}</th>
                              <th>{t("RN")}</th>
                              <th>{t("BtBy")}</th>
                              <th>{t("Kgs")}</th>

                              <th>{t("Draw")}</th>
                              <th>{t("Link")}</th>
                            </tr>
                          </thead>
                          <tbody className="customers1">
                            {JockeyHistory === undefined ? (
                              <PleaseWait />
                            ) : (
                              <>
                                {!SliceData ? (
                                  <PleaseWait />
                                ) : (
                                  SliceData &&
                                  SliceData?.map((item) => {
                                    return (
                                      <tr
                                        className="BodyNew1 historyhorseth"
                                        style={{
                                          background: `${
                                            item.RaceResultData
                                              ? item.RaceResultData
                                                  .TrackLengthData
                                                  .GroundTypeModelData.NameEn ==
                                                "Dirt"
                                                ? "#9F5109"
                                                : item.RaceResultData?.TrackLengthData?.GroundTypeModelData?.NameEn == "Turf"
                                                ? "#5ec40f"
                                                : "#949494"
                                              : "#949494"
                                          }`,
                                        }}
                                      >
                                        <td
                                          className="lb"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            handleShowRace(item.RaceID)
                                          }
                                        >
                                          <Moment format="DD/MM/YYYY">
                                            {item.RaceResultData === null ? (
                                              <></>
                                            ) : (
                                              item.RaceResultData.StartTime
                                            )}
                                          </Moment>
                                        </td>
                                        <td>
                                          {item.RaceResultData ? (
                                            cookiedata === "en" ? (
                                              item.RaceResultData &&
                                              item.RaceResultData
                                                ?.RaceCourseData ? (
                                                item.RaceResultData?.RaceCourseData?.TrackNameEn
                                              ) : (
                                                <>-</>
                                              )
                                            ) : item.RaceResultData &&
                                              item.RaceResultData?.RaceCourseData ? (
                                              item.RaceResultData?.RaceCourseData?.TrackNameAr
                                            ) : (
                                              <>-</>
                                            )
                                          ) : (
                                            <>- </>
                                          )}
                                          (
                                          {cookiedata === "en" ? (
                                            item.RaceResultData &&
                                            item.RaceResultData ? (
                                              item.RaceResultData?.TrackLengthData?.GroundTypeModelData?.NameEn.charAt(
                                                0
                                              )
                                            ) : (
                                              <>-</>
                                            )
                                          ) : item.RaceResultData ? (
                                            item.RaceResultData.TrackLengthData.GroundTypeModelData.NameAr.charAt(
                                              0
                                            )
                                          ) : (
                                            <>-</>
                                          )}
                                          )
                                        </td>
                                        <td>
                                        {item.RaceResultData ? (
                                            item.RaceResultData?.TrackLengthData.TrackLength
                                          ) : (
                                            <>-</>
                                          )}
                                        </td>
                                        <td>
                                          {item.RaceResultData
                                            ? cookiedata === "en"
                                              ? item.RaceResultData &&
                                                item.RaceResultData
                                                  .TrackConditionData.NameEn
                                              : item.RaceResultData &&
                                                item.RaceResultData
                                                  .TrackConditionData.NameAr
                                            : "_"}
                                        </td>
                                        <td>
                                          <OverlayTrigger
                                            className="tooltip-btn"
                                            overlay={
                                              <Tooltip
                                                id="tooltip-disabled"
                                                className="tooltip-btn"
                                              >
                                                {item.RaceResultData &&
                                                item.RaceResultData
                                                  ? cookiedata === "en"
                                                    ? item.RaceResultData &&
                                                      item.RaceResultData&&
                                                      item.RaceResultData
                                                        .RaceTypeModelData
                                                        .NameEn
                                                    : item.RaceResultData &&
                                                      item.RaceResultData&&
                                                      item.RaceResultData
                                                        .RaceTypeModelData
                                                        .NameAr
                                                  : "_"}
                                              </Tooltip>
                                            }
                                          >
                                            <span
                                              className="d-inline-block"
                                              style={{
                                                marginTop: "12px",
                                              }}
                                            >
                                              <p className="tooltipclass1">
                                                {" "}
                                                {item.RaceResultData &&
                                                item.RaceResultData
                                                  ? cookiedata === "en"
                                                    ? item.RaceResultData &&
                                                      item.RaceResultData
                                                        .RaceTypeModelData
                                                        .NameEn
                                                    : item.RaceResultData &&
                                                      item.RaceResultData
                                                        .RaceTypeModelData
                                                        .NameAr
                                                  : "_"}
                                              </p>
                                            </span>
                                          </OverlayTrigger>
                                        </td>
                                        <td>
                                          {item.PrizeWin ? item.PrizeWin : 0}
                                        </td>
                                        <td>{item.RaceTime}</td>
                                        {/* <td>{formatAMPM(item?.RaceResultData?.StartTime)}</td> */}
                                        <td
                                          onClick={() =>
                                            handleShowJockey(
                                              item.JockeyOnRaceDataResult
                                                ? item.JockeyOnRaceDataResult
                                                : ""
                                            )
                                          }
                                          style={{ cursor: "pointer" }}
                                        >
                                          {item.JockeyOnRaceDataResult
                                            ? cookiedata === "en"
                                              ?  window.textTransform(item.JockeyOnRaceDataResult
                                                  .ShortNameEn)
                                              : item.JockeyOnRaceDataResult
                                                  .ShortNameAr
                                            : "_"}
                                        </td>
                                        <td>
                                                  {item.RaceResultData
                                                    .RacehorsesData
                                                    ? item.RaceResultData
                                                        ?.RacehorsesData[0]
                                                        .JockeyRaceWeight
                                                    : "0"}
                                                </td>
                                        <td>
                                          {item.FinalPositionDataHorse ===
                                          null ? (
                                            <></>
                                          ) : (
                                            <>
                                              {cookiedata === "en" ? (
                                                <>
                                                  {cookiedata === "en"
                                                    ? item.FinalPositionDataHorse &&
                                                      item
                                                        .FinalPositionDataHorse
                                                        .NameEn
                                                    : item.FinalPositionDataHorse &&
                                                      item
                                                        .FinalPositionDataHorse
                                                        .NameAr}
                                                </>
                                              ) : (
                                                <>
                                                  {
                                                    item.FinalPositionDataHorse
                                                      .NameAr
                                                  }
                                                </>
                                              )}
                                            </>
                                          )}
                                        </td>
                                        <td>

                                        {
                                                    distanceLabel(item.Distance &&
                                                      item.Distance , item.FinalPositionDataHorse &&
                                                      item.FinalPositionDataHorse?.Rank)
                                                  }



                                         
                                        </td>
                                        <td>{item.RaceResultData?.Runners}</td>
                                        {/* <td>6</td> */}
                                        {/* <td>16.25</td> */}
                                        <td
                                          onClick={() =>
                                            handleShowHorse(
                                              item.BeatenByData
                                                ? item.BeatenByData
                                                : ""
                                            )
                                          }
                                          style={{ cursor: "pointer" }}
                                        >
                                           {item.BeatenByData ===
                                                    null ? (
                                                      <>{cookiedata === "en" ?  window.textTransform(item.HorseIDData?.HorseBeatenByEnglishName) : item.HorseIDData.HorseBeatenByArabicName}</>
                                                    ) : (
                                                      <>
                                                        {cookiedata === "en" ? (
                                                          <>
                                                            {
                                                               window.textTransform(item?.BeatenByData
                                                                ?.NameEn)
                                                            }
                                                          </>
                                                        ) : (
                                                          <>
                                                            {
                                                              item.BeatenByData
                                                                .NameAr
                                                            }
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                        </td>{" "}
                                        <td>
                                        {
                                                      item.BeatenByData !== null ? item.BeatenByData.WinningHorseJockeyWeight : (item.HorseIDData.BeatenByJockeyWeight === null ? 0 :  item.HorseIDData.BeatenByJockeyWeight)
                                                    }
                                        </td>
                                        <td>
                                          {item.RaceResultData
                                            ? item.RaceResultData &&
                                              item.RaceResultData?.Draw
                                            : <>- </>}
                                        </td>
                                        <td
                                          className="rb"
                                          onClick={() =>
                                            handleShows(item.VideoLink)
                                          }
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          <img src={arrow1} alt="" />
                                        </td>
                                      </tr>
                                    );
                                  })
                                )}
                              </>
                            )}
                          </tbody>
                         </table>
                         <div className="paginationstats">
                         <Pagination
                postsPerPage={postsPerPage}
                totalPosts={totalcountdata}
                paginate={paginate}
                currentPage={currentPage}
                TotalPages={totalpagesdata}
              />
                         </div>

                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab
                  eventKey="Entries"
                  title={t("Entries")}
                  tabClassName="profile-tabitem"
                >
                  <JockeyEntries id={id} />
                </Tab>
                <Tab
                  eventKey="Stats"
                  title={t("Stats")}
                  tabClassName="profile-tabitem"
                >
                  <div className="RaceDetailCard">
                    <Stats id={id} />
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      )}

      <Modal
        show={shows}
        onHide={handleCloses}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <VideoFrame data={modaldatas} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        show={ShowHorse}
        onHide={handleHorseCloses}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <HorseModal data={modaldatasHorse} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        show={ShowJockey}
        onHide={handleJockeyCloses}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <JockeyDetail data={modaldatasJockey} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        show={showRace}
        onHide={handleCloseRace}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader" closeButton></Modal.Header>
        <Modal.Body>
          <RaceModal id={modaldataRace} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};
export default JockeyDetail;
