import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});


const getSingleTrainerStats = createSlice({
    name: 'singletrainerstats',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchSingleTrainerStats.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchSingleTrainerStats.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchSingleTrainerStats.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setTrainer, setStatus } = getSingleTrainerStats.actions;
export default getSingleTrainerStats.reducer;

export const fetchSingleTrainerStats = createAsyncThunk('/LatestResultOfHorsesWithTrainer/fetch', async ({year, id }) => {
    const res = await axios.get(`${window.env.API_URL}/LatestResultOfHorsesWithTrainer/${id}?year=${year}`);
    const trainerstatsData = res.data;
    return trainerstatsData.data;
})