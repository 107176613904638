import Slider from "../../Components/Slider";
import Sponsor from "../../Components/Sponsor";
import RaceCardSlider from "../../Components/RaceCardSlider";
import Addington from "../../Components/Addington";
import RaceCardDetail from "../../../../pages/RaceCardDetail";
import { useLocation } from "react-router-dom";
import TodayRaceCard from "../../../../pages/TodayRaceCard";
import React,{useRef} from "react";
import { lazy } from "react";
import WinningList from "../../Components/WinningList";
import Result from "../../Components/Result";
import CurrentSection from "../CurrentPost";
import Banner from "../Banner";
import { useSelector , useDispatch   } from "react-redux";
import { fetchRace } from "../../../../redux/getReducer/getRaceCard";
import { useEffect } from "react";
import HomeCenterSubSection from "../../Section/HomeCenterSubSection";
import {useNavigate} from "react-router-dom"


const Ads = lazy(() => import("../../Components/Ads"));
const Competition = lazy(() => import("../../Components/Competition"));
const LeaderBoard = lazy(() => import("../../Components/LeaderBoard"));
const Graph = lazy(() => import("../../Components/Graph"));
const Prediction = lazy(() => import("../../Components/Prediction"));

const HomeLayout = () => {
  const { pathname } = useLocation();
  const { data: racecard, status } = useSelector((state) => state.racecard);
    const navigate = useNavigate();
  // const myRef = useRef(null);
  // useEffect(() => {
  //   // Scroll to the component when the component mounts
  //   if (myRef.current) {
    
  //     myRef.current.scrollIntoView({ behavior: 'smooth' });
  //   console.log("chal ja")
  //   }

  // }, [myRef]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRace());
  }, [dispatch]);

  return (
    <React.Suspense>
      <div className="row">
        <div className="col-sm-3 mobilehide">
          <CurrentSection />
          <Sponsor />
        </div>
        <div className="col-sm-9">
          <Slider />
          <div className="row racesliderrow">
            {
                racecard?.length === 0 ? <></> : <> <div className="col-sm-10">
                <RaceCardSlider />
              </div>
              <div className="col-sm-2 Addingtondiv">
                <Addington />
              </div></>
            }
           
          </div>
          <div className="col-sm-3 desktophide">
          <CurrentSection />
          <Sponsor />
        </div>
          <div className="row hassam">
            <div className="col-sm-8">
            
                     <div className="pre-next-btn-newone"  >{
                        pathname === '/' ? <> 
                        <Result />
                        <Banner />
                        <HomeCenterSubSection />
                        </> : (
                          pathname === '/mkscard' ? <TodayRaceCard /> : <RaceCardDetail />
                        )
                      }
                      </div>
            
             
            </div>
            <div className="col-sm-4">
              <div className="HomeRightSection">
                <Competition />
              </div>
              <div className="HomeRightLeaderBoader" >
                <LeaderBoard />
                <WinningList />
              </div>
            </div>
          </div>
        </div>
      </div>
     </React.Suspense>
  );
};
export default HomeLayout;
