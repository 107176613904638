import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getResultYearDropdownSlice = createSlice({
    name: 'ResultYearDropdown',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchResultYearDropdown.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchResultYearDropdown.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchResultYearDropdown.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setResultYearDropdown, setStatus } = getResultYearDropdownSlice.actions;
export default getResultYearDropdownSlice.reducer;

export const fetchResultYearDropdown = createAsyncThunk('/ResultYearDropdown/fetch', async ({ Value2,  data1,
    data2,
    data3,
    data4,
    data5,
    data6,
    data7,
    data8,
    data9,
    data10,
    data11,
    data12,
    data13,
    data14, IncreasePage1 }) => {
    const res = await axios.get(`${window.env.API_URL}/ResultYearDropdown?NameEn=${Value2}&size=${IncreasePage1}&StartTimeOfRace=${data1}&HorseID=${data2}&TrainerOnRace=${data3}&JockeyOnRace=${data4}&RaceCourseOfRace=${data5}&RaceCourseNationality=${data6}&RaceTypeOfRace=${data7}&RaceGround=${data8}&RaceTracklength=${data9}&OwnerId=${data10}&MeetingType=${data11}&Sponsor=${data12}&HorseKindinRace=${data13}&date=${data14}`);
    const ResultYearDropdownData = res.data;
    return ResultYearDropdownData.results;
})
