import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

const getPointStagingSlice = createSlice({
  name: "PointStaging",
  initialState: {
    data: [],
    status: STATUSES.IDLE,
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchPointStaging.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchPointStaging.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(fetchPointStaging.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { setPointStaging, setStatus } = getPointStagingSlice.actions;
export default getPointStagingSlice.reducer;

export const fetchPointStaging = createAsyncThunk(
  "/CompetitionStaging/fetch",
  async () => {
    const res = await axios.get(`${window.env.API_URL}/CompetitionStaging`);
    const PointStagingData = res.data;
    return PointStagingData.data;
  }
);
