import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});


const getJockeyStatsSlice = createSlice({
    name: 'jockeystats',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchJockeyStats.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchJockeyStats.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchJockeyStats.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setTrainer, setStatus } = getJockeyStatsSlice.actions;
export default getJockeyStatsSlice.reducer;

export const fetchJockeyStats = createAsyncThunk('/JockeyStatistics/fetch', async ({   year,
    StartDate,
    EndDate,
    RaceCourseSearch,
    HorseKindSearch,
    TrackLengthSearch,
    RaceTypeSearch,
    RaceKindSearch,
    RaceNationalitySearch,
    RaceGroundSearch,
    RaceDateSearch,
    currentPage,}) => {
    const res = await axios.get(`${window.env.API_URL}/JockeyStatistics?year=${
        year === undefined ? "" : year
      }&startTimeTo=${StartDate}&startTimeFrom=${EndDate}&RaceCourse=${RaceCourseSearch}&HorseKindinRace=${HorseKindSearch}&TrackLength=${TrackLengthSearch}&RaceType=${RaceTypeSearch}&RaceKind=${RaceKindSearch}&NationalityID=${RaceNationalitySearch}&Ground=${RaceGroundSearch}&Date=${RaceDateSearch}&page=${currentPage}`);
    const jockeystatsData = res.data;
    return jockeystatsData;
})