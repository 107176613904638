import React, {useState, useEffect} from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { requestForToken, onMessageListener } from './firebase';
import logoimg from '../Webiste/assets/MKS.png'
import addNotification from 'react-push-notification'
import socket from '../Socket';
import Cookies from 'js-cookie';

const Notification = () => {
  const [notification, setNotification] = useState({title: '', body: '',link:''});
  const notify = () =>  toast(<ToastDisplay/>); 
  let url = notification.link;

  const token = Cookies.get("token");
  const cookiedata = Cookies.get("i18next");


  function ToastDisplay() {



    addNotification({
      title:notification.title,
      message:notification.body,
      duration:4000,
      native:true,
      onClick:() => window.open(url, '_blank')
  })
    return (
      <div>
        <img src={logoimg} alt="Saddle Battle " />
        <p>
            <b>{notification?.title}</b>
        </p>
        {/* <a href={notification?.link}>{notification?.link}</a> */}
        <p>{notification?.body}</p>
      </div>
    );
  };

  useEffect(() => {
    if (notification?.title ){
     notify()
    }
  }, [notification])

  requestForToken();



  onMessageListener()
    .then((payload) => {
      setNotification({title:( cookiedata === "en" ?  payload.notification.title : payload.data.TitleAr), body: (cookiedata === "en" ? payload.notification.body :  payload.data.BodyAr), link: payload.data.url})
      socket.emit("NotificationUpdate",token)

    })
    .catch((err) => console.log('failed: ', err));

  return (
     <Toaster position="top-right"
    />
  )
}

export default Notification
