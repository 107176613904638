import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import i18next from "i18next";
import classNames from "classnames";
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector } from 'react-redux';

const languages = [
  {
    code: "en",
    name: "En",
    country_code: "gb",
  },
  {
    code: "ar",
    name: "Ar",
    dir: "rtl",
    country_code: "sa",
  },
];

const Language = () => {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  return (
    <>
     <Dropdown>
      <Dropdown.Toggle variant="success" className="themeclass"  id="dropdown-basic">
      {currentLanguageCode}
      </Dropdown.Toggle>

      <Dropdown.Menu >
      {languages.map(({ code, name, country_code }) => (
                <Dropdown.Item key={country_code}>
                  <p
                    className={classNames("dropdown-item lgpointer", {
                      disabled: currentLanguageCode === code,
                    })}
                    onClick={() => {
                      i18next.changeLanguage(code);
                      // window.location.reload();
                    }}
                  >
                    {name}
                  </p>
                </Dropdown.Item>
              ))}
       
      </Dropdown.Menu>
    </Dropdown>
      
    </>
  );
};
export default Language;